<app-page>
  <div class="c_teamDetailHeader">
    <h2 class="u_txt--hdg2 u_txt--white">
      Team Profile
      <span class="u_txt--white" *ngIf="isAccountTeam$ | async"
        >(Single-Player Team
        <button (click)="openAcctModal()" class="c_teamDetailPage__acctTeamBtn">
          <i class="fas fa-question-circle"></i></button
        >)</span
      >
    </h2>
  </div>
  <ng-container *ngIf="(isPageLoading$ | async) === false; else loading">
    <div class="c_teamDetailGrid">
      <div class="c_teamDetailGrid__teamInfo">
        <app-team-info-block></app-team-info-block>
      </div>
      <div class="c_teamDetailGrid__teamStats">
        <app-team-stats-block></app-team-stats-block>
      </div>
      <div class="c_teamDetailGrid__roster">
        <app-team-roster-block></app-team-roster-block>
      </div>
      <div class="c_teamDetailGrid__leagueHistory">
        <app-league-history-block></app-league-history-block>
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <app-loader [useWhite]="true">Loading Team...</app-loader>
  </ng-template>
</app-page>
