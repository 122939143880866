import { Component, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

import { OrganizationSearchTerms } from "@services/organizations/organization.api.types";
import { OrganizationService } from "@services/organizations/organization.service";

import { OrganizationSearchQuery } from "src/app/reducers/organization/organization.types";
import { defaultSearchQuery, SearchQuery } from "src/app/util/search-utils";
import { ggleaguesOrg } from "src/app/static-data/organizations/ggleagues-organization.data";

const clarificationString = "Don't see your club? Try searching by state to see which clubs are available";

@Component({
  selector: "app-club-search-page",
  templateUrl: "./club-search-page.component.html",
  styleUrls: ["./club-search-page.component.scss"],
})
export class ClubSearchPageComponent implements OnInit {
  public lastSearchTerms: OrganizationSearchTerms | null = null;

  public searchResults$: Observable<OrganizationSearchQuery> = of(null);
  public loading = false;
  public paginationInfo: SearchQuery = defaultSearchQuery;
  public clarificationString = clarificationString;

  public ggleaguesOrg = ggleaguesOrg;

  constructor(private _orgService: OrganizationService) { }

  public searchOrganizations(searchTerms: OrganizationSearchTerms): void {
    this.lastSearchTerms = searchTerms;
    this.loading = true;
    this._orgService.organizationSearch(searchTerms).pipe(tap((searchResult) => {
      const { page, limit, pageLimit, total } = searchResult;
      this.paginationInfo = {
        page,
        pageLimit,
        limit,
        total,
      };
      this.loading = false;
    })).subscribe(
      (results) => {
        this.searchResults$ = of(results);
        window.scrollTo(0, 450);
      }
    );
  }

  public ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  /**
   * Detect when the pagination component changes so we can
   * perform a search
   *
   * @param event is the emitted value
   * @author Christian Tweed
   */
  public handlePageChange({ page, limit }: SearchQuery): void {
    if (!!this.lastSearchTerms) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { page: _page, limit: _limit, ...rest } = this.lastSearchTerms;
      const searchTerms: OrganizationSearchTerms = {
        ...rest,
        page,
        limit,
      };
      this.searchOrganizations(searchTerms);
      return;
    }

    this.searchOrganizations({
      page,
      limit,
    });
  }
}
