<div class="c_forfeitConfirmationStep__confirmationInfo">
  <p>
    <strong>This match will be recorded as a <u>forfeit</u></strong>
  </p>
  <small>
    Please check the following results and ensure they are correct. Once
    submitted they <strong class="u_txt--warn">can not</strong> be edited
  </small>
</div>

<div class="c_forfeitConfirmationStep__teams">
  <div
    class="c_forfeitConfirmationStep__team"
    [ngClass]="{
      'c_forfeitConfirmationStep__team--forfeitting':
        forfeittingTeamId === matchReport.teams[0].id,
      'c_forfeitConfirmationStep__team--winning':
        forfeittingTeamId !== matchReport.teams[0].id
    }"
  >
    <img
      [src]="matchReport.teams[0]?.logoUrl | teamLogoDefault"
      alt="Team One Icon"
    />
    <strong
      >{{ matchReport.teams[0].title }} <br />
      <span>
        is
        {{
          forfeittingTeamId === matchReport.teams[0].id
            ? "forfeitting"
            : "winning"
        }}
        the series</span
      ></strong
    >
  </div>

  <div
    class="c_forfeitConfirmationStep__team"
    [ngClass]="{
      'c_forfeitConfirmationStep__team--forfeitting':
        forfeittingTeamId === matchReport.teams[1].id,
      'c_forfeitConfirmationStep__team--winning':
        forfeittingTeamId !== matchReport.teams[1].id
    }"
  >
    <img
      [src]="matchReport.teams[1]?.logoUrl | teamLogoDefault"
      alt="Team One Icon"
    />
    <strong
      >{{ matchReport.teams[1].title }} <br />
      <span>
        is
        {{
          forfeittingTeamId === matchReport.teams[1].id
            ? "forfeitting"
            : "winning"
        }}
        the series</span
      ></strong
    >
  </div>
</div>

<ng-template #footerButtons>
  <app-modal-stepper-footer-buttons
    [hasPrevious]="true"
    [hasNext]="false"
    [formToCheck]="null"
    errorMessage=""
  >
    <button
      class="c_button primary_dark c_forfeitConfirmationStep__button"
      (click)="submitForfeit()"
    >
      Finish
    </button>
  </app-modal-stepper-footer-buttons>
</ng-template>
