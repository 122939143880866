import {
  Component,
  Input,
  OnInit,
} from "@angular/core";

import { GenericRosterMember } from "src/app/reducers/leagues/league.types";
import { processImage, ImageTypes } from "../../util/image-utils";
import { generateUserDetailRoute } from "src/app/enums/routes/routePaths";

@Component({
  selector: "app-large-roster-icon",
  templateUrl: "./large-roster-icon.component.html",
  styleUrls: ["./large-roster-icon.component.scss"],
})
export class LargeRosterIconComponent implements OnInit {
  @Input() public rosterMemberData: GenericRosterMember;
  public userImage: string;
  public generateProfileIconStyle(profileImg: string): string {
    return `url('${profileImg}')`;
  }
  public generateRoute(id: string): string {
    return generateUserDetailRoute(id);
  }

  public ngOnInit() {
    this.userImage = processImage(
      this.rosterMemberData.image,
      ImageTypes.USER_SQ
    );
  }
}
