import { Component, Input } from "@angular/core";

import { isoStringToShortWithTime } from "@utils/date-utils";

import { generateSeriesDetailRoute } from "src/app/enums/routes/routePaths";
import { LeagueSeriesMatchup } from "src/app/reducers/leagues/league.types";

@Component({
  selector: "app-small-battle-royale-match-block",
  templateUrl: "./small-battle-royale-match-block.component.html",
  styleUrls: ["./small-battle-royale-match-block.component.scss"],
})
export class SmallBattleRoyaleMatchBlockComponent {
  @Input() public battleRoyaleMatchup!: LeagueSeriesMatchup;

  public getMatchDetailRoute(id: string): string[] {
    return [`/${generateSeriesDetailRoute(id)}`];
  }

  public processDate(date: string): string {
    return isoStringToShortWithTime(date);
  }
}
