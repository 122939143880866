/* eslint-disable max-len */
import { Component, Input } from "@angular/core";
import { BattleRoyaleStreamGame } from "@apptypes/streaming-series.types";
import { Store } from "@ngrx/store";
import { modalOptions } from "@utils/modal-helpers";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Observable, of } from "rxjs";
import { take, map } from "rxjs/operators";
import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import { RootState } from "src/app/reducers";
import { SeriesMatchupV2 } from "src/app/reducers/matches/matches.types";
import { BattleRoyaleAggregateResultsModalComponent } from "../battle-royale-aggregate-results-modal/battle-royale-aggregate-results-modal.component";

@Component({
  selector: "app-battle-royale-preview-block",
  templateUrl: "./battle-royale-preview-block.component.html",
  styleUrls: ["./battle-royale-preview-block.component.scss"],
})
export class BattleRoyalePreviewBlockComponent {
  @Input() public seriesData: SeriesMatchupV2;
  @Input() public brSeriesData: BattleRoyaleStreamGame[] = [];

  public seriesWinner$: Observable<string | null> = of(null);
  public gamePlatforms = gamePlatforms;

  constructor(
    private _modalService: NgxSmartModalService,
    private _store: Store<RootState>
  ) {
    this.seriesWinner$ = this._store.select("matches", "seriesMatchup").pipe(
      take(1),
      map((seriesMatchup) => {
        if (seriesMatchup.winnerId) {
          const winnerTeam = seriesMatchup.teams.find((team) => team.id === seriesMatchup.winnerId);

          if (winnerTeam) {
            return `${winnerTeam.title} 🏆`;
          }
        }

        return null;
      })
    );
  }

  public get gameLogoPath(): string {
    const esport = this.seriesData.esport;
    switch (esport) {
      case gamePlatforms.FORTNITE:
        return "/assets/images/br_preview/FortniteLogo.svg";
      case gamePlatforms.ROCKET_LEAGUE:
        return "https://content.ggleagues.com/public/esport_artwork/br_images/Rocket_League_logo.svg";
    }
  }

  public get esportEventType(): string {
    const esport = this.seriesData.esport;
    switch (esport) {
      case gamePlatforms.FORTNITE:
        return "Battle Royale";
      case gamePlatforms.ROCKET_LEAGUE:
        return "Tournament";
    }
  }

  public openAggregateResults(): void {
    this._modalService.create(
      BattleRoyaleAggregateResultsModalComponent.modalId,
      BattleRoyaleAggregateResultsModalComponent,
      modalOptions
    ).setData(this.brSeriesData).open();
  }
}
