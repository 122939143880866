import { Injectable } from "@angular/core";

import { JoinLeagueModalSteps } from "src/app/enums/join-league-modal-steps.enum";
import {
  LeagueRegistrationConfig,
  LeagueRegistrationInformation,
  OrganizationTypes,
} from "src/app/reducers/leagues/league.types";
import { TeamTypes } from "src/app/reducers/teams/teams.types";


@Injectable({
  providedIn: "root",
})
export class JoinLeagueModalService {
  private _modalSteps: JoinLeagueModalSteps[] = [];

  public resetSteps(): void {
    this._modalSteps = [];
  }

  public constructSteps(registrationInformation: LeagueRegistrationInformation): void {
    this.resetSteps();

    if(this._hasTeamStep(registrationInformation.registrationConfig) ){
      this._modalSteps.push(JoinLeagueModalSteps.TEAM_STEP);
    }

    if(this._hasReferralStep(registrationInformation)){
      this._modalSteps.push(JoinLeagueModalSteps.REFERRAL_STEP);
    }

    if(this._hasSponsorStep(registrationInformation)){
      this._modalSteps.push(JoinLeagueModalSteps.SPONSOR_STEP);
    }

    if(this._hasRegistrationTypeStep(registrationInformation, this.hasReferralStep, this.hasSponsorStep)){
      this._modalSteps.push(JoinLeagueModalSteps.REGISTRATION_TYPE_STEP);
    }

    if(this._hasCityStep(registrationInformation)){
      this._modalSteps.push(JoinLeagueModalSteps.CITY_STEP);
    }

    this._modalSteps.push(JoinLeagueModalSteps.CONFIRMATION_STEP);

    if(this._hasCheckoutStep(registrationInformation, this.hasSponsorStep, this.hasReferralStep)){
      this._modalSteps.push(JoinLeagueModalSteps.CHECKOUT_STEP);
    }
  }

  public get hasTeamStep(): boolean {
    return this._modalSteps.includes(JoinLeagueModalSteps.TEAM_STEP);
  }

  public get hasSponsorStep(): boolean {
    return this._modalSteps.includes(JoinLeagueModalSteps.SPONSOR_STEP);
  }

  public get hasReferralStep(): boolean {
    return this._modalSteps.includes(JoinLeagueModalSteps.REFERRAL_STEP);
  }

  public get hasRegistrationTypeStep(): boolean {
    return this._modalSteps.includes(JoinLeagueModalSteps.REGISTRATION_TYPE_STEP);
  }

  public get hasCityStep(): boolean {
    return this._modalSteps.includes(JoinLeagueModalSteps.CITY_STEP);
  }

  public get hasCheckoutStep(): boolean {
    return this._modalSteps.includes(JoinLeagueModalSteps.CHECKOUT_STEP);
  }

  public get isRegistrationStepFirst(): boolean {
    return !this.hasTeamStep && this.hasRegistrationTypeStep;
  }

  public get isReferralStepFirst(): boolean {
    return !this.hasTeamStep && !this.hasRegistrationTypeStep && this.hasReferralStep;
  }

  public get isSponsorStepFirst(): boolean {
    return !this.hasTeamStep && !this.hasRegistrationTypeStep && this.hasSponsorStep;
  }

  public get isCityStepFirst(): boolean {
    return !this.hasTeamStep && !this.hasReferralStep && !this.hasSponsorStep && this.hasCityStep;
  }

  public get isConfirmationStepFirst(): boolean {
    return !this.hasTeamStep && !this.hasReferralStep && !this.hasSponsorStep && !this.hasCityStep;
  }

  private _hasTeamStep(regConfig?: LeagueRegistrationConfig): boolean {
    if (regConfig) {
      const { team_registration_type } = regConfig;
      return team_registration_type === TeamTypes.STANDARD;
    }

    return true;
  }

  private _hasSponsorStep = ({ organizations, isSeasonPassLeague }: LeagueRegistrationInformation): boolean =>
    organizations.filter(org => org.organizationLeagueType === OrganizationTypes.SPONSOR).length > 0 && !isSeasonPassLeague;

  private _hasReferralStep = ({ organizations, isSeasonPassLeague }: LeagueRegistrationInformation): boolean =>
    organizations.filter(org => org.organizationLeagueType === OrganizationTypes.REFERRAL).length > 0 && !isSeasonPassLeague;

  private _hasCityStep = ({ registrationConfig, isSeasonPassLeague }: LeagueRegistrationInformation): boolean =>
    registrationConfig.register_location && !isSeasonPassLeague;

  private _hasRegistrationTypeStep = (
    { isSeasonPassLeague }: LeagueRegistrationInformation,
    hasReferralStep: boolean = false,
    hasSponsorStep: boolean = false
  ): boolean => hasReferralStep && hasSponsorStep && !isSeasonPassLeague;

  private _hasCheckoutStep = (
    { isSeasonPassLeague, teamFee }: LeagueRegistrationInformation,
    hasSponsorStep: boolean = false,
    hasReferralStep: boolean = false
  ): boolean => {
    const isOnlySponsorStep = hasSponsorStep && !hasReferralStep;
    return !!teamFee && parseFloat(teamFee) > 0 && !isOnlySponsorStep && !isSeasonPassLeague;
  };
}
