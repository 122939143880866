import { Pipe, PipeTransform } from "@angular/core";

import { Nullish } from "@apptypes/generic-nullish.types";

import { generateOrganizationSlugRoute } from "src/app/enums/routes/routePaths";
import { validatePipeRoute } from "../utils/routing-pipes.utils";

@Pipe({
  name: "organizationDetailRouter",
})
export class OrganizationDetailRouterPipe implements PipeTransform {
  public transform = (slug: Nullish<string>): string[] => validatePipeRoute(slug, generateOrganizationSlugRoute);
}
