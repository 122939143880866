<div>
  <ng-container *ngIf="!loading; else chatLoading">
    <h3 class="u_txt--hdg3">Admin Notifications</h3>
    <div class="c_chatBlock">
      <app-chat-stream
        [messages]="adminMessages"
        [isAdminNotifStream]="true"
      ></app-chat-stream>
    </div>
    <h3 class="u_txt--hdg3">{{ isQueue ? "Queue" : "Match" }} Chat</h3>
    <div class="c_chatBlock">
      <app-chat-stream [messages]="userMessages"></app-chat-stream>
      <app-add-new-comment-block
        (sendChatEvent)="submitComment($event)"
        [status]="chat.chatroom.status"
        [chatType]="chatData.referenceType"
        [lobbyCodes]="lobbyCodes"
      ></app-add-new-comment-block>
    </div>
  </ng-container>
  <ng-template #chatLoading>
    <div class="c_chatBlock">
      <app-loader>Loading Chat...</app-loader>
    </div>
  </ng-template>
</div>
