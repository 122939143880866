<div
  class="c_teamInfoBlock"
  *ngIf="currentTeam$ | async as team; else notAvailable"
>
  <h2 class="c_teamInfoBlock__name u_txt--hdg2">{{ team.title }}</h2>
  <div
    class="c_teamInfoBlock__info"
    *ngIf="team.completedGames > 0; else noCompletedMatches"
  >
    <!-- TODO: Add Rank in-->
    <!-- <strong class="c_teamInfoBlock_info_rank">
			Rank: Gold
		</strong> -->
    <strong class="c_teamInfoBlock__info__record">
      <!-- 53 LP / -->
      {{ team.wins }}W - {{ losses }}L
    </strong>
    <span class="c_teamInfoBlock__info__winPercent">
      Win Ratio:
      {{ winRatio }}%
    </span>
  </div>
  <ng-template #noCompletedMatches>
    <span class="c_teamInfoBlock__info">
      This team has not participated in any matches
    </span>
  </ng-template>
  <div class="c_teamInfoBlock__bio">
    <div
      class="c_teamInfoBlock__bio__photo"
      [ngStyle]="{ 'background-image': teamImage }"
    ></div>
    <p
      *ngIf="team.description; else noDescription"
      class="c_teamInfoBlock__bio__txt u_txt--body"
    >
      {{ team.description }}
    </p>
    <ng-template #noDescription
      ><p class="c_teamInfoBlock__bio__txt u_txt--body">
        No Description For This Team
      </p></ng-template
    >
    <button
      class="c_button primary_dark"
      *ngIf="team.captainId === userId"
      routerLink="/{{ getTeamEditRoute(team.id) }}"
    >
      Edit Details
    </button>
  </div>
</div>

<ng-template #notAvailable>
  <div class="c_teamInfoBlock">
    <h2 class="c_teamInfoBlock_name u_txt--hdg2">Team Could Not Be Found</h2>
    <p class="c_teamInfoBlock__bio__txt">We are sorry for the issue.</p>
    <p class="c_teamInfoBlock__bio__txt">
      Please make sure your team is a valid team.
    </p>
    <div
      class="c_teamInfoBlock__bio__photo"
      [ngStyle]="{ 'background-image': teamImage }"
    ></div>
  </div>
</ng-template>
