<div class="c_noSearchResults">
  <i
    class="fas fa-2x fa-search"
    [ngClass]="{ 'u_txt--white': hasWhiteText }"
  ></i>
  <p class="u_txt" [ngClass]="{ 'u_txt--white': hasWhiteText }">
    We could not find any {{ searchItem }} matching your search. Please adjust
    your search and try again.
  </p>
  <ng-container *ngIf="isOrg; else useCustomCopy">
    <p class="u_txt" [ngClass]="{ 'u_txt--white': hasWhiteText }">
      Can't find your organization? You can get a GGLeagues Pass and we'll get
      you into the event you want!
    </p>
    <app-organization-search-result [searchResult]="ggleaguesOrg">
    </app-organization-search-result>
  </ng-container>
  <ng-template #useCustomCopy>
    <p class="u_txt" [ngClass]="{ 'u_txt--white': hasWhiteText }">
      {{ clarificationString }}
    </p>
  </ng-template>
</div>
