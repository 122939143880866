import { InviteCodeStatuses } from "src/app/enums/team.enum";
import { GenericRosterMember } from "../leagues/league.types";

export interface TeamsState {
  currentTeam?: TeamDetails;
  allTeams?: Team[];
  fetching: boolean;
  fetchingId: boolean;
  teamIdError: boolean;
  teamsError: boolean;
  isUpdatingRoster: boolean;
  addingTeamToLeague: boolean;
  addingTeamToLeagueError: boolean;
}

export interface Team {
  id: string;
  type: string;
  description: string;
  title: string;
  captain: string;
}

export interface SearchedTeam {
  id: number;
  captainId: number;
  title: string;
  captain: string;
}

export interface GenericTeamRoster {
  starters: GenericRosterMember[];
  bench: GenericRosterMember[];
  pending: GenericRosterMember[];
}

export enum TeamTypes {
  STANDARD = "standard",
  ACCOUNT_SOLO_TEAM = "account",
  BYE = "bye_week",
}

export interface TeamDetails {
  id: number;
  type: string;
  captainId: number;
  description: string;
  title: string;
  completedGames: number;
  inviteCode?: string;
  inviteCodeStatus?: InviteCodeStatuses;
  logoUrl: string;
  wins: number;
  nextSeriesMatchup: GenericTeamSeriesMatchup;
  lastCompletedSeriesMatchup: GenericTeamSeriesMatchup;
  members: GenericTeamRoster;
  leagues?: GenericTeamLeagueData[];
  teamType: TeamTypes;
}

export interface GenericTeamLeagueData {
  id: number;
  title: string;
  description: string;
  finished: boolean;
  entrants: number;
  startTime: number;
  logoUrl: string;
}

export enum TeamRosterUpdateActions {
  ACCEPT_INVITE = "ACCEPT_INVITE",
  ACTIVATE_PLAYER = "ACTIVATE_PLAYER",
  BENCH_PLAYER = "BENCH_PLAYER",
  DELETE_PLAYER = "DELETE_PLAYER",
  REJECT_PLAYER = "REJECT_PLAYER",
}

export interface GenericTeamSeriesMatchup {
  id: string;
  type: string;
  winnerId: string;
  leagueId: string;
  teams: {
    id: string;
    captainId: string;
    title: string;
    wins: number;
    completedGames: number;
    logoUrl: string;
  }[];
}

export interface GenericTeamInviteCodeInformation {
  inviteCode: string;
  inviteCodeStatus: InviteCodeStatuses;
  teamId: string;
}

export interface RankedTeam {
  name: string;
  wins: number;
  losses: number;
  id: number;
}

export interface RankedTeamScore {
  name: string;
  id: string;
  score: number;
}

export interface CreateTeamRequest {
  title: string;
  split_team_fee: boolean;
  logo?: File;
  description: string;
}

export interface TeamUpdateRequest {
  title: string;
  logo?: File;
  description: string;
  id: number;
}
