/* eslint-disable @typescript-eslint/no-unused-vars */
import { Logger } from "@utils/logger";
import { SessionStorageKeys } from "src/app/enums/session-storage-keys.enum";

// Refernce to Google Analytics
// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

const registerRedditSeasonPassActivationEvent = (passId: string, redditRef: string, isDash = false): void => {
  try {
    const eventName = isDash ? "dash_season_pass_activation_reddit_referral" : "season_pass_activation_reddit_referral";
    const eventCategory = isDash ? "dash_events" : "season_pass_activation";
    gtag("event", eventName, {
      event_category: eventCategory,
      event_label: `league:${passId}|ref:${redditRef}`,
      value: passId,
    });
    Logger.log("Sent season_pass_activation_reddit_referral to analtyics");
    Logger.log({
      event_category: "season_pass_activation",
      event_label: `league:${passId}|ref:${redditRef}`,
      value: passId,
    });
  } catch (e) {
    Logger.error("season_pass_activation_reddit_referral event failed to fire");
  }
};

const registerGoogleSeasonPassActivationEvent = (passId: string, googleRef: string, isDash = false): void => {
  try {
    const eventName = isDash ? "dash_season_pass_activation_google_referral" : "season_pass_activation_google_referral";
    const eventCategory = isDash ? "dash_events" : "season_pass_activation";
    gtag("event", eventName, {
      event_category: eventCategory,
      event_label: `league:${passId}|ref:${googleRef}`,
      value: passId,
    });
    Logger.log("Sent season_pass_activation_google_referral to analtyics");
    Logger.log({
      event_category: "season_pass_activation",
      event_label: `league:${passId}|ref:${googleRef}`,
      value: passId,
    });
  } catch (e) {
    Logger.error("season_pass_activation_google_referral event failed to fire");
  }
};

const registerTwitterSeasonPassActivationEvent = (passId: string, twtrRef: string, isDash = false): void => {
  try {
    const eventName = isDash ? "dash_season_pass_activation_twitter_referral" : "season_pass_activation_twitter_referral";
    const eventCategory = isDash ? "dash_events" : "season_pass_activation";
    gtag("event", eventName, {
      event_category: eventCategory,
      event_label: `league:${passId}|ref:${twtrRef}`,
      value: passId,
    });
    Logger.log("Sent season_pass_activation_twitter_referral to analtyics");
    Logger.log({
      event_category: "season_pass_activation",
      event_label: `league:${passId}|ref:${twtrRef}`,
      value: passId,
    });
  } catch (e) {
    Logger.error("season_pass_activation_twitter_referral event failed to fire");
  }
};

const registerFBSeasonPassActivationEvent = (passId: string, fbRef: string, isDash = false): void => {
  try {
    const eventName = isDash ? "dash_season_pass_activation_fb_referral" : "season_pass_activation_fb_referral";
    const eventCategory = isDash ? "dash_events" : "season_pass_activation";
    gtag("event", eventName, {
      event_category: eventCategory,
      event_label: `league:${passId}|ref:${fbRef}`,
      value: passId,
    });
    Logger.log("Sent season_pass_activation_fb_referral to analtyics");
    Logger.log({
      event_category: "season_pass_activation",
      event_label: `league:${passId}|ref:${fbRef}`,
      value: passId,
    });
  } catch (e) {
    Logger.error("season_pass_activation_fb_referral event failed to fire");
  }
};

const registerSeasonPassActivationRef = (passId: string, isDash = false): void => {
  try {
    const eventName = isDash ? "dash_season_pass_activation_organic" : "season_pass_activation_organic";
    const eventCategory = isDash ? "dash_events" : "season_pass_activation";
    gtag("event", eventName, {
      event_category: eventCategory,
      event_label: `league:${passId}|ref:organic`,
      value: passId,
    });
    Logger.log("Sent season_pass_activation_organic to analytics");
    Logger.log({
      event_category: "season_pass_activation",
      event_label: `league:${passId}|ref:organic}`,
      value: passId,
    });
  } catch (e) {
    Logger.error("season_pass_activation_reddit_referral event failed to fire");
  }
};

export const triggerSeasonPassActivationAnalyticsEvent = (passId: string, userId: string | number): void => {
  try {
    gtag("event", "conversion", {
      send_to: "AW-345413332/Dpx_CNyhjOYCENSt2qQB",
    });
    gtag("event", "conversion", {
      send_to: "AW-345413332/-Z8SCM6WhPMCENSt2qQB",
      transaction_id: `P:${passId}|U:${userId}`,
    });
  } catch (e) {
    Logger.error(e);
  }

  registerSeasonPassActivationRef(passId);

  // const rid = localStorage.getItem(SessionStorageKeys.REDDIT_CAMPAIGN_REF);
  // const gclid = localStorage.getItem(SessionStorageKeys.GOOGLE_CAMPAIGN_REF);
  // const fbclid = localStorage.getItem(SessionStorageKeys.FB_CAMPAIGN_REF);
  // const twtr = localStorage.getItem(SessionStorageKeys.TWITTER_CAMPAIGN_REF);


  // if (fbclid) {
  //   registerFBSeasonPassActivationEvent(passId, fbclid);
  // } else if (gclid) {
  //   registerGoogleSeasonPassActivationEvent(passId, gclid);
  // } else if (rid) {
  //   registerRedditSeasonPassActivationEvent(passId, rid);
  // } else if (twtr) {
  //   registerTwitterSeasonPassActivationEvent(passId, twtr);
  // } else {
  //   registerSeasonPassActivationRef(passId);
  // }
};


// DASH RELATED PASS ANALYTICS

export const dashUserSelectedPass = (passId: string): void => {
  try {
    gtag("event", "dash_user_selected_pass", {
      event_category: "dash_events",
      event_label: `pass:${passId}`,
      value: passId,
    });
    Logger.log("Sent dash_user_selected_pass to analtyics");
    Logger.log({
      event_category: "dash_events",
      event_label: `pass:${passId}`,
      value: passId,
    });
  } catch (e) {
    Logger.error("dash_user_selected_pass event failed to fire");
  }
};

export const dashResetSelectedPass = (): void => {
  try {
    gtag("event", "dash_user_reset_pass_selection", {
      event_category: "dash_events",
      event_label: "pass_reset",
    });
    Logger.log("Sent dash_user_reset_pass_selection to analtyics");
    Logger.log({
      event_category: "dash_events",
      event_label: "pass_reset",
    });
  } catch (e) {
    Logger.error("dash_user_reset_pass_selection event failed to fire");
  }
};

export const triggerDashSeasonPassActivationAnalyticsEvent = (passId: string, userId: string | number): void => {
  try {
    gtag("event", "conversion", {
      send_to: "AW-345413332/Dpx_CNyhjOYCENSt2qQB",
    });
    gtag("event", "conversion", {
      send_to: "AW-345413332/-Z8SCM6WhPMCENSt2qQB",
      transaction_id: `P:${passId}|U:${userId}`,
    });
  } catch (e) {
    Logger.error(e);
  }

  // const rid = localStorage.getItem(SessionStorageKeys.REDDIT_CAMPAIGN_REF);
  // const gclid = localStorage.getItem(SessionStorageKeys.GOOGLE_CAMPAIGN_REF);
  // const fbclid = localStorage.getItem(SessionStorageKeys.FB_CAMPAIGN_REF);
  // const twtr = localStorage.getItem(SessionStorageKeys.TWITTER_CAMPAIGN_REF);

  registerSeasonPassActivationRef(passId, true);

  // if (fbclid) {
  //   registerFBSeasonPassActivationEvent(passId, fbclid, true);
  // } else if (gclid) {
  //   registerGoogleSeasonPassActivationEvent(passId, gclid, true);
  // } else if (rid) {
  //   registerRedditSeasonPassActivationEvent(passId, rid, true);
  // } else if (twtr) {
  //   registerTwitterSeasonPassActivationEvent(passId, twtr, true);
  // } else {
  //   registerSeasonPassActivationRef(passId, true);
  // }
};
