/* eslint-disable prefer-arrow/prefer-arrow-functions */
//Route imports won't work as arrow functions

import { environment } from "@environments/environment";

import { ModuleRouteParams, ComponentRouteParams } from "./routeParams";

export const routeModifiers = {
  EDIT: "edit",
  ROSTER: "roster",
  SCHEDULE: "schedule",
  LEAGUES: "leagues",
  LIST: "list",
  SET_SCHEDULE: "setschedule",
  CREATE: "create",
  RESET: "reset",
  REGISTER_SUCCESS: "registerSuccess",
  SEARCH: "search",
  NEW: "new",
  INVITE: "invite",
  PASSES: "passes",
  PASS_SUCCESS: "pass_success",
  CHECKIN: "checkin",
  QUEUE_SUCCESS: "queuematch",
};

export const routeRoots = {
  MATCH: "match",
  USER: "user",
  TEAMS: "teams",
  LOGIN: "login",
  PASSWORD: "password",
  LEAGUES: "leagues",
  DASHBOARD: "dashboard",
  PROFILE: "profile",
  NOTIFICATIONS: "notifications",
  MY_TEAMS: "myteams",
  ABOUT: "about",
  PAYMENTS: "payments",
  NO_PERMISSIONS: "nopermissions",
  REFERRALS: "referrals",
  SIGN_UP: "sign_up",
  SIGNUP: "signup",
  CONTACT: "contact",
  RULES: "rules",
  FAQ: "faq",
  PRIVACY: "privacy",
  HOME: "",
  SERIES_MATCHUP: "series_matchup",
  ORGANIZATION: "organization",
  WELCOME: "welcome",
  PARENTS: "parents",
  SCRIMMAGE: "scrimmages",
  ESPORTS_PASS: "esports_pass",
  CLUBS: "clubs",
};

export const routeQueries = {
  OPEN_MAX_LEAGUE_SEARCH: {
    limit: 50,
    open: true,
    page: 1,
  },
};

export function generateLeagueListTypeRoute(leagueList: string): string {
  return `${routeRoots.LEAGUES}/${routeModifiers.LIST}/${leagueList}`;
}
export function generateLeagueDetailRoute(leagueId: string | number): string {
  return `${routeRoots.LEAGUES}/${leagueId}`;
}

export function generateLeagueDetailCheckinRoute(leagueId: string | number, queueId: string | number): string {
  return `${routeRoots.LEAGUES}/${leagueId}/${routeModifiers.CHECKIN}/${queueId}`;
}

export function generateLeagueDetailPassActivationRoute(leagueId: string, passId: string): string {
  return `${routeRoots.LEAGUES}/${leagueId}/${routeModifiers.PASS_SUCCESS}/${passId}`;
}

export function generateLeagueScheduleRoute(leagueId: string | number): string {
  return `${routeRoots.LEAGUES}/${leagueId}/${routeModifiers.SCHEDULE}`;
}
export function generateLeagueEditRoute(leagueId: string): string {
  return `${routeRoots.LEAGUES}/${leagueId}/${routeModifiers.EDIT}`;
}
export function generateDashboardLeagueDetailRoute(leagueId: string) {
  return `${routeRoots.DASHBOARD}/${routeModifiers.LEAGUES}/${leagueId}`;
}
export function generateUserDetailRoute(userId: string) {
  return `${routeRoots.USER}/${userId}`;
}
export function generateLeagueSeasonPassesRoute(leagueId: string | number) {
  return `${routeRoots.LEAGUES}/${leagueId}/${routeModifiers.PASSES}`;
}
// TEAM ROUTES
export function generateTeamDetailRoute(teamId: string): string {
  return `${routeRoots.TEAMS}/${teamId}`;
}
export function generateTeamRosterRoute(teamId: string) {
  return `${routeRoots.TEAMS}/${teamId}/${routeModifiers.ROSTER}`;
}
export function generateTeamLeaguesRoute(teamId: string) {
  return `${routeRoots.TEAMS}/${teamId}/${routeModifiers.LEAGUES}`;
}
export function generateTeamEditRoute(teamId: string) {
  return `${routeRoots.TEAMS}/${teamId}/${routeModifiers.EDIT}`;
}
// export function generateTeamScheduleRoute(teamId: string) {
// 	return `${routeRoots.TEAMS}/${teamId}/${routeModifiers.SCHEDULE}`;
// }
export function generateTeamInstantInviteRoute(inviteCode: string) {
  return `${routeRoots.TEAMS}/${routeModifiers.INVITE}/${inviteCode}`;
}
// END TEAM ROUTES
export function generateMatchDetailRoute(matchId: string) {
  return `${routeRoots.MATCH}/${matchId}`;
}
export function generateSeriesDetailRoute(matchId: string) {
  return `${routeRoots.SERIES_MATCHUP}/${matchId}`;
}
export function generateScrimmageRoute(scrimmageId: string): string {
  return `${routeRoots.SCRIMMAGE}/${scrimmageId}`;
}
export function generateLoginRedirectRoute(redirect: string) {
  return `${routeRoots.LOGIN}/${redirect}`;
}
export function generateCreatePasswordRoute(token: string) {
  return `${routeRoots.PASSWORD}/${routeModifiers.CREATE}/${token}`;
}
export function generateSeriesMatchupRoute(seriesMatchupId: string) {
  return `${routeRoots.SERIES_MATCHUP}/${seriesMatchupId}`;
}
export function generateSeriesMatchupRouteFromQueue(seriesMatchupId: string) {
  return `${routeRoots.SERIES_MATCHUP}/${seriesMatchupId}/${routeModifiers.QUEUE_SUCCESS}`;
}
export function generateOrganizationSlugRoute(slug: string) {
  return `${routeRoots.ORGANIZATION}/${slug}`;
}
export function generateClubSlugRoute(slug: string) {
  return `${routeRoots.CLUBS}/${slug}`;
}
export function generateOrganizationSlugPassSuccessRoute(slug: string, passId: string) {
  return `${routeRoots.ORGANIZATION}/${slug}/${routeModifiers.PASS_SUCCESS}/${passId}`;
}
export function generateClubSlugPassSuccessRoute(slug: string, passId: string) {
  return `${routeRoots.CLUBS}/${slug}/${routeModifiers.PASS_SUCCESS}/${passId}`;
}
export function generateEsportPassRoute(esportPassId: string): string {
  return `${routeRoots.ESPORTS_PASS}/${esportPassId}`;
}
export function generateEsportPassSuccessRoute(esportPassId: string): string {
  return `${routeRoots.ESPORTS_PASS}/${esportPassId}/payment_success`;
}
export function generateDashboardPassSuccessRoute(esportPassId: string | number): string {
  return `${routeRoots.DASHBOARD}/${routeModifiers.PASS_SUCCESS}/${esportPassId}`;
}
export function generateRulebookRoute(slug: string): string {
  return `${routeRoots.RULES}/${slug}`;
}

export const routePaths = {
  HOME: `${routeRoots.HOME}`,
  LOGIN: `${routeRoots.LOGIN}`,
  LOGIN_EXPIRED: `${routeRoots.LOGIN}/reauth`,
  LOGIN_REDIRECT: generateLoginRedirectRoute(ModuleRouteParams.REDIRECT),
  LOGIN_SIGNUP_SUCCESS: `${routeRoots.LOGIN}/${routeModifiers.REGISTER_SUCCESS}`,
  NEW_USER_LANDING: `${routeRoots.WELCOME}`,
  SIGNUP: `${routeRoots.SIGNUP}`,
  SIGN_UP: `${routeRoots.SIGN_UP}`,
  PASSWORD_RESET: `${routeRoots.PASSWORD}/${routeModifiers.RESET}`,
  PASSWORD_CREATE: generateCreatePasswordRoute(ModuleRouteParams.PASSWORD_TOKEN),
  ABOUT: `${routeRoots.ABOUT}`,
  CONTACT: `${routeRoots.CONTACT}`,
  RULES: `${routeRoots.RULES}`,
  FAQ: `${routeRoots.FAQ}`,
  PRIVACY: `${routeRoots.PRIVACY}`,
  LEAGUES: `${routeRoots.LEAGUES}`,
  LEAGUE_NEW: `${routeRoots.LEAGUES}/${routeModifiers.NEW}`,
  LEAGUES_LIST_TYPE: generateLeagueListTypeRoute(ModuleRouteParams.LEAGUE_TYPE),
  LEAGUE_DETAIL: generateLeagueDetailRoute(ModuleRouteParams.LEAGUE_ID),
  LEAGUE_DETAIL_PASS_SUCCESS: generateLeagueDetailPassActivationRoute(ModuleRouteParams.LEAGUE_ID, ModuleRouteParams.ESPORT_PASS_ID),
  LEAGUE_DETAIL_CHECKIN: generateLeagueDetailCheckinRoute(ModuleRouteParams.LEAGUE_ID, ModuleRouteParams.QUEUE_ID),
  LEAGUE_SCHEDULE: generateLeagueScheduleRoute(ModuleRouteParams.LEAGUE_ID),
  LEAGUE_EDIT: generateLeagueEditRoute(ModuleRouteParams.LEAGUE_ID),
  LEAGUES_SEARCH: `${routeRoots.LEAGUES}/${routeModifiers.SEARCH}`,
  LEAGUES_PASSES: generateLeagueSeasonPassesRoute(ModuleRouteParams.LEAGUE_ID),
  DASHBOARD_LEAGUE_ID: generateDashboardLeagueDetailRoute(ModuleRouteParams.LEAGUE_ID),
  DASHBOARD: `${routeRoots.DASHBOARD}`,
  PROFILE: `${routeRoots.PROFILE}`,
  PROFILE_EDIT: `${routeRoots.PROFILE}/${routeModifiers.EDIT}`,
  USER_DETAIL: generateUserDetailRoute(ModuleRouteParams.USER_ID),
  NOTIFICATIONS: `${routeRoots.NOTIFICATIONS}`,
  REFERRALS: `${routeRoots.REFERRALS}`,
  TEAM_NEW: `${routeRoots.TEAMS}/${routeModifiers.NEW}`,
  TEAM_DETAIL: generateTeamDetailRoute(ModuleRouteParams.TEAM_ID),
  TEAM_ROSTER: generateTeamRosterRoute(ModuleRouteParams.TEAM_ID),
  TEAM_LEAGUES: generateTeamLeaguesRoute(ModuleRouteParams.TEAM_ID),
  TEAM_EDIT: generateTeamEditRoute(ModuleRouteParams.TEAM_ID),
  // TEAM_SCHEDULE: generateTeamScheduleRoute(ModuleRouteParams.TEAM_ID),
  TEAM_INSTANT_INVITE: generateTeamInstantInviteRoute(ModuleRouteParams.INSTANT_INVITE),
  MY_TEAMS: `${routeRoots.MY_TEAMS}`,
  TEAMS: `${routeRoots.TEAMS}`,
  MATCH_DETAIL: generateMatchDetailRoute(ModuleRouteParams.MATCH_ID),
  SERIES_MATCHUP: generateSeriesMatchupRoute(ModuleRouteParams.SERIES_MATCHUP_ID),
  SERIES_MATCHUP_QUEUE_SUCCESS: generateSeriesMatchupRouteFromQueue(ModuleRouteParams.SERIES_MATCHUP_ID),
  SCRIMMAGE: generateScrimmageRoute(ModuleRouteParams.SCRIMMAGE_ID),
  PAYMENTS: `${routeRoots.PAYMENTS}`,
  NO_PERMISSIONS: `${routeRoots.NO_PERMISSIONS}`,
  ORGANIZATION: generateOrganizationSlugRoute(ModuleRouteParams.ORGANIZATION_SLUG),
  CLUB: generateClubSlugRoute(ModuleRouteParams.CLUB_SLUG),
  CLUB_PASS_SUCCESS: generateClubSlugPassSuccessRoute(ModuleRouteParams.CLUB_SLUG, ModuleRouteParams.ESPORT_PASS_ID),
  ORGANIZATION_PASS_SUCCESS:
    generateOrganizationSlugPassSuccessRoute(ModuleRouteParams.ORGANIZATION_SLUG, ModuleRouteParams.ESPORT_PASS_ID),
  ORGANIZATION_SEARCH: `${routeRoots.ORGANIZATION}/${routeModifiers.SEARCH}`,
  ORGANIZATION_SEARCH_ALT: `${routeRoots.ORGANIZATION}s`,
  CLUB_SEARCH: `${routeRoots.CLUBS}/${routeModifiers.SEARCH}`,
  CLUB_SEARCH_ALT: `${routeRoots.CLUBS}`,
  PARENTS: routeRoots.PARENTS,
  ESPORT_PASS_DETAILS: generateEsportPassRoute(ModuleRouteParams.ESPORT_PASS_ID),
  ESPORT_PASS_PAYMENTS_SUCCESS: generateEsportPassSuccessRoute(ModuleRouteParams.ESPORT_PASS_ID),
  RULEBOOK: generateRulebookRoute(ModuleRouteParams.RULEBOOK_ID),
  DASHBOARD_PASS_SUCCESS: generateDashboardPassSuccessRoute(ModuleRouteParams.ESPORT_PASS_ID),
  NOT_FOUND: "**",
};

//Break routes into sub consts

export const leagueRoutes = {
  LEAGUES: `${routeRoots.LEAGUES}`,
  LEAGUE_NEW: `${routeRoots.LEAGUES}/${routeModifiers.NEW}`,
  LEAGUES_LIST_TYPE: generateLeagueListTypeRoute(ModuleRouteParams.LEAGUE_TYPE),
  LEAGUE_DETAIL: generateLeagueDetailRoute(ModuleRouteParams.LEAGUE_ID),
  LEAGUE_SCHEDULE: generateLeagueScheduleRoute(ModuleRouteParams.LEAGUE_ID),
  LEAGUE_EDIT: generateLeagueEditRoute(ModuleRouteParams.LEAGUE_ID),
  LEAGUES_PASSES: generateLeagueSeasonPassesRoute(ModuleRouteParams.LEAGUE_ID),
};

export const dashboardRoutes = {
  DASHBOARD_LEAGUE_ID: generateDashboardLeagueDetailRoute(ModuleRouteParams.LEAGUE_ID),
  DASHBOARD: `${routeRoots.DASHBOARD}`,
};

export const userRoutes = {
  PROFILE: `${routeRoots.PROFILE}`,
  PROFILE_EDIT: `${routeRoots.PROFILE}/${routeModifiers.EDIT}`,
  USER_DETAIL: generateUserDetailRoute(ModuleRouteParams.USER_ID),
  PAYMENTS: `${routeRoots.PAYMENTS}`,
  LOGIN: `${routeRoots.LOGIN}`,
  LOGIN_REDIRECT: generateLoginRedirectRoute(ComponentRouteParams.LOGIN_REDIRECT),
  LOGIN_SIGNUP_SUCCESS: `${routeRoots.LOGIN}/${routeModifiers.REGISTER_SUCCESS}`,
  PASSWORD_RESET: `${routeRoots.PASSWORD}/${routeModifiers.RESET}`,
  PASSWORD_CREATE: generateCreatePasswordRoute(ModuleRouteParams.PASSWORD_TOKEN),
  NOTIFICATIONS: `${routeRoots.NOTIFICATIONS}`,
};

export const teamRoutes = {
  TEAM_NEW: `${routeRoots.TEAMS}/${routeModifiers.NEW}`,
  TEAM_DETAIL: generateTeamDetailRoute(ModuleRouteParams.TEAM_ID),
  TEAM_ROSTER: generateTeamRosterRoute(ModuleRouteParams.TEAM_ID),
  TEAM_LEAGUES: generateTeamLeaguesRoute(ModuleRouteParams.TEAM_ID),
  TEAM_EDIT: generateTeamEditRoute(ModuleRouteParams.TEAM_ID),
  // TEAM_SCHEDULE: generateTeamScheduleRoute(ModuleRouteParams.TEAM_ID),
  MY_TEAMS: `${routeRoots.MY_TEAMS}`,
  TEAMS: `${routeRoots.TEAMS}`,
};

export const matchRoutes = {
  MATCH_DETAIL: generateMatchDetailRoute(ModuleRouteParams.MATCH_ID),
  SERIES_MATCHUP: generateSeriesMatchupRoute(ModuleRouteParams.SERIES_MATCHUP_ID),
  SERIES_MATCHUP_QUEUE_SUCCESS: generateSeriesMatchupRouteFromQueue(ModuleRouteParams.SERIES_MATCHUP_ID),
  SCRIMMAGE: generateScrimmageRoute(ModuleRouteParams.SCRIMMAGE_ID),
};

export const staticRoutes = {
  HOME: `${routeRoots.HOME}`,
  SIGNUP: `${routeRoots.SIGNUP}`,
  SIGN_UP: `${routeRoots.SIGN_UP}`,
  ABOUT: `${routeRoots.ABOUT}`,
  CONTACT: `${routeRoots.CONTACT}`,
  RULES: `${routeRoots.RULES}`,
  FAQ: `${routeRoots.FAQ}`,
  PRIVACY: `${environment.staticSiteUrl}/privacy`,
  TERMS: `${environment.staticSiteUrl}/terms-and-conditions`,
  NO_PERMISSIONS: `${routeRoots.NO_PERMISSIONS}`,
  WELCOME: `${routeRoots.WELCOME}`,
  ORGANIZATION_SEARCH: `${routeRoots.ORGANIZATION}/${routeModifiers.SEARCH}`,
  PARENTS: routeRoots.PARENTS,
  NEW_USER_LANDING: `${routeRoots.WELCOME}`,
  NOT_FOUND: "**",
  LOGIN_REDIRECT: `${routeRoots.LOGIN}/redirect`,
};

export const externalRoutes = {
  PRIVACY: `${environment.staticSiteUrl}/privacy`,
  TERMS: `${environment.staticSiteUrl}/terms-and-conditions`,
};
