<div class="c_newUserDash">
  <header class="c_newUserDash__header">
    <div class="c_newUserDash__header__textCopy">
      <h2 class="u_txt--hdg2">
        Welcome to GGLeagues
        <br />
        Good Luck and Have Fun!
      </h2>
      <p>
        GGLeagues is building a world of Good Gamers, and we are glad to see you
        here!
      </p>
    </div>
    <div class="c_newUserDash__header__imgContainer">
      <img src="/assets/images/dashboard/dash-header-image.svg" />
    </div>
  </header>
  <section aria-labelledby="getReady" class="c_newUserDash__getReady">
    <h3 id="getReady" class="u_txt--hdg3">Get Ready to Start Gaming</h3>
    <p>
      When you become a good gamer you gain access to a wide variety of exciting
      esport programs and events!
    </p>
    <div class="c_leagueCarouselsContainer">
      <div>
        <app-league-carousel-feature
          [leaguesCategory]="'weeklies'"
          [gameCards]="weeklyCarouselCards"
        >
        </app-league-carousel-feature>
      </div>
      <div>
        <app-league-carousel-feature
          [leaguesCategory]="'rec'"
          [gameCards]="recCarouselCards"
          [userOwnsPass]="userAlreadyHasPass$ | async"
        >
        </app-league-carousel-feature>
      </div>
    </div>
  </section>
  <section aria-labelledby="faq-header" class="c_newUserDash__faq">
    <h3 id="faq-header" class="u_txt--hdg3">Frequently Asked Questions</h3>
    <ul class="c_newUserDash__faq__questionsContainer">
      <li class="c_newUserDash__faq__question" *ngFor="let faqItem of faqItems">
        <h4 class="u_txt--hdg4">{{ faqItem.title }}</h4>
        <p>{{ faqItem.copy }}</p>
        <a [href]="faqItem.link" target="_blank">View More</a>
      </li>
    </ul>
  </section>
</div>
