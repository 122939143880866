import { Injectable } from "@angular/core";
import {
  Actions,
  Effect,
  ofType,
} from "@ngrx/effects";
import {
  map,
  mergeMap,
  catchError,
  take,
  switchMap,
} from "rxjs/operators";
import { of } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { Store } from "@ngrx/store";

import {
  LeagueActionTypes,
  GetLeagueById,
  GetLeagueByIdSuccess,
  GetLeagueByIdError,
  GetQuickPlayEventByLeagueID,
  GetQuickPlayEventByLeagueIDError,
  GetQuickPlayEventByLeagueIDSuccess,
  GetLeagueBrackets,
  GetLeagueBracketsError,
  UpdateLeagueBrackets,
} from "./league.actions";
import { RootState } from "..";
import { LeaguesV2Service } from "@services/v2/leagues/leagues.v2.service";
import { QuickPlayEventsService } from "@services/quick-play-events/quick-play-events.service";
import { Logger } from "@utils/logger";
import { QuickPlayEvent } from "@apptypes/quick-play-events.types";
import { LeagueBracketDetails } from "./league.types";

@Injectable()
export class LeagueEffects {
  @Effect()
  public league$ = this._actions$.pipe(
    ofType<GetLeagueById>(LeagueActionTypes.GET_LEAGUE_BY_ID),
    map((action) => action.payload),
    mergeMap((payload) => this._store.select("user", "currentUser").pipe(
      take(1),
      map((currentUser) => (currentUser !== null)),
      mergeMap((isAuth) => {
        if (isAuth) {
          return this._leagueV2Service.getLeagueDetailsAuthenticated(payload);
        } else {
          return this._leagueV2Service.getLeagueDetailsUnauthenticatede(payload);
        }
      })
    )),
    mergeMap((league) => {
      if (league) {
        return of(new GetLeagueByIdSuccess(league));
      } else {
        this._toastr.error("The league could not be retrieved", "Error");
        return of(new GetLeagueByIdError());
      }
    }),
    catchError(() => {
      this._toastr.error("The league could not be retrieved", "Error");
      return of(new GetLeagueByIdError());
    })
  );

  @Effect()
  public quickPlayEvent$ = this._actions$.pipe(
    ofType<GetQuickPlayEventByLeagueID>(LeagueActionTypes.GET_QUICK_PLAY_EVENT_BY_LEAGUE_ID),
    map(action => action.payload),
    switchMap((leagueID) => this._quickPlayEventsService.getQuickPlayEvent(leagueID).pipe(
      catchError((err) => {
        Logger.error(err);
        this._toastr.error("The QuickPlayEvent could not be retrieved", "Error");
        return of(null);
      })
    )),
    map((quickPlayEvents: QuickPlayEvent[] | null = []) => {
      if(quickPlayEvents === null) {
        return new GetQuickPlayEventByLeagueIDError();
      }

      if(quickPlayEvents.length === 0) {
        return new GetQuickPlayEventByLeagueIDError();
      }

      return new GetQuickPlayEventByLeagueIDSuccess(quickPlayEvents[0]);
    })
  );

  @Effect()
  public leagueBrackets$ = this._actions$.pipe(
    ofType<GetLeagueBrackets>(LeagueActionTypes.GET_LEAGUE_BRACKETS),
    map(action => action.payload),
    switchMap((leagueID) => this._leagueV2Service.getLeagueTournaments(leagueID).pipe(
      catchError((err) => {
        Logger.error(err);
        return of(null);
      })
    )),
    map((leagueBrackets: LeagueBracketDetails[] | null) => {
      if(leagueBrackets === null){
        return new GetLeagueBracketsError();
      }

      return new UpdateLeagueBrackets(leagueBrackets);
    })
  );

  constructor(
    private _actions$: Actions,
    private _leagueV2Service: LeaguesV2Service,
    private _quickPlayEventsService: QuickPlayEventsService,
    private _toastr: ToastrService,
    private _store: Store<RootState>
  ) { }
}
