import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  map,
  filter,
  take,
} from "rxjs/operators";
import { Observable } from "rxjs";

import { GenericTeamLeagueData } from "src/app/reducers/teams/teams.types";
import { RootState } from "src/app/reducers";
import { processImage, ImageTypes } from "../../util/image-utils";
import { generateTeamLeaguesRoute, generateLeagueDetailRoute } from "src/app/enums/routes/routePaths";

@Component({
  selector: "app-league-history-block",
  templateUrl: "./league-history-block.component.html",
  styleUrls: ["./league-history-block.component.scss"],
})
export class LeagueHistoryBlockComponent implements OnInit {
  public activeLeagues$: Observable<GenericTeamLeagueData[]>;
  public pastLeagues$: Observable<GenericTeamLeagueData[]>;
  public teamId$: Observable<number>;
  public leagueImage: string;

  constructor(private _store: Store<RootState>) {}

  public ngOnInit() {
    this.teamId$ = this._store.select("teams", "currentTeam").pipe(
      filter((team) => team !== null),
      map((currentTeam) => {
        if (currentTeam) {
          return currentTeam.id;
        }

        return null;
      }),
      take(1)
    );

    this.activeLeagues$ = this._store.select("teams", "currentTeam").pipe(
      filter((team) => team !== null),
      map((team) =>
        team.leagues
          .filter((league) => !league.finished)
          .sort((a, b) => {
            if (a.startTime > b.startTime) {
              return -1;
            }

            return 1;
          })
      ),
      take(1)
    );

    this.pastLeagues$ = this._store.select("teams", "currentTeam").pipe(
      filter((team) => team !== null),
      map((team) =>
        team.leagues
          .filter((league) => league.finished)
          .sort((a, b) => {
            if (a.startTime > b.startTime) {
              return -1;
            }

            return 1;
          })
          .slice(0, 3)
      ),
      take(1)
    );

    this.activeLeagues$.pipe(take(1)).subscribe((leagues) => {
      if (leagues && leagues.length > 0) {
        const league = leagues[0];
        this.leagueImage = processImage(league.logoUrl, ImageTypes.LEAGUE_RECT);
      }
    });
  }

  public getTeamLeagueRoute(id: number): string {
    return generateTeamLeaguesRoute(id.toString());
  }

  public getLeagueDetailRoute(id: number): string {
    return generateLeagueDetailRoute(id.toString());
  }
}
