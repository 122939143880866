import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-utils";
import { ChatData } from "@apptypes/chat/chat-data.interface";

import { CommentValidityErrors } from "../comment-validator/comment-validator.types";
import { CommentService } from "../comment.abstract.service";
import { CloudComment } from "../comment.api.types";

@Injectable({
  providedIn: "any",
})
export class CloudSeriesMatchupCommentService extends CommentService {
  public postComment({ firestoreDocID: chatroomId }: ChatData, userId: string, message: string): Observable<unknown> {
    const url = `${environment.cloudAPIBase}/manageChat`;
    const options = {
      headers: basicAuthHeader(),
    };

    const messageError: false | CommentValidityErrors = this._commentValidator.isMessageInvalid(message);
    if (messageError) {
      this._logMessageError(messageError);
      return of(null);
    }

    const body = this._formatChatMessage(chatroomId, userId, message);

    return this._http.post(url, body, options);
  }

  protected _formatChatMessage(chatroomId: string, userId: string, message: string): CloudComment {
    return {
      chatroomId,
      userId,
      message,
    };
  }

}
