<div class="c_clubSearchHeroImage">
  <p class="u_txt--white u_txt--hdg2">
    Join your local esports club today to connect with local gamers and
    jumpstart your journey into the world of esports.
  </p>
  <img
    src="/assets/images/modal_images/about_modal/polar_bear-01.svg"
    alt="polar bear at computer"
  />
</div>
