/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { DashboardActions, DashboardActionTypes } from "./dashboard.actions";
import { DashboardState } from "./dashboard.types";

const initialState: DashboardState = {
  nextMatch: null,
  nextQueue: null,
  leagues: null,
  userClubs: [],
  isLoading: true,
  isError: false,
  updatedOn: null,
};

export function dashboardReducer(state = initialState, action: DashboardActions): DashboardState {
  switch (action.type) {
    case DashboardActionTypes.GET_GENERIC_DASHBOARD:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case DashboardActionTypes.GET_GENERIC_DASHBOARD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        isError: false,
      };
    case DashboardActionTypes.GET_GENERIC_DASHBOARD_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case DashboardActionTypes.CLEAR_DASHBOARD:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
