
import { Component, HostListener } from "@angular/core";
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
} from "@angular/router";
import { environment } from "@environments/environment";
import { registerAnonymousExit, registerUserExit } from "@utils/analytics/exit.analytics";
import { Logger } from "@utils/logger";
import { SessionStorageKeys } from "./enums/session-storage-keys.enum";

// Refernce to Google Analytics
// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;
// Refernce to FB Analytics
// eslint-disable-next-line @typescript-eslint/ban-types
declare let fbq: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    // ONLY ENABLES GA IN PRODUCTION
    if (environment.production) {
      this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          try {
            gtag("config", "UA-163436899-1", {
              page_path: event.urlAfterRedirects,
            });
            gtag("config", "AW-345413332", {
              page_path: event.urlAfterRedirects,
            });
            fbq("track", "PageView");
          } catch (e) {
            Logger.error(e);
          }
        }
      });
    }
    if (!environment.production) { // disables GA on local/staging:
      window["ga-disable-UA-163436899-1"] = true;
    }
  }

  // We watch on 'visibilitychange' as it more reliably tracks exit events
  // This will capture mobile users clicking the home button for example
  @HostListener("window:visibilitychange", ["$event"])
  public unloadHandler() {
    if (document.visibilityState === "hidden") {
      // First check if we need to skip the exit analytic due to a forced redirect
      const shouldSkipExitAnalytics = localStorage.getItem(SessionStorageKeys.IGNORE_EXIT_ANALYTIC) === "true";

      if (!shouldSkipExitAnalytics) {
        // Check to see if user has logged in at any point during this session
        const hasUserLoggedIn = localStorage.getItem(SessionStorageKeys.USER_HAS_LOGGED_IN) === "true";

        if (hasUserLoggedIn) {
          registerUserExit(window.location.href);
          localStorage.removeItem(SessionStorageKeys.USER_HAS_LOGGED_IN);
        } else {
          registerAnonymousExit(window.location.href);
        }
      } else {
        localStorage.removeItem(SessionStorageKeys.IGNORE_EXIT_ANALYTIC);
      }

    }
  }

  public onActivate(): void {
    if (this._router.url.indexOf("/clubs/search") === -1) {
      window.scrollTo(0, 0);
    }

    const ridref = this._activatedRoute.snapshot.queryParamMap.get("rid");
    const fbclidref = this._activatedRoute.snapshot.queryParamMap.get("fbclid");
    const gclidref = this._activatedRoute.snapshot.queryParamMap.get("gclid");
    const twtrRef = this._activatedRoute.snapshot.queryParamMap.get("twtr");
    const weeklyRef = this._activatedRoute.snapshot.queryParamMap.get("weeklyLink");

    if (weeklyRef) {
      localStorage.setItem(SessionStorageKeys.WEEKLY_NEWUSER_REDIRECT, weeklyRef);
    }

    if (ridref) {
      localStorage.setItem(SessionStorageKeys.REDDIT_CAMPAIGN_REF, ridref);
    }
    if (fbclidref) {
      localStorage.setItem(SessionStorageKeys.FB_CAMPAIGN_REF, fbclidref);
    }
    if (gclidref) {
      localStorage.setItem(SessionStorageKeys.GOOGLE_CAMPAIGN_REF, gclidref);
    }
    if (twtrRef) {
      localStorage.setItem(SessionStorageKeys.TWITTER_CAMPAIGN_REF, twtrRef);
    }
  }
}
