import {
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { of } from "rxjs";
import { Store } from "@ngrx/store";
import {
  catchError,
  map,
  filter,
  pluck,
  takeUntil,
  mergeMap,
} from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

import { NotificationsService } from "@services/notifications.service";

import { Notification, NotificationResourceTypes } from "src/app/reducers/matches/matches.types";
import { RootState } from "src/app/reducers";
import { Unsubscriber } from "src/app/util/unsubscriber";
import { unixTimeToFullWithTime } from "src/app/util/date-utils";
import { generateMatchDetailRoute, generateSeriesMatchupRoute } from "src/app/enums/routes/routePaths";

interface ChatNotificationGroup {
  chatTitle: string;
  messageCount: number;
  notificationIds: string[];
  resourceType: NotificationResourceTypes;
  matchId: string;
  latestTimestamp: number;
}

interface ChatNotificationMap {
  [matchId: string]: ChatNotificationGroup;
}

@Component({
  selector: "app-notifications-page",
  templateUrl: "./notifications-page.component.html",
  styleUrls: ["./notifications-page.component.scss"],
})
export class NotificationsPageComponent implements OnInit, OnDestroy {
  public notifications: Notification[];
  public notificationsMap: ChatNotificationGroup[] = [];
  public showHideNotificationStatus = "Show";

  public loading = true;
  public viewingReadNotifications = false;

  private _readThreshold: number;
  private _filterUpdates = false;
  private _unsub = new Unsubscriber();

  constructor(private _notificationService: NotificationsService, private _toastr: ToastrService, private _store: Store<RootState>) {}

  public ngOnInit() {
    this._store
      .select("user", "currentUser")
      .pipe(
        filter((user) => user !== null),
        pluck("id"),
        mergeMap((id) => this._notificationService.getUserNotifications(id)),
        map((notifs) => {
          if (!this._filterUpdates) {
            return notifs;
          }

          const currentReadNotifications = notifs.filter((notif) => notif.status === "read").length;

          if (currentReadNotifications >= this._readThreshold) {
            return notifs;
          }
          return null;
        }),
        filter((notifs) => notifs !== null),
        takeUntil(this._unsub.unsubEvent)
      )
      .subscribe((notifications) => {
        this._filterUpdates = false;

        const chatNotifGroups: ChatNotificationMap = {
        };

        this.notifications = notifications;

        this._readThreshold = notifications.filter((notif) => notif.status === "read").length;

        notifications.map((notification) => {
          if (!chatNotifGroups[notification.matchId] && notification.status === "unread") {
            const newNotifGroup: ChatNotificationGroup = {
              matchId: notification.matchId,
              chatTitle: notification.matchTitle,
              messageCount: 1,
              resourceType: notification.resourceType,
              latestTimestamp: notification.timestamp.seconds,
              notificationIds: [notification.notificationId],
            };
            chatNotifGroups[notification.matchId] = newNotifGroup;
          } else {
            if (notification.status === "unread") {
              chatNotifGroups[notification.matchId].messageCount++;
              chatNotifGroups[notification.matchId].notificationIds.push(notification.notificationId);
              if (chatNotifGroups[notification.matchId].latestTimestamp < notification.timestamp.seconds) {
                chatNotifGroups[notification.matchId].latestTimestamp = notification.timestamp.seconds;
              }
            }
          }
        });
        const groups = Object.values(chatNotifGroups);

        groups.sort((a, b) => {
          if (a.latestTimestamp > b.latestTimestamp) {
            return -1;
          } else if (a.latestTimestamp < b.latestTimestamp) {
            return 1;
          } else {
            return 0;
          }
        });

        this.notificationsMap = groups;
        this.loading = false;
      });
  }

  public ngOnDestroy() {
    this._unsub.kill();
  }

  public clearNotification(notification: ChatNotificationGroup) {
    this._filterUpdates = true;
    this._readThreshold += notification.notificationIds.length;

    this._notificationService
      .dismissNotifications(notification.notificationIds)
      .pipe(catchError((err) => of(err)))
      .subscribe((notificationResult) => {
        if (notificationResult.hasOwnProperty("success")) {
          this.notificationsMap = this.notificationsMap.filter((notifGroup) => notifGroup.matchId !== notification.matchId);

          this._toastr.success("Notifications Dismissed");
        } else {
          this._toastr.error("Could Not Dismiss Notifications");
        }
      });
  }

  public viewReadNotifications() {
    this.viewingReadNotifications = !this.viewingReadNotifications;
    if (this.viewingReadNotifications) {
      this.showHideNotificationStatus = "Hide";
    } else {
      this.showHideNotificationStatus = "Show";
    }
  }

  public dismissAll(): void {
    if (this.notificationsMap.length === 0) {
      return;
    }
    const ids = [];
    this.notificationsMap.map((notificationGroup) => {
      ids.push(...notificationGroup.notificationIds);
    });

    this._filterUpdates = true;
    this._readThreshold += ids.length;

    this._notificationService
      .dismissNotifications(ids)
      .pipe(catchError((err) => of(err)))
      .subscribe((notificationResult) => {
        if (notificationResult.hasOwnProperty("success")) {
          this.notificationsMap = [];

          this._toastr.success("Notifications Dismissed");
        } else {
          this._toastr.error("Could Not Dismiss Notifications");
        }
      });
  }

  public processDate(date: number): string {
    return unixTimeToFullWithTime(date);
  }

  public getMatchDetailRoute(notif: Notification): string {
    if (notif.resourceType === NotificationResourceTypes.SERIES) {
      return generateSeriesMatchupRoute(notif.matchId);
    }
    return generateMatchDetailRoute(notif.matchId);
  }
}
