<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
  [modalFooter]="footer"
></app-modal-container>
<ng-template #title>
  <app-modal-title>
    {{ sponsorPassModalProps?.seasonPass?.title || "Esports Season Pass" }}
  </app-modal-title>
</ng-template>
<ng-template #content>
  <section class="c_sponsorPassModal" *ngIf="sponsorPassModalProps">
    <i class="fas fa-laptop-code fa-5x c_sponsorPassModal__icon"></i>
    <ng-container
      *ngIf="!sponsorPassModalProps?.isPurchaseInfo; else puchaseCopy"
    >
      <form class="c_sponsorPassModal__form" [formGroup]="sponsorForm">
        <label for="sponsorCode" class="c_inputLabel"> Activation Code </label>
        <input
          id="sponsorCode"
          type="text"
          formControlName="sponsorCode"
          class="c_input"
          placeholder="Add your activation code here"
        />
      </form>
      <p class="u_txt">
        If you have already obtained an activation code from
        {{ sponsorPassModalProps.seasonPass.organizationName }}, you can enter
        it here to activate your esports pass. You will not be charged since you
        have already paid. <br /><br />If you need to purchase a pass, go back
        and select the purchase pass option
      </p>
    </ng-container>
    <ng-template #puchaseCopy>
      <p class="u_txt--body">
        This esports pass is being sold directly from
        {{ sponsorPassModalProps.seasonPass.organizationName }}
      </p>
      <p>
        If you need to purchase the pass through them, you can buy it through
        their website or by contacting
        {{ sponsorPassModalProps.seasonPass.organizationName }} directly.
      </p>
      <p>
        If you cannot find this information, reach out to
        <a
          href="
        mailto:support@ggleagues.com?subject=Need%20Help%20Purchasing%20Pass%20through%20my%20Organization!
        "
          >support@ggleagues.com</a
        >
        and we'll be happy to help!
      </p>
    </ng-template>
  </section>
</ng-template>
<ng-template #footer>
  <div class="c_sponsorPassModal__footer">
    <p class="u_txt c_input__error" *ngIf="hasError">
      Your activation code is not formatted correctly.
    </p>
    <p class="u_txt c_input__error" *ngIf="submissionError">
      {{ submissionError }}
    </p>
    <button
      *ngIf="!sponsorPassModalProps?.isPurchaseInfo"
      class="c_button primary_dark c_sponsorPassModal__submitButton"
      (click)="submit(sponsorForm)"
    >
      Redeem Sponsor Code
    </button>
  </div>
</ng-template>
