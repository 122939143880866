<ng-container *ngIf="!!leagueMatch">
  <a
    class="c_leagueMatchBlock"
    [routerLink]="leagueMatch.id | seriesMatchupRouter"
  >
    <app-date-box
      [date]="leagueMatch.gameStartTime"
      [isActive]="isDateActive"
      [isGrayedOut]="isGrayedOut"
    >
    </app-date-box>
    <div class="c_leagueMatchBlockDetails">
      <span class="c_leagueMatchBlockDetails__title">
        <ng-container
          *ngIf="
            leagueMatch.tournament.schedulingType !==
              schedulingTypes.BATTLE_ROYALE;
            else brFormat
          "
        >
          Round {{ leagueMatch.matchupMapping.round }} -
          {{ leagueMatch.tournament.schedulingType | enumTitle }}
        </ng-container>
        <ng-template #brFormat> Battle Royale </ng-template>
      </span>

      <span class="c_leagueMatchBlockDetails__time">
        <i class="far fa-clock"></i>
        {{ leagueMatch.gameStartTime | date: "shortTime" }}
      </span>
    </div>
    <span class="c_leagueMatchBlockDetails__matchup">
      <ng-container
        *ngIf="
          leagueMatch.teams.length === 2 &&
            leagueMatch.tournament.schedulingType !==
              schedulingTypes.BATTLE_ROYALE;
          else teamCount
        "
      >
        <span
          class="c_leagueMatchBlockDetails__matchup__teamOne"
          [ngClass]="{
            c_leagueMatchBlockDetails__matchup__teamWinner:
              leagueMatch.winnerId === leagueMatch.teams[0].id
          }"
        >
          <ng-container
            *ngIf="leagueMatch.winnerId === leagueMatch.teams[0].id"
          >
            <strong>Winner!</strong>
            <br />
          </ng-container>
          {{ leagueMatch.teams[0].title }}
        </span>
        <span class="c_leagueMatchBlockDetails__matchup__vsTag">vs</span>
        <span
          class="c_leagueMatchBlockDetails__matchup__teamTwo"
          [ngClass]="{
            c_leagueMatchBlockDetails__matchup__teamWinner:
              leagueMatch.winnerId === leagueMatch.teams[1].id
          }"
        >
          <ng-container
            *ngIf="leagueMatch.winnerId === leagueMatch.teams[1].id"
          >
            <strong>Winner!</strong>
            <br />
          </ng-container>
          {{ leagueMatch.teams[1].title }}</span
        >
      </ng-container>
    </span>
  </a>
</ng-container>

<ng-template #teamCount>
  <span
    *ngIf="
      leagueMatch.tournament.schedulingType === schedulingTypes.BATTLE_ROYALE
    "
    class="c_leagueMatchBlockDetails__matchup__teamCount"
    [ngClass]="{ c_leagueMatchBlock__greyText: isGrayedOut }"
    >Battle Royale ({{ leagueMatch.teams.length }} teams)</span
  >
  <span
    *ngIf="
      leagueMatch.tournament.schedulingType !== schedulingTypes.BATTLE_ROYALE
    "
    class="c_leagueMatchBlockDetails__matchup__teamCount"
    [ngClass]="{ c_leagueMatchBlock__greyText: isGrayedOut }"
    >TBD</span
  >
</ng-template>
