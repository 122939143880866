import { Injectable } from "@angular/core";
import { Token } from "@stripe/stripe-js";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

import { environment } from "@environments/environment";

import { basicAuthHeader, basicAuthHeaderWJSON } from "../util/auth-utils";

interface PaymentDataAPIResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      chargeSuccess: boolean;
      leagueId: number;
      leagueName: string;
      participationFeeAmount: string;
      createdAt: string;
      updatedAt: string;
    };
  }[];
}

interface PaymentDetailDataAPIResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      chargeSuccess: boolean;
      leagueId: number;
      leagueName: string;
      participationFeeAmount: string;
      receiptUrl: string;
      createdAt: string;
      updatedAt: string;
    };
  };
}

export interface Payment {
  id: number;
  chargeSuccess: boolean;
  leagueId: number;
  leagueName: string;
  fee: number;
  date: string;
  receiptUrl?: string;
}

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  constructor(private _http: HttpClient) { }

  public getUserPayments(): Observable<Payment[]> {
    const url = `${environment.apiBase}/api/v1/league_team_user_payments/`;
    const headers = basicAuthHeaderWJSON();

    return this._http
      .get<PaymentDataAPIResponse>(url, {
      headers,
    })
      .pipe(
        take(1),
        map((rawPaymentArray) => {
          const formattedPayments = rawPaymentArray.data.map((payment) => ({
            id: payment.attributes.id,
            chargeSuccess: payment.attributes.chargeSuccess,
            leagueId: payment.attributes.leagueId,
            leagueName: payment.attributes.leagueName,
            fee: +payment.attributes.participationFeeAmount,
            date: payment.attributes.createdAt,
          }));

          formattedPayments.sort((a, b) => {
            if (a.date > b.date) {
              return -1;
            } else if (a.date < b.date) {
              return 1;
            } else {
              return 0;
            }
          });

          return formattedPayments;
        })
      );
  }

  public getIndividualPayment(id: string | number): Observable<Payment> {
    const url = `${environment.apiBase}/api/v1/league_team_user_payments/${id}`;
    const headers = basicAuthHeaderWJSON();

    return this._http
      .get<PaymentDetailDataAPIResponse>(url, {
      headers,
    })
      .pipe(
        take(1),
        map((rawPaymentApiData) => {
          const { data } = rawPaymentApiData;
          return {
            id: data.attributes.id,
            chargeSuccess: data.attributes.chargeSuccess,
            leagueId: data.attributes.leagueId,
            leagueName: data.attributes.leagueName,
            fee: parseFloat(data.attributes.participationFeeAmount),
            receiptUrl: data.attributes.receiptUrl,
            date: data.attributes.createdAt,
          };
        })
      );
  }

  public processPayment(stripeToken: Token, paymentId: number): Observable<unknown> {
    const url = `${environment.apiBase}/api/v1/league_team_user_payments/process_payment`;
    const headers = basicAuthHeader();
    const payload = {
      payment_id: paymentId,
      stripeToken: stripeToken.id,
    };

    return this._http.post(url, payload, {
      headers,
    });
  }
}
