import { Nullish } from "@apptypes/generic-nullish.types";

import { staticRoutes } from "src/app/enums/routes/routePaths";

export type NullishID = Nullish<string | number>;

/**
 * A function to check if the id is nullish and either use the given route
 * or redirect to the not found page
 *
 * @param id
 * @param routeGenerateFn
 * @author Christian Tweed
 */
export const validatePipeRoute = (
  id: Nullish<string | number>,
  routeGenerateFn: (id: string) => string
): string[] => !!id ? [`/${routeGenerateFn(id.toString())}`] : [`/${staticRoutes.NOT_FOUND}`];
