<app-page>
  <div class="c_scrimmagePage" *ngIf="scrimmage$ | async as scrimmage">
    <div class="c_scrimmagePage__header">
      <h2 class="u_txt--white u_txt--hdg2">Scrimmage</h2>
      <h3 class="u_txt--white u_txt--hdg3">
        {{ scrimmage.startTime | date: "EEEE MMMM d, h:mma" }}
      </h3>
    </div>
    <div class="c_scrimmagePage__teamPreview">
      <app-team-preview-block
        [teamInfo]="scrimmage.teams[0]"
        [winnerId]="scrimmage.winnerId"
      >
      </app-team-preview-block>
      <strong class="u_txt">VS</strong>
      <app-team-preview-block
        [teamInfo]="scrimmage.teams[1]"
        [winnerId]="scrimmage.winnerId"
      >
      </app-team-preview-block>
    </div>
    <div class="c_scrimmagePage__roster">
      <app-matchup-roster-tables
        [teams]="scrimmage.teams"
        [esport]="scrimmage.esport"
      ></app-matchup-roster-tables>
    </div>
    <div class="c_scrimmagePage__chat">
      <h3 class="u_txt--hdg3">Scrimmage Chat</h3>
      <app-chat-block
        *ngIf="chatData$ | async as chatData"
        [chatData]="chatData"
        [teams]="scrimmage.teams"
      ></app-chat-block>
    </div>
  </div>
  <ng-container *ngIf="loading$ | async">
    <app-loader [useWhite]="true" #scrimmageLoading
      >Loading Scrimmage...</app-loader
    >
  </ng-container>
</app-page>
