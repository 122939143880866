import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
  ElementRef,
} from "@angular/core";
import {
  loadStripe,
  Stripe,
  Token,
} from "@stripe/stripe-js";
import {
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";

import { environment } from "@environments/environment";
import { PaymentService } from "@services/payment.service";

import { Logger } from "../../util/logger";
import { StatesAbbreviations } from "src/app/enums/states.enum";

@Component({
  selector: "app-integrated-checkout",
  templateUrl: "./integrated-checkout.component.html",
  styleUrls: ["./integrated-checkout.component.scss"],
  providers: [PaymentService],
})
export class IntegratedCheckoutComponent implements AfterViewInit, OnInit {
  @Input() public paymentItem: string;
  @Output() public paymentSuccess = new EventEmitter<Token>();

  public userInfoForm: FormGroup;
  public stripe: Stripe;
  public stateList = Object.keys(StatesAbbreviations);
  public statesAbbreviations = StatesAbbreviations;
  public isProcessing = false;
  public needsToScroll = false;

  constructor(private _elRef: ElementRef) {
    this.userInfoForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      addressLine1: new FormControl("", Validators.required),
      addressLine2: new FormControl(""),
      addressCity: new FormControl("", Validators.required),
      addressState: new FormControl(null, Validators.required),
    });
  }

  public async ngOnInit() {
    this.stripe = await loadStripe(environment.stripeApiKey);
    const elements = this.stripe.elements();
    // Custom styling can be passed to options when creating an Element.
    const style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: "16px",
        color: "#32325d",
      },
    };

    // Create an instance of the card Element.
    const card = elements.create("card", {
      style,
    });

    // Add an instance of the card Element into the `card-element` <div>.
    card.mount("#card-element");

    const stripeTokenHandler = (token: Token) => {
      // Insert the token ID into the form so it gets submitted to the server
      const paymentForm = document.getElementById("payment-form");
      const hiddenInput = document.createElement("input");
      hiddenInput.setAttribute("type", "hidden");
      hiddenInput.setAttribute("name", "stripeToken");
      hiddenInput.setAttribute("value", token.id);
      paymentForm.appendChild(hiddenInput);

      this.paymentSuccess.emit(token);
    };

    // Create a token or display an error when the form is submitted.
    const form = document.getElementById("payment-form");
    form.addEventListener("submit", async (event) => {
      event.preventDefault();
      if (this.userInfoForm.valid) {
        this.isProcessing = true;

        const { token, error } = await this.stripe.createToken(card, {
          name: this.userInfoForm.get("name").value,
          address_line1: this.userInfoForm.get("addressLine1").value,
          address_line2: this.userInfoForm.get("addressLine2").value,
          address_city: this.userInfoForm.get("addressCity").value,
          address_state: this.userInfoForm.get("addressState").value,
        });

        if (error) {
          // Inform the customer that there was an error.
          const errorElement = document.getElementById("card-errors");
          errorElement.textContent = error.message;
          Logger.error(error);
          this.isProcessing = false;
        } else {
          // Send the token to your server.
          stripeTokenHandler(token);
        }
      } else {
        // Inform the customer that there was an error.
        this.userInfoForm.markAllAsTouched();
        const errorElement = document.getElementById("card-errors");
        errorElement.textContent = "Billing information is invalid or incomplete";
      }
    });
  }

  public ngAfterViewInit(): void {
    const paymentBtn = this._elRef.nativeElement.querySelector("#paymentBtn");

    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    const observer = new IntersectionObserver((entries) => {
      if(entries[0].isIntersecting === true) {
        this.needsToScroll = false;
      } else {
        this.needsToScroll = true;
      }
    }, {
      threshold: [1],
    });

    observer.observe(paymentBtn);
  }

  public get name() {
    return this.userInfoForm.get("name");
  }
  public get addressLine1() {
    return this.userInfoForm.get("addressLine1");
  }
  public get addressCity() {
    return this.userInfoForm.get("addressCity");
  }
  public get addressState() {
    return this.userInfoForm.get("addressState");
  }
}
