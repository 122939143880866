import { Component } from "@angular/core";

import { staticRoutes } from "src/app/enums/routes/routePaths";

@Component({
  selector: "app-need-help-button",
  templateUrl: "./need-help-button.component.html",
  styleUrls: ["./need-help-button.component.scss"],
})
export class NeedHelpButtonComponent {
  public staticRoutes = staticRoutes;
}
