import {
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import {
  Observable,
  of,
  zip,
} from "rxjs";
import {
  mergeMap,
  filter,
  first,
  tap,
  catchError,
} from "rxjs/operators";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";

import { RootState } from "src/app/reducers";
import { Logger as logger } from "../../util/logger";
import {
  PersonalUserProfile,
  UserProfile,
  UserTeam,
} from "src/app/reducers/user/user.types";
import { ClearFetchedUser, FetchProfileById } from "src/app/reducers/user/user.actions";
import { routeRoots, staticRoutes } from "src/app/enums/routes/routePaths";
import { TeamTypes } from "src/app/reducers/teams/teams.types";

@Component({
  selector: "app-user-page",
  templateUrl: "./user-page.component.html",
  styleUrls: ["./user-page.component.scss"],
})
export class UserPageComponent implements OnInit, OnDestroy {
  public isProfile$: Observable<boolean> = of(false);
  public userProfile$: Observable<UserProfile | PersonalUserProfile>;
  public isMyProfile$: Observable<boolean> = of(true);
  public topThreeTeams: UserTeam[];
  public staticRoutes = staticRoutes;
  public teamTypes = TeamTypes;

  constructor(private _store: Store<RootState>, private _titleService: Title, private _activatedRoute: ActivatedRoute) {}

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | User Profile");

    zip(this._activatedRoute.url, this._store.select("user", "currentUser"), this._activatedRoute.params).
      pipe(
        filter(([urlSegments, currentUser]) => !!urlSegments && !!currentUser),
        mergeMap(([
          urlSegments,
          currentUser,
          { userid },
        ]) => {
          const url = urlSegments[0];
          switch(url.path) {
            case routeRoots.USER:
              if(userid === currentUser.id.toString()){
                this.isMyProfile$ = of(true);
                return of(currentUser);
              }
              this.isMyProfile$ = of(false);
              this._store.dispatch(new FetchProfileById(+userid));
              return this._store.select("user", "browsingUser");
            case routeRoots.PROFILE:
              this.isMyProfile$ = of(true);
              return of(currentUser);
            default:
              throw new Error("Bad URL for User Page");
          }
        }),
        filter(user => !!user),
        tap((user) => {
          this.userProfile$ = of(user);
        }),
        first(),
        catchError((err) => {
          logger.error(err);
          return of(null); //need to return null
        })
      ).subscribe();
  }

  public ngOnDestroy() {
    this._store.dispatch(new ClearFetchedUser());
  }

  public sortTeams(teams: UserTeam[]): UserTeam[] {
    //Need this to prevent read-only issues
    const newTeams = teams.filter((team) => team.teamType === this.teamTypes.STANDARD);
    const accountTeam = teams.find((team) => team.teamType === this.teamTypes.ACCOUNT_SOLO_TEAM);
    newTeams.sort((a, b) => {
      if (a.id < b.id) {
        return 1;
      } else if (a.id > b.id) {
        return -1;
      } else {
        return 0;
      }
    });
    return [accountTeam, ...newTeams.slice(0, 2)];
  }
}
