import {
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Observable } from "rxjs";
import {
  map,
  take,
  takeUntil,
} from "rxjs/operators";

import { MatchesService } from "@services/matches/matches.service";
import { QuickPlayEventsService } from "@services/quick-play-events/quick-play-events.service";
import { EsportUserCredentialsAttributes } from "@services/user/user.api.types";
import { Logger } from "@utils/logger";
import { Unsubscriber } from "@utils/unsubscriber";

import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import { RootState } from "src/app/reducers";
import { UpdateEsportCredentials } from "src/app/reducers/user/user.actions";
import { EsportUserCredential } from "src/app/reducers/user/user.types";
import { ModalController } from "../modal-controller/modal-controller.abstract";

enum CheckinSteps {
  UNKNOWN = "unknown",
  ADD_CREDENTIALS = "add_esport_credential",
  EDIT_CREDENTIALS = "edit_esport_credential",
  CHECK_IN = "check_in",
}

export interface QuickPlayCheckinInfo {
  esport: gamePlatforms;
  quickPlayEventID: string;
  teamId: string;
  type: "tournament";
}

export interface BRCheckinInfo {
  gameId: number;
  seriesId: string;
  teamId: string;
  esport: gamePlatforms;
  type: "seriesMatchup";
}

@Component({
  selector: "app-battle-royale-checkin-modal",
  templateUrl: "./battle-royale-checkin-modal.component.html",
  styleUrls: ["./battle-royale-checkin-modal.component.scss"],
})
export class BattleRoyaleCheckinModalComponent extends ModalController<BRCheckinInfo> implements OnInit, OnDestroy {
  public static MODAL_NAME = "brCheckinModal";
  // General props
  public stepEnum = CheckinSteps;
  public currentStep: CheckinSteps = CheckinSteps.UNKNOWN;
  public userEsportCredential$: Observable<EsportUserCredential>;
  // Add_Credentials Step props
  public credentialValue: EsportUserCredential;
  public credentialFormLoading = false;
  public credentialsFormHasError = false;
  public credentialsForm: FormGroup;
  public editCredentialsForm;
  //Game Id
  public checkinInfo: BRCheckinInfo | QuickPlayCheckinInfo;
  public gamePlatforms = gamePlatforms;
  //Error Props
  public errorPreventingCheckin: string | null = null;

  public fortniteChecklist = {
    serverEast: false,
    gameMode: false,
    namecheck: false,
  };

  private _unsub = new Unsubscriber();

  constructor(
    private _formBuilder: FormBuilder,
    private _store: Store<RootState>,
    private _matchService: MatchesService,
    private _modalService: NgxSmartModalService,
    private _quickPlayEventService: QuickPlayEventsService,
    private _router: Router
  ) {
    super(_modalService, BattleRoyaleCheckinModalComponent.MODAL_NAME, _router);
    this.checkinInfo = this.getData();

    this.credentialsForm = this._formBuilder.group({
      requiredEsportCredential: ["", Validators.required],
    });

    this.userEsportCredential$ = this._store.select("user", "currentUser", "esportCredentials").pipe(
      takeUntil(this._unsub.unsubEvent),
      map((credList) => {
        if (credList) {
          return credList.find((cred) => cred.esport === this.checkinInfo.esport);
        } else {
          return undefined;
        }
      })
    );
  }

  public ngOnInit(): void {
    this.userEsportCredential$
      .pipe(
        takeUntil(this._unsub.unsubEvent)
      )
      .subscribe(
        (res) => {
          if (res && res.userEsportId) {
            this.credentialFormLoading = false;
            this.credentialValue = res;
            this.currentStep = CheckinSteps.CHECK_IN;
          } else {
            this.currentStep = CheckinSteps.ADD_CREDENTIALS;
          }
        }
      );
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public editCredential(): void {
    this.editCredentialsForm = this._formBuilder.group({
      requiredEsportCredential: ["", Validators.required],
    });
    this.currentStep = CheckinSteps.EDIT_CREDENTIALS;
  }

  public backToCheckin(): void {
    this.currentStep = CheckinSteps.CHECK_IN;
  }

  public saveCredentials(credForm: FormGroup, isUpdate: boolean = false): void {
    // TODO Testing
    if (credForm.valid) {
      this.credentialFormLoading = true;
      this.credentialsFormHasError = false;
      const userEsportId = credForm.value.requiredEsportCredential.gameCredential;
      if (userEsportId) {
        this._store.select("user", "currentUser", "availableEsports").pipe(take(1)).subscribe(
          (availEsportList) => {
            const userEsportCred = availEsportList.find((availEsport) => availEsport.name === this.checkinInfo.esport);
            if (userEsportCred) {
              const formattedUpdate: EsportUserCredentialsAttributes = {
                userEsportId,
                esportId: userEsportCred.id,
              };

              if (isUpdate) {
                formattedUpdate.id = this.credentialValue.id;
              }

              this._store.dispatch(new UpdateEsportCredentials([formattedUpdate]));
            } else {
              this.credentialsFormHasError = true;
            }
          },
          (err) => {
            Logger.error(err);
            this.credentialFormLoading = false;
            this.credentialsFormHasError = true;
          }
        );
      } else {
        this.credentialFormLoading = false;
        this.credentialsFormHasError = true;
      }
    } else {
      this.credentialFormLoading = false;
      this.credentialsFormHasError = true;
    }
  }

  public checkinToGame(): void {
    this.errorPreventingCheckin = null;

    if (this.checkinInfo.esport === gamePlatforms.FORTNITE) {
      if (
        !this.fortniteChecklist.gameMode ||
        !this.fortniteChecklist.namecheck ||
        !this.fortniteChecklist.serverEast
      ) {
        this.errorPreventingCheckin = "Please read and accept the checklist before you check-in";
        return;
      }
    }
    const checkinHttpCall$: Observable<{ success: true }> = this.checkinInfo.type === "seriesMatchup" ? this._matchService.checkinToMatch(
      this.checkinInfo.seriesId,
      this.checkinInfo.gameId,
      this.checkinInfo.teamId
    ) : this._quickPlayEventService.checkinToQuickPlayEvent(this.checkinInfo.quickPlayEventID, this.checkinInfo.teamId);

    //Observable is not called until subscribed to so only one http request will fire
    checkinHttpCall$.pipe(
      take(1)
    ).subscribe(
      () => {
        this._modalService.closeLatestModal();
      },
      (err) => {
        Logger.error(err);
        window.alert("Error Checking In! Please try again or contact an admin");
      }
    );
  }

};
