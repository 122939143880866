import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  BehaviorSubject,
  combineLatest,
  concat,
  Observable,
  of,
  Subject,
} from "rxjs";
import {
  delay,
  filter,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { Store } from "@ngrx/store";

import { Unsubscriber } from "@utils/unsubscriber";
import { QuickPlayEventStatus } from "@apptypes/quick-play-events.types";
import { QuickPlayEventsService } from "@services/quick-play-events/quick-play-events.service";
import { LeagueEndpointService } from "@services/league-endpoints.service";

import {
  GenericLeagueBracket,
  LeagueBracketDetails,
  LeagueBracketStatus,
  LeagueTeam,
} from "src/app/reducers/leagues/league.types";
import { RootState } from "src/app/reducers";
import { APITournamentTypes } from "src/app/enums/tournamentTypes.enum";
import { ClearQuickPlayEvent } from "src/app/reducers/leagues/league.actions";

//TODO: Move all quickplay logic into a new component

@Component({
  selector: "app-bracket-match-block",
  templateUrl: "./bracket-match-block.component.html",
  styleUrls: ["./bracket-match-block.component.scss"],
})
export class BracketMatchBlockComponent implements OnDestroy, OnInit {
  @Input() public useStreamingBracketData = false;
  @Input() public isFortnite = false;
  public leagueTournaments$: Observable<LeagueBracketDetails[]>;
  public leagueTeams$: Observable<LeagueTeam[]> = of([]);
  public loading = true;
  public filteredBracket$: Observable<LeagueBracketDetails | null> = of(null);
  public matchForm: FormGroup;
  public tournamentType$: Observable<APITournamentTypes | null> = of(null);
  public tournamentTypes = APITournamentTypes;

  public animateUpdate$: Observable<boolean>;
  public quickPlayStatusUpdate$: Observable<boolean>;
  public isBracketBeingBuilt$: Observable<boolean>;

  private _unsub = new Unsubscriber();
  private _animateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _animateTriggerSubject: Subject<void> = new Subject<void>();

  constructor(
    private _formBuilder: FormBuilder,
    private _store: Store<RootState>,
    private _quickPlayEventsService: QuickPlayEventsService,
    private _leaguesService: LeagueEndpointService
  ) {
    this.matchForm = this._formBuilder.group({
      selectedTournament: [null],
    });

    this.animateUpdate$ = this._animateSubject.asObservable();

    this._animateTriggerSubject.asObservable().pipe(
      tap(() => this._animateSubject.next(true)),
      delay(500),
      tap(() => this._animateSubject.next(false)),
      takeUntil(this._unsub.unsubEvent)
    ).subscribe();
  }

  public ngOnInit() {
    if (this.useStreamingBracketData && !this.isFortnite) {
      this._setupQuickPlay();
    } else {
      this._setupTournament();
    }
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
    this._store.dispatch(new ClearQuickPlayEvent());
  }

  private _setupQuickPlay(): void {
    //NOTE: This whole thing will need a refactor at some point

    this.leagueTournaments$ = this._store.select("leagues", "league").pipe(
      filter((league) => !!league),
      map((league) => league.brackets),
      takeUntil(this._unsub.unsubEvent)
    );

    this.leagueTeams$ = this._store.select("leagues", "league").pipe(
      filter(league => league !== null),
      map(({ teams }) => teams),
      takeUntil(this._unsub.unsubEvent)
    );

    const user$ = this._store.select("user", "currentUser").pipe(
      first()
    );

    const quickPlayEvent$ = this._quickPlayEventsService.currentQuickPlayEventStreamData$.pipe(
      filter(quickPlayEventStreamData => quickPlayEventStreamData !== null),
      filter(quickPlayEventStream => quickPlayEventStream.quickPlayEvent.status !== QuickPlayEventStatus.PENDING)
    );

    this.filteredBracket$ = quickPlayEvent$.pipe(
      withLatestFrom(user$),
      map(([quickPlayEventData, userProfile]) => ({
        quickPlayEventData,
        userProfile,
      })),
      map(({
        quickPlayEventData,
        userProfile,
      }) => {
        if (userProfile === null || userProfile === undefined) {
          return null;
        }

        const foundTeam = quickPlayEventData.tournamentTeams.find(
          (team) => team.userIDs.includes(userProfile.id.toString())
        );

        return foundTeam !== undefined ? parseInt(foundTeam.tournamentID, 10) : null;
      }),
      filter(tournamentID => tournamentID !== null),
      tap(() => this._animateTriggerSubject.next()),
      switchMap((tournamentID) => this._leaguesService.getTournamentStreamByTournamentID(tournamentID)),
      takeUntil(this._unsub.unsubEvent) //Probably redundant since async pipe, but just being safe
    );

    //We want to pay attention to when the QuickPlayEvent changes from pending to in_progress because
    //that is when the brackets are being built on the backend and it may take some time
    this.quickPlayStatusUpdate$ = this._quickPlayEventsService.currentQuickPlayEventStreamData$.pipe(
      filter(quickPlayEventStreamData => quickPlayEventStreamData !== null),
      map(quickPlayEvent => quickPlayEvent.quickPlayEvent.status === QuickPlayEventStatus.IN_PROGRESS),
      takeUntil(this._unsub.unsubEvent)
    );

    const bracketsAreNotSeeded$: Observable<boolean> = this.filteredBracket$.pipe(
      //Make sure all seriesMatchupIDs are truthy
      map((bracket) => !bracket.matchupMapping.reduce(
        (accumulator: boolean, currentMatchupMapping: GenericLeagueBracket) => accumulator && !!currentMatchupMapping.seriesMatchupId, true)
      )
    );

    const checkinsDoNotMatchTournamentTeams$: Observable<boolean> = this._quickPlayEventsService.currentQuickPlayEventStreamData$.pipe(
      filter(quickPlayEvent => quickPlayEvent !== null),
      map(({ tournamentTeams, checkins }) => checkins.length > 0 && tournamentTeams.length !== checkins.length)
    );

    this.isBracketBeingBuilt$ = combineLatest([bracketsAreNotSeeded$, checkinsDoNotMatchTournamentTeams$]).pipe(
      map(([bracketsAreNotSeeded, checkinsDoNotMatch]) => bracketsAreNotSeeded || checkinsDoNotMatch)
    );

    this.tournamentType$ = this.filteredBracket$.pipe(
      map((bracket) => (bracket !== null && bracket !== undefined) ? bracket.schedulingType : null),
      takeUntil(this._unsub.unsubEvent)
    );
  }

  private _setupTournament(): void {
    this.leagueTournaments$ = this._store.select("leagues", "league").pipe(
      filter(league => league !== null),
      map((league) => league.brackets),
      map((brackets) => {
        let sortedTourneys: LeagueBracketDetails[] = [];
        if (!!brackets && brackets.length > 0) {
          const inProgressLeagues = brackets.filter(
            (bracket) => bracket.status === LeagueBracketStatus.IN_PROGRESS || bracket.status === LeagueBracketStatus.COMPLETED
          );
          const roundRobinsTournies = inProgressLeagues.filter(
            (roundRobinLeague) => roundRobinLeague.schedulingType === APITournamentTypes.ROUND_ROBIN
          );
          const bracketTournies = inProgressLeagues.filter(
            (bracketLeague) => bracketLeague.schedulingType === APITournamentTypes.ELIMINATION
          );
          const battleRoyaleTournies = inProgressLeagues.filter(
            (brLeague) => brLeague.schedulingType === APITournamentTypes.BATTLE_ROYALE
          );
          sortedTourneys = [
            ...bracketTournies,
            ...roundRobinsTournies,
            ...battleRoyaleTournies,
          ];
        }
        return sortedTourneys;
      }),
      tap((brackets) => {
        if (brackets[0]) {
          this.matchForm.patchValue({
            selectedTournament: brackets[0],
          });
        }
      }),
      takeUntil(this._unsub.unsubEvent)
    );

    const initialBracket$ = this.leagueTournaments$.pipe(
      map((brackets) => brackets[0]),
      first()
    );

    const formChanges$: Observable<LeagueBracketDetails> = this.matchForm.valueChanges.pipe(
      map(({ selectedTournament }) => selectedTournament ?? null),
      takeUntil(this._unsub.unsubEvent)
    );

    this.filteredBracket$ = concat(initialBracket$, formChanges$);

    this.tournamentType$ = this.filteredBracket$.pipe(
      map((bracket) => (bracket !== null && bracket !== undefined) ? bracket.schedulingType : null),
      takeUntil(this._unsub.unsubEvent)
    );
  }
}
