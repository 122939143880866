<div class="c_stepperContainer">
  <form [formGroup]="controlForm" class="c_stepperContainer__form">
    <div>Please provide a sponsor code</div>
    <ng-template matStepLabel>Sponsor</ng-template>
    <input
      placeholder="e.g. MYLEAGUE20"
      class="c_input c_sponsorCode__input"
      type="text"
      formControlName="sponsorCode"
    />
  </form>
  <div class="c_stepperContainer__btns">
    <app-modal-stepper-footer-buttons
      [hasNext]="true"
      [hasPrevious]="!isFirstStep"
      [formToCheck]="controlForm"
      [errorMessage]="errorMessage"
    >
    </app-modal-stepper-footer-buttons>
  </div>
</div>
