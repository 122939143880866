import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-utils";

@Injectable({
  providedIn: "root",
})
export class AnnouncementsService {

  constructor(
    private _http: HttpClient
  ) { }

  public dismissAnnouncement(announcementId: string | number) {
    const url = `${environment.apiBase}/api/v2/announcements/${announcementId}/mark_viewed`;
    const headers = basicAuthHeader();
    const payload = null;

    return this._http.post(url, payload, {
      headers,
    });
  }
}
