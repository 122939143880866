import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";

import { PasswordService } from "@services/password.service";

import { staticRoutes } from "src/app/enums/routes/routePaths";
import { FormController } from "src/app/components/form-controller/form-controller.abstract";

@Component({
  selector: "app-password-reset-page",
  templateUrl: "./password-reset-page.component.html",
  styleUrls: ["./password-reset-page.component.scss"],
  providers: [PasswordService],
})
export class PasswordResetPageComponent extends FormController implements OnInit {
  public sentEmail = false;
  public resetEmailForm: FormGroup;
  public staticRoutes = staticRoutes;

  constructor(private _passwordService: PasswordService, private _titleService: Title, private _toastr: ToastrService) {
    super();
    this.resetEmailForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
    });
    this._loadForm(this.resetEmailForm);
  }

  public emailInput(): string {
    try {
      return this.resetEmailForm.value.email || "";
    } catch (e) {
      return "";
    }
  }

  public ngOnInit() {
    // this._store.dispatch(new LogOutUser());
    this._titleService.setTitle("GGLeagues | Reset Password");
  }

  public sendPasswordRequest() {
    if (this._canProceed()) {
      this._passwordService.generateResetPasswordEmail(this.emailInput()).subscribe(
        // Response
        () => {
          this.sentEmail = true;
          this._toastr.success(`Email Sent to ${this.emailInput()}`);
        },
        // Error
        () => {
          // Always send success message to avoid any phishing attempts
          this.sentEmail = true;
          this._toastr.success(`Email Sent to ${this.emailInput()}`);
        }
      );
    }
  }
}
