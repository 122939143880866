<div class="c_teamInvitesBlock">
  <h4 class="u_txt--hdg4 u_txt--bold c_teamInvitesBlock__header">
    Pending Invites
  </h4>
  <div class="c_teamInvitesBlock__player c_teamInvitesBlock__player--header">
    <div class="c_teamInvitesBlock__player__handle">In-Game Name</div>
    <div class="c_teamInvitesBlock__player__button"></div>
  </div>
  <div *ngIf="(pending$ | async)?.length > 0; else pendingUnavailable">
    <div
      class="c_teamInvitesBlock__player"
      *ngFor="let player of pending$ | async"
    >
      <div class="c_teamInvitesBlock__player__handle">
        {{ player.inGameName }}
      </div>
      <div class="c_teamInvitesBlock__player__buttons">
        <a
          routerLink="/{{ getUserDetailRoute(player.id) }}"
          target="_blank"
          class="c_button primary_dark"
          >View Profile</a
        >
        <button
          (click)="approvePlayer(player.teamUserId)"
          class="c_button success c_teamInvitesBlock__inviteAction c_teamInvitesBlock__inviteAction--accept"
        >
          Approve
        </button>
        <button
          (click)="rejectPlayer(player.teamUserId)"
          class="c_button error c_teamInvitesBlock__inviteAction c_teamInvitesBlock__inviteAction--reject"
        >
          Reject
        </button>
      </div>
    </div>
  </div>
  <ng-template #pendingUnavailable>
    <div class="c_teamInvitesBlock__player">
      <p>There are no pending players</p>
    </div>
  </ng-template>
</div>
