import {
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import {
  of,
  Observable,
  combineLatest,
  interval,
} from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import {
  DomSanitizer,
  SafeResourceUrl,
  Title,
} from "@angular/platform-browser";
import { AngularFirestore } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import {
  takeUntil,
  filter,
  map,
  tap,
  pluck,
  take,
  first,
  switchMap,
} from "rxjs/operators";
import { NgxSmartModalService } from "ngx-smart-modal";


import { Unsubscriber } from "@utils/unsubscriber";
import { modalOptions } from "@utils/modal-helpers";
import { doArraysShareStringValues } from "@utils/array-utils";
import { SeriesMatchupCommentProvider } from "@providers/comment-service/series-matchup-comment.provider";
import { SeriesMatchupChatroomProvider } from "@providers/chatroom-collection";
import { ChatData } from "@apptypes/chat/chat-data.interface";
import { BattleRoyaleStreamGame, SeriesStreamData } from "@apptypes/streaming-series.types";
import { environment } from "@environments/environment";

import {
  ClearMatchCommenters,
  GetSeriesMatchupById,
  UpdateMatchCommenters,
} from "src/app/reducers/matches/matches.actions";
import { ComponentRouteParams } from "src/app/enums/routes/routeParams";
import { SeriesMatchupLobbyInformation, SeriesMatchupV2 } from "src/app/reducers/matches/matches.types";
import { RootState } from "src/app/reducers";
import { generateLeagueDetailRoute, generateSeriesMatchupRoute } from "src/app/enums/routes/routePaths";
import { ChatResourceTypes } from "src/app/enums/chat-resource-types";
import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import { FirestoreCollections, SeriesMatchupStreamCollections } from "src/app/enums/firestore.enum";
import { APITournamentTypes } from "src/app/enums/tournamentTypes.enum";
import { QueueSuccessModalComponent } from "src/app/components/queue-success-modal/queue-success-modal.component";

@Component({
  selector: "app-series-matchup-page",
  templateUrl: "./series-matchup-page.component.html",
  styleUrls: ["./series-matchup-page.component.scss"],
  providers: [SeriesMatchupCommentProvider, SeriesMatchupChatroomProvider],
})
export class SeriesMatchupPageComponent implements OnInit, OnDestroy {
  public loading$: Observable<boolean> = of(true);
  public seriesMatchup$: Observable<SeriesMatchupV2>;
  public userHasMatchPermissions$: Observable<boolean> = of(false);
  public chatData$: Observable<ChatData>;
  public seriesType$: Observable<APITournamentTypes>;
  public firebaseSeriesStream$: Observable<SeriesStreamData>;
  public firebaseStreamBRGames$: Observable<BattleRoyaleStreamGame[]> = of([]);
  public lobbyCodes = [];
  public battleRoyaleLobbyCodes = [];
  public chatResourceTypes = ChatResourceTypes;
  public gamePlatforms = gamePlatforms;
  public seriesTypes = APITournamentTypes;
  public displayTBDBanner = false;
  public safeStreamUrl = null;

  private _unsub = new Unsubscriber();
  private _showTitle = false;

  constructor(
    private _route: ActivatedRoute,
    private _store: Store<RootState>,
    private _firestore: AngularFirestore,
    private _titleService: Title,
    private _modalService: NgxSmartModalService,
    private _sanitizer: DomSanitizer,
    private _router: Router
  ) { }

  public ngOnInit() {
    this.seriesMatchup$ = this._store.select("matches", "seriesMatchup").pipe(
      filter((seriesMatchup) => !!seriesMatchup),
      tap((seriesMatchup) => {
        console.log({
          type: "SERIES_MATCHUP_INITIAL_STATE",
          data: seriesMatchup,
        });
        // TODO: THIS IS A HARDCODE FIX FOR DEMO ENV ONLY
        const isMissingATeam = !seriesMatchup.teams[0] || !seriesMatchup.teams[1];
        if (isMissingATeam) {
          console.log("RETRYING SERIES MATCHUP DATA");
          this._store.dispatch(new GetSeriesMatchupById(seriesMatchup.id));
        }
      }),
      filter((seriesMatchup) => {
        const isMissingATeam = !seriesMatchup.teams[0] || !seriesMatchup.teams[1];
        return !isMissingATeam;
      }),
      tap((seriesMatchup) => {
        console.log({
          type: "SERIES_MATCHUP_UPDATED_STATE",
          data: seriesMatchup,
        });
      }),
      takeUntil(this._unsub.unsubEvent)
    );

    this.chatData$ = this.seriesMatchup$.pipe(
      map((seriesMatchup) => ({
        referenceType: ChatResourceTypes.SERIES_MATCHUP,
        railsID: seriesMatchup.id,
        firestoreDocID: seriesMatchup.chatroomReferenceId,
      }))
    );

    const currentUser$ = this._store.select("user", "currentUser").pipe(filter((currentUser) => currentUser !== null));

    this.userHasMatchPermissions$ = combineLatest([this.seriesMatchup$, currentUser$]).pipe(
      map(([seriesData, userData]) => {
        if (userData.isAdmin) {
          return true; // Admins always get access
        }
        const userTeamIds = (userData.teams || []).map((team) => team.id.toString());
        if (userTeamIds.length === 0) {
          return false;
        }
        const seriesTeamIds = (seriesData.teams || []).map((team) => team.id);
        return doArraysShareStringValues(userTeamIds, seriesTeamIds);
      }),
      takeUntil(this._unsub.unsubEvent)
    );

    this.seriesType$ = this.seriesMatchup$.pipe(
      takeUntil(this._unsub.unsubEvent),
      pluck("seriesMatchType")
    );

    this._route.data.pipe(
      take(1)
    ).subscribe(
      (routeData) => {
        if (routeData && routeData.queueReferral) {
          this._initBlinkerTitle();
          const modal = this._modalService.create(QueueSuccessModalComponent.MODAL_NAME, QueueSuccessModalComponent, {
            ...modalOptions,
            dismissable: true,
          }).open();

          modal.onClose.pipe(
            switchMap(() => this._route.paramMap),
            map((paramMap) => paramMap.get(ComponentRouteParams.SERIES_MATCHUP_ID)),
            first()
          ).subscribe((seriesMatchupID) => {
            this._router.navigate([generateSeriesMatchupRoute(seriesMatchupID)], {
              replaceUrl: true,
            });
          });
        } else {
          this._titleService.setTitle("GGLeagues | Series Matchup");
        }
      }
    );
    this._route.paramMap.pipe(takeUntil(this._unsub.unsubEvent)).subscribe((params) => {
      this._store.dispatch(new GetSeriesMatchupById(params.get(ComponentRouteParams.SERIES_MATCHUP_ID)));
    });

    this.seriesMatchup$.pipe(
      tap(seriesMatchup => this._updateTBDBannerStatus(seriesMatchup)),
      tap(seriesMatchup => this._loadMatchCommenters(seriesMatchup)),
      takeUntil(this._unsub.unsubEvent)
    ).subscribe((seriesMatchup) => {
      this.lobbyCodes = this._findUniqueLobbyCodes(seriesMatchup);
      this.loading$ = of(false);
    });

    this.seriesMatchup$.pipe(first()).subscribe(
      (res) => {
        if (res.twitchStreamUrl) {
          this.safeStreamUrl = this.sanitizedStreamUrl(res.twitchStreamUrl);
        }
      }
    );

    // TODO: Clean this up after setup
    this.seriesMatchup$.pipe(
      take(1),
      pluck("firebaseStreamId"),
      takeUntil(this._unsub.unsubEvent)
    ).subscribe(
      (streamId) => {
        if (streamId) {
          const firebaseSeriesDoc = this._firestore.doc<SeriesStreamData>(`/${FirestoreCollections.SERIES_MATCHUP_STREAMDATA}/${streamId}`);
          this.firebaseSeriesStream$ = firebaseSeriesDoc.valueChanges();
          this.firebaseStreamBRGames$ = firebaseSeriesDoc
            .collection<BattleRoyaleStreamGame>(SeriesMatchupStreamCollections.BATTLE_ROYALE_GAMES)
            .valueChanges()
            .pipe(
              map((brGamesUnsorted) => {
                brGamesUnsorted.sort((a, b) => {
                  const gameAId = a.battle_royale_matchup_game_id;
                  const gameBId = b.battle_royale_matchup_game_id;

                  if (gameAId > gameBId) { return 1; };
                  if (gameBId > gameAId) { return -1; };
                  return 0;
                });

                this.battleRoyaleLobbyCodes = Array.from(new Set(brGamesUnsorted.map(brGame => brGame.lobby_code))).filter(code => !!code);
                return brGamesUnsorted;
              }),
              takeUntil(this._unsub.unsubEvent)
            );
        }
      }
    );

  }

  public sanitizedStreamUrl(twitchStreamUrl: string): SafeResourceUrl {
    const environmentRoot = environment.internalUrl.indexOf("localhost") !== -1 ?
      "localhost" :
      environment.internalUrl.split("//")[1];
    const NAME_GROUP_NUMBER = 4;
    const twitchChannelValue = twitchStreamUrl.match(/^(https:\/\/){0,1}(www.)(twitch.tv\/)(\w+)$/)[NAME_GROUP_NUMBER];
    const urlValue = `https://player.twitch.tv/?channel=${twitchChannelValue}&parent=${environmentRoot}`;
    return this._sanitizer.bypassSecurityTrustResourceUrl(urlValue);
  }

  public ngOnDestroy() {
    this._unsub.kill();
    this._store.dispatch(new ClearMatchCommenters());
  }

  public gameHasWinner(winnerId: string | number | null): boolean {
    if (winnerId === "null" || !winnerId) {
      return false;
    }

    if (winnerId) {
      return true;
    }

    return false;
  }

  public getLeagueDetailRoute(id: string | number): string[] {
    return id ? [`/${generateLeagueDetailRoute(id.toString())}`] : [];
  }

  public get hasLobbies(): boolean {
    return this.lobbyCodes?.length > 0 ?? false;
  }

  //TODO: Refactor this mess
  private _findUniqueLobbyCodes(matchup: SeriesMatchupV2): SeriesMatchupLobbyInformation[] {
    const uniqueCodes: SeriesMatchupLobbyInformation[] = [];
    const seriesMatches = matchup.matches ?? [];
    if (seriesMatches.length > 0) {
      for (const seriesMatch of seriesMatches) {
        const currentMatch = seriesMatch;
        const isCodeUnique = uniqueCodes.find((minimalLobby) => minimalLobby.lobby === currentMatch.lobby) === undefined;
        const isCodeValid = currentMatch.lobby && currentMatch.lobby.length > 0;
        if (isCodeUnique && isCodeValid) {
          uniqueCodes.push({
            lobby: currentMatch.lobby,
            lobbyPw: currentMatch.lobbyPw,
            teamLeftLobby: this._getTeamNameFromIdInMatchup(matchup, currentMatch.teamOneId),
            teamRightLobby: this._getTeamNameFromIdInMatchup(matchup, currentMatch.teamTwoId),
            winner: currentMatch.winnerId ? this._getTeamNameFromIdInMatchup(matchup, currentMatch.winnerId) : undefined,
          });
        }
      }
    }
    return uniqueCodes;
  }

  private _getTeamNameFromIdInMatchup(matchup: SeriesMatchupV2, teamId: string): string | null {
    const matchedTeam = matchup.teams.find((team) => team.id === teamId);
    if (matchedTeam) {
      return matchedTeam.title;
    }

    return null;
  }

  private _updateTBDBannerStatus(matchup: SeriesMatchupV2): void {
    this.displayTBDBanner = !matchup.teams[0] || !matchup.teams[1];
  }

  /**
   * Take a series matchup and take all of the players ids and usernames
   * and dispatch an update match commenters action
   *
   * @param seriesMatchup
   * @author Christian Tweed
   */
  private _loadMatchCommenters(seriesMatchup: SeriesMatchupV2): void {
    const matchCommenters = [];
    seriesMatchup.teams.forEach(team => {
      team.players.forEach(player => {
        const matchCommenter = [player.id, player.username];
        matchCommenters.push(matchCommenter);
      });
    });
    this._store.dispatch(new UpdateMatchCommenters(matchCommenters));
  }

  private _initBlinkerTitle(): void {
    interval(1000).pipe(
      takeUntil(this._unsub.unsubEvent)
    ).subscribe(
      () => {
        this._showTitle = !this._showTitle;
        if (this._showTitle) {
          this._titleService.setTitle("GGLeagues | Series Matchup");
        } else {
          this._titleService.setTitle("**MATCH FOUND**");
        }
      }
    );
  }
}
