import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { filter } from "rxjs/operators";

import { routePaths } from "src/app/enums/routes/routePaths";
import { RootState } from "src/app/reducers";
import { DashboardClubs } from "src/app/reducers/dashboard";
import { DashClubInfoModalComponent } from "../dash-club-info-modal/dash-club-info-modal.component";

@Component({
  selector: "app-dash-club-panel",
  templateUrl: "./dash-club-panel.component.html",
  styleUrls: ["./dash-club-panel.component.scss"],
})
export class DashClubPanelComponent {
  public dashInfoModal = DashClubInfoModalComponent;
  public orgSearchRoute = `/${routePaths.ORGANIZATION_SEARCH}`;

  public userClubList$: Observable<DashboardClubs[]> = of([]);

  constructor(private _store: Store<RootState>) {
    this.userClubList$ = this._store.select("dashboard", "userClubs").pipe(filter((clubs) => !!clubs));
  }
}
