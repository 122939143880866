import { Component, Input } from "@angular/core";

@Component({
  selector: "app-form-footer-error-message",
  templateUrl: "./form-footer-error-message.component.html",
  styleUrls: ["./form-footer-error-message.component.scss"],
})
export class FormFooterErrorMessageComponent {
  @Input() public errorMessage = "You have errors above, please correct them.";
  @Input() public alignLeft = false;

  public get alignmentStyle(): string {
    if (this.alignLeft) {
      return "text-align: left;";
    }

    return "";
  }
}
