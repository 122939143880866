<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
  [modalFooter]="footer"
></app-modal-container>

<ng-template #title>
  <app-modal-title>Report Inaccurate Match Results</app-modal-title>
</ng-template>

<ng-template #content>
  <p class="u_txt c_disputeResultsModal__description">
    This is only for innacurate match results, not for reporting the behavior of
    the other team. Both teams and an Admin will be notified.
  </p>
  <ng-container [formGroup]="disputeForm">
    <textarea
      class="c_input c_disputeResultsModal__input"
      formControlName="disputeDescription"
    ></textarea>
    <p
      class="u_txt c_disputeResultsModal__errorMessage"
      *ngIf="disputeDescriptionInvalid"
    >
      Your description cannot be blank
    </p>
  </ng-container>
</ng-template>

<ng-template #footer>
  <app-modal-footer-buttons>
    <button
      mat-button
      (click)="sendDispute()"
      class="c_button primary_dark c_disputeResultsModal__submitButton"
    >
      Dispute
    </button>
  </app-modal-footer-buttons>
</ng-template>
