import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Observable, of } from "rxjs";
import {
  filter,
  first,
  map,
  mergeMap,
  tap,
} from "rxjs/operators";

import { ScrimmageService } from "@services/v2/scrimmage/scrimmage.service";
import { ScrimmageCommentProvider } from "@providers/comment-service/scrimmage-comment.provider";
import { ScrimmageChatroomProvider } from "@providers/chatroom-collection/scrimmage-chatroom.provider";

import { ChatResourceTypes } from "src/app/enums/chat-resource-types";
import { Scrimmage } from "src/app/reducers/matches/matches.types";
import { ComponentRouteParams } from "src/app/enums/routes/routeParams";
import { ChatData } from "@apptypes/chat/chat-data.interface";

@Component({
  selector: "app-scrimmage-page",
  templateUrl: "./scrimmage-page.component.html",
  styleUrls: ["./scrimmage-page.component.scss"],
  providers: [ScrimmageCommentProvider, ScrimmageChatroomProvider],
})
export class ScrimmagePageComponent implements OnInit {
  public loading$: Observable<boolean> = of(true);
  public scrimmage$: Observable<Scrimmage>;
  public chatData$: Observable<ChatData>;

  public chatResourceTypes = ChatResourceTypes;

  constructor(
    private _activateRoute: ActivatedRoute,
    private _scrimmage: ScrimmageService,
    private _title: Title
  ) {}

  public ngOnInit() {
    this.scrimmage$ = this._activateRoute.paramMap.pipe(
      map((params) => params.get(ComponentRouteParams.SCRIMMAGE_ID)),
      mergeMap((scrimmageId) => this._scrimmage.getScrimmageById(scrimmageId)),
      filter((scrimmage) => !!scrimmage),
      tap(() => this.loading$ = of(false)),
      first()
    );

    this.chatData$ = this.scrimmage$.pipe(
      map(scrimmage => ({
        railsID: scrimmage.id,
        firestoreDocID: "",
        referenceType: ChatResourceTypes.SCRIMMAGE,
      }))
    );

    this._title.setTitle("GGLeagues | Scrimmage");
  }
}
