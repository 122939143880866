<div class="c_gameCredentialsLayout">
  <div class="c_gameCredentialsLayout__headerContainer">
    <h4 class="u_txt--hdg4 c_gameCredentialsLayout__header">Esport Profiles</h4>
    <button
      id="editButton"
      class="c_button primary-dark"
      *ngIf="isShowingEditButton"
      (click)="startEditing()"
    >
      Add/Edit Esports
    </button>
    <div
      class="c_gameCredentialsLayout__headerButtons"
      *ngIf="isShowingFormActionButtons"
    >
      <button class="c_button success" (click)="submitChanges()">Save</button>
      <button class="c_button error" (click)="cancel()">Cancel</button>
    </div>
  </div>
  <hr />
  <p *ngIf="hasError" class="u_txt--warn">
    You have errors below, please correct them.
  </p>
  <ng-container *ngIf="isUser; else nonUser">
    <ng-container *ngIf="isShowingCredentialLayout; else empty">
      <form [formGroup]="userCredentialForm">
        <div class="c_gameCredentialsLayout__credentials">
          <ng-container *ngFor="let credential of credentialsMap | keyvalue">
            <app-game-credentials
              *ngIf="isShowingCredential(credential)"
              [formControlName]="credential.key"
              [gameCredential]="credential.value"
              [esport]="credential.key"
              [isUser]="true"
              [isInEditMode]="isInEditMode"
              [cancel]="cancelSubject.asObservable()"
            >
            </app-game-credentials>
          </ng-container>
        </div>
      </form>
    </ng-container>
    <ng-template #empty>
      <div class="c_gameCredentialsLayout__empty">
        <i class="fas fa-9x fa-gamepad"></i>
        <p class="u_txt">You haven't linked any esport accounts yet.</p>
        <p class="u_txt">
          Add esport accounts to make it easier for your opponents and teammates
          to find you in game!
        </p>
        <button
          id="editButton"
          class="c_button primary-dark"
          (click)="startEditing()"
        >
          Add/Edit Esports
        </button>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #nonUser>
    <ng-container *ngIf="!isEmpty; else nonUserEmpty">
      <div class="c_gameCredentialsLayout__nonUserCredentialsContainer">
        <div class="c_gameCredentialsLayout__credentials">
          <ng-container *ngFor="let credential of credentialsMap | keyvalue">
            <app-game-credentials
              *ngIf="isShowingCredential(credential)"
              [gameCredential]="credential.value"
              [esport]="credential.key"
              [isUser]="false"
              [isInEditMode]="false"
            >
            </app-game-credentials>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-template #nonUserEmpty>
      <div class="c_gameCredentialsLayout__empty">
        <i class="fas fa-9x fa-gamepad"></i>
        <p class="u_txt">
          This user has not linked any esport accounts to their profile, or has
          hidden them in their privacy settings
        </p>
      </div>
    </ng-template>
  </ng-template>
</div>
