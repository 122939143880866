export enum BattleRoyaleGameStatus {
  PENDING = "pending",
  ACCEPTING_CHECKINS = "accepting_checkin",
  IN_PROGRESS = "in_progress",
  COMPLETE = "complete",
  CANCELLED = "cancelled",
}

export const BattleRoyaleGameStatusLabel: {[key in BattleRoyaleGameStatus]: string} = {
  [BattleRoyaleGameStatus.PENDING]: "Waiting for Check-In to Open",
  [BattleRoyaleGameStatus.ACCEPTING_CHECKINS]: "Game is Accepting Check-Ins",
  [BattleRoyaleGameStatus.IN_PROGRESS]: "Game is In Progress.  Check-Ins are closed",
  [BattleRoyaleGameStatus.COMPLETE]: "Game is Completed",
  [BattleRoyaleGameStatus.CANCELLED]: "Game has been Cancelled",
};
