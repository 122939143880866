import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

import { staticRoutes } from "src/app/enums/routes/routePaths";

@Component({
  selector: "app-no-permissions-page",
  templateUrl: "./no-permissions-page.component.html",
  styleUrls: ["./no-permissions-page.component.scss"],
})
export class NoPermissionsPageComponent implements OnInit {
  public routes = staticRoutes;

  constructor(private _titleService: Title) {}

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | Not Allowed");
  }
}
