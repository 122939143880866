import {
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Title } from "@angular/platform-browser";
import { parse, differenceInYears } from "date-fns";

import { UserService } from "@services/user/user.service";
import { UserSignupObject } from "@services/user/user.api.types";

import { Logger } from "../../util/logger";
import { pwValidatorGroup } from "../../util/password-validators";
import {
  formHasError,
  formHasSpecificError,
  nameValidator,
  profanityValidator,
} from "src/app/util/form-helpers";
import { externalRoutes, userRoutes } from "src/app/enums/routes/routePaths";
import {
  months,
  days,
  years,
} from "src/app/enums/date.enum";
import { Store } from "@ngrx/store";
import { RootState } from "src/app/reducers";
import { Observable, of } from "rxjs";
import {
  first,
  map,
  take,
  takeUntil,
} from "rxjs/operators";
import * as SignupAnalytics from "@utils/analytics/signup.analytics";
import { LoginNewUser } from "src/app/reducers/user/user.actions";
import { Club } from "src/app/reducers/clubs/club.types";
import { Unsubscriber } from "@utils/unsubscriber";
import { ActivatedRoute } from "@angular/router";

interface SignupForm {
  discordName: string;
  email: string;
  firstName: string;
  lastName: string;
  inGameName: string;
  password: string;
  clubId?: number | string;
  zipCode?: number | string;
  referralCode?: string;
}

enum SignupStates {
  INITIAL = "INITIAL",
  GUARDIAN_PROMPT = "GUARDIAN_PROMPT",
  GUARDIAN_CONFIRM = "GUARDIAN_CONFIRM",
}

@Component({
  selector: "app-sign-up-page",
  templateUrl: "./sign-up-page.component.html",
  styleUrls: ["./sign-up-page.component.scss"],
})
export class SignUpPageComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public isProcessing = false;
  public guardianForm: FormGroup;
  public signUpForm: FormGroup;
  public externalRoutes = externalRoutes;
  public userRoutes = userRoutes;
  public months = months;
  public days = days;
  public years = years;
  public hasClubContext = false;
  public followClubOnSignup = true;

  public guardianSignupData: UserSignupObject = null;

  public dateParseErr = false;
  public formSubmitError: string | null = null;

  public signupState: SignupStates = SignupStates.INITIAL;
  public signupStateEnum = SignupStates;
  public hasBlockingErrors = false;

  public club$: Observable<Club>;

  public redirectUrl$: Observable<string | null> = of(null);

  private _currentClubId: string;
  private _unsubscriber = new Unsubscriber();

  constructor(
    private _signUpService: UserService,
    private _toastr: ToastrService,
    private _titleService: Title,
    private _store: Store<RootState>,
    private _activatedRoute: ActivatedRoute
  ) {
    const referralCodeParam = this._activatedRoute.snapshot.queryParamMap.get("referral_code") || "";
    this.signUpForm = new FormGroup(
      {
        discordName: new FormControl("", [Validators.pattern(/^(.+)#(\d{4})$/), profanityValidator]),
        zipCode: new FormControl("", [Validators.pattern(/^(\d{5}([\-]\d{4})?)$/)]),
        email: new FormControl("", [Validators.required, Validators.email]),
        confirmEmail: new FormControl("", [Validators.required]),
        firstName: new FormControl("", [
          Validators.required,
          nameValidator,
          Validators.minLength(2),
        ]),
        lastName: new FormControl("", [Validators.required, nameValidator]),
        inGameName: new FormControl("", [
          Validators.required,
          profanityValidator,
          Validators.minLength(3),
          Validators.maxLength(16),
        ]),
        password: new FormControl("", [
          Validators.required,
          Validators.minLength(8),
          ...pwValidatorGroup,
        ]),
        //If the password has any of these issues, then the confirm will implicitly
        //as well
        confirmPassword: new FormControl("", [Validators.required]),
        showPW: new FormControl(false),
        privacyAgreement: new FormControl(false, [Validators.requiredTrue]),
        dobMonth: new FormControl("", [Validators.required]),
        dobDay: new FormControl("", [Validators.required]),
        dobYear: new FormControl("", [Validators.required]),
        referralCode: new FormControl(referralCodeParam),
      },
      [this._confirmEmailValidator, this._confirmPasswordValidator]
    );

    this.club$ = this._store.select("clubs", "club").pipe(takeUntil(this._unsubscriber.unsubEvent));

    this.redirectUrl$ = this._store.select("user", "userLoginRedirectRoute").pipe(
      first(),
      map((route) => (route || null))
    );
  }

  public get isFormInvalid(): boolean {
    return this.hasBlockingErrors && this.signUpForm.invalid;
  }

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | Sign Up");

    this.club$.pipe(
      take(1)

    ).subscribe(
      (club) => {
        if (club) {
          this._currentClubId = club.id;
          this.hasClubContext = true;
        }
      }
    );
  }

  public ngOnDestroy(): void {
    this._unsubscriber.kill();
  }

  public showPassword() {
    return this.signUpForm.get("showPW").value;
  }

  public hasError(controlName: string): boolean {
    return formHasError(controlName, this.signUpForm);
  }

  public hasSpecificError(controlName: string, errorName: string): boolean {
    return formHasSpecificError(controlName, errorName, this.signUpForm);
  }

  public async submitSignup(formData: FormGroup): Promise<void> {
    this.signUpForm.markAllAsTouched();
    this.dateParseErr = false;
    const NOW = new Date();
    const { dobMonth, dobDay, dobYear } = formData.value;
    const dob = parse(`${dobMonth} ${dobDay} ${dobYear}`, "MMMM d yyyy", NOW);
    const userAge = differenceInYears(NOW, dob);

    if (dob.toString() === "Invalid Date" || isNaN(userAge)) {
      this.dateParseErr = true;
      this.hasBlockingErrors = true;
      return;
    }

    if (userAge < 13) {
      this.signupState = SignupStates.GUARDIAN_PROMPT;
      this.guardianSignupData = this._signupFormDataMapper(formData.value as SignupForm, dob);
    } else if (formData.valid) {
      this.isLoading = true;

      const ERROR_MSG = "There was an error with signup, please try again.  If this persists, reach out to an admin";
      const ERROR_TITLE = "Signup Error";
      const SUCCESS_MSG = "User account successfully created!";
      const SUCCESS_TITLE = "Account Created!";
      this._resetCustomFormError();

      try {
        const formattedSignupData = this._signupFormDataMapper(formData.value as SignupForm, dob);

        this._signUpService.createUser(formattedSignupData).subscribe(
          // Success
          async () => {
            SignupAnalytics.registerSignupSuccessEvent(
              await this.redirectUrl$.toPromise()
            );
            this._toastr.success(SUCCESS_MSG, SUCCESS_TITLE);
            const { email, password } = formattedSignupData;
            const formattedPayload = {
              credentials: {
                email,
                password,
              },
              clubId: this.followClubOnSignup ? this._currentClubId : undefined,
            };

            this._store.dispatch(new LoginNewUser(formattedPayload));
          },
          // Error
          async (error) => {
            Logger.error(error);
            this._toastr.error(ERROR_MSG, ERROR_TITLE);
            this.isLoading = false;
            try {
              if (error.errors?.email[0] === "has already been taken") {
                SignupAnalytics.registerSignupErrorEvent(
                  await this.redirectUrl$.toPromise(),
                  `The email address "${formData.value.email}" is already in use.`
                );
                this._setCustomFormError(`The email address "${formData.value.email}" is already in use.`);
              } else {
                SignupAnalytics.registerSignupErrorEvent(
                  await this.redirectUrl$.toPromise(),
                  JSON.stringify(error)
                );
                this._setCustomFormError("There was an error signing up.");
              }
            } catch (e) {
              SignupAnalytics.registerSignupErrorEvent(
                await this.redirectUrl$.toPromise(),
                JSON.stringify(e)
              );
              Logger.error("Error parsing error message");
              Logger.error(e);
              this._setCustomFormError("There was an error signing up.");
            }
          }
        );
      } catch (e) {
        Logger.error(e);
        this._toastr.error(ERROR_MSG, ERROR_TITLE);
        this.isLoading = false;
        this._setCustomFormError("There was an error signing up.");
        SignupAnalytics.registerSignupErrorEvent(
          await this.redirectUrl$.toPromise(),
          JSON.stringify(e)
        );
      }
    } else {
      this.hasBlockingErrors = true;
    }
  }

  public async proceedToVerification(): Promise<void> {
    this.signupState = SignupStates.GUARDIAN_CONFIRM;
  }

  private _setCustomFormError(errMsg: string): void {
    this.formSubmitError = errMsg;
  }

  private _resetCustomFormError(): void {
    this.formSubmitError = null;
  }

  private _signupFormDataMapper(
    formData: SignupForm,
    dateOfBirth: Date
  ): UserSignupObject {
    const signupObject: UserSignupObject = {
      email: formData.email,
      discord_id: formData.discordName,
      password: formData.password,
      password_confirmation: formData.password,
      first_name: formData.firstName,
      last_name: formData.lastName,
      in_game_name: formData.inGameName,
      dob: dateOfBirth.toISOString(),
      clubId: this._currentClubId,
      zip_code: formData.zipCode,
    };

    if (formData.referralCode) {
      signupObject.referral_code = formData.referralCode;
    }

    return signupObject;
  }

  /**
   * Check to see if both emails have been modified and match
   *
   * @param control is the form we are cross validating
   * @author Christian Tweed
   * @returns null if they match, an error otherwise
   */
  private _confirmEmailValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const email = control.get("email");
    const confirmEmail = control.get("confirmEmail");

    return email &&
      confirmEmail &&
      confirmEmail.touched &&
      email.value !== confirmEmail.value ?
      {
        nonMatchingEmail: true,
      } :
      null;
  };

  /**
   * Check to see if both passwords have been modified and match
   *
   * @param control is the form we are cross validating
   * @author Christian Tweed
   * @returns null if they match, an error otherwise
   */
  private _confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get("password");
    const confirmPassword = control.get("confirmPassword");

    return password &&
      confirmPassword &&
      confirmPassword.touched &&
      password.value !== confirmPassword.value ?
      {
        nonMatchingPasswords: true,
      } :
      null;
  };
}
