<app-page>
  <a
    class="c_button secondary_light"
    [routerLink]="getOrganizationLink((seasonPass$ | async)?.organizationId)"
    >Back to Club</a
  >
  <article
    class="c_passDetailsPage"
    *ngIf="seasonPass$ | async as seasonPass; else loading"
  >
    <header>
      <h2 class="u_txt--hdg2">{{ seasonPass.title }} Pass</h2>
    </header>
    <app-pass-details-panel
      [userOwnsPass]="userOwnsPass$ | async"
      [seasonPass]="seasonPass"
      [showTallmadgeWaiver]="isTallmadge"
      [showHopewellWaiver]="isHopewell"
    >
    </app-pass-details-panel>
  </article>
  <ng-template #loading>
    <article *ngIf="!errorLoading; else error">
      <app-loader [useWhite]="true">Loading Esport Pass...</app-loader>
    </article>
  </ng-template>
  <ng-template #error>
    <article class="c_passDetailsPage c_passDetailsPage__error">
      <header>
        <h1 class="u_txt--hdg1">There was an error loading this esport pass</h1>
      </header>
      <i class="fas fa-exclamation-triangle fa-9x"></i>
    </article>
  </ng-template>
</app-page>
