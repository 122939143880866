import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ModalController } from "../modal-controller/modal-controller.abstract";

@Component({
  selector: "app-series-matchup-no-cred-info-modal",
  templateUrl: "./series-matchup-no-cred-info-modal.component.html",
  styleUrls: ["./series-matchup-no-cred-info-modal.component.scss"],
})
export class SeriesMatchupNoCredInfoModalComponent extends ModalController<null> {
  public static modalId = "seriesMatchupNoCredInfoModal";

  constructor(private _modalService: NgxSmartModalService, private _router: Router) {
    super(_modalService, SeriesMatchupNoCredInfoModalComponent.modalId, _router);
  }
}
