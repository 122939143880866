<app-page>
  <header class="c_myTeamsPage__header">
    <h2 class="u_txt--hdg2 u_txt--white">My Teams</h2>
    <app-need-help-button></app-need-help-button>
  </header>
  <ng-container *ngIf="(isLoading$ | async) === false; else loading">
    <div class="c_myTeamsPage">
      <ng-container *ngIf="userAccountTeam$ | async as acctTeam">
        <h3 class="u_txt--hdg3">
          Single-Player Team
          <button (click)="openAcctModal()" class="c_myTeamsPage__acctTeamBtn">
            <i class="fas fa-question-circle"></i>
          </button>
        </h3>
        <div class="c_myTeamsPage__teamsList">
          <a
            class="c_myTeamsPage__teamsList__teamItem"
            routerLink="/{{ getTeamDetailRoute(acctTeam.id) }}"
          >
            {{ acctTeam.title }}
            <i *ngIf="acctTeam.isCaptain" class="fas fa-crown"></i>
          </a>
        </div>
      </ng-container>
      <h3 class="u_txt--hdg3">Multiplayer Teams</h3>
      <div
        class="c_myTeamsPage__teamsList"
        *ngIf="(userTeams$ | async)?.length > 0; else noTeams"
      >
        <a
          class="c_myTeamsPage__teamsList__teamItem"
          *ngFor="let team of userTeams$ | async"
          routerLink="/{{ getTeamDetailRoute(team.id) }}"
        >
          {{ team.title }} <i *ngIf="team.isCaptain" class="fas fa-crown"></i>
        </a>
        <a
          routerLink="/{{ teamRoutes.TEAM_NEW }}"
          class="c_button primary_dark c_myTeamsPage__teamsList__newTeamButton"
        >
          Create Your Own
        </a>
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <app-loader [useWhite]="true">Loading User Teams...</app-loader>
  </ng-template>
  <ng-template #noTeams>
    <div class="c_myTeamsPage__teamsList c_myTeamsPage__teamsList--noTeams">
      <h4 class="c_myTeamsPage__noTeamsInfo u_txt--hdg4">
        You have no multi-player teams.
        <br />
        If you have been invited, it may still be waiting on a captain's
        approval.
      </h4>
      <br />
      <div class="c_myTeamsPage__buttons">
        <a routerLink="/{{ teamRoutes.TEAMS }}" class="c_button primary_dark">
          Join a Team
        </a>
        <br />
        <a
          routerLink="/{{ teamRoutes.TEAM_NEW }}"
          class="c_button primary_dark"
        >
          Create Your Own
        </a>
      </div>
    </div>
  </ng-template>
</app-page>
