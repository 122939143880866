import { Pipe, PipeTransform } from "@angular/core";

import { Nullish } from "@apptypes/generic-nullish.types";
import { isLogoUrlValid, ImageTypes } from "@utils/image-utils";

@Pipe({
  name: "teamLogoDefault",
})
export class TeamLogoDefaultPipe implements PipeTransform {
  public transform(logoUrl: Nullish<string>, useRectangleImage = false): string {
    const imageType = useRectangleImage ? ImageTypes.TEAM_RECT : ImageTypes.TEAM_SQ;

    return isLogoUrlValid(logoUrl) ? logoUrl : imageType;
  }
}
