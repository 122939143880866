import { Component, Input } from "@angular/core";

import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import { generateLeagueDetailRoute, generateSeriesMatchupRoute } from "src/app/enums/routes/routePaths";
import { DashboardNextMatch } from "src/app/reducers/dashboard";
import { showPlatformImage } from "src/app/util/image-utils";

@Component({
  selector: "app-dash-match-preview",
  templateUrl: "./dash-match-preview.component.html",
  styleUrls: ["./dash-match-preview.component.scss"],
})
export class DashMatchPreviewComponent {
  @Input() public match: DashboardNextMatch;

  public showEsportImage(esport: gamePlatforms): string {
    return showPlatformImage(esport);
  }

  public getSeriesMatchupRoute(id: string | number): string[] {
    if (this.match.gameType === "standard") {
      return id ? [`/${generateSeriesMatchupRoute(id.toString())}`] : [];
    } else {
      return [`/${generateLeagueDetailRoute(this.match.leagueId)}`];
    }
  }

  public get matchupDisplayText(): string {
    if (this.match.esport === gamePlatforms.ROCKET_LEAGUE && this.match.matchTitle === "Battle Royale") {
      return "Live Tournament";
    }
    return this.match.matchTitle;
  }
}
