<button
  class="c_button c_selfReportButton primary_dark"
  (click)="openModal()"
  [disabled]="buttonLoading"
>
  <ng-container *ngIf="!buttonLoading; else loadingSpinner">
    Submit Results
  </ng-container>
  <ng-template #loadingSpinner>
    <i class="fa fa-circle-notch fa-spin"></i>
  </ng-template>
</button>
