import {
  Component,
  forwardRef,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from "@angular/forms";
import { takeUntil } from "rxjs/operators";

import { Unsubscriber } from "@utils/unsubscriber";
import { ModalStep } from "@apptypes/view-types/modal.types";

import { MatchReport } from "src/app/reducers/matches/matches.types";
import { ModalStepperFooterButtonsComponent } from "../modal-stepper-footer-buttons/modal-stepper-footer-buttons.component";

@Component({
  selector: "app-forfeit-team-step",
  templateUrl: "./forfeit-team-step.component.html",
  styleUrls: ["./forfeit-team-step.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ForfeitTeamStepComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ForfeitTeamStepComponent),
      multi: true,
    },
  ],
})
export class ForfeitTeamStepComponent implements ControlValueAccessor, OnDestroy, Validator, ModalStep {
  @Input() public matchReport: MatchReport;
  @ViewChild("footerButtons") public footerButtons: TemplateRef<ModalStepperFooterButtonsComponent>;
  public forfeittingTeamControl = new FormControl("", [Validators.required]);

  private _unsub = new Unsubscriber();

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public get winningTeam(): string {
    return this.forfeittingTeamControl.value;
  }

  public get forfeitFormErrorMessage(): string {
    return this.forfeittingTeamControl.invalid ? "You need to choose the forfeitting team" : "";
  }

  /** CVA Methods */
  public onTouch = () => { };

  public writeValue(winningId: string): void {
    this.forfeittingTeamControl.patchValue(winningId);
  }

  public registerOnChange(onChangeCallback: () => unknown): void {
    this.forfeittingTeamControl.valueChanges.pipe(
      takeUntil(this._unsub.unsubEvent)
    ).subscribe(onChangeCallback);
  }

  public registerOnTouched(onTouchCallback: () => unknown): void {
    this.onTouch = onTouchCallback;
  }

  public setDisabledState(isDisabled: boolean): void {
    return isDisabled ?
      this.forfeittingTeamControl.disable() :
      this.forfeittingTeamControl.enable();
  }

  /** Validator Methods */
  public validate(): ValidationErrors {
    return this.forfeittingTeamControl.valid ? null : this.forfeittingTeamControl.errors;
  }
}
