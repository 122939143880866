import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "@environments/environment";

import { UserLogin, UserLoginToken } from "../reducers/user/user.types";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(private _http: HttpClient) { }

  public logon(payload: UserLogin): Observable<UserLoginToken> {
    const url = `${environment.apiBase}/api/login.json`;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this._http.post<UserLoginToken>(
      url,
      {
        api_user: payload,
      },
      {
        headers,
      }
    );
  }

  public logout(jti: string): Observable<unknown> {
    const url = `${environment.apiBase}/api/logout.json`;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jti}`,
    });

    return this._http.delete<unknown>(url, {
      headers,
    });
  }
}
