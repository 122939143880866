<button
  type="submit"
  class="c_button primary_dark c_formLoadingButton"
  [disabled]="isLoading"
>
  <span *ngIf="!isLoading">
    <ng-content></ng-content>
  </span>
  <span *ngIf="isLoading">
    <i class="fas fa-circle-notch fa-spin"></i>
  </span>
</button>
