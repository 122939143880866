import { Component, forwardRef } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from "@angular/forms";

import { BaseCVA } from "src/app/components/base-cva/base-cva.class";
import { JoinLeagueModalService } from "src/app/stateful-services/join-league-modal/join-league-modal.service";

const minSponsorCodeLength = 6;

@Component({
  selector: "app-sponser-code-step",
  templateUrl: "./sponser-code-step.component.html",
  styleUrls: ["./sponser-code-step.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SponserCodeStepComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SponserCodeStepComponent),
      multi: true,
    },
  ],
})
export class SponserCodeStepComponent extends BaseCVA implements Validator {
  constructor(private _formBuilder: FormBuilder, private _joinLeagueModalService: JoinLeagueModalService) {
    super();
    this.controlForm = this._formBuilder.group(
      {
        sponsorCode: [null, Validators.required],
      },
      {
        validators: this._lengthValidator,
      }
    );
  }

  public get isFirstStep(): boolean {
    return this._joinLeagueModalService.isSponsorStepFirst;
  }

  /* Validator Function */
  public validate(): ValidationErrors | null {
    const error = {
      valid: false,
      message: this.controlForm.errors?.sponsorCodeLengthError ?
        "Sponsor code is too short, minimum of six characters" :
        "No sponsor code provided",
    };

    return this.controlForm.valid ?
      null :
      {
        invalidForm: error,
      };
  }

  public get errorMessage(): string {
    return this.controlForm.invalid ?
      this.controlForm.errors?.sponsorCodeLengthError ?
        "Invalid sponsor code" :
        "No sponsor code provided" :
      "";
  }

  /**
   * Invalidates the form is the sponsor code is under 6 characters long
   *
   * @param form is the sponsor code form
   * @author Christian Tweed
   */
  private _lengthValidator: ValidatorFn = (form: FormGroup) => {
    const sponsorCode: string = form.get("sponsorCode").value;
    if (sponsorCode) {
      if (sponsorCode.length >= minSponsorCodeLength) {
        form.setErrors(null);
        return null;
      } else if (sponsorCode.length < minSponsorCodeLength && sponsorCode.length > 0) {
        return {
          sponsorCodeLengthError: true,
        };
      } else {
        form.setErrors(null);
        return null;
      }
    }
  };
}
