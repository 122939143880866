<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
></app-modal-container>

<ng-template #title>
  <app-modal-title>Missing Match Results</app-modal-title>
</ng-template>

<ng-template #content>
  <div class="c_dashOverDueModal">
    <p>
      This game is past the expected finishing time, and has no score or winner
      reported for it.
    </p>
    <p>
      If a score is not reported for these games in a timely manner, you risk
      having the match declared as a forfeit. You can click "Go to Match" on
      your next game panel to go the game in question and report the score.
    </p>
    <p>
      If this game is just running late, you can ignore this message, just be
      sure to report scores when you are finished! If the game has been
      rescheduled, please reach out to an admin to ensure they do not
      accidentally mark the game as incomplete.
    </p>
  </div>
</ng-template>
