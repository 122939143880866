import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import {
  first,
  map,
  mergeMap,
  pluck,
} from "rxjs/operators";
import { Observable } from "rxjs";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ToastrService } from "ngx-toastr";

import { UserService } from "@services/user/user.service";
import { phoneNumberRegex } from "@utils/form-helpers";
import { modalOptions } from "@utils/modal-helpers";

import { RootState } from "src/app/reducers";
import { TextNotificationVerifyModalComponent } from "../text-notification-verify-modal/text-notification-verify-modal.component";
import { TextNotifServiceService } from "@services/v2/text-notif-service/text-notif-service.service";

@Component({
  selector: "app-notification-management-panel",
  templateUrl: "./notification-management-panel.component.html",
  styleUrls: ["./notification-management-panel.component.scss"],
})
export class NotificationManagementPanelComponent implements OnInit {
  public notificationForm: FormGroup;
  public enableNotifications = false;
  public verificationStatus: "verified" | "unverified" = "unverified";
  public isLoading = false;
  public userPhone$: Observable<string>;

  constructor(
    private _formBuilder: FormBuilder,
    private _toastr: ToastrService,
    private _textNotifService: TextNotifServiceService,
    private _userService: UserService,
    private _modalService: NgxSmartModalService,
    private _store: Store<RootState>
  ) {
    this.notificationForm = this._formBuilder.group({
      phone: [null, [Validators.required, Validators.pattern(phoneNumberRegex)]],
    });

    this.userPhone$ = this._store.select("user", "currentUser").pipe(
      map((userProfile) => userProfile.phoneNumber),
      first()
    );
  }

  public ngOnInit(): void {
    this._textNotifService.getCurrentSubscriptions().subscribe(
      (res) => {
        if (res && res.status === "active") {
          this.enableNotifications = true;
        }
      }
    );
  }

  public disableNotifications(): void {
    this._textNotifService.cancelAllTextNotifications().subscribe(
      () => {
        this._toastr.success(
          "Text Notifcations have been deactivated on your account"
        );
        this.enableNotifications = false;
      }
    );
  }

  public enableNotificationTexts(): void {
    this._store.select("user", "currentUser").pipe(
      first(),
      pluck("id"),
      mergeMap((userId) => this._userService.updateUserPhone(
        this.notificationForm.value.phone,
        userId
      )),
      mergeMap(() => this._textNotifService.enableTextVerificationProcess())
    ).subscribe(
      () => {
        this._modalService.create(
          TextNotificationVerifyModalComponent.MODAL_ID,
          TextNotificationVerifyModalComponent,
          modalOptions
        ).open().onCloseFinished.pipe(
          mergeMap(() => this._textNotifService.getCurrentSubscriptions())
        ).subscribe(
          (res) => {
            if (res && res.status === "active") {
              this.enableNotifications = true;
            } else {
              this.enableNotifications = false;
            }
          }
        );
      }
    );
  }

  public get isPhoneNumberInvalid(): boolean {
    return (!!this.notificationForm.get("phone")?.errors ?? false) && (this.notificationForm.get("phone")?.dirty ?? false);
  }
}
