<ng-container *ngIf="teamInfo; else tbd">
  <div class="c_teamPreviewBlock">
    <a
      routerLink="/{{ getTeamDetailRoute(teamInfo.id) }}"
      class="c_teamPreviewBlock__team"
    >
      <div
        class="c_teamPreviewBlock__image"
        [ngStyle]="{ 'background-image': teamImage }"
      ></div>
      <div class="c_teamPreviewBlock__teamName">
        {{ getTitleWithRecords(teamInfo) }}
        <i
          class="fas fa-trophy c_teamPreviewBlock__winnerIcon"
          *ngIf="teamInfo.id === winnerId"
        ></i>
      </div>
    </a>
  </div>
</ng-container>
<ng-template #tbd>
  <div class="c_teamPreviewBlock">
    <div
      class="c_teamPreviewBlock__image"
      [style.background]="'url(' + teamImage + ')'"
    ></div>
    <div class="c_teamPreviewBlock__teamName">TBD</div>
    <ul class="c_teamPreviewBlock__roster"></ul>
  </div>
</ng-template>
