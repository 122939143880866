import { GGWebEnvironment } from "./environment.interface";

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: GGWebEnvironment = {
  production: false,
  apiBase: "https://vast-ravine-96447.herokuapp.com",
  internalUrl: "https://staging.ggleagues.com",
  cloudAPIBase: "https://us-central1-staging-ggleagues.cloudfunctions.net",
  stripeApiKey: "pk_test_L4LvPTLSqxXj94d8FC5GUaeX0007oN2qN7",
  staticSiteUrl: "https://staging.home.ggleagues.com",
  firebaseConfig: {
    apiKey: "AIzaSyCAjFdAR9OsnRJzdzVVM3hZGrvEHfaEoE4",
    authDomain: "staging-ggleagues.firebaseapp.com",
    databaseURL: "https://staging-ggleagues.firebaseio.com",
    projectId: "staging-ggleagues",
    storageBucket: "staging-ggleagues.appspot.com",
    messagingSenderId: "1030868835572",
    appId: "1:1030868835572:web:c23dd50e9c83ccd21f92a1",
    measurementId: "G-763X2L02S4",
    notificationCollection: "notifications",
    chatroomCollection: "chatrooms",
  },
  ggleaguesOrgId: "233",
  ggleaguesOrgSlug: "ggleagues-inc",
  gglCurrentPass: "1208",
  useCloudChats: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
