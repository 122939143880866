import {
  Component,
  Input,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-modal-stepper-footer-buttons",
  templateUrl: "./modal-stepper-footer-buttons.component.html",
  styleUrls: ["./modal-stepper-footer-buttons.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ModalStepperFooterButtonsComponent {
  @Input() public hasPrevious: boolean;
  @Input() public hasNext: boolean;
  @Input() public formToCheck: FormGroup | FormControl;
  @Input() public errorMessage = "You have errors above, please correct them.";

  public hasBlockingErrors = false;

  public checkForBlockers(e: Event): void {
    if (this.formToCheck && !this.formToCheck.valid) {
      this.hasBlockingErrors = true;
      e.preventDefault();
    } else {
      this.hasBlockingErrors = false;
    }
  }
}
