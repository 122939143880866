import { Injectable } from "@angular/core";
import {
  Actions,
  Effect,
  ofType,
} from "@ngrx/effects";
import { mergeMap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { ToastrService } from "ngx-toastr";

import {
  DashboardActionTypes,
  GetLeagueDashboard,
  GetLeagueDashboardError,
  GetLeagueDashboardSuccess,
} from "./dashboard.actions";
import { Logger } from "../../util/logger";
import { DashboardV2Service } from "@services/v2/dashboard/dashboard.v2.service";

@Injectable()
export class DashboardEffects {
  @Effect()
  public league$ = this._actions$.pipe(
    ofType<GetLeagueDashboard>(DashboardActionTypes.GET_GENERIC_DASHBOARD),
    mergeMap(() => this._dashboardService.fetchDashboardData()),
    mergeMap((dashboardRes) =>
      of(new GetLeagueDashboardSuccess(dashboardRes))
    ),
    catchError((err) => {
      this._toastr.error("The dashboard could not be retrieved", "Error");
      Logger.error(err);
      return of(new GetLeagueDashboardError(err));
    })
  );

  constructor(
    private _actions$: Actions,
    private _dashboardService: DashboardV2Service,
    private _toastr: ToastrService
  ) {}
}
