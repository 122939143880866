<div class="c_chatBlock" *ngIf="!loading; else chatLoading">
  <app-chat-stream [messages]="messages"></app-chat-stream>
  <app-add-new-comment-block
    *ngIf="chatData !== null"
    (sendChatEvent)="submitComment($event)"
    [status]="chat.chatroom.status"
    [chatType]="chatData.referenceType"
  ></app-add-new-comment-block>
</div>
<ng-template #chatLoading>
  <div class="c_chatBlock">
    <app-loader>Loading Chat...</app-loader>
  </div>
</ng-template>
