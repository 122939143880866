import { Component, Input } from "@angular/core";

@Component({
  selector: "app-bounce-loader",
  templateUrl: "./bounce-loader.component.html",
  styleUrls: ["./bounce-loader.component.scss"],
})
export class BounceLoaderComponent {
  @Input() public loadError = false;
}
