import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BattleRoyaleForniteTeamScore, BattleRoyaleStreamGame } from "@apptypes/streaming-series.types";
import { Store } from "@ngrx/store";
import { NgxSmartModalService } from "ngx-smart-modal";
import {
  take,
  pluck,
  tap,
} from "rxjs/operators";
import { BattleRoyaleGameStatus } from "src/app/enums/battle-royale-game-status.enum";
import { routePaths } from "src/app/enums/routes/routePaths";
import { RootState } from "src/app/reducers";
import { ModalController } from "../modal-controller/modal-controller.abstract";

interface AggregateScores {
  team_id: number;
  fortnite_team_scores: number;
};

@Component({
  selector: "app-battle-royale-aggregate-results-modal",
  templateUrl: "./battle-royale-aggregate-results-modal.component.html",
  styleUrls: ["./battle-royale-aggregate-results-modal.component.scss"],
})
export class BattleRoyaleAggregateResultsModalComponent extends ModalController<BattleRoyaleStreamGame[]> implements OnInit {
  public static modalId = "brAggregateResultsModal";

  public brGamesList: BattleRoyaleStreamGame[] = [];
  public aggregateScoresList: BattleRoyaleForniteTeamScore[] = [];
  public rulesUri = `/${routePaths.RULES}`;
  public seriesIsComplete = false;
  public completeGamesCount = 0;

  private _seriesTeams = new Map();
  private _winnerId: string | null = null;

  constructor(
    private _modalService: NgxSmartModalService,
    private _router: Router,
    private _store: Store<RootState>
  ) {
    super(_modalService, BattleRoyaleAggregateResultsModalComponent.modalId, _router);
  }

  public ngOnInit(): void {
    this.brGamesList = this.getData() || [];
    this.aggregateScoresList = this._initAggregateScores(this.brGamesList);

    this._store.select("matches", "seriesMatchup").pipe(
      take(1),
      tap((seriesMatchupData) => {
        this._winnerId = seriesMatchupData.winnerId || null;
      }),
      pluck("teams")
    ).subscribe(
      (teamsList) => {
        this._seriesTeams = new Map(teamsList.map(({ id, title }) =>
          [id, title]
        ));
      }
    );
  }

  public getTeamName(teamId: number): string {
    const stringId = teamId.toString();

    return this._seriesTeams.get(stringId) || "Unknown";
  }

  public get seriesWinner(): string {
    if (this._winnerId !== null) {
      return this._seriesTeams.get(this._winnerId) || "Unknown";
    } else {
      return "TBD";
    }
  }

  private _initAggregateScores(brGames: BattleRoyaleStreamGame[]): AggregateScores[] {
    const aggregateScoreObj = {
    };
    brGames.forEach((brGame) => {
      (brGame.fortnite_team_scores || []).forEach((result) => {
        aggregateScoreObj[result.team_id] = (aggregateScoreObj[result.team_id] || 0) + result.fortnite_team_scores;
      });
      if (brGame.status === BattleRoyaleGameStatus.COMPLETE) {
        this.completeGamesCount++;
      }
    });
    const unsortedAggregateScoreList = Object.keys(aggregateScoreObj).map((aggregateScoreKey) => ({
      team_id: parseInt(aggregateScoreKey, 10),
      fortnite_team_scores: aggregateScoreObj[aggregateScoreKey],
    }));
    unsortedAggregateScoreList.sort((a, b) => {
      // Safe parseing, if it fails we'll still display data just unsorted
      const rankA = a.fortnite_team_scores;
      const rankB = b.fortnite_team_scores;

      if (rankA > rankB) {
        return -1;
      } else if (rankA < rankB) {
        return 1;
      } else {
        return 0;
      }
    });

    return [...unsortedAggregateScoreList];
  }

}
