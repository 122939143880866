import { FormGroup, FormControl } from "@angular/forms";

import { UserUpdateForm } from "@services/user/user.api.types";

import { Logger as logger } from "../util/logger";

export const formHasError = (controlName: string, parentForm: FormGroup): boolean => {
  const control = parentForm.get(controlName);
  if (control.touched && !!control.errors) {
    logger.error("Validation Error");
    return true;
  }

  return false;
};

/**
 * Given a control name and a specific error type, will return true or false if the
 * control has the given error.  Useful for toggling on context-specific error messages
 */
export const formHasSpecificError = (controlName: string, errorName: string, parentForm: FormGroup): boolean => {
  if (formHasError(controlName, parentForm)) {
    const controlErrors = parentForm.get(controlName).errors;
    if (controlErrors[errorName]) {
      return true;
    }
  }

  return false;
};

export const slugifyString = (str: string) => {
  try {
    const slugRegex = / /g;
    return str.replace(slugRegex, "-").toLowerCase();
  } catch (e) {
    return str;
  }
};

/**
 * Validator that checks for awful, profane words.
 */
export const profanityValidator = (control: FormControl) => {
  /* Please add to this horrible awful regex with other things you think of, current words caught:
   * Nigger (nig, nigg, niggr, nigga)
   * Faggot (fag)
   * Autist
   * Cunt
   * Retard (tard)
   * Rape (raped, rapist)
   * Nazi
   * Cuck
   * Cock
   */
  // eslint-disable-next-line max-len
  const profanityRegex = /(^|\s)nig(|g|a|r|ger)($|\s)|(^|\s)fag($|\s)|(^|\s)autis(t|m)($|\s)|(^|\s)fag(|got)($|\s)|(^|\s)(re|)tard($|\s)|(^|\s)cunt($|\s)|(^|\s)rap(e|ed|ist)($|\s)|(^|\s)nazi($|\s)|(^|\s)cuck($|\s)|(^|\s)cock($|\s)/i;

  const textContent: string = control.value;

  if (textContent) {
    const regexMatches = textContent.match(profanityRegex);
    if (regexMatches) {
      return {
        profanity: true,
      };
    }
  }

  return null;
};

/**
 * Checks to make sure that the name is at least two characters long
 * and to include most unicode letters
 *
 * @param control is the form control that contains a name
 * @see https://stackoverflow.com/questions/2385701/regular-expression-for-first-and-last-name
 * @author Christian Tweed
 */
export const nameValidator = (control: FormControl) => {
  const nameRegex = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/;

  const textContent: string = control.value;

  if (textContent) {
    if (textContent.length < 2) {
      return {
        invalidLength: true,
      };
    }
    const regexMatches = !textContent.match(nameRegex);
    if (regexMatches) {
      return {
        invalidCharacters: true,
      };
    }
    return null;
  }
  return {
    noNameProvided: true,
  };
};

/**
 * Take a given object and iterate over its entries and replace nullish ones
 * with an empty string. Note that this is only useful for objects that have only
 * string properties
 *
 * @param object
 * @author Christian Tweed
 */
export const replaceNullishPropertiesWithString = <T>(object: T): T => {
  Object.entries(object).map((entry) => {
    const key = entry[0];
    const value = entry[1];
    object[key] = value ?? "";
  });

  return object;
};

/**
 * Cleans out the user form and replaces any nullish values with an empty string
 *
 * @param userForm
 * @author Christian Tweed
 */
export const userFormDuster = (userForm: UserUpdateForm): UserUpdateForm => {
  userForm.user = replaceNullishPropertiesWithString(userForm.user);
  return userForm;
};

//Matches a standard (123)-123-1234 number
export const phoneNumberRegex = /^(\([\d]{3}\)-[\d]{3}-[\d]{4})$/;
