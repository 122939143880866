import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";

import { ModalController } from "../modal-controller/modal-controller.abstract";


export interface ParticipantListTeamModalData {
  teamId: string | number;
  teamName: string;
  teamLogoUrl: string | null;
  subtext: string | null; //Keep this a generic name for multiple purposes
}

@Component({
  selector: "app-participant-list-modal",
  templateUrl: "./participant-list-modal.component.html",
  styleUrls: ["./participant-list-modal.component.scss"],
})
export class ParticipantListModalComponent extends ModalController<ParticipantListTeamModalData[]> {
  public static MODAL_ID = "participant-list-modal";

  constructor(private _modalService: NgxSmartModalService, private _router: Router) {
    super(_modalService, ParticipantListModalComponent.MODAL_ID, _router);
  }

  public get participantList(): ParticipantListTeamModalData[] {
    const rawData = this.getData() ?? [];
    const sortedData = [...rawData];

    sortedData.sort((a, b) => {
      const titleA = a.teamName.toLowerCase();
      const titleB = b.teamName.toLowerCase();

      if (titleA > titleB) {
        return 1;
      }

      if (titleB > titleA) {
        return -1;
      }

      return 0;
    });

    return sortedData.filter((sortedTeam) => {
      const BYE_WEEK_IDENTIFIER = "BYE WEEK";
      if (sortedTeam.teamName.indexOf(BYE_WEEK_IDENTIFIER) === 0) {
        return false;
      }

      return true;
    });
  }
}
