import { Component, Input } from "@angular/core";

import { LeagueSeriesMatchup } from "src/app/reducers/leagues/league.types";

@Component({
  selector: "app-battle-royale-matches-preview",
  templateUrl: "./battle-royale-matches-preview.component.html",
  styleUrls: ["./battle-royale-matches-preview.component.scss"],
})
export class BattleRoyaleMatchesPreviewComponent {
  @Input() public battleRoyaleMatches: LeagueSeriesMatchup[] = [];
}
