/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { UserState } from "./user.types";
import { UserActions, UserActionTypes } from "./user.actions";
import { dashboardRoutes } from "src/app/enums/routes/routePaths";

const initialState: UserState = {
  currentUser: null,
  userNotifications: [],
  isLoggingIn: false,
  isLoggingInError: false,
  fetchingProfile: false,
  fetchingProfileError: false,
  fetchingProfileById: false,
  fetchingProfileByIdError: false,
  isUpdating: false,
  isUpdatingError: false,
  userLoginRedirectRoute: `/${dashboardRoutes.DASHBOARD}`,
  refreshingUserProfile: false,
  refreshingUserProfileError: false,
};

export function userReducer(state = initialState, action: UserActions): UserState {
  switch (action.type) {
    case UserActionTypes.LOGIN_USER:
    case UserActionTypes.RESTORE_USER:
      return {
        ...state,
        isLoggingIn: true,
        isLoggingInError: false,
        fetchingProfile: true,
      };
    case UserActionTypes.LOGIN_USER_ERROR:
      // Remove USERJWT if login is failed to prevent retries or errors
      localStorage.removeItem("userjwt");

      return {
        ...state,
        currentUser: null,
        isLoggingIn: false,
        isLoggingInError: true,
        userLoginRedirectRoute: null,
        fetchingProfile: false,
      };
    case UserActionTypes.LOGIN_USER_SUCCESS:
    case UserActionTypes.RESTORE_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        isLoggingIn: false,
        isLoggingInError: false,
        fetchingProfile: false,
      };
    case UserActionTypes.LOGOUT_USER:
      return {
        ...state,
        currentUser: null,
        isLoggingIn: false,
        isLoggingInError: false,
      };
    case UserActionTypes.UPDATE_ESPORT_CREDENTIALS:
    case UserActionTypes.UPDATE_USER:
      return {
        ...state,
        isUpdating: true,
        isUpdatingError: true,
      };
    case UserActionTypes.UPDATE_ESPORT_CREDENTIALS_ERROR:
    case UserActionTypes.UPDATE_USER_ERROR:
      return {
        ...state,
        isUpdating: false,
        isUpdatingError: true,
      };
    case UserActionTypes.UPDATE_ESPORT_CREDENTIALS_SUCCESS:
    case UserActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdatingError: false,
      };
    case UserActionTypes.FETCH_PROFILE:
      return {
        ...state,
        fetchingProfile: true,
        fetchingProfileError: false,
      };
    case UserActionTypes.FETCH_PROFILE_ERROR:
      return {
        ...state,
        fetchingProfile: false,
        fetchingProfileError: true,
      };
    case UserActionTypes.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        fetchingProfile: false,
        fetchingProfileError: false,
      };
    case UserActionTypes.STASH_LOGIN_REDIRECT:
      return {
        ...state,
        userLoginRedirectRoute: action.payload,
      };
    case UserActionTypes.CLEAR_LOGIN_ROUTE:
      return {
        ...state,
        userLoginRedirectRoute: null,
      };
    case UserActionTypes.FETCH_PROFILE_BY_ID:
      return {
        ...state,
        fetchingProfileById: true,
        fetchingProfileByIdError: false,
      };
    case UserActionTypes.FETCH_PROFILE_BY_ID_ERROR:
      return {
        ...state,
        fetchingProfileById: false,
        fetchingProfileByIdError: true,
      };
    case UserActionTypes.FETCH_PROFILE_BY_ID_SUCCESS:
      return {
        ...state,
        browsingUser: action.payload,
        fetchingProfileById: false,
        fetchingProfileByIdError: false,
      };
    case UserActionTypes.CLEAR_FETCHED_PROFILE:
      return {
        ...state,
        browsingUser: null,
      };
    case UserActionTypes.REFRESH_USER_PROFILE:
      return {
        ...state,
        refreshingUserProfile: true,
        refreshingUserProfileError: false,
        //	currentUser: null
      };
    case UserActionTypes.REFRESH_USER_PROFILE_ERROR:
      return {
        ...state,
        refreshingUserProfile: false,
        refreshingUserProfileError: true,
      };
    case UserActionTypes.REFRESH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        refreshingUserProfile: false,
        refreshingUserProfileError: false,
        currentUser: action.payload,
      };
    case UserActionTypes.PATCH_CURRENT_USER_DATA:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
