import { Component, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import {
  map,
  filter,
  takeUntil,
  pluck,
  take,
  mergeMap,
} from "rxjs/operators";

import { NotificationsService } from "@services/notifications.service";

import { RootState } from "src/app/reducers";
import { LogOutUser } from "src/app/reducers/user/user.actions";
import { Unsubscriber } from "src/app/util/unsubscriber";
import { routePaths } from "src/app/enums/routes/routePaths";
import { Notification } from "src/app/reducers/matches/matches.types";

interface HeaderLink {
  label: string;
  path: string;
  requiresAuth: boolean;
}

const makeHeaderLink = (label: string, path: string, requiresAuth = true): HeaderLink => ({
  label,
  path,
  requiresAuth,
});

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnDestroy {
  public isLoggedIn$: Observable<boolean>;
  public userNotifications$: Observable<Notification[]> = of([]);
  public notificationCount$: Observable<number> = of(0);
  public isOverflowOpen = false;
  public isMobileHeaderOpen = false;

  public homeRoute = routePaths.HOME;
  public loginRoute = `/${routePaths.LOGIN}`;
  public signupRoute = `/${routePaths.SIGN_UP}`;
  public notificationsRoute = `/${routePaths.NOTIFICATIONS}`;

  public headerLinks: HeaderLink[] = [
    makeHeaderLink("Home", routePaths.DASHBOARD),
    makeHeaderLink("My Profile", routePaths.PROFILE),
    makeHeaderLink("My Teams", routePaths.MY_TEAMS),
    makeHeaderLink("Leagues", routePaths.LEAGUES, false),
    makeHeaderLink("Clubs", routePaths.CLUB_SEARCH, false),
  ];
  public overflowLinks: HeaderLink[] = [
    makeHeaderLink("Create Team", routePaths.TEAM_NEW),
    makeHeaderLink("Find a Team", routePaths.TEAMS),
    makeHeaderLink("Payment History", routePaths.PAYMENTS),
    makeHeaderLink("Referrals", routePaths.REFERRALS),
  ]; //NOTE: We're running out of vertical space for links at iphone SE

  private _unsub = new Unsubscriber();

  constructor(private _notificationService: NotificationsService, private _store: Store<RootState>) {
    this.isLoggedIn$ = this._store.select("user", "currentUser").pipe(
      map((user) => {
        if (user !== null) {
          return true;
        }

        return false;
      }),
      takeUntil(this._unsub.unsubEvent)
    );
    this.userNotifications$ = this.isLoggedIn$.pipe(
      filter((isLoggedIn) => isLoggedIn),
      take(1),
      mergeMap(() => this._store.select("user", "currentUser")),
      filter((user) => user !== null),
      pluck("id"),
      mergeMap((id) =>
        this._notificationService.getUserNotifications(id).pipe(
          map((notifications) => {
            if (notifications) {
              return notifications.filter((notification) => notification.status === "unread");
            }

            return [];
          })
        )
      ),
      takeUntil(this._unsub.unsubEvent)
    );
    this.notificationCount$ = this.userNotifications$.pipe(map((userNotifications) => userNotifications.length));
  }

  public ngOnDestroy() {
    this._unsub.kill();
  }

  public logout(): void {
    this._store.dispatch(new LogOutUser());
    this.isOverflowOpen = false;
    document.querySelector("body").classList.remove("header-open");
  }

  public toggleOverflow(): void {
    this.isOverflowOpen = !this.isOverflowOpen;
    if (this.isOverflowOpen) {
      document.querySelector("body").classList.add("header-open");
    } else {
      document.querySelector("body").classList.remove("header-open");
    }
  }

  public closeMenu(): void {
    this.isOverflowOpen = false;
    document.querySelector("body").classList.remove("header-open");
  }
}
