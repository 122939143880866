<app-modal-container
  [modalContent]="content"
  [modalTitle]="title"
></app-modal-container>

<ng-template #content>
  <p class="u_txt">
    Please use the insturctions below to learn how to add friends in
    {{ esport }}.
  </p>
  <ng-container *ngIf="friendLinks.length > 0; else noLinks">
    <ng-container *ngFor="let link of friendLinks">
      <a class="u_txt--link--dark" [href]="link" target="_blank">{{ link }}</a
      ><br />
    </ng-container>
  </ng-container>
  <ng-template #noLinks>
    <p class="u_txt">
      Please refer to your game's manual for instruction on how to add friends
    </p>
  </ng-template>
</ng-template>

<ng-template #title>
  <app-modal-title>Add Friends in {{ esport }}</app-modal-title>
</ng-template>
