import { Component } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";

import { ModalController } from "../modal-controller/modal-controller.abstract";
import { SeasonPass } from "@apptypes/season-pass/season-pass.types";
import { SeasonPassService } from "@services/v2/season-pass/season-pass.service";
import { Logger } from "@utils/logger";
import {
  generateDashboardPassSuccessRoute,
  generateEsportPassSuccessRoute,
  generateLeagueDetailPassActivationRoute,
  generateOrganizationSlugPassSuccessRoute,
} from "src/app/enums/routes/routePaths";

export interface SponsorPassModalData {
  isPurchaseInfo: boolean;
  seasonPass: SeasonPass;
  leagueContext?: string;
  orgContext?: string;
  navigateToDashboard?: boolean;
}

@Component({
  selector: "app-sponsor-pass-modal",
  templateUrl: "./sponsor-pass-modal.component.html",
  styleUrls: ["./sponsor-pass-modal.component.scss"],
})
export class SponsorPassModalComponent extends ModalController<SponsorPassModalData> {
  public static modalID = "sponsorPass";
  public sponsorForm: FormGroup;
  public submissionError: string | null = null;

  private _hasBlockingErrors = false;

  constructor(
    private _modalService: NgxSmartModalService,
    private _router: Router,
    private _seasonPassService: SeasonPassService
  ) {
    super(_modalService, SponsorPassModalComponent.modalID, _router);

    this.sponsorForm = new FormGroup({
      sponsorCode: new FormControl(null, [Validators.required, Validators.minLength(6)]),
    });
  }

  public get sponsorPassModalProps() {
    return this.getData();
  }

  public submit(form: FormGroup): void {
    this.submissionError = null;
    this._hasBlockingErrors = form.invalid;

    if (this._hasBlockingErrors) {
      return;
    }

    const { sponsorCode } = form.value;

    const { seasonPass, leagueContext, orgContext, navigateToDashboard } = this.sponsorPassModalProps;

    this._seasonPassService.registerPassWithCode(seasonPass.id, sponsorCode).subscribe(
      () => {
        if (leagueContext) {
          this._router.navigate([`/${generateLeagueDetailPassActivationRoute(leagueContext, seasonPass.id)}`]);
        } else if (orgContext) {
          this._router.navigate([`/${generateOrganizationSlugPassSuccessRoute(orgContext, seasonPass.id)}`]);
        } else if (navigateToDashboard) {
          this._router.navigate([`/${generateDashboardPassSuccessRoute(seasonPass.id)}`]);
        } else {
          this._router.navigate([`/${generateEsportPassSuccessRoute(seasonPass.id)}`]);
        }
      },
      (err) => {
        Logger.error(err);
        this.submissionError = err.error.errors[0][0];

      }
    );
  }

  public get hasError(): boolean {
    return this._hasBlockingErrors && this.sponsorForm.invalid;
  }
}
