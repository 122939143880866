/*
 *
 * Sponsor Locations are hardcoded for now, we can discuss more semantic ways to code them in the future
 *
 */

export enum SPONSOR_MANIFEST_LOCATIONS {
  TITLE_SPONSOR = "1",
  SECONDARY_SPONSOR_LOGO_ONE = "2",
  SECONDARY_SPONSOR_LOGO_TWO = "3",
  SECONDARY_SPONSOR_LOGO_THREE = "4",
}
