import { Injectable } from "@angular/core";

import { Nullish } from "@apptypes/generic-nullish.types";

import { BAD_WORDS } from "./bad-words";

@Injectable({
  providedIn: "root",
})
export class BadWordsService {
  private _badWordList = BAD_WORDS;

  public isProfane(message: Nullish<string>): boolean {
    if(!message){
      return false;
    }

    return this._badWordList.filter((badWord) => {
      //Example regex created would be: /\bepic\b/gi if badWord was 'epic'.
      const badWordRegex = new RegExp(`\\b${badWord.replace(/(\W)/g, "\\$1")}\\b`, "gi");
      return badWordRegex.test(message);
    }
    ).length > 0;
  }
}
