import { Injectable } from "@angular/core";
import {
  Actions,
  Effect,
  ofType,
} from "@ngrx/effects";
import {
  map,
  mergeMap,
  catchError,
  take,
} from "rxjs/operators";
import { of } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

import { UserService } from "@services/user/user.service";
import { LoginService } from "@services/login.service";

import {
  UserActionTypes,
  LoginUser,
  LoginUserSuccess,
  LoginUserError,
  RestoreUserLogin,
  LogOutUser,
  UpdateUser,
  FetchUserProfileSuccess,
  UpdateUserError,
  ClearLoginRoute,
  RestoreUserSuccess,
  FetchProfileById,
  FetchProfileByIdSuccess,
  FetchProfileByIdError,
  RefreshUserProfile,
  RefreshUserProfileSuccess,
  RefreshUserProfileError,
  LoginNewUser,
  LoginNewUserSuccess,
  UpdateEsportCredentials,
  UpdateEsportCredentialsSuccess,
  UpdateEsportCredentialsError,
  PatchCurrentUserData,
  FetchUserProfileError,
} from "./user.actions";
import { SessionStorageKeys } from "src/app/enums/session-storage-keys.enum";
import { RootState } from "..";
import { NullAction } from "../nullaction";
import { Logger as logger } from "../../util/logger";
import {
  userRoutes,
  staticRoutes,
  dashboardRoutes,
  routePaths,
} from "src/app/enums/routes/routePaths";
import { ClearDashboard } from "../dashboard/dashboard.actions";
import { ClubsService } from "@services/v2/clubs/clubs.service";
import { NotificationModalService } from "@services/v2/notification-modal.service";
import { registerLoginErrorEvent, registerLoginSuccessEvent } from "@utils/analytics/signup.analytics";

@Injectable()
export class UserEffects {
  @Effect()
  public loginUser$ = this._actions$.pipe(
    ofType<LoginUser>(UserActionTypes.LOGIN_USER),
    map((action) => action.payload),
    mergeMap((loginPayload) => this._loginService.logon(loginPayload.credentials)),
    mergeMap((loginObj) => {
      if (loginObj && loginObj.jwt !== null) {
        localStorage.setItem("userjwt", loginObj.jwt);
        return this._userService.getProfile().pipe(
          catchError((e) => {
            logger.error(e);
            return of(null);
          })
        );
      }
      return of(null);
    }),
    mergeMap((prof) => {
      if (prof) {
        registerLoginSuccessEvent();
        return of(new LoginUserSuccess(prof));
      }
      this._toastr.error("Unsuccessful Login, try again");
      logger.error("Couldn't Retrieve Profile");
      registerLoginErrorEvent("Couldn't Retrieve Profile");
      return of(new LoginUserError());
    })
  );

  @Effect()
  public loginNewUser$ = this._actions$.pipe(
    ofType<LoginNewUser>(UserActionTypes.LOGIN_NEW_USER),
    map((action) => action.payload),
    mergeMap((loginPayload) => this._loginService.logon(loginPayload.credentials).pipe(
      map((loginObj) => ({
        loginObj,
        clubId: loginPayload.clubId,
      }))
    )),
    mergeMap(({ loginObj, clubId }) => {
      if (loginObj && loginObj.jwt !== null) {
        localStorage.setItem("userjwt", loginObj.jwt);
        if (clubId) {
          return this._clubService.subscribeToClub(clubId).pipe(
            mergeMap(() => this._userService.getProfile().pipe(
              catchError((e) => {
                logger.error(e);
                return of(null);
              })
            )),
            catchError((e) => {
              logger.error(e);
              this._toastr.error(
                "Could not follow club, return to page and try again",
                "Error following club"
              );
              return this._userService.getProfile().pipe(
                catchError((e2) => {
                  logger.error(e2);
                  return of(null);
                })
              );
            })
          );
        } else {
          return this._userService.getProfile().pipe(
            catchError((e) => {
              logger.error(e);
              return of(null);
            })
          );
        }
      }
      return of(null);
    }),
    mergeMap((prof) => {
      if (prof) {
        registerLoginSuccessEvent();
        return of(new LoginNewUserSuccess(prof));
      }

      this._toastr.error("Unsuccessful Login, try again");
      registerLoginErrorEvent("Couldn't Retrieve Profile");
      logger.error("Couldn't Retrieve Profile");
      return of(new LoginUserError());
    })
  );

  @Effect()
  public restoreUser$ = this._actions$.pipe(
    ofType<RestoreUserLogin>(UserActionTypes.RESTORE_USER),
    map((action) => action.payload),
    mergeMap((loginCreds) => {
      if (loginCreds && loginCreds.jwt !== null) {
        localStorage.setItem(SessionStorageKeys.USER_JWT, loginCreds.jwt);
        return this._userService.getProfile().pipe(
          catchError((e) => {
            logger.error(e);
            return of(null);
          })
        );
      }
      return of(null);
    }),
    mergeMap((prof) => {
      if (prof) {
        localStorage.setItem(SessionStorageKeys.USER_HAS_LOGGED_IN, "true");
        return of(new RestoreUserSuccess(prof));
      }
      this._toastr.error("Unsuccessful Login, try again");
      return of(new LoginUserError());
    })
  );

  @Effect()
  public logoutUser$ = this._actions$.pipe(
    ofType<LogOutUser>(UserActionTypes.LOGOUT_USER),
    mergeMap(() => {
      const currentUserJwt = localStorage.getItem(SessionStorageKeys.USER_JWT);
      localStorage.removeItem(SessionStorageKeys.USER_JWT);
      if (currentUserJwt) {
        this._router.navigate([`/${routePaths.LOGIN}`]);
        this._toastr.success("Logged Out");
        return this._loginService.logout(currentUserJwt);
      }

      return of(new NullAction());
    }),
    mergeMap(() => of(new ClearDashboard()))
  );

  @Effect()
  public loginSuccess$ = this._actions$.pipe(
    ofType<LoginUserSuccess>(UserActionTypes.LOGIN_USER_SUCCESS),
    map((action) => {
      const announcements = action.payload.announcements;
      this._notificationModalService.openUserNotificationModal(announcements);
    }),
    mergeMap(() => this._store.select("user", "userLoginRedirectRoute").pipe(take(1))),
    mergeMap((route) => {
      const parsedRoute = this._router.parseUrl(route);
      const validatedRoute = !!parsedRoute.root ? parsedRoute : `/${dashboardRoutes.DASHBOARD}`; //dashboard
      this._router.navigateByUrl(validatedRoute);
      this._toastr.success("Login Successful");
      return of(new ClearLoginRoute());
    })
  );

  @Effect()
  public loginNewUserSuccess$ = this._actions$.pipe(
    ofType<LoginNewUserSuccess>(UserActionTypes.LOGIN_NEW_USER_SUCCESS),
    map((action) => {
      const announcements = action.payload.announcements;
      this._notificationModalService.openUserNotificationModal(announcements);
    }),
    mergeMap(() => this._store.select("user", "userLoginRedirectRoute").pipe(take(1))),
    mergeMap((route) => {
      const weeklyRef = localStorage.getItem(SessionStorageKeys.WEEKLY_NEWUSER_REDIRECT);
      if (!!weeklyRef) {
        localStorage.removeItem(SessionStorageKeys.WEEKLY_NEWUSER_REDIRECT);
        this._router.navigate(["/leagues"], {
          queryParams: {
            league_types: "quick_play_league",
          },
        });
      } else {
        const validatedRoute = route ? route : `/${dashboardRoutes.DASHBOARD}`; //dashboard
        this._router.navigate([validatedRoute]);
      }
      return of(new ClearLoginRoute());
    })
  );

  @Effect()
  public restoreSuccess$ = this._actions$.pipe(
    ofType<LoginUserSuccess>(UserActionTypes.RESTORE_USER_SUCCESS),
    mergeMap((action) => {
      this._toastr.success("Login Successful");
      const announcements = action.payload.announcements;
      this._notificationModalService.openUserNotificationModal(announcements);

      const weeklyRef = localStorage.getItem(SessionStorageKeys.WEEKLY_NEWUSER_REDIRECT);
      if (!!weeklyRef) {
        localStorage.removeItem(SessionStorageKeys.WEEKLY_NEWUSER_REDIRECT);
        this._router.navigate(["/leagues"], {
          queryParams: {
            league_types: "quick_play_league",
          },
        });
      }

      return of(new ClearLoginRoute());
    })
  );

  @Effect()
  public updateUser$ = this._actions$.pipe(
    ofType<UpdateUser>(UserActionTypes.UPDATE_USER),
    map((action) => action.payload),
    mergeMap((payload) => this._userService.updateUser(payload[0], payload[1]).pipe(
      catchError((e) => {
        logger.error(e);
        this._toastr.error("Update failed");
        return of(null);
      })
    )),
    mergeMap((res) => {
      if (res.success) {
        this._toastr.success("Profile Updated!");
        return this._userService.getProfile().pipe(
          catchError((e) => {
            this._toastr.error("Update failed");
            logger.error(e);
            return of(null);
          })
        );
      }
      return of(null);
    }),
    mergeMap((apiResponse) => {
      if (apiResponse) {
        this._router.navigate([`/${userRoutes.PROFILE}`]);
        return of(new FetchUserProfileSuccess(apiResponse));
      }
      return of(new UpdateUserError());
    })
  );

  @Effect()
  public getUserById$ = this._actions$.pipe(
    ofType<FetchProfileById>(UserActionTypes.FETCH_PROFILE_BY_ID),
    map((action) => action.payload),
    mergeMap((id) => this._userService.getUser(id).pipe(
      catchError((e) => {
        logger.error(e);
        return of(null);
      })
    )),
    mergeMap((profile) => {
      if (profile) {
        return of(new FetchProfileByIdSuccess(profile));
      }

      this._toastr.error("Could Not Find Profile", "Error");
      return of(new FetchProfileByIdError());
    })
  );

  @Effect()
  public refreshProfile$ = this._actions$.pipe(
    ofType<RefreshUserProfile>(UserActionTypes.REFRESH_USER_PROFILE),
    map((action) => action.payload),
    mergeMap(() => this._userService.getProfile().pipe(
      catchError((error) => {
        logger.error(error);
        return of(null);
      })
    )),
    mergeMap((profile) => {
      if (profile) {
        return of(new RefreshUserProfileSuccess(profile));
      }
      this._toastr.error("Could Not Refresh Your Profile", "Error");
      return of(new RefreshUserProfileError());
    })
  );

  @Effect()
  public updateCredentials$ = this._actions$.pipe(
    ofType<UpdateEsportCredentials>(UserActionTypes.UPDATE_ESPORT_CREDENTIALS),
    map((action) => action.payload),
    mergeMap((payload) => this._userService.updateUserEsportCredentials(payload).pipe(
      catchError((error) => {
        logger.error(error);
        return of(null);
      })
    )),
    mergeMap((response) => {
      if (response?.success ?? false) {
        return of(new UpdateEsportCredentialsSuccess());
      }
      this._toastr.error("Could not update esport credentials", "Error");
      return of(new UpdateEsportCredentialsError());
    })
  );

  @Effect()
  public updateCredentialsSuccess$ = this._actions$.pipe(
    ofType<UpdateEsportCredentialsSuccess>(UserActionTypes.UPDATE_ESPORT_CREDENTIALS_SUCCESS),
    mergeMap(() => this._userService.getProfile().pipe(
      catchError((error) => {
        logger.error(error);
        return of(null);
      })
    )),
    mergeMap((profile) => {
      if (profile) {
        return of(new PatchCurrentUserData(profile));
      }
      this._toastr.error("Could not fetch profile", "Error");
      return of(new FetchUserProfileError());
    })
  );

  public userRoutes = userRoutes;
  public staticRoutes = staticRoutes;
  public dashboardRoutes = dashboardRoutes;

  constructor(
    private _actions$: Actions,
    private _loginService: LoginService,
    private _router: Router,
    private _store: Store<RootState>,
    private _toastr: ToastrService,
    private _userService: UserService,
    private _clubService: ClubsService,
    private _notificationModalService: NotificationModalService
  ) { }
}
