<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
></app-modal-container>

<ng-template #title>
  <app-modal-title>Team List ({{ participantList.length }})</app-modal-title>
</ng-template>

<ng-template #content>
  <div class="c_participantList">
    <hr class="c_participantListModal__divider" />
    <ul class="c_participantList__list">
      <ng-container *ngFor="let participant of participantList">
        <li class="c_participantList__list__listItem">
          <img
            [src]="participant.teamLogoUrl | teamLogoDefault"
            class="c_participantList__image"
            alt="Team Logo"
          />
          <a
            [routerLink]="participant.teamId | teamDetailRouter"
            class="c_participantList__link"
          >
            <h4 class="u_txt--hdg4">{{ participant.teamName }}</h4>
            <p *ngIf="participant.subtext">{{ participant.subtext }}</p>
          </a>
        </li>
        <hr class="c_participantListModal__divider" />
      </ng-container>
    </ul>
  </div>
</ng-template>
