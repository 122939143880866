<div class="c_leagueMatchesBracketsContainer">
  <app-tabs-block>
    <app-tab tabTitle="Matches">
      <app-league-matches-block></app-league-matches-block>
    </app-tab>
    <app-tab tabTitle="Brackets">
      <app-bracket-controller> </app-bracket-controller>
    </app-tab>
    <app-tab tabTitle="Standings">
      <app-league-team-standings></app-league-team-standings>
    </app-tab>
  </app-tabs-block>
</div>
