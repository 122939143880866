import { Injectable } from "@angular/core";


export interface ProcessedFile {
  file: File;
  dataURL: string;
}


@Injectable({
  providedIn: "root",
})
export class ImageResizeService {

  /**
   * Takes a dom event and retrieves the file from the event,
   * and then tries to resize it
   *
   * @param event$
   * @author Christian Tweed
   */
  public readImage($event): Promise<ProcessedFile> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      if ($event.target.files && $event.target.files.length > 0) {
        const file = $event.target.files[0];
        this.resizeImage(file)
          .then((resizedFile: File) => {
            reader.onerror = reject;
            reader.readAsDataURL(resizedFile as File);

            reader.onload = () => {
              const dataURL = reader.result as string;
              resolve({
                file,
                dataURL,
              });
            };
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        //No Files to process
        reject({
          error: "No files available",
        });
      }
    });
  }

  /**
   * Takes an image and resizes it to a new max width and max height.
   *
   * @param file
   * @param maxWidth
   * @param maxHeight
   * @author Christian Tweed
   * @see https://jsfiddle.net/4p8w02gd/
   * @returns the file unaltered if the proportions of the file are below the maxWidth and maxHeight
   */
  public resizeImage = (file: File, maxWidth: number = 512, maxHeight: number = 512): Promise<File> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        const width = image.width;
        const height = image.height;

        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
        }

        let newWidth;
        let newHeight;
        if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        } else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }

        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;

        const context = canvas.getContext("2d");

        context.drawImage(image, 0, 0, newWidth, newHeight);
        canvas.toBlob((blob) => {
          resolve(this._blobToFile(blob, file.name));
        }, file.type);
      };
      image.onerror = reject;
    });


  /**
   * Converts a blob to a file.
   *
   * @param blob
   * @param fileName
   * @see https://stackoverflow.com/questions/27159179/how-to-convert-blob-to-file-in-javascript
   * @author Christian Tweed
   */
  private _blobToFile(blob: Blob, fileName: string): File {
    return new File([blob], fileName, {
      lastModified: new Date().getTime(),
      type: blob.type,
    });
  }
}
