import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { NgxSmartModalService } from "ngx-smart-modal";

import { RootState } from "src/app/reducers";
import { UpdateTeamRoster } from "src/app/reducers/teams/teams.actions";
import { TeamRosterUpdateActions } from "src/app/reducers/teams/teams.types";
import { LeaveTeamModalInformation } from "src/app/util/modal-helpers";
import { ModalController } from "../modal-controller/modal-controller.abstract";

@Component({
  selector: "app-leave-team-modal",
  templateUrl: "./leave-team-modal.component.html",
  styleUrls: ["./leave-team-modal.component.scss"],
})
export class LeaveTeamModalComponent extends ModalController<LeaveTeamModalInformation> {
  private _teamData: LeaveTeamModalInformation;

  constructor(private _modalService: NgxSmartModalService, private _store: Store<RootState>, private _router: Router) {
    super(_modalService, "leaveTeam", _router);
    this._teamData = this.getData();
  }

  public leaveTeam(): void {
    this._store.dispatch(
      new UpdateTeamRoster({
        teamUserId: this._teamData.userTeamId,
        rosterAction: TeamRosterUpdateActions.DELETE_PLAYER,
      })
    );
    this.close();
  }
}
