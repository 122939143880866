<div class="c_stepperContainer">
  <div class="c_stepperContainer__form">
    <app-integrated-checkout
      [paymentItem]="paymentItem"
      (paymentSuccess)="paymentSuccessFunction($event)"
    >
    </app-integrated-checkout>
  </div>
  <div class="c_stepperContainer__btns">
    <app-modal-stepper-footer-buttons
      [hasNext]="false"
      [hasPrevious]="true"
    ></app-modal-stepper-footer-buttons>
  </div>
</div>
