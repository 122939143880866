<app-modal-container
  [modalTitle]="header"
  [modalContent]="content"
></app-modal-container>

<ng-template #header>
  <app-modal-title>GGLeagues Now Has Text Notifications!</app-modal-title>
</ng-template>

<ng-template #content>
  <div class="c_textModalBody">
    <img
      class="c_textModal__logoImg"
      src="/assets/images/modal_images/text_notification_modal/text-notifications-logo.png"
      alt="Phone with GGLeagues Logo"
    />
    <p class="u_txt--body">
      Want updates on the go? GGLeagues now offers text notifications for games!
      To start receiving them, just enable notifications from your profile
      settings!
    </p>
    <div class="c_textModal__buttonContainer">
      <button class="c_button secondary_dark" (click)="fullDismiss()">
        Not Now
      </button>
      <button class="c_button primary_dark" (click)="openTextNotifManagement()">
        Setup Notifications
      </button>
    </div>
  </div>
</ng-template>
