import { Injectable } from "@angular/core";
import {
  Actions,
  Effect,
  ofType,
} from "@ngrx/effects";
import {
  map,
  mergeMap,
  catchError,
} from "rxjs/operators";
import { of } from "rxjs";
import { ToastrService } from "ngx-toastr";

import { ChatroomService } from "@services/chatroom/chatroom.service";
import { MatchesService } from "@services/matches/matches.service";

import {
  MatchesActionTypes,
  GetSeriesMatchupById,
  GetSeriesMatchupByIdSuccess,
  GetSeriesMatchupByIdError,
  FetchUnknownMatchCommenters,
  UpdateMatchCommenters,
  FetchUnknownMatchCommentersError,
} from "./matches.actions";

import { Logger as logger } from "../../util/logger";

@Injectable()
export class MatchesEffects {
  @Effect()
  public seriesMatchup$ = this._actions$.pipe(
    ofType<GetSeriesMatchupById>(MatchesActionTypes.GET_SERIES_MATCHUP_BY_ID),
    map((action) => action.payload),
    mergeMap((id) => this._matchesService.getSeriesMatchupV2ById(id)),
    mergeMap((seriesMatchup) => {
      if (seriesMatchup) {
        return of(new GetSeriesMatchupByIdSuccess(seriesMatchup));
      }
      this._toastr.error("Could Not Find That Series Matchup", "Error");
      logger.error("1Could Not Fetch Series Matchup");
      logger.error({
        match: seriesMatchup,
      });
      return of(new GetSeriesMatchupByIdError());

    }),
    catchError((err) => {
      this._toastr.error("Could Not Find That Series Matchup", "Error");
      logger.error("2Could Not Fetch Series Matchup");
      console.error(err);
      return of(new GetSeriesMatchupByIdError());
    })
  );

  @Effect()
  public FetchUnknownMatchCommenters$ = this._actions$.pipe(
    ofType<FetchUnknownMatchCommenters>(MatchesActionTypes.FETCH_UNKNOWN_MATCH_COMMENTERS),
    map((action) => action.payload),
    mergeMap((ids) =>
      this._chat.getUsernames({
        user_ids: ids,
        limit: ids.length,
      })
    ),
    mergeMap((response) => {
      if(!response){
        logger.error("Could not load any usernames");
        return of(new FetchUnknownMatchCommentersError());
      }
      const usernameMap = new Map<string, string>();
      response.users.forEach(user => {
        usernameMap.set(user.id.toString(), user.inGameName);
      });
      return of(new UpdateMatchCommenters(Array.from(usernameMap)));
    }),
    catchError((err) => {
      logger.error(err);
      return of(new FetchUnknownMatchCommentersError());
    })
  );

  constructor(
    private _actions$: Actions,
    private _matchesService: MatchesService,
    private _toastr: ToastrService,
    private _chat: ChatroomService
  ) {}
}
