<ul class="c_leagueTeamStandingsList">
  <li
    class="
      c_leagueTeamStandingsList__entry c_leagueTeamStandingsList__entry--header
    "
  >
    <span class="c_leagueTeamStanding__rank">Rank</span>
    <span class="c_leagueTeamStanding__team">Team</span>
    <ng-container *ngIf="useScoresAsStandings">
      <span class="c_leagueTeamStanding__wins">Score</span>
    </ng-container>
    <ng-container *ngIf="!useScoresAsStandings">
      <span class="c_leagueTeamStanding__wins">Wins</span>
      <span class="c_leagueTeamStanding__losses">Losses</span>
    </ng-container>
  </li>
  <ng-container *ngIf="useScoresAsStandings">
    <li
      *ngFor="let teamStanding of standingsByScore; let i = index"
      class="c_leagueTeamStandingsList__entry"
    >
      <span
        class="c_leagueTeamStanding__rank c_leagueTeamStanding__rank--{{
          i + 1
        }}"
      >
        <span class="c_leagueTeamStanding__rank--value">
          {{ i + 1 }}
        </span>
      </span>
      <span class="c_leagueTeamStanding__team">
        <a routerLink="/teams/{{ teamStanding.id }}">{{ teamStanding.name }}</a>
      </span>
      <span class="c_leagueTeamStanding__wins">{{ teamStanding.score }}</span>
    </li>
  </ng-container>
  <ng-container *ngIf="!useScoresAsStandings">
    <li
      *ngFor="let teamStanding of standingsBySeries; let i = index"
      class="c_leagueTeamStandingsList__entry"
    >
      <span
        class="c_leagueTeamStanding__rank c_leagueTeamStanding__rank--{{
          i + 1
        }}"
      >
        <span class="c_leagueTeamStanding__rank--value">
          {{ i + 1 }}
        </span>
      </span>
      <span class="c_leagueTeamStanding__team">
        <a routerLink="/teams/{{ teamStanding.id }}">{{
          teamStanding.title
        }}</a>
      </span>
      <span class="c_leagueTeamStanding__wins">{{
        teamStanding.seriesWins
      }}</span>
      <span class="c_leagueTeamStanding__losses">{{
        teamStanding.seriesLosses
      }}</span>
    </li>
  </ng-container>
</ul>
