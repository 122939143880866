import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Validators, FormControl } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Router } from "@angular/router";
import { MatHorizontalStepper } from "@angular/material/stepper";
import { StepperSelectionEvent } from "@angular/cdk/stepper";

import { MatchReportService } from "@services/match-report/match-report.service";
import { Unsubscriber } from "@utils/unsubscriber";
import { ModalStep } from "@apptypes/view-types/modal.types";

import {
  MatchReport,
  MatchReportGame,
  MatchReportSubmissionType,
} from "src/app/reducers/matches/matches.types";
import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import { ModalController } from "../modal-controller/modal-controller.abstract";
import { ModalStepperFooterButtonsComponent } from "../modal-stepper-footer-buttons/modal-stepper-footer-buttons.component";
import { SubmissionChoiceStepComponent } from "../submission-choice-step/submission-choice-step.component";
import { ReportResultsStepComponent } from "../report-results-step/report-results-step.component";
import { ReportConfirmationStepComponent } from "../report-confirmation-step/report-confirmation-step.component";
import { ForfeitTeamStepComponent } from "../forfeit-team-step/forfeit-team-step.component";
import { ForfeitConfirmationStepComponent } from "../forfeit-confirmation-step/forfeit-confirmation-step.component";

@Component({
  selector: "app-self-report-modal",
  templateUrl: "./self-report-modal.component.html",
  styleUrls: ["./self-report-modal.component.scss"],
})
export class SelfReportModalComponent extends ModalController<MatchReport>  implements AfterViewInit, OnDestroy {
  public static MODAL_ID = "selfReport";

  @ViewChild(MatHorizontalStepper) public stepper: MatHorizontalStepper;
  @ViewChild(SubmissionChoiceStepComponent) public submissionChoiceStep: ModalStep;
  @ViewChild(ReportResultsStepComponent) public reportResultsStep: ModalStep;
  @ViewChild(ReportConfirmationStepComponent) public reportConfirmationStep: ModalStep;
  @ViewChild(ForfeitTeamStepComponent) public forfeitTeamStep: ModalStep;
  @ViewChild(ForfeitConfirmationStepComponent) public forfeitConfirmationStep: ModalStep;

  public activeFooterButtons: TemplateRef<ModalStepperFooterButtonsComponent> = null;

  public submissionChoiceControl = new FormControl(null, [Validators.required]);
  public gameReportsControl = new FormControl(null, [Validators.required]);
  public forfeittingTeamIdControl = new FormControl("", [Validators.required]);

  public gamePlatforms = gamePlatforms;
  public matchReportTypes = MatchReportSubmissionType;
  public matchReport: MatchReport;

  private _unsub = new Unsubscriber();

  constructor(
    private _matchService: MatchReportService,
    private _modalService: NgxSmartModalService,
    private _router: Router,
    private _cd: ChangeDetectorRef
  ) {
    super(_modalService, SelfReportModalComponent.MODAL_ID, _router);
    this.matchReport = this.getData();
  }

  public ngAfterViewInit(): void {
    this.stepper.selectionChange.pipe(
      takeUntil(this._unsub.unsubEvent)
    ).subscribe((stepChangeEvent: StepperSelectionEvent) => {
      const activeStep = this._getActiveFooterButtons(stepChangeEvent.selectedIndex);
      if(activeStep){
        this.activeFooterButtons = activeStep.footerButtons;
      }
    });

    this.activeFooterButtons = this.submissionChoiceStep.footerButtons;
    this._cd.detectChanges();
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public get submissionChoice(): MatchReportSubmissionType | null {
    return this.submissionChoiceControl.value;
  }

  public get forfeittingTeamId(): string {
    return this.forfeittingTeamIdControl.value;
  }

  public get gameReports(): MatchReportGame[] {
    return this.gameReportsControl.value;
  }

  public submissionSuccessHandler(): void {
    this.close();
  }

  private _getActiveFooterButtons(index: number): ModalStep | null {
    switch(index){
      case 0:
        return this.submissionChoiceStep;
      case 1:
        return this.submissionChoice === MatchReportSubmissionType.FORFEIT ?
          this.forfeitTeamStep :
          this.reportResultsStep;
      case 2:
        return this.submissionChoice === MatchReportSubmissionType.FORFEIT ?
          this.forfeitConfirmationStep :
          this.reportConfirmationStep;
      default:
        return null;
    }
  }
}
