<li class="c_organizationLeaguePass">
  <a
    [routerLink]="leaguePass.id | passDetailsRouter"
    [queryParams]="leagueId$ | async | leagueContextQuery"
  >
    <i class="icon1 fas fa-ticket-alt fa-3x"></i>
    <h5 class="u_txt--hdg5">{{ leaguePass.title }} Esport Pass</h5>
    <i class="icon2 fas fa-external-link-alt fa-3x"></i>
  </a>
</li>
