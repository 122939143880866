<section>
  <header>
    <h3 class="u_txt--hdg3">Find Your Organization's Pass</h3>
  </header>
  <form [formGroup]="filterForm">
    <label>Title</label>
    <input
      class="c_organizationLeaguePassList__input"
      formControlName="filterTerm"
      type="text"
      placeholder="Type your organization's name to filter"
    />
  </form>
  <ul
    class="c_organizationLeaguePassList__list"
    *ngIf="filteredPassList.length > 0; else empty"
  >
    <app-league-pass-result
      [leaguePass]="pass"
      *ngFor="let pass of filteredPassList"
    >
    </app-league-pass-result>
  </ul>
  <ng-template #empty>
    <div
      class="c_leaguePassList__empty"
      *ngIf="!noResultsFilter; else noResults"
    >
      <i class="fas fa-search fa-3x"></i>
      <h4 class="u_txt--hdg4">There are no season passes for this league</h4>
    </div>

    <ng-template #noResults>
      <div class="c_leaguePassList__empty">
        <i class="fas fa-search fa-3x"></i>
        <h4 class="u_txt--hdg4">
          Cannot find any season passes matching your filter term
        </h4>
      </div>
    </ng-template>
  </ng-template>
</section>
