import {
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { Store } from "@ngrx/store";
import {
  pluck,
  take,
  takeUntil,
} from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { Observable, of } from "rxjs";
import { Title } from "@angular/platform-browser";

import { RootState } from "src/app/reducers";
import { GetTeamById } from "src/app/reducers/teams/teams.actions";
import { ComponentRouteParams } from "src/app/enums/routes/routeParams";
import { generateTeamDetailRoute } from "src/app/enums/routes/routePaths";
import { Unsubscriber } from "src/app/util/unsubscriber";

@Component({
  selector: "app-edit-team-roster-page",
  templateUrl: "./edit-team-roster-page.component.html",
  styleUrls: ["./edit-team-roster-page.component.scss"],
})
export class EditTeamRosterPageComponent implements OnInit, OnDestroy {
  public isPageLoading$: Observable<boolean> = of(true);
  public teamId: number;
  public teamName$: Observable<string | null> = of(null);

  private _unsub = new Unsubscriber();
  constructor(
    private _route: ActivatedRoute,
    private _store: Store<RootState>,
    private _titleService: Title
  ) {
    this.teamName$ = this._store.select("teams", "currentTeam").pipe(
      pluck("title"),
      takeUntil(this._unsub.unsubEvent)
    );
  }

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | Edit Team Roster");
    this._route.paramMap.pipe(take(1)).subscribe((params) => {
      this.teamId = +params.get(ComponentRouteParams.TEAM_ID);
      this._store.dispatch(new GetTeamById(this.teamId));
      this.isPageLoading$ = this._store
        .select("teams", "fetchingId")
        .pipe(takeUntil(this._unsub.unsubEvent));
    });
  }

  public ngOnDestroy() {
    this._unsub.kill();
  }

  public getTeamDetailRoute(id: number): string {
    return generateTeamDetailRoute(id.toString());
  }
}
