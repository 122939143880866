import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ModalController } from "../modal-controller/modal-controller.abstract";

@Component({
  selector: "app-dash-overdue-info-modal",
  templateUrl: "./dash-overdue-info-modal.component.html",
  styleUrls: ["./dash-overdue-info-modal.component.scss"],
})
export class DashOverdueInfoModalComponent extends ModalController<null> {
  public static modalId = "dashOverdueGameInfo";

  constructor(private _modalService: NgxSmartModalService, private _router: Router) {
    super(_modalService, DashOverdueInfoModalComponent.modalId, _router);
  }
}
