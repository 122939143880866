<div class="c_leagueMatchesPanel">
  <app-matches-preview
    [matches]="upcomingMatches"
    [matchType]="'Unplayed Matches'"
  ></app-matches-preview>
  <app-matches-preview
    [matches]="recentMatches"
    [matchType]="'Recently Played Matches'"
  ></app-matches-preview>
</div>
