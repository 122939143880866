
import { Component } from "@angular/core";

@Component({
  selector: "app-esports-pass-info-modal",
  templateUrl: "./esports-pass-info-modal.component.html",
  styleUrls: ["./esports-pass-info-modal.component.scss"],
})
export class EsportsPassInfoModalComponent {
  public static MODAL_NAME = "esports_pass_++info_modal";

  // constructor(
  //   private _ngxModalService: NgxSmartModalService
  // ) { }

  // @HostListener("window:popstate", ["$event"])
  // public dismissModal() {
  //   this._ngxModalService.close(EsportsPassInfoModalComponent.MODAL_NAME);
  // }

  // public ngOnDestroy() {
  //   if (window.history.state.modal) {
  //     history.back();
  //   }
  // }

  // public ngOnInit() {
  //   const modalState = {
  //     modal: true,
  //     desc: "Modal has been opened",
  //   };
  //   history.pushState(modalState, null);
  // }

  public openZendeskModal(): void {
    //This method is for the zendesk widget exposed by vendor scripts
    //SEE: https://developer.zendesk.com/api-reference/widget/core/#open
    // eslint-disable-next-line @typescript-eslint/dot-notation
    window["zE"]("webWidget", "open");
  }
}
