import {
  Component,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  loadStripe,
  Stripe,
  Token,
} from "@stripe/stripe-js";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ToastrService } from "ngx-toastr";

import { environment } from "@environments/environment";
import { PaymentService } from "@services/payment.service";

import { Logger } from "../../util/logger";

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
  providers: [PaymentService],
})
export class CheckoutComponent {
  @Input() public paymentId: number;
  @Output() public paymentSuccess = new EventEmitter<void>();

  public stripe: Stripe;
  public isProcessing = false;

  constructor(
    private _ngxSmartModalService: NgxSmartModalService,
    private _paymentService: PaymentService,
    private _toastr: ToastrService
  ) {}

  public async openModal() {
    this._ngxSmartModalService.getModal("myModal").open();
    this.stripe = await loadStripe(environment.stripeApiKey);
    const elements = this.stripe.elements();
    // Custom styling can be passed to options when creating an Element.
    const style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: "16px",
        color: "#32325d",
      },
    };

    // Create an instance of the card Element.
    const card = elements.create("card", {
      style,
    });

    // Add an instance of the card Element into the `card-element` <div>.
    card.mount("#card-element");

    const stripeTokenHandler = (token: Token) => {
      // Insert the token ID into the form so it gets submitted to the server
      const paymentForm = document.getElementById("payment-form");
      const hiddenInput = document.createElement("input");
      hiddenInput.setAttribute("type", "hidden");
      hiddenInput.setAttribute("name", "stripeToken");
      hiddenInput.setAttribute("value", token.id);
      paymentForm.appendChild(hiddenInput);

      this._paymentService.processPayment(token, this.paymentId).subscribe(
        //Success
        () => {
          this._ngxSmartModalService.getModal("myModal").close();
          this._toastr.success(
            "Your payment to GGLeagues was successful!",
            "Payment Processed"
          );
          this.paymentSuccess.emit();
          this.isProcessing = false;
        },
        //Error
        (err) => {
          Logger.error(err);
          this._toastr.error(
            "There was an error processing the payment, please try again",
            "Payment Error"
          );
          this.isProcessing = false;
        }
      );
    };

    // Create a token or display an error when the form is submitted.
    const form = document.getElementById("payment-form");
    form.addEventListener("submit", async (event) => {
      event.preventDefault();
      this.isProcessing = true;

      const { token, error } = await this.stripe.createToken(card);

      if (error) {
        // Inform the customer that there was an error.
        const errorElement = document.getElementById("card-errors");
        errorElement.textContent = error.message;
        Logger.error(error);
        this.isProcessing = false;
      } else {
        // Send the token to your server.
        stripeTokenHandler(token);
      }
    });
  }
}
