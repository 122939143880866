import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-utils";

import {
  TeamLeagueJoinable,
  TeamLeagueJoinableAPIData,
  TeamLeagueJoinableAPIDataError,
} from "./teams.v2.api.types";

@Injectable({
  providedIn: "root",
})
export class TeamsV2Service {
  constructor(private _http: HttpClient) { }

  public canTeamJoinLeague(teamId: string | number, leagueId: string | number): Observable<TeamLeagueJoinable> {
    const url = `${environment.apiBase}/api/v2/teams/${teamId}/leagues/${leagueId}/joinable`;
    const headers = basicAuthHeader();

    return this._http.get<TeamLeagueJoinableAPIData>(url, {
      headers,
    }).pipe(
      map(({ joinable }) => ({
        joinable,
        errors: [],
      })),
      catchError(({ error: { joinable, errors: apiErrors } }: TeamLeagueJoinableAPIDataError) => of({
        joinable: joinable ?? false,
        errors: [].concat(...apiErrors ?? ["There was a server error, please try again later"]),
      }))
    );
  }
}
