import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { OrganizationSearchTerms } from "@services/organizations/organization.api.types";
import { registerOrgNameSearchEvent, registerOrgZipSearchEvent } from "@utils/analytics/orgsearch.analytics";
import { SearchQuery } from "@utils/search-utils";

import { StatesEnum } from "src/app/enums/states.enum";

@Component({
  selector: "app-organization-search-menu",
  templateUrl: "./organization-search-menu.component.html",
  styleUrls: ["./organization-search-menu.component.scss"],
})
export class OrganizationSearchMenuComponent implements AfterViewInit, OnChanges {
  @Input() public useRouting = false;
  @Input() public paginationInfo: SearchQuery = null;
  @Input() public hasWhiteText = false;
  @Input() public searchTerms: OrganizationSearchTerms;
  @Output() public searchTermsChange: EventEmitter<OrganizationSearchTerms> = new EventEmitter<OrganizationSearchTerms>();

  public zipSearchForm: FormGroup;
  public searchForm: FormGroup;
  public states = Object.entries(StatesEnum);
  public searchByZipStartsActive = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this.searchForm = this._formBuilder.group({
      searchName: [""],
      state: [null],
    });

    this.zipSearchForm = this._formBuilder.group({
      zipCode: [""],
    });
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.useRouting) {
        const { name, page, state_matches: state, postal_code: zipCode } = this._route.snapshot.queryParams;
        if (!!name || !!page || !!state || !!zipCode) {

          if (page && this.paginationInfo) {
            this.paginationInfo.page = page;
          }

          const isZip = !!zipCode;

          if (isZip) {
            this.zipSearchForm.setValue({
              zipCode,
            });

            this.searchByZipStartsActive = true;

          }
          else {
            const searchFormData = {
              searchName: name ?? "",
              state: state ?? null,
            };

            this.searchForm.setValue(searchFormData);
          }

          this.searchOrganizations(isZip);
        }
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.useRouting && changes.paginationInfo && !changes.paginationInfo.firstChange) {
      const searchTerms = {
        ...this.searchTerms,
        page: this.paginationInfo?.page ?? 1,
      };
      this._updateUrl(searchTerms);
    }
  }

  public searchOrganizations(isZip = false): void {
    const searchTerms = this._readForm(isZip);
    if (isZip) {
      registerOrgZipSearchEvent(searchTerms.postal_code);
      this.searchForm.reset();
    } else {
      registerOrgNameSearchEvent(searchTerms.name, searchTerms.state_matches);
      this.zipSearchForm.reset();
    }
    if (this.useRouting) {
      this._updateUrl(searchTerms);
    }
    this.searchTermsChange.emit(searchTerms);
  }

  private _readForm(isZip = false): OrganizationSearchTerms {
    const postal_code = this.zipSearchForm.get("zipCode").value;
    const name = this.searchForm.get("searchName").value ?? "";
    const state_matches = this.searchForm.get("state").value ?? "";
    const searchTerms: OrganizationSearchTerms = isZip ?
      {
        postal_code,
      } :
      {
        name,
        state_matches,
      };

    searchTerms.page = 1; //Always restart at Page 1

    if (this.paginationInfo) {
      const { limit } = this.paginationInfo;
      if (limit) {
        searchTerms.limit = limit;
      }
    }

    return searchTerms;
  }

  /**
   * Take all the search terms and add them as queries to the
   * url
   *
   * @param searchTerms is the params for the search
   */
  private _updateUrl(searchTerms: OrganizationSearchTerms): void {
    //Limit is for other classes, but excluded here
    const { name, state_matches, page, postal_code } = searchTerms;
    const isZip = !!postal_code;
    if (isZip) {
      this._router.navigate([], {
        relativeTo: this._route,
        queryParams: {
          postal_code,
          name: "",
          state_matches: "",
          page,
        },
        queryParamsHandling: "merge",
      });
    } else {
      this._router.navigate([], {
        relativeTo: this._route,
        queryParams: {
          postal_code: "",
          name,
          state_matches,
          page,
        },
        queryParamsHandling: "merge",
      });
    }
  }
}
