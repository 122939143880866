import {
  Component,
  Input,
  OnChanges,
} from "@angular/core";

import { processImage, ImageTypes } from "../../util/image-utils";
import { generateTeamDetailRoute } from "src/app/enums/routes/routePaths";
import { ScrimmageTeam, SeriesMatchupV2Team } from "src/app/reducers/matches/matches.types";

@Component({
  selector: "app-team-preview-block",
  templateUrl: "./team-preview-block.component.html",
  styleUrls: ["./team-preview-block.component.scss"],
})
export class TeamPreviewBlockComponent implements OnChanges {
  @Input() public teamInfo: SeriesMatchupV2Team | ScrimmageTeam | null;
  @Input() public winnerId: string | null = null;

  public teamImage: string;

  public ngOnChanges() {
    this.teamImage = this.teamInfo ? processImage(this.teamInfo.logoUrl, ImageTypes.TEAM_RECT) : ImageTypes.TEAM_RECT;
  }

  public getTeamDetailRoute(id: string): string {
    return generateTeamDetailRoute(id);
  }

  public getTitleWithRecords(teamInfo: SeriesMatchupV2Team | ScrimmageTeam): string {
    switch (teamInfo.type) {
      case "scrimmageTeam":
        return teamInfo.title;
      case "team":
        return `${teamInfo.title} (${teamInfo.wins} - ${teamInfo.losses})`;
      default:
        return "";
    }
  }
}
