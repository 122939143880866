<app-page>
  <div class="c_userEditPageHeader">
    <h2 class="u_txt--hdg2 u_txt--white">Edit Profile</h2>
    <a
      routerLink="/{{ userRoutes.PROFILE }}"
      class="c_button secondary_light c_editProfile__backBtn"
      >Back to Profile</a
    >
  </div>
  <div class="c_userEditBlock">
    <form
      [formGroup]="userEditForm"
      (submit)="saveProfileUpdates(userEditForm, $event)"
      *ngIf="!isLoading; else loading"
    >
      <fieldset>
        <h4 class="u_txt--hdg4">Account Information</h4>
        <hr />
        <label class="c_inputLabel" for="firstName">First Name</label>
        <input
          class="c_input"
          type="text"
          placeholder="First Name"
          formControlName="firstName"
          id="firstName"
          name="firstName"
        />
        <p
          class="c_input__error"
          *ngIf="hasSpecificError('firstName', 'invalidCharacters')"
        >
          Your name contains invalid characters, please remove them
        </p>
        <p
          class="c_input__error"
          *ngIf="hasSpecificError('firstName', 'minLength')"
        >
          Your name is too short
        </p>
        <p
          class="c_input__error"
          *ngIf="hasSpecificError('firstName', 'noNameProvided')"
        >
          You must provide a name
        </p>
        <label class="c_inputLabel" for="lastName">Last Name</label>
        <input
          class="c_input"
          type="text"
          placeholder="Last Name"
          formControlName="lastName"
          id="lastName"
          name="lastName"
        />
        <p
          class="c_input__error"
          *ngIf="hasSpecificError('lastName', 'invalidCharacters')"
        >
          Your name contains invalid characters, please remove them
        </p>
        <p
          class="c_input__error"
          *ngIf="hasSpecificError('lastName', 'minLength')"
        >
          Your name is too short
        </p>
        <p
          class="c_input__error"
          *ngIf="hasSpecificError('lastName', 'noNameProvided')"
        >
          You must provide a name
        </p>
        <label class="c_inputLabel" for="email">Email Address</label>
        <input
          class="c_input"
          type="text"
          placeholder="Email"
          formControlName="email"
          id="email"
          name="email"
        />
        <p class="c_input__error" *ngIf="hasSpecificError('email', 'email')">
          You must provide a valid email address
        </p>
        <p class="c_input__error" *ngIf="hasSpecificError('email', 'required')">
          You must provide an email address
        </p>
        <label class="c_inputLabel" for="handle">Username</label>
        <input
          class="c_input"
          type="text"
          placeholder="Username"
          formControlName="username"
          id="handle"
          name="handle"
        />
        <label class="c_inputLabel" for="zipCode">Zip Code</label>
        <input
          class="c_input"
          type="text"
          placeholder="Zip Code"
          formControlName="zipCode"
          id="zipCode"
          name="zipCode"
        />
        <p
          class="c_input__error"
          *ngIf="hasSpecificError('zipCode', 'pattern')"
        >
          You must provide a valid zip code
        </p>
        <hr />
        <label class="c_inputLabel" for="discordName">Discord Name</label>
        <input
          class="c_input"
          type="text"
          placeholder="DiscordName#1234"
          formControlName="discordId"
          id="discordName"
          name="discordName"
        />
        <p
          class="c_input__error"
          *ngIf="hasSpecificError('discordId', 'pattern')"
        >
          Discord Name must be in format DiscordName#1234
        </p>
        <p
          class="c_input__error"
          *ngIf="hasSpecificError('discordId', 'profanity')"
        >
          Your discord name cannot contain profanity
        </p>
      </fieldset>
      <fieldset>
        <label class="c_inputLabel" for="description">Player Bio</label>
        <textarea
          class="c_input"
          placeholder="Bio/Description"
          formControlName="description"
          id="description"
          name="description"
        ></textarea>
        <p
          class="c_input__error"
          *ngIf="hasSpecificError('description', 'profanity')"
        >
          You cannot have profanity in your description
        </p>
      </fieldset>
      <br />
      <div class="c_userEditBlock__buttons">
        <app-form-footer-error-message
          *ngIf="formInvalid"
        ></app-form-footer-error-message>
        <button type="submit" class="c_button primary_dark">
          Update Profile
        </button>
      </div>
    </form>
    <ng-template #loading>
      <app-loader [useWhite]="true">Loading Profile...</app-loader>
    </ng-template>
  </div>
  <div class="c_userEditBlock__address">
    <app-user-edit-address-block></app-user-edit-address-block>
  </div>
  <div class="c_userEditBlock__notifications">
    <app-notification-management-panel></app-notification-management-panel>
  </div>
  <div class="c_userEditBlock__gameCredentials">
    <app-game-credentials-layout
      [isUser]="true"
      [isInEditMode]="true"
    ></app-game-credentials-layout>
  </div>
</app-page>
