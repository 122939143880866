<app-page>
  <div class="c_noPermissionsPage">
    <div class="c_noPermissionsPage__icon">
      <i class="fas fa-exclamation-triangle fa-8x"></i>
    </div>
    <div class="c_noPermissionsPage__text u_txt--hdg2">
      You do not have permission to access that page
    </div>
    <div class="c_noPermissionsPage__homeBtn">
      <a routerLink="/{{ routes.HOME }}" class="c_button primary_dark"
        >Return to Homepage</a
      >
    </div>
  </div>
</app-page>
