import {
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ModalStep } from "@apptypes/view-types/modal.types";
import { Subscription } from "rxjs";

import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import { MatchReport, MatchReportSubmissionType } from "src/app/reducers/matches/matches.types";
import { ModalStepperFooterButtonsComponent } from "../modal-stepper-footer-buttons/modal-stepper-footer-buttons.component";

const AutomaticallySubmittingEsports = [gamePlatforms.LEAGUE_OF_LEGENDS];

@Component({
  selector: "app-submission-choice-step",
  templateUrl: "./submission-choice-step.component.html",
  styleUrls: ["./submission-choice-step.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SubmissionChoiceStepComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => SubmissionChoiceStepComponent),
    },
  ],
})
export class SubmissionChoiceStepComponent implements ControlValueAccessor, Validator, OnDestroy, OnInit, ModalStep {
  @Input() public matchReport: MatchReport;
  @ViewChild("footerButtons") public footerButtons: TemplateRef<ModalStepperFooterButtonsComponent>;

  public submissionChoiceControl: FormControl;
  public isEsportAutoSubmitting = false;

  public gamePlatforms = gamePlatforms;
  public submissionTypes = MatchReportSubmissionType;

  private _onChangeSubscriptions: Subscription[] = [];

  constructor() {
    this.submissionChoiceControl = new FormControl(null, [Validators.required, this._leagueOfLegendsValidator]);
  }

  public ngOnInit(): void {
    this.isEsportAutoSubmitting = AutomaticallySubmittingEsports.includes(this.matchReport.esport);
  }

  public ngOnDestroy(): void {
    //Kill all subscriptions
    this._onChangeSubscriptions.forEach(sub => sub.unsubscribe());
  }

  public get submissionChoice(): MatchReportSubmissionType {
    return this.submissionChoiceControl.value;
  }

  public get submissionChoiceFormErrorMessage(): string {
    return this.submissionChoiceControl.invalid ?
      this.submissionChoiceControl.errors?.leagueOfLegendsForfeitError ?
        "You cannot report League of Legends matches" :
        "You must select a result type" :
      "";
  }

  public generateBestOfMessage(gameCount: number): string {
    if (gameCount > 1) {
      return "(Best of " + gameCount.toString() + ")";
    }
    return "";
  }

  //CVA Methods
  public onTouched = () => { };

  public writeValue(value: MatchReportSubmissionType): void {
    this.submissionChoiceControl.patchValue(value);
  }

  public registerOnChange(onChangeCallback: () => unknown): void {
    this._onChangeSubscriptions.push(this.submissionChoiceControl.valueChanges.subscribe(onChangeCallback));
  }

  public registerOnTouched(onTouched: () => unknown): void {
    this.onTouched = onTouched;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.submissionChoiceControl.disable();
    }
    else {
      this.submissionChoiceControl.enable();
    }
  }

  //Validator Methods

  public validate(): ValidationErrors | null {
    if (this.submissionChoiceControl.valid) {
      return null;
    }

    return this.submissionChoiceControl.errors;
  }

  private _leagueOfLegendsValidator: ValidatorFn = (control: FormControl) => {
    const isSubmission = control.value === MatchReportSubmissionType.SUBMISSION;
    const isLeagueOfLegends = this.matchReport?.esport === gamePlatforms.LEAGUE_OF_LEGENDS;

    if (isLeagueOfLegends && isSubmission) {
      return {
        leagueOfLegendsForfeitError: true,
      };
    }

    return null;
  };
}
