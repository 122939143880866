<div class="c_leagueHistoryBlock">
  <h4 class="u_txt--hdg4 c_leagueHistoryBlock__header">Leagues</h4>
  <div class="c_leagueHistoryBlock__grid">
    <div
      class="c_leagueHistoryBlock__grid__current"
      *ngIf="(activeLeagues$ | async)?.length > 0; else emptyCurrent"
    >
      <div class="c_leagueHistoryBlock__grid__header">
        <h5 class="u_txt--hdg5">
          Current Leagues ({{ (activeLeagues$ | async)?.length }})
        </h5>
        <a
          routerLink="/{{ getTeamLeagueRoute(teamId$ | async) }}"
          class="c_leagueHistoryBlock__seeAllLink"
          >See All &#9656;</a
        >
      </div>
      <a
        routerLink="/{{
          getLeagueDetailRoute((activeLeagues$ | async)[0]?.id)
        }}"
        class="c_leagueHistoryBlock__grid__current__linkContainer"
        *ngIf="(activeLeagues$ | async)?.length > 0"
      >
        <div class="c_leagueHistoryBlock__grid__current__preview">
          <app-logo-svg-white
            [isCircle]="true"
            [hasBackgroundColor]="true"
          ></app-logo-svg-white>
        </div>
        <div class="c_leagueHistoryBlock__grid__current__description">
          <div class="c_leagueHistoryBlock__grid__current__description__title">
            {{ (activeLeagues$ | async)[0].title }}
          </div>
        </div>
      </a>
      <div
        class="c_leagueHistoryBlock__grid__current__linkContainer"
        *ngIf="(activeLeagues$ | async)?.length === 0"
      >
        This team is not currently enrolled in an active league
      </div>
    </div>
    <ng-template #emptyCurrent>
      <div class="c_leagueHistoryBlock__grid__current">
        <div class="c_leagueHistoryBlock__grid__header">
          <h5 class="u_txt--hdg5">Current Leagues</h5>
        </div>
        <p class="u_txt--black">There are no current leagues for this team</p>
      </div>
    </ng-template>
    <div class="c_leagueHistoryBlock__grid__divider"></div>
    <div
      class="c_leagueHistoryBlock__grid__past"
      *ngIf="(pastLeagues$ | async)?.length > 0; else emptyPast"
    >
      <div class="c_leagueHistoryBlock__grid__header">
        <h5 class="u_txt--hdg5">Past Leagues</h5>
        <a
          routerLink="/{{ getTeamLeagueRoute(teamId$ | async) }}"
          class="c_leagueHistoryBlock__seeAllLink"
          >See All &#9656;</a
        >
      </div>
      <ul class="c_leagueHistoryBlock__grid__past__list">
        <li *ngFor="let pastLeague of pastLeagues$ | async">
          <a routerLink="/{{ getLeagueDetailRoute(pastLeague.id) }}">{{
            pastLeague.title
          }}</a>
        </li>
      </ul>
    </div>
    <ng-template #emptyPast>
      <div class="c_leagueHistoryBlock__grid__past">
        <div class="c_leagueHistoryBlock__grid__header">
          <h5 class="u_txt--hdg5">Past Leagues</h5>
        </div>
        <p class="u_txt--black">There are no past leagues for this team</p>
      </div>
    </ng-template>
  </div>
</div>
