import { Component, Input } from "@angular/core";
import { DashboardClubs } from "src/app/reducers/dashboard";

import { SearchOrganization } from "src/app/reducers/organization/organization.types";

@Component({
  selector: "app-organization-search-result",
  templateUrl: "./organization-search-result.component.html",
  styleUrls: ["./organization-search-result.component.scss"],
})
export class OrganizationSearchResultComponent {
  @Input() public searchResult: SearchOrganization | DashboardClubs;
  @Input() public blue = false;

  /**
   * Generates the string for the css background image property
   *
   * @param link is the resource location to the displayed image
   * @returns the formatted url for the css display
   * @author Christian Tweed
   */
  public generateImageURL(link: string): string {
    return `url('${link}')`;
  }
}
