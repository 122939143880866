import { Component, Input } from "@angular/core";

@Component({
  selector: "app-logo-svg-white",
  templateUrl: "./logo-svg-white.component.html",
  styleUrls: ["./logo-svg-white.component.scss"],
})
export class LogoSvgWhiteComponent {
  @Input() public isCircle: boolean;
  @Input() public hasBackgroundColor: boolean;
  @Input() public useHoverColorShift: boolean;
}
