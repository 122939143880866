<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
></app-modal-container>

<ng-template #title>
  <app-modal-title
    >Why isn't my opponent's esports account showing?</app-modal-title
  >
</ng-template>

<ng-template #content>
  <p>
    If your opponent does not have an esports account linked in the roster
    segment, please use the <strong>Match Chat</strong> below to contact them
    and get the information you need to play! If they said they have added it
    recently, you may need to refresh the matchup page to see it
  </p>
  <p>
    If you are unable to get in touch with your opponent through chat and get
    their esport account information by the time your series starts, please use
    the <strong>Contact Admin</strong> button below to get help.
  </p>
</ng-template>
