import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";

import { environment } from "@environments/environment";
import { Logger } from "@utils/logger";

import { basicAuthHeader } from "src/app/util/auth-utils";
import {
  ClubsDashAPIResponse,
  DashboardV2APILeagues,
  DashboardV2APINextMatchups,
  DashboardV2APIQueueSession,
  DashboardV2APIResponse,
  DashboardV2APITeams,
} from "./dashboard.v2.api.types";
import {
  DashboardClubs,
  DashboardLeague,
  DashboardLeagueFlatMap,
  DashboardLeagueMap,
  DashboardLeagueTeam,
  DashboardNextMatch,
  DashboardQueue,
  UserDashboard,
} from "src/app/reducers/dashboard";
import { apiGamePlatforms, apiToPlatform } from "src/app/enums/game-platforms.enum";
import { QueueSessionStatuses } from "../leagues/leagues.v2.api.types";
import { isISODateInPast } from "@utils/date-utils";
import { addMinutes, parseISO } from "date-fns";


@Injectable({
  providedIn: "root",
})
export class DashboardV2Service {
  private _sortedMatchesCache: DashboardV2APINextMatchups[] = [];
  private _dashboardLeaguesCache: DashboardV2APILeagues[] = [];
  private _dashboardTeamsCache: DashboardV2APITeams[] = [];
  private _dashboardQueuesCache: DashboardV2APIQueueSession[] = [];

  constructor(private _http: HttpClient) { }

  public fetchDashboardData(): Observable<UserDashboard> {
    const url = `${environment.apiBase}/api/v2/dashboards`;
    const opts = {
      headers: basicAuthHeader(),
    };
    return this._http
      .get<DashboardV2APIResponse>(url, opts)
      .pipe(mergeMap(async (apiRes) => await this._mapApiResponseToUserDashboard(apiRes)));
  }

  private async _mapApiResponseToUserDashboard(apiResponse: DashboardV2APIResponse): Promise<UserDashboard> {
    try {
      await this._setupSharedCaches(apiResponse);
      return {
        nextMatch: this._mapApiResponseToNextMatch(this._sortedMatchesCache[0]),
        nextQueue: this._mapApiResponseToNextQueue(this._dashboardQueuesCache),
        leagues: this._mapApiResponseToLeagueOverview(this._dashboardLeaguesCache),
        userClubs: this._mapApiResponseToUserClubs(apiResponse.data.attributes.clubs.data),
        updatedOn: new Date().toISOString(),
      };
    } catch (e) {
      Logger.error(e);
      return {
        nextMatch: null,
        leagues: [],
        nextQueue: null,
        userClubs: [],
        updatedOn: null,
      };
    }
  }

  private async _setupSharedCaches(apiResponse: DashboardV2APIResponse): Promise<void> {
    this._sortedMatchesCache = this._setupMatches(apiResponse.data.attributes.nextMatchups.data);
    this._dashboardLeaguesCache = apiResponse.data.attributes.leagues.data;
    this._dashboardTeamsCache = apiResponse.data.attributes.teams.data;
    this._dashboardQueuesCache = this._setupQueues(apiResponse.data.attributes.upcomingTournamentQueueSession.data);
  }

  private _setupQueues(apiQueues: DashboardV2APIQueueSession[] = []): DashboardV2APIQueueSession[] {
    const sortedQueues = [...apiQueues].sort((a, b) => {
      if (a.attributes.startTime > b.attributes.startTime) {
        return 1;
      }

      if (b.attributes.startTime > a.attributes.startTime) {
        return -1;
      }

      return 0;
    });
    return sortedQueues;
  }

  private _setupMatches(apiNextMatchResponse: DashboardV2APINextMatchups[]): DashboardV2APINextMatchups[] {
    const matchArray = [...apiNextMatchResponse];

    matchArray.sort((matchA, matchB) => {
      const matchAStartTime = matchA.attributes.matchupStartTime;
      const matchBStartTime = matchB.attributes.matchupStartTime;
      if (matchAStartTime > matchBStartTime) {
        return 1;
      }
      if (matchAStartTime < matchBStartTime) {
        return -1;
      }
      return 0;
    });

    return matchArray;
  }

  private _mapApiResponseToNextMatch(
    apiDashboardResponse: DashboardV2APINextMatchups,
    apiEsport?: apiGamePlatforms
  ): DashboardNextMatch | null {
    const nextMatch = apiDashboardResponse;
    if (nextMatch) {
      return {
        matchId: nextMatch.id,
        matchTime: nextMatch.attributes.matchupStartTime,
        matchTitle: nextMatch.attributes.title,
        esport: apiToPlatform(apiEsport ?? this._findEsportByLeagueId(nextMatch.attributes.leagueId)),
        leagueId: nextMatch.attributes.leagueId,
        leagueName: nextMatch.attributes.leagueTitle,
        gameType: "standard",
      };
    }

    return null;
  }

  private _mapDashQueueToNextMatch(
    apiDashQueue: DashboardV2APIQueueSession
  ): DashboardNextMatch {
    return {
      matchId: null,
      matchTime: apiDashQueue.attributes.startTime,
      matchTitle: "League Match Queue Session",
      esport: apiToPlatform(this._findEsportByLeagueId(apiDashQueue.attributes.leagueId)),
      leagueId: apiDashQueue.attributes.leagueId,
      leagueName: "queue_for_given_league",
      gameType: "queue",
    };
  }

  private _mapApiResponseToLeagueOverview(apiLeagueResponse: DashboardV2APILeagues[]): DashboardLeagueFlatMap {
    if (apiLeagueResponse.length > 0) {
      const apiLeagues = apiLeagueResponse;
      const leagueMap: DashboardLeagueMap = new Map();
      apiLeagues.forEach((leagueObj) => {
        const formattedLeagueObj: DashboardLeague = {
          id: leagueObj.id,
          name: leagueObj.attributes.title,
          leagueTeam: this._mapApiTeamToLeague(leagueObj.attributes.teamIds),
          leagueNextMatch: this._findAndMapNextMatch(leagueObj.id),
        };
        leagueMap.set(formattedLeagueObj.id, formattedLeagueObj);
      });
      return Array.from(leagueMap);
    }

    return [];
  }

  private _mapApiResponseToUserClubs(apiClubsResponse: ClubsDashAPIResponse[]): DashboardClubs[] {
    return apiClubsResponse.map((apiClub) => ({
      id: apiClub.id,
      type: "club",
      organizationId: apiClub.attributes.organization.data.id,
      name: apiClub.attributes.organization.data.attributes.name,
      slug: apiClub.attributes.organization.data.attributes.slug,
      state: apiClub.attributes.organization.data.attributes.state,
      logoUrl: apiClub.attributes.organization.data.attributes.logoUrl,
    }));
  }

  private _mapApiTeamToLeague(teamIds: number[]): DashboardLeagueTeam {
    const mappedTeam = this._dashboardTeamsCache.find((teamObj) => teamIds.indexOf(parseInt(teamObj.id, 10)) !== -1);
    if (mappedTeam) {
      return {
        id: mappedTeam.id,
        title: mappedTeam.attributes.title,
        logoUrl: mappedTeam.attributes.logoUrl,
        teamType: mappedTeam.attributes.teamType,
      };
    }
    return null;
  }

  private _mapApiResponseToNextQueue(queues: DashboardV2APIQueueSession[] = []): DashboardQueue {
    const nextActiveQueue = queues.find((queue) => queue.attributes.status !== QueueSessionStatuses.COMPLETED);
    if (nextActiveQueue) {
      const MINUTES_BEFORE_QUEUE = -10; // Queue opens 10m before start time
      const startTimeWithTenMinBuffer = addMinutes(parseISO(nextActiveQueue.attributes.startTime), MINUTES_BEFORE_QUEUE).toISOString();
      const isQueueOpen = isISODateInPast(startTimeWithTenMinBuffer);

      return {
        id: nextActiveQueue.id,
        ...nextActiveQueue.attributes,
        isQueueOpen,
      };
    }

    return null;
  }

  private _findAndMapNextMatch(leagueId: string): DashboardNextMatch | null {
    const parsedId = parseInt(leagueId, 10);
    const nextMatch = this._sortedMatchesCache.find((matches) => matches.attributes.leagueId === parsedId);
    if (nextMatch) {
      return this._mapApiResponseToNextMatch(nextMatch);
    } else {
      const nextQueue = this._dashboardQueuesCache.filter(
        (queue) => queue.attributes.leagueId === parsedId
      ).find(
        (leagueQueues) => leagueQueues.attributes.status !== QueueSessionStatuses.COMPLETED
      );
      if (nextQueue) {
        return this._mapDashQueueToNextMatch(nextQueue);
      }
    }
    return null;
  }

  private _findEsportByLeagueId(leagueId: number): apiGamePlatforms {
    const associatedLeague = this._dashboardLeaguesCache.find((league) => league.id === leagueId.toString());
    return associatedLeague?.attributes?.esportName ?? apiGamePlatforms.OTHER;
  }
}
