import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ModalController } from "../modal-controller/modal-controller.abstract";

@Component({
  selector: "app-match-has-results-modal",
  templateUrl: "./match-has-results-modal.component.html",
  styleUrls: ["./match-has-results-modal.component.scss"],
})
export class MatchHasResultsModalComponent extends ModalController<void> {

  public static MODAL_NAME = "game_has_results_modal";

  constructor(private _modalService: NgxSmartModalService, private _router: Router) {
    super(_modalService, MatchHasResultsModalComponent.MODAL_NAME, _router);
  }

}
