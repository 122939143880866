import { Pipe, PipeTransform } from "@angular/core";

import { Nullish } from "@apptypes/generic-nullish.types";

import { generateEsportPassRoute } from "src/app/enums/routes/routePaths";
import { validatePipeRoute } from "../utils/routing-pipes.utils";

@Pipe({
  name: "passDetailsRouter",
})
export class PassDetailsRouterPipe implements PipeTransform {
  public transform = (esportPassId: Nullish<string | number>): string[] => validatePipeRoute(esportPassId, generateEsportPassRoute);
}
