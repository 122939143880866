import { Subject } from "rxjs";

export class Unsubscriber {
  private _unsubscribe$ = new Subject<void>();

  public get unsubEvent(): Subject<void> {
    return this._unsubscribe$;
  }

  public kill(): void {
    // Executes and kills all active subscriptions on destroy
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
