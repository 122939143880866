<ng-container *ngIf="nextQueue$ | async as nextQueue">
  <div class="c_dashNextQueue">
    <h2 class="u_txt--hdg2 c_dashNextQueue__title">
      <span class="c_dashNextQueue__title__label">
        Your Next Match Queue:
      </span>
      <strong class="c_dashNextQueue__title__datetime">
        {{ nextQueue.startTime | date: "MMMM d @ h:mm a" }}
      </strong>
    </h2>
    <ng-container *ngIf="leagueInfoLoading$ | async">
      <app-loader> Loading Queue Data... </app-loader>
    </ng-container>
    <ng-container *ngIf="leagueData$ | async as leagueData">
      <div class="c_dashNextQueue__detailsGrid">
        <div
          class="c_dashNextQueue__gameImg"
          [style]="showEsportImage(leagueData.esport)"
        ></div>
        <div class="c_dashNextQueue__details">
          <dl class="c_dashNextQueue__details__infoList">
            <dt>League Name:</dt>
            <dd>{{ leagueData.title }}</dd>
            <dt>Queue Status:</dt>
            <dd>
              {{ getQueueStatusMessage(nextQueue) }}
            </dd>
          </dl>
        </div>
        <div class="c_dashNextQueue__buttonContainer">
          <a
            [routerLink]="leagueQueueLink(nextQueue.id, nextQueue.leagueId)"
            class="c_button primary_dark"
            *ngIf="nextQueue.isQueueOpen"
          >
            Open Queue
          </a>
          <a
            [routerLink]="leagueLink(nextQueue.leagueId)"
            class="c_button secondary_dark"
            >Go to League</a
          >
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
