import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/firestore";
import {
  combineLatest,
  Observable,
  of,
} from "rxjs";
import {
  map,
  shareReplay,
  switchMap,
} from "rxjs/operators";
import { Store } from "@ngrx/store";

import {
  QuickPlayEvent,
  QuickPlayEventStream,
  QuickPlayEventStreamCheckin,
  QuickPlayEventStreamData,
  QuickPlayEventStreamTournament,
  QuickPlayEventStreamTournamentTeam,
} from "@apptypes/quick-play-events.types";
import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-utils";
import { unixTimeToIsoString } from "@utils/date-utils";

import {
  APIQuickPlayEvent,
  APIQuickPlayEventStream,
  APIQuickPlayEventStreamCheckin,
  APIQuickPlayEventStreamTournament,
  APIQuickPlayEventStreamTournamentTeam,
} from "./quick-play-events.api.types";
import { FirestoreCollections, QuickPlayEventStreamCollections } from "src/app/enums/firestore.enum";
import { RootState } from "src/app/reducers";

@Injectable({
  providedIn: "root",
})
export class QuickPlayEventsService {
  /**
   * Get the current QuickPlayEventStreamData from the latest QuickPlayEvent in the redux store.
   * Note: This is not an auto-unsubscribing observable so make sure to use the async pipe or properly unsubscribe.
   */
  public currentQuickPlayEventStreamData$: Observable<QuickPlayEventStreamData | null> = this._store.select(
    "leagues", "quickPlayEvent"
  ).pipe(
    switchMap((quickPlayEvent: QuickPlayEvent | null) => {
      //Maybe filter here instead?
      if(quickPlayEvent === null){
        return of(null);
      }

      return this.getQuickPlayEventStream(quickPlayEvent.streamReferenceId);
    }),
    shareReplay(1)
  );

  constructor(private _http: HttpClient, private _fire: AngularFirestore, private _store: Store<RootState>) { }

  public getQuickPlayEvent(leagueID: string): Observable<QuickPlayEvent[]> {
    const url = `${environment.apiBase}/api/v2/leagues/${leagueID}/quick_play_events`;
    const options = {
      headers: basicAuthHeader(),
    };

    const mapAPIQuickPlayEvent = (
      { id, type, attributes: { streamReferenceId, status, chatroomReferenceId } }: APIQuickPlayEvent
    ): QuickPlayEvent => ({
      id,
      type,
      status,
      streamReferenceId,
      chatroomReferenceId,
    });

    const mapAPIQuickPlayEvents = ({ data }: { data: APIQuickPlayEvent[] }): QuickPlayEvent[] => data.map(mapAPIQuickPlayEvent);

    return this._http.get<{ data: APIQuickPlayEvent[] }>(url, options).pipe(
      map(mapAPIQuickPlayEvents)
    );
  }

  public checkinToQuickPlayEvent(quickPlayEventID: string, teamID: string): Observable<{ success: true }> {
    const url = `${environment.apiBase}/api/v2/quick_play_events/${quickPlayEventID}/quick_play_event_checkins`;
    const options = {
      headers: basicAuthHeader(),
    };
    const body = {
      quick_play_event_checkin: {
        team_id: teamID,
      },
    };

    return this._http.post<{ success: true }>(url, body, options);
  }

  public getQuickPlayEventStream(quickPlayEventStreamID: string): Observable<QuickPlayEventStreamData> {
    const mapQuickPlayEventStream = (apiQuickPlayEventStream: APIQuickPlayEventStream): QuickPlayEventStream => ({
      id: `${apiQuickPlayEventStream.ggleagues_id}`,
      leagueID: `${apiQuickPlayEventStream.league_id}`,
      startTime: apiQuickPlayEventStream.startTime,
      status: apiQuickPlayEventStream.status,
      createdAt: unixTimeToIsoString(apiQuickPlayEventStream.created_at.seconds),
    });

    const quickPlayEventStreamDoc = this._fire.collection(
      FirestoreCollections.QUICK_PLAY_EVENT_STREAMS
    ).doc<APIQuickPlayEventStream>(quickPlayEventStreamID);

    const quickPlayEventStream$ = quickPlayEventStreamDoc.valueChanges().pipe(
      map(mapQuickPlayEventStream)
    );

    return combineLatest([
      quickPlayEventStream$,
      this._getQuickPlayEventCheckins(quickPlayEventStreamDoc),
      this._getQuickPlayEventTournamentTeams(quickPlayEventStreamDoc),
      this._getQuickPlayEventTournaments(quickPlayEventStreamDoc),
    ]).pipe(
      map(([
        quickPlayEvent,
        checkins,
        tournamentTeams,
        tournaments,
      ]) => ({
        quickPlayEvent,
        checkins,
        tournamentTeams,
        tournaments,
      }))
    );
  }

  private _getQuickPlayEventCheckins(doc: AngularFirestoreDocument<APIQuickPlayEventStream>): Observable<QuickPlayEventStreamCheckin[]> {
    const mapQuickPlayEventCheckin = (
      { esport_credential_snapshot, quick_play_event_checkin_id, team_id, user_id }: APIQuickPlayEventStreamCheckin
    ): QuickPlayEventStreamCheckin => ({
      esportCredentialSnapshot: esport_credential_snapshot,
      quickPlayEventCheckinID: `${quick_play_event_checkin_id}`,
      teamID: `${team_id}`,
      userID: `${user_id}`,
    });

    return doc.collection<APIQuickPlayEventStreamCheckin>(QuickPlayEventStreamCollections.QUICK_PLAY_EVENT_CHECKINS).valueChanges().pipe(
      map(checkins => checkins.map(mapQuickPlayEventCheckin))
    );
  }

  private _getQuickPlayEventTournamentTeams(
    doc: AngularFirestoreDocument<APIQuickPlayEventStream>
  ): Observable<QuickPlayEventStreamTournamentTeam[]> {
    const mapQuickPlayEventTournamentTeam = (
      { status, team_id, tournament_id, user_ids }: APIQuickPlayEventStreamTournamentTeam
    ): QuickPlayEventStreamTournamentTeam => ({
      status,
      teamID: `${team_id}`,
      tournamentID: `${tournament_id}`,
      userIDs: user_ids.map(userID => `${userID}`),
    });
    return doc.collection(QuickPlayEventStreamCollections.TOURNAMENT_TEAMS).valueChanges().pipe(
      map(apiTeams => apiTeams.map(mapQuickPlayEventTournamentTeam))
    );
  }

  private _getQuickPlayEventTournaments(
    doc: AngularFirestoreDocument<APIQuickPlayEventStream>
  ): Observable<QuickPlayEventStreamTournament[]> {
    const mapQuickPlayEventTournament = (
      { scheduling_type, status, tournament_id }: APIQuickPlayEventStreamTournament
    ): QuickPlayEventStreamTournament => ({
      schedulingType: scheduling_type,
      status,
      tournamentID: `${tournament_id}`,
    });

    return doc.collection(QuickPlayEventStreamCollections.TOURNAMENTS).valueChanges().pipe(
      map(apiTournaments => apiTournaments.map(mapQuickPlayEventTournament))
    );
  }
}
