import {
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import {
  filter,
  pluck,
  takeUntil,
} from "rxjs/operators";

import { TeamDetails, TeamTypes } from "../../reducers/teams/teams.types";
import { RootState } from "src/app/reducers";
import { processImage, ImageTypes } from "../../util/image-utils";
import { Unsubscriber } from "src/app/util/unsubscriber";
import { generateTeamEditRoute } from "src/app/enums/routes/routePaths";

@Component({
  selector: "app-team-info-block",
  templateUrl: "./team-info-block.component.html",
  styleUrls: ["./team-info-block.component.scss"],
})
export class TeamInfoBlockComponent implements OnInit, OnDestroy {
  public currentTeam$: Observable<TeamDetails>;
  public losses: number;
  public winRatio: number;
  public teamImage: string;
  public userId: number;
  public isAccountTeam: boolean;

  private _unsub = new Unsubscriber();

  constructor(private _store: Store<RootState>) {}

  public ngOnInit() {
    this.currentTeam$ = this._store.select("teams", "currentTeam").pipe(
      filter((team) => team !== null),
      takeUntil(this._unsub.unsubEvent)
    );

    this.currentTeam$
      .pipe(takeUntil(this._unsub.unsubEvent))
      .subscribe((team) => {
        if (team) {
          this.losses = +team.completedGames - +team.wins;
          this.winRatio = +((+team.wins / +team.completedGames) * 100).toFixed(
            2
          );
          this.teamImage = processImage(team.logoUrl, ImageTypes.TEAM_SQ);
          this.isAccountTeam = team.teamType === TeamTypes.ACCOUNT_SOLO_TEAM;
        }
      });

    this._store
      .select("user", "currentUser")
      .pipe(
        filter((user) => user !== null),
        pluck("id"),
        takeUntil(this._unsub.unsubEvent)
      )
      .subscribe((id) => {
        this.userId = id;
      });
  }

  public ngOnDestroy() {
    this._unsub.kill();
  }

  public getTeamEditRoute(id: number): string {
    return generateTeamEditRoute(id.toString());
  }
}
