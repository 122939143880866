import {
  Component,
  Input,
  OnChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import {
  filter,
  first,
  map,
  pluck,
} from "rxjs/operators";

import { RootState } from "src/app/reducers";
import { LeagueBracketDetails, LeagueSeriesMatchupV2 } from "src/app/reducers/leagues/league.types";

@Component({
  selector: "app-battle-royale-matches-panel",
  templateUrl: "./battle-royale-matches-panel.component.html",
  styleUrls: ["./battle-royale-matches-panel.component.scss"],
})
export class BattleRoyaleMatchesPanelComponent implements OnChanges {
  @Input() public bracket: LeagueBracketDetails | null = null;
  public unplayedMatchups: LeagueSeriesMatchupV2[] = [];
  public recentlyPlayedMatchups: LeagueSeriesMatchupV2[] = [];

  constructor(private _store: Store<RootState>) { }

  public ngOnChanges(): void {
    if (this.bracket) {
      this.unplayedMatchups = [];
      this.recentlyPlayedMatchups = [];
      this._store.select("leagues", "league").pipe(
        filter(league => !!league),
        pluck("series"),
        map(series => series.filter(seriesMatch => seriesMatch.tournament.id.toString() === this.bracket.id)),
        first()
      ).subscribe(series => series.forEach(this._pushMatch));
    }
  }

  private _pushMatch = (match: LeagueSeriesMatchupV2): number => {
    const teamIds = match.teams.map(team => team.id);
    const matchHasWinner = teamIds.includes(match.winnerId) && !!match.winnerId;
    return matchHasWinner ? this.recentlyPlayedMatchups.push(match) : this.unplayedMatchups.push(match);
  };
}
