<div class="c_dashLeagueOverviewGrid" *ngIf="activeLeague">
  <div class="c_dashLeagueOverviewSidebar">
    <h3 class="u_txt--hdg3 c_dashLeagueOverviewSidebar__header">
      My Leagues
      <app-info-modal-button
        [modalId]="leagueInfoModal.modalId"
        [modalComponent]="leagueInfoModal"
      ></app-info-modal-button>
    </h3>
    <ul class="c_dashLeagueOverviewSidebar__leagueList">
      <li *ngFor="let league of activeLeagueList">
        <button
          class="c_button"
          (click)="changeActiveLeague(league)"
          [ngClass]="{
            secondary_dark: league.id !== activeLeague.id
          }"
        >
          {{ league.name }}
        </button>
      </li>
    </ul>
  </div>
  <div class="c_dashLeagueOverviewData">
    <ng-container *ngIf="activeLeague">
      <header>
        <h3 class="u_txt--hdg3">{{ activeLeague.name }}</h3>
        <div class="c_leagueOverviewHeaderBtns">
          <a
            [routerLink]="generateLeagueRoute(activeLeague.id)"
            class="c_button secondary_dark"
            >Go to League</a
          >
          <a
            [routerLink]="generateLeagueScheduleRoute(activeLeague.id)"
            class="c_button secondary_dark"
            >Go to Schedule</a
          >
        </div>
      </header>
      <div class="c_leagueOverviewPanel">
        <h4 class="u_txt--hdg4">My Team</h4>
        <app-team-block
          [team]="activeLeagueTeam"
          [centered]="true"
        ></app-team-block>
      </div>
      <div class="c_leagueOverviewPanel">
        <h4 class="u_txt--hdg4">My Next League Match</h4>
        <div class="c_leagueOverview__matchContainer">
          <app-dash-match-preview
            [match]="activeLeagueNextMatch"
          ></app-dash-match-preview>
        </div>
      </div>
    </ng-container>
  </div>
</div>
