<form [formGroup]="controlForm">
  <div>Please select your method of registration</div>
  <div class="c_regChoiceForm__radioGroup">
    <ng-container *ngIf="!sponsorOnlyMode">
      <input
        id="registrationReferral"
        formControlName="registrationType"
        type="radio"
        class="c_registrationType__radioInput"
        [value]="registrationTypes.REFERRAL"
      />
      <label
        class="c_registrationType__label"
        for="registrationReferral"
        [ngClass]="{
          c_registrationType__selectedRadio:
            registrationType === registrationTypes.REFERRAL
        }"
      >
        <div class="c_registrationType__label__icon">
          <i class="fas fa-users"></i>
        </div>
        Join by selecting a Referral Organization
      </label>
    </ng-container>
    <input
      id="registrationSponsor"
      formControlName="registrationType"
      type="radio"
      class="c_registrationType__radioInput"
      [value]="registrationTypes.SPONSOR"
    />
    <label
      class="c_registrationType__label"
      for="registrationSponsor"
      [ngClass]="{
        c_registrationType__selectedRadio:
          registrationType === registrationTypes.SPONSOR
      }"
    >
      <div class="c_registrationType__label__icon">
        <i class="fas fa-laptop-code"></i>
      </div>
      Join with Sponsor Code
    </label>
  </div>
</form>
<app-modal-stepper-footer-buttons
  [hasNext]="true"
  [hasPrevious]="!isFirstStep"
  [formToCheck]="controlForm"
  [errorMessage]="errorMessage"
>
</app-modal-stepper-footer-buttons>
