import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import {
  forkJoin,
  Observable,
  of,
} from "rxjs";
import {
  filter,
  first,
  map,
  pluck,
  switchMap,
  tap,
} from "rxjs/operators";

import { SeasonPassService } from "@services/v2/season-pass/season-pass.service";
import { LeagueSeasonPass } from "@apptypes/season-pass/season-pass.types";

import { ComponentRouteParams } from "src/app/enums/routes/routeParams";
import { RootState } from "src/app/reducers";
import { DashboardClubs } from "src/app/reducers/dashboard";
import { GetLeagueDashboard } from "src/app/reducers/dashboard/dashboard.actions";
import { GetLeagueById } from "src/app/reducers/leagues/league.actions";
import { GenericLeagueDetails } from "src/app/reducers/leagues/league.types";

interface LeaguePassPageAsyncData {
  league: GenericLeagueDetails;
  userClubs: DashboardClubs[];
  seasonPasses: LeagueSeasonPass[];
}

@Component({
  selector: "app-league-passes-page",
  templateUrl: "./league-passes-page.component.html",
  styleUrls: ["./league-passes-page.component.scss"],
})
export class LeaguePassesPageComponent {
  public league$: Observable<GenericLeagueDetails>;
  public data$: Observable<LeaguePassPageAsyncData | null> = of(null);

  constructor(
    private _store: Store<RootState>,
    private _router: ActivatedRoute,
    private _seasonPassService: SeasonPassService
  ) {
    this.league$ = this._setupLeagues();
    const userClubs$: Observable<DashboardClubs[]> = this._setupOrganizations();
    const seasonPass$ = this._fetchSeasonPasses();

    this.data$ = forkJoin([
      this.league$,
      userClubs$,
      seasonPass$,
    ]).pipe(
      map(([
        league,
        userClubs,
        seasonPasses,
      ]) => ({
        league,
        userClubs,
        seasonPasses,
      }))
    );
  }

  private _setupLeagues(): Observable<GenericLeagueDetails> {
    return this._store.select("leagues").pipe(
      filter(league => !league.fetchingLeagueById),
      tap((leagues) => {
        if(!leagues.league) {
          this._router.paramMap.pipe(first()).subscribe(paramMap => {
            const leagueId = paramMap.get(ComponentRouteParams.LEAGUE_ID);
            this._store.dispatch(new GetLeagueById(+leagueId));
          });
        }
      }),
      filter(leagues => !!leagues.league),
      pluck("league"),
      first()
    );
  }

  private _setupOrganizations(): Observable<DashboardClubs[]> {
    return this._store.select("dashboard", "updatedOn").pipe(
      tap(updatedOn => {
        if(!updatedOn) {
          this._store.dispatch(new GetLeagueDashboard());
        }
      }),
      filter(updatedOn => !!updatedOn),
      switchMap(() => this._store.select("dashboard", "userClubs")),
      first()
    );
  }

  private _fetchSeasonPasses(): Observable<LeagueSeasonPass[]> {
    return this.league$.pipe(
      map(league => league.id),
      switchMap(leagueId => this._seasonPassService.getLeagueSeasonPasses(leagueId.toString())),
      first()
    );
  }
}
