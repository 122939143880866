import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { CarouselGameCard } from "@apptypes/component-interfaces/carousel-cards.interface";
import { environment } from "@environments/environment";
import { registerNewUserDashPassEvent, registerNewUserDashWeekliesEvent } from "@utils/analytics/dashboard.analytics";
import KeenSlider, { KeenSliderInstance } from "keen-slider";

@Component({
  selector: "app-league-carousel-feature",
  templateUrl: "./league-carousel-feature.component.html",
  styleUrls: ["./league-carousel-feature.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeagueCarouselFeatureComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild("sliderRef") public sliderRef: ElementRef<HTMLElement>;

  @Input() public leaguesCategory: "weeklies" | "rec" = "weeklies";
  @Input() public gameCards: CarouselGameCard[] = [];
  @Input() public userOwnsPass = false;

  public slider: KeenSliderInstance = null;
  public currentSlide = 0;
  public sliderDotHelper: number[] = [];
  public recButtonCopy = "Purchase Pass";
  public isWeeklies = false;

  constructor(
    private _router: Router,
    private _cdr: ChangeDetectorRef
  ) { }

  public static createLeagueCarouselCard = (cardImgUrl: string, cardTitle: string): CarouselGameCard => ({
    cardImgUrl,
    cardTitle,
    imgAlt: `Picture of ${cardTitle}`,
  });

  public ngOnInit(): void {
    this.recButtonCopy = this.userOwnsPass ? "View Leagues" : "Purchase Pass";
    this.isWeeklies = this.leaguesCategory === "weeklies";
  }

  public ngAfterViewInit() {
    // Code ripped straight from KeenSlider website, not modifying it as it works perfect OOTB - AMM
    this.slider = new KeenSlider(
      this.sliderRef.nativeElement,
      {
        loop: true,
        initial: this.currentSlide,
        slideChanged: (s) => {
          this.currentSlide = s.track.details.rel;
          this._cdr.detectChanges();
        },
      },
      [
        (slider) => {
          let timeout;
          let mouseOver = false;
          const clearNextTimeout = () => {
            clearTimeout(timeout);
          };
          const nextTimeout = () => {
            clearTimeout(timeout);
            if (mouseOver) { return; };
            timeout = setTimeout(() => {
              slider.next();
            }, 2000);
          };
          slider.on("created", () => {
            slider.container.addEventListener("mouseover", () => {
              mouseOver = true;
              clearNextTimeout();
            });
            slider.container.addEventListener("mouseout", () => {
              mouseOver = false;
              nextTimeout();
            });
            nextTimeout();
          });
          slider.on("dragStarted", clearNextTimeout);
          slider.on("animationEnded", nextTimeout);
          slider.on("updated", nextTimeout);
        },
      ]
    );
    this.sliderDotHelper = [...Array(this.slider.track.details.slides.length).keys()];
    this._cdr.detectChanges();
  }

  public ngOnDestroy() {
    if (this.slider) { this.slider.destroy(); };
  }

  public cardUrl(card: CarouselGameCard): string {
    return card.cardImgUrl;
  }

  public weeklyButtonAction(): void {
    registerNewUserDashWeekliesEvent();
    this._router.navigate(["/leagues"], {
      queryParams: {
        league_types: "quick_play_league",
      },
    });
  }

  public seasonPassButtonAction(): void {
    if (this.userOwnsPass) {
      this._router.navigate(["/leagues"], {
        queryParams: {
          league_types: "recreational_league",
        },
      });
    } else {
      registerNewUserDashPassEvent();
      this._router.navigate([`/esports_pass/${environment.gglCurrentPass}`], {
        queryParams: {
          redirectTo: "dashboard",
        },
      });
    }
  }
}
