import { Provider } from "@angular/core";

import { CloudQuickPlayEventCommentService } from "@services/comment/cloud-quick-play-event-comment/cloud-quick-play-event-comment.service";

import { COMMENT_SERVICE } from "./comment-service.token";

export const QuickPlayEventCommentProvider: Provider = {
  provide: COMMENT_SERVICE,
  useClass: CloudQuickPlayEventCommentService,
};
