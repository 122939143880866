<app-page>
  <app-loader *ngIf="isLoading">Loading Instant Invite...</app-loader>
  <div class="c_inviteError" *ngIf="!isLoading && inviteHasError">
    <div class="c_inviteError__icon">
      <i class="fas fa-exclamation-triangle fa-8x"></i>
    </div>
    <h3 class="c_inviteError__text u_txt--hdg2">Error with Instant Invite</h3>
    <p>
      There is an error with your invite link. Either the link is either
      invalid, or it has been disabled by the captain of the team you are trying
      to join.
    </p>
    <div class="c_inviteError__homeBtn">
      <a routerLink="/{{ homeRoute }}" class="c_button primary_dark"
        >Return to Homepage</a
      >
    </div>
  </div>
</app-page>
