<div class="c_playerTeamBlock" *ngIf="teams?.length > 0; else noTeams">
  <h4 class="u_txt--hdg4 c_playerTeamBlock__header">Teams</h4>
  <ul class="c_playerTeamBlock__teamList">
    <li *ngFor="let team of teams">
      <app-team-block [team]="team"></app-team-block>
    </li>
  </ul>
</div>
<ng-template #noTeams>
  <div class="c_playerTeamBlock">
    <h4 class="u_txt--hdg4 c_playerTeamBlock__header">Teams</h4>
    <div class="c_playerTeamBlock__noTeams">
      <p class="u_txt">There are no teams for this profile.</p>
    </div>
  </div>
</ng-template>
