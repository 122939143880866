import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";

import { ModalController } from "../modal-controller/modal-controller.abstract";

@Component({
  selector: "app-account-team-explainer-modal",
  templateUrl: "./account-team-explainer-modal.component.html",
  styleUrls: ["./account-team-explainer-modal.component.scss"],
})
export class AccountTeamExplainerModalComponent extends ModalController<null> {
  constructor(private _modalService: NgxSmartModalService, private _router: Router) {
    super(_modalService, "accountTeam", _router);
  }
}
