<div class="c_matchupRosterTables">
  <h4 class="c_matchupRosterTable__header u_txt--hdg4">
    Team Rosters & {{ esportTagTitle }}s
  </h4>
  <div class="c_matchupRosterTables__container">
    <app-add-friend-button [esport]="esport"></app-add-friend-button>
    <div class="c_matchupRosterTables__tables">
      <ng-container
        *ngFor="let team of teams"
        [ngTemplateOutlet]="teamTable"
        [ngTemplateOutletContext]="{ $implicit: team }"
      ></ng-container>
    </div>
  </div>
</div>
<ng-template #teamTable let-team>
  <div class="c_matchupRosterTables__team">
    <a
      [routerLink]="getTeamDetailRoute(team.id)"
      class="c_matchupRosterTables__team--link"
    >
      <h5 class="u_txt--hdg5">{{ team.title }}:</h5>
    </a>
    <div class="c_matchupRosterTables__tableContainer">
      <table class="c_matchupRosterTables__table">
        <thead>
          <tr>
            <th>Player</th>
            <th>{{ esportTagTitle }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let player of sortedPlayers(team.players)"
            class="c_matchupRosterTables__tableRow"
          >
            <td>
              <a [routerLink]="getUserDetailRoute(player.id)" target="_blank">
                {{ player.username }}
              </a>
            </td>
            <td class="c_matchupRosterTables__credentialCell">
              <span *ngIf="player.esportCredential; else noCredential">
                {{ player.esportCredential }}
              </span>
              <ng-template #noCredential>
                <a
                  class="c_matchupRosterTables__credentialCell__ctaLink"
                  [routerLink]="profileEditRoute"
                  target="_blank"
                  *ngIf="
                    showUserCredentialPrompt(userId$ | async, player.id);
                    else noCredModal
                  "
                  >Add your {{ esportTagTitle }}
                  <i class="fas fa-external-link-alt"></i
                ></a>
                <ng-template #noCredModal>
                  <button
                    (click)="openNoCredInfoModal()"
                    class="c_matchupRosterTables__credentialCell__noCredButton"
                  >
                    Not Linked Yet <i class="far fa-question-circle"></i>
                  </button>
                </ng-template>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="c_matchupRosterMobileTable">
        <div
          *ngFor="let player of sortedPlayers(team.players)"
          class="c_matchupRosterMobileCard"
        >
          <div>
            <strong>Player: </strong>
            <br />
            <a [routerLink]="getUserDetailRoute(player.id)" target="_blank">
              {{ player.username }}
            </a>
          </div>
          <div>
            <strong>{{ esportTagTitle }}: </strong>
            <br />
            <span
              ><span *ngIf="player.esportCredential; else noCredential">
                {{ player.esportCredential }}
              </span>
              <ng-template #noCredential>
                <a
                  class="c_matchupRosterTables__credentialCell__ctaLink"
                  [routerLink]="profileEditRoute"
                  target="_blank"
                  *ngIf="
                    showUserCredentialPrompt(userId$ | async, player.id);
                    else noCredModal
                  "
                  >Add your {{ esportTagTitle }}
                  <i class="fas fa-external-link-alt"></i
                ></a>
                <ng-template #noCredModal>
                  <button
                    (click)="openNoCredInfoModal()"
                    class="c_matchupRosterTables__credentialCell__noCredButton"
                  >
                    Not Linked Yet <i class="far fa-question-circle"></i>
                  </button>
                </ng-template> </ng-template
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
