<app-modal-container [modalContent]="content"></app-modal-container>

<ng-template #content>
  <div class="c_textModalBody">
    <img
      class="c_textModal__logoImg"
      src="/assets/images/modal_images/text_notification_modal/text-notifications-logo.png"
      alt="Phone with GGLeagues Logo"
    />
    <h4 class="u_txt--hdg4">Verify Phone Number</h4>
    <p class="u_txt--body">
      To complete your text message notifications setup, please provide the
      6-Digit code sent to your phone.
    </p>
    <div class="c_inputContainer">
      <form [formGroup]="verifyForm">
        <label for="verifyCode">Verification Code</label>
        <input
          class="c_input"
          type="text"
          maxlength="6"
          formControlName="verifyCode"
        />
      </form>
    </div>
    <div *ngIf="formError">
      <small class="u_txt--warn">{{ formError }}</small>
    </div>
    <div class="c_textModal__buttonContainer">
      <button class="c_button secondary_dark" (click)="cancelNumber()">
        Cancel
      </button>
      <button class="c_button primary_dark" (click)="confirmNumber(verifyForm)">
        Confirm
      </button>
    </div>
  </div>
</ng-template>
