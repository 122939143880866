import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-utils";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FirestoreCollections } from "src/app/enums/firestore.enum";
import {
  LeagueQueue,
  LeagueQueueCheckin,
  LeagueQueueTournamentAPIResponse,
  QueuePreflightData,
} from "./league-queue.api.types";

@Injectable({
  providedIn: "root",
})
export class LeagueQueueService {

  constructor(
    private _http: HttpClient,
    private _firestore: AngularFirestore
  ) { }

  public checkinToLeagueQueue(queueId: string | number, teamId: string | number): Observable<unknown> {
    const url = `${environment.apiBase}/api/v2/tournament_queue_sessions/${queueId}/tournament_queue_session_checkins`;
    const payload = {
      tournament_queue_session_checkin: {
        team_id: teamId,
      },
    };
    const headers = basicAuthHeader();

    return this._http.post(url, payload, {
      headers,
    });
  }

  public getQueueStreamKey(tournamentId: string | number, queueId: string | number): Observable<string> {
    const url = `${environment.apiBase}/api/v2/tournaments/${tournamentId}/tournament_queue_sessions/${queueId}`;
    const headers = basicAuthHeader();
    return this._http.get<{ data: LeagueQueueTournamentAPIResponse }>(url, {
      headers,
    }).pipe(
      map((res) => {
        const queueStreamKey = res.data.attributes.streamReferenceId;
        return queueStreamKey;
      })
    );
  }

  public getQueuePreflightData(tournamentId: string | number, queueId: string | number): Observable<QueuePreflightData> {
    const url = `${environment.apiBase}/api/v2/tournaments/${tournamentId}/tournament_queue_sessions/${queueId}`;
    const headers = basicAuthHeader();
    return this._http.get<{ data: LeagueQueueTournamentAPIResponse }>(url, {
      headers,
    }).pipe(
      map((res) => {
        const queueStreamKey = res.data.attributes.streamReferenceId;
        const roundCount = res.data.attributes.matchRoundCount;
        return {
          queueStreamKey,
          roundCount,
        };
      })
    );
  }

  public getQueueStream(queueStreamKey: string): Observable<LeagueQueue> {
    return this._firestore.collection(FirestoreCollections.QUEUE_SESSION_STREAMS).doc<LeagueQueue>(queueStreamKey).valueChanges();
  }

  public getUserStreamCheckin(queueStreamKey: string, userId: number): Observable<LeagueQueueCheckin | null> {
    return this._firestore
      .collection(FirestoreCollections.QUEUE_SESSION_STREAMS)
      .doc(queueStreamKey)
      .collection<LeagueQueueCheckin>(FirestoreCollections.QUEUE_SESSION_STREAM_CHECKINS, ref => ref.where("user_id", "==", userId))
      .valueChanges().pipe(
        map((queueCheckinMatches) => {
          if (queueCheckinMatches.length === 0) {
            return null;
          } else {
            return queueCheckinMatches[0];
          }
        })
      );
  }

  public getUserTeamStreamCheckin(queueStreamKey: string, teamId: number): Observable<LeagueQueueCheckin | null> {
    return this._firestore
      .collection(FirestoreCollections.QUEUE_SESSION_STREAMS)
      .doc(queueStreamKey)
      .collection<LeagueQueueCheckin>(FirestoreCollections.QUEUE_SESSION_STREAM_CHECKINS, ref => ref.where("team_id", "==", teamId))
      .valueChanges().pipe(
        map((queueCheckinMatches) => {
          if (queueCheckinMatches.length === 0) {
            return null;
          } else {
            return queueCheckinMatches[0];
          }
        })
      );
  }
}
