import { Action } from "@ngrx/store";

import {
  Team,
  TeamDetails,
  TeamRosterUpdateActions,
} from "./teams.types";

export enum TeamsActionTypes {
  GET_TEAM_BY_ID = "[Team] Fetching a Team",
  GET_TEAMS = "[Team] Fetching Teams",
  GET_TEAM_BY_ID_SUCCESS = "[Team] Retreived Team",
  GET_TEAM_BY_ID_ERROR = "[Team] Failed to Retreive Team",
  GET_TEAMS_SUCCESS = "[Team] Retrieved teams",
  GET_TEAMS_ERROR = "[Team] Failed to Retrieve Teams",
  UPDATE_ROSTER = "[Team] Updating Team Roster",
  UPDATE_ROSTER_ERROR = "[Team] Update Roster Failed",
  UPDATE_ROSTER_SUCCESS = "[Team] Update Roster Success",
  ADD_TEAM_TO_LEAGUE = "[Team] Adding a Team to a League",
  ADD_TEAM_TO_LEAGUE_SUCCESS = "[Team] Added a Team to a League Successfully",
  ADD_TEAM_TO_LEAGUE_ERROR = "[Team] Failed to Add a Team to a League",
  ROSTER_REQUEST = "[Team] Requesting to Join Roster",
  ROSTER_REQUEST_ERROR = "[Team] Request to Join Roster Error",
  ROSTER_REQUEST_SUCCESS = "[Team] Request to Join Roster Success",
  LOCAL_TEAM_UPDATE = "[Team] Updating the team locally",
  LOCAL_TEAM_UPDATE_SUCCESS = "[Team] Update team locally success",
  LOCAL_TEAM_UPDATE_ERROR = "[Team] Update team locally error",
}

export class GetTeamById implements Action {
  public readonly type = TeamsActionTypes.GET_TEAM_BY_ID;
  constructor(public payload: number) {}
}
export class GetTeams implements Action {
  public readonly type = TeamsActionTypes.GET_TEAMS;
  constructor(public payload: void) {}
}
export class GetTeamsByIdSuccess implements Action {
  public readonly type = TeamsActionTypes.GET_TEAM_BY_ID_SUCCESS;
  constructor(public payload: TeamDetails) {}
}
export class GetTeamsSuccess implements Action {
  public readonly type = TeamsActionTypes.GET_TEAMS_SUCCESS;
  constructor(public payload: Team[]) {}
}
export class GetTeamByIdError implements Action {
  public readonly type = TeamsActionTypes.GET_TEAM_BY_ID_ERROR;
  constructor(public payload: void) {}
}

export class GetTeamsError implements Action {
  public readonly type = TeamsActionTypes.GET_TEAMS_ERROR;
  constructor(public payload: void) {}
}

export class UpdateTeamRoster implements Action {
  public readonly type = TeamsActionTypes.UPDATE_ROSTER;
  constructor(
    public payload: {
      teamUserId: number;
      rosterAction: TeamRosterUpdateActions;
    }
  ) {}
}

export class UpdateTeamRosterError implements Action {
  public readonly type = TeamsActionTypes.UPDATE_ROSTER_ERROR;
  constructor(public payload: void) {}
}

export class UpdateTeamRosterSuccess implements Action {
  public readonly type = TeamsActionTypes.UPDATE_ROSTER_SUCCESS;
  constructor(public payload: void) {}
}

export class AddTeamToLeague implements Action {
  public readonly type = TeamsActionTypes.ADD_TEAM_TO_LEAGUE;
  constructor(
    public payload: {
      teamId: number;
      leagueId: number;
    }
  ) {}
}

export class AddTeamToLeagueSuccess implements Action {
  public readonly type = TeamsActionTypes.ADD_TEAM_TO_LEAGUE_SUCCESS;
  constructor(public payload: void) {}
}

export class AddTeamToLeagueError implements Action {
  public readonly type = TeamsActionTypes.ADD_TEAM_TO_LEAGUE_ERROR;
  constructor(public payload: void) {}
}

export class RequestRoster implements Action {
  public readonly type = TeamsActionTypes.ROSTER_REQUEST;
  constructor(
    public payload: {
      teamId: string;
      userId: string;
    }
  ) {}
}

export class RequestRosterError implements Action {
  public readonly type = TeamsActionTypes.ROSTER_REQUEST_ERROR;
  constructor(public payload: void) {}
}

export class RequestRosterSuccess implements Action {
  public readonly type = TeamsActionTypes.ROSTER_REQUEST_SUCCESS;
  constructor(public payload: void) {}
}

export class LocalTeamUpdate implements Action {
  public readonly type = TeamsActionTypes.LOCAL_TEAM_UPDATE;
  constructor(public payload: Partial<TeamDetails>) {}
}

export type TeamsActions =
  | GetTeams
  | GetTeamById
  | GetTeamsSuccess
  | GetTeamsByIdSuccess
  | GetTeamsError
  | GetTeamByIdError
  | UpdateTeamRoster
  | UpdateTeamRosterError
  | UpdateTeamRosterSuccess
  | AddTeamToLeague
  | AddTeamToLeagueError
  | AddTeamToLeagueSuccess
  | RequestRoster
  | RequestRosterError
  | RequestRosterSuccess
  | LocalTeamUpdate;
