<app-tabs-block [darkBackground]="hasWhiteText">
  <app-tab tabTitle="Search by Name/State" [active]="!searchByZipStartsActive">
    <p
      class="c_organizationSearchMenu__hint"
      [ngClass]="{ 'u_txt--white': hasWhiteText }"
    >
      Search for an club using the name and state
    </p>
    <div class="c_organizationSearchMenu__body">
      <form
        class="c_organizationSearchMenu__searchBar"
        [formGroup]="searchForm"
        (ngSubmit)="searchOrganizations()"
      >
        <input
          class="c_input c_organizationSearchMenu__searchTerm"
          type="text"
          placeholder="Search for organization by name..."
          formControlName="searchName"
        />
        <select
          class="c_input c_organizationSearchMenu__stateSelect"
          formControlName="state"
        >
          <option [ngValue]="null" selected>All States</option>
          <option *ngFor="let state of states" [value]="state[0]">
            {{ state[1] }}
          </option>
        </select>
        <button
          type="submit"
          class="c_button c_organizationSearchMenu__searchButton"
          [ngClass]="{
            primary_light: hasWhiteText,
            primary_dark: !hasWhiteText
          }"
        >
          <i class="fas fa-search"></i>
        </button>
      </form>
    </div>
  </app-tab>
  <app-tab tabTitle="Search by Zip" [active]="searchByZipStartsActive">
    <p
      class="c_organizationSearchMenu__hint"
      [ngClass]="{ 'u_txt--white': hasWhiteText }"
    >
      Find clubs closest to your zip code!
    </p>
    <div class="c_organizationSearchMenu__body">
      <form
        class="c_organizationSearchMenu__searchBar"
        [formGroup]="zipSearchForm"
        (ngSubmit)="searchOrganizations(true)"
      >
        <input
          class="c_input c_organizationSearchMenu__searchZip"
          type="text"
          maxlength="5"
          appNumbersOnly
          placeholder="Search for organization by zipcode..."
          formControlName="zipCode"
        />
        <button
          type="submit"
          class="c_button c_organizationSearchMenu__searchButton"
          [ngClass]="{
            primary_light: hasWhiteText,
            primary_dark: !hasWhiteText
          }"
        >
          <i class="fas fa-search"></i>
        </button>
      </form>
    </div>
  </app-tab>
</app-tabs-block>
