import {
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import {
  map,
  takeUntil,
  filter,
  pluck,
  take,
  first,
} from "rxjs/operators";

import { RootState } from "src/app/reducers";
import { GenericLeagueDetails } from "src/app/reducers/leagues/league.types";
import { Unsubscriber } from "src/app/util/unsubscriber";
import { generateLeagueScheduleRoute, routePaths } from "src/app/enums/routes/routePaths";
import { isISODateInFuture } from "src/app/util/date-utils";

import { RankedTeam, RankedTeamScore } from "src/app/reducers/teams/teams.types";
import { LeaguesV2Service } from "@services/v2/leagues/leagues.v2.service";
import { APITournamentTypes } from "src/app/enums/tournamentTypes.enum";
import { NgxSmartModalService } from "ngx-smart-modal";
import { LeagueTypes } from "src/app/enums/league-type.enum";
import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import {
  ELITE_RULE_LINKS,
  REC_RULE_LINKS,
  WEEKLY_RULE_LINKS,
} from "src/app/enums/rulebooks.enum";
import { PlayerGuides } from "src/app/enums/resources/document-links/player-guides.enum";
import { ParticipantListModalComponent, ParticipantListTeamModalData } from "../participant-list-modal/participant-list-modal.component";
import { modalOptions } from "@utils/modal-helpers";
import { Fall2021NESList } from "src/app/enums/league-lists/2021-fall-nes";

@Component({
  selector: "app-league-details",
  templateUrl: "./league-details.component.html",
  styleUrls: ["./league-details.component.scss"],
})
export class LeagueDetailsComponent implements OnInit, OnDestroy {
  public isQuickplay = false;
  public teams: RankedTeam[] = [];
  public teamScores: RankedTeamScore[] = [];
  public league$: Observable<GenericLeagueDetails>;
  public userIsLoggedIn$: Observable<boolean>;
  public leagueId: number;
  public leagueRegistrationOpen: boolean;
  public defaultRulesLink = `/${routePaths.RULES}`;
  public codeOfConductLink = PlayerGuides.CODE_OF_CONDUCT;
  public leagueIsNESLeague = false;

  public REC_RULES_ROUTES = REC_RULE_LINKS;


  private _unsub = new Unsubscriber();

  constructor(
    private _store: Store<RootState>,
    private _leagueService: LeaguesV2Service,
    private _modalService: NgxSmartModalService
  ) {
    this.userIsLoggedIn$ = this._store.select("user", "currentUser").pipe(
      map(user => !!user),
      first()
    );
  }

  public ngOnInit() {
    this.league$ = this._store.select("leagues", "league").pipe(
      filter((league) => league !== null),
      map((league) => {
        this.leagueId = league.id;
        this.teams = league.teams.map((team) => ({
          name: team.title,
          wins: team.wins,
          losses: team.losses,
          id: team.id,
        }));
        this.leagueIsNESLeague = Fall2021NESList.indexOf(league.id) !== -1;
        if (isISODateInFuture(league.registrationEndDate)) {
          this.leagueRegistrationOpen = true;
        } else {
          this.leagueRegistrationOpen = false;
        }
        return league;
      }),
      takeUntil(this._unsub.unsubEvent)
    );

    this.league$.pipe(
      pluck("leagueType"),
      take(1)
    ).subscribe(
      (type) => {
        if (type === LeagueTypes.QUICKPLAY) {
          this.isQuickplay = true;
        }
      }
    );

    this.league$.pipe(
      pluck("brackets"),
      map((brackets) => brackets.filter((bracket) => bracket.schedulingType === APITournamentTypes.BATTLE_ROYALE))
    ).subscribe(
      (brBrackets) => {
        if (brBrackets.length > 0) {
          // Per LM Team, we can operate of the assumption that current league offerings
          // that have BR tournaments will only have ONE BR tournament per league,
          // Whenever this assumption changes we can rewrite this logic to handle new usecases
          const tournamentId = brBrackets[0].id;
          this._leagueService.getBRTournamentScores(tournamentId)
            .subscribe(
              (res) => {
                this.teamScores = res;
              }
            );
        }
      }
    );
  }

  public ngOnDestroy() {
    this._unsub.kill();
  }

  public isLeagueFortnite(esport: gamePlatforms) {
    return esport === gamePlatforms.FORTNITE;
  }

  public getGenerateLeagueScheduleRoute(id: number): string {
    return generateLeagueScheduleRoute(id.toString());
  }

  public getRulesRoute(esport: gamePlatforms, leagueType: LeagueTypes): string | null {
    if (leagueType === LeagueTypes.ELITE_LEAGUE) {
      switch (esport) {
        case gamePlatforms.LEAGUE_OF_LEGENDS:
          return ELITE_RULE_LINKS.LOL_ELITE;
        case gamePlatforms.ROCKET_LEAGUE:
          return ELITE_RULE_LINKS.RL_ELITE;
        case gamePlatforms.OVERWATCH:
          return ELITE_RULE_LINKS.OW_ELITE;
        case gamePlatforms.VALORANT:
          return ELITE_RULE_LINKS.VALORANT_ELITE;
        default:
          return null;
      }
    }
    else if (leagueType === LeagueTypes.RECREATIONAL) {
      switch (esport) {
        case gamePlatforms.MARIO_KART:
          return REC_RULE_LINKS.MARIOKART;
        case gamePlatforms.FORTNITE:
          return REC_RULE_LINKS.FORTNITE;
        case gamePlatforms.ROCKET_LEAGUE:
          return REC_RULE_LINKS.ROCKETLEAGUE;
        case gamePlatforms.EA_MADDEN:
          return REC_RULE_LINKS.MADDEN;
        case gamePlatforms.MARIO_KART:
          return REC_RULE_LINKS.MARIOKART;
        case gamePlatforms.SSBB:
          return REC_RULE_LINKS.SMASHBROS;
        default:
          return null;
      }
    }
    else if (leagueType === LeagueTypes.QUICKPLAY) {
      switch (esport) {
        case gamePlatforms.MARIO_KART:
          return WEEKLY_RULE_LINKS.MARIOKART_WEEKLY;
        case gamePlatforms.FORTNITE:
          return WEEKLY_RULE_LINKS.FORTNITE_BR_WEEKLY;
        case gamePlatforms.SSBB:
          return WEEKLY_RULE_LINKS.SSBU_WEEKLY;
        case gamePlatforms.ROCKET_LEAGUE:
          return WEEKLY_RULE_LINKS.RL_WEEKLY;
        default:
          return null;
      }
    }
    else {
      return null;
    }
  }

  public openParticipants(): void {
    this._leagueService.getLeagueTeamList(this.leagueId).subscribe(
      (res) => {
        const modalData: ParticipantListTeamModalData[] = res.map((leagueTeam) => ({
          teamId: leagueTeam.id,
          teamName: leagueTeam.title,
          teamLogoUrl: leagueTeam.logoUrl,
          subtext: null,
        }));
        this._modalService.create(
          ParticipantListModalComponent.MODAL_ID,
          ParticipantListModalComponent,
          modalOptions
        )
          .setData(modalData)
          .open();
      }
    );
  }
}
