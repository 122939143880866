<form [formGroup]="controlForm">
  <div>Please select your city</div>
  <select formControlName="selectedCity">
    <option value="">--Select a City---</option>
    <option *ngFor="let city of okCities" [value]="city">{{ city }}</option>
  </select>
</form>
<app-modal-stepper-footer-buttons
  [hasNext]="true"
  [hasPrevious]="!isFirstStep"
  [formToCheck]="controlForm"
  [errorMessage]="errorMessage"
>
</app-modal-stepper-footer-buttons>
