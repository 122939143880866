export enum ModuleRouteParams {
  USER_ID = ":userid",
  TEAM_ID = ":teamid",
  MATCH_ID = ":matchid",
  LEAGUE_TYPE = ":leaguetype",
  LEAGUE_ID = ":leagueid",
  QUEUE_ID = ":queueid",
  REDIRECT = ":redirect",
  PASSWORD_TOKEN = ":token",
  SERIES_MATCHUP_ID = ":seriesmatchupid",
  ORGANIZATION_SLUG = ":cluborgslug",
  CLUB_SLUG = ":cluborgslug",
  INSTANT_INVITE = ":instantinvite",
  SCRIMMAGE_ID = ":scrimmageid",
  ESPORT_PASS_ID = ":esportpassid",
  RULEBOOK_ID = ":rulebookid",
}

export enum ComponentRouteParams {
  USER_ID = "userid",
  TEAM_ID = "teamid",
  MATCH_ID = "matchid",
  LEAGUE_TYPE = "leaguetype",
  LEAGUE_ID = "leagueid",
  QUEUE_ID = "queueid",
  LOGIN_REDIRECT = "redirect",
  SIGNUP_SUCCESS = "registerSuccess",
  PASSWORD_TOKEN = "token",
  SERIES_MATCHUP_ID = "seriesmatchupid",
  ORGANIZATION_SLUG = "cluborgslug",
  INSTANT_INVITE = "instantinvite",
  SCRIMMAGE_ID = "scrimmageid",
  ESPORT_PASS_ID = "esportpassid",
  RULEBOOK_ID = "rulebookid",
  CLUB_SLUG = "cluborgslug",

}

export enum routeAnchors {
  MY_PASSES = "myPasses",
}

export enum routeQueries {
  LEAGUE_ID = "league_id",
  ORG_ID = "org_id",
  RULEBOOK_ID = "rulebookid",
}
