import { Component, OnInit } from "@angular/core";
import { of } from "rxjs";
import {
  catchError,
  first,
  map,
  pluck,
} from "rxjs/operators";
import {
  FormGroup,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { Token } from "@stripe/stripe-js";
import { ToastrService } from "ngx-toastr";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Router } from "@angular/router";

import { LeagueEndpointService } from "@services/league-endpoints.service";

import { RootState } from "src/app/reducers";
import {
  LeagueRegistrationInformation,
  RegistrationTeamInfo,
  OrganizationTypes,
  JoinLeagueReferralParams,
  RegistrationOrganization,
  JoinLeagueConfirmationInformation,
} from "src/app/reducers/leagues/league.types";
import { RegistrationTypes } from "src/app/enums/registration-types.enum";
import { GetLeagueById } from "src/app/reducers/leagues/league.actions";
import { Logger } from "../../util/logger";
import { TeamTypes } from "src/app/reducers/teams/teams.types";
import { ModalController } from "../modal-controller/modal-controller.abstract";
import { GetLeagueDashboard } from "src/app/reducers/dashboard/dashboard.actions";
import { JoinLeagueModalService } from "src/app/stateful-services/join-league-modal/join-league-modal.service";
import { triggerLeagueRegistrationAnalyticsEvent } from "@utils/analytics/leagues.analytics";
import { LeagueTypes } from "src/app/enums/league-type.enum";

const SPONSOR_ONLY_LEAGUES = [6297];

@Component({
  selector: "app-join-league-modal",
  templateUrl: "./join-league-modal.html",
  styleUrls: ["./join-league-modal.component.scss"],
})
export class JoinLeagueModalComponent extends ModalController<LeagueRegistrationInformation> implements OnInit {
  public static MODAL_ID = "joinLeague";
  public registrationInformation: LeagueRegistrationInformation;
  public isMobile: boolean;
  public joinLeagueForm: FormGroup;
  public registrationTypes = RegistrationTypes;
  public useSponsorOnly$ = of(false);

  private _isQuickplayLeague = false;

  constructor(
    private _modalService: NgxSmartModalService,
    private _leagueService: LeagueEndpointService,
    private _toast: ToastrService,
    private _store: Store<RootState>,
    private _router: Router,
    private _joinLeagueModalService: JoinLeagueModalService
  ) {
    super(_modalService, JoinLeagueModalComponent.MODAL_ID, _router);
    this.registrationInformation = this.getData();
    this._joinLeagueModalService.constructSteps(this.registrationInformation);
    this.joinLeagueForm = new FormGroup({
      team: new FormControl(""),
      registrationChoice: new FormControl(""),
      referral: new FormControl(""),
      sponsor: new FormControl(""),
      // TODO Gate cities with config flag
      city: new FormControl(""),
    });

    if (this.registrationInformation) {
      if (!this._joinLeagueModalService.hasRegistrationTypeStep) {
        if (this._joinLeagueModalService.hasSponsorStep) {
          this.registrationChoiceControl.patchValue({
            registrationType: RegistrationTypes.SPONSOR,
          });
        }
        else if (this._joinLeagueModalService.hasReferralStep) {
          this.registrationChoiceControl.patchValue({
            registrationType: RegistrationTypes.SPONSOR,
          });
        }
        else {
          this.registrationChoiceControl.patchValue({
            registrationType: RegistrationTypes.NEITHER,
          });
        }
      }
    }
  }

  public ngOnInit(): void {
    this._store.select("leagues", "league").pipe(
      first()
    ).subscribe(
      (leagueVal) => {
        this._isQuickplayLeague = leagueVal.leagueType === LeagueTypes.QUICKPLAY;
      }
    );
    this.useSponsorOnly$ = this._store.select("leagues", "league").pipe(
      pluck("id"),
      first(),
      map((id) => SPONSOR_ONLY_LEAGUES.includes(id))
    );
  }

  public getSoloTeamId(): string {
    const soloTeam = this.registrationInformation.teams.find((team) => team.teamType === TeamTypes.ACCOUNT_SOLO_TEAM);
    return soloTeam ? soloTeam.id : "-1"; // Force error if user has no solo team
  }

  public processPaymentAndJoin = ($event: Token): void => {
    const { registrationConfig } = this.registrationInformation;
    const useSoloTeam = registrationConfig.team_registration_type === TeamTypes.ACCOUNT_SOLO_TEAM;
    const params: JoinLeagueReferralParams = {
      teamId: useSoloTeam ? this.getSoloTeamId() : this.selectedTeam?.id,
      leagueId: this.registrationInformation.id,
      stripeToken: $event,
      city: registrationConfig?.register_location ?
        this._getOklaCity() : // TODO: Un-hardcode this when we do states....
        null,
    };
    if (this.registrationType === RegistrationTypes.REFERRAL) {
      params.organizationId = this.selectedReferral.id;
    }
    this._leagueService
      .joinLeagueWithOptions(params)
      .pipe(
        catchError((err) => {
          Logger.error(err);
          this._toast.error(this._readErrorMessage(err), "You could not join the league");
          return of(null);
        })
      )
      .subscribe(async (response) => {
        if (response) {
          const isWeekly = await this._store.select("leagues", "league").pipe(
            first(),
            pluck("leagueType"),
            map((leagueType) => leagueType === LeagueTypes.QUICKPLAY)
          ).toPromise();
          triggerLeagueRegistrationAnalyticsEvent(params.leagueId, isWeekly);
          this._toast.success("You have joined the league");
          this._store.dispatch(new GetLeagueDashboard());
          this._store.dispatch(new GetLeagueById(+this.registrationInformation.id));
          this.close();
        }
      });
  };

  public canCheckout(): boolean {
    return this.registrationType !== this.registrationTypes.SPONSOR &&
      this.registrationInformation.teamFee &&
      parseFloat(this.registrationInformation.teamFee) > 0;
  }

  public get isSeasonPassLeague(): boolean {
    return this.registrationInformation.isSeasonPassLeague;
  }

  public get registrationType(): RegistrationTypes {
    if (this._isQuickplayLeague) {
      return RegistrationTypes.NEITHER;
    }

    return (this.registrationChoiceControl.value.registrationType as RegistrationTypes) ?? RegistrationTypes.NEITHER;
  }

  public get isEliteLegue(): boolean {
    return this.registrationInformation.leagueType === LeagueTypes.ELITE_LEAGUE;
  }

  public get selectedTeam(): RegistrationTeamInfo {
    return this.teamControl.value?.selectedTeam;
  }

  public get selectedReferral(): RegistrationOrganization {
    return this.referralControl.value?.selectedReferral;
  }

  public get sponsorCode(): string {
    return this.sponsorControl.value?.sponsorCode;
  }

  public get teamControl(): AbstractControl {
    return this.joinLeagueForm.get("team");
  }

  public get registrationChoiceControl(): AbstractControl {
    return this.joinLeagueForm.get("registrationChoice");
  }

  public get cityControl(): AbstractControl {
    return this.joinLeagueForm.get("city");
  }

  public get referralControl(): AbstractControl {
    return this.joinLeagueForm.get("referral");
  }

  public get sponsorControl(): AbstractControl {
    return this.joinLeagueForm.get("sponsor");
  }

  public get hasTeamStep(): boolean {
    return this._joinLeagueModalService.hasTeamStep;
  }

  public get hasSponsorStep(): boolean {
    return this._joinLeagueModalService.hasSponsorStep && this.registrationChoiceControl.value === RegistrationTypes.SPONSOR;
  }

  public get hasReferralStep(): boolean {
    return this._joinLeagueModalService.hasReferralStep && this.registrationChoiceControl.value === RegistrationTypes.REFERRAL;
  }

  public get hasRegistrationTypeStep(): boolean {
    return this._joinLeagueModalService.hasRegistrationTypeStep;
  }

  public get hasCityStep(): boolean {
    return this._joinLeagueModalService.hasCityStep;
  }

  public get confirmationInformation(): JoinLeagueConfirmationInformation {
    return {
      leagueFee: isNaN(parseFloat(this.registrationInformation.teamFee)) ? -1 : parseFloat(this.registrationInformation.teamFee),
      registrationType: this.registrationType,
      selectedTeam: this.teamControl.value?.selectedTeam as RegistrationTeamInfo,
      sponsorCode: this.sponsorControl.value?.sponsorCode as string,
      referrall: this.referralControl.value?.selectedReferral as RegistrationOrganization,
      leagueId: this.registrationInformation.id,
      teamType: this.registrationInformation.registrationConfig?.team_registration_type ?? TeamTypes.STANDARD,
      soloTeamId: this.getSoloTeamId(),
      registerWithLocation: this.registrationInformation.registrationConfig?.register_location,
      city: this._getOklaCity(), // TODO: Un-hardcode this when we do states....
      isSeasonPassLeague: this.registrationInformation.isSeasonPassLeague,
    };
  }

  public get referralOrganizations(): RegistrationOrganization[] {
    return this.registrationInformation.organizations.filter(
      (organization) => organization.organizationLeagueType.toLocaleLowerCase() === OrganizationTypes.REFERRAL.toLocaleLowerCase()
    );
  }

  public get referrallSponsorControl(): AbstractControl {
    switch (this.registrationType) {
      case RegistrationTypes.REFERRAL:
        return this.joinLeagueForm.get("referral") as AbstractControl;
      case RegistrationTypes.SPONSOR:
        return this.joinLeagueForm.get("sponsor") as AbstractControl;
      case RegistrationTypes.NEITHER:
      default:
        return this.joinLeagueForm;
    }
  }

  // TODO: Make this generic when hardcode is no longer needed
  private _getOklaCity(): string {
    return this.cityControl.value?.selectedCity;
  }

  private _readErrorMessage(err): string {
    try {
      if (err.error.errors[0].base) {
        return err.error.errors[0].base;
      }
      return err.error.errors[0];
    } catch {
      Logger.error("Unknown error reading response");
      return "Server error while joining league please contact support@ggleagues.com";
    }
  }
}
