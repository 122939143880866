import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "@environments/environment";
import { ChatData } from "@apptypes/chat/chat-data.interface";
import { basicAuthHeader } from "@utils/auth-utils";

import { CommentService } from "../comment.abstract.service";
import { CloudComment } from "../comment.api.types";

/**
 * A Comment service to post to Cloud QuickPlayEvent Chatrooms
 *
 * @author Christian Tweed
 */
@Injectable({
  providedIn: "any",
})
export class CloudQuickPlayEventCommentService extends CommentService {
  public postComment({ firestoreDocID: chatroomId }: ChatData, authorId: string, message: string): Observable<{ success: true }> {
    const url = `${environment.cloudAPIBase}/manageQuickPlayEventChat`;

    const options = {
      headers: basicAuthHeader(),
    };

    const payload = this._formatChatMessage(chatroomId, authorId, message);

    return this._http.post<{ success: true }>(url, payload, options);
  }

  protected _formatChatMessage(chatroomId: string, userId: string, message: string): CloudComment {
    return {
      message,
      userId,
      chatroomId,
    };
  }
}
