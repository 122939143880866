import {
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { takeUntil } from "rxjs/operators";

import { Unsubscriber } from "@utils/unsubscriber";
import { MatchReportService } from "@services/match-report/match-report.service";
import { ModalStep } from "@apptypes/view-types/modal.types";

import {
  MatchReport,
  MatchReportGame,
  NOT_PLAYED,
  WINNER_DECLARED,
} from "src/app/reducers/matches/matches.types";
import { ModalStepperFooterButtonsComponent } from "../modal-stepper-footer-buttons/modal-stepper-footer-buttons.component";
import { ExpandableContentComponent } from "../expandable-content/expandable-content.component";

@Component({
  selector: "app-report-results-step",
  templateUrl: "./report-results-step.component.html",
  styleUrls: ["./report-results-step.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReportResultsStepComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => ReportResultsStepComponent),
    },
  ],
})
export class ReportResultsStepComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator, ModalStep {
  @Input() public matchReport: MatchReport;
  @ViewChild("footerButtons") public footerButtons: TemplateRef<ModalStepperFooterButtonsComponent>;
  @ViewChildren(ExpandableContentComponent) public expandableContentComponents: QueryList<ExpandableContentComponent>;

  public reportForm: FormArray = new FormArray([]);
  public NOT_PLAYED = NOT_PLAYED;
  public WINNER_DECLARED = WINNER_DECLARED;
  public notEnoughToDeclareWinner = true;
  public isShowingWarningState = false;

  private _unsub = new Unsubscriber();

  public ngOnInit(): void {
    this.matchReport.games.forEach((matchReportGame) => {
      this.reportForm.push(new FormGroup({
        gameId: new FormControl(matchReportGame.gameId, Validators.required),
        winnerId: new FormControl(matchReportGame.winnerId, Validators.required),
        screenshot: new FormControl(matchReportGame.screenshot),
      }));
    });

    this.reportForm.setValidators([this._allSelectedValidator, this._createGameCheckingValidator(this.matchReport)]);

    this.reportForm.valueChanges.pipe(
      takeUntil(this._unsub.unsubEvent)
    ).subscribe((matchReportGames: MatchReportGame[]) => {
      this.notEnoughToDeclareWinner = !MatchReportService.doGamesHaveEnoughToDeclareWinner(matchReportGames, this.matchReport.teams);
      this.isShowingWarningState = this.notEnoughToDeclareWinner && this._checkAllFormsAreDirty(this.reportForm);
    });
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public handleExpansionChangeEvent(event: boolean, index: number): void {
    if (event) {
      //On open shut every other ExpandableContentComponents
      this.expandableContentComponents.forEach((component, componentIndex) => {
        if (componentIndex !== index && component.isShowingContent) {
          component.expandContent();
          this.reportForm.controls[componentIndex].markAsDirty();
        }
      });
    }
  }

  public getMatchName(i: number) {
    return `Game ${i + 1}`;
  }

  public uploadFile(index: string): void {
    const element: HTMLElement = document.getElementById(`${index}-Screenshot`) as HTMLElement;
    element.click();
  }

  public loadImage($event, formGroup: FormGroup) {
    const files: File[] = $event.target.files;
    formGroup.patchValue({
      screenshot: files[0],
    });
    formGroup.markAsDirty();
  }

  public getScreenShot(formGroup: FormGroup): File | null {
    return formGroup.value?.screenshot ?? null;
  }

  public clearScreenshot(formGroup: FormGroup): void {
    formGroup.patchValue({
      screenshot: null,
    });
  }

  public getGroupStatus(formGroup: FormGroup): null | NOT_PLAYED | WINNER_DECLARED {
    if (!formGroup.get("winnerId")) {
      return null;
    }

    const winnerId = formGroup.get("winnerId").value;

    if (formGroup.dirty && !winnerId) {
      return NOT_PLAYED;
    }

    if (winnerId && (
      (winnerId === this.matchReport.teams[0]?.id ?? false) ||
      (winnerId === this.matchReport.teams[1]?.id ?? false)
    )) {
      return WINNER_DECLARED;
    }

    if (formGroup.dirty && formGroup.get("winnerId").value === NOT_PLAYED) {
      return NOT_PLAYED;
    }

    return null;
  }

  /** CVA Methods */
  public onTouched = () => { };

  public writeValue(results: MatchReportGame[] | null | undefined): void {
    // eslint-disable-next-line object-curly-newline
    const formVals: MatchReportGame[] = [];

    if (!!results) {
      results.forEach((val, index) => {
        formVals[index.toString()] = val;
      });
    }

    this.reportForm.patchValue(formVals);
  }

  public registerOnChange(callBackFn: () => unknown): void {
    this.reportForm.valueChanges.pipe(
      takeUntil(this._unsub.unsubEvent)
    ).subscribe(callBackFn);
  }

  public registerOnTouched(onTouchFn: () => unknown): void {
    this.onTouched = onTouchFn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.reportForm.disable();
    }
    else {
      this.reportForm.enable();
    }
  }

  /** Validator Methods */

  public validate(): ValidationErrors {
    return this.reportForm.valid ? null : this.reportForm.errors;
  }

  private _createGameCheckingValidator = (matchReport: MatchReport): ValidatorFn => (form: FormArray) => {
    const formVals: MatchReportGame[] = form.controls.map((control) => control.value);
    const doGamesHaveAWinner = MatchReportService.doGamesHaveEnoughToDeclareWinner(formVals, matchReport.teams);

    if (!doGamesHaveAWinner) {
      return {
        notEnoughToDeclareWinner: true,
      };
    }
    return null;
  };

  private _allSelectedValidator: ValidatorFn = (form: FormArray) => {
    const formVals: MatchReportGame[] = form.controls.map((control) => control.value);
    const notAllSelected = formVals.map((matchReportGame) => matchReportGame?.winnerId).filter((winnerId) => !winnerId).length > 0;

    if (notAllSelected) {
      return {
        notAllOptionsSelected: true,
      };
    }

    return null;
  };

  private _checkAllFormsAreDirty(formArray: FormArray): boolean {
    return formArray.controls.map((control) => control.dirty).reduce((previousValue, currentValue) => previousValue && currentValue, true);
  }
}
