<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
  [modalFooter]="activeFooterButtons"
>
</app-modal-container>

<ng-template #title>
  <app-modal-title>Report Results</app-modal-title>
</ng-template>

<ng-template #content>
  <div class="c_selfReportModal">
    <mat-horizontal-stepper [linear]="true">
      <!-- Submission Choice Step -->
      <mat-step [stepControl]="submissionChoiceControl">
        <ng-template matStepLabel>Submission Choice</ng-template>
        <app-submission-choice-step
          [matchReport]="matchReport"
          [formControl]="submissionChoiceControl"
        >
        </app-submission-choice-step>
      </mat-step>
      <!-- Report Step -->
      <mat-step
        [stepControl]="gameReportsControl"
        *ngIf="
          submissionChoice === matchReportTypes.SUBMISSION ||
          submissionChoice === null
        "
      >
        <ng-template matStepLabel>Reporting</ng-template>
        <app-report-results-step
          [matchReport]="matchReport"
          [formControl]="gameReportsControl"
        >
        </app-report-results-step>
      </mat-step>
      <!-- Forfeit Step -->
      <mat-step
        [stepControl]="forfeittingTeamIdControl"
        *ngIf="submissionChoice === matchReportTypes.FORFEIT"
      >
        <ng-template matStepLabel>Forfeit</ng-template>
        <app-forfeit-team-step
          [matchReport]="matchReport"
          [formControl]="forfeittingTeamIdControl"
        >
        </app-forfeit-team-step>
      </mat-step>
      <!-- Report Confirmation Step -->
      <mat-step
        *ngIf="
          submissionChoice === matchReportTypes.SUBMISSION ||
          submissionChoice === null
        "
      >
        <ng-template matStepLabel>Confirmation</ng-template>
        <app-report-confirmation-step
          [matchReport]="matchReport"
          [gameReports]="gameReports"
          (submitReportSuccessEvent)="submissionSuccessHandler()"
        >
        </app-report-confirmation-step>
      </mat-step>
      <!-- Forfeit Report Step -->
      <mat-step *ngIf="submissionChoice === matchReportTypes.FORFEIT">
        <ng-template matStepLabel>Confirmation</ng-template>
        <app-forfeit-confirmation-step
          [matchReport]="matchReport"
          [forfeittingTeamId]="forfeittingTeamId"
          (submitForfeitSuccessEvent)="submissionSuccessHandler()"
        >
        </app-forfeit-confirmation-step>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</ng-template>
