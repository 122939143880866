<div>
  <h4 class="u_txt--hdg4">Shipping Information</h4>
  <hr />
  <form [formGroup]="shippingForm">
    <fieldset [disabled]="isSaving">
      <label class="c_inputLabel" for="firstName"> First Name * </label>
      <input
        class="c_input"
        type="text"
        placeholder="First Name"
        formControlName="firstName"
        id="firstName"
      />
      <label class="c_inputLabel" for="lastName"> Last Name * </label>
      <input
        class="c_input"
        type="text"
        placeholder="Last Name"
        formControlName="lastName"
        id="lastName"
      />
      <label class="c_inputLabel" for="addressOne"> Address 1 *</label>
      <input
        class="c_input"
        type="text"
        placeholder="Address 1"
        formControlName="addressOne"
        id="addressOne"
      />
      <label class="c_inputLabel" for="addressTwo"> Address 2</label>
      <input
        class="c_input"
        type="text"
        placeholder="Address 2"
        formControlName="addressTwo"
        id="addressTwo"
      />
      <div class="c_userEditAddress__zipCityStateBlock">
        <div class="c_userEditAddress__zipCityStateBlock__item">
          <label class="c_inputLabel" for="city"> City </label>
          <input
            class="c_input"
            type="text"
            placeholder="City"
            formControlName="city"
            id="city"
          />
        </div>
        <div class="c_userEditAddress__zipCityStateBlock__item">
          <label class="c_inputLabel" for="state"> State </label>
          <select
            class="c_input"
            type="text"
            placeholder="State"
            formControlName="state"
            id="state"
          >
            <option disabled selected value="">State</option>
            <option
              *ngFor="let state of stateList"
              [ngValue]="statesAbbreviations[state]"
            >
              {{ state }}
            </option>
          </select>
        </div>
        <div class="c_userEditAddress__zipCityStateBlock__item">
          <label class="c_inputLabel" for="zipCode"> Zipcode </label>
          <input
            class="c_input"
            type="text"
            placeholder="Zipcode"
            formControlName="zipCode"
            id="zipCode"
          />
        </div>
      </div>
    </fieldset>
  </form>
  <p class="c_userEditAddress_formError u_txt--warn" *ngIf="!!formError">
    {{ formError }}
  </p>
  <div class="c_userEditAddress__buttonGroup">
    <button [disabled]="isSaving" class="c_button" (click)="updateAddress()">
      Update Address
    </button>
  </div>
</div>
