/* eslint-disable max-len */
import { NgModule } from "@angular/core";
import {
  Routes,
  RouterModule,
  ActivatedRouteSnapshot,
} from "@angular/router";

import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { SignUpPageComponent } from "./pages/sign-up-page/sign-up-page.component";
import { AboutPageComponent } from "./pages/about-page/about-page.component";
import { ContactPageComponent } from "./pages/contact-page/contact-page.component";
import { RulesPageComponent } from "./pages/rules-page/rules-page.component";
import { FaqPageComponent } from "./pages/faq-page/faq-page.component";
import { LeagueDetailPageComponent } from "./pages/league-detail-page/league-detail-page.component";
import { DashboardPageComponent } from "./pages/dashboard-page/dashboard-page.component";
import { UserPageComponent } from "./pages/user-page/user-page.component";
import { TeamDetailPageComponent } from "./pages/team-detail-page/team-detail-page.component";
import { MatchPageComponent } from "./pages/match-page/match-page.component";
import { NotfoundPageComponent } from "./pages/notfound-page/notfound-page.component";
import { UserEditPageComponent } from "./pages/user-edit-page/user-edit-page.component";
import { CreateTeamPageComponent } from "./pages/create-team-page/create-team-page.component";
import { EditTeamRosterPageComponent } from "./pages/edit-team-roster-page/edit-team-roster-page.component";
import { AuthGuard } from "./guards/auth-guard.service";
import { PreLogin } from "./guards/prelogin.service";
import { TeamAllLeaguesComponent } from "./pages/team-all-leagues/team-all-leagues.component";
import { MyTeamsPageComponent } from "./pages/my-teams-page/my-teams-page.component";
import { PaymentsPageComponent } from "./pages/payments-page/payments-page.component";
import { TeamEditPageComponent } from "./pages/team-edit-page/team-edit-page.component";
import { TeamsSearchPageComponent } from "./pages/teams-search-page/teams-search-page.component";
import { SchedulePageComponent } from "./pages/schedule-page/schedule-page.component";
import { PasswordResetPageComponent } from "./pages/password-reset-page/password-reset-page.component";
import { NoPermissionsPageComponent } from "./pages/no-permissions-page/no-permissions-page.component";
import { NotificationsPageComponent } from "./pages/notifications-page/notifications-page.component";
import { CreateNewPasswordPageComponent } from "./pages/create-new-password-page/create-new-password-page.component";
import { SeriesMatchupPageComponent } from "./pages/series-matchup-page/series-matchup-page.component";

import { generateOrganizationSlugRoute, routePaths } from "src/app/enums/routes/routePaths";
import { NewUserPageComponent } from "./pages/new-user-page/new-user-page.component";
import { TeamInstantInviteComponent } from "./pages/team-instant-invite/team-instant-invite.component";
import { ParentsComponent } from "./pages/parents/parents.component";
import { ScrimmagePageComponent } from "./pages/scrimmage/scrimmage-page.component";
import { PassDetailsPageComponent } from "./pages/pass-details-page/pass-details-page.component";
import { LeaguePassesPageComponent } from "./pages/league-passes-page/league-passes-page.component";
import { RulesPdfPageComponent } from "./pages/rules-pdf-page/rules-pdf-page.component";
import { RedirectGuard } from "./guards/redirect-guard.guard";
import { environment } from "@environments/environment";
import { ClubSearchPageComponent } from "./pages/club-search-page/club-search-page.component";
import { LeagueCheckinPageComponent } from "./pages/league-checkin-page/league-checkin-page.component";
import { LeaveQueueGuard } from "./guards/leave-queue-guard.guard";

const routes: Routes = [
  {
    path: routePaths.HOME,
    component: DashboardPageComponent,
    canActivate: [AuthGuard],
  },
  //Login Signup Routes
  {
    path: routePaths.LOGIN,
    component: LoginPageComponent,
    canActivate: [PreLogin],
  },
  {
    path: routePaths.LOGIN_REDIRECT,
    component: LoginPageComponent,
    canActivate: [PreLogin],
  },
  {
    path: routePaths.SIGNUP,
    component: SignUpPageComponent,
    canActivate: [PreLogin],
  },
  {
    path: routePaths.SIGN_UP,
    component: SignUpPageComponent,
    canActivate: [PreLogin],
  },
  // Password Reset Routes
  {
    path: routePaths.PASSWORD_RESET,
    component: PasswordResetPageComponent,
  },
  {
    path: routePaths.PASSWORD_CREATE,
    component: CreateNewPasswordPageComponent,
  },
  // Static Routes
  {
    path: routePaths.ABOUT,
    component: AboutPageComponent,
    canActivate: [PreLogin],
  },
  {
    path: routePaths.CONTACT,
    component: ContactPageComponent,
    canActivate: [PreLogin],
  },
  {
    path: routePaths.RULES,
    component: RulesPageComponent,
    canActivate: [PreLogin],
  },
  {
    path: routePaths.RULEBOOK,
    component: RulesPdfPageComponent,
    canActivate: [PreLogin],
  },
  {
    path: routePaths.FAQ,
    component: FaqPageComponent,
    resolve: {
      url: "externalUrlRedirectResolver",
    },
    data: {
      externalUrl: "http://support.ggleagues.com",
    },
  },
  {
    path: routePaths.NEW_USER_LANDING,
    component: NewUserPageComponent,
    canActivate: [PreLogin],
  },
  {
    path: routePaths.PARENTS,
    component: ParentsComponent,
    canActivate: [PreLogin],
  },
  {
    path: routePaths.PRIVACY,
    component: RedirectGuard,
    canActivate: [RedirectGuard],
    data: {
      externalUrl: `${environment.staticSiteUrl}/privacy`,
    },
  },
  // CLUBS
  {
    path: routePaths.CLUB_SEARCH_ALT,
    component: ClubSearchPageComponent,
    canActivate: [PreLogin],
  },
  {
    path: routePaths.CLUB_SEARCH,
    component: ClubSearchPageComponent,
    canActivate: [PreLogin],
  },
  {
    path: routePaths.CLUB,
    loadChildren: () => import("./clubs/clubs.module").then(m => m.ClubsModule),
    canActivate: [PreLogin],
  },
  // BEGIN AUTHENTICATED ROUTES
  // League Routes
  {
    path: routePaths.LEAGUES,
    loadChildren: () => import("./league-search/league-search.module").then((module) => module.LeagueSearchModule),
    canActivate: [PreLogin],
  },
  {
    path: routePaths.LEAGUES_SEARCH,
    loadChildren: () => import("./league-search/league-search.module").then((module) => module.LeagueSearchModule),
    canActivate: [PreLogin],
  },
  {
    path: routePaths.LEAGUE_DETAIL,
    component: LeagueDetailPageComponent,
    canActivate: [PreLogin],
  },
  {
    path: routePaths.LEAGUE_DETAIL_CHECKIN,
    component: LeagueCheckinPageComponent,
    canActivate: [AuthGuard],
    canDeactivate: [LeaveQueueGuard],
  },
  {
    path: routePaths.LEAGUE_DETAIL_PASS_SUCCESS,
    component: LeagueDetailPageComponent,
    canActivate: [AuthGuard],
    data: {
      passSuccess: true,
    },
  },
  {
    path: routePaths.LEAGUE_SCHEDULE,
    component: SchedulePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: routePaths.ESPORT_PASS_DETAILS,
    component: PassDetailsPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: routePaths.ESPORT_PASS_PAYMENTS_SUCCESS,
    component: PassDetailsPageComponent,
    canActivate: [AuthGuard],
    data: {
      passSuccess: true,
    },
  },
  {
    path: routePaths.LEAGUES_PASSES,
    component: LeaguePassesPageComponent,
    canActivate: [AuthGuard],
  },
  // Added in to support emails... for the time being
  {
    path: routePaths.DASHBOARD_LEAGUE_ID,
    component: LeagueDetailPageComponent,
    canActivate: [AuthGuard],
  },
  // Dashboard Routes
  {
    path: routePaths.DASHBOARD,
    component: DashboardPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: routePaths.DASHBOARD_PASS_SUCCESS,
    component: DashboardPageComponent,
    canActivate: [AuthGuard],
    data: {
      passSuccess: true,
    },
  },
  // User/Profile Routes
  {
    path: routePaths.PROFILE,
    component: UserPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: routePaths.USER_DETAIL,
    component: UserPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: routePaths.PROFILE_EDIT,
    component: UserEditPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: routePaths.NOTIFICATIONS,
    component: NotificationsPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: routePaths.REFERRALS,
    loadChildren: () => import("./referrals/referrals.module").then(m => m.ReferralsModule),
  },
  // Team Routes
  {
    path: routePaths.TEAM_NEW,
    component: CreateTeamPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: routePaths.TEAM_INSTANT_INVITE,
    component: TeamInstantInviteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: routePaths.TEAM_DETAIL,
    component: TeamDetailPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: routePaths.TEAM_ROSTER,
    component: EditTeamRosterPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: routePaths.TEAM_LEAGUES,
    component: TeamAllLeaguesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: routePaths.TEAM_EDIT,
    component: TeamEditPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: routePaths.MY_TEAMS,
    component: MyTeamsPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: routePaths.TEAMS,
    component: TeamsSearchPageComponent,
    canActivate: [AuthGuard],
  },
  // Match Routes
  {
    path: routePaths.MATCH_DETAIL,
    component: MatchPageComponent,
    canActivate: [AuthGuard],
  },
  //Series Matchup Routes
  {
    path: routePaths.SERIES_MATCHUP,
    component: SeriesMatchupPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: routePaths.SERIES_MATCHUP_QUEUE_SUCCESS,
    component: SeriesMatchupPageComponent,
    canActivate: [AuthGuard],
    data: {
      queueReferral: true,
    },
  },
  //Scrimmage Routes
  {
    path: routePaths.SCRIMMAGE,
    component: ScrimmagePageComponent,
    canActivate: [AuthGuard],
  },
  // Payments Routes
  {
    path: routePaths.PAYMENTS,
    component: PaymentsPageComponent,
    canActivate: [AuthGuard],
  },
  // HOTFIX CH891
  {
    path: generateOrganizationSlugRoute("city-of-norwell"),
    redirectTo: generateOrganizationSlugRoute("town-of-norwell"),
    pathMatch: "full",
  },
  // HOTFIX CH3810
  {
    path: generateOrganizationSlugRoute("city-of-south-san-francisco"),
    redirectTo: generateOrganizationSlugRoute("south-san-francisco"),
    pathMatch: "full",
  },
  {
    path: generateOrganizationSlugRoute("343"),
    redirectTo: generateOrganizationSlugRoute("265"),
    pathMatch: "full",
  },
  {
    path: generateOrganizationSlugRoute("273"),
    redirectTo: generateOrganizationSlugRoute("265"),
    pathMatch: "full",
  },
  // END HOTFIX CH3810
  // Organization
  {
    path: routePaths.ORGANIZATION_SEARCH,
    redirectTo: routePaths.CLUB_SEARCH,
  },
  {
    path: routePaths.ORGANIZATION_SEARCH_ALT,
    redirectTo: routePaths.CLUB_SEARCH,
  },
  {
    path: routePaths.ORGANIZATION,
    redirectTo: routePaths.CLUB,
  },
  {
    path: routePaths.CLUB_PASS_SUCCESS,
    loadChildren: () => import("./clubs/clubs.module").then(m => m.ClubsModule),
    canActivate: [AuthGuard],
    data: {
      passSuccess: true,
    },
  },
  // END AUTHENTICATED ROUTES
  // 404 Catchall
  {
    path: routePaths.NO_PERMISSIONS,
    component: NoPermissionsPageComponent,
    canActivate: [PreLogin],
  },
  {
    path: routePaths.NOT_FOUND,
    component: NotfoundPageComponent,
    canActivate: [PreLogin],
  },
];

interface ExteralRedirectRouteData {
  externalUrl: string;
}

@NgModule({
  providers: [
    RedirectGuard,
    // Angular Redirect Shim from https://stackoverflow.com/a/40421975
    {
      provide: "externalUrlRedirectResolver",
      useValue: (route: ActivatedRouteSnapshot) => {
        window.location.href = (route.data as ExteralRedirectRouteData).externalUrl;
      },

    },
  ],
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      initialNavigation: "enabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
