<div class="c_teamRosterBlock">
  <div
    class="c_teamRosterBlock__header"
    *ngIf="!isAccountTeam; else clarificationMessage"
  >
    <h4 class="u_txt--hdg4 c_teamRosterBlock__header__text">Team Roster</h4>
    <div
      class="c_teamRosterBlock__header__editBtn"
      *ngIf="(isTeamCaptain$ | async) && !isAccountTeam"
    >
      <button
        (click)="openMagicLinkModal()"
        class="c_button primary_dark c_teamRosterBlock__magicLinkBtn"
      >
        Add Team Members
      </button>
      <a
        routerLink="/{{ getTeamRosterRoute(teamId) }}"
        class="c_button primary_dark"
      >
        <div
          class="c_teamRosterBlock__editRosterButtonContainer"
          attr.data-count="{{ notificationCount$ | async }}"
          [ngClass]="{
            c_teamRosterBlock__badgeContainer: notificationCount$ | async
          }"
        >
          Edit Roster
        </div>
      </a>
    </div>
    <div
      class="c_teamRosterBlock__header__editBtn"
      *ngIf="(isTeamMember$ | async) === false"
    >
      <button
        (click)="requestToJoinTeam()"
        class="c_button primary_dark"
        *ngIf="!isAccountTeam"
      >
        Request to Join Team
      </button>
    </div>
    <div
      class="c_teamRosterBlock__header__editBtn"
      *ngIf="userCanRemoveSelf$ | async"
    >
      <button
        class="c_button primary_dark c_leaveBtn"
        (click)="openLeaveTeamModal()"
      >
        Leave Team
      </button>
    </div>
  </div>
  <div class="c_teamRosterBlock__starters" *ngIf="!isAccountTeam">
    <h5 class="u_txt--hdg5 c_teamRosterBlock__header">Starters</h5>
    <div
      class="c_teamRosterBlock__rosterGrid"
      *ngIf="(teamStarters$ | async)?.length > 0; else startersUnavailable"
    >
      <app-large-roster-icon
        *ngFor="let starter of teamStarters$ | async"
        [rosterMemberData]="starter"
      >
      </app-large-roster-icon>
    </div>
  </div>
  <div class="c_teamRosterBlock__bench" *ngIf="!isAccountTeam">
    <h5 class="u_txt--hdg5 c_teamRosterBlock__header">Bench</h5>
    <div
      class="c_teamRosterBlock__rosterGrid"
      *ngIf="(teamBench$ | async)?.length > 0; else benchUnavailable"
    >
      <app-large-roster-icon
        *ngFor="let bench of teamBench$ | async"
        [rosterMemberData]="bench"
      >
      </app-large-roster-icon>
    </div>
  </div>
  <ng-template #clarificationMessage>
    <div class="c_teamRosterBlock__accountTeam">
      <h4 class="u_txt--hdg4">Account Team</h4>
      <p class="u_txt c_teamRosterBlock__accountTeamInfo">
        This is an account team. Everyone at GGLeagues has an account team
        that's used for single-player leagues. Account teams cannot be joined by
        other players.
      </p>
    </div>
  </ng-template>
</div>
<ng-template #benchUnavailable>
  <p>There are no benched players</p>
</ng-template>

<ng-template #startersUnavailable>
  <p>There are no starting players</p>
</ng-template>
