<article class="c_leagueCarouselFeature">
  <div class="c_leagueCarouselFeature__carousel">
    <div class="keen-slider" #sliderRef>
      <div class="c_leagueCarouselFeature__slide keen-slider__slide number-slide" *ngFor="let card of gameCards"
        style="background-image: url('{{ cardUrl(card) }}')"></div>
    </div>
  </div>
  <div class="c_leagueCarouselFeature__sliderDots">
    <span class="c_leagueCarouselFeature__slider__title">{{
      gameCards[currentSlide]?.cardTitle
      }}</span>
    <button (click)="slider.moveToIdx(i)" *ngFor="let slide of sliderDotHelper; let i = index" [class]="
        'c_leagueCarouselFeature__sliderDot ' +
        (i === currentSlide ? 'active' : '')
      " [attr.aria-label]="'Go to Slide #' + (i + 1)"></button>
  </div>
  <div class="c_leagueCarouselFeature__copy" #weeklies>
    <ng-container *ngIf="isWeeklies; else seasons">
      <header class="c_leagueCarouselFeature__copy__headings">
        <h4 class="u_txt--hdg4">Weeklies</h4>
        <p>
          <strong>Free</strong>
          <span>with restrictions</span>
        </p>
      </header>
      <section class="c_leagueCarouselFeature__copy__body">
        <p>
          Check out GGLeagues Weeklies Tournaments. These run nightly throughout
          the week.
        </p>
        <ul>
          <li>Fun, Social, Friendy Competition</li>
          <li>Play solo or with Friends</li>
          <li>Moderated by GGLeagues Referees</li>
          <li>Prizes for 1st, 2nd, 3rd, and 4th places</li>
        </ul>
      </section>
      <div class="c_leagueCarouselFeature__copy__buttonContainer">
        <button (click)="weeklyButtonAction()" class="c_button c_button--slim">
          Sign up for Free
        </button>
      </div>
    </ng-container>
    <ng-template #seasons>
      <header class="c_leagueCarouselFeature__copy__headings">
        <h4 class="u_txt--hdg4">Season Pass</h4>
        <p>
          <strong>$40</strong>
          <span>per 3 month season</span>
        </p>
      </header>
      <section class="c_leagueCarouselFeature__copy__body">
        <ng-container *ngIf="userOwnsPass; else default">
          <div class="c_leagueCarouselFeature__copy__passOwner">
            <i aria-hidden="true" class="fas fa-ticket-alt fa-5x"></i>
            <p>
              You already own a pass!<br />Click below to see a list of leagues
              you can join!
            </p>
          </div>
        </ng-container>
        <ng-template #default>
          <p>
            Take the competition up a level with our League Season. Compete in
            solo or team play in as many games as you want.
          </p>
          <ul>
            <li>Summer, Winter, Spring, and Fall Seasons</li>
            <li>6 week of regulation play, 2 weeks of playoffs</li>
            <li>All participants receive GGLeagues Swag</li>
            <li>Playoffs qualifiers receive prizes</li>
            <li>Champions receive prize, trophy, and title</li>
          </ul>
        </ng-template>
      </section>
      <div class="c_leagueCarouselFeature__copy__buttonContainer">
        <button (click)="seasonPassButtonAction()" class="c_button c_button--slim">
          {{ recButtonCopy }}
        </button>
      </div>
    </ng-template>
  </div>
</article>