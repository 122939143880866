import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Token } from "@stripe/stripe-js";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "@environments/environment";

import {
  AvailableEsport,
  EsportUserCredential,
  PersonalUserProfile,
  ProfileAnnouncement,
  UserEsportsPass,
  UserProfile,
  UserTeam,
} from "src/app/reducers/user/user.types";
import { basicAuthHeader } from "src/app/util/auth-utils";
import {
  UserDataAPIResponse,
  UserProfileDataAPIResponse,
  UserUpdateForm,
  UserSignupObject,
  UserTeamAPIData,
  AvailableEsportAPIData,
  EsportUserCredentialAPIData,
  APIEsportUserCredentials,
  EsportUserCredentialsAttributes,
  UserPassAPIData,
  UserProfileAnnouncementData,
} from "./user.api.types";
import { apiToPlatform } from "src/app/enums/game-platforms.enum";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private _http: HttpClient) { }

  public getUser(id: number): Observable<UserProfile> {
    const url = `${environment.apiBase}/api/v1/users/${id}.json`;
    const headers = basicAuthHeader();
    return this._http.get<UserDataAPIResponse>(url, {
      headers,
    }).pipe(map((apiResponse) => this._mapUser(apiResponse)));
  }

  public getProfile(): Observable<PersonalUserProfile> {
    const url = `${environment.apiBase}/api/v1/users/profile.json`;
    const headers = basicAuthHeader();
    return this._http.get<UserProfileDataAPIResponse>(url, {
      headers,
    }).pipe(map((apiResponse) => this._mapUserProfile(apiResponse)));
  }

  //TODO: Clean up --Christian
  //User is only if the auth token sent by bearer matches the user_id in the url
  public updateUser(info: UserUpdateForm, id: number): Observable<{ success: string[] }> {
    for (const property in info.user) {
      if (Object.prototype.hasOwnProperty.call(info.user, property)) {
        //Make sure we do not accept any
        if (
          property === "in_game_name" ||
          property === "email" ||
          property === "first_name" ||
          property === "last_name" ||
          property === "discord_id" ||
          property === "zip_code"
        ) {
          if (info[property] === null || info[property] === undefined || info[property] === "") {
            delete info[property];
          }
        }
        //Clears any value that is null or undefined so that way
        //we only pass empty strings to the db
        if (info[property] === null || info[property] === undefined) {
          //Check if required again
          delete info[property];
        }
      }
    }
    const url = `${environment.apiBase}/api/v1/users/${id}`;
    const headers = basicAuthHeader();
    return this._http.patch<{ success: string[] }>(url, info, {
      headers,
    });
  }

  public updateUserPhone(phoneNumber: string, id: number): Observable<{ success: string[] }> {
    const info = {
      phone: phoneNumber,
    };
    const url = `${environment.apiBase}/api/v1/users/${id}`;
    const headers = basicAuthHeader();
    return this._http.patch<{ success: string[] }>(url, info, {
      headers,
    });
  }

  public createUser(signupInfo: UserSignupObject): Observable<unknown> {
    const url = `${environment.apiBase}/api/`;
    let newUserPayload;

    if (signupInfo.referral_code) {
      newUserPayload = {
        api_user: {
          ...signupInfo,
          referral_code: undefined,
        },
        user_referral: {
          referral_code: signupInfo.referral_code,
        },
      };
    } else {
      newUserPayload = {
        api_user: {
          ...signupInfo,
        },
        user_referral: {
          referral_code: "",
        },
      };
    }

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this._http
      .post(url, newUserPayload, {
        headers,
      });
  }

  public createUserWithParentalConsent(parentEmail: string, stripeToken: Token, signupInfo: UserSignupObject): Observable<unknown> {
    const url = `${environment.apiBase}/api/v1/parental_consents	`;
    const payload = {
      parental_consent: {
        parent_email: parentEmail,
        method_data: {
          stripe_token: stripeToken,
        },
        user: {
          ...signupInfo,
        },
      },
    };

    if (signupInfo.referral_code) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (payload.parental_consent as any).referral_code = signupInfo.referral_code;
    }

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this._http
      .post(url, payload, {
        headers,
      });
  }

  public updateUserEsportCredentials(esportCredentials: EsportUserCredentialsAttributes[]): Observable<unknown> {
    const url = `${environment.apiBase}/api/v1/users/esport_user_credential`;
    const headers = basicAuthHeader();
    const payload: APIEsportUserCredentials = {
      user: {
        esport_user_credentials_attributes: [],
      },
    };
    //SEE: https://stackoverflow.com/questions/11704267/in-javascript-how-to-conditionally-add-a-member-to-an-object
    esportCredentials.map((esport) => {
      const { id, esportId, userEsportId } = esport;
      const isMarkedForDestroy = userEsportId?.length === 0 ?? true;
      const isUserEsportIdPresent = userEsportId?.length > 0 ?? false;
      const esportUserCredentialAttribute = {
        ...(id && {
          id,
        }),
        ...(isMarkedForDestroy && {
          _destroy: true,
        }),
        ...(isUserEsportIdPresent && {
          user_esport_id: userEsportId,
        }),
        esport_id: esportId,
      };
      payload.user.esport_user_credentials_attributes.push(esportUserCredentialAttribute);
    });
    return this._http.post(url, payload, {
      headers,
    });
  }

  private _mapUser(apiResponse: UserDataAPIResponse): UserProfile {
    const {
      id,
      inGameName,
      teams,
      esportCredentials = {
        data: [],
      },
    } = apiResponse.data.attributes;
    return {
      id,
      inGameName,
      type: "profile",
      teams: teams.map((team) => ({
        id: team.team_id,
        isCaptain: team.captain,
        title: team.name,
        logoUrl: "",
        teamType: team.team_type,
      })),
      esportCredentials:
        esportCredentials.data ? esportCredentials.data.map((esportCredential) => this._mapEsportUserCredential(esportCredential)) : [],
    };
  }

  private _mapUserProfile(apiResponse: UserProfileDataAPIResponse): PersonalUserProfile {
    //Guard clause
    if (!apiResponse) {
      return null;
    }

    const {
      id,
      description,
      discordId,
      email,
      firstName,
      lastName,
      inGameName,
      isAdmin,
      parentalConsentStatus,
      teams,
      phone,
      availableEsports,
      userOrganizationSeasonPasses,
      esportCredentials = {
        data: [],
      },
      announcements,
      zipCode,
    } = apiResponse.data.attributes;
    return {
      id,
      type: "profile",
      description,
      discordId,
      email,
      firstName,
      lastName,
      inGameName,
      isAdmin,
      phoneNumber: phone,
      parentalConsentStatus,
      zipCode,
      gamePasses: userOrganizationSeasonPasses.data.map((userPassData) => this._mapUserPasses(userPassData)),
      teams: teams.data.filter((team) => !!team).map((team) => this._mapUserTeam(team, id)),
      availableEsports: availableEsports.data.filter((esport) => !!esport).map((esport) => this._mapAvailableEsports(esport)),
      esportCredentials:
        esportCredentials.data
          .filter((esportCredential) => !!esportCredential)
          .map((esportCredential) => this._mapEsportUserCredential(esportCredential)),
      announcements: announcements.data.map(this._mapUserAnnouncements),
    };
  }

  private _mapUserTeam(apiUserTeam: UserTeamAPIData, userId: number): UserTeam {
    const { id, title, captain, playerCount, teamType, captainId } = apiUserTeam.attributes;
    const isCaptain = userId === captainId;
    const logoUrl = "";
    return {
      id,
      title,
      captain,
      playerCount,
      teamType,
      isCaptain,
      logoUrl,
    };
  }

  private _mapAvailableEsports(apiEsport: AvailableEsportAPIData): AvailableEsport {
    const { id, type } = apiEsport;
    const name = apiToPlatform(apiEsport.attributes.name);
    return {
      id,
      type,
      name,
    };
  }

  private _mapEsportUserCredential(apiEsportCredential: EsportUserCredentialAPIData): EsportUserCredential {
    const { id, type } = apiEsportCredential;
    const { userEsportId } = apiEsportCredential.attributes;
    const esport = apiToPlatform(apiEsportCredential.attributes.esport);
    return {
      id,
      type,
      userEsportId,
      esport,
    };
  }

  private _mapUserPasses({ id, type, attributes: { status, organizationSeasonPass } }: UserPassAPIData): UserEsportsPass {
    const { id: seasonPassId, attributes: orgPassDataAttributes } = organizationSeasonPass.data;
    return {
      id,
      type,
      seasonPassId,
      status,
      title: orgPassDataAttributes.title,
      orgId: orgPassDataAttributes.organizationId.toString(),
      orgName: orgPassDataAttributes.organizationName,
    };
  }

  private _mapUserAnnouncements(dataAnnouncement: UserProfileAnnouncementData): ProfileAnnouncement {
    return {
      id: dataAnnouncement.id,
      type: dataAnnouncement.type,
      ...dataAnnouncement.attributes,
    };
  }

}
