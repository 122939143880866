<app-page>
  <div class="c_matchPage">
    <header>
      <h2 class="c_noPermissionsPage__text u_txt--hdg2">Match Not Available</h2>
    </header>
    <div class="c_matchPage__icon">
      <i class="fas fa-archive fa-8x"></i>
    </div>
    <p class="u_txt">This match has been archived</p>
    <div class="c_matchPage__homeBtn">
      <a routerLink="/{{ routes.HOME }}" class="c_button primary_dark"
        >Return to Homepage</a
      >
    </div>
  </div>
</app-page>
