import { Component, forwardRef } from "@angular/core";
import {
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from "@angular/forms";

import { OK_CITIES } from "src/app/enums/geo/okla-cities.enum";
import { BaseCVA } from "src/app/components/base-cva/base-cva.class";
import { JoinLeagueModalService } from "src/app/stateful-services/join-league-modal/join-league-modal.service";

@Component({
  selector: "app-city-selection-step",
  templateUrl: "./city-selection-step.component.html",
  styleUrls: ["./city-selection-step.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CitySelectionStepComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CitySelectionStepComponent),
      multi: true,
    },
  ],
})
export class CitySelectionStepComponent extends BaseCVA implements Validator {
  // TODO Remove this when hardcode is no longer needed
  public okCities = OK_CITIES;

  constructor(private _formBuilder: FormBuilder, private _joinLeagueModalService: JoinLeagueModalService) {
    super();
    this.controlForm = this._formBuilder.group({
      selectedCity: ["", Validators.required],
    });
  }

  public get isFirstStep(): boolean {
    return this._joinLeagueModalService.isCityStepFirst;
  }

  /* Validator Functions */

  public validate(): ValidationErrors | null {
    return this.controlForm.valid ?
      null :
      {
        invalidForm: {
          valid: false,
          message: "No city selected",
        },
      };
  }

  public get errorMessage(): string {
    return this.controlForm.invalid ? "No city selected" : "";
  }
}
