import {
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { Store } from "@ngrx/store";
import {
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import {
  take,
  filter,
  takeUntil,
} from "rxjs/operators";

import { TeamsService } from "@services/teams.service";
import { ImageResizeService, ProcessedFile } from "@services/images/image-resize/image-resize.service";

import { RootState } from "src/app/reducers";
import { GetTeamById } from "src/app/reducers/teams/teams.actions";
import { profanityValidator } from "src/app/util/form-helpers";
import { Unsubscriber } from "src/app/util/unsubscriber";
import { ComponentRouteParams } from "src/app/enums/routes/routeParams";
import { generateTeamDetailRoute } from "src/app/enums/routes/routePaths";
import { FileFormController } from "src/app/components/file-form-controller/file-form-controller.abstract";
import { ImageTypes } from "src/app/util/image-utils";
import { TeamUpdateRequest } from "src/app/reducers/teams/teams.types";
import { Logger as logger } from "../../util/logger";

@Component({
  selector: "app-team-edit-page",
  templateUrl: "./team-edit-page.component.html",
  styleUrls: ["./team-edit-page.component.scss"],
})
export class TeamEditPageComponent extends FileFormController implements OnInit, OnDestroy {
  public teamEditForm: FormGroup;
  public isLoading = true;
  public imageError = false;
  public teamId: number;
  public serverError = false;

  private _unsub = new Unsubscriber();
  private _imageSrc: string;

  constructor(
    private _teamsService: TeamsService,
    private _store: Store<RootState>,
    private _titleService: Title,
    private _route: ActivatedRoute,
    private _router: Router,
    private _imgResizeService: ImageResizeService
  ) {
    super(_imgResizeService);
  }

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | Editing Team");
    this._route.paramMap.pipe(take(1)).subscribe((params) => {
      this.teamId = +params.get(ComponentRouteParams.TEAM_ID);
      this._store.dispatch(new GetTeamById(this.teamId));
    });

    this._store
      .select("teams", "currentTeam")
      .pipe(
        filter((team) => team !== null),
        takeUntil(this._unsub.unsubEvent)
      )
      .subscribe((teamData) => {
        this._imageSrc = teamData.logoUrl ?? ImageTypes.TEAM_SQ;

        this.teamEditForm = new FormGroup({
          title: new FormControl(teamData.title, [
            Validators.required,
            profanityValidator,
            Validators.maxLength(32),
          ]),
          description: new FormControl(teamData.description, [profanityValidator]),
          logoUrl: new FormControl(),
        });

        this._loadForm(this.teamEditForm);
        this.isLoading = false;
      });
  }

  public ngOnDestroy() {
    this._unsub.kill();
  }

  public updateTeam(): void {
    if (this._canProceed()) {
      const formValues = this.teamEditForm.value;
      const formObj: TeamUpdateRequest = {
        id: this.teamId,
        logo: this.imageFile?.file ?? null,
        title: formValues.title,
        description: formValues.description,
      };

      this._teamsService.editTeam(formObj).subscribe(
        //Success
        () => {
          this._hasBlockingErrors = false;
          this._store.dispatch(new GetTeamById(this.teamId));
          this._router.navigate([this.getTeamDetailRoute(this.teamId)]);
        },
        //Error
        (error) => {
          this._hasBlockingErrors = false;
          this.serverError = true;
          logger.error(error);
        }
      );
      this._hasBlockingErrors = false;
    }
  }

  public getTeamDetailRoute(id: number): string {
    return generateTeamDetailRoute(id.toString());
  }

  public get image(): string {
    return this.imageFile?.dataURL ?? this._imageSrc;
  }

  public get isUsingDefaultImage(): boolean {
    return this.image === ImageTypes.TEAM_SQ;
  }

  public get updatedFileName(): string {
    const file: ProcessedFile = this.teamEditForm.get("loadedFile").value;
    return file?.file.name ?? "";
  }
}
