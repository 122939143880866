import { Action } from "@ngrx/store";

import { UserDashboard } from "./dashboard.types";

export enum DashboardActionTypes {
  GET_GENERIC_DASHBOARD = "[Dashboard] Get Generic Dashboards",
  GET_GENERIC_DASHBOARD_ERROR = "[Dashboard] Get Generic Dashboards error",
  GET_GENERIC_DASHBOARD_SUCCESS = "[Dashboard] Get Generic Dashboards success",
  CLEAR_DASHBOARD = "[Dashboard] Clear Dashboard",
}

export class GetLeagueDashboard implements Action {
  public readonly type = DashboardActionTypes.GET_GENERIC_DASHBOARD;
  constructor(public payload: void) { }
}

export class GetLeagueDashboardSuccess implements Action {
  public readonly type = DashboardActionTypes.GET_GENERIC_DASHBOARD_SUCCESS;
  constructor(public payload: UserDashboard) { }
}

export class GetLeagueDashboardError implements Action {
  public readonly type = DashboardActionTypes.GET_GENERIC_DASHBOARD_ERROR;
  constructor(public payload: unknown) { }
}

export class ClearDashboard implements Action {
  public readonly type = DashboardActionTypes.CLEAR_DASHBOARD;
  constructor(public payload: void) { }
}

export type DashboardActions =
  GetLeagueDashboard |
  GetLeagueDashboardSuccess |
  GetLeagueDashboardError |
  ClearDashboard;
