<app-page>
  <div class="c_rulesPdfContainer">
    <h1 class="u_txt--hdg1">{{ rulebookTitle }}</h1>
    <ng-container *ngIf="hasRules; else invalid">
      <div class="c_rulesPdfContainer__btns">
        <a [routerLink]="rulesRoute" class="c_button secondary_dark"
          >Go back to Rules Page</a
        >
        <a [href]="rulebookPath" target="_blank" class="c_button primary_dark"
          >Download .PDF <i class="fas fa-file-download"></i
        ></a>
      </div>
      <embed [src]="rulebookPath" />
    </ng-container>
    <ng-template #invalid>
      <div class="c_rulesPdfContainer__invalid">
        <i class="fas fa-exclamation-triangle fa-9x"></i>
        <h3 class="u_txt--hdg3">Error! Invalid rulebook URL provided</h3>
        <a [routerLink]="rulesRoute" class="c_button primary_dark"
          >Go back to Rules Page</a
        >
      </div>
    </ng-template>
  </div>
</app-page>
