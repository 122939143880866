<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
  [modalFooter]="footer"
></app-modal-container>

<ng-template #title>
  <app-modal-title>Leave Team?</app-modal-title>
</ng-template>
<ng-template #content>
  <p>Are you sure you want to leave this team?</p>
</ng-template>
<ng-template #footer>
  <app-modal-footer-buttons>
    <button class="c_button secondary_dark" (click)="close()">Cancel</button>
    <button class="c_button primary_dark" (click)="leaveTeam()">Confirm</button>
  </app-modal-footer-buttons>
</ng-template>
