import { Injectable } from "@angular/core";
import {
  Actions,
  Effect,
  ofType,
} from "@ngrx/effects";
import {
  map,
  mergeMap,
  catchError,
  take,
} from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { of } from "rxjs";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";

import { Logger as logger } from "../../util/logger";
import {
  TeamsActionTypes,
  GetTeamsByIdSuccess,
  GetTeamByIdError,
  GetTeamsSuccess,
  GetTeamsError,
  GetTeams,
  GetTeamById,
  UpdateTeamRoster,
  UpdateTeamRosterError,
  UpdateTeamRosterSuccess,
  AddTeamToLeague,
  AddTeamToLeagueSuccess,
  AddTeamToLeagueError,
  RequestRoster,
  RequestRosterSuccess,
  RequestRosterError,
} from "./teams.actions";
import { TeamsService } from "../../services/teams.service";
import { TeamRosterUpdateActions } from "./teams.types";
import { RootState } from "..";
import { GetLeagueById } from "../leagues/league.actions";

@Injectable()
export class TeamsEffects {
  @Effect()
  public teams$ = this._actions$.pipe(
    ofType<GetTeams>(TeamsActionTypes.GET_TEAMS),
    map((action) => action.payload),
    mergeMap(() => this._teamsService.getTeams()),
    mergeMap((teamList) => {
      if (teamList.length > 0 && teamList) {
        return of(new GetTeamsSuccess(teamList));
      } else {
        logger.error("Couldn't Retrieve Teams");
        return of(new GetTeamsError());
      }
    }),
    catchError(() => {
      this._toastr.error("Couldn't Retrieve Teams", "Error");
      return of(new GetTeamsError());
    })
  );

  @Effect()
  public team$ = this._actions$.pipe(
    ofType<GetTeamById>(TeamsActionTypes.GET_TEAM_BY_ID),
    map((action) => action.payload),
    mergeMap((id) => this._teamsService.getTeamDetails(id)),
    mergeMap((team) => {
      if (team) {
        return of(new GetTeamsByIdSuccess(team));
      } else {
        this._toastr.error("Couldn't Find That Team", "Error");
        return of(new GetTeamByIdError());
      }
    }),
    catchError(() => {
      this._toastr.error("Couldn't Find That Team", "Error");
      return of(new GetTeamByIdError());
    })
  );

  @Effect()
  public teamUpdate$ = this._actions$.pipe(
    ofType<UpdateTeamRoster>(TeamsActionTypes.UPDATE_ROSTER),
    map((action) => action.payload),
    mergeMap((payload) => {
      const teamUserId = payload.teamUserId.toString();
      switch (payload.rosterAction) {
        case TeamRosterUpdateActions.ACCEPT_INVITE:
          return this._teamsService.approveUserInvite(teamUserId);
        case TeamRosterUpdateActions.ACTIVATE_PLAYER:
          return this._teamsService.activatePlayer(teamUserId);
        case TeamRosterUpdateActions.BENCH_PLAYER:
          return this._teamsService.benchPlayer(teamUserId);
        case TeamRosterUpdateActions.DELETE_PLAYER:
          return this._teamsService.removeUserFromTeam(teamUserId);
        case TeamRosterUpdateActions.REJECT_PLAYER:
          return this._teamsService.rejectUserInvite(teamUserId);
        default:
          return of("ERROR");
      }
    }),
    mergeMap((result) => {
      if (result !== "ERROR") {
        return of(new UpdateTeamRosterSuccess());
      } else {
        this._toastr.error("Failed to Update Team, Please Try Again", "Error");
        return of(new UpdateTeamRosterError());
      }
    }),
    catchError(() => {
      this._toastr.error("Failed to Update Team, Please Try Again", "Error");
      return of(new GetTeamByIdError());
    })
  );

  @Effect()
  public teamUpdateSuccess$ = this._actions$.pipe(
    ofType<UpdateTeamRosterSuccess>(TeamsActionTypes.UPDATE_ROSTER_SUCCESS),
    map((action) => action.payload),
    mergeMap(() =>
      this._store.select("teams", "currentTeam").pipe(
        take(1),
        mergeMap((team) => {
          this._toastr.success("Roster Updated!");
          return of(new GetTeamById(+team.id));
        })
      )
    )
  );

  @Effect()
  public addTeamToLeague$ = this._actions$.pipe(
    ofType<AddTeamToLeague>(TeamsActionTypes.ADD_TEAM_TO_LEAGUE),
    map((action) => action.payload),
    mergeMap((payload) => this._teamsService.addTeamToLeague(payload.teamId, payload.leagueId)),
    mergeMap((result) => {
      if (result !== "ERROR") {
        this._toastr.success("Added Team to League Successfully");
        return of(new AddTeamToLeagueSuccess());
      } else {
        this._toastr.error("Couldn't Join the League");
        return of(new AddTeamToLeagueError());
      }
    }),
    catchError((err) => {
      this._toastr.error("Couldn't Join the League");
      logger.error(err);
      return of(new AddTeamToLeagueError());
    })
  );

  @Effect()
  public teamRequest$ = this._actions$.pipe(
    ofType<RequestRoster>(TeamsActionTypes.ROSTER_REQUEST),
    map((action) => action.payload),
    mergeMap((payload) => this._teamsService.addUserToTeam(payload.teamId, payload.userId)),
    mergeMap((result) => {
      if (result !== "ERROR") {
        this._toastr.success("Request to Join Team Sent!");
        return of(new RequestRosterSuccess());
      } else {
        this._toastr.error("Request to Join Team Failed");
        return of(new RequestRosterError());
      }
    }),
    catchError((err) => {
      const error = err.error.errors["team_users.base"];
      if (error) {
        const errorMessage = error[0];
        this._toastr.error(errorMessage.replace("This user is", "You are"));
      } else {
        this._toastr.error("Request to Join Team Failed.");
      }
      return of(new RequestRosterError());
    })
  );

  @Effect()
  public addTeamToLeagueSuccess$ = this._actions$.pipe(
    ofType<AddTeamToLeagueSuccess>(TeamsActionTypes.ADD_TEAM_TO_LEAGUE_SUCCESS),
    map((action) => action.payload),
    mergeMap(() =>
      this._store.select("leagues", "league").pipe(
        take(1),
        mergeMap((team) => of(new GetLeagueById(+team.id)))
      )
    )
  );

  constructor(
    private _actions$: Actions,
    private _teamsService: TeamsService,
    private _toastr: ToastrService,
    private _store: Store<RootState>,
    private _router: Router
  ) {}
}
