import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";

import { environment } from "@environments/environment";
import { SeriesMatchupV2APIPlayer, SeriesMatchupV2APITeam } from "@services/matches/matches.api.types";
import { Logger } from "@utils/logger";
import { basicAuthHeader } from "@utils/auth-utils";

import { ScrimmageAPIResponse } from "./scrimmage.api.types";
import {
  Scrimmage,
  ScrimmageTeam,
  SeriesMatchupV2Player,
} from "src/app/reducers/matches/matches.types";
import { apiToPlatform } from "src/app/enums/game-platforms.enum";

@Injectable({
  providedIn: "root",
})
export class ScrimmageService {
  constructor(private _httpService: HttpClient) {}

  public getScrimmageById(id: string): Observable<Scrimmage> {
    const url = `${environment.apiBase}/api/v2/scrimmages/${id}`;
    const options = {
      headers: basicAuthHeader(),
    };
    return this._httpService.get<ScrimmageAPIResponse>(url, options).pipe(
      map((scrimmage) => this._mapScrimmage(scrimmage)),
      catchError((err) => {
        Logger.error(err);
        return of(null);
      })
    );
  }

  private _mapScrimmage(apiResponse: ScrimmageAPIResponse): Scrimmage {
    const { id, type, attributes } = apiResponse.data;
    const { createdAt, esport, title, winnerId, teams } = attributes;
    return {
      id,
      type,
      esport: apiToPlatform(esport),
      teams: teams.data.map((team) => this._mapScrimmageTeam(team)),
      startTime: createdAt,
      winnerId,
      title,
    };
  }

  private _mapScrimmageTeam(apiTeam: SeriesMatchupV2APITeam): ScrimmageTeam {
    const { id, attributes } = apiTeam;
    const { logoUrl, players, captain, teamType, title } = attributes;
    return {
      id,
      type: "scrimmageTeam",
      logoUrl,
      teamType,
      players: players.data.map((player) => this._mapScrimmagePlayer(player, captain.data.id)),
      title,
    };
  }

  private _mapScrimmagePlayer(apiPlayer: SeriesMatchupV2APIPlayer, captainId: string): SeriesMatchupV2Player {
    const { id, type, attributes } = apiPlayer;
    const { esportCredential, username } = attributes;
    return {
      id,
      type,
      esportCredential,
      username,
      isCaptain: captainId === id,
    };
  }
}
