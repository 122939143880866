<ng-template #title>
  <app-modal-title>Check-in to Game</app-modal-title>
</ng-template>

<ng-template #content>
  <ng-container [ngSwitch]="currentStep" *ngIf="checkinInfo">
    <div *ngSwitchCase="stepEnum.ADD_CREDENTIALS">
      <p>
        You must connect your {{ checkinInfo.esport }} Account to your GGLeagues
        account before you checkin so we can track your score
      </p>
      <form
        class="c_brCheckin__credentialFormPanel"
        [formGroup]="credentialsForm"
        (submit)="saveCredentials(credentialsForm)"
      >
        <app-game-credentials
          [gameCredential]="''"
          [esport]="checkinInfo.esport"
          [isUser]="true"
          [isInEditMode]="true"
          [formControlName]="'requiredEsportCredential'"
        >
        </app-game-credentials>
        <div class="c_brCheckin__formButtons">
          <div class="c_brCheckin__formError" *ngIf="credentialsFormHasError">
            <small
              >There was an error saving your credentials, please try
              again</small
            >
          </div>
          <button class="c_button" [disabled]="credentialFormLoading">
            <span *ngIf="!credentialFormLoading"> Save Credential </span>
            <span *ngIf="credentialFormLoading">
              <i
                class="c_brCheckin__loadingSpinner"
                class="fa fa-circle-notch fa-spin"
              ></i>
            </span>
          </button>
        </div>
      </form>
    </div>
    <div *ngSwitchCase="stepEnum.EDIT_CREDENTIALS">
      <p>
        Please ensure that the linked {{ checkinInfo.esport }} Account is
        accurate
      </p>
      <form
        class="c_brCheckin__credentialFormPanel"
        [formGroup]="editCredentialsForm"
        (submit)="saveCredentials(editCredentialsForm, true)"
      >
        <app-game-credentials
          [gameCredential]="credentialValue.userEsportId"
          [esport]="checkinInfo.esport"
          [isUser]="true"
          [isInEditMode]="true"
          [formControlName]="'requiredEsportCredential'"
        >
        </app-game-credentials>
        <div class="c_brCheckin__formError" *ngIf="credentialsFormHasError">
          <small
            >There was an error saving your credentials, please try again</small
          >
        </div>
        <div class="c_brCheckin__formButtons">
          <button
            class="c_button secondary_dark"
            [disabled]="credentialFormLoading"
            (click)="backToCheckin()"
          >
            Cancel
          </button>
          <button class="c_button" [disabled]="credentialFormLoading">
            <span *ngIf="!credentialFormLoading"> Save </span>
            <span *ngIf="credentialFormLoading">
              <i
                class="c_brCheckin__loadingSpinner"
                class="fa fa-circle-notch fa-spin"
              ></i>
            </span>
          </button>
        </div>
      </form>
    </div>
    <div *ngSwitchCase="stepEnum.CHECK_IN">
      <p>
        Checking in marks you "ready" to play in the
        {{ checkinInfo.esport }} lobby, and will show you the lobby's unique
        code when it is available. Make sure to watch the announcements box to
        know when the unique code is available
      </p>
      <p>
        Please make sure your {{ checkinInfo.esport }} Username is correct, as
        it is used to assign points to you when the match is over.
      </p>
      <div>
        <dl>
          <dt>{{ checkinInfo.esport }} Username:</dt>
          <dd>
            <strong>
              {{ (userEsportCredential$ | async).userEsportId }}
            </strong>
            <button class="c_linkBtn" (click)="editCredential()">(Edit)</button>
          </dd>
        </dl>
      </div>
      <div *ngIf="checkinInfo.esport === gamePlatforms.FORTNITE">
        <p>Before you check in for Fortnite, please accept this checklist:</p>
        <ul class="c_brCheckin__fortniteChecklist">
          <li>
            <label
              [ngClass]="{
                'u_txt--warn':
                  !fortniteChecklist.serverEast && errorPreventingCheckin
              }"
            >
              <input
                [(ngModel)]="fortniteChecklist.serverEast"
                type="checkbox"
              />
              I've set my server to the "NA-East" region
            </label>
          </li>
          <li>
            <label
              [ngClass]="{
                'u_txt--warn':
                  !fortniteChecklist.gameMode && errorPreventingCheckin
              }"
            >
              <input [(ngModel)]="fortniteChecklist.gameMode" type="checkbox" />
              I've selected the correct game mode ("Solos" for 1v1 tournaments,
              "Duos" for 2v2 tournaments)
            </label>
          </li>
          <li>
            <label
              [ngClass]="{
                'u_txt--warn':
                  !fortniteChecklist.namecheck && errorPreventingCheckin
              }"
            >
              <input
                [(ngModel)]="fortniteChecklist.namecheck"
                type="checkbox"
              />
              I have double-checked my username to make sure it is correct
            </label>
          </li>
        </ul>
      </div>
      <div class="c_brCheckin__checkinButton">
        <small *ngIf="errorPreventingCheckin !== null" class="u_txt--warn">
          {{ errorPreventingCheckin }}
        </small>
        <button class="c_button" (click)="checkinToGame()">Check-In</button>
      </div>
    </div>
    <div *ngSwitchDefault>loading...</div>
  </ng-container>
</ng-template>

<app-modal-container [modalTitle]="title" [modalContent]="content">
</app-modal-container>
