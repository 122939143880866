<div class="c_dashNextGame">
  <ng-container *ngIf="nextMatch$ | async as nextMatch; else noMatch">
    <ng-container [ngSwitch]="getGameStatus(nextMatch.matchTime)">
      <div
        *ngSwitchCase="gameStatuses.IN_PROGRESS"
        class="
          c_dashNextGame__statusBanner c_dashNextGame__statusBanner--inProgress
        "
      >
        Match In Progress
      </div>
      <div
        *ngSwitchCase="gameStatuses.OVERDUE"
        class="
          c_dashNextGame__statusBanner c_dashNextGame__statusBanner--overdue
        "
        role="button"
        (click)="openOverdueModal()"
      >
        Missing Match Results <i class="fas fa-exclamation-circle"></i>
      </div>
    </ng-container>
    <h2 class="c_dashNextGame__title u_txt--hdg2">
      <span class="c_dashNextGame__title__label"> Your Next Match: </span>
      <strong class="c_dashNextGame__title__time">{{
        nextMatch.matchTime | date: "MMMM d @ h:mm a"
      }}</strong>
    </h2>
    <div class="c_dashNextGame__detailsGrid">
      <div
        class="c_dashNextGame__details c_dashNextGame__details__gameImg"
        [style]="showEsportImage(nextMatch.esport)"
      ></div>
      <div class="c_dashNextGame__details">
        <dl class="c_dashNextGame__details__infoList">
          <dt>League Name:</dt>
          <dd>{{ nextMatch.leagueName }}</dd>
          <dt>Matchup:</dt>
          <dd>{{ nextMatch.matchTitle }}</dd>
        </dl>
      </div>
      <div
        class="c_dashNextGame__details c_dashNextGame__details--buttonContainer"
      >
        <a
          *ngIf="nextMatch.gameType === 'standard'"
          [routerLink]="generateMatchupRoute(nextMatch.matchId)"
          class="c_button primary_dark"
          >Go to Match</a
        >
        <a
          [routerLink]="generateLeagueRoute(nextMatch.leagueId)"
          class="c_button secondary_dark"
          >Go to League</a
        >
      </div>
    </div>
  </ng-container>
  <ng-template #noMatch>
    <div class="c_dashNextGame__noUpcomingMatches">
      <i class="fas fa-5x fa-calendar-alt"></i>
      <p class="u_txt">You do not have any upcoming matches at this time</p>
    </div>
  </ng-template>
</div>
