import {
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { Store } from "@ngrx/store";
import {
  mergeMap,
  pluck,
  filter,
  takeUntil,
} from "rxjs/operators";
import { Observable, of } from "rxjs";
import { NgxSmartModalService } from "ngx-smart-modal";

import { GenericRosterMember } from "src/app/reducers/leagues/league.types";
import { RootState } from "src/app/reducers";
import { RequestRoster } from "src/app/reducers/teams/teams.actions";
import { Unsubscriber } from "src/app/util/unsubscriber";
import { generateTeamRosterRoute } from "src/app/enums/routes/routePaths";
import { TeamMagicLinkComponent } from "../team-magic-link/team-magic-link.component";
import { TeamDetails, TeamTypes } from "src/app/reducers/teams/teams.types";
import { LeaveTeamModalComponent } from "../leave-team-modal/leave-team-modal.component";
import { LeaveTeamModalInformation, modalOptions } from "src/app/util/modal-helpers";

@Component({
  selector: "app-team-roster-block",
  templateUrl: "./team-roster-block.component.html",
  styleUrls: ["./team-roster-block.component.scss"],
})
export class TeamRosterBlockComponent implements OnInit, OnDestroy {
  public teamStarters$: Observable<GenericRosterMember[]>;
  public teamBench$: Observable<GenericRosterMember[]>;
  public isTeamCaptain$: Observable<boolean> = of(false);
  public isTeamMember$: Observable<boolean> = of(false);
  public notificationCount$: Observable<number> = of(0);
  public teamId: number;
  public userId: number;
  public isAccountTeam: boolean;
  public team: TeamDetails;

  public userCanRemoveSelf$: Observable<boolean> = of(false);

  private _inviteCode: string;
  private _inviteCodeStatus: string;
  private _userTeamId: number;

  private _unsub = new Unsubscriber();

  constructor(
    private _store: Store<RootState>,
    private _modal: NgxSmartModalService
  ) {}

  public ngOnInit() {
    this._store
      .select("user", "currentUser")
      .pipe(
        pluck("id"),
        mergeMap((userId) => {
          this.userId = userId;
          return this._store.select("teams", "currentTeam").pipe(
            filter((team) => team !== null),
            takeUntil(this._unsub.unsubEvent)
          );
        }),
        takeUntil(this._unsub.unsubEvent)
      )
      .subscribe((team) => {
        const captainId = team.captainId;
        this.team = team;
        this.teamId = team.id;
        this.isTeamCaptain$ = of(captainId === this.userId);
        this.teamStarters$ = of(team.members.starters);
        this.teamBench$ = of(team.members.bench);
        this.notificationCount$ = of(team.members.pending.length);
        this._inviteCode = team.inviteCode;
        this._inviteCodeStatus = team.inviteCodeStatus;
        this.isAccountTeam = team.teamType === TeamTypes.ACCOUNT_SOLO_TEAM;
        const fullTeam = [
          ...team.members.bench,
          ...team.members.pending,
          ...team.members.starters,
        ];
        fullTeam.some((member) => {
          if (+member.id === this.userId) {
            this.isTeamMember$ = of(true);
            this._userTeamId = +member.teamUserId;
            return true;
          }
        });
      });

    this.userCanRemoveSelf$ = this.isTeamCaptain$.pipe(
      mergeMap((isCap) => {
        if (isCap) {
          return of(false);
        }

        return this.isTeamMember$;
      }),
      takeUntil(this._unsub.unsubEvent)
    );
  }

  public ngOnDestroy() {
    this._unsub.kill();
  }

  public requestToJoinTeam() {
    this._store.dispatch(
      new RequestRoster({
        teamId: this.teamId.toString(),
        userId: this.userId.toString(),
      })
    );
  }

  public openMagicLinkModal(): void {
    const data = {
      inviteCode: this._inviteCode,
      inviteCodeStatus: this._inviteCodeStatus,
      teamId: `${this.teamId}`,
    };

    this._modal.create("teamMagicLink", TeamMagicLinkComponent, modalOptions).setData(data).open();
  }

  public openLeaveTeamModal(): void {
    const data: LeaveTeamModalInformation = {
      userTeamId: this._userTeamId,
      team: this.team,
    };

    this._modal.create("leaveTeam", LeaveTeamModalComponent, modalOptions).setData(data).open();
  }

  public getTeamRosterRoute(id: number): string {
    return generateTeamRosterRoute(id.toString());
  }
}
