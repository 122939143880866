import { NavigationEnd, Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import { filter, take } from "rxjs/operators";

/**
 * A base class for smart modals in the future to handle closing events, and any other needs in the future
 *
 * @author Christian Tweed
 */
export abstract class ModalController<T> {
  constructor(protected _modal: NgxSmartModalService, protected _modalIdentifier: string, protected _routerService: Router) {
    this._routerService.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        this.close();
      });
  }

  public close() {
    this._modal.resetModalData(this._modalIdentifier);
    this._modal.closeAll();
  }

  public getData(): T {
    return this._modal.getModalData(this._modalIdentifier) as T;
  }

  public setData(data: T): void {
    this._modal.setModalData(data, this._modalIdentifier);
  }

  public get loaded(): boolean {
    return this._modal.getModal(this._modalIdentifier)?.hasData() ?? false;
  }
}
