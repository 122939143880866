import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { Store } from "@ngrx/store";
import {
  map,
  filter,
  mergeMap,
  take,
} from "rxjs/operators";

import { RootState } from "../reducers";
import { RestoreUserLogin, StashLoginRoute } from "../reducers/user/user.actions";
import { UserProfile } from "../reducers/user/user.types";
import { SessionStorageKeys } from "../enums/session-storage-keys.enum";
import { isValidRouteToStash } from "../util/auth-utils";
import { Logger } from "@utils/logger";
import { routePaths } from "../enums/routes/routePaths";

@Injectable({
  providedIn: "root",
})
export class PreLogin implements CanActivate {
  constructor(private _router: Router, private _store: Store<RootState>) { }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._waitForLoginToLoad().pipe(
      take(1),
      map(() => {
        /*
          Excluding "login" based routes so that we don't wind up resetting the stashed URL
          to dashboard every time the login token expires.  Since /login is a prelogin route
          it would try to stash every time, get caught by the route validator, and stash as
          /dashboard instead of the users intended route
         */
        if (`${next.url[0]}` !== routePaths.LOGIN) {
          const attemptedURL = `/${next.url.join("/")}`;
          const safeURL = isValidRouteToStash(attemptedURL) ? attemptedURL : "/dashboard";
          localStorage.setItem(SessionStorageKeys.REAUTH_REDIRECT, safeURL);
        }

        try {
          const decodedURL = decodeURIComponent(state.url);
          // Stash the route initial attempted to navigate to it after login
          if (isValidRouteToStash(decodedURL)) {
            this._store.dispatch(new StashLoginRoute(decodedURL));
          }
          return true;
        }
        catch (e) {
          Logger.error(e);
          return true;
        }
      })
    );
  }

  private _waitForLoginToLoad(): Observable<UserProfile> {
    return this._store.select("user", "currentUser").pipe(
      mergeMap((userProfile) => {
        if (userProfile) {
          return of(userProfile);
        }

        const sessionJWT = localStorage.getItem(SessionStorageKeys.USER_JWT);

        if (sessionJWT) {
          this._store.dispatch(
            new RestoreUserLogin({
              jwt: sessionJWT,
            })
          );
          return this._store.select("user", "fetchingProfile").pipe(
            filter((fetchingProfile) => !fetchingProfile),
            mergeMap(() => this._store.select("user", "currentUser"))
          );
        } else {
          return of(null);
        }
      })
    );
  }
}
