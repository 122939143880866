<p class="c_forfeitTeamStep__info">
  Which Team
  <strong class="u_txt--warn">is Forfeitting</strong> the Series?
</p>

<form class="c_forfeitTeamStepForm">
  <!-- Not a typo, Sends the winning team as value instead of forfeitted team per API direction -->
  <input
    class="c_forfeitTeamStep__radioInput"
    [formControl]="forfeittingTeamControl"
    [value]="matchReport.teams[0]?.id"
    id="teamOne"
    type="radio"
  />
  <label class="c_forfeitTeamStep__label" for="teamOne">
    <img
      [src]="matchReport.teams[0]?.logoUrl | teamLogoDefault"
      alt="Team Icon"
    />
    <p class="u_txt">{{ matchReport.teams[0].title }}</p>
  </label>
  <!-- Not a typo, Sends the winning team as value instead of forfeitted team per API direction -->
  <input
    class="c_forfeitTeamStep__radioInput"
    [formControl]="forfeittingTeamControl"
    [value]="matchReport.teams[1]?.id"
    id="teamTwo"
    type="radio"
  />
  <label class="c_forfeitTeamStep__label" for="teamTwo">
    <img
      [src]="matchReport.teams[1]?.logoUrl | teamLogoDefault"
      alt="Team Icon"
    />
    <p class="u_txt">{{ matchReport.teams[1]?.title }}</p>
  </label>
</form>

<ng-template #footerButtons>
  <app-modal-stepper-footer-buttons
    [hasPrevious]="true"
    [hasNext]="true"
    [formToCheck]="forfeittingTeamControl"
    [errorMessage]="forfeitFormErrorMessage"
  >
  </app-modal-stepper-footer-buttons>
</ng-template>
