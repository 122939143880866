<p class="c_submissionChoiceStep__info">
  What type of result are you reporting?
</p>

<form class="c_submissionChoiceStep__choices">
  <input
    class="c_submissionChoiceStep__radioInput"
    [formControl]="submissionChoiceControl"
    [value]="submissionTypes.SUBMISSION"
    type="radio"
    id="reportingMatch"
    [attr.disabled]="isEsportAutoSubmitting ? true : null"
  />
  <label
    class="c_submissionChoiceStep__label c_submissionChoiceStep__label--report"
    for="reportingMatch"
    [ngClass]="{
      'c_submissionChoiceStep__label--disabled': isEsportAutoSubmitting
    }"
  >
    <i class="fas fa-4x fa-gamepad"></i>
    <p class="u_txt">
      The <strong>full series was played</strong>
      {{ generateBestOfMessage(matchReport.totalMatches) }}
    </p>
    <small
      class="c_submissionChoiceStep__disabledMessage"
      *ngIf="isEsportAutoSubmitting"
    >
      League of Legends games cannot be self-reported. Results are automatically
      updated by Riot every night after midnight.
    </small>
  </label>

  <input
    [formControl]="submissionChoiceControl"
    [value]="submissionTypes.FORFEIT"
    type="radio"
    id="reportingForfeit"
    class="c_submissionChoiceStep__radioInput"
  />
  <label
    class="c_submissionChoiceStep__label c_submissionChoiceStep__label--forfeit"
    for="reportingForfeit"
    [ngClass]="{
      c_submissionChoiceStep__selectedRadio:
        submissionChoice === submissionTypes.FORFEIT
    }"
  >
    <i class="fas fa-3x fa-flag"></i>
    <p class="u_txt">One of the teams <strong>forfeited</strong></p>
  </label>
</form>

<ng-template #footerButtons>
  <app-modal-stepper-footer-buttons
    [hasNext]="true"
    [hasPrevious]="false"
    [formToCheck]="submissionChoiceControl"
    [errorMessage]="submissionChoiceFormErrorMessage"
  ></app-modal-stepper-footer-buttons>
</ng-template>
