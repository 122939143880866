<div
  class="c_organizationSearchResult"
  [ngClass]="{ 'c_organizationSearchResult--blue': blue }"
  routerLink="{{ searchResult.slug | organizationDetailRouter }}"
>
  <div
    class="
      c_organizationSearchResult__logo c_organizationSearchResult__fixedSize
    "
    *ngIf="searchResult.logoUrl; else defaultLogo"
    [ngStyle]="{ 'background-image': generateImageURL(searchResult.logoUrl) }"
  ></div>
  <ng-template #defaultLogo>
    <div class="c_organizationSearchResult__fixedSize">
      <app-logo-svg-white
        [isCircle]="true"
        [hasBackgroundColor]="true"
      ></app-logo-svg-white>
    </div>
  </ng-template>

  <p class="u_txt c_organizationSearchResult__name">
    <strong class="c_organizationSearchResult__label"> Name </strong>
    <br />
    {{ searchResult.name }}
  </p>

  <p class="u_txt c_organizationSearchResult__state">
    <strong class="c_organizationSearchResult__label"> State </strong>
    <br />
    {{ searchResult.state }}
  </p>
</div>
