<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
></app-modal-container>

<ng-template #title>
  <app-modal-title>What are "My Leagues"?</app-modal-title>
</ng-template>

<ng-template #content>
  <p>
    My Leagues are a list of the current and active leagues you are registered
    to play in. Once a league is finished it will no longer show up in your list
    below.
  </p>
</ng-template>
