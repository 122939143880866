<!-- League Details Panel -->
<div
  class="c_leagueDetailsPanel"
  *ngIf="league$ | async as league; else loading"
>
  <div class="c_leagueDetails">
    <app-tabs-block [headerButton]="tabHeaderButton">
      <app-tab tabTitle="Details">
        <p>
          {{
            league.description ||
              "This league does not have a description yet. "
          }}
        </p>
        <p *ngIf="isLeagueFortnite(league.esport)">
          All Fortnite events are for ages 13 and older
        </p>
        <div class="c_leagueDetails__nes_stinger" *ngIf="leagueIsNESLeague">
          <p>
            This league is apart of the
            <strong>2021 National Esports Series</strong>. Qualifying players
            will have the opportunity to play for additional prizing at a
            national level. For more information, please visit
            <a
              href="https://ggleagues.com/page/national-esports-series"
              target="_blank"
              >https://ggleagues.com/page/national-esports-series</a
            >.
          </p>
        </div>
      </app-tab>
      <app-tab tabTitle="Rules">
        <ng-container
          *ngIf="
            getRulesRoute(league.esport, league.leagueType) as rulesLink;
            else defaultRules
          "
        >
          <p>
            You can find our {{ league.esport }} Rulebook for your league here:
          </p>
          <p>
            <a
              class="c_button secondary_dark"
              target="_blank"
              rel="noreferrer"
              [href]="rulesLink"
            >
              <span class="c_leagueDetailsPanel--desktop">
                {{ league.esport }} Rulebook
                {{
                  isLeagueFortnite(league.esport) && !isQuickplay
                    ? "(Battle Royale)"
                    : ""
                }}
                {{
                  isLeagueFortnite(league.esport) && isQuickplay
                    ? "(Elimination)"
                    : ""
                }}
              </span>
              <span class="c_leagueDetailsPanel--mobile">
                Rulebook
                {{
                  isLeagueFortnite(league.esport) && !isQuickplay ? "(BR) " : ""
                }}
                {{
                  isLeagueFortnite(league.esport) && isQuickplay ? "(Elim)" : ""
                }}
              </span>
              <i class="fa fa-external-link"></i>
            </a>
          </p>
          <p *ngIf="isLeagueFortnite(league.esport) && !isQuickplay">
            <a
              class="c_button secondary_dark"
              target="_blank"
              rel="noreferrer"
              [href]="REC_RULES_ROUTES.FORTNITE_ELIM"
            >
              <span class="c_leagueDetailsPanel--desktop">
                {{ league.esport }} Rulebook (Elimination)
              </span>
              <span class="c_leagueDetailsPanel--mobile">
                Rulebook (Elim)
              </span>
              <i class="fa fa-external-link"></i>
            </a>
          </p>
        </ng-container>
        <ng-template #defaultRules>
          <p>
            There is not a default ruleset for this league, please check your
            email for a copy of the rules or reach out to our support team with
            any questions
          </p>
        </ng-template>
        <br />
        <p>
          All players are expected to follow the
          <a href="/rules#player-conduct" target="_blank"
            >GGLeagues Code of Conduct</a
          >
          whenever they are playing in one of our events. You can download a
          copy of the the code of conduct here:
        </p>
        <p>
          <a
            class="c_button secondary_dark"
            target="_blank"
            rel="noreferrer"
            [href]="codeOfConductLink"
          >
            <span class="c_leagueDetailsPanel--desktop">
              GGLeagues Code of Conduct (PDF)
            </span>
            <span class="c_leagueDetailsPanel--mobile"> Player Conduct </span>
            <i class="fas fa-file-download"></i>
          </a>
        </p>
      </app-tab>
    </app-tabs-block>
    <ng-template #tabHeaderButton>
      <button
        *ngIf="userIsLoggedIn$ | async"
        class="c_button secondary_dark c_button--slim"
        (click)="openParticipants()"
      >
        View Participants
      </button>
    </ng-template>
  </div>
</div>
<ng-template #loading>
  <div class="c_leagueDetailsPanel">
    <app-loader></app-loader>
  </div>
</ng-template>
