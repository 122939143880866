import { TitleCasePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

import { Nullish } from "@apptypes/generic-nullish.types";
import { Logger } from "@utils/logger";

@Pipe({
  name: "enumTitle",
})
export class EnumTitlePipe implements PipeTransform {
  private _titlePipe = new TitleCasePipe();

  /**
   * Takes a snake case enum value from the API and transforms it into Title Case. It will
   * return an empty string on error.
   *
   * @param enumString
   */
  public transform(enumString: Nullish<string>): string {
    try {
      if(!enumString){
        return "";
      }

      return this._titlePipe.transform(enumString.split("_").join(" "));
    }
    catch(e) {
      Logger.error(e);
      return "";
    }
  }

}
