import {
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { Observable, of } from "rxjs";
import { Store } from "@ngrx/store";
import {
  map,
  take,
  takeUntil,
} from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";

import { GenericTeamLeagueData } from "src/app/reducers/teams/teams.types";
import { RootState } from "src/app/reducers";
import { GetTeamById } from "src/app/reducers/teams/teams.actions";
import { ComponentRouteParams } from "src/app/enums/routes/routeParams";
import { generateLeagueDetailRoute, generateTeamDetailRoute } from "src/app/enums/routes/routePaths";
import { Unsubscriber } from "src/app/util/unsubscriber";

@Component({
  selector: "app-team-all-leagues",
  templateUrl: "./team-all-leagues.component.html",
  styleUrls: ["./team-all-leagues.component.scss"],
})
export class TeamAllLeaguesComponent implements OnInit, OnDestroy {
  public activeLeagues$: Observable<GenericTeamLeagueData[]>;
  public isPageLoading$: Observable<boolean> = of(true);
  public pastLeagues$: Observable<GenericTeamLeagueData[]>;
  public teamId$: Observable<string>;
  public teamName$: Observable<string>;

  private _unsub = new Unsubscriber();

  constructor(
    private _route: ActivatedRoute,
    private _store: Store<RootState>,
    private _titleService: Title
  ) {
    this.isPageLoading$ = this._store.select("teams", "fetchingId");
    this.teamId$ = this._route.paramMap.pipe(
      take(1),
      map((routeParams) => routeParams.get(ComponentRouteParams.TEAM_ID))
    );
    this.teamName$ = this._store.select("teams", "currentTeam").pipe(
      map((currentTeam) => {
        if (currentTeam) {
          return currentTeam.title;
        }

        return null;
      }),
      takeUntil(this._unsub.unsubEvent)
    );
    this.activeLeagues$ = this._store.select("teams", "currentTeam").pipe(
      map((team) =>
        team.leagues
          .filter((league) => !league.finished)
          .sort((a, b) => {
            if (a.startTime > b.startTime) {
              return -1;
            }

            return 1;
          })
      ),
      takeUntil(this._unsub.unsubEvent)
    );
    this.pastLeagues$ = this._store.select("teams", "currentTeam").pipe(
      map((team) =>
        team.leagues
          .filter((league) => league.finished)
          .sort((a, b) => {
            if (a.startTime > b.startTime) {
              return -1;
            }

            return 1;
          })
      ),
      takeUntil(this._unsub.unsubEvent)
    );
  }

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | Team Details - League List");
    this.teamId$.pipe(take(1)).subscribe((teamId) => {
      this._store.dispatch(new GetTeamById(parseInt(teamId, 10)));
    });
  }

  public ngOnDestroy() {
    this._unsub.kill();
  }

  public getTeamDetailRoute(id: string): string {
    return generateTeamDetailRoute(id);
  }

  public getLeagueDetailRoute(id: number): string {
    return generateLeagueDetailRoute(id.toString());
  }
}
