import { Component, Input } from "@angular/core";

@Component({
  selector: "app-svg-animated-check",
  templateUrl: "./svg-animated-check.component.html",
  styleUrls: ["./svg-animated-check.component.scss"],
})
export class SvgAnimatedCheckComponent {
  @Input() public customWidthValue = 100;

  public get customWidth(): string {
    return `${this.customWidthValue}px;`;
  }
}
