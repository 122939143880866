import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";

import { LeagueSeasonPass } from "@apptypes/season-pass/season-pass.types";

import { RootState } from "src/app/reducers";

@Component({
  selector: "app-league-pass-result",
  templateUrl: "./league-pass-result.component.html",
  styleUrls: ["./league-pass-result.component.scss"],
})
export class LeaguePassResultComponent {
  @Input() public leaguePass!: LeagueSeasonPass;
  public leagueId$: Observable<string | number>;

  constructor(private _store: Store<RootState>) {
    this.leagueId$ = this._store.select("leagues", "league").pipe(
      map(league => league.id),
      first()
    );
  }
}
