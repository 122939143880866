/* eslint-disable max-len */
export enum gamePlatforms {
  LEAGUE_OF_LEGENDS = "League of Legends",
  ROCKET_LEAGUE = "Rocket League",
  OVERWATCH = "Overwatch",
  DOTA_2 = "Dota 2",
  VALORANT = "Valorant",
  FORTNITE = "Fortnite",
  EA_MADDEN = "EA Madden",
  EA_NHL = "EA NHL",
  EA_FIFA = "EA Fifa",
  NBA_2K = "NBA 2k",
  SSBB = "Super Smash Bros Ultimate",
  CALL_OF_DUTY = "Call of Duty",
  MARIO_KART = "Mario Kart",
  OTHER = "Other",
  POKEMON = "Pokemon",
}

export enum apiGamePlatforms {
  OTHER = "other",
  ROCKET_LEAGUE = "rocket_league",
  OVERWATCH = "overwatch",
  VALORANT = "valorant",
  FORTNITE = "fortnite",
  EA_MADDEN = "ea_madden",
  EA_FIFA = "ea_fifa",
  EA_NHL = "ea_nhl",
  LEAGUE_OF_LEGENDS = "league_of_legends",
  SSBB = "super_smash_bros",
  NBA_2K = "nba_2k",
  CALL_OF_DUTY = "call_of_duty",
  MARIO_KART = "mario_kart",
  DOTA_2 = "dota",
  POKEMON = "pokemon",
}

export const gameCredentialTitles: Map<gamePlatforms, string> = new Map<gamePlatforms, string>([
  [gamePlatforms.ROCKET_LEAGUE, "Epic ID"],
  [gamePlatforms.VALORANT, "Riot Account"],
  [gamePlatforms.FORTNITE, "Fortnite In-Game Name"],
  [gamePlatforms.MARIO_KART, "Switch Friend Code"],
  [gamePlatforms.SSBB, "Switch Friend Code"],
  [gamePlatforms.EA_NHL, "PSN/Xbox Live ID"],
  [gamePlatforms.EA_FIFA, "PSN/Xbox Live ID"],
  [gamePlatforms.EA_MADDEN, "PSN/Xbox Live ID"],
  [gamePlatforms.NBA_2K, "PSN/Xbox Live ID"],
  [gamePlatforms.LEAGUE_OF_LEGENDS, "Summoner Name"],
  [gamePlatforms.OVERWATCH, "Battle Net Tag"],
  [gamePlatforms.DOTA_2, "Steam Account Name"],
  [gamePlatforms.CALL_OF_DUTY, "PSN/Xbox Live ID"],
  [gamePlatforms.POKEMON, "Switch Friend Code"],
]);

export const platformToAPI = (platform: gamePlatforms) => {
  switch (platform) {
    case gamePlatforms.DOTA_2:
      return apiGamePlatforms.DOTA_2;
    case gamePlatforms.LEAGUE_OF_LEGENDS:
      return apiGamePlatforms.LEAGUE_OF_LEGENDS;
    case gamePlatforms.FORTNITE:
      return apiGamePlatforms.FORTNITE;
    case gamePlatforms.OVERWATCH:
      return apiGamePlatforms.OVERWATCH;
    case gamePlatforms.ROCKET_LEAGUE:
      return apiGamePlatforms.ROCKET_LEAGUE;
    case gamePlatforms.VALORANT:
      return apiGamePlatforms.VALORANT;
    case gamePlatforms.EA_FIFA:
      return apiGamePlatforms.EA_FIFA;
    case gamePlatforms.EA_MADDEN:
      return apiGamePlatforms.EA_MADDEN;
    case gamePlatforms.EA_NHL:
      return apiGamePlatforms.EA_NHL;
    case gamePlatforms.SSBB:
      return apiGamePlatforms.SSBB;
    case gamePlatforms.NBA_2K:
      return apiGamePlatforms.NBA_2K;
    case gamePlatforms.CALL_OF_DUTY:
      return apiGamePlatforms.CALL_OF_DUTY;
    case gamePlatforms.MARIO_KART:
      return apiGamePlatforms.MARIO_KART;
    case gamePlatforms.POKEMON:
      return apiGamePlatforms.POKEMON;
    case gamePlatforms.OTHER:
    default:
      return apiGamePlatforms.OTHER;
  }
};

export const apiToPlatform = (apiPlatform: apiGamePlatforms) => {
  switch (apiPlatform) {
    case apiGamePlatforms.DOTA_2:
      return gamePlatforms.DOTA_2;
    case apiGamePlatforms.EA_FIFA:
      return gamePlatforms.EA_FIFA;
    case apiGamePlatforms.EA_MADDEN:
      return gamePlatforms.EA_MADDEN;
    case apiGamePlatforms.EA_NHL:
      return gamePlatforms.EA_NHL;
    case apiGamePlatforms.FORTNITE:
      return gamePlatforms.FORTNITE;
    case apiGamePlatforms.LEAGUE_OF_LEGENDS:
      return gamePlatforms.LEAGUE_OF_LEGENDS;
    case apiGamePlatforms.OTHER:
      return gamePlatforms.OTHER;
    case apiGamePlatforms.OVERWATCH:
      return gamePlatforms.OVERWATCH;
    case apiGamePlatforms.ROCKET_LEAGUE:
      return gamePlatforms.ROCKET_LEAGUE;
    case apiGamePlatforms.VALORANT:
      return gamePlatforms.VALORANT;
    case apiGamePlatforms.SSBB:
      return gamePlatforms.SSBB;
    case apiGamePlatforms.NBA_2K:
      return gamePlatforms.NBA_2K;
    case apiGamePlatforms.CALL_OF_DUTY:
      return gamePlatforms.CALL_OF_DUTY;
    case apiGamePlatforms.MARIO_KART:
      return gamePlatforms.MARIO_KART;
    case apiGamePlatforms.POKEMON:
      return gamePlatforms.POKEMON;
    default:
      return gamePlatforms.OTHER;
  }
};

export const gameCredentialsHelpLinks: Map<gamePlatforms, string[]> = new Map<gamePlatforms, string[]>([
  [gamePlatforms.LEAGUE_OF_LEGENDS, ["https://support-leagueoflegends.riotgames.com/hc/en-us/articles/360041788533-Riot-ID-FAQ"]],
  [gamePlatforms.VALORANT, ["https://support-leagueoflegends.riotgames.com/hc/en-us/articles/360041788533-Riot-ID-FAQ"]],
  [gamePlatforms.FORTNITE, ["https://www.epicgames.com/help/en-US/fortnite-c75/epic-accounts-c114/how-to-add-friends-in-fortnite-a6392"]],
  [gamePlatforms.ROCKET_LEAGUE, ["https://support.rocketleague.com/hc/en-us/articles/360053544474-How-do-I-send-and-receive-friend-requests-"]],
  [gamePlatforms.SSBB, ["https://en-americas-support.nintendo.com/app/answers/detail/a_id/22438/~/how-to-view-or-change-your-friend-code"]],
  [gamePlatforms.OVERWATCH, ["https://us.battle.net/support/en/article/75767"]],
  [gamePlatforms.EA_MADDEN, ["https://help.ea.com/en-us/help/madden/madden-nfl-21-mobile/how-to-find-your-madden-nfl-21-mobile-user-id/"]],
  [gamePlatforms.NBA_2K, ["https://dotesports.com/general/news/how-to-play-nba-2k21-online-with-friends"]],
  [gamePlatforms.EA_NHL, ["https://www.playstation.com/en-us/support/account/change-psn-online-id/", "https://support.xbox.com/en-US/help/friends-social-activity/friends-groups/add-friends"]],
]);

export const gameCredentialAddFriendsLinks: Map<gamePlatforms, string[]> = new Map<gamePlatforms, string[]>([
  [gamePlatforms.LEAGUE_OF_LEGENDS, ["https://support-legendsofruneterra.riotgames.com/hc/en-us/articles/360038997293-Managing-Your-Friends-List"]],
  [gamePlatforms.VALORANT, ["https://ag.hyperxgaming.com/article/9925/how-to-add-friends-in-valorant"]],
  [gamePlatforms.ROCKET_LEAGUE, ["https://support.rocketleague.com/hc/en-us/articles/360053544474"]],
  [gamePlatforms.SSBB, ["https://en-americas-support.nintendo.com/app/answers/detail/a_id/22326/~/how-to-add-friends"]],
  [gamePlatforms.EA_FIFA, ["https://support.xbox.com/en-US/help/friends-social-activity/friends-groups/add-friends", "https://www.playstation.com/en-us/support/account/add-friends-psn/"]],
  [gamePlatforms.EA_MADDEN, ["https://support.xbox.com/en-US/help/friends-social-activity/friends-groups/add-friends", "https://www.playstation.com/en-us/support/account/add-friends-psn/"]],
  [gamePlatforms.EA_NHL, ["https://support.xbox.com/en-US/help/friends-social-activity/friends-groups/add-friends", "https://www.playstation.com/en-us/support/account/add-friends-psn/"]],
  [gamePlatforms.CALL_OF_DUTY, ["https://support.xbox.com/en-US/help/friends-social-activity/friends-groups/add-friends", "https://www.playstation.com/en-us/support/account/add-friends-psn/"]],
  [gamePlatforms.NBA_2K, ["https://support.xbox.com/en-US/help/friends-social-activity/friends-groups/add-friends", "https://www.playstation.com/en-us/support/account/add-friends-psn/"]],
  [gamePlatforms.CALL_OF_DUTY, ["https://support.xbox.com/en-US/help/friends-social-activity/friends-groups/add-friends", "https://www.playstation.com/en-us/support/account/add-friends-psn/"]],
  [gamePlatforms.FORTNITE, ["https://www.epicgames.com/help/en-US/fortnite-c75/epic-accounts-c114/how-to-add-friends-in-fortnite-a6392"]],
  [gamePlatforms.DOTA_2, ["https://support.steampowered.com/kb_article.php?ref=1184-UOZV-2743"]],
  [gamePlatforms.OVERWATCH, ["https://worldofwarcraft.com/en-us/news/13645404/chat-with-friends-in-the-battlenet-desktop-app"]],
]);
