<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
></app-modal-container>

<ng-template #title>
  <app-modal-title>What are "My Clubs"?</app-modal-title>
</ng-template>

<ng-template #content>
  <p>Clubs are communities that sponsor or host events on GGLeagues.</p>
  <p>
    The "My Clubs" panel is a list of all clubs that you have either purchased
    an esports pass for, or followed from their clubs page. When you follow a
    club you will get updates when they release new events, games, or have
    announcements.
  </p>
</ng-template>
