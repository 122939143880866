import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-utils";
import { ChatData } from "@apptypes/chat/chat-data.interface";

import { CommentService } from "../comment.abstract.service";
import { RailsTournamentComment } from "../comment.api.types";
import { CommentValidityErrors } from "../comment-validator/comment-validator.types";

/**
 * A comment service to post tournament comments to the rails application
 *
 * @author Christian Tweed
 */
@Injectable({
  providedIn: "any",
})
export class RailsTournamentCommentService extends CommentService {

  public postComment({ railsID: tournamentId }: ChatData, authorId: string, message: string): Observable<unknown> {
    const url = `${environment.apiBase}/api/v2/tournaments/${tournamentId}/tournament_chatrooms/message`;
    const options = {
      headers: basicAuthHeader(),
    };

    const messageError: false | CommentValidityErrors = this._commentValidator.isMessageInvalid(message);

    if (messageError) {
      this._logMessageError(messageError);
      return of(null);
    }

    const body = this._formatChatMessage(tournamentId, authorId, message);

    return this._http.post(url, body, options);
  }

  protected _formatChatMessage(tournament_id: string, user_id: string, body: string): RailsTournamentComment {
    return {
      message: {
        body,
        tournament_id,
        user_id,
      },
    };
  }

}
