import { Component } from "@angular/core";
import { Router } from "@angular/router";
import {
  BattleRoyaleForniteTeamScore,
  BattleRoyaleFortniteUploadedResults,
  BattleRoyaleStreamGame,
} from "@apptypes/streaming-series.types";
import { Logger } from "@utils/logger";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ModalController } from "../modal-controller/modal-controller.abstract";

export interface BattleRoyaleResultModalData {
  gameInfo: BattleRoyaleStreamGame;
  gameIndex: number;
  teamInfo: [id: string, title: string][];
}

@Component({
  selector: "app-battle-royale-results-modal",
  templateUrl: "./battle-royale-results-modal.component.html",
  styleUrls: ["./battle-royale-results-modal.component.scss"],
})
export class BattleRoyaleResultsModalComponent extends ModalController<BattleRoyaleResultModalData>{
  public static MODAL_NAME = "br_results_modal";
  public gameInfo: BattleRoyaleStreamGame;
  public gameIndex: number;

  private _teamIdList: Map<string, string> = new Map();

  constructor(
    private _modalService: NgxSmartModalService,
    private _router: Router
  ) {
    super(_modalService, BattleRoyaleResultsModalComponent.MODAL_NAME, _router);
    const { gameIndex, gameInfo, teamInfo } = this.getData();
    this.gameIndex = gameIndex;
    this.gameInfo = gameInfo;
    this._teamIdList = new Map(teamInfo);
  }

  public get gameHasFinalScores(): boolean {
    if (this.gameInfo) {
      const scores = this.gameInfo.fortnite_team_scores || [];
      if (scores.length > 0) {
        return true;
      }
    }

    return false;
  }

  public get gameHasUploadedStats(): boolean {
    if (this.gameInfo) {
      const scores = this.gameInfo.uploaded_results || [];
      if (scores.length > 0) {
        return true;
      }
    }

    return false;
  }

  public getTeamName(teamId: number): string {
    const stringId = teamId.toString();

    return this._teamIdList.get(stringId) || "Unknown";
  }

  public get sortedScores(): BattleRoyaleForniteTeamScore[] {
    const scoresCopy = [...this.gameInfo.fortnite_team_scores];
    scoresCopy.sort((a, b) => {
      // Safe parseing, if it fails we'll still display data just unsorted
      const rankA = a.fortnite_team_scores;
      const rankB = b.fortnite_team_scores;

      if (rankA > rankB) {
        return -1;
      } else if (rankA < rankB) {
        return 1;
      } else {
        return 0;
      }
    });

    return scoresCopy;
  }

  public get sortedGameStats(): BattleRoyaleFortniteUploadedResults[] {
    const statsCopy = [...this.gameInfo.uploaded_results];
    statsCopy.sort((a, b) => {
      // Safe parseing, if it fails we'll still display data just unsorted
      try {
        const rankA = parseInt(a.match_placement, 10);
        const rankB = parseInt(b.match_placement, 10);

        if (rankA > rankB) {
          return 1;
        } else {
          return -1;
        }
      } catch (e) {
        Logger.error(e);
        return 0;
      }
    });
    return statsCopy;
  }
}
