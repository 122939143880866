import {
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ISOString } from "@apptypes/date.types";
import { Store } from "@ngrx/store";
import { QueueSession, QueueSessionStatuses } from "@services/v2/leagues/leagues.v2.api.types";
import { isISODateInPast, isoStringToMedDateTime } from "@utils/date-utils";
import { Logger } from "@utils/logger";
import { Unsubscriber } from "@utils/unsubscriber";
import {
  addMinutes,
  isBefore,
  parseISO,
} from "date-fns";
import { interval } from "rxjs";
import {
  map,
  pluck,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";
import { generateLeagueDetailCheckinRoute } from "src/app/enums/routes/routePaths";
import { RootState } from "src/app/reducers";
import { LeagueQueueBracketDetails } from "src/app/reducers/leagues/league.types";

@Component({
  selector: "app-league-checkin-panel",
  templateUrl: "./league-checkin-panel.component.html",
  styleUrls: ["./league-checkin-panel.component.scss"],
})
export class LeagueCheckinPanelComponent implements OnInit, OnDestroy {
  public queueTournament: LeagueQueueBracketDetails;
  public activeSession: QueueSession | null;
  public queueStartTime: string = null;
  public queueCloseTime: string = null;
  public isQueueOpen = false;

  private _leagueId: string = null;
  private _timerUnsub = new Unsubscriber();

  constructor(
    private _store: Store<RootState>
  ) { }

  public ngOnInit(): void {
    this._store.select("leagues", "league").pipe(
      tap((league) => {
        this._leagueId = league.id.toString();
      }),
      pluck("queueBrackets"),
      map((qBrackets: LeagueQueueBracketDetails[] = []) => {
        const selectedQueueTournament = qBrackets[0];
        if (selectedQueueTournament) {
          const sortedQueueSessions = this._sortQueueSessions(selectedQueueTournament.queueSessions);
          return {
            ...selectedQueueTournament,
            queueSessions: sortedQueueSessions,
          };
        } else {
          return null;
        }
      }),
      take(1)
    ).subscribe(
      (selectedBracket) => {
        if (selectedBracket) {
          this.queueTournament = selectedBracket;
          this.activeSession = this._findActiveSession(selectedBracket.queueSessions);
          if (this.activeSession) {
            this.queueStartTime = isoStringToMedDateTime(this.activeSession.startTime);
            this.isQueueOpen = this._checkForQueueOpen();
            if (this.isQueueOpen) {
              this.queueCloseTime = this._generateCloseTime(this.activeSession.startTime);
            } else {
              this._initTimer();
            }
          } else {
            this.isQueueOpen = false;
          }
        }
      }
    );
  }

  public ngOnDestroy(): void {
    this._timerUnsub.kill();
  }

  public get queuePanelHeading(): string {
    if (!this.activeSession) {
      return "All Queues Completed";
    }

    if (this.activeSession.status === QueueSessionStatuses.COMPLETED) {
      return "Queue has Finished!";
    }

    if (this.isQueueOpen) {
      return "Queue is Open!";
    } else {
      return "Queue is Closed";
    }
  }

  public get openQueueMessage(): string {
    if (this.activeSession.status === QueueSessionStatuses.COMPLETED) {
      return "The queue has completed for the week, but you can still check the queue page for matches and to chat with others";
    }

    return "You're able to join the queue for this week's match, click below to get started";
  }

  public get queueLink(): string {
    return `/${generateLeagueDetailCheckinRoute(this._leagueId, this.activeSession.id)}`;
  }

  private _generateCloseTime(startTime: ISOString): string {
    const parsedDate = parseISO(startTime);
    try {
      const STANDARD_QUEUE_LENGTH = 15;
      const updatedDate = addMinutes(parsedDate, STANDARD_QUEUE_LENGTH);
      return isoStringToMedDateTime(updatedDate.toISOString());
    } catch (e) {
      Logger.error(e);
      return "TBD";
    }
  }

  private _sortQueueSessions = (sessions: QueueSession[]): QueueSession[] => {
    const sessionsSorted = [...sessions];
    sessionsSorted.sort((a, b) => {
      if (a.startTime > b.startTime) {
        return 1;
      }

      if (b.startTime > a.startTime) {
        return -1;
      }

      return 0;
    });
    return sessionsSorted;
  };

  private _findActiveSession(queueSessions: QueueSession[] = []): QueueSession {
    const completedQueues = queueSessions.filter(
      (qSession) => qSession.status === QueueSessionStatuses.COMPLETED
    );
    const lastCompletedQueue = completedQueues[(completedQueues.length - 1)];
    const firstPendingSession = queueSessions.find(
      (qSession) => qSession.status !== QueueSessionStatuses.COMPLETED
    );

    if (firstPendingSession) {
      const pendingSessionIsOpen = this._checkForQueueOpen(firstPendingSession);
      if (pendingSessionIsOpen) {
        return firstPendingSession;
      }
    }

    if (lastCompletedQueue) {
      const QUEUE_OPEN_MINUTES_BUFFER = -60;
      const currentTimeWithRecencyBuffer = addMinutes(new Date(), QUEUE_OPEN_MINUTES_BUFFER);
      if (isBefore(currentTimeWithRecencyBuffer, parseISO(lastCompletedQueue.startTime))) {
        return lastCompletedQueue;
      }
    }

    return firstPendingSession;
  }

  private _initTimer(): void {
    const ONE_MINUTE_IN_MS = 60000;
    interval(ONE_MINUTE_IN_MS).pipe(
      takeUntil(this._timerUnsub.unsubEvent)
    ).subscribe(
      () => {
        console.log("hello from initTimer");
        this.isQueueOpen = this._checkForQueueOpen();
        if (this.isQueueOpen) {
          this.queueCloseTime = this._generateCloseTime(this.activeSession.startTime);
          this._timerUnsub.kill();
        }
      }
    );
  }

  private _checkForQueueOpen(queueToCheck?: QueueSession): boolean {
    const activeSession = queueToCheck || this.activeSession;
    try {
      const MINUTES_BEFORE_QUEUE = -10;
      const startTimeWithTenMinBuffer = addMinutes(parseISO(activeSession.startTime), MINUTES_BEFORE_QUEUE).toISOString();
      return isISODateInPast(startTimeWithTenMinBuffer);
    } catch (e) {
      Logger.error(e);
      return false;
    }
  }
}
