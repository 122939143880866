<a
  routerLink="/{{ getMatchDetailRoute(match.id) }}"
  class="c_smallMatchBlock"
  *ngIf="match"
>
  <div class="c_smallMatchBlock__date">
    <span class="u_txt">{{ processDate(match.date) }}</span>
  </div>
  <div
    class="c_smallMatchBlock__team c_smallMatchBlock__team--left"
    [ngClass]="{
      'c_smallMatchBlock__team--loser': match.winner === teamTwo?.name
    }"
  >
    <div class="c_smallMatchBlock__team__details">
      <strong class="c_smallMatchBlock__team__details__name">
        {{ teamOne?.name }}
      </strong>
      <span class="c_smallMatchBlock__team__details__record">
        {{ teamOne?.wins }}W - {{ teamOne?.losses }}L
      </span>
    </div>
    <div
      [ngStyle]="{ 'background-image': teamOneLogo }"
      class="c_smallMatchBlock__team__image"
    >
      <i class="fas fa-users teamOne" *ngIf="!teamOneLogo"></i>
    </div>
  </div>
  <div class="c_smallMatchBlock__vs" *ngIf="!match.winner">vs</div>
  <div
    class="c_smallMatchBlock__vs"
    *ngIf="match.winner && match.winner === teamOne?.name"
  >
    <strong>{{ teamOne?.score }}</strong
    >-<span class="c_smallMatchBlock__vs--loser">{{ teamTwo?.score }}</span>
  </div>
  <div
    class="c_smallMatchBlock__vs"
    *ngIf="match.winner && match.winner === teamTwo?.name"
  >
    <span class="c_smallMatchBlock__vs--loser">{{ teamOne?.score }}</span
    >-<strong>{{ teamTwo?.score }}</strong>
  </div>
  <div
    class="c_smallMatchBlock__team c_smallMatchBlock__team--right"
    [ngClass]="{
      'c_smallMatchBlock__team--loser': match.winner === teamOne?.name
    }"
  >
    <div
      [ngStyle]="{ 'background-image': teamTwoLogo }"
      class="c_smallMatchBlock__team__image"
    >
      <i class="fas fa-users" *ngIf="!teamTwoLogo"></i>
    </div>
    <div class="c_smallMatchBlock__team__details">
      <strong class="c_smallMatchBlock__team__details__name">
        {{ teamTwo?.name }}
      </strong>
      <span class="c_smallMatchBlock__team__details__record">
        {{ teamTwo?.wins }}W - {{ teamTwo?.losses }}L
      </span>
    </div>
  </div>
</a>
