<ng-container *ngIf="leagueTournaments$ | async as tournaments">
  <section [ngSwitch]="tournamentType$ | async" class="c_bracketMatchBlock">
    <h5 class="u_txt--hdg5">
      {{ useStreamingBracketData ? "Live Matches" : "League Matches" }}
    </h5>
    <small *ngIf="useStreamingBracketData"
      >Last Updated:
      {{
        ((filteredBracket$ | async)?.lastUpdated | date: "medium") || "N/A"
      }}</small
    >
    <p
      *ngIf="(quickPlayStatusUpdate$ | async) && (isBracketBeingBuilt$ | async)"
      class="c_bracketMatchBlock__quickPlayStatusUpdate u_txt--hdg5"
    >
      The bracket is currently being built. This may take several minutes.
    </p>
    <form
      [formGroup]="matchForm"
      class="c_bracketMatchBlock__form"
      *ngIf="tournaments.length > 0 && (!useStreamingBracketData || isFortnite)"
    >
      <label for="phase"> League Phase (Group/Playoff) </label>
      <select
        id="phase"
        formControlName="selectedTournament"
        class="c_bracketMatchBlock__dropdown"
      >
        <option *ngFor="let tournament of tournaments" [ngValue]="tournament">
          {{ tournament.title }}
        </option>
      </select>
    </form>
    <!-- Battle Royale Matches -->
    <ng-container *ngSwitchCase="tournamentTypes.BATTLE_ROYALE">
      <div
        class="c_bracketMatchBlock__gameInfo"
        *ngIf="filteredBracket$ | async as bracket"
      >
        <app-battle-royale-matches-panel
          [bracket]="bracket"
        ></app-battle-royale-matches-panel>
      </div>
    </ng-container>
    <!-- Round Robin Matches -->
    <ng-container *ngSwitchCase="tournamentTypes.ROUND_ROBIN">
      <div
        class="c_bracketMatchBlock__gameInfo"
        *ngIf="filteredBracket$ | async as bracket"
      >
        <app-matches-panel [bracket]="bracket"></app-matches-panel>
      </div>
    </ng-container>
    <!-- Elimination Bracket -->
    <ng-container *ngSwitchCase="tournamentTypes.ELIMINATION">
      <ng-container *ngIf="filteredBracket$ | async as bracket">
        <div
          class="c_bracketMatchBlock__gameInfo"
          [ngClass]="{
            'c_bracketMatchBlock__gameInfo--update': animateUpdate$ | async
          }"
          *ngIf="(isBracketBeingBuilt$ | async) === false; else loader"
        >
          <app-bracket-controller
            [tournament]="bracket"
            [teamList]="leagueTeams$ | async"
            [useStream]="true"
          >
          </app-bracket-controller>
        </div>
        <ng-template #loader>
          <app-loader
            >Loading Bracket. This may take several minutes.</app-loader
          >
        </ng-template>
      </ng-container>
    </ng-container>
    <!-- No Tournament -->
    <ng-container *ngSwitchDefault>
      <div class="c_bracketMatchBlock__noTournaments">
        <h5 class="u_txt--hdg5">
          No games have been scheduled for this league yet
        </h5>
      </div>
    </ng-container>
  </section>
</ng-container>
