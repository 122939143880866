<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
  [modalFooter]="footer"
></app-modal-container>

<ng-template #title>
  <app-modal-title>Remove Player?</app-modal-title>
</ng-template>

<ng-template #content>
  <div class="c_removePlayerModal">
    <p *ngIf="loaded">
      Are you sure you want to remove {{ getData().inGameName }} from your team?
    </p>
  </div>
</ng-template>

<ng-template #footer>
  <app-modal-footer-buttons>
    <button class="c_button secondary_dark" (click)="close()">Cancel</button>
    <button class="c_button primary_dark" (click)="removePlayer()">
      Confirm
    </button>
  </app-modal-footer-buttons>
</ng-template>
