<div class="c_dashOrgPanel">
  <header class="c_dashOrgPanel__header">
    <h3 class="c_dashOrgPanel__title u_txt--hdg3">My Clubs</h3>
    <app-info-modal-button
      [modalId]="dashInfoModal.modalId"
      [modalComponent]="dashInfoModal"
    ></app-info-modal-button>
    <a [routerLink]="orgSearchRoute" class="c_button secondary_dark"
      >Go to Club Search</a
    >
  </header>
  <div class="c_dashOrgPanel__organiztions">
    <ng-container *ngIf="(userClubList$ | async).length > 0; else noOrgs">
      <app-organization-search-result
        *ngFor="let org of userClubList$ | async"
        [searchResult]="org"
        [blue]="true"
      ></app-organization-search-result>
    </ng-container>
    <ng-template #noOrgs>
      <p class="c_dashOrgPanel__errorMessage">You don't have any clubs yet.</p>
    </ng-template>
  </div>
</div>
