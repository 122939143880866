import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ModalController } from "../modal-controller/modal-controller.abstract";

@Component({
  selector: "app-dash-club-info-modal",
  templateUrl: "./dash-club-info-modal.component.html",
  styleUrls: ["./dash-club-info-modal.component.scss"],
})
export class DashClubInfoModalComponent extends ModalController<null> {
  public static modalId = "dashOrgInfoModal";

  constructor(private _modalService: NgxSmartModalService, private _router: Router) {
    super(_modalService, DashClubInfoModalComponent.modalId, _router);
  }
}
