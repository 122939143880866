<app-modal-container
  *ngIf="passPurchaseInfo"
  [modalTitle]="title"
  [modalContent]="content"
  [modalFooter]="footer"
>
</app-modal-container>

<ng-template #content>
  <ng-container *ngIf="!collectAddressStep">
    <ng-container *ngIf="passPurchaseInfo">
      <div class="c_passPurchaseModal__headingData">
        <h4 class="u_txt--hdg4">{{ passPurchaseInfo.title }}</h4>
        <h3 class="u_txt--hdg3">
          ${{ passPurchaseInfo.price }} <span>(incl. tax)</span>
        </h3>
      </div>

      <p class="u_txt">
        Purchasing this pass will activate it on your account ({{
          username$ | async
        }}) immediately, and allow you to join
        <strong>
          <app-season-pass-league-count-context
            [seasonPass]="passPurchaseInfo"
          ></app-season-pass-league-count-context>
        </strong>
        hosted by {{ passPurchaseInfo.organizationName }}
        this season at no additional cost.
      </p>
      <p class="u_txt">
        After completing purchase you will be redirected back to the league you
        were viewing and be all set to join!
      </p>
    </ng-container>
    <ng-container *ngIf="showTallmadgeCopy">
      <blockquote>
        By clicking through to the next landing page and in consideration of
        your participation, you hereby acknowledge and consent to all assumption
        of risk, waivers of liability and indemnity that are applied in the
        Terms and Conditions and Privacy Policy to GGLeagues also applying to
        the City of Tallmadge and do hereby expressly release and hold harmless
        the City of Tallmadge, it officers, employees, agents and assigns from
        any and all liability, claims, costs, expenses and/or damages associated
        with such participation.
      </blockquote>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="collectAddressStep">
    <p>
      This pass comes with a bonus gift for all who activate it! If you would
      like to claim this gift please provide a shipping address below
    </p>
    <div *ngIf="!userIsSkippingAddress">
      <h4 class="u_txt--hdg4">Shipping Information</h4>
      <ng-container>
        <form [formGroup]="shippingForm">
          <label class="c_inputLabel" for="firstName">
            Legal First Name *
          </label>
          <input
            class="c_input"
            type="text"
            placeholder="Legal First Name"
            formControlName="firstName"
            id="firstName"
          />
          <label class="c_inputLabel" for="lastName"> Legal Last Name * </label>
          <input
            class="c_input"
            type="text"
            placeholder="Legal Last Name"
            formControlName="lastName"
            id="lastName"
          />
          <label class="c_inputLabel" for="addressOne"> Address 1 *</label>
          <input
            class="c_input"
            type="text"
            placeholder="Address 1"
            formControlName="addressOne"
            id="addressOne"
          />
          <label class="c_inputLabel" for="addressTwo"> Address 2</label>
          <input
            class="c_input"
            type="text"
            placeholder="Address 2"
            formControlName="addressTwo"
            id="addressTwo"
          />
          <div class="c_passPurchaseModal__shippingForm__zipCityStateBlock">
            <div
              class="c_passPurchaseModal__shippingForm__zipCityStateBlock__item"
            >
              <label class="c_inputLabel" for="city"> City </label>
              <input
                class="c_input"
                type="text"
                placeholder="City"
                formControlName="city"
                id="city"
              />
            </div>
            <div
              class="c_passPurchaseModal__shippingForm__zipCityStateBlock__item"
            >
              <label class="c_inputLabel" for="state"> State </label>
              <select
                class="c_input"
                type="text"
                placeholder="State"
                formControlName="state"
                id="state"
              >
                <option disabled selected value="">State</option>
                <option
                  *ngFor="let state of stateList"
                  [ngValue]="statesAbbreviations[state]"
                >
                  {{ state }}
                </option>
              </select>
            </div>
            <div
              class="c_passPurchaseModal__shippingForm__zipCityStateBlock__item"
            >
              <label class="c_inputLabel" for="zipCode"> Zipcode </label>
              <input
                class="c_input"
                type="text"
                placeholder="Zipcode"
                formControlName="zipCode"
                id="zipCode"
              />
            </div>
          </div>
        </form>
        <p class="c_winnerModal__formError u_txt--warn" *ngIf="!!formError">
          {{ formError }}
        </p>
        <hr />
      </ng-container>
    </div>
    <div>
      <label>
        <input type="checkbox" [(ngModel)]="userIsSkippingAddress" />
        I don't want to provide my address, and will skip the free gift
      </label>
    </div>
    <div
      class="c_passPurchaseModal__noShipWarning"
      *ngIf="userIsSkippingAddress"
    >
      <div class="c_passPurchaseModal__noShipWarning__icon">
        <i class="fas fa-exclamation-circle"></i>
      </div>
      <p>
        You're electing to skip providing an address, and will not receive a
        gift with this pass
      </p>
    </div>
  </ng-container>
</ng-template>

<ng-template #title>
  <app-modal-title>
    {{ passPurchaseInfo.title }}
  </app-modal-title>
</ng-template>

<ng-template #footer>
  <app-modal-footer-buttons>
    <button
      *ngIf="!collectAddressStep && passHasSwag"
      class="c_button primary_dark c_passPurchaseModal__submitButton"
      (click)="goToAddressStep()"
    >
      <ng-container *ngIf="loadingData">
        <i class="fa fa-circle-notch fa-spin"></i>
      </ng-container>
      <ng-container *ngIf="!loadingData"> Next </ng-container>
    </button>
    <button
      *ngIf="collectAddressStep || !passHasSwag"
      class="c_button primary_dark c_passPurchaseModal__submitButton"
      (click)="purchasePass(passPurchaseInfo.id)"
    >
      Purchase Pass
    </button>
  </app-modal-footer-buttons>
</ng-template>
