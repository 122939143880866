<div class="c_joinLeagueBtnContainer">
  <button
    class="c_button primary_dark"
    (click)="openDialogue()"
    [matTooltip]="toolTipMessage"
    *ngIf="
      !isLoading &&
      !isLeagueFull &&
      !isLeagueRegistrationClosed &&
      !userInLeague &&
      !leagueHasError &&
      !userNeedsSeasonPass
    "
  >
    Join League
  </button>
  <div
    [ngClass]="{
      c_joinLeagueBtnExplainerText: !useGetPassButton,
      'c_joinLeagueBtnExplainerText--success': userInLeague,
      c_joinLeagueBtn__getPass: useGetPassButton
    }"
    *ngIf="
      isLeagueFull ||
      isLeagueRegistrationClosed ||
      userInLeague ||
      leagueHasError ||
      userNeedsSeasonPass
    "
  >
    <a routerLink="passes" class="c_button" *ngIf="useGetPassButton">
      Get a Pass!&nbsp;&nbsp;<i class="fas fa-external-link-alt"></i
    ></a>
    <ng-container *ngIf="!useGetPassButton">
      <ng-container *ngIf="userNeedsSeasonPass; else noLinkTemplate">
        <small>
          <a routerLink="passes">
            {{ cantJoinLeagueCopy }} <i class="fas fa-external-link-alt"></i>
          </a>
        </small>
      </ng-container>
      <ng-template #noLinkTemplate>
        <ng-container
          *ngIf="
            (isLeagueFull || isLeagueRegistrationClosed) && !userInLeague;
            else generic
          "
        >
          <small>
            <a (click)="openSupportModal()">
              {{ cantJoinLeagueCopy }}
              <br />
              Contact Support <i class="fas fa-external-link-alt"></i>
            </a>
          </small>
        </ng-container>
        <ng-template #generic>
          <small>
            {{ cantJoinLeagueCopy }}
          </small>
        </ng-template>
      </ng-template>
    </ng-container>
  </div>
</div>
