export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const days = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
];

const CURRENT_YEAR = new Date().getUTCFullYear();
export const years = Array(CURRENT_YEAR - (CURRENT_YEAR - 100))
  .fill("")
  .map((v, idx) => CURRENT_YEAR - idx);
