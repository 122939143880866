import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import {
  first,
  map,
  pluck,
  switchMap,
} from "rxjs/operators";

import { SeasonPass } from "@apptypes/season-pass/season-pass.types";

import { RegistrationTypes } from "src/app/enums/registration-types.enum";
import { ModalController } from "../modal-controller/modal-controller.abstract";
import { RootState } from "src/app/reducers";
import { SeasonPassService } from "@services/v2/season-pass/season-pass.service";
import { Logger } from "@utils/logger";
import {
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { StatesAbbreviations } from "src/app/enums/states.enum";
import { UserAddress, UserAddressService } from "@services/v2/user-address/user-address.service";
import { SessionStorageKeys } from "src/app/enums/session-storage-keys.enum";

interface PassPurchaseModalData {
  seasonPass: SeasonPass;
  leagueContext?: string;
  orgContext?: string;
  isTallmadge: boolean;
  navigateToDashboard?: boolean;
}

interface ShippingFormValues {
  firstName: string;
  lastName: string;
  addressOne: string;
  addressTwo: string;
  city: string;
  state: string;
  zipCode: string;
}

@Component({
  selector: "app-pass-purchase-modal",
  templateUrl: "./pass-purchase-modal.component.html",
  styleUrls: ["./pass-purchase-modal.component.scss"],
})
export class PassPurchaseModalComponent extends ModalController<PassPurchaseModalData> implements OnInit {
  public static modalID = "passPurchase";
  public registrationTypes = RegistrationTypes;
  public username$: Observable<string>;
  public collectAddressStep = false;
  public loadingData = true;

  public useExisitingAddress = false;
  public userIsSkippingAddress = false;

  public formError: null | string = null;
  public stateList = Object.keys(StatesAbbreviations);
  public statesAbbreviations = StatesAbbreviations;

  public shippingForm = this._fb.group({
    firstName: new FormControl("", [Validators.required]),
    lastName: new FormControl("", [Validators.required]),
    addressOne: new FormControl("", [Validators.required]),
    addressTwo: new FormControl(""),
    city: new FormControl("", [Validators.required]),
    state: new FormControl("", [Validators.required]),
    zipCode: new FormControl("", [Validators.required]),
  });

  private _userId: string | number;
  private _userCurrentAddress: UserAddress | null = null;


  constructor(
    private _modalService: NgxSmartModalService,
    private _router: Router,
    private _store: Store<RootState>,
    private _seasonPassService: SeasonPassService,
    private _userAddressService: UserAddressService,
    private _fb: FormBuilder
  ) {
    super(_modalService, PassPurchaseModalComponent.modalID, _router);
    this.username$ = this._store.select("user", "currentUser").pipe(
      map(user => user.inGameName),
      first()
    );
  }

  public ngOnInit(): void {
    this._store.select("user", "currentUser").pipe(
      pluck("id"),
      first()
    ).subscribe(
      async (userId) => {
        this._userId = userId;
        this._userCurrentAddress = await this._userAddressService.getUserAddress(this._userId).toPromise();
        this.loadingData = false;
        if (this._userCurrentAddress) {
          this.shippingForm.setValue({
            firstName: this._userCurrentAddress.first_name,
            lastName: this._userCurrentAddress.last_name,
            addressOne: this._userCurrentAddress.address_first,
            addressTwo: this._userCurrentAddress.address_second,
            city: this._userCurrentAddress.city,
            state: this._userCurrentAddress.state,
            zipCode: this._userCurrentAddress.zip_code,
          });
        }
      }
    );
  }

  public get showTallmadgeCopy(): boolean {
    return this.getData()?.isTallmadge;
  }

  public get passPurchaseInfo() {
    return this.getData()?.seasonPass;
  }

  public get passHasSwag(): boolean {
    return this.getData()?.seasonPass?.hasSwag;
  }

  public goToAddressStep(): void {
    this.collectAddressStep = true;
  }

  public purchasePass(passId: string) {
    const generateAddressUpdater = (id, address) => {
      if (address) {
        return this._userAddressService.updateUserAddress(id, formattedUserAddress);
      }

      return of(null);
    };
    let formattedUserAddress: UserAddress | null = null;
    if (this.passHasSwag) {
      if (!this.userIsSkippingAddress && !this.shippingForm.valid) {
        this.shippingForm.markAllAsTouched();
        this.formError = "Please correct the form errors to continue";
        return;
      }


      const userFormAddress = this.shippingForm.value as ShippingFormValues;
      formattedUserAddress = {
        address_first: userFormAddress.addressOne,
        address_second: userFormAddress.addressTwo || undefined,
        address_type: "shipping",
        city: userFormAddress.city,
        country: "USA",
        first_name: userFormAddress.firstName,
        last_name: userFormAddress.lastName,
        state: userFormAddress.state,
        zip_code: userFormAddress.zipCode,
      };

      if (this._userCurrentAddress) {
        formattedUserAddress.id = this._userCurrentAddress.id;
      }

    }

    this._store.select("user", "currentUser").pipe(
      pluck("id"),
      first(),
      switchMap((id) =>
        (!this.userIsSkippingAddress && this.passHasSwag) ?
          generateAddressUpdater(id, formattedUserAddress) :
          of(null)
      )
    ).subscribe(
      // SUCCESS
      () => {
        const leagueId = this.getData().leagueContext ?? null;
        const orgId = this.getData().orgContext ?? null;
        const navigateToDashboard = this.getData().navigateToDashboard ?? null;
        // Don't process exit analytics on a checkout redirect
        localStorage.setItem(SessionStorageKeys.IGNORE_EXIT_ANALYTIC, "true");
        this._seasonPassService.getPassCheckoutPage(passId, orgId, leagueId, navigateToDashboard).subscribe(
          (res) => {
            if (res !== null) {
              window.location.href = res;
            } else {
              window.alert("Error trying to connect to payment service, please try again");
            }
          },
          (err) => {
            Logger.error(err);
            window.alert("Error trying to connect to payment service, please try again");

          }
        );
      },
      // ERROR
      () => {
        this.formError = "Error saving your address, if this continues please contact support";
      }
    );

  }
}
