import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";

import { ModalController } from "../../modal-controller/modal-controller.abstract";

@Component({
  selector: "app-lobby-help-modal-frame",
  templateUrl: "./lobby-help-modal-frame.component.html",
  styleUrls: ["./lobby-help-modal-frame.component.scss"],
})
export class LobbyHelpModalFrameComponent extends ModalController<null> {
  @Input() public modalTitle = "How to Create a Lobby";

  constructor(private _modalService: NgxSmartModalService, private _router: Router) {
    super(_modalService, "esportHelp", _router);
  }
}
