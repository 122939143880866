/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { LeagueState } from "./league.types";
import { LeagueActions, LeagueActionTypes } from "./league.actions";

const initialState: LeagueState = {
  league: null,
  leagueEnrollmentStatus: null,
  fetchingLeagueById: false,
  fetchingLeagueByIdError: false,
  quickPlayEvent: null,
  fetchingQuickPlayEvent: false,
  fetchingQuickPlayEventError: false,
  fetchingLeagueBrackets: false,
  fetchingLeagueBracketsError: false,
};

export function leagueReducer(
  state = initialState,
  action: LeagueActions
): LeagueState {
  switch (action.type) {
    case LeagueActionTypes.GET_LEAGUE_BY_ID:
      return {
        ...state,
        fetchingLeagueById: true,
        fetchingLeagueByIdError: false,
        league: null,
      };
    case LeagueActionTypes.GET_LEAGUE_BY_ID_ERROR:
      return {
        ...state,
        fetchingLeagueById: false,
        fetchingLeagueByIdError: true,
        league: null,
      };
    case LeagueActionTypes.GET_LEAGUE_BY_ID_SUCCESS:
      return {
        ...state,
        fetchingLeagueById: false,
        fetchingLeagueByIdError: false,
        league: action.payload,
      };
    case LeagueActionTypes.UPDATE_LEAGUE_BRACKET_DATA:
      return {
        ...state,
        fetchingLeagueBrackets: false,
        fetchingLeagueBracketsError: false,
        league: {
          ...state.league,
          brackets: action.payload,
        },
      };
    case LeagueActionTypes.UPDATE_LEAGUE_TEAM_DATA:
      return {
        ...state,
        league: {
          ...state.league,
          teams: action.payload,
        },
      };
    case LeagueActionTypes.UPDATE_LEAGUE_ENROLLMENT_DATA:
      return {
        ...state,
        leagueEnrollmentStatus: action.payload,
      };
    case LeagueActionTypes.GET_QUICK_PLAY_EVENT_BY_LEAGUE_ID:
      return {
        ...state,
        fetchingQuickPlayEvent: true,
        fetchingLeagueByIdError: false,
      };
    case LeagueActionTypes.GET_QUICK_PLAY_EVENT_BY_ID_LEAGUE_ERROR:
      return {
        ...state,
        fetchingQuickPlayEvent: false,
        fetchingQuickPlayEventError: true,
      };
    case LeagueActionTypes.GET_QUICK_PLAY_EVENT_BY_ID_LEAGUE_SUCCESS:
      return {
        ...state,
        fetchingQuickPlayEvent: false,
        fetchingLeagueByIdError: false,
        quickPlayEvent: action.payload,
      };
    case LeagueActionTypes.GET_LEAGUE_BRACKETS:
      return {
        ...state,
        fetchingLeagueBrackets: true,
        fetchingLeagueBracketsError: false,
      };
    case LeagueActionTypes.GET_LEAGUE_BRACKETS_ERROR:
      return {
        ...state,
        fetchingLeagueBrackets: false,
        fetchingLeagueBracketsError: true,
      };
    case LeagueActionTypes.CLEAR_QUICK_PLAY_EVENT:
      return {
        ...state,
        quickPlayEvent: null,
      };
    default:
      return state;
  }
}
