/* eslint-disable @typescript-eslint/no-unused-vars */
import { Logger } from "@utils/logger";
import { SessionStorageKeys } from "src/app/enums/session-storage-keys.enum";

// Refernce to Google Analytics
// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

const registerRedditLeagueRegisterEvent = (leagueId: string, redditRef: string): void => {
  try {
    gtag("event", "league_registration_reddit_referral", {
      event_category: "league_registration",
      event_label: `league:${leagueId}|ref:${redditRef}`,
      value: leagueId,
    });
    Logger.log("Sent league_registration_reddit_referral to analtyics");
    Logger.log({
      event_category: "league_registration",
      event_label: `league:${leagueId}|ref:${redditRef}`,
      value: leagueId,
    });
  } catch (e) {
    Logger.error("league_registration_reddit_referral event failed to fire");
  }
};

const registerGoogleLeagueRegisterEvent = (leagueId: string, googleRef: string): void => {
  try {
    gtag("event", "league_registration_google_referral", {
      event_category: "league_registration",
      event_label: `league:${leagueId}|ref:${googleRef}`,
      value: leagueId,
    });
    Logger.log("Sent league_registration_google_referral to analtyics");
    Logger.log({
      event_category: "league_registration",
      event_label: `league:${leagueId}|ref:${googleRef}`,
      value: leagueId,
    });
  } catch (e) {
    Logger.error("league_registration_google_referral event failed to fire");
  }
};

const registerFBLeagueRegisterEvent = (leagueId: string, fbRef: string): void => {
  try {
    gtag("event", "league_registration_fb_referral", {
      event_category: "league_registration",
      event_label: `league:${leagueId}|ref:${fbRef}`,
      value: leagueId,
    });
    Logger.log("Sent league_registration_fb_referral to analtyics");
    Logger.log({
      event_category: "league_registration",
      event_label: `league:${leagueId}|ref:${fbRef}`,
      value: leagueId,
    });
  } catch (e) {
    Logger.error("league_registration_twitter_referral event failed to fire");
  }
};


const registerTwitterLeagueRegisterEvent = (leagueId: string, twitterRef: string): void => {
  try {
    gtag("event", "league_registration_twitter_referral", {
      event_category: "league_registration",
      event_label: `league:${leagueId}|ref:${twitterRef}`,
      value: leagueId,
    });
    Logger.log("Sent league_registration_twitter_referral to analtyics");
    Logger.log({
      event_category: "league_registration",
      event_label: `league:${leagueId}|ref:${twitterRef}`,
      value: leagueId,
    });
  } catch (e) {
    Logger.error("league_registration_twitter_referral event failed to fire");
  }
};

const registerLeagueOrganicRef = (leagueId: string): void => {
  try {
    gtag("event", "league_registration_organic", {
      event_category: "league_registration",
      event_label: `league:${leagueId}|ref:organic`,
      value: leagueId,
    });
    Logger.log("Sent league_registration_organic to analtyics");
    Logger.log({
      event_category: "league_registration",
      event_label: `league:${leagueId}|ref:organic}`,
      value: leagueId,
    });
  } catch (e) {
    Logger.error("league_registration_reddit_referral event failed to fire");
  }
};

const registerLeagueWeeklyRef = (leagueId: string): void => {
  try {
    gtag("event", "weekly_registration_organic", {
      event_category: "weekly_league_registration",
      event_label: `league:${leagueId}|ref:weekly_organic`,
      value: leagueId,
    });
    Logger.log("Sent weekly_registration_organic to analtyics");
    Logger.log({
      event_category: "weekly_league_registration",
      event_label: `league:${leagueId}|ref:weekly_organic}`,
      value: leagueId,
    });
  } catch (e) {
    Logger.error("weekly_registration_organic event failed to fire");
  }
};

export const triggerLeagueRegistrationAnalyticsEvent = (leagueId: string, isWeekly = false): void => {
  try {
    gtag("event", "conversion", {
      send_to: "AW-345413332/eUBFCOXRg_MCENSt2qQB",
    });
  } catch (e) {
    Logger.error(e);
  }

  if (isWeekly) {
    registerLeagueWeeklyRef(leagueId);
  } else {
    registerLeagueOrganicRef(leagueId);
  }

  // const rid = localStorage.getItem(SessionStorageKeys.REDDIT_CAMPAIGN_REF);
  // const gclid = localStorage.getItem(SessionStorageKeys.GOOGLE_CAMPAIGN_REF);
  // const fbclid = localStorage.getItem(SessionStorageKeys.FB_CAMPAIGN_REF);
  // const twtr = localStorage.getItem(SessionStorageKeys.TWITTER_CAMPAIGN_REF);
  // if (fbclid) {
  //   registerFBLeagueRegisterEvent(leagueId, fbclid);
  // } else if (gclid) {
  //   registerGoogleLeagueRegisterEvent(leagueId, gclid);
  // } else if (rid) {
  //   registerRedditLeagueRegisterEvent(leagueId, rid);
  // } else if (twtr) {
  //   registerTwitterLeagueRegisterEvent(leagueId, twtr);
  // } else {
  //   registerLeagueOrganicRef(leagueId);
  // }
};
