<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
></app-modal-container>

<ng-template #title>
  <app-modal-title>Battle Royale Series Results</app-modal-title>
</ng-template>

<ng-template #content>
  <div class="c_winnerContainer">
    <ng-container *ngIf="seriesIsComplete; else progressDisplay">
      <h5 class="u_txt--hdg5">
        Winner: <strong>{{ seriesWinner }}</strong>
      </h5>
      <small *ngIf="seriesWinner === 'TBD'">
        Winner will be determined after all the games are reported and scored
      </small>
    </ng-container>
    <ng-template #progressDisplay>
      <h5 class="u_txt--hdg5">
        Series In-Progress:
        <strong
          >{{ completeGamesCount }}/{{ brGamesList.length }} games
          played</strong
        >
      </h5>
      <small>
        A winner will not be declared until all games are completed and scored.
      </small>
    </ng-template>
  </div>
  <ng-container *ngIf="aggregateScoresList.length > 0; else noScore">
    <div class="c_resultsTable">
      <table>
        <thead>
          <tr>
            <th>
              <span> Team Name</span>
            </th>
            <th><span>Total Score</span></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let aggregateScore of aggregateScoresList">
            <td>{{ getTeamName(aggregateScore.team_id) }}</td>
            <td>{{ aggregateScore.fortnite_team_scores }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <small>
      Total score is calculated by adding the final scores from each game. In
      instances of a tie for 1st place, the winner will be decided by the
      <a [routerLink]="rulesUri" target="_blank">tiebreaker rules</a>
    </small>
  </ng-container>
  <ng-template #noScore>
    No game scores have been reported yet. This modal will update as soon as one
    game is scored.
  </ng-template>
</ng-template>
