/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { AbstractControl } from "@angular/forms";

export function passwordUppercase(control: AbstractControl) {
  const controlValue: string = control.value;
  if (!controlValue) {
    return {
      passwordUppercase: true,
    };
  }

  const uppercaseRegex = /[A-Z]/;
  const regexMatch = controlValue.match(uppercaseRegex);
  if (!regexMatch) {
    return {
      passwordUppercase: true,
    };
  }
  return null;
}

export function passwordLowercase(control: AbstractControl) {
  const controlValue: string = control.value;
  if (!controlValue) {
    return {
      passwordLowercase: true,
    };
  }

  const lowercaseRegex = /[a-z]/;
  const regexMatch = controlValue.match(lowercaseRegex);
  if (!regexMatch) {
    return {
      passwordLowercase: true,
    };
  }
  return null;
}

export function passwordNumber(control: AbstractControl) {
  const controlValue: string = control.value;
  if (!controlValue) {
    return {
      passwordNumber: true,
    };
  }

  const numberRegex = /\d/;
  const regexMatch = controlValue.match(numberRegex);
  if (!regexMatch) {
    return {
      passwordNumber: true,
    };
  }
  return null;
}

export function passwordSpecial(control: AbstractControl) {
  const controlValue: string = control.value;
  if (!controlValue) {
    return {
      passwordSpecial: true,
    };
  }

  const specialRegex = /(@|!|\*|\$|%|#|\?|\+|=|&|\:|\^)/;
  const regexMatch = controlValue.match(specialRegex);
  if (!regexMatch) {
    return {
      passwordSpecial: true,
    };
  }
  return null;
}

export const pwValidatorGroup = [
  passwordLowercase,
  passwordUppercase,
  passwordNumber,
  passwordSpecial,
];
