<div class="c_paymentBlock" *ngIf="payments !== null">
  <div class="c_paymentBlockHeader">
    <h3 class="u_txt--hdg3">Outstanding Payments</h3>
  </div>
  <div class="c_paymentBlockSort">
    <div class="c_paymentBlockSort__Heading" (click)="sortPayments('date')">
      <h3 class="u_txt--hdg3">Date</h3>
      <div
        class="c_paymentBlockSort__icon"
        *ngIf="!sortTable.date.active; else dateActive"
      >
        <i class="fa fa-sort"></i>
      </div>
      <ng-template #dateActive>
        <div class="c_paymentBlockSort__icon">
          <i
            *ngIf="sortTable.date.direction === 'up'"
            class="fa fa-sort-up"
          ></i>
          <i
            *ngIf="sortTable.date.direction === 'down'"
            class="fas fa-sort-down"
          ></i>
        </div>
      </ng-template>
    </div>
    <div class="c_paymentBlockSort__Heading" (click)="sortPayments('league')">
      <h3 class="u_txt--hdg3">League</h3>
      <div
        class="c_paymentBlockSort__icon"
        *ngIf="!sortTable.league.active; else leagueActive"
      >
        <i class="fa fa-sort"></i>
      </div>
      <ng-template #leagueActive>
        <div class="c_paymentBlockSort__icon">
          <i
            class="fa fa-sort-up"
            *ngIf="sortTable.league.direction === 'up'"
          ></i>
          <i
            class="fas fa-sort-down"
            *ngIf="sortTable.league.direction === 'down'"
          ></i>
        </div>
      </ng-template>
    </div>
    <div class="c_paymentBlockSort__Heading" (click)="sortPayments('amount')">
      <h3 class="u_txt--hdg3">Amount</h3>
      <div
        class="c_paymentBlockSort__icon"
        *ngIf="!sortTable.amount.active; else amountActive"
      >
        <i class="fas fa-sort"></i>
      </div>
      <ng-template #amountActive>
        <div class="c_paymentBlockSort__icon">
          <i
            class="fas fa-sort-up"
            *ngIf="sortTable.amount.direction === 'up'"
          ></i>
          <i
            class="fas fa-sort-down"
            *ngIf="sortTable.amount.direction === 'down'"
          ></i>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="c_paymentBlockPayments" *ngIf="payments.length > 0; else noItems">
    <div
      class="c_paymentBlockPayments__payment"
      *ngFor="let payment of payments"
    >
      <p>{{ parseDate(payment.date) }}</p>
      <p>{{ payment.leagueName }}</p>
      <p>${{ payment.fee }}</p>
      <app-checkout
        *ngIf="!payment.chargeSuccess"
        [paymentId]="payment.id"
        (paymentSuccess)="processSuccessfulPayment()"
      ></app-checkout>
      <div *ngIf="payment.chargeSuccess">
        <button
          (click)="openReceipt(payment.id)"
          class="c_button secondary_dark"
        >
          View Reciept
        </button>
      </div>
    </div>
  </div>
  <ng-template #noItems>
    <div class="c_paymentBlockPayments">
      <p>No Payments to Show</p>
    </div>
  </ng-template>
  <div class="c_paymentBlockFooter">
    <!-- TODO: Implement paging -->
    <p *ngIf="payments.length > 0; else noPayments">
      Showing {{ startIndex + 1 }} to
      {{ endIndex > payments.length ? payments.length : endIndex }} of
      {{ payments.length }} entries
    </p>
    <ng-template #noPayments>
      <p>Showing No Payments</p>
    </ng-template>
    <div class="c_paymentBlockShow">
      <!-- TODO: Reenable when we have pagination stats -->
      <!-- <label>
				Show up to
				<select [(ngModel)]="range">
					<option *ngFor="let val of rangeValues" [ngValue]="val">{{
						val
					}}</option>
				</select>
				entries
			</label> -->
    </div>
  </div>
  <!-- TODO: Implement past payments -->
</div>
