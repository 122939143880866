import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Title } from "@angular/platform-browser";

import { PaymentService } from "@services/payment.service";
import { Payment } from "@services/payment.service";

@Component({
  selector: "app-payments-page",
  templateUrl: "./payments-page.component.html",
  styleUrls: ["./payments-page.component.scss"],
  animations: [],
})
export class PaymentsPageComponent implements OnInit {
  public payments: Observable<Payment[]>;

  constructor(
    private _titleService: Title,
    private _paymentsService: PaymentService
  ) {}

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | Payments");
    this.getPaymentData();
  }

  public getPaymentData(): void {
    this.payments = this._paymentsService.getUserPayments();
  }
}
