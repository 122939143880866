import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

import jsonToFormData from "@ajoelp/json-to-formdata";

import { environment } from "@environments/environment";

import {
  Team,
  TeamDetails,
  GenericTeamLeagueData,
  SearchedTeam,
  GenericTeamRoster,
  GenericTeamSeriesMatchup,
  GenericTeamInviteCodeInformation,
  TeamTypes,
  TeamUpdateRequest,
  CreateTeamRequest,
} from "../reducers/teams/teams.types";
import { basicAuthHeader, basicAuthHeaderWJSON } from "../util/auth-utils";
import { InviteCodeStatuses } from "../enums/team.enum";

interface TeamDataAPIResponse {
  attributes: {
    description: string;
    title: string;
    captain: string;
    teamType: TeamTypes;
  };
  id: string;
  type: string;
}

interface TeamDetailsDataApiResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      captainId: number;
      id: number;
      description: string;
      title: string;
      inviteCode: string;
      inviteCodeStatus: string;
      completedGames: number;
      logoUrl: string;
      wins: number;
      members: TeamDetailsAPIRosterData[];
      lastCompletedSeriesMatchup: TeamSeriesMatchupData;
      nextSeriesMatchup: TeamSeriesMatchupData;
      teamType: TeamTypes;
    };
    relationships: {
      leagues: {
        data: {
          id: string;
          type: string;
        }[];
      };
    };
  };
  included: TeamIncludedItem[];
}

interface TeamDetailsAPIRosterData {
  approved: boolean;
  in_game_name: string;
  starter: boolean;
  team_user_id: string;
  user_id: string;
}

interface LeagueIncluded {
  id: string;
  type: "leagues";
  attributes: {
    id: number;
    title: string;
    description: string;
    finished: boolean;
    entrants: number;
    startTime: number;
    logoUrl: string;
  };
}

interface TeamSeriesMatchupData {
  data: {
    id: string;
    type: string;
    attributes: {
      winnerId: number;
      leagueId: number;
      teams: {
        id: string;
        type: string;
        attributes: {
          captain_id: number;
          title: string;
          wins: number;
          completed_games: number;
          logo_url: string;
        };
      }[];
    };
  };
}

interface TeamsSearchDataAPIResponse {
  attributes: {
    id: number;
    title: string;
    captain: string;
    captainId: number;
  };
  id: string;
  type: string;
}
type TeamIncludedItem = LeagueIncluded;

interface TeamInviteDataAPIResponse {
  data: {
    type: string;
    id: number;
    attributes: {
      title: string;
    };
  };
}

@Injectable({
  providedIn: "root",
})
export class TeamsService {
  constructor(private _http: HttpClient) { }

  public getTeams(): Observable<Team[]> {
    const url = `${environment.apiBase}/api/v1/teams.json`;
    const headers = basicAuthHeader();
    return this._http
      .get<{ data: TeamDataAPIResponse[] }>(url, {
      headers,
    })
      .pipe(
        take(1),
        map((rawTeamArray) =>
          rawTeamArray.data.map((team) => ({
            id: team.id,
            type: team.type,
            description: team.attributes.description,
            title: team.attributes.title,
            captain: team.attributes.captain,
            teamType: team.attributes.teamType,
          }))
        )
      );
  }

  public getTeamDetails(id: number): Observable<TeamDetails> {
    const url = `${environment.apiBase}/api/v1/teams/${id}.json`;
    const headers = basicAuthHeader();
    return this._http
      .get<TeamDetailsDataApiResponse>(url, {
      headers,
    })
      .pipe(
        map((rawTeamData) => {
          const res = this._mapTeamDetails(rawTeamData);
          return res;
        })
      );
  }

  public getTeam(id: number): Observable<Team> {
    const url = `${environment.apiBase}/api/v1/teams/${id}.json`;
    const headers = basicAuthHeader();
    return this._http
      .get<{ data: TeamDataAPIResponse }>(url, {
      headers,
    })
      .pipe(map((rawTeamData) => this._mapTeamData(rawTeamData.data)));
  }

  // Can be used to request to join a team as well
  // TODO: Add in some response type when available
  public addUserToTeam(teamId: string, userId: string): Observable<unknown> {
    const url = `${environment.apiBase}/api/v1/team_users`;
    const headers = basicAuthHeader();
    const payload = {
      team_user: {
        team_id: teamId,
        user_id: userId,
      },
    };
    return this._http.post(url, payload, {
      headers,
    });
  }

  public removeUserFromTeam(teamUserId: string) {
    const url = `${environment.apiBase}/api/v1/team_users/${teamUserId}`;
    const headers = basicAuthHeader();

    return this._http.delete(url, {
      headers,
    });
  }

  public rejectUserInvite(teamUserId: string) {
    const url = `${environment.apiBase}/api/v1/team_users/${teamUserId}`;
    const headers = basicAuthHeader();

    return this._http.delete(url, {
      headers,
    });
  }

  public approveUserInvite(teamUserId: string): Observable<unknown> {
    const url = `${environment.apiBase}/api/v1/team_users/${teamUserId}`;
    const headers = basicAuthHeader();
    const payload = {
      team_user: {
        approved: true,
        starter: false,
      },
    };
    return this._http.patch(url, payload, {
      headers,
    });
  }

  public activatePlayer(teamUserId: string): Observable<unknown> {
    const url = `${environment.apiBase}/api/v1/team_users/${teamUserId}`;
    const headers = basicAuthHeader();
    const payload = {
      team_user: {
        approved: true,
        starter: true,
      },
    };
    return this._http.patch(url, payload, {
      headers,
    });
  }

  public benchPlayer(teamUserId: string): Observable<unknown> {
    const url = `${environment.apiBase}/api/v1/team_users/${teamUserId}`;
    const headers = basicAuthHeader();
    const payload = {
      team_user: {
        approved: true,
        starter: false,
      },
    };
    return this._http.patch(url, payload, {
      headers,
    });
  }

  public addTeamToLeague(teamId: number, leagueId: number): Observable<unknown> {
    const url = `${environment.apiBase}/api/v1/league_teams`;
    const headers = basicAuthHeader();
    const payload = {
      league_team: {
        team_id: teamId,
        league_id: leagueId,
      },
    };
    return this._http.post(url, payload, {
      headers,
    });
  }

  public createTeam(newTeam: CreateTeamRequest): Observable<unknown> {
    const url = `${environment.apiBase}/api/v1/teams`;
    const headers = basicAuthHeader();
    const payload: { team: CreateTeamRequest } = {
      team: {
        ...newTeam,
      },
    };
    const formData = jsonToFormData(payload);
    return this._http.post(url, formData, {
      headers,
    });
  }

  public editTeam(editTeam: TeamUpdateRequest): Observable<unknown> {
    const url = `${environment.apiBase}/api/v1/teams/${editTeam.id}`;
    const headers = basicAuthHeader();
    //There's gotta be a clean way to do this with destructuring and nullish coalescence
    let payload;
    if (editTeam.logo) {
      payload = {
        team: {
          title: editTeam.title,
          logo: editTeam.logo,
          description: editTeam.description,
        },
      };
    } else {
      payload = {
        team: {
          title: editTeam.title,
          description: editTeam.description,
        },
      };
    }
    const formData = jsonToFormData(payload);
    return this._http.put(url, formData, {
      headers,
    });
  }

  public updateTeamCodeStatus(teamId: string, teamInviteCodeStatus: InviteCodeStatuses): Observable<GenericTeamInviteCodeInformation> {
    const payload = {
      invite_code_status: teamInviteCodeStatus,
    };
    const url = `${environment.apiBase}/api/v1/teams/${teamId}`;
    const headers = basicAuthHeader();
    return this._http
      .patch<TeamDetailsDataApiResponse>(url, payload, {
      headers,
    })
      .pipe(
        map((rawTeamData) => ({
          teamId: `${rawTeamData.data.attributes.id}`,
          inviteCode: rawTeamData.data.attributes.inviteCode,
          inviteCodeStatus: rawTeamData.data.attributes.inviteCodeStatus as InviteCodeStatuses,
        }))
      );
  }

  public searchTeams(searchKey: string): Observable<SearchedTeam[]> {
    const url = `${environment.apiBase}/api/v1/teams.json?q[title_cont]=${searchKey}`;
    const headers = basicAuthHeader();
    return this._http
      .get<{ data: TeamsSearchDataAPIResponse[] }>(url, {
      headers,
    })
      .pipe(map((apiResponse) => apiResponse.data.map((teamAPI) => this._mapTeamSearch(teamAPI))));
  }

  public joinTeamWithCode(inviteCode: string): Observable<{ teamName: string; teamId: string }> {
    const url = `${environment.apiBase}/api/v1/teams/join_with_code`;
    const headers = basicAuthHeaderWJSON();
    const body = {
      team: {
        invite_code: inviteCode,
      },
    };
    return this._http
      .post<TeamInviteDataAPIResponse>(url, body, {
      headers,
    })
      .pipe(
        map((apiResponse) => ({
          teamName: apiResponse.data.attributes.title,
          teamId: `${apiResponse.data.id}`,
        }))
      );
  }

  /**Takes a team detail object and maps it to a roster team for the
   * roster pages.
   */
  private _mapTeamDetailsToGenericRoster(teamCaptainId: number, rawTeamRoster: TeamDetailsAPIRosterData[]): GenericTeamRoster {
    const roster: GenericTeamRoster = {
      starters: [],
      bench: [],
      pending: [],
    };
    if (rawTeamRoster !== null) {
      for (const team of rawTeamRoster) {
        //Starters
        if (team.starter === true && team.approved === true) {
          roster.starters.push({
            id: team.user_id,
            inGameName: team.in_game_name,
            isCaptain: teamCaptainId === +team.user_id,
            teamUserId: +team.team_user_id,
          });
        }
        //Benched
        else if (team.starter === false && team.approved === true) {
          roster.bench.push({
            id: team.user_id,
            inGameName: team.in_game_name,
            isCaptain: teamCaptainId === +team.user_id,
            teamUserId: +team.team_user_id,
          });
        }
        //Pending
        else {
          roster.pending.push({
            id: team.user_id,
            inGameName: team.in_game_name,
            isCaptain: teamCaptainId === +team.user_id,
            teamUserId: +team.team_user_id,
          });
        }
      }
    }
    return roster;
  }

  private _mapTeamData(apiResponse: TeamDataAPIResponse): Team {
    return {
      id: apiResponse.id,
      type: apiResponse.type,
      description: apiResponse.attributes.description,
      title: apiResponse.attributes.title,
      captain: apiResponse.attributes.captain,
    };
  }

  private _mapTeamDetails(apiResponse: TeamDetailsDataApiResponse): TeamDetails {
    const apiLeagues: GenericTeamLeagueData[] = [];
    apiResponse.included.map((includedItem) => {
      switch (includedItem.type) {
        case "leagues":
          apiLeagues.push(this._mapTeamDetailsLeagueIncluded(includedItem));
          break;
        default:
          //Do nothing
          break;
      }
    });
    const {
      id,
      captainId,
      description,
      title,
      completedGames,
      logoUrl,
      wins,
      nextSeriesMatchup,
      lastCompletedSeriesMatchup,
      members,
      inviteCode,
      inviteCodeStatus,
      teamType,
    } = apiResponse.data.attributes;
    return {
      id,
      type: apiResponse.data.type,
      captainId,
      description,
      title,
      completedGames,
      logoUrl,
      inviteCode,
      inviteCodeStatus: inviteCodeStatus as InviteCodeStatuses,
      wins,
      lastCompletedSeriesMatchup: lastCompletedSeriesMatchup.data ? this._mapTeamSeriesMatchupData(lastCompletedSeriesMatchup) : null,
      nextSeriesMatchup: nextSeriesMatchup.data ? this._mapTeamSeriesMatchupData(nextSeriesMatchup) : null,
      members: this._mapTeamDetailsToGenericRoster(captainId, members),
      leagues: apiLeagues,
      teamType,
    };
  }

  private _mapTeamSearch(apiResponse: TeamsSearchDataAPIResponse): SearchedTeam {
    return {
      id: apiResponse.attributes.id,
      captainId: apiResponse.attributes.captainId,
      captain: apiResponse.attributes.captain,
      title: apiResponse.attributes.title,
    };
  }

  private _mapTeamDetailsLeagueIncluded(apiResponse: LeagueIncluded): GenericTeamLeagueData {
    return {
      id: apiResponse.attributes.id,
      title: apiResponse.attributes.title,
      description: apiResponse.attributes.description,
      finished: apiResponse.attributes.finished,
      entrants: apiResponse.attributes.entrants,
      startTime: apiResponse.attributes.startTime,
      logoUrl: apiResponse.attributes.logoUrl,
    };
  }

  private _mapTeamSeriesMatchupData(apiData: TeamSeriesMatchupData): GenericTeamSeriesMatchup {
    return {
      id: apiData.data.id,
      type: apiData.data.type,
      winnerId: `${apiData.data.attributes.winnerId}`,
      leagueId: `${apiData.data.attributes.leagueId}`,
      teams: apiData.data.attributes.teams.map((team) => ({
        id: team.id,
        logoUrl: team.attributes.logo_url,
        captainId: `${team.attributes.captain_id}`,
        title: team.attributes.title,
        wins: team.attributes.wins,
        completedGames: team.attributes.completed_games,
      })),
    };
  }
}
