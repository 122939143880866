import { Logger } from "@utils/logger";

// Refernce to Google Analytics
// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

export const registerOrgZipSearchEvent = (zip: string): void => {
  try {

    gtag("event", "org_zip_search", {
      event_category: "org_search",
      event_label: `zip_search: ${zip}`,
      value: `${zip}`,
    });
  } catch (e) {
    Logger.error("Analytics event failed to fire");
  }
};

export const registerOrgNameSearchEvent = (name = "N/A", state = "N/A"): void => {
  try {
    gtag("event", "org_name_state_search", {
      event_category: "org_search",
      event_label: `name_search: term_${name}|state_${state}`,
      value: `term_${name}|state_${state}`,

    });
  } catch (e) {
    Logger.error("Analytics event failed to fire");
  }
};

export const registerDashboardOrgZipSearchEvent = (zip: string): void => {
  try {

    gtag("event", "dash_org_zip_search", {
      event_category: "dash_events",
      event_label: `zip_search: ${zip}`,
      value: `${zip}`,
    });
  } catch (e) {
    Logger.error("Analytics event failed to fire");
  }
};

export const registerDashboardOrgNameSearchEvent = (name = "N/A", state = "N/A"): void => {
  try {
    gtag("event", "dash_org_name_state_search", {
      event_category: "dash_events",
      event_label: `name_search: term_${name}|state_${state}`,
      value: `term_${name}|state_${state}`,

    });
  } catch (e) {
    Logger.error("Analytics event failed to fire");
  }
};
