import { Component, Input } from "@angular/core";

import { GenericLeagueMatch } from "src/app/reducers/leagues/league.types";

@Component({
  selector: "app-matches-preview",
  templateUrl: "./matches-preview.component.html",
  styleUrls: ["./matches-preview.component.scss"],
})
export class MatchesPreviewComponent {
  @Input() public matches: GenericLeagueMatch[];
  @Input() public matchType: string;
}
