import {
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "app-header-toggle-button",
  templateUrl: "./header-toggle-button.component.html",
  styleUrls: ["./header-toggle-button.component.scss"],
})
export class HeaderToggleButtonComponent {
  @Input() public toggleBoolean = false;
  @Output() public toggle = new EventEmitter<Event>();

  public onToggleClick(event: Event) {
    this.toggle.emit(event);
  }
}
