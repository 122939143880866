import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-utils";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export interface TextSubscriptionsAPIData {
  id: string;
  type: string;
  attributes: {
    status: "active" | "inactive";
    verificationStatus: "verified" | "unverified";
  };
}

export interface TextSubscriptions {
  id: string;
  type: string;
  status: "active" | "inactive";
  verificationStatus: "verified" | "unverified";
}

@Injectable({
  providedIn: "root",
})
export class TextNotifServiceService {

  constructor(
    private _http: HttpClient
  ) { }

  public enableTextVerificationProcess(): Observable<unknown> {
    const url = `${environment.apiBase}/api/v2/text_notification_subscriptions/verification_initiation`;
    const headers = basicAuthHeader();

    return this._http.post(url, {
    }, {
      headers,
    });
  }

  public verifyNumber(verifyCode: string | number): Observable<unknown> {

    const url = `${environment.apiBase}/api/v2/text_notification_subscriptions/verify`;
    const payload = {
      text_notification_subscription: {
        code: verifyCode,
      },
    };
    const headers = basicAuthHeader();

    return this._http.post(url, payload, {
      headers,
    });
  }

  public cancelAllTextNotifications(): Observable<unknown> {
    const url = `${environment.apiBase}/api/v2/text_notification_subscriptions/unsubscribe`;
    const headers = basicAuthHeader();

    return this._http.post(url, {
    }, {
      headers,
    });
  }

  public getCurrentSubscriptions(): Observable<TextSubscriptions | null> {
    const url = `${environment.apiBase}/api/v2/text_notification_subscriptions`;
    const headers = basicAuthHeader();

    return this._http.get<{ data: TextSubscriptionsAPIData | null }>(url, {
      headers,
    }).pipe(
      map((res) => {
        if (!!res && !!res.data) {
          return {
            id: res.data.id,
            type: res.data.type,
            ...res.data.attributes,
          };
        }

        return null;
      })
    );
  }
}
