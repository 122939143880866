import { CarouselGameCard } from "@apptypes/component-interfaces/carousel-cards.interface";

const createLeagueCarouselCard = (cardImgUrl: string, cardTitle: string): CarouselGameCard => ({
  cardImgUrl,
  cardTitle,
  imgAlt: `Picture of ${cardTitle}`,
});

export const FortniteCard = createLeagueCarouselCard(
  "https://content.ggleagues.com/public/webassets/carousel_photo_assets/fortnite.jpeg",
  "Fortnite"
);

export const MaddenCard = createLeagueCarouselCard(
  "https://content.ggleagues.com/public/webassets/carousel_photo_assets/madden.jpeg",
  "Madden"
);

export const MarioKartCard = createLeagueCarouselCard(
  "https://content.ggleagues.com/public/webassets/carousel_photo_assets/mariokart.jpeg",
  "Mario Kart"
);

export const RocketLeagueCard = createLeagueCarouselCard(
  "https://content.ggleagues.com/public/webassets/carousel_photo_assets/rocketleague.jpeg",
  "Rocket League"
);

export const SSBUCard = createLeagueCarouselCard(
  "https://content.ggleagues.com/public/webassets/carousel_photo_assets/ssbu.jpeg",
  "Smash Bros."
);

export const NBA2KCard = createLeagueCarouselCard(
  "https://content.ggleagues.com/public/webassets/carousel_photo_assets/nba2k.jpeg",
  "NBA 2K"
);

