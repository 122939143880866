<app-static-page pageTitle="Rules" class="c_rulesPage">
  <section aria-labelledby="recLeagueTitle">
    <h2 class="u_txt--hdg2" #recLeagueTitle>Recreational League Rulebooks</h2>
    <p>
      Below are our current default rulesets for the recreational league games
      supported on GGLeagues. Your individual league may have a variation of
      these rules that was sent to you when you joined the league.
    </p>
    <p>
      It is <strong>highly recommended</strong> that all players thoroughly read
      through their rulebook(s) prior to their league beginning. Please reach
      out to <a href="mailto:support@ggleagues.com">support@ggleagues.com</a> if
      you have any questions about the rules for your league.
    </p>
    <ul>
      <li>
        <a [routerLink]="rulesLinks.FORTNITE">Fortnite (Battle Royale)</a>
      </li>
      <li>
        <a [routerLink]="rulesLinks.FORTNITE_ELIM"
          >Fortnite (Elimination Race)</a
        >
      </li>
      <li><a [routerLink]="rulesLinks.ROCKETLEAGUE">Rocket League</a></li>
      <li>
        <a [routerLink]="rulesLinks.SMASHBROS">Super Smash Bros Ultimate</a>
      </li>
      <li><a [routerLink]="rulesLinks.MADDEN">Madden</a></li>
      <li><a [routerLink]="rulesLinks.MARIOKART">MarioKart</a></li>
    </ul>
  </section>
  <hr />
  <section aria-labelledby="eliteLeagueTitle">
    <h2 class="u_txt--hdg2" #eliteLeagueTitle>Elite League Rulebooks</h2>
    <p>
      Below are our current default rulesets for the elite league games
      supported on GGLeagues. Your individual league may have a variation of
      these rules that was sent to you when you joined the league.
    </p>
    <p>
      It is <strong>highly recommended</strong> that all players thoroughly read
      through their rulebook(s) prior to their league beginning. Please reach
      out to <a href="mailto:support@ggleagues.com">support@ggleagues.com</a> if
      you have any questions about the rules for your league.
    </p>
    <ul>
      <li>
        <a [routerLink]="eliteRulesLink.LOL_ELITE">League of Legends (Elite)</a>
      </li>
      <li><a [routerLink]="eliteRulesLink.OW_ELITE">Overwatch (Elite)</a></li>
      <li>
        <a [routerLink]="eliteRulesLink.RL_ELITE">Rocket League (Elite)</a>
      </li>
      <li>
        <a [routerLink]="eliteRulesLink.VALORANT_ELITE">Valorant (Elite)</a>
      </li>
    </ul>
  </section>
  <hr />
  <section aria-labelledby="weeklyLeagueTitle">
    <h2 class="u_txt--hdg2" #weeklyLeagueTitle>Weekly League Rulebooks</h2>
    <p>
      Below are our current default rulesets for the weekly league games
      supported on GGLeagues. Your individual league may have a variation of
      these rules that was sent to you when you joined the league.
    </p>
    <p>
      It is <strong>highly recommended</strong> that all players thoroughly read
      through their rulebook(s) prior to their league beginning. Please reach
      out to <a href="mailto:support@ggleagues.com">support@ggleagues.com</a> if
      you have any questions about the rules for your league.
    </p>
    <ul>
      <li>
        <a [routerLink]="weeklyRulesLinks.SSBU_WEEKLY"
          >Super Smash Bros Ultimate (Weekly)</a
        >
      </li>
      <li>
        <a [routerLink]="weeklyRulesLinks.RL_WEEKLY">Rocket League (Weekly)</a>
      </li>
      <li>
        <a [routerLink]="weeklyRulesLinks.MARIOKART_WEEKLY"
          >MarioKart (Weekly)</a
        >
      </li>
      <li>
        <a [routerLink]="weeklyRulesLinks.FORTNITE_BR_WEEKLY"
          >Fortnite (Battle Royale) (Weekly)</a
        >
      </li>
      <li>
        <a [routerLink]="weeklyRulesLinks.NBA_2K">NBA 2K (Weekly)</a>
      </li>
    </ul>
  </section>
  <hr />
  <section aria-labelledby="playerConductTitle">
    <h2 id="player-conduct" class="u_txt--hdg2" #playerConductTitle>
      Player Conduct
    </h2>
    <p>
      GGLeagues has a zero-tolerance policy. Please ensure you are familiar with
      our code of conduct prior to participating in a league. To ensure we are
      providing the best experience possible, all players are expected to
      display Good Gamer Behavior as listed below.
    </p>
    <ol>
      <li>Respect your opponents, team, and GGLeagues staff</li>
      <li>
        Don’t be toxic. This means <strong>NO</strong>:
        <ul>
          <li>Cursing</li>
          <li>Sexism</li>
          <li>Racism</li>
          <li>Homophobic remarks</li>
          <li>Taunting</li>
          <li>Bullying</li>
          <li>Lewd/NSFW remarks or posts</li>
        </ul>
      </li>
      <li>Play with integrity in all GGLeagues matches</li>
      <li>Follow all the rules listed out in our GGLeagues Rulebooks</li>
    </ol>
    <p>
      Everyone participating in GGLeagues also agrees to follow all the rules as
      outlined in the GGLeagues Code of Conduct:
    </p>
    <p>
      <a
        class="c_button secondary_dark"
        target="_blank"
        rel="noreferrer"
        [href]="playerGuides.CODE_OF_CONDUCT"
      >
        Complete GGLeagues Code of Conduct (PDF)
        <i class="fas fa-file-download"></i>
      </a>
    </p>
  </section>
  <hr />
</app-static-page>
