import { Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { Store } from "@ngrx/store";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Router } from "@angular/router";

import { TeamsService } from "@services/teams.service";

import { InviteCodeStatuses } from "src/app/enums/team.enum";
import { GenericTeamInviteCodeInformation } from "src/app/reducers/teams/teams.types";
import { Logger } from "src/app/util/logger";
import { RootState } from "src/app/reducers";
import { LocalTeamUpdate } from "src/app/reducers/teams/teams.actions";
import { ModalController } from "../modal-controller/modal-controller.abstract";

const baseUrl = "https://app.ggleagues.com/teams/invite/<INVITE_CODE>";

@Component({
  selector: "app-team-magic-link",
  templateUrl: "./team-magic-link.component.html",
  styleUrls: ["./team-magic-link.component.scss"],
})
export class TeamMagicLinkComponent extends ModalController<GenericTeamInviteCodeInformation> {
  public linkEnabled = true;
  public linkCode = baseUrl;

  private _inviteInfo: GenericTeamInviteCodeInformation;

  constructor(
    private _toastr: ToastrService,
    private _teamsService: TeamsService,
    private _store: Store<RootState>,
    private _modalService: NgxSmartModalService,
    private _router: Router
  ) {
    super(_modalService, "teamMagicLink", _router);

    this._inviteInfo = this.getData();
    this.linkCode = this.linkCode.replace("<INVITE_CODE>", this._inviteInfo.inviteCode);
    this.linkEnabled = this._inviteInfo.inviteCodeStatus === InviteCodeStatuses.ALWAYS;
  }

  public changeStatus() {
    const newStatus = this._flipStatus(this._inviteInfo.inviteCodeStatus);
    this._teamsService
      .updateTeamCodeStatus(this._inviteInfo.teamId, newStatus)
      .pipe(
        catchError((err) => {
          Logger.error(err);
          this._toastr.error("Could not update the invite code status", "Error");
          return of(null);
        })
      )
      .subscribe((result) => {
        if (result) {
          this.linkCode = baseUrl.replace("<INVITE_CODE>", result.inviteCode);
          this.linkEnabled = result.inviteCodeStatus === InviteCodeStatuses.ALWAYS;
          this._inviteInfo = result;
          this._store.dispatch(
            new LocalTeamUpdate({
              inviteCodeStatus: newStatus,
            })
          );
        }
      });
  }

  public copyToClipboard() {
    if (this.linkEnabled) {
      const copyCode = document.getElementById("copyCode") as HTMLInputElement;
      copyCode.select();
      copyCode.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this._toastr.success("Copied to clipboard!");
    }
  }

  private _flipStatus(inviteCodeStatus: InviteCodeStatuses) {
    if (inviteCodeStatus === InviteCodeStatuses.ALWAYS) {
      return InviteCodeStatuses.NEVER;
    } else if (inviteCodeStatus === InviteCodeStatuses.NEVER) {
      return InviteCodeStatuses.ALWAYS;
    } else {
      return InviteCodeStatuses.NEVER;
    }
  }
}
