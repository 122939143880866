<div class="c_modal">
  <div class="c_modal__title">
    <ng-container [ngTemplateOutlet]="modalTitle"></ng-container>
  </div>
  <div
    class="c_modal__content"
    [ngClass]="{ 'c_modal__content--stepper': isStepper }"
  >
    <ng-container [ngTemplateOutlet]="modalContent"></ng-container>
  </div>
  <div class="c_modal__footer">
    <ng-container [ngTemplateOutlet]="modalFooter"></ng-container>
  </div>
</div>
