<app-lobby-help-modal-frame modalTitle="Joining a Fornite Battle Royale Match">
  <iframe
    class="c_helpModal__video"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/8ZqZ5fcm7_Y"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
  <p class="u_txt">
    To join the GGLeagues custom Battle Royale follow these steps:
  </p>
  <h3>Step 1: Get The Battle Royale Lobby Code:</h3>
  <ol>
    <li>
      Wait for the current game to begin <strong>Accepting Check-Ins</strong>.
      Check-ins open usually 10 minutes before the series begins, and will stay
      open for 10 minutes for each game
    </li>
    <li>
      Follow the steps to check-in to the current game. You will need to provide
      your Fortnite ID if you haven't done so yet. This allows us to know what
      your final score is at the end of the game
    </li>
    <li>
      After checking in, the lobby info will change and display the Lobby Code
      for the game
    </li>
  </ol>
  <h3>
    <a
      href="https://www.epicgames.com/help/en-US/supportacreator-c83/program-benefits-c92/how-to-start-a-private-match-in-fortnite-for-supportacreator-a3294"
      target="_blank"
      rel="noopener"
    >
      Step 2: Join the Custom Lobby in Fortnite
      <i class="fas fa-external-link-alt"></i>
    </a>
  </h3>
  <ol>
    <li>Click Change to bring up the menu and select the game mode.</li>
    <li>
      Select Custom Matchmaking Key and enter the key you received from your
      host. (Note: Match keys are case sensitive.)
    </li>
    <li>
      Click Accept. (Note: If you try to join after the match started, you’ll
      receive an error message. If you’re immediately kicked after joining, your
      host’s match may already have reached the 100-player maximum capacity.)
    </li>
    <li>Click Play and wait for the match to start.</li>
  </ol>
</app-lobby-help-modal-frame>
