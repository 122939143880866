import {
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import {
  first,
  map,
  pluck,
  skipWhile,
  takeUntil,
} from "rxjs/operators";
import { generateLeagueDetailRoute, generateLeagueScheduleRoute } from "src/app/enums/routes/routePaths";
import { RootState } from "src/app/reducers";
import {
  DashboardLeague,
  DashboardLeagueTeam,
  DashboardNextMatch,
} from "src/app/reducers/dashboard";
import { UserTeam } from "src/app/reducers/user/user.types";
import { Unsubscriber } from "src/app/util/unsubscriber";
import { DashLeaguesInfoModalComponent } from "../dash-leagues-info-modal/dash-leagues-info-modal.component";
@Component({
  selector: "app-dash-league-overview",
  templateUrl: "./dash-league-overview.component.html",
  styleUrls: ["./dash-league-overview.component.scss"],
})
export class DashLeagueOverviewComponent implements OnDestroy, OnInit {
  public activeLeague: DashboardLeague;
  public activeLeagueList: DashboardLeague[];
  public activeLeagueTeam: UserTeam;
  public activeLeagueNextMatch: DashboardNextMatch;
  public userInLeague = false;
  public leagueInfoModal = DashLeaguesInfoModalComponent;

  public isLoading$: Observable<boolean> = of(true);

  private _unsub = new Unsubscriber();

  constructor(private _store: Store<RootState>) {
    this.isLoading$ = this._store.select("dashboard").pipe(pluck("isLoading"), takeUntil(this._unsub.unsubEvent));
  }

  public ngOnInit(): void {
    this._store
      .select("dashboard", "leagues")
      .pipe(
        map((leagues) => new Map(leagues)),
        skipWhile((dashboardLeagues) => dashboardLeagues.size === 0),
        first()
      )
      .subscribe((dashboardLeagues) => {
        this.userInLeague = true;
        const leagueList = Array.from(dashboardLeagues.values());
        this.activeLeagueList = leagueList;
        this.changeActiveLeague(leagueList[0]);
      });
  }

  public ngOnDestroy() {
    this._unsub.kill();
  }

  public changeActiveLeague(league: DashboardLeague): void {
    this.activeLeague = league;
    this.activeLeagueTeam = this._mapDashboardLeagueTeamToUserTeam(league.leagueTeam);
    this.activeLeagueNextMatch = league.leagueNextMatch;
  }

  public generateLeagueRoute(leagueId: string): string {
    return `/${generateLeagueDetailRoute(leagueId)}`;
  }

  public generateLeagueScheduleRoute(leagueId: string): string {
    return `/${generateLeagueScheduleRoute(leagueId)}`;
  }

  private _mapDashboardLeagueTeamToUserTeam(dashTeam: DashboardLeagueTeam): UserTeam {
    const { id, title, logoUrl, teamType } = dashTeam;
    const captain = null;
    const isCaptain = null;
    const playerCount = null;

    return {
      id: +id,
      title,
      logoUrl,
      teamType,
      captain,
      isCaptain,
      playerCount,
    };
  }
}
