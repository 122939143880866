<div
  class="c_tabsBlock"
  [ngClass]="{
    'c_tabsBlock--invert': darkBackground,
    'c_tabsBlock--scrollable': scrollable && useTabs
  }"
>
  <div class="c_tabsBlock__tabContainer" *ngIf="useTabs; else useDropdown">
    <ul class="c_tabsBlock__tabsList">
      <li
        *ngFor="let tab of tabs"
        (click)="selectTab(tab)"
        [ngClass]="{
          'u_txt--bold': tab.active,
          c_tabsBlock__tabHeaderDark: darkBackground,
          c_tabsBlock__tabHeader: !darkBackground,
          'c_tabsBlock__tabHeader--active': !darkBackground && tab.active,
          'c_tabsBlock__tabHeaderDark--active': darkBackground && tab.active,
          c_tabsBlock__nonSlimContent: !slimContent
        }"
      >
        <span>{{ tab.tabTitle }}</span>
      </li>
    </ul>
    <div
      class="
        c_tabsBlock__headerBtnContainer c_tabsBlock__headerBtnContainer--desktop
      "
    >
      <ng-container [ngTemplateOutlet]="headerButton"></ng-container>
    </div>
  </div>
  <ng-template #useDropdown>
    <div class="c_tabsBlock__dropdownContainer">
      <form [formGroup]="dropDownForm">
        <select class="c_tabsBlock__dropdown" formControlName="activeTab">
          <option *ngFor="let tab of tabs" [ngValue]="tab">
            {{ tab.tabTitle }}
          </option>
        </select>
      </form>
    </div>
  </ng-template>
  <ng-content></ng-content>
  <div
    class="
      c_tabsBlock__headerBtnContainer c_tabsBlock__headerBtnContainer--mobile
    "
  >
    <ng-container [ngTemplateOutlet]="headerButton"></ng-container>
  </div>
</div>
