import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

import { staticRoutes } from "src/app/enums/routes/routePaths";

@Component({
  selector: "app-notfound-page",
  templateUrl: "./notfound-page.component.html",
  styleUrls: ["./notfound-page.component.scss"],
})
export class NotfoundPageComponent implements OnInit {
  public routes = staticRoutes;

  constructor(private _titleService: Title) {}

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | 404");
  }
}
