import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { LeaguesV2Service } from "@services/v2/leagues/leagues.v2.service";
import { first, mergeMap } from "rxjs/operators";
import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import { RootState } from "src/app/reducers";
import { LeagueTeamStanding } from "src/app/reducers/leagues/league.types";
import { RankedTeamScore } from "src/app/reducers/teams/teams.types";
@Component({
  selector: "app-league-team-standings",
  templateUrl: "./league-team-standings.component.html",
  styleUrls: ["./league-team-standings.component.scss"],
})
export class LeagueTeamStandingsComponent implements OnInit {
  public useScoresAsStandings = false;

  private _leagueTeamStandings: LeagueTeamStanding[] = [];
  private _leagueTeamScores: RankedTeamScore[] = [];

  constructor(
    private _leagueV2Service: LeaguesV2Service,
    private _store: Store<RootState>
  ) { }

  public ngOnInit(): void {
    this._store.select("leagues", "league")
      .pipe(
        first()
      ).subscribe(
        (league) => {
          if (league && league.esport === gamePlatforms.FORTNITE) {
            this.useScoresAsStandings = true;
            this._leagueV2Service.getLeagueBRTournament(league.id).pipe(
              mergeMap((brtournaments) => {
                const mainTournament = brtournaments[0];
                return this._leagueV2Service.getBRTournamentScores(mainTournament.id);
              })
            ).subscribe(
              (res) => {
                this._leagueTeamScores = res;
              }
            );

          } else {
            this._leagueV2Service.getLeagueStandingsList(league.id)
              .subscribe(
                (res) => {
                  this._leagueTeamStandings = res;
                }
              );
          }
        }
      );
  }

  public get standingsBySeries(): LeagueTeamStanding[] {
    const standingsCopy = [...this._leagueTeamStandings.filter((leagueTeam) => leagueTeam.title.indexOf("BYE WEEK") === -1)];
    standingsCopy.sort((a, b) => {
      if (a.seriesWins > b.seriesWins) {
        return -1;
      }

      if (a.seriesWins < b.seriesWins) {
        return 1;
      }

      if (a.seriesWins === b.seriesWins) {
        if (a.seriesLosses < b.seriesLosses) {
          return -1;
        }

        if (a.seriesLosses > b.seriesLosses) {
          return 1;
        }
      }

      return 0;
    });


    return standingsCopy;
  }

  public get standingsByScore(): RankedTeamScore[] {
    const standingsCopy: RankedTeamScore[] = [...this._leagueTeamScores.filter((leagueTeam) => leagueTeam.name.indexOf("BYE WEEK") === -1)];
    standingsCopy.sort((a, b) => {
      if (a.score > b.score) {
        return -1;
      }

      if (a.score < b.score) {
        return 1;
      }

      return 0;
    });


    return standingsCopy;
  }

}
