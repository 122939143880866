<div class="c_chatStreamBlock__container">
  <app-new-messages-banner
    *ngIf="isBannerShowing"
    (click)="dismissBanner()"
  ></app-new-messages-banner>
  <div
    class="c_chatStreamBlock"
    [ngClass]="{ 'c_chatStreamBlock--admin': isAdminNotifStream }"
    appScrollBottom
  >
    <ng-container *ngIf="isAdminNotifStream; else defaultMessage">
      <div
        class="c_chatStreamBlock__defaultMessage c_chatStreamBlock__defaultMessage--admin"
      >
        <p class="c_chatStreamBlock__name">🤖 GGLeaguesBot</p>
        <p>
          Hey teams!
          <br />
          <br />
          This is the admin notification panel. The person running your series
          tonight will use this separate window to communicate important updates
          about your matches.
          <br />
          <br />
          Only GGLeagues admins can talk here, but you can still use the
          chatroom below to talk with your fellow players once you've checked
          in!
        </p>
      </div>
    </ng-container>
    <ng-template #defaultMessage>
      <div class="c_chatStreamBlock__defaultMessage">
        <p class="c_chatStreamBlock__name">🤖 GGLeaguesBot</p>
        <p>
          Hey teams!
          <br />
          This is your match chat where you can communicate directly with your
          opponent. Please follow the guidelines set out in your rulebooks on
          how to connect with your opponent and get into the match.
          <br />
          You can find our rulebooks here: https://www.ggleagues.com/rules.
          <br />
          If you need to contact an admin, please tap on the "Contact Admin"
          button below and our team will come assist you.
        </p>
      </div>
    </ng-template>
    <app-chat-message
      *ngFor="let message of messages"
      [message]="message"
    ></app-chat-message>
  </div>
</div>
