<a
  [routerLink]="getMatchDetailRoute(battleRoyaleMatchup.id)"
  class="c_smallBattleRoyaleMatchBlock"
>
  <div class="c_smallBattleRoyaleMatchBlock__details">
    <div class="c_smallBattleRoyaleMatchBlock__teamIcon">
      <i class="fas fa-users fa-3x"></i>
    </div>
    <div class="c_smallBattleRoyaleMatchBlock__date">
      <span class="u_txt">{{
        processDate(battleRoyaleMatchup.gameStartTime)
      }}</span>
    </div>
    <strong> BATTLE ROYALE </strong>
    <span> {{ battleRoyaleMatchup.teams.length }} TEAMS </span>
  </div>
</a>
