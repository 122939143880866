<div class="c_modalStepperFooterButtons">
  <small
    *ngIf="hasBlockingErrors"
    class="c_modalStepperFooterButtons__errorMessage u_txt--warn"
  >
    {{ errorMessage }}
  </small>
  <div class="c_modalStepperFooterButtons__buttonContainer">
    <button
      *ngIf="hasPrevious"
      class="c_button secondary_dark c_modalStepperFooterButtons__button"
      matStepperPrevious
    >
      Back
    </button>
    <button
      (click)="checkForBlockers($event)"
      *ngIf="hasNext"
      class="c_button primary_dark c_modalStepperFooterButtons__button"
      matStepperNext
    >
      Next
    </button>
    <!-- In case we need specific buttons at the end of a modal who take odd functions -->
    <ng-content></ng-content>
  </div>
</div>
