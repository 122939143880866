import {
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SeasonPass } from "@apptypes/season-pass/season-pass.types";
import { Store } from "@ngrx/store";
import { SeasonPassService } from "@services/v2/season-pass/season-pass.service";
// eslint-disable-next-line max-len
import { triggerDashSeasonPassActivationAnalyticsEvent, triggerSeasonPassActivationAnalyticsEvent } from "@utils/analytics/passes.analytics";
import { Logger } from "@utils/logger";
import { modalOptions } from "@utils/modal-helpers";
import { Unsubscriber } from "@utils/unsubscriber";
import { NgxSmartModalService } from "ngx-smart-modal";
import { of } from "rxjs";
import {
  first,
  map,
  pluck,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import { ComponentRouteParams } from "src/app/enums/routes/routeParams";
import { routePaths } from "src/app/enums/routes/routePaths";
import { RootState } from "src/app/reducers";
import { RefreshUserProfile } from "src/app/reducers/user/user.actions";
import { ModalController } from "../modal-controller/modal-controller.abstract";

export interface LeaguePassActivationModalData {
  esportPass: SeasonPass;
  contextLeague?: {
    id: string;
    title: string;
  };
}

@Component({
  selector: "app-league-pass-activation-modal",
  templateUrl: "./league-pass-activation-modal.component.html",
  styleUrls: ["./league-pass-activation-modal.component.scss"],
})
export class LeaguePassActivationModalComponent extends ModalController<LeaguePassActivationModalData> implements OnDestroy, OnInit {
  public static MODAL_NAME = "lpActivationModal";

  public profileRoute = `/${routePaths.PROFILE}`;
  public esportPass: SeasonPass | null = null;
  public contextLeagueId: string | null = null;
  public contextLeagueTitle: string | null = null;
  public userHasPass = false;
  public activatePassWarning = false;

  private _unsub = new Unsubscriber();
  private _totalRetries = 0;


  constructor(
    private _modalService: NgxSmartModalService,
    private _router: Router,
    private _store: Store<RootState>
  ) {
    super(_modalService, LeaguePassActivationModalComponent.MODAL_NAME, _router);
  }

  public static initPassActivationModal(
    modalService: NgxSmartModalService,
    activeRoute: ActivatedRoute,
    seasonPassService: SeasonPassService,
    store: Store<RootState>
  ): void {
    activeRoute.data.pipe(
      map((routeData = {
      }) => routeData.passSuccess),
      switchMap((isPassSuccess) => {
        if (isPassSuccess) {
          return activeRoute.paramMap.pipe(
            map((paramMap) => paramMap.get(ComponentRouteParams.ESPORT_PASS_ID)),
            switchMap((passId) => seasonPassService.getSeasonPassById(passId))
          );
        } else {
          return of(null);
        }
      }),
      first()
    ).subscribe(
      async (passData: SeasonPass | null) => {
        if (passData !== null) {
          const modalData: LeaguePassActivationModalData = {
            esportPass: passData,
          };
          try {
            const userId = await store.select("user", "currentUser").pipe(
              first(),
              pluck("id")
            ).toPromise();
            if (window.location?.pathname.indexOf("/dashboard/pass_success") === 0) {
              triggerDashSeasonPassActivationAnalyticsEvent(passData.id, userId);
            } else {
              triggerSeasonPassActivationAnalyticsEvent(passData.id, userId);
            }
          } catch (e) {
            Logger.error(e);
          }
          modalService.create(LeaguePassActivationModalComponent.MODAL_NAME, LeaguePassActivationModalComponent, {
            ...modalOptions,
            dismissable: false,
          }).setData(modalData).open();
        }
      }
    );
  }
  public ngOnInit(): void {
    const modalData = this.getData();
    this.esportPass = modalData.esportPass;
    if (modalData.contextLeague) {
      this.contextLeagueId = modalData.contextLeague.id;
      this.contextLeagueTitle = modalData.contextLeague.title;
    }

    this._store.select("user").pipe(
      /*
       * Uses two plucks to make sure we *always* capture the loading switch from "FetchUserProfile" dispatch
       * This ensures that the update is captured even if there are no changes to the gamepass
       */
      pluck("currentUser"),
      pluck("gamePasses"),
      takeUntil(this._unsub.unsubEvent)
    ).subscribe(
      (res) => {
        const passMatch = this.esportPass ? res.find((pass) => pass.seasonPassId === this.esportPass.id) : false;
        if (passMatch) {
          this.userHasPass = true;
          this._unsub.kill();
        } else if (this._totalRetries < 3) {
          this._totalRetries++;
          const PROFILE_REFETCH_DELAY = 19000; // 19 seconds (assuming about 1 second for network call)
          setTimeout(() => {
            this._store.dispatch(new RefreshUserProfile());
          }, PROFILE_REFETCH_DELAY);
        } else {
          Logger.error("Issue fetching activated pass...showing error message and recomennding refresh");
          this._unsub.kill(); // Killing so we don't endlessly ping
          this.activatePassWarning = true;
        }
      }
    );
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public get formattedPassName(): string {
    const passTitle = this.esportPass.title;
    const match = passTitle.match(/\b(pass)\b/gi);
    if (match && match.length > 0) {
      return passTitle;
    }

    return `${passTitle} Pass`;
  }

  public dismissModal(): void {
    this._modalService.closeLatestModal();
  }
}
