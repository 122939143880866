import { Component, Input } from "@angular/core";

@Component({
  selector: "app-icon-column",
  templateUrl: "./icon-column.component.html",
  styleUrls: ["./icon-column.component.scss"],
})
export class IconColumnComponent {
  @Input() public isHeaderless = false;
}
