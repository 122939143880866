<app-page>
  <h2 class="c_editRoster__header u_txt--hdg2 u_txt--white">
    Edit Team Roster ({{ teamName$ | async }})
  </h2>
  <ng-container *ngIf="(isPageLoading$ | async) === false; else loading">
    <a
      routerLink="/{{ getTeamDetailRoute(teamId) }}"
      class="c_button secondary_light c_editRoster__backBtn"
      >Back to Team Overview</a
    >
    <!-- <div class="c_editRoster__teamInfo">
      <app-team-info-block></app-team-info-block>
    </div> -->
    <div class="c_editRoster__invites">
      <app-team-invites-block></app-team-invites-block>
    </div>
    <div class="c_editRoster__roster">
      <app-team-roster-edit-block></app-team-roster-edit-block>
    </div>
  </ng-container>
  <ng-template #loading>
    <app-loader [useWhite]="true">Loading Roster...</app-loader>
  </ng-template>
</app-page>
