import {
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { LoginService } from "@services/login.service";
import { Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import {
  Observable,
  of,
  zip,
} from "rxjs";
import {
  first,
  map,
  mergeMap,
  take,
  takeUntil,
} from "rxjs/operators";

import { RootState } from "src/app/reducers";
import {
  LoginUser,
  LogOutUser,
  LoginNewUser,
  StashLoginRoute,
} from "src/app/reducers/user/user.actions";
import { UserLogin } from "src/app/reducers/user/user.types";
import {
  generateTeamInstantInviteRoute,
  routePaths,
  userRoutes,
} from "src/app/enums/routes/routePaths";
import { ComponentRouteParams } from "src/app/enums/routes/routeParams";
import { Unsubscriber } from "src/app/util/unsubscriber";
import { FormController } from "src/app/components/form-controller/form-controller.abstract";
import { SessionStorageKeys } from "src/app/enums/session-storage-keys.enum";

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"],
  providers: [LoginService],
})
export class LoginPageComponent extends FormController implements OnInit, OnDestroy {
  public loginFormGroup: FormGroup;
  public isLoggingIn$: Observable<boolean>;
  public isRedirect$: Observable<boolean>;
  public isReauth$: Observable<boolean>;
  public isSignupConfirmation$: Observable<boolean>;
  public isInstantInviteRedirect$: Observable<boolean>;

  public userRoutes = userRoutes;
  public signupRoute = `/${routePaths.SIGN_UP}`;

  private _redirectUrl = `/${userRoutes.LOGIN_REDIRECT}`;
  private _reauthUrl = `/${routePaths.LOGIN_EXPIRED}`;
  private _unsub = new Unsubscriber();

  constructor(private _route: ActivatedRoute, private _store: Store<RootState>, private _titleService: Title) {
    super();
    this.loginFormGroup = new FormGroup({
      email: new FormControl("", [Validators.email, Validators.required]),
      password: new FormControl("", [Validators.required]),
    });

    this.isLoggingIn$ = this._store.select("user", "isLoggingIn").pipe(takeUntil(this._unsub.unsubEvent));
    this.isRedirect$ = this._store.select("router", "state", "url").pipe(
      map((url) => url === this._redirectUrl),
      takeUntil(this._unsub.unsubEvent)
    );
    this.isReauth$ = this._store.select("router", "state", "url").pipe(
      map((url) => url === this._reauthUrl),
      takeUntil(this._unsub.unsubEvent)
    );
    this.isSignupConfirmation$ = this._route.paramMap.pipe(
      take(1),
      map((paramMap) => {
        const paramVal = paramMap.get(ComponentRouteParams.LOGIN_REDIRECT);
        if (paramVal === ComponentRouteParams.SIGNUP_SUCCESS) {
          return true;
        }

        return false;
      })
    );
    this.isInstantInviteRedirect$ = this.isRedirect$.pipe(
      take(1),
      mergeMap((isRedirect) => {
        if (isRedirect) {
          return this._store.select("user", "userLoginRedirectRoute").pipe(
            map((redirectRoute) => {
              if (redirectRoute) {
                // Check if the redirect url is a invite url
                if (redirectRoute.indexOf(`/${generateTeamInstantInviteRoute("")}`) === 0) {
                  return true;
                }
              }

              return false;
            })
          );
        }

        return of(false);
      })
    );

    this._loadForm(this.loginFormGroup);
    this._store.dispatch(new LogOutUser());
  }

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | Login");
    this.isReauth$.pipe(
      first()
    ).subscribe(
      //SUCCESS
      (isReauth) => {
        if (isReauth) {
          const reauthUrl = localStorage.getItem(SessionStorageKeys.REAUTH_REDIRECT);
          if (reauthUrl) {
            localStorage.removeItem(SessionStorageKeys.REAUTH_REDIRECT);
            this._store.dispatch(new StashLoginRoute(reauthUrl));
          }
        }
      }
    );
  }

  public ngOnDestroy() {
    this._unsub.kill();
  }

  public loginUser(): void {
    if (this._canProceed()) {
      const { email, password } = this.loginFormGroup.value;
      const payload: UserLogin = {
        email,
        password,
      };

      zip(this.isRedirect$, this.isSignupConfirmation$)
        .pipe(
          take(1),
          map((loginCases) => ({
            isRedirect: loginCases[0],
            isSignup: loginCases[1],
          }))
        )
        .subscribe((loginCases) => {
          const { isRedirect, isSignup } = loginCases;
          if (isRedirect) {
            this._store.dispatch(
              new LoginUser({
                credentials: payload,
                isRedirect: true,
                isSignup: false,
              })
            );
          } else if (isSignup) {
            this._store.dispatch(
              new LoginNewUser({
                credentials: payload,
              })
            );
          } else {
            this._store.dispatch(
              new LoginUser({
                credentials: payload,
              })
            );
          }
        });
    }
  }
}
