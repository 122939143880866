import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { debounceTime, takeUntil } from "rxjs/operators";

import { Unsubscriber } from "@utils/unsubscriber";
import { LeagueSeasonPass } from "@apptypes/season-pass/season-pass.types";

import { DashboardClubs } from "src/app/reducers/dashboard";

@Component({
  selector: "app-league-pass-list",
  templateUrl: "./league-pass-list.component.html",
  styleUrls: ["./league-pass-list.component.scss"],
})
export class LeaguePassListComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public userClubs: DashboardClubs[] = [];
  @Input() public readonly seasonPassList: LeagueSeasonPass[] = [];
  public filteredPassList: LeagueSeasonPass[] = [];

  public filterForm: FormGroup = new FormGroup({
    filterTerm: new FormControl(""),
  });

  private _unsub = new Unsubscriber();

  public ngOnInit(): void {
    this.filteredPassList = [...this.seasonPassList];
  }

  public ngAfterViewInit(): void {
    this.filterForm.valueChanges.pipe(
      debounceTime(900),
      takeUntil(this._unsub.unsubEvent)
    ).subscribe(formValues => this.filterPassList(formValues.filterTerm));
  }

  public filterPassList(filterTerm: string): void {
    this.filteredPassList = [...this.seasonPassList].filter(pass =>
      pass.title.toLowerCase().includes(filterTerm.toLowerCase())
    );
  }

  public ngOnDestroy() {
    this._unsub.kill();
  }

  public get noResultsFilter(): boolean {
    return this.filterForm.dirty && this.filteredPassList.length === 0;
  }
}
