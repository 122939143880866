<app-page>
  <div class="c_notFoundPage">
    <div class="c_notFoundPage__icon">
      <i class="fas fa-exclamation-triangle fa-8x"></i>
    </div>
    <div class="c_notFoundPage__text u_txt--hdg2">
      We can't find the page you're looking for...
    </div>
    <div class="c_notFoundPage__homeBtn">
      <a routerLink="/{{ routes.HOME }}" class="c_button primary_dark"
        >Return to Homepage</a
      >
    </div>
  </div>
</app-page>
