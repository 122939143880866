/* eslint-disable @typescript-eslint/no-explicit-any */
import { environment } from "@environments/environment";
import { format } from "date-fns";
export class Logger {
  public static error = (msg: any): void => {
    if (!environment.production || (window as any).gglShowErrors) {
      const logDate = format(new Date(), "[MM-dd-yy][HH:mm:SS]");
      console.error(`[ERROR]${logDate}`);
      console.error(msg);
    }
  };

  public static log = (msg: any): void => {
    if (!environment.production || (window as any).gglShowErrors) {
      const logDate = format(new Date(), "[MM-dd-yy][HH:mm:SS]");
      console.log(`[LOG]${logDate}`);
      console.log(msg);
    }
  };

  public static warn = (msg: any): void => {
    if (!environment.production || (window as any).gglShowErrors) {
      const logDate = format(new Date(), "[MM-dd-yy][HH:mm:SS]");
      console.warn(`[WARN]${logDate}`);
      console.warn(msg);
    }
  };
}
