import { Component, Input } from "@angular/core";

import { getExtendedDate } from "src/app/util/date-utils";
import { ChatMessage } from "src/app/reducers/matches/matches.types";
import { Teams } from "src/app/enums/team.enum";
import { PlayerGuides } from "src/app/enums/resources/document-links/player-guides.enum";

@Component({
  selector: "app-chat-message",
  templateUrl: "./chat-message.component.html",
  styleUrls: ["./chat-message.component.scss"],
})
export class ChatMessageComponent {
  public static SYSTEM_MESSAGE_ID = -999;

  @Input() public message: ChatMessage;
  public teams = Teams;
  public playerGuides = PlayerGuides;

  public get isSystemMessage(): boolean {
    return this.message.userId === ChatMessageComponent.SYSTEM_MESSAGE_ID;
  }

  public formatDate(date: number): string {
    return getExtendedDate(date);
  }
}
