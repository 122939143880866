<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
></app-modal-container>

<ng-template #title>
  <app-modal-title>Need Help Finding Your {{ esportTitle }}?</app-modal-title>
</ng-template>
<ng-template #content>
  <p class="u_txt">
    Please use the links below to figure out your {{ esportTitle }}.
  </p>
  <ng-container *ngIf="helpLinks.length > 0; else noLinks">
    <ng-container *ngFor="let link of helpLinks">
      <a class="u_txt--link--dark" [href]="link" target="_blank">{{ link }}</a
      ><br />
    </ng-container>
  </ng-container>
  <ng-template #noLinks>
    <p class="u_txt">
      Please refer to your game's manual for instruction on how to find your
      tag.
    </p>
  </ng-template>
</ng-template>
