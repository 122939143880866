<button class="c_textButton" (click)="onToggleClick($event)">
  <ng-container [ngSwitch]="toggleBoolean">
    <ng-container *ngSwitchCase="true">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
      >
        <g fill="none" fill-rule="evenodd">
          <g fill="#000">
            <g>
              <path
                d="M-.808 16.192l41.659.528c1.898.024 3.431 1.557 3.455 3.455l.002.133c.024 1.909-1.504 3.476-3.413 3.5h-.087L-.851 23.28c-1.898-.024-3.431-1.557-3.455-3.455l-.002-.133c-.024-1.909 1.504-3.476 3.413-3.5h.087z"
                transform="translate(-260 -20) translate(260 20) rotate(45 20 20)"
              />
              <path
                d="M-.85 16.72l41.658-.528c1.909-.024 3.476 1.504 3.5 3.413v.087l-.002.133c-.024 1.898-1.557 3.431-3.455 3.455l-41.659.528c-1.909.024-3.476-1.504-3.5-3.413v-.087l.002-.133c.024-1.898 1.557-3.431 3.455-3.455z"
                transform="translate(-260 -20) translate(260 20) rotate(135 20 20)"
              />
            </g>
          </g>
        </g>
      </svg>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="49"
        viewBox="0 0 60 49"
      >
        <defs>
          <filter
            id="6zhjs17fwa"
            width="105%"
            height="120%"
            x="-2.5%"
            y="-10%"
            filterUnits="objectBoundingBox"
          >
            <feOffset
              dx="2"
              dy="4"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            />
            <feColorMatrix
              in="shadowOffsetOuter1"
              result="shadowMatrixOuter1"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
            />
            <feMerge>
              <!-- <feMergeNode in="shadowMatrixOuter1"/> -->
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <g
            fill="#000"
            filter="url(#6zhjs17fwa)"
            transform="translate(-250 -16)"
          >
            <g transform="translate(256 22)">
              <rect width="48" height="7" rx="3.5" />
              <rect width="48" height="7" y="15" rx="3.5" />
              <rect width="48" height="7" y="30" rx="3.5" />
            </g>
          </g>
        </g>
      </svg>
    </ng-container>
  </ng-container>
</button>
