import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

import { PlayerGuides } from "src/app/enums/resources/document-links/player-guides.enum";
import {
  ELITE_RULE_LINKS,
  REC_RULE_LINKS,
  WEEKLY_RULE_LINKS,
} from "src/app/enums/rulebooks.enum";

@Component({
  selector: "app-rules-page",
  templateUrl: "./rules-page.component.html",
  styleUrls: ["./rules-page.component.scss"],
})
export class RulesPageComponent implements OnInit {
  public playerGuides = PlayerGuides;

  public rulesLinks = REC_RULE_LINKS;
  public eliteRulesLink = ELITE_RULE_LINKS;
  public weeklyRulesLinks = WEEKLY_RULE_LINKS;

  constructor(private _titleService: Title) { }

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | Rules");
  }
}
