import {
  Component,
  forwardRef,
  Input,
  OnInit,
} from "@angular/core";
import {
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

import { RegistrationOrganization } from "src/app/reducers/leagues/league.types";
import { BaseCVA } from "src/app/components/base-cva/base-cva.class";
import { Unsubscriber } from "src/app/util/unsubscriber";
import { JoinLeagueModalService } from "src/app/stateful-services/join-league-modal/join-league-modal.service";

@Component({
  selector: "app-referral-choice-step",
  templateUrl: "./referral-choice-step.component.html",
  styleUrls: ["./referral-choice-step.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReferralChoiceStepComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReferralChoiceStepComponent),
      multi: true,
    },
  ],
})
export class ReferralChoiceStepComponent
  extends BaseCVA
  implements OnInit, Validator {
  @Input() public regInfoReferrals: RegistrationOrganization[] = [];
  public referralList = [];
  public referralFilterInput$ = new Subject<string>();

  private _unsub = new Unsubscriber();

  constructor(private _formBuilder: FormBuilder, private _joinLeagueModalService: JoinLeagueModalService) {
    super();
    this.controlForm = this._formBuilder.group({
      selectedReferral: [null, Validators.required],
    });
  }

  public ngOnInit(): void {
    this.referralList = this.regInfoReferrals;

    this.referralFilterInput$
      .pipe(debounceTime(200), takeUntil(this._unsub.unsubEvent))
      .subscribe((searchTerm) => {
        if (searchTerm) {
          const searchTermNoCase = searchTerm.toUpperCase();
          this.referralList = this.regInfoReferrals.filter(
            (referral) =>
              referral.name.toUpperCase().indexOf(searchTermNoCase) !== -1
          );
        } else {
          this.referralList = this.regInfoReferrals;
        }
      });
  }

  /**
   * Evaluates if a given referral is currently selected by the radio button
   *
   * @param referralId is the id of the referral organization
   * @author Christian Tweed
   */
  public isReferralSelected(referralId: string): boolean {
    const referral = this.controlForm.get("selectedReferral").value;
    if (referral) {
      return referral.id === referralId;
    }
    return false;
  }

  public get errorMessage(): string {
    return this.controlForm.invalid ? "No referral selected" : "";
  }

  public get isFirstStep(): boolean {
    return this._joinLeagueModalService.isReferralStepFirst;
  }

  /* Validator Functions */

  public validate(): ValidationErrors | null {
    return this.controlForm.valid ?
      null :
      {
        invalidForm: {
          valid: false,
          message: "No referral selected",
        },
      };
  }
}
