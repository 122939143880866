import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "@environments/environment";

import { basicAuthHeader } from "../util/auth-utils";

@Injectable({
  providedIn: "root",
})
export class PasswordService {
  constructor(private _http: HttpClient) { }

  public generateResetPasswordEmail(userEmail: string): Observable<unknown> {
    const url = `${environment.apiBase}/api/password`;
    const headers = basicAuthHeader();
    const payload = {
      api_user: {
        email: userEmail,
      },
    };
    return this._http.post(url, payload, {
      headers,
    });
  }

  public setNewUserPassword(password: string, token: string): Observable<{ success?: boolean }> {
    const url = `${environment.apiBase}/api/password`;
    const headers = basicAuthHeader();
    const payload = {
      api_user: {
        reset_password_token: token,
        password,
        confirm_password: password,
      },
    };
    return this._http.patch(url, payload, {
      headers,
    });
  }
}
