import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import {
  combineLatest,
  Observable,
  of,
} from "rxjs";
import {
  filter,
  first,
  map,
  takeUntil,
} from "rxjs/operators";
import { NgxSmartModalService } from "ngx-smart-modal";

import { Unsubscriber } from "@utils/unsubscriber";
import { modalOptions } from "@utils/modal-helpers";
import {
  QuickPlayEventStatus,
  QuickPlayEventStreamCheckin,
  QuickPlayEventStreamData,
} from "@apptypes/quick-play-events.types";
import { QuickPlayEventsService } from "@services/quick-play-events/quick-play-events.service";

import { RootState } from "src/app/reducers";
import { GenericLeagueDetails } from "src/app/reducers/leagues/league.types";
import { UserProfile, UserTeam } from "src/app/reducers/user/user.types";
// eslint-disable-next-line max-len
import { BattleRoyaleCheckinModalComponent, QuickPlayCheckinInfo } from "../battle-royale-checkin-modal/battle-royale-checkin-modal.component";
import { ParticipantListModalComponent, ParticipantListTeamModalData } from "../participant-list-modal/participant-list-modal.component";

const QuickPlayEventCheckinStatusLabel: { [key in QuickPlayEventStatus] } = {
  [QuickPlayEventStatus.PENDING]: "Tournament is Accepting Check-Ins",
  [QuickPlayEventStatus.IN_PROGRESS]: "Tournament is In Progress. Check-Ins are Closed",
  [QuickPlayEventStatus.COMPLETE]: "Tournament has Completed",
};

const QuickPlayEventCheckinDisabledMessage: { [key in QuickPlayEventStatus] } = {
  [QuickPlayEventStatus.PENDING]: "",
  [QuickPlayEventStatus.IN_PROGRESS]: "The tournament has started, check-ins are closed",
  [QuickPlayEventStatus.COMPLETE]: "The tournament has been played to completion",
};

@Component({
  selector: "app-quickplay-checkin-panel",
  templateUrl: "./quickplay-checkin-panel.component.html",
  styleUrls: ["./quickplay-checkin-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickplayCheckinPanelComponent implements OnInit, OnDestroy {
  public activeTeam$: Observable<UserTeam | null> = of(null);
  public user$: Observable<UserProfile | null> = of(null);
  public league$: Observable<GenericLeagueDetails | null> = of(null);
  public quickPlayEventData$: Observable<QuickPlayEventStreamData | null> = of(null);

  public status$: Observable<string> = of(QuickPlayEventCheckinStatusLabel.complete);
  public isCheckinEnabled$: Observable<boolean> = of(false);
  public disabledMessage$: Observable<string> = of(QuickPlayEventCheckinDisabledMessage.complete);
  public isUserCheckedIn$: Observable<boolean> = of(false);
  public checkinCount$: Observable<number> = of(0);

  public statusLabels = QuickPlayEventCheckinStatusLabel;
  public disabledMessages = QuickPlayEventCheckinDisabledMessage;

  private _unsub = new Unsubscriber();

  constructor(
    private _store: Store<RootState>,
    private _modalService: NgxSmartModalService,
    private _quickPlayEventService: QuickPlayEventsService
  ) {}

  public ngOnInit(): void {
    this.user$ = this._store.select("user", "currentUser").pipe(
      first()
    );

    this.league$ = this._store.select("leagues", "league").pipe(
      first()
    );

    this.activeTeam$ = combineLatest([this.user$, this.league$]).pipe(
      filter(([user, league]) => !!user && !!league),
      map(([user, league]) => {
        const leagueTeamIds = league.teams.map(team => team.id);
        return user.teams.find(({ id }) => leagueTeamIds.indexOf(id) !== -1);
      }),
      first()
    );

    this.quickPlayEventData$ = this._quickPlayEventService.currentQuickPlayEventStreamData$.pipe(
      takeUntil(this._unsub.unsubEvent)
    );

    this.checkinCount$ = this.quickPlayEventData$.pipe(
      map(({ checkins }) => checkins.length)
    );

    this.isUserCheckedIn$ = combineLatest([this.user$, this.quickPlayEventData$]).pipe(
      map(([user, { checkins }]) => this._isUserCheckedIn(user, checkins))
    );

    this.disabledMessage$ = this.quickPlayEventData$.pipe(
      map(({ quickPlayEvent }) => QuickPlayEventCheckinDisabledMessage[quickPlayEvent.status])
    );

    this.isCheckinEnabled$ = this.quickPlayEventData$.pipe(
      map(({ quickPlayEvent }) => quickPlayEvent.status === QuickPlayEventStatus.PENDING)
    );

    this.status$ = this.quickPlayEventData$.pipe(
      map(({ quickPlayEvent }) => QuickPlayEventCheckinStatusLabel[quickPlayEvent.status])
    );
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public openCheckinModal(): void {
    const constructQuickPlayCheckinInfo = (
      { quickPlayEvent }: QuickPlayEventStreamData, team: UserTeam, { esport }: GenericLeagueDetails
    ): QuickPlayCheckinInfo => ({
      quickPlayEventID: quickPlayEvent.id,
      teamId: team.id.toString(),
      esport,
      type: "tournament",
    });

    combineLatest([
      this.quickPlayEventData$,
      this.activeTeam$,
      this.league$,
    ]).pipe(
      map(([
        quickPlayEventData,
        team,
        league,
      ]) => constructQuickPlayCheckinInfo(quickPlayEventData, team, league)
      ),
      first()
    ).subscribe((checkinInfo) => this._modalService.create(
      BattleRoyaleCheckinModalComponent.MODAL_NAME,
      BattleRoyaleCheckinModalComponent,
      modalOptions
    ).setData(checkinInfo).open());
  }

  public viewCheckedInTeams(checkins: QuickPlayEventStreamCheckin[], league: GenericLeagueDetails): void {
    const mapTeamToParticipantTeam = (
      { teamID: teamId, esportCredentialSnapshot }: QuickPlayEventStreamCheckin
    ): ParticipantListTeamModalData | null => {
      const leagueTeam = league.teams.find(team => `${team.id}` === teamId);

      if (!leagueTeam) {
        return null;
      }

      const { title: teamName, logoUrl: teamLogoUrl } = leagueTeam;

      return {
        teamId,
        teamName,
        teamLogoUrl,
        subtext: esportCredentialSnapshot,
      };
    };

    const teamList = checkins.map(mapTeamToParticipantTeam).filter(team => !!team);

    this._modalService.create(
      ParticipantListModalComponent.MODAL_ID,
      ParticipantListModalComponent,
      modalOptions
    ).setData(teamList).open();
  }

  private _isUserCheckedIn = (user: UserProfile | null, checkins: QuickPlayEventStreamCheckin[] | null = []): boolean => {
    if(user === null || checkins === null){
      return false;
    }
    const userID = `${user.id}`;
    return checkins.find((checkin) => checkin.userID === userID) !== undefined;
  };
}
