import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";

import { basicAuthHeader } from "../../util/auth-utils";
import {
  Organization,
  OrganizationLeague,
  OrganizationSearchQuery,
  OrganizationSeasonPass,
  SearchOrganization,
} from "../../reducers/organization/organization.types";
import { apiToPlatform } from "../../enums/game-platforms.enum";
import {
  OrganizationAPIResponse,
  OrganizationAPILeague,
  OrganizationSearchTerms,
  OrganizationSearchAPIResponse,
  defaultOrganizationSearchQuery,
  OrganizationAPISeasonPass,
} from "./organization.api.types";
import {
  readSearchHeaders,
  SearchHeaders,
  setupParams,
} from "src/app/util/search-utils";
import { Logger as logger } from "../../util/logger";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class OrganizationService {
  constructor(private _http: HttpClient) { }

  /** Gets an organization by slug identifier or id.
   *
   * @param id is either a uuid or a slug identifier
   * @author Christian Tweed
   */
  public getOrganization(id: string): Observable<Organization> {
    const headers = basicAuthHeader();
    const url = `${environment.apiBase}/api/v1/organizations/${id}`;

    return this._http
      .get<OrganizationAPIResponse>(url, {
      headers,
    })
      .pipe(map(this._mapOrganizationAPIResponseToOrganization));
  }

  /**
   * Takes a searchTerms and searches the api for matching organizations
   *
   * @param searchTerms (name, page, limit)
   * @author Christian Tweed
   */
  public organizationSearch(searchTerms: OrganizationSearchTerms): Observable<OrganizationSearchQuery> {
    const { params, headers }: SearchHeaders = setupParams(searchTerms);
    const url = `${environment.apiBase}/api/v2/search/organizations/search`;

    return this._http
      .get<OrganizationSearchAPIResponse>(url,
      {
        headers,
        params,
        observe: "response",
      }
    )
      .pipe(
        map((apiResponse) => {
          const { page, pageLimit, limit, total } = readSearchHeaders(apiResponse.headers);
          this._mapSearchOrganizationAPIResponseToOrganizationSearch(apiResponse.body);
          return {
            limit,
            page,
            pageLimit,
            total,
            organizations: this._mapSearchOrganizationAPIResponseToOrganizationSearch(apiResponse.body),
          };
        }),
        //HOTFIX CH3810
        map((formattedRes) => ({
          ...formattedRes,
          organizations: formattedRes.organizations.filter((org) => (org.id !== "343" && org.id !== "273")),
        })),
        catchError((err) => {
          logger.error(err);
          return of(defaultOrganizationSearchQuery);
        })
      );
  }

  /**
   * Takes an apiResponse and formats the object for usability
   *
   * @param apiResponse is the unformatted response
   * @author Christian Tweed
   */
  private _mapOrganizationAPIResponseToOrganization = ({ data: apiOrganization }: OrganizationAPIResponse): Organization => {
    const {
      name,
      leagues: apiLeagues,
      logoUrl,
      twitterUrl,
      facebookUrl,
      instagramUrl,
      websiteUrl,
      seasonPassesAcceptingRegistration: apiSeasonPassesAcceptingRegistration,
      availableEsports: apiAvailableEsports,
    } = apiOrganization.attributes;

    const mapLeagues = ({ id, type, attributes }: OrganizationAPILeague): OrganizationLeague => {
      const { esport: apiEsport } = attributes;
      const esport = apiToPlatform(apiEsport);
      return {
        id,
        type,
        ...attributes,
        esport,
      };
    };

    const mapSeasonPasses = ({ id, type, attributes }: OrganizationAPISeasonPass): OrganizationSeasonPass => ({
      id,
      type,
      ...attributes,
    });

    const leagues = apiLeagues.data.map(mapLeagues);
    const seasonPassesAcceptingRegistration = apiSeasonPassesAcceptingRegistration.data.map(mapSeasonPasses);
    const availableEsports = apiAvailableEsports.data.map(apiEsport => apiToPlatform(apiEsport.attributes.name));

    return {
      id: apiOrganization.id,
      type: apiOrganization.type,
      name,
      logoUrl,
      websiteUrl,
      twitterUrl,
      facebookUrl,
      instagramUrl,
      leagues,
      seasonPassesAcceptingRegistration,
      availableEsports,
    };
  };

  /**
   * Takes an apiResponse and formats the search result for usability
   *
   * @param apiResponse is the unformatted search response
   * @author Christian Tweed
   */
  private _mapSearchOrganizationAPIResponseToOrganizationSearch(apiResponse: OrganizationSearchAPIResponse): SearchOrganization[] {
    if (apiResponse) {
      return apiResponse.data.map((apiOrganization) => {
        const { slug, state, name, logoUrl } = apiOrganization.attributes;
        return {
          slug,
          state,
          name,
          logoUrl,
          id: apiOrganization.id,
          type: apiOrganization.type,
        };
      });
    }

    return [];
  }
}
