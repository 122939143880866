/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { modalOptions } from "src/app/util/modal-helpers";
import { ModalController } from "../modal-controller/modal-controller.abstract";

type GenericModal = ModalController<any>;

@Component({
  selector: "app-info-modal-button",
  templateUrl: "./info-modal-button.component.html",
  styleUrls: ["./info-modal-button.component.scss"],
})
export class InfoModalButtonComponent {
  @Input() public modalId: string;
  @Input() public modalComponent: GenericModal;

  constructor(private _modal: NgxSmartModalService) { }

  public openInfoModal(): void {
    this._modal.create(this.modalId, this.modalComponent as any, modalOptions).open();
  }
}
