import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import {
  catchError,
  map,
  switchMap,
} from "rxjs/operators";

import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-utils";
import { Logger } from "@utils/logger";

import {
  BRTournamentScoresAPIResponse,
  JoinLeagueAPIResponseSuccess,
  LeagueAPIMatch,
  LeagueAPIMatchTeam,
  LeagueAPIMatchTournament,
  TournamentCheckinParams,
  TournamentCheckinSuccess,
  V2LeagueDetailsAPIResponse,
  V2LeagueLayoutManifestAPIResponse,
  V2LeagueTeamsAPIResponse,
  V2LeagueTeamStandingsAPIResponse,
  V2LeagueTournamentsAPIResponse,
} from "./leagues.v2.api.types";
import { RankedTeamScore, TeamTypes } from "src/app/reducers/teams/teams.types";
import {
  EliminationBracketDataAPIResponse,
  GenericLeagueDetails,
  LeagueSeriesMatchupMatchupMapping,
  LeagueSeriesMatchupTeam,
  LeagueSeriesMatchupTournament,
  LeagueSeriesMatchupV2,
  LeagueTeam,
  GenericLeagueBracket,
  LeagueBracketDetails,
  LeagueTeamStanding,
  LeagueQueueBracketDetails,
  LeagueRegistrationConfig,
  LeagueLayoutManifest,
} from "src/app/reducers/leagues/league.types";
import { apiToPlatform } from "src/app/enums/game-platforms.enum";
import { APITournamentTypes } from "src/app/enums/tournamentTypes.enum";

@Injectable({
  providedIn: "root",
})
export class LeaguesV2Service {
  constructor(
    private _http: HttpClient
  ) { }

  public getLeagueDetailsUnauthenticatede(leagueId: string | number): Observable<GenericLeagueDetails> {
    const url = `${environment.apiBase}/api/v2/leagues/${leagueId}`;
    const headers = basicAuthHeader();
    return this._http.get<{ data: V2LeagueDetailsAPIResponse }>(url, {
      headers,
    }).pipe(
      map((res) => this._mapV2League(res.data))
    );

  }

  public getLeagueDetailsAuthenticated(leagueId: string | number): Observable<GenericLeagueDetails> {
    const url = `${environment.apiBase}/api/v2/leagues/${leagueId}`;
    const headers = basicAuthHeader();
    return this._http.get<{ data: V2LeagueDetailsAPIResponse }>(url, {
      headers,
    }).pipe(
      map((res) => this._mapV2League(res.data)),
      switchMap((baseLeague) => forkJoin([
        this.getLeagueTournaments(leagueId),
        this.getLeagueQueuesTournaments(leagueId), // TODO: Get this mapped to an all leagues service
        this.getLeagueMatches(leagueId),
        this.getLeagueTeamList(leagueId),
      ]).pipe(
        map(([
          brackets,
          queueBrackets,
          series,
          teams,
        ]) => ({
          ...baseLeague,
          brackets,
          queueBrackets,
          series,
          teams,
        }))
      ))
    );
  }

  public getLeagueTeamList(leagueId: string | number): Observable<LeagueTeam[]> {
    const url = `${environment.apiBase}/api/v2/leagues/${leagueId}/teams`;
    const headers = basicAuthHeader();
    return this._http.get<{ data: V2LeagueTeamsAPIResponse[] }>(url, {
      headers,
    }).pipe(
      map((res) => this._mapV2TeamsList(res.data))
    );
  }

  public getLeagueMatches(leagueId: string | number): Observable<LeagueSeriesMatchupV2[]> {
    const mapLeagueMatchAPITeam = ({ id, attributes: { title, teamType } }: LeagueAPIMatchTeam): LeagueSeriesMatchupTeam => ({
      id,
      title,
      teamType,
    });

    const mapLeagueSeriesMatchupTournament = (
      { id, type, attributes: { schedulingType, title } }: LeagueAPIMatchTournament
    ): LeagueSeriesMatchupTournament => ({
      id,
      type,
      schedulingType,
      title,
    });

    const mapMatchupMapping = ({
      round,
      series_matchup_id: seriesMatchupId,
      team_one,
      team_two,
      winner,
      match_mapper: matchupMapper,
    }: EliminationBracketDataAPIResponse): LeagueSeriesMatchupMatchupMapping => ({
      round,
      seriesMatchupId,
      winnerId: winner?.toString() ?? null,
      //incase we get weird null ids on some matchups
      teamIds: [team_one?.toString() ?? null, team_two?.toString() ?? null].filter(id => !!id),
      matchupMapper,
    });

    const mapLeagueMatch = ({
      id,
      type,
      attributes: {
        matchupStartTime: gameStartTime,
        teams: { data: teams },
        winner,
        winnerId,
        tournament: { data: apiTournament },
        matchupMapping,
      },
    }: LeagueAPIMatch): LeagueSeriesMatchupV2 => ({
      id,
      type,
      gameStartTime,
      teams: teams.map(mapLeagueMatchAPITeam),
      winner,
      winnerId: winnerId?.toString() ?? null,
      tournament: mapLeagueSeriesMatchupTournament(apiTournament),
      matchupMapping: !!matchupMapping ? mapMatchupMapping(matchupMapping) : null,
    });

    const mapData = ({ data }: { data: LeagueAPIMatch[] }): LeagueSeriesMatchupV2[] => data.map(mapLeagueMatch);

    const url = `${environment.apiBase}/api/v2/leagues/${leagueId}/series_matchups`;
    const options = {
      headers: basicAuthHeader(),
    };
    return this._http.get<{ data: LeagueAPIMatch[] }>(url, options).pipe(
      map(mapData)
    );
  }

  public getLeagueStandingsList(leagueId: string | number): Observable<LeagueTeamStanding[]> {
    const url = `${environment.apiBase}/api/v2/leagues/${leagueId}/teams/records_for_league`;
    const headers = basicAuthHeader();
    return this._http.get<V2LeagueTeamStandingsAPIResponse[]>(url, {
      headers,
    }).pipe(
      map((res) => this._mapV2Standings(res))
    );
  }

  public getLeagueTournaments(leagueId: string | number): Observable<LeagueBracketDetails[]> {
    const url = `${environment.apiBase}/api/v2/leagues/${leagueId}/tournaments`;
    const headers = basicAuthHeader();
    return this._http.get<{ data: V2LeagueTournamentsAPIResponse[] }>(url, {
      headers,
    }).pipe(
      map((res) => {
        const nonQueueTournaments = res.data.filter(
          (tournament) => tournament.attributes.schedulingType !== APITournamentTypes.ROUND_ROBIN_QUEUE
        );
        return this._mapV2Brackets(nonQueueTournaments);
      })
    );
  }

  public getQuickplayTournaments(leagueId: string | number): Observable<LeagueBracketDetails[]> {
    const url = `${environment.apiBase}/api/v2/leagues/${leagueId}/tournaments`;
    const headers = basicAuthHeader();
    return this._http.get<{ data: V2LeagueTournamentsAPIResponse[] }>(url, {
      headers,
    }).pipe(
      map((res) => {
        const quickplayTournaments = res.data.filter(
          (tournament) => (
            tournament.attributes.schedulingType !== APITournamentTypes.ROUND_ROBIN_QUEUE &&
            tournament.attributes.schedulingType !== APITournamentTypes.BATTLE_ROYALE
          )
        );
        return this._mapV2Brackets(quickplayTournaments);
      })
    );
  }

  public getLeagueQueuesTournaments(leagueId: string | number): Observable<LeagueQueueBracketDetails[]> {
    const url = `${environment.apiBase}/api/v2/leagues/${leagueId}/tournaments`;
    const headers = basicAuthHeader();
    return this._http.get<{ data: V2LeagueTournamentsAPIResponse[] }>(url, {
      headers,
    }).pipe(
      map((res) => {
        const queueTournaments = res.data.filter(
          (tournament) => tournament.attributes.schedulingType === APITournamentTypes.ROUND_ROBIN_QUEUE
        );
        return this._mapV2QueueBrackets(queueTournaments);
      })
    );
  }

  public getLeagueBrackets(leagueId: string | number): Observable<LeagueBracketDetails[]> {
    const url = `${environment.apiBase}/api/v2/leagues/${leagueId}/tournaments`;
    const headers = basicAuthHeader();
    return this._http.get<{ data: V2LeagueTournamentsAPIResponse[] }>(url, {
      headers,
    }).pipe(
      map((res) => {
        const bracketTournaments = res.data.filter((tournament) => tournament.attributes.schedulingType === APITournamentTypes.ELIMINATION);
        return this._mapV2Brackets(bracketTournaments);
      })
    );
  }

  public getLeagueBRTournament(leagueId: string | number): Observable<LeagueBracketDetails[]> {
    const url = `${environment.apiBase}/api/v2/leagues/${leagueId}/tournaments`;
    const headers = basicAuthHeader();
    return this._http.get<{ data: V2LeagueTournamentsAPIResponse[] }>(url, {
      headers,
    }).pipe(
      map((res) => {
        const bracketTournaments = res.data.filter(
          (tournament) => tournament.attributes.schedulingType === APITournamentTypes.BATTLE_ROYALE
        );
        return this._mapV2Brackets(bracketTournaments);
      })
    );
  }

  public checkIntoQuickplayLeague(tournamentId: string | number, teamId: string | number): Observable<TournamentCheckinSuccess | null> {
    const url = `${environment.apiBase}/api/v2/tournaments/${tournamentId}/tournament_checkins`;
    const options = {
      headers: basicAuthHeader(),
    };
    const payload: TournamentCheckinParams = {
      tournament_checkin: {
        team_id: teamId,
      },
    };
    return this._http.post<TournamentCheckinSuccess | null>(url, payload, options);
  }

  public joinLeague(leagueId: string | number, teamId: string | number): Observable<JoinLeagueAPIResponseSuccess> {
    const url = `${environment.apiBase}/api/v2/leagues/${leagueId}/teams/${teamId}/join_league`;
    const headers = basicAuthHeader();
    return this._http.post<JoinLeagueAPIResponseSuccess>(url, null, {
      headers,
    });
  }

  /**
   * Returns sorted list of ranked team scores for a given tournament ID
   *
   * @param tournamentId
   * @return
   * @memberof LeaguesV2Service
   */
  public getBRTournamentScores(tournamentId: string): Observable<RankedTeamScore[]> {
    const url = `${environment.apiBase}/api/v2/tournaments/${tournamentId}/fortnite_score_report`;
    const headers = basicAuthHeader();
    return this._http.get<{ score_report: BRTournamentScoresAPIResponse[] }>(url, {
      headers,
    }).pipe(
      map((scoreReport) => this._mapBRTournamentScores(scoreReport.score_report)),
      map((formattedScores) => {
        const scoreList = [...formattedScores];

        scoreList.sort((a, b) => {
          if (a.score > b.score) {
            return -1;
          } else if (b.score > a.score) {
            return 1;
          } else {
            return 0;
          }
        });

        return scoreList;
      }),
      catchError((err) => {
        Logger.error(err);
        return [];
      })
    );
  }

  private _mapBRTournamentScores(rawScores: BRTournamentScoresAPIResponse[]): RankedTeamScore[] {
    return rawScores.map((rawScore) => ({
      id: rawScore.team_id,
      score: rawScore.total_score,
      name: rawScore.team_title,
    }));
  }

  private _mapV2League(rawV2League: V2LeagueDetailsAPIResponse): GenericLeagueDetails {
    const { id, type, attributes } = rawV2League;
    return {
      id: parseInt(id, 10),
      type,
      title: attributes.title,
      description: attributes.description,
      defaultTeamFee: attributes.defaultTeamFee,
      finished: attributes.finished,
      maxEntrants: attributes.maxEntrants,
      currentEntrants: attributes.currentEntrantsCount,
      leagueType: attributes.leagueType,
      esport: apiToPlatform(attributes.esport),
      startTime: attributes.startTime,
      registrationEndDate: attributes.registrationEndDate,
      twitchStreamUrl: attributes.twitchStreamUrl,
      registrationConfig: {
      } as unknown as LeagueRegistrationConfig,  // TODO Figure out how to get this
      organizationSeasonPassIds: attributes.organizationSeasonPassIds.map((passId) => passId.toString()),
      teams: [],  // TODO: Figure out what we're going to do with these?  I'm thinking we'll get them from their individual endpoints later
      games: [],
      series: [],
      brackets: [],
      queueBrackets: [],
      teamRegistrationType: attributes.teamRegistrationType as TeamTypes,
      leagueLayoutManifest: (attributes.leagueLayoutManifest.data === null) ?
        null : this._mapV2LeagueLayoutManifest(attributes.leagueLayoutManifest),
    };
  }

  private _mapV2TeamsList(rawV2Teams: V2LeagueTeamsAPIResponse[]): LeagueTeam[] {
    return rawV2Teams.map((v2Team) => {
      const { id, type, attributes } = v2Team;
      return {
        id: parseInt(id, 10),
        type,
        title: attributes.title,
        description: attributes.description,
        wins: 0,
        losses: 0,
        logoUrl: attributes.logoUrl,
        teamType: attributes.teamType,
        userIds: attributes.userIds.map((userId) => userId.toString()),
      };
    });
  }

  private _mapV2Brackets(rawBrackets: V2LeagueTournamentsAPIResponse[]): LeagueBracketDetails[] {
    return rawBrackets.map((rbracket) => ({
      id: rbracket.id,
      type: "tournaments",
      schedulingType: rbracket.attributes.schedulingType,
      title: rbracket.attributes.title,
      status: rbracket.attributes.status,
      chatReferenceId: rbracket.attributes.chatroomReferenceId,
      matchupMapping: rbracket.attributes.matchupMapping.map((tdata) => this._matchupMapper(tdata)),
      streamReferenceId: rbracket.attributes.streamReferenceId,
    }));
  }

  private _mapV2QueueBrackets(rawBrackets: V2LeagueTournamentsAPIResponse[]): LeagueQueueBracketDetails[] {
    return rawBrackets.map((rbracket) => ({
      id: rbracket.id,
      type: "tournaments",
      schedulingType: APITournamentTypes.ROUND_ROBIN_QUEUE,
      title: rbracket.attributes.title,
      status: rbracket.attributes.status,
      chatReferenceId: rbracket.attributes.chatroomReferenceId,
      matchupMapping: rbracket.attributes.matchupMapping.map((tdata) => this._matchupMapper(tdata)),
      streamReferenceId: rbracket.attributes.streamReferenceId,
      queueSessions: rbracket.attributes.tournamentQueueSessions.data.map((qSession) => ({
        id: qSession.id,
        status: qSession.attributes.status,
        startTime: qSession.attributes.startTime,
      })),
    }));
  }

  private _matchupMapper = (tournamentData: EliminationBracketDataAPIResponse): GenericLeagueBracket => ({
    round: tournamentData.round,
    winner: tournamentData.winner,
    teamOne: tournamentData.team_one,
    teamTwo: tournamentData.team_two,
    matchIds: tournamentData.match_ids ? tournamentData.match_ids : null,
    matchMapper: tournamentData.match_mapper,
    seriesMatchupId: tournamentData.series_matchup_id ? tournamentData.series_matchup_id : null,
    playinRoundId: tournamentData.play_in_round_id,
    teamOneSeriesId: tournamentData.team_one_series_id,
    teamTwoSeriesId: tournamentData.team_two_series_id,
  });

  private _mapV2Standings(rawStandings: V2LeagueTeamStandingsAPIResponse[]): LeagueTeamStanding[] {
    return rawStandings.map((rStanding) => {
      const { title, id, record } = rStanding;
      return {
        id,
        title,
        gameLosses: record.game_losses,
        gameWins: record.game_wins,
        seriesLosses: record.series_losses,
        seriesWins: record.series_wins,
      };
    });
  }

  private _mapV2LeagueLayoutManifest(rawManifest: V2LeagueLayoutManifestAPIResponse): LeagueLayoutManifest {
    const sponsorshipWells = rawManifest.data.attributes.imageAssets.data.filter(
      (imgData) => imgData.type === "sponsorshipCampaignContentWells"
    );
    // eslint-disable-next-line object-curly-newline
    const mappedSponsorshipWells = {};
    if (sponsorshipWells.length > 0) {
      sponsorshipWells.forEach((spWell) => {
        mappedSponsorshipWells[spWell.attributes.pageWellReference.toString()] = {
          assetId: spWell.id,
          assetUrl: spWell.attributes.imageUrl,
        };
      });
    }

    return {
      id: rawManifest.data.id,
      sponsorshipCampaignContentWells: {
        ...mappedSponsorshipWells,
      },
    };
  }
}


