import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { take, filter } from "rxjs/operators";

import { TeamDetails } from "src/app/reducers/teams/teams.types";
import { RootState } from "src/app/reducers";

@Component({
  selector: "app-team-stats-block",
  templateUrl: "./team-stats-block.component.html",
  styleUrls: ["./team-stats-block.component.scss"],
})
export class TeamStatsBlockComponent implements OnInit {
  public team$: Observable<TeamDetails>;

  constructor(private _store: Store<RootState>) {}

  public generateWinPercentage(wins: number, matches: number): string {
    if (matches !== 0) {
      const rawPct = (wins / matches) * 100;

      return `${Math.trunc(rawPct)}%`;
    }

    return "N/A";
  }

  public ngOnInit() {
    this.team$ = this._store.select("teams", "currentTeam").pipe(
      filter((teams) => teams !== null),
      take(1)
    );
  }
}
