import { Component } from "@angular/core";

import {
  externalRoutes,
  routePaths,
  staticRoutes,
} from "src/app/enums/routes/routePaths";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  public currentYear = new Date().getFullYear();
  public privacyRoute = `/${routePaths.PRIVACY}`;
  public staticTemplateRoutes = staticRoutes;
  public externalRoutes = externalRoutes;
}
