import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from "@angular/core";
import {
  forkJoin,
  Observable,
  of,
} from "rxjs";
import {
  map,
  catchError,
  first,
  pluck,
  tap,
} from "rxjs/operators";
import { Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";

import { LeagueEndpointService } from "@services/league-endpoints.service";
import { LeaguesV2Service } from "@services/v2/leagues/leagues.v2.service";
import { TeamsV2Service } from "@services/v2/teams/teams.v2.service";

import { RegistrationTypes } from "src/app/enums/registration-types.enum";
import {
  JoinLeagueConfirmationInformation,
  JoinLeagueErrorState,
  JoinLeagueReferralParams,
} from "src/app/reducers/leagues/league.types";
import { ImageTypes, processImage } from "src/app/util/image-utils";
import { TeamTypes } from "src/app/reducers/teams/teams.types";
import { Logger } from "../../util/logger";
import { GetLeagueById } from "src/app/reducers/leagues/league.actions";
import { RootState } from "src/app/reducers";
import { UserEsportsPass } from "src/app/reducers/user/user.types";
import { JoinLeagueModalService } from "src/app/stateful-services/join-league-modal/join-league-modal.service";
import { triggerLeagueRegistrationAnalyticsEvent } from "@utils/analytics/leagues.analytics";
import { LeagueTypes } from "src/app/enums/league-type.enum";

@Component({
  selector: "app-join-league-confirmation-step",
  templateUrl: "./join-league-confirmation-step.component.html",
  styleUrls: ["./join-league-confirmation-step.component.scss"],
})
export class JoinLeagueConfirmationStepComponent implements OnInit {
  @Input() public confirmationInfo: JoinLeagueConfirmationInformation;
  @Output() public joinSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  public seasonPassTitle$: Observable<string> = of("");

  public errorState: JoinLeagueErrorState = {
    team: "",
    sponsor: "",
    referral: "",
    payment: "",
    seasonPass: "",
  };

  public checkedTeams: {
    [key: string]: {
      eligible: boolean;
      errorMessage: string;
    };
  }[] = [];

  public registrationTypes = RegistrationTypes;

  private _leagueType: LeagueTypes;

  constructor(
    private _leagueService: LeagueEndpointService,
    private _leaguesV2Service: LeaguesV2Service,
    private _teamsV2Service: TeamsV2Service,
    private _toast: ToastrService,
    private _store: Store<RootState>,
    private _joinLeagueModalService: JoinLeagueModalService
  ) { }

  public ngOnInit(): void {
    this.seasonPassTitle$ = this.getSeasonPassTitle$();
  }

  public canCheckout(): boolean {
    return (
      this.confirmationInfo.registrationType !== RegistrationTypes.SPONSOR &&
      this.confirmationInfo.leagueFee &&
      this.confirmationInfo.leagueFee > 0 &&
      !this.confirmationInfo.isSeasonPassLeague
    );
  }

  public get confirmationHasError(): boolean {
    const { referral, sponsor, team, seasonPass } = this.errorState;

    const teamHasError = team.length > 0;
    const referralHasError = this.isReferral && referral.length > 0;
    const sponsorHasError = this.isSponsor && sponsor.length > 0;
    const seasonPassHasError = this.isSeasonPass && seasonPass.length > 0;

    return teamHasError ||
      referralHasError ||
      sponsorHasError ||
      seasonPassHasError;
  }

  public displayTeamImage(logo: string): string {
    return processImage(logo, ImageTypes.TEAM_SQ);
  }

  public paymentIsBlocked(): Observable<boolean> {
    return this._canTeamJoinleague(this._registeringTeamId).pipe(map(eligible => !eligible));
  }

  public get isFreeLeague(): boolean {
    return this.confirmationInfo.leagueFee <= 0;
  }

  public joinLeagueWithNoPayment(): void {
    const teamId = this._registeringTeamId;
    const { leagueId, sponsorCode } = this.confirmationInfo;

    if (this.isSeasonPass) {
      this._joinLeagueWithSeasonPass(leagueId, teamId);
      return;
    }

    if (this.confirmationInfo.registrationType === RegistrationTypes.SPONSOR) {
      this._joinLeagueWithSponsorCode(teamId, leagueId, sponsorCode);
      return;
    }

    const params: JoinLeagueReferralParams = {
      teamId,
      leagueId,
      city: this.confirmationInfo.registerWithLocation ?
        this.confirmationInfo.city :
        null,
    };

    if (this.confirmationInfo.registrationType === RegistrationTypes.REFERRAL) {
      params.organizationId = this.confirmationInfo.referrall.id;
    }

    this._joinLeagueWithOptions(params);
  }

  public getSeasonPassTitle$(): Observable<string> {
    const userPasses$: Observable<UserEsportsPass[]> = this._store.select("user", "currentUser").pipe(
      pluck("gamePasses"),
      first()
    );

    const league$ = this._store.select("leagues", "league").pipe(
      first()
    );

    return forkJoin([userPasses$, league$]).pipe(
      tap(([, { leagueType }]) => {
        this._leagueType = leagueType;
      }),
      map(([userPasses, league]) => userPasses.find(pass => league.organizationSeasonPassIds.includes(pass.seasonPassId))),
      pluck("title"),
      tap((passTitle) => {
        if (!passTitle && this.isSeasonPass) {
          this.errorState.seasonPass = "You do not have a valid season pass";
        }
      }),
      first()
    );
  }

  public get isFirstStep(): boolean {
    return this._joinLeagueModalService.isConfirmationStepFirst;
  }

  public get isReferral(): boolean {
    return this.confirmationInfo.registrationType === RegistrationTypes.REFERRAL && !this.confirmationInfo.isSeasonPassLeague;
  }

  public get isSponsor(): boolean {
    return this.confirmationInfo.registrationType === RegistrationTypes.SPONSOR && !this.confirmationInfo.isSeasonPassLeague;
  }

  public get isSeasonPass(): boolean {
    return this.confirmationInfo.isSeasonPassLeague;
  }

  private get _registeringTeamId(): string {
    const useSoloTeam = this.confirmationInfo.teamType === TeamTypes.ACCOUNT_SOLO_TEAM;
    return useSoloTeam ?
      this.confirmationInfo.soloTeamId :
      this.confirmationInfo.selectedTeam?.id; //Use optional until the team is loaded
  }

  private _joinLeagueWithSponsorCode(teamId: string, leagueId: string, sponsorCode: string): void {
    this._leagueService
      .joinLeagueWithSponsorCode(
        teamId,
        leagueId,
        sponsorCode
      )
      .pipe(
        catchError(this._joinLeagueError),
        first()
      )
      .subscribe(this._joinLeagueSuccess);
  }

  private _joinLeagueWithOptions(params: JoinLeagueReferralParams): void {
    this._leagueService
      .joinLeagueWithOptions(params)
      .pipe(
        catchError(this._joinLeagueError),
        first()
      )
      .subscribe(this._joinLeagueSuccess);
  }

  private _joinLeagueWithSeasonPass(leagueId: string, teamId: string): void {
    this._leaguesV2Service.joinLeague(leagueId, teamId).pipe(
      catchError(this._joinLeagueError),
      first()
    ).subscribe(
      this._joinLeagueSuccess
    );
  }

  private _readErrorMessage(err): string {
    try {
      if (err.error.errors[0].base) {
        return err.error.errors[0].base;
      }
      return err.error.errors[0];
    } catch {
      Logger.error("Unknown error reading response");
      return "Server error while joining league please contact support@ggleagues.com";
    }
  }

  private _canTeamJoinleague(teamId: string): Observable<boolean> {
    if (!teamId) {
      return of(false);
    }

    const team = this.checkedTeams[teamId];

    if (team) {
      this.errorState.team = team.errorMessage;
      return of(team.eligible);
    }

    //Until we fully replace the v1 call with v2 we need to pick which we use
    if (this.isSeasonPass) {
      return this._teamsV2Service.canTeamJoinLeague(teamId, this.confirmationInfo.leagueId)
        .pipe(
          map(({ joinable, errors: [joinErrorMessage] }) => {
            const errorMessage = joinable ? "" : joinErrorMessage;
            this._setTeamErrorState(teamId, errorMessage, joinable);
            return joinable;
          }),
          first()
        );
    }

    return this._leagueService.getTeamLeagueJoinable(teamId, this.confirmationInfo.leagueId).pipe(
      map(({ joinable, errors: [joinErrorMessage] }) => {
        const errorMessage = joinable ? "" : joinErrorMessage;
        this._setTeamErrorState(teamId, errorMessage, joinable);
        return joinable;
      }),
      first()
    );
  }

  private _setTeamErrorState(teamId: string, errorMessage: string, eligible: boolean): void {
    this.errorState.team = errorMessage;
    this.checkedTeams[teamId] = {
      eligible,
      errorMessage,
    };
  }

  private _joinLeagueError = (err: unknown): Observable<null> => {
    Logger.error(err);
    const errorMessage = this._readErrorMessage(err);
    this._setOrganizationErrorState(errorMessage);
    this._toast.error(
      errorMessage,
      "You could not join the league"
    );
    return of(null);
  };

  private _joinLeagueSuccess = async (response: unknown): Promise<void> => {
    Logger.log(response);
    if (response) {
      const isWeekly = await this._store.select("leagues", "league").pipe(
        first(),
        pluck("leagueType"),
        map((leagueType) => leagueType === LeagueTypes.QUICKPLAY)
      ).toPromise();
      triggerLeagueRegistrationAnalyticsEvent(this.confirmationInfo.leagueId, isWeekly);
      this._toast.success("You have joined the league");
      this._store.dispatch(
        new GetLeagueById(+this.confirmationInfo.leagueId)
      );
      this.joinSuccess.emit(true);
      this._setOrganizationErrorState("");
    }
  };

  private _setOrganizationErrorState(errorMessage: string): void {
    if (this.isReferral) {
      this.errorState.referral = errorMessage;
    }

    if (this.isSponsor) {
      this.errorState.sponsor = errorMessage;
    }

    if (this.isSeasonPass) {
      this.errorState.seasonPass = errorMessage;
    }
  }
}
