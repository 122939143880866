import { HttpClient } from "@angular/common/http";
import { Provider } from "@angular/core";
import { ToastrService } from "ngx-toastr";

import { environment } from "@environments/environment";
import { CloudTournamentCommentService } from "@services/comment/cloud-tournament-comment/cloud-tournament-comment.service";
import { CommentValidatorService } from "@services/comment/comment-validator/comment-validator.service";
import { RailsTournamentCommentService } from "@services/comment/rails-tournament-comment/rails-tournament-comment.service";

import { COMMENT_SERVICE } from "./comment-service.token";

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function TournamentCommentFactory(
  http: HttpClient,
  toastr: ToastrService,
  validator: CommentValidatorService
): CloudTournamentCommentService | RailsTournamentCommentService {
  return environment.useCloudChats ?
    new CloudTournamentCommentService(http, toastr, validator) :
    new RailsTournamentCommentService(http, toastr, validator);
};

/**
 * Provides a CommentService for Tournament Chatrooms, will automatically
 * inject the cloud service if available
 */
export const TournamentCommentServiceProvider: Provider = {
  provide: COMMENT_SERVICE,
  useFactory: TournamentCommentFactory,
  deps: [
    HttpClient,
    ToastrService,
    CommentValidatorService,
  ],
};
