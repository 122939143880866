<ng-template #title>
  <app-modal-title>Join a League</app-modal-title>
</ng-template>

<ng-template #content>
  <div>
    <ng-container [formGroup]="joinLeagueForm">
      <mat-horizontal-stepper [linear]="true" #stepper>
        <!-- Elite League Disclaimer Step -->
        <mat-step *ngIf="isEliteLegue">
          <ng-template matStepLabel>Disclaimer</ng-template>
          <h2>Elite League Requirements</h2>
          <p>
            All teams in elite leagues must satisfy the following requirements
            to register:
          </p>
          <ol>
            <li>
              Each team captain must have a Discord ID listed on their GGLeagues
              Profile when the team is registered
            </li>
            <li>
              Each team member must have their profile for the league game
              listed on their profile when the team is registered
            </li>
          </ol>
          <div class="c_stepperContainer__btns">
            <app-modal-stepper-footer-buttons
              [hasNext]="true"
              [hasPrevious]="false"
            >
            </app-modal-stepper-footer-buttons>
          </div>
        </mat-step>
        <!-- Team Step -->
        <mat-step [stepControl]="teamControl" *ngIf="hasTeamStep">
          <ng-template matStepLabel>Select your team</ng-template>
          <app-select-team-step
            formControlName="team"
            [regInfoTeams]="registrationInformation.teams"
            (closeDialogue)="close()"
          >
          </app-select-team-step>
        </mat-step>
        <!-- Registration Type Selection Step -->
        <mat-step
          [stepControl]="registrationChoiceControl"
          *ngIf="hasRegistrationTypeStep"
        >
          <ng-template matStepLabel>Registration Type</ng-template>
          <app-registration-selection-step
            [sponsorOnlyMode]="useSponsorOnly$ | async"
            formControlName="registrationChoice"
          ></app-registration-selection-step>
        </mat-step>
        <!-- Referral Step -->
        <mat-step [stepControl]="referralControl" *ngIf="hasReferralStep">
          <ng-template matStepLabel>Referral</ng-template>
          <app-referral-choice-step
            formControlName="referral"
            [regInfoReferrals]="referralOrganizations"
          >
          </app-referral-choice-step>
        </mat-step>
        <!-- Sponsor Step -->
        <mat-step [stepControl]="sponsorControl" *ngIf="hasSponsorStep">
          <ng-template matStepLabel>Sponsor</ng-template>
          <app-sponser-code-step
            formControlName="sponsor"
          ></app-sponser-code-step>
        </mat-step>
        <!-- Sponsor or Referral Step -->
        <mat-step
          [stepControl]="referrallSponsorControl"
          *ngIf="hasRegistrationTypeStep"
        >
          <ng-template matStepLabel
            >{{ registrationType === registrationTypes.NEITHER ?
            "Referral/Sponsor" : registrationType }}</ng-template
          >
          <ng-container *ngIf="registrationType === registrationTypes.REFERRAL">
            <app-referral-choice-step
              formControlName="referral"
              [regInfoReferrals]="referralOrganizations"
            >
            </app-referral-choice-step>
          </ng-container>
          <ng-container *ngIf="registrationType === registrationTypes.SPONSOR">
            <app-sponser-code-step
              formControlName="sponsor"
            ></app-sponser-code-step>
          </ng-container>
        </mat-step>
        <!-- City Selection Step -->
        <mat-step [stepControl]="cityControl" *ngIf="hasCityStep">
          <ng-template matStepLabel>City</ng-template>
          <app-city-selection-step
            formControlName="city"
          ></app-city-selection-step>
        </mat-step>
        <!-- Confirmation Step -->
        <mat-step>
          <ng-template matStepLabel>Confirmation</ng-template>
          <app-join-league-confirmation-step
            [confirmationInfo]="confirmationInformation"
            (joinSuccess)="close()"
          >
          </app-join-league-confirmation-step>
        </mat-step>
        <!-- Checkout Step -->
        <mat-step *ngIf="canCheckout()">
          <ng-template matStepLabel>Payment</ng-template>
          <app-checkout-step
            paymentItem="League Fee"
            [paymentSuccessFunction]="processPaymentAndJoin"
          >
          </app-checkout-step>
        </mat-step>
      </mat-horizontal-stepper>
    </ng-container>
  </div>
</ng-template>

<app-modal-container
  [isStepper]="true"
  [modalTitle]="title"
  [modalContent]="content"
>
</app-modal-container>
