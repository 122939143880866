import {
  Component,
  QueryList,
  ContentChildren,
  AfterViewInit,
  Input,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { TemplateRef } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { takeUntil } from "rxjs/operators";

import { Unsubscriber } from "@utils/unsubscriber";

import { TabComponent } from "../tab/tab.component";

@Component({
  selector: "app-tabs-block",
  templateUrl: "./tabs-block.component.html",
  styleUrls: ["./tabs-block.component.scss"],
})
export class TabsBlockComponent implements AfterViewInit, OnDestroy {
  @ContentChildren(TabComponent) public tabs: QueryList<TabComponent>;
  @Input() public darkBackground = false;
  @Input() public slimContent = false;
  @Input() public scrollable = false;

  @Input()
  public headerButton: TemplateRef<unknown>;

  public useTabs = true;
  public dropDownForm = new FormGroup({
    activeTab: new FormControl(null),
  });

  private _unsub = new Unsubscriber();

  @HostListener("window:resize", ["$event"])
  public onResize() {
    //2 tabs do not look awful at smaller
    if (window.innerWidth <= 450 && this.tabs.toArray().length > 2) {
      this.dropDownForm.patchValue({
        activeTab: this.tabs.toArray()[0],
      });
      this.useTabs = false;
    }

    if (window.innerWidth > 450) {
      this.useTabs = true;
    }
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public ngAfterViewInit() {
    //See: https://blog.angular-university.io/angular-debugging/
    setTimeout(() => {
      this.onResize();

      const activeTabs = this.tabs.filter((tab) => tab.active);

      if (activeTabs.length === 0) {
        this.selectTab(this.tabs.first);
      }

      this.dropDownForm.valueChanges.pipe(
        takeUntil(this._unsub.unsubEvent)
      ).subscribe((form) => {
        this.selectTab(form.activeTab);
      });
    });

  }

  public selectTab(tab: TabComponent) {
    this.tabs.toArray().forEach((iteratedTab) => {
      iteratedTab.active = false;
    });

    if (tab) {
      tab.active = true;
    }
  }
}
