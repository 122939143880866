<app-page>
  <ng-container [ngSwitch]="userAcceptsQueue">
    <ng-container *ngSwitchCase="true">
      <a [routerLink]="backToLeagueRoute" class="c_button secondary_light"
        >Back to League Page</a
      >
      <header class="c_checkinPageHeader">
        <h2 class="u_txt--hdg2 u_txt--white">League Match Queue</h2>
      </header>
      <div class="c_checkinPageContainer c_checkinPageContainer--accepted">
        <div class="c_checkinInfoContainer">
          <header class="c_queueInfoHeader">
            <h3 class="u_txt--hdg3">
              {{ queueTitleMessage }}
              <div *ngIf="queueIsStreaming" class="loader"></div>
            </h3>
          </header>
          <dl>
            <dt>Time Elapsed:</dt>
            <dd>{{ queueWaitTime }}</dd>
            <dt>Queue Close-Time:</dt>
            <dd>{{ queueCloseTime }}</dd>
            <dt>Status:</dt>
            <dd>{{ queueStatus }}</dd>
          </dl>
        </div>
        <div
          class="c_queueMatchPanel"
          *ngIf="matchesFoundForUser || queueIsFinished"
        >
          <ng-container *ngIf="matchesFoundForUser">
            <h4 class="u_txt--hdg4">
              Your Queue {{ playerMatch.length > 1 ? "Matches" : "Match" }}
            </h4>
            <div class="c_queueMatchPanel__matchesContainer">
              <div
                class="c_queueMatchPanel__match"
                *ngFor="let pMatch of playerMatch; let i = index"
              >
                <h5 *ngIf="playerMatch.length > 1" class="u_txt--hdg4">
                  Match {{ i + 1 }}
                </h5>
                <dl>
                  <dt>Team One:</dt>
                  <dd>{{ getTeamNameFromId(pMatch.team_one) }}</dd>
                  <dt>Team Two:</dt>
                  <dd>{{ getTeamNameFromId(pMatch.team_two) }}</dd>
                </dl>
                <a class="c_button" [routerLink]="generateMatchRoute(pMatch)">
                  Go to Match
                </a>
              </div>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              checkinStatusInitialized &&
              queueIsFinished &&
              !matchesFoundForUser
            "
          >
            <ng-container *ngIf="!userDidntCheckin">
              <h4 class="u_txt--hdg4 u_txt--warn">No Match Was Found...</h4>
              <p>
                We were unable to assign you a match for this week due to having
                an odd number of participants in the queue
              </p>
              <p>Due to this, you have received a bye for this week.</p>
            </ng-container>
            <ng-container *ngIf="userDidntCheckin">
              <h4 class="u_txt--hdg4 u_txt--warn">
                You did not check-in for this week
              </h4>
              <p>
                Your team has missed the queue period for this week. This has
                resulted in an automatic loss for your team this week.
                <br />
                Make sure to check into next week's queue on time to get a game!
              </p>
            </ng-container>
          </ng-container>
        </div>
        <div class="c_queueChatContainer">
          <h4 class="u_txt--hdg4">League Queue Chat</h4>
          <app-queue-tournament-chat-block></app-queue-tournament-chat-block>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="false">
      <header class="c_checkinPageHeader">
        <h2 class="u_txt--hdg2 u_txt--white">Join Match Queue</h2>
      </header>
      <div class="c_checkinPageContainer">
        <div class="c_checkinInfoContainer c_checkinInfoContainer--declined">
          <p>Choose one of the options below:</p>
          <div>
            <button class="c_button" (click)="openQueueModal()">
              Join Queue
            </button>
          </div>
          <div>
            <a [routerLink]="backToLeagueRoute" class="c_button secondary_dark"
              >Back to League</a
            >
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="c_chekcinPagePendingInput">
        <app-loader [useWhite]="true"> Waiting for user input </app-loader>
      </div>
    </ng-container>
  </ng-container>
</app-page>
