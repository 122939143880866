<app-lobby-help-modal-frame
	modalTitle="Learning About Your Rocket League Match"
>
	<p class="u_txt">How To Join a Rocket League Lobby</p>
	<iframe
		class="c_helpModal__video"
		width="560"
		height="315"
		src="https://www.youtube.com/embed/FZtORtHKC5w"
		frameborder="0"
		allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
		allowfullscreen
	></iframe>
	<p class="u_txt">How to Create a Rocket League Lobby</p>
	<iframe
		class="c_helpModal__video"
		width="560"
		height="315"
		src="https://www.youtube.com/embed/XSh-Unr__sQ"
		frameborder="0"
		allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
		allowfullscreen
	></iframe>
</app-lobby-help-modal-frame>
