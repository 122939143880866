import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "app-expandable-content",
  templateUrl: "./expandable-content.component.html",
  styleUrls: ["./expandable-content.component.scss"],
  encapsulation: ViewEncapsulation.None, //styling content projection
})
export class ExpandableContentComponent {
  @Input() public hasBackground = false;
  @Input() public hasDarkbackground = false;
  @Output() public expansionChangeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isShowingContent = false;

  public expandContent(): void {
    this.isShowingContent = !this.isShowingContent;
    this.expansionChangeEvent.emit(this.isShowingContent);
  }
}
