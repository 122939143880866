import { ISOString } from "@apptypes/date.types";
import { apiGamePlatforms } from "src/app/enums/game-platforms.enum";
import { LeagueTypes } from "src/app/enums/league-type.enum";
import { APITournamentTypes } from "src/app/enums/tournamentTypes.enum";
import { EliminationBracketDataAPIResponse, LeagueBracketStatus } from "src/app/reducers/leagues/league.types";
import { TeamTypes } from "src/app/reducers/teams/teams.types";

export interface JoinLeagueAPIResponseSuccess {
  success: string;
}

export interface BRTournamentScoresAPIResponse {
  team_id: string;
  team_title: string;
  total_score: number;
}

export interface TournamentCheckinParams {
  tournament_checkin: {
    team_id: string | number;
  };
}

export interface TournamentCheckinSuccess {
  success: true;
}

// Generated by https://quicktype.io

export interface V2LeagueDetailsAPIResponse {
  id: string;
  type: "league";
  attributes: {
    defaultTeamFee: string | null;
    description: string;
    finished: boolean;
    leagueType: LeagueTypes;
    maximumTeamSize: number;
    minimumTeamSize: number;
    organizationSeasonPassIds: number[];
    teamRegistrationType: TeamTypes;
    timeZone: string;
    title: string;
    twitchStreamUrl: string | null;
    esport: apiGamePlatforms;
    maxEntrants: number;
    currentEntrantsCount: number;
    startTime: ISOString;
    registrationEndDate: ISOString;
    leagueLayoutManifest: V2LeagueLayoutManifestAPIResponse | null;
  };
}

// Generated by https://quicktype.io

export interface V2LeagueTeamsAPIResponse {
  id: string;
  type: "teams";
  attributes: {
    description: string;
    teamType: TeamTypes;
    title: string;
    userIds: number[];
    logoUrl: null;
  };
}

export interface LeagueAPIMatch {
  id: string;
  type: "seriesMatchups";
  attributes: {
    matchupMapping: EliminationBracketDataAPIResponse;
    matchupStartTime: ISOString;
    teams: {
      data: LeagueAPIMatchTeam[];
    };
    tournament: {
      data: LeagueAPIMatchTournament;
    };
    winner: string;
    winnerId: number;
  };
}

export interface LeagueAPIMatchTeam {
  id: string;
  type: "teams";
  attributes: {
    teamType: TeamTypes;
    title: string;
  };
}

export interface LeagueAPIMatchTournament {
  id: string;
  type: "tournament";
  attributes: {
    schedulingType: APITournamentTypes;
    title: string;
  };
};

export interface V2LeagueTournamentsAPIResponse {
  id: string;
  type: string;
  attributes: {
    matchupMapping: EliminationBracketDataAPIResponse[];
    schedulingType: APITournamentTypes;
    status: LeagueBracketStatus;
    title: string;
    chatroomReferenceId: string;
    streamReferenceId: string;
    tournamentQueueSessions?: {
      data: V2LeagueQueueSessionAPIResponse[];
    };
  };
}

export interface V2LeagueQueueSessionAPIResponse {
  id: string;
  type: "tournamentQueueSessions";
  attributes: {
    status: QueueSessionStatuses;
    startTime: ISOString;
  };
}

export interface QueueSession {
  id: string;
  status: QueueSessionStatuses;
  startTime: ISOString;
}

export enum QueueSessionStatuses {
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  COMPLETED = "complete",
}
export interface V2LeagueTeamStandingsAPIResponse {
  id: number;
  title: string;
  record: {
    game_losses: number;
    game_wins: number;
    series_losses: number;
    series_wins: number;
  };
}

export interface V2LeagueLayoutManifestAPIResponse {
  data: {
    id: string;
    type: "leagueLayoutManifest";
    attributes: {
      imageAssets: {
        data: V2LeagueLayoutManifestImageAssetsAPIReponse[];
      };
      layoutVersion: string;
    };
  };
}

export interface V2LeagueLayoutManifestImageAssetsAPIReponse {
  id: string;
  type: "sponsorshipCampaignContentWells"; // TODO: Update these when we expand
  attributes: {
    externalUrlReference?: string;
    imageUrl: string;
    pageWellReference: number;
  };
}
