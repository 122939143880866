/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { addMatchCommenters } from "@utils/reducer-utils/match-utils";

import { MatchesActions, MatchesActionTypes } from "./matches.actions";
import { MatchState } from "./matches.types";

const initialState: MatchState = {
  seriesMatchup: null,
  matchCommenters: [],
  fetchingSeriesMatchupById: false,
  fetchingSeriesMatchupByIdError: false,
  fetchingMatchCommenters: false,
  fetchingMatchCommentersError: false,
};

export function matchesReducer(
  state = initialState,
  action: MatchesActions
): MatchState {
  switch (action.type) {
    case MatchesActionTypes.GET_SERIES_MATCHUP_BY_ID:
      return {
        ...state,
        seriesMatchup: null,
        fetchingSeriesMatchupById: true,
        fetchingSeriesMatchupByIdError: false,
      };
    case MatchesActionTypes.GET_SERIES_MATCHUP_BY_ID_ERROR:
      return {
        ...state,
        seriesMatchup: null,
        fetchingSeriesMatchupById: false,
        fetchingSeriesMatchupByIdError: true,
      };
    case MatchesActionTypes.GET_SERIES_MATCHUP_BY_ID_SUCCESS:
      return {
        ...state,
        seriesMatchup: action.payload,
        fetchingSeriesMatchupById: false,
        fetchingSeriesMatchupByIdError: false,
      };
    case MatchesActionTypes.UPDATE_CURRENT_SERIES_MATCHUP_DATA:
      return {
        ...state,
        seriesMatchup: {
          ...state.seriesMatchup,
          ...action.payload,
        },
      };
    case MatchesActionTypes.UPDATE_MATCH_COMMENTERS:
      return {
        ...state,
        matchCommenters: addMatchCommenters([...state.matchCommenters], action.payload),
        fetchingMatchCommenters: false,
        fetchingMatchCommentersError: false,
      };
    case MatchesActionTypes.FETCH_UNKNOWN_MATCH_COMMENTERS:
      return {
        ...state,
        fetchingMatchCommenters: true,
        fetchingMatchCommentersError: false,
      };
    case MatchesActionTypes.FETCH_UNKNOWN_MATCH_COMMENTERS_ERROR:
      return {
        ...state,
        fetchingMatchCommenters: false,
        fetchingMatchCommentersError: true,
      };
    case MatchesActionTypes.CLEAR_MATCH_COMMENTERS:
      return {
        ...state,
        matchCommenters: [],
      };
    default:
      return state;
  }
}
