import { Action } from "@ngrx/store";

export enum NullActionTypes {
  NULL_ACTION = "[NULL] Null Action",
}

export class NullAction implements Action {
  public readonly type = NullActionTypes.NULL_ACTION;
  constructor(public payload: void) {}
}
