import { Injectable } from "@angular/core";
import {
  Effect,
  Actions,
  ofType,
} from "@ngrx/effects";
import { ToastrService } from "ngx-toastr";
import {
  map,
  mergeMap,
  catchError,
  tap,
} from "rxjs/operators";
import { of } from "rxjs";

import { ClubsService } from "@services/v2/clubs/clubs.service";

import { Logger } from "../../util/logger";
import {
  GetClubById,
  ClubActionTypes,
  GetClubByIdSuccess,
  GetClubByIdError,
  GetClubSubscriptions,
  GetClubSubscriptionsError,
  GetClubSubscriptionsSuccess,
  SubscribeToClub,
  SubscribeToClubError,
  SubscribeToClubSuccess,
  UnsubscribeToClubSuccess,
  UnsubscribeToClub,
  UnsubscribeToClubError,
} from "./club.actions";

@Injectable()
export class ClubEffects {
  @Effect()
  public club$ = this._action$.pipe(
    ofType<GetClubById>(ClubActionTypes.GET_CLUB_BY_ID),
    map((action) => action.payload),
    mergeMap((id) => this._clubService.getClub(id).pipe(
      catchError((err) => {
        Logger.error(err);
        return of(null);
      })
    )),
    mergeMap((club) => {
      if (club) {
        return of(new GetClubByIdSuccess(club));
      } else {
        this._toastr.error("Could Not Retrieve this Club", "Error");
        Logger.error(club);
        return of(new GetClubByIdError());
      }
    })
  );

  @Effect()
  public clubSubscriptions$ = this._action$.pipe(
    ofType<GetClubSubscriptions>(ClubActionTypes.GET_CLUB_SUBSCRIPTIONS),
    mergeMap(() => this._clubService.getUserClubSubscription().pipe(
      catchError((err) => {
        Logger.error(err);
        return of(null);
      })
    )),
    mergeMap((subscriptions) => {
      if(subscriptions){
        return of(new GetClubSubscriptionsSuccess(subscriptions));
      }
      return of(new GetClubSubscriptionsError());
    })
  );

  @Effect()
  public subscribeToClub$ = this._action$.pipe(
    ofType<SubscribeToClub>(ClubActionTypes.SUBSCRIBE_TO_CLUB),
    map((action) => action.payload),
    mergeMap((clubId) => this._clubService.subscribeToClub(clubId).pipe(
      catchError((err) => {
        Logger.error(err);
        return of(null);
      })
    )),
    mergeMap((response) => {
      if(response){
        return of(new SubscribeToClubSuccess());
      }
      this._toastr.error("Could Not Subscribe to This Club", "Error");
      return of(new SubscribeToClubError());
    })
  );

  @Effect()
  public subscribeToClubSuccess$ = this._action$.pipe(
    ofType<SubscribeToClubSuccess>(ClubActionTypes.SUBSCRIBE_TO_CLUB_SUCCESS),
    mergeMap(() => of(new GetClubSubscriptions()))
  );

  @Effect()
  public unsubscribeToClub$ = this._action$.pipe(
    ofType<UnsubscribeToClub>(ClubActionTypes.UNSUBSCRIBE_TO_CLUB),
    map((action) => action.payload),
    mergeMap((clubId) => this._clubService.unsubscribeToClub(clubId).pipe(
      catchError((err) => {
        Logger.error(err);
        return of(null);
      })
    )),
    mergeMap((response) => {
      if(response){
        return of(new UnsubscribeToClubSuccess());
      }
      this._toastr.error("Could Not Unsubscribe to This Club", "Error");
      return of(new UnsubscribeToClubError());
    })
  );

  @Effect()
  public unsubscribeToClubSuccess$ = this._action$.pipe(
    ofType<UnsubscribeToClubSuccess>(ClubActionTypes.UNSUBSCRIBE_TO_CLUB_SUCCESS),
    tap(() => this._toastr.success("You have unsubscribed from the club")),
    mergeMap(() => of(new GetClubSubscriptions()))
  );

  constructor(
    private _action$: Actions,
    private _clubService: ClubsService,
    private _toastr: ToastrService
  ) { }
}
