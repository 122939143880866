<ng-container *ngIf="quickPlayEventData$ | async as quickPlayEventData">
  <section class="c_quickPlayCheckinPanel">
    <header>
      <h3 class="u_txt--hdg3">Check-In</h3>
    </header>
    <dl class="c_quickPlayCheckinPanel__infoList">
      <dt>Status:</dt>
      <dd>{{ status$ | async }}</dd>
      <dt>Total Teams Checked-In:</dt>
      <dd>
        <span>
          {{ quickPlayEventData.checkins.length || 0 }}
        </span>
        <a
          *ngIf="league$ | async as league"
          (click)="viewCheckedInTeams(quickPlayEventData.checkins, league)"
          >View Checked In Teams</a
        >
      </dd>
      <dt>Your Check-In:</dt>
      <dd>
        <ng-container *ngIf="activeTeam$ | async as activeTeam; else noTeam">
          <ng-container
            *ngIf="(isUserCheckedIn$ | async) === false; else checkinStatus"
          >
            <ng-container
              *ngIf="isCheckinEnabled$ | async; else checkinDisabled"
            >
              <button
                class="c_button primary_dark"
                (click)="openCheckinModal()"
              >
                Check-in
              </button>
            </ng-container>
            <ng-template #checkinDisabled>
              <button class="c_button primary_dark" disabled>Checkin</button>
              <p>
                <small>
                  {{ disabledMessage$ | async }}
                </small>
              </p>
            </ng-template>
          </ng-container>
          <ng-template #checkinStatus>
            <p class="c_quickPlayCheckinPanel__checkedInMessage">
              You are checked in!
            </p>
          </ng-template>
        </ng-container>
        <ng-template #noTeam>
          <p>You do not have a team in this league to check-in with</p>
        </ng-template>
      </dd>
    </dl>
    <hr />
    <p>
      <strong>NOTICE:</strong> Players who do not check-in will not be placed
      into the tournament. Anyone who plays without joining will not have their
      games recorded
    </p>
  </section>
</ng-container>
