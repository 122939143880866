import { Component } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { modalOptions } from "src/app/util/modal-helpers";

import { DisputeResultsModalComponent } from "../dispute-results-modal/dispute-results-modal.component";

@Component({
  selector: "app-dispute-results-button",
  templateUrl: "./dispute-results-button.component.html",
  styleUrls: ["./dispute-results-button.component.scss"],
})
export class DisputeResultsButtonComponent {
  constructor(
    private _modalService: NgxSmartModalService
  ) {}

  public openDialogue() {
    this._modalService.create("disputeResults", DisputeResultsModalComponent, modalOptions).open();
  }
}
