/**
 * Compares two arrays of strings (like teamIDs, userIDs) and returns true if the two arrays
 * share **any** common values.
 */
export const doArraysShareStringValues = (arr1: string[], arr2: string[]): boolean => {
  // Determine shortest array for perf reasons
  const [long, short] = arr1.length > arr2.length ? [arr1, arr2] : [arr2, arr1];

  let doArraysShareValue = false;

  for (const strVal of short) {
    doArraysShareValue = long.includes(strVal);
    if (doArraysShareValue) {
      break;
    }
  }

  return doArraysShareValue;
};
