<form [formGroup]="reportForm" class="c_reportResultsStep__form">
  <div
    class="c_reportResultsStep__warning c_reportResultsStep__warning--mobile"
    *ngIf="isShowingWarningState"
  >
    <i
      class="fas fa-2x fa-exclamation-circle c_reportResultsStep__warningIcon"
    ></i>
    <p class="u_txt c_reportResultsStep__tableText">
      There isn't a winner from the game results, are you sure you want to
      continue?
    </p>
  </div>
  <div class="c_reportResultsStep__selectOptions">
    <ng-container
      *ngFor="let reportFormGroup of reportForm.controls; let index = index"
    >
      <ng-container [formGroup]="reportFormGroup">
        <app-expandable-content
          [hasBackground]="true"
          (expansionChangeEvent)="handleExpansionChangeEvent($event, index)"
        >
          <span button class="c_reportResultsStep__matchHeader">
            <span>
              {{ getMatchName(index) | uppercase }}
            </span>
            <ng-container
              *ngTemplateOutlet="
                statusChip;
                context: { $implicit: reportFormGroup }
              "
            ></ng-container>
          </span>
          <div class="c_reportResultsStep__fullDropdown">
            <label for="winnerId-{{ index }}"> Select Winner </label>
            <select formControlName="winnerId" id="winnerId-{{ index }}">
              <option [value]="null" disabled selected>SELECT A WINNER</option>
              <option [value]="matchReport.teams[0].id">
                {{ matchReport.teams[0].title }}
              </option>
              <option [value]="matchReport.teams[1].id">
                {{ matchReport.teams[1].title }}
              </option>
              <option [value]="NOT_PLAYED">Not Played</option>
            </select>
            <label for="{{ index }}-Screenshot"> Screenshot </label>
            <ng-container
              *ngIf="!getScreenShot(reportFormGroup); else clearFile"
            >
              <button
                class="
                  c_button c_button--slim
                  c_reportResultsStep__uploadButton
                "
                (click)="uploadFile(index)"
              >
                <i class="fas fa-upload"></i>
                <p class="u_txt c_reportResultsStep__tableText">Upload</p>
              </button>
            </ng-container>
            <ng-template #clearFile>
              <div class="c_reportResultsStep__clearButton">
                <p
                  *ngIf="getScreenShot(reportFormGroup) as file"
                  class="u_txt c_reportResultsStep__tableText"
                >
                  {{ file.name }}
                </p>
                <button
                  class="c_button error c_button--slim"
                  (click)="clearScreenshot(reportFormGroup)"
                >
                  <i class="fas fa-times fa-2x"></i>
                </button>
              </div>
            </ng-template>
            <input
              class="c_selfReport__fileUpload"
              type="file"
              accept="image/*"
              id="{{ index }}-Screenshot"
              (change)="loadImage($event, reportFormGroup)"
            />
          </div>
        </app-expandable-content>
      </ng-container>
    </ng-container>
  </div>
</form>

<div
  class="c_reportResultsStep__warning c_reportResultsStep__warning--desktop"
  *ngIf="isShowingWarningState"
>
  <i
    class="fas fa-2x fa-exclamation-circle c_reportResultsStep__warningIcon"
  ></i>
  <p class="u_txt c_reportResultsStep__tableText">
    There isn't a winner from the game results, are you sure you want to
    continue?
  </p>
</div>

<ng-template #footerButtons>
  <app-modal-stepper-footer-buttons
    [hasPrevious]="true"
    [hasNext]="true"
    [formToCheck]="reportForm"
    errorMessage="Please record the result for each game"
  >
  </app-modal-stepper-footer-buttons>
</ng-template>

<ng-template #statusChip let-reportFormGroup>
  <ng-container *ngIf="getGroupStatus(reportFormGroup) === WINNER_DECLARED">
    <i class="fas fa-check-circle"></i>
  </ng-container>
  <ng-container *ngIf="getGroupStatus(reportFormGroup) === NOT_PLAYED">
    <i class="fas fa-exclamation-circle"></i>
  </ng-container>
</ng-template>
