<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
></app-modal-container>

<ng-template #title>
  <app-modal-title>Having Issues joining {{ getEsport }}?</app-modal-title>
</ng-template>

<ng-template #content>
  <ng-container [ngSwitch]="getEsport">
    <ng-container *ngSwitchCase="gamePlatforms.FORTNITE">
      <p>
        If you're having issues joining your Fortnite Battle Royale Match,
        please follow this checklist:
      </p>
      <ol>
        <li>
          Ensure you are under the right region (NA - East)
          <ul>
            <li>
              Make sure you are NOT under “Auto”. Auto will cause an error even
              though it says NA-East.
            </li>
          </ul>
        </li>
        <li>
          Are you under the right game mode?
          <ul>
            <li>Use "Solos" for Solo Tournaments</li>
            <li>Use "Duos" for Duo Tournaments</li>
          </ul>
        </li>
        <li>
          Make sure the lobby code is entered correctly (Capitalization matters)
        </li>
        <li>
          Make sure you click “play” after entering the code. You will be in
          matchmaking until an admin starts the game!
        </li>
        <li>
          Restart your game and try joining the lobby again
          <ul>
            <li>This is the most common way to solve your problem!</li>
          </ul>
        </li>
        <li>Repeat steps #1 and #5 again</li>
      </ol>
    </ng-container>
    <ng-container *ngSwitchCase="gamePlatforms.ROCKET_LEAGUE">
      <p>
        If you're having issues joining your Rocket League Tournament, please
        follow this checklist:
      </p>
      <ol>
        <li>
          Go to your settings and make sure you have enabled "Cross Platform
          Play" in your Gameplay Settings
          <br />
          <img
            src="https://content.ggleagues.com/public/helper_images/modal_helpers/ggl_rl_settings.png"
          />
        </li>
        <li>If issues persist contact an admin!</li>
      </ol>
    </ng-container>
    <ng-container *ngSwitchDefault>
      If you're experiencing issues, please reach out to an admin via chat and
      they will help you resolve your issue to the best of their ability
    </ng-container>
  </ng-container>
</ng-template>
