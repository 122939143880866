<app-page
  [invert]="(userInLeague$ | async) === false && (isLoading$ | async) === false"
  [useWhite]="(userInLeague$ | async) === false"
>
  <a
    *ngIf="
      (isLoading$ | async) === false &&
      (userInLeague$ | async) === true &&
      (userHasEsportsCredentials$ | async) === false
    "
    class="c_credentialsBanner"
    routerLink="/profile"
  >
    <p>
      <i class="fas fa-user-plus"></i>
      GGLeagues now supports linking your esports accounts with your GGLeagues
      profile.
      <a routerLink="/profile">Click here to start linking accounts!</a>
    </p>
  </a>
  <header class="c_dashboard__header" *ngIf="userInLeague$ | async">
    <h1 class="u_txt--white u_txt--hdg1">Dashboard</h1>
    <p
      class="u_txt--white"
      *ngIf="(dashboard$ | async)?.updatedOn as timestamp"
    >
      Last Updated At: {{ timestamp | date: "h:mm a" }}
      <button class="c_refreshBtn" (click)="refreshDashboard()">
        <i
          class="fas fa-sync-alt"
          [ngClass]="{ 'fa-spin': (isLoading$ | async) }"
        ></i>
      </button>
    </p>
  </header>
  <ng-container *ngIf="(isLoading$ | async) === false; else isLoading">
    <ng-container *ngIf="userInLeague$ | async; else newUserCopy">
      <div class="c_dashboard">
        <div class="c_dashboard__nextGame">
          <app-dash-next-queue
            *ngIf="showQueuePanel$ | async; else nextGame"
          ></app-dash-next-queue>
          <ng-template #nextGame>
            <app-dash-next-game></app-dash-next-game>
          </ng-template>
        </div>
        <div class="c_dashboard__leagueOverview">
          <app-dash-league-overview></app-dash-league-overview>
        </div>
        <div class="c_dashboard__clubs">
          <app-dash-club-panel></app-dash-club-panel>
        </div>
        <div class="c_dashboard__extraBtns">
          <a [routerLink]="myTeamsRoute" class="c_button primary_light"
            >View All My Teams</a
          >
        </div>
      </div>
    </ng-container>
    <ng-template #newUserCopy>
      <app-new-user-dashboard></app-new-user-dashboard>
    </ng-template>
  </ng-container>
  <ng-template #isLoading>
    <app-loader [useWhite]="true">Loading Dashboard...</app-loader>
  </ng-template>
</app-page>
