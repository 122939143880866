<div class="c_lobbyInformation">
  <h4 class="c_lobbyInformation__header u_txt--hdg4">Lobby Information</h4>
  <div class="c_lobbyInformation__container">
    <app-esport-help-modal
      [esport]="esport"
      [isDark]="true"
      [isBattleRoyaleMode]="false"
    ></app-esport-help-modal>
    <select
      *ngIf="lobbyCodes.length > 1"
      class="c_lobbyInformation__lobbySelect"
      [(ngModel)]="selectedLobby"
    >
      <option *ngFor="let lobby of lobbyCodes; let i = index" [ngValue]="lobby">
        Game {{ i + 1 }}
      </option>
    </select>
    <div class="c_lobbyInformation__lobby">
      <dl class="c_lobbyInformation__lobbyDetails">
        <dd>Lobby</dd>
        <dt>{{ selectedLobby.lobby }}</dt>
        <ng-container *ngIf="selectedLobby.lobbyPw">
          <dd>Password</dd>
          <dt>{{ selectedLobby.lobbyPw }}</dt>
        </ng-container>
        <ng-container *ngIf="esport === gamePlatforms.LEAGUE_OF_LEGENDS">
          <dd>Lobby Team Left</dd>
          <dt>{{ selectedLobby.teamLeftLobby }}</dt>
          <dd>Lobby Team Right</dd>
          <dt>{{ selectedLobby.teamRightLobby }}</dt>
        </ng-container>
        <ng-container *ngIf="selectedLobby.winner">
          <dd>Game Winner:</dd>
          <dt>{{ selectedLobby.winner }}</dt>
        </ng-container>
      </dl>
    </div>
  </div>
</div>
