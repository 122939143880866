import {
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import {
  filter,
  pluck,
  takeUntil,
} from "rxjs/operators";

import { TeamsService } from "@services/teams.service";

import { SearchedTeam } from "src/app/reducers/teams/teams.types";
import { RootState } from "src/app/reducers";
import { RequestRoster } from "src/app/reducers/teams/teams.actions";
import { Unsubscriber } from "src/app/util/unsubscriber";
import {
  generateUserDetailRoute,
  generateTeamDetailRoute,
  staticRoutes,
} from "src/app/enums/routes/routePaths";

@Component({
  selector: "app-teams-search-page",
  templateUrl: "./teams-search-page.component.html",
  styleUrls: ["./teams-search-page.component.scss"],
})
export class TeamsSearchPageComponent implements OnInit, OnDestroy {
  public searchResults: SearchedTeam[];
  public isSearching = false;
  public searchTerm: string;
  public lastSearchTerm: string;
  public staticRoutes = staticRoutes;
  public userHasSearched = false;

  private _userId: number;
  private _unsubscriber = new Unsubscriber();

  constructor(private _teamsService: TeamsService, private _titleService: Title, private _store: Store<RootState>) {}

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | Teams Search");

    this._store
      .select("user", "currentUser")
      .pipe(
        filter((user) => user !== null),
        pluck("id"),
        takeUntil(this._unsubscriber.unsubEvent)
      )
      .subscribe((id) => {
        this._userId = id;
      });
  }

  public ngOnDestroy() {
    this._unsubscriber.kill();
  }

  public searchTeams(searchTerm: string): void {
    if (searchTerm && !this.isSearching) {
      this.isSearching = true;
      this._teamsService.searchTeams(searchTerm).subscribe((result) => {
        this.userHasSearched = true;
        this.isSearching = false;
        this.lastSearchTerm = searchTerm;
        this.searchResults = result;
      });
    }
  }

  public requestToJoinTeam(teamId: number) {
    if (this._userId) {
      this._store.dispatch(
        new RequestRoster({
          teamId: teamId.toString(),
          userId: this._userId.toString(),
        })
      );
    }
  }

  public getUserDetailRoute(id: number): string {
    return generateUserDetailRoute(id.toString());
  }

  public getTeamDetailRoute(id: number): string {
    return generateTeamDetailRoute(id.toString());
  }
}
