import { Component, Input } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";

import { SeasonPass } from "@apptypes/season-pass/season-pass.types";
import { modalOptions } from "@utils/modal-helpers";

import { PassPurchaseModalComponent } from "../pass-purchase-modal/pass-purchase-modal.component";
import { SponsorPassModalComponent } from "../sponsor-pass-modal/sponsor-pass-modal.component";
import { OrganizationSeasonPassRegistrationType } from "src/app/enums/registration-types.enum";
import { Observable, of } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { first, map } from "rxjs/operators";
import { routeQueries } from "src/app/enums/routes/routeParams";
import { CustomerSupportService } from "@services/customer-support/customer-support.service";

@Component({
  selector: "app-pass-details-panel",
  templateUrl: "./pass-details-panel.component.html",
  styleUrls: ["./pass-details-panel.component.scss"],
})
export class PassDetailsPanelComponent {
  @Input() public seasonPass!: SeasonPass;
  @Input() public userOwnsPass = false;
  @Input() public showTallmadgeWaiver = false;
  @Input() public showHopewellWaiver = false;
  public passTypes = OrganizationSeasonPassRegistrationType;
  public leagueId$: Observable<string | null> = of(null);
  public orgId$: Observable<string | null> = of(null);

  constructor(
    private _modalService: NgxSmartModalService,
    private _activatedRoute: ActivatedRoute,
    public zendeskService: CustomerSupportService
  ) {
    this.leagueId$ = this._activatedRoute.queryParamMap.pipe(
      map(paramMap => paramMap.get(routeQueries.LEAGUE_ID)),
      first()
    );
    this.orgId$ = this._activatedRoute.queryParamMap.pipe(
      map(paramMap => paramMap.get(routeQueries.ORG_ID)),
      first()
    );
  }

  public async openSponsorPassModal(): Promise<void> {
    this._modalService.create(
      SponsorPassModalComponent.modalID,
      SponsorPassModalComponent,
      modalOptions
    ).setData({
      isPurchaseInfo: false,
      seasonPass: this.seasonPass,
      leagueContext: await this.leagueId$.toPromise() || undefined,
      orgContext: await this.orgId$.toPromise() || undefined,
    }).open();
  }
  public openSponsorPassModalPurchaseInfo(): void {
    this._modalService.create(
      SponsorPassModalComponent.modalID,
      SponsorPassModalComponent,
      modalOptions
    ).setData({
      isPurchaseInfo: true,
      seasonPass: this.seasonPass,
    }).open();
  }

  public async openPurchasePassModal(isTallmadge = false): Promise<void> {
    const navigateToDashboard = this._activatedRoute.snapshot.queryParams?.redirectTo === "dashboard";
    this._modalService.create(
      PassPurchaseModalComponent.modalID,
      PassPurchaseModalComponent,
      modalOptions
    ).setData({
      seasonPass: this.seasonPass,
      leagueContext: await this.leagueId$.toPromise() || undefined,
      orgContext: await this.orgId$.toPromise() || undefined,
      isTallmadge,
      navigateToDashboard,
    }).open();
  }

  public get leagueList() {
    return this.seasonPass?.leagues ?? [];
  }
}
