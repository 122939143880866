import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

import { ChatMessage } from "src/app/reducers/matches/matches.types";
import { ScrollBottomDirective } from "src/app/util/view-directives/scroll-bottom.directive";

@Component({
  selector: "app-chat-stream",
  templateUrl: "./chat-stream.component.html",
  styleUrls: ["./chat-stream.component.scss"],
})
export class ChatStreamComponent implements OnChanges {
  @Input() public messages: ChatMessage[];
  @Input() public isAdminNotifStream = false;

  @ViewChild(ScrollBottomDirective)
  public scrollBottomDirective: ScrollBottomDirective;

  public isBannerShowing = false;

  public ngOnChanges(changes: SimpleChanges) {
    if (!changes.messages.firstChange) {
      if (!this.scrollBottomDirective.isUserInAutoScrollRange) {
        this.isBannerShowing = true;
      }
      this.scrollBottomDirective.scrollToBottom();
    }
  }

  public dismissBanner() {
    this.isBannerShowing = false;
    this.scrollBottomDirective.scrollToBottom(true);
  }
}
