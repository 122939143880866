import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import {
  first,
  map,
  switchMap,
  tap,
} from "rxjs/operators";

import { RootState } from "src/app/reducers";
import { GenericLeagueDetails } from "src/app/reducers/leagues/league.types";

@Component({
  selector: "app-season-pass-league-banner",
  templateUrl: "./season-pass-league-banner.component.html",
  styleUrls: ["./season-pass-league-banner.component.scss"],
})
export class SeasonPassLeagueBannerComponent {
  public userHasPass = true;
  public userHasPass$: Observable<boolean> = of(false);
  public passTitle: string | undefined = undefined;
  public league$: Observable<GenericLeagueDetails>;

  constructor(private _store: Store<RootState>) {
    this.league$ = this._store.select("leagues", "league").pipe(
      first()
    );

    this.userHasPass$ = this._checkUserHasPass(this.league$);
  }

  private _checkUserHasPass = (league$: Observable<GenericLeagueDetails>): Observable<boolean> => league$.pipe(
    switchMap(league => this._store.select("user", "currentUser").pipe(
      map(user => user.gamePasses.find(gamePass => league.organizationSeasonPassIds.includes(gamePass.seasonPassId))),
      tap(gamePass => {
        if (gamePass) {
          this.passTitle = gamePass.title;
        }
      }),
      map(gamePass => !!gamePass),
      first()
    )),
    first()
  );
}
