import { INgxSmartModalOptions } from "ngx-smart-modal/src/config/ngx-smart-modal.config";
import { TeamDetails } from "../reducers/teams/teams.types";

export const modalOptions: INgxSmartModalOptions = {
  closable: false,
  dismissable: false,
  escapable: false,
  refocus: false,
  customClass: "c_ngxSmartModal",
};

export interface LeaveTeamModalInformation {
  userTeamId: number;
  team: TeamDetails;
}

export interface VideoModalInformation {
  videoLink: string;
  modalTitle: string;
}
