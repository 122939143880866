import { OnInit } from "@angular/core";
import { Component, Input } from "@angular/core";
import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import { SeriesMatchupLobbyInformation } from "src/app/reducers/matches/matches.types";

@Component({
  selector: "app-matchup-lobby-block",
  templateUrl: "./matchup-lobby-block.component.html",
  styleUrls: ["./matchup-lobby-block.component.scss"],
})
export class MatchupLobbyBlockComponent implements OnInit {
  @Input() public esport;
  @Input() public lobbyCodes: SeriesMatchupLobbyInformation[];
  public selectedLobby: SeriesMatchupLobbyInformation;
  public gamePlatforms = gamePlatforms;

  public ngOnInit() {
    this.selectedLobby = this.lobbyCodes[0];
  }
}
