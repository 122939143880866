import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { NgxSmartModalService } from "ngx-smart-modal";
import { RootState } from "src/app/reducers";

import { GenericRosterMember } from "src/app/reducers/leagues/league.types";
import { UpdateTeamRoster } from "src/app/reducers/teams/teams.actions";
import { TeamRosterUpdateActions } from "src/app/reducers/teams/teams.types";
import { ModalController } from "../modal-controller/modal-controller.abstract";

@Component({
  selector: "app-remove-player-modal",
  templateUrl: "./remove-player-modal.component.html",
  styleUrls: ["./remove-player-modal.component.scss"],
})
export class RemovePlayerModalComponent extends ModalController<GenericRosterMember> {
  constructor(private _modalService: NgxSmartModalService, private _store: Store<RootState>, private _router: Router) {
    super(_modalService, "removePlayer", _router);
  }

  public removePlayer(): void {
    const player = this.getData();
    this._store.dispatch(
      new UpdateTeamRoster({
        teamUserId: player.teamUserId,
        rosterAction: TeamRosterUpdateActions.DELETE_PLAYER,
      })
    );
    this.close();
  }
}
