import {
  Component,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

import { SearchQuery } from "src/app/util/search-utils";

@Component({
  selector: "app-search-pagination-footer",
  templateUrl: "./search-pagination-footer.component.html",
  styleUrls: ["./search-pagination-footer.component.scss"],
})
export class SearchPaginationFooterComponent {
  @Input() public useDarkBackground: boolean;
  @Input() public paginationInfo: SearchQuery;
  @Output() public paginationInfoChanges = new EventEmitter<SearchQuery>();

  public increment() {
    if (this.paginationInfo.page < this.paginationInfo.pageLimit) {
      this.paginationInfo = {
        page: ++this.paginationInfo.page,
        ...this.paginationInfo,
      };
      this.paginationInfoChanges.emit(this.paginationInfo);
    }
  }

  public decrement() {
    if (this.paginationInfo.page > 1) {
      this.paginationInfo = {
        page: --this.paginationInfo.page,
        ...this.paginationInfo,
      };
      this.paginationInfoChanges.emit(this.paginationInfo);
    }
  }

  public goToStart() {
    const { pageLimit, limit, total } = this.paginationInfo;
    const newPage = {
      page: 1,
      pageLimit,
      total,
      limit,
    };
    this.paginationInfo = newPage;
    this.paginationInfoChanges.emit(newPage);
  }

  public goToEnd() {
    const { pageLimit, limit, total } = this.paginationInfo;
    //Spread operator with prop change doesn't seem to work
    const newPage = {
      page: pageLimit,
      pageLimit,
      total,
      limit,
    };
    this.paginationInfo = newPage;
    this.paginationInfoChanges.emit(newPage);
  }
}
