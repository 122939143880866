<app-page>
  <ng-container *ngIf="(isPageLoading$ | async) === false; else loading">
    <div class="c_teamAllLeaguesHeader">
      <h2 class="u_txt--hdg2 u_txt--white">Team Profile</h2>
      <a
        routerLink="/{{ getTeamDetailRoute(teamId$ | async) }}"
        class="c_button secondary_light c_teamAllLeagues__backBtn"
        >Back to Team Page</a
      >
    </div>
    <div class="c_teamAllLeagues__listBox">
      <h5 class="u_txt--hdg5">Active Leagues</h5>
      <ul class="c_teamAllLeagues__list">
        <li *ngFor="let activeLeague of activeLeagues$ | async">
          <a routerLink="/{{ getLeagueDetailRoute(activeLeague.id) }}">
            {{ activeLeague.title }}
          </a>
        </li>
      </ul>
      <h5 class="u_txt--hdg5">Completed Leagues</h5>
      <ul class="c_teamAllLeagues__list">
        <li *ngFor="let pastLeague of pastLeagues$ | async">
          <a routerLink="/{{ getLeagueDetailRoute(pastLeague.id) }}">
            {{ pastLeague.title }}
          </a>
        </li>
      </ul>
    </div>
  </ng-container>
  <ng-template #loading>
    <app-loader [useWhite]="true">Loading Leagues...</app-loader>
  </ng-template>
</app-page>
