import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-utils";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

interface GetAddressApiResponse {
  id: string;
  type: "addresses";
  attributes: {
    addressFirst?: string;
    addressSecond?: string;
    addressType: "shipping" | "1";
    city?: string;
    country: "USA";
    email?: string;
    firstName?: string;
    lastName?: string;
    state?: string;
    zipCode?: string;
  };
}

interface AddressAPIPayload {
  addresses: {
    addresses_attributes: UserAddress[];
  };
}

export interface UserAddress {
  id?: number | string;
  address_first?: string;
  address_second?: string;
  address_type: "shipping" | "1";
  city?: string;
  country: "USA";
  email?: string;
  first_name?: string;
  last_name?: string;
  state?: string;
  zip_code?: string;
}


@Injectable({
  providedIn: "root",
})
export class UserAddressService {

  constructor(
    private _http: HttpClient
  ) { }

  public getUserAddress(userId: string | number): Observable<UserAddress | null> {
    const url = `${environment.apiBase}/api/v2/users/${userId}/addresses`;
    const opts = {
      headers: basicAuthHeader(),
    };

    return this._http.get<{ data: GetAddressApiResponse[] }>(url, opts).pipe(
      map((apiRes) => {
        if (apiRes.data.length > 0) {
          const { id, attributes } = apiRes.data[0];
          return {
            id,
            address_first: attributes.addressFirst,
            address_second: attributes.addressSecond,
            address_type: "shipping",
            city: attributes.city,
            country: attributes.country,
            email: attributes.email,
            first_name: attributes.firstName,
            last_name: attributes.lastName,
            state: attributes.state,
            zip_code: attributes.zipCode,
          } as UserAddress;
        }

        return null;
      })
    );
  }

  public updateUserAddress(userId: string | number, address: UserAddress): Observable<unknown> {
    const url = `${environment.apiBase}/api/v2/users/${userId}/addresses/manage`;
    const opts = {
      headers: basicAuthHeader(),
    };
    const payload: AddressAPIPayload = {
      addresses: {
        addresses_attributes: [
          {
            country: "USA",
            ...address,
            address_type: "shipping",
          },
        ],
      },
    };

    return this._http.post<unknown>(url, payload, opts);
  }
}
