<app-modal-container [modalTitle]="title" [modalContent]="content">
</app-modal-container>
<ng-template #title>
  <app-modal-title>{{ modalTitle }}</app-modal-title>
</ng-template>

<ng-template #content>
  <div class="c_helpModalContent">
    <ng-content></ng-content>
  </div>
</ng-template>
