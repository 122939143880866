import { environment } from "@environments/environment";

import { StatesAbbreviations } from "src/app/enums/states.enum";
import { SearchOrganization } from "src/app/reducers/organization/organization.types";

export const ggleaguesOrg: SearchOrganization = {
  id: environment.ggleaguesOrgId,
  type: "org",
  slug: environment.ggleaguesOrgSlug,
  name: "GGLeagues",
  state: StatesAbbreviations.IL,
};
