import { Component } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TextNotifServiceService } from "@services/v2/text-notif-service/text-notif-service.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ModalController } from "../modal-controller/modal-controller.abstract";

@Component({
  selector: "app-text-notification-verify-modal",
  templateUrl: "./text-notification-verify-modal.component.html",
  styleUrls: ["./text-notification-verify-modal.component.scss"],
})
export class TextNotificationVerifyModalComponent extends ModalController<void> {
  public static MODAL_ID = "textVerifyModal";

  public verifyForm: FormGroup;
  public formError: null | string;


  constructor(
    private _ngxService: NgxSmartModalService,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _textNotifService: TextNotifServiceService
  ) {
    super(_ngxService, TextNotificationVerifyModalComponent.MODAL_ID, _router);
    this.verifyForm = this._formBuilder.group({
      verifyCode: [undefined, [Validators.required, Validators.pattern(/[0-9]{6}/)]],
    });
  }

  public cancelNumber(): void {
    this._textNotifService.cancelAllTextNotifications().subscribe(
      () => {
        this._ngxService.closeLatestModal();
      }
    );
  }

  public confirmNumber(verifyForm: FormGroup): void {
    this.formError = null;
    if (!verifyForm.valid) {
      this.formError = "You must provide a 6-digit verification code";
      return;
    }
    const codeValue = verifyForm.value.verifyCode;
    this._textNotifService.verifyNumber(codeValue).subscribe(
      () => {
        this._ngxService.closeLatestModal();
      },
      () => {
        this.formError = "An error occured while trying to verify, please try again";
      }
    );
  }
}
