<div class="c_gameCredentials__container">
  <label class="c_gameCredentials__esportTitle"
    >{{ esport }}
    <i
      *ngIf="isInEditMode && gameHasLinks"
      class="fas fa-question-circle"
      (click)="openModal()"
    ></i
  ></label>
  <div class="c_gameCredentials__gameInfoContainer">
    <img
      class="c_gameCredentials__esportImage"
      [src]="showEsportImage"
      alt="{{ esport }} logo"
    />
    <div class="c_gameCredentials__nameContainer">
      <label class="u_txt c_gameCredentials__tagName"
        >{{ showEsportAccountTitle() }}:</label
      >
      <p
        class="u_txt c_gameCredentials__displayName"
        *ngIf="!isInEditMode; else edit"
      >
        {{ gameCredentialName }}
      </p>
      <ng-template #edit>
        <form [formGroup]="controlForm" class="c_gameCredentials__form">
          <input
            formControlName="gameCredential"
            maxlength="100"
            placeholder="{{ placeHolderForCredential }}"
            type="text"
            class="c_input"
          />
        </form>
        <p class="u_txt--warn" *ngIf="hasError">
          <span *ngIf="placeHolderForCredential; else spacesError">
            Your profile name is invalid, it needs to follow the format:
            {{ placeHolderForCredential }}
          </span>
          <ng-template #spacesError>
            <span>
              Your profile name must be at least 3 characters and cannot start
              or end with spaces</span
            >
          </ng-template>
        </p>
      </ng-template>
    </div>
  </div>
</div>
