import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

import { routePaths } from "src/app/enums/routes/routePaths";

@Component({
  selector: "app-match-page",
  templateUrl: "./match-page.component.html",
  styleUrls: ["./match-page.component.scss"],
})
export class MatchPageComponent implements OnInit {
  public routes = routePaths;
  constructor(private _titleService: Title) {}

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | Match Details");
  }
}
