<ng-container *ngIf="league$ | async as league">
  <aside
    class="c_seasonPassLeagueBanner"
    *ngIf="
      league.organizationSeasonPassIds &&
      league.organizationSeasonPassIds.length > 0
    "
  >
    <p class="u_txt">
      <ng-container *ngIf="userHasPass$ | async; else userHasNoPass">
        You've got a <strong>{{ passTitle }}</strong
        >, you're all set to join this league! <i class="fas fa-grin-beam"></i>
      </ng-container>
      <ng-template #userHasNoPass>
        This League Requires a <strong>Seasonal Esports Pass</strong> from one
        of the participating organizations to play:
        <a
          [routerLink]="league.id | leaguePassesRouter"
          class="u_txt--link--dark"
        >
          Click to Get One!
        </a>
      </ng-template>
    </p>
  </aside>
</ng-container>
