import {
  Component,
  Input,
  OnInit,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { loadStripe, Token } from "@stripe/stripe-js";
import { ToastrService } from "ngx-toastr";

import { environment } from "@environments/environment";
import { UserService } from "@services/user/user.service";
import { UserSignupObject } from "@services/user/user.api.types";

import { formHasSpecificError } from "src/app/util/form-helpers";
import { Logger } from "src/app/util/logger";
import { externalRoutes } from "src/app/enums/routes/routePaths";
import { Store } from "@ngrx/store";
import { RootState } from "src/app/reducers";
import { Observable, of } from "rxjs";
import { first, map } from "rxjs/operators";

import * as SignupAnalytics from "@utils/analytics/signup.analytics";
import { LoginNewUser } from "src/app/reducers/user/user.actions";

@Component({
  selector: "app-guardian-verification-form",
  templateUrl: "./guardian-verification-form.component.html",
  styleUrls: ["./guardian-verification-form.component.scss"],
})
export class GuardianVerificationFormComponent implements OnInit {
  @Input() public userSignupData: UserSignupObject;

  public guardianForm: FormGroup;
  public isProcessing = false;
  public externalRoutes = externalRoutes;
  public formSubmitError: string | null = null;

  public redirectUrl$: Observable<string | null> = of(null);


  constructor(
    private _signUpService: UserService,
    private _toastr: ToastrService,
    private _store: Store<RootState>
  ) {
    this.guardianForm = new FormGroup({
      parentEmail: new FormControl("", [Validators.required, Validators.email]),
      consentCheckbox: new FormControl(false, [Validators.requiredTrue]),
    });
    this.redirectUrl$ = this._store.select("user", "userLoginRedirectRoute").pipe(
      first(),
      map((route) => (route || null))
    );
  }

  public async ngOnInit(): Promise<void> {
    const stripe = await loadStripe(environment.stripeApiKey);
    const elements = stripe.elements();

    const style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: "18px",
        color: "#32325d",
      },
    };

    // Create an instance of the card Element.
    const card = elements.create("card", {
      style,
    });

    // Add an instance of the card Element into the `card-element` <div>.
    card.mount("#card-element");

    const stripeTokenHandler = (token: Token) => {
      // Insert the token ID into the form so it gets submitted to the server
      const paymentForm = document.getElementById("payment-form");
      const hiddenInput = document.createElement("input");
      hiddenInput.setAttribute("type", "hidden");
      hiddenInput.setAttribute("name", "stripeToken");
      hiddenInput.setAttribute("value", token.id);
      paymentForm.appendChild(hiddenInput);

      const ERROR_MSG = "There was an error with signup, please try again.  If this persists, reach out to an admin";
      const ERROR_TITLE = "Signup Error";
      const SUCCESS_MSG = "User account successfully created!";
      const SUCCESS_TITLE = "Account Created!";

      const parentEmail = this.guardianForm.get("parentEmail").value;

      this._signUpService
        .createUserWithParentalConsent(parentEmail, token, this.userSignupData)
        .subscribe(
          // Success
          async () => {
            SignupAnalytics.registerSignupSuccessEvent(
              await this.redirectUrl$.toPromise()
            );
            this._toastr.success(SUCCESS_MSG, SUCCESS_TITLE);

            const { email, password } = this.userSignupData;
            const formattedPayload = {
              credentials: {
                email,
                password,
              },
            };

            this._store.dispatch(new LoginNewUser(formattedPayload));
          },
          // Error
          async (error) => {
            this._toastr.error(ERROR_MSG, ERROR_TITLE);
            SignupAnalytics.registerSignupErrorEvent(
              await this.redirectUrl$.toPromise(),
              JSON.stringify(error)
            );
            try {
              Logger.log(error);
              if (error.errors[0][0] === "Email has already been taken") {
                this._setCustomFormError(`The email address "${this.userSignupData.email}" is already in use.`);
              } else if (error.errors[0][0].indexOf("Stripe::CardError") === 0) {
                const cardError = error.errors[0][0].match(/^(Stripe::CardError - )(.+)$/);
                const ERROR_REGEX_GROUP = 2;
                this._setCustomFormError(`The credit card you provided was declined (Reason: ${cardError[ERROR_REGEX_GROUP]})`);
              } else {
                this._setCustomFormError("There was an error signing up.");

              }
            } catch (e) {
              Logger.error("Error parsing error message");
              Logger.error(e);
              this._setCustomFormError("There was an error signing up.");
            }
            this.isProcessing = false;
          }
        );
    };

    // Create a token or display an error when the form is submitted.
    const form = document.getElementById("payment-form");
    form.addEventListener("submit", async (event) => {
      event.preventDefault();
      const errorElement = document.getElementById("card-errors");
      errorElement.textContent = "";

      this.guardianForm.markAllAsTouched();

      if (!this.guardianForm.valid) {
        return;
      }

      this.isProcessing = true;

      const { token, error } = await stripe.createToken(card);

      if (error) {
        // Inform the customer that there was an error.
        errorElement.textContent = error.message;
        Logger.error(error);
        this.isProcessing = false;
      } else {
        // Send the token to your server.
        stripeTokenHandler(token);
      }
    });
  }

  public guardianFormHasSpecificError(controlName: string, errorName: string): boolean {
    return formHasSpecificError(controlName, errorName, this.guardianForm);
  }

  private _setCustomFormError(errMsg: string): void {
    this.formSubmitError = errMsg;
  }
}
