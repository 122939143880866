import { Input } from "@angular/core";
import { Component } from "@angular/core";

import { GenericLeagueDetails } from "src/app/reducers/leagues/league.types";

@Component({
  selector: "app-league-passes-info-block",
  templateUrl: "./league-passes-info-block.component.html",
  styleUrls: ["./league-passes-info-block.component.scss"],
})
export class LeaguePassesInfoBlockComponent {
  @Input() public league!: GenericLeagueDetails;

  public openZendeskModal(): void {
    //This method is for the zendesk widget exposed by vendor scripts
    //SEE: https://developer.zendesk.com/api-reference/widget/core/#open
    // eslint-disable-next-line @typescript-eslint/dot-notation
    window["zE"]("webWidget", "open");
  }
}
