import { HttpClient } from "@angular/common/http";
import { Provider } from "@angular/core";
import { ToastrService } from "ngx-toastr";

import { environment } from "@environments/environment";
import { CloudSeriesMatchupCommentService } from "@services/comment/cloud-series-matchup-comment/cloud-series-matchup-comment.service";
import { CommentValidatorService } from "@services/comment/comment-validator/comment-validator.service";
import { RailsSeriesMatchupCommentService } from "@services/comment/rails-series-matchup-comment/rails-series-matchup-comment.service";

import { COMMENT_SERVICE } from "./comment-service.token";

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function SeriesMatchupCommentFactory(
  http: HttpClient,
  toastr: ToastrService,
  validator: CommentValidatorService
): CloudSeriesMatchupCommentService | RailsSeriesMatchupCommentService {
  return environment.useCloudChats ?
    new CloudSeriesMatchupCommentService(http, toastr, validator) :
    new RailsSeriesMatchupCommentService(http, toastr, validator);
}

/**
 * Provides a CommentService for SeriesMatchup Chatrooms, will automatically
 * inject the cloud service if available
 */
export const SeriesMatchupCommentProvider: Provider = {
  provide: COMMENT_SERVICE,
  useFactory: SeriesMatchupCommentFactory,
  deps: [
    HttpClient,
    ToastrService,
    CommentValidatorService,
  ],
};

