<app-page>
  <header class="c_teamsSearchHeader">
    <h1 class="u_txt--hdg1 u_txt--white">Teams Search</h1>
    <app-need-help-button></app-need-help-button>
  </header>
  <form class="c_teamsSearchBox" (onSubmit)="searchTeams(searchTerm)">
    <input
      class="c_input"
      name="searchTerm"
      [(ngModel)]="searchTerm"
      placeholder="Team Title"
    />
    <button
      type="submit"
      class="c_button primary_light"
      (click)="searchTeams(searchTerm)"
    >
      <i *ngIf="!isSearching" class="fas fa-search"></i>
      <i *ngIf="isSearching" class="fas fa-circle-notch fa-spin"></i>
    </button>
  </form>
  <div class="c_teamsSearchResults">
    <ng-container *ngIf="!isSearching; else loading">
      <ng-container
        *ngIf="searchResults && searchResults.length > 0; else noResults"
      >
        <div
          class="c_teamsSearchResults__team"
          *ngFor="let team of searchResults; let i = index"
          [ngStyle]="{ 'z-index': searchResults.length - i }"
        >
          <h3 class="u_txt--hdg3">
            <a routerLink="/{{ getTeamDetailRoute(team.id) }}">
              {{ team.title }}
            </a>
          </h3>
          <div class="c_teamsSearchResults__team__captain">
            <div>
              <strong class="c_teamsSearchResults__team__label"
                >Team Captain:
              </strong>
            </div>
            <div>
              <a
                class="c_teamsSearchResults__team__captainLink"
                routerLink="/{{ getUserDetailRoute(team.captainId) }}"
                target="_blank"
              >
                {{ team.captain }}
              </a>
            </div>
          </div>
          <div class="c_teamsSearchResults__team__button">
            <button
              (click)="requestToJoinTeam(team.id)"
              class="c_button primary_dark"
            >
              Request to Join Team
            </button>
          </div>
        </div>

        <div class="c_teamsSearchResults__count">
          {{ searchResults.length }} result{{
            searchResults.length > 1 ? "s" : ""
          }}
          found for "{{ lastSearchTerm }}"
        </div>
      </ng-container>
      <ng-template #noResults>
        <app-no-search-results *ngIf="userHasSearched" searchItem="teams">
        </app-no-search-results>
      </ng-template>
    </ng-container>
    <ng-template #loading>
      <app-loader [useWhite]="true">Loading Teams...</app-loader>
    </ng-template>
  </div>
</app-page>
