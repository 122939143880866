<div *ngIf="seriesData" class="c_brTeamPreviewBlock">
  <img class="c_brTeamPreviewBlock__gamelogo" [src]="gameLogoPath" />
  <h2 class="u_txt--hdg2 c_brTeamPreviewBlock__title">
    {{ seriesData.esport }} {{ esportEventType }}
  </h2>
  <p>
    <strong>Total Teams Participating: </strong
    ><span>{{ seriesData.teams.length }}</span>
  </p>
  <div class="c_winnerCopy">
    <h5 class="u_txt--hdg5">
      Series Winner:
      <strong class="c_winnerTitle">{{
        (seriesWinner$ | async) || "TBD"
      }}</strong>
    </h5>
    <small *ngIf="(seriesWinner$ | async) === null"
      >A winner will be declared when all games are completed and scored.
    </small>
  </div>
  <div>
    <button
      *ngIf="seriesData.esport === gamePlatforms.FORTNITE"
      class="c_button primary_dark c_button--slim"
      (click)="openAggregateResults()"
    >
      Show Series Results
    </button>
  </div>
</div>
