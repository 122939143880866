import { Component, Input } from "@angular/core";
import { environment } from "@environments/environment";
import { StatesAbbreviations } from "src/app/enums/states.enum";
import { SearchOrganization } from "src/app/reducers/organization/organization.types";

@Component({
  selector: "app-no-search-results",
  templateUrl: "./no-search-results.component.html",
  styleUrls: ["./no-search-results.component.scss"],
})
export class NoSearchResultsComponent {
  @Input() public searchItem = "results";
  @Input() public clarificationString = "";
  @Input() public hasWhiteText = true;
  @Input() public isOrg = false;

  public ggleaguesOrg: SearchOrganization = {
    id: environment.ggleaguesOrgId,
    type: "org",
    slug: environment.ggleaguesOrgSlug,
    name: "GGLeagues",
    state: StatesAbbreviations.IL,
  };
}
