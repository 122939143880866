<ng-container *ngIf="!isLoading; else loading">
  <ng-container *ngIf="!hasError; else error">
    <div class="c_leagueMatchBlock__filterButtons">
      <button
        class="c_button c_button--slim"
        (click)="setUpcomingMatches()"
        [ngClass]="{ primary_dark: isUpcoming, secondary_dark: !isUpcoming }"
      >
        Upcoming Matches
      </button>
      <button
        class="c_button c_button--slim secondary_dark"
        (click)="setPlayedMatches()"
        [ngClass]="{ primary_dark: !isUpcoming, secondary_dark: isUpcoming }"
      >
        Played Matches
      </button>
      <a class="c_linkBtn" [routerLink]="scheduleLink">
        League Schedule <i class="fas fa-external-link-alt"></i
      ></a>
    </div>
    <div
      *ngIf="isUpcoming && sortedMatches.length > 0"
      class="c_leagueMatchBlock__playedMatchesHeader"
    >
      <div>
        <h3 class="u_txt--hdg3">
          {{ getYearMonthDisplay(selectedYearMonth) }} Matches
        </h3>
        <small> Today's Date: {{ today | date: "EEEE MMMM d, yyyy" }} </small>
      </div>
      <div>
        <label>
          Month Filter:
          <select class="c_input" [(ngModel)]="selectedYearMonth">
            <option
              *ngFor="let yearMonth of yearMonthList"
              [ngValue]="yearMonth"
            >
              {{ getYearMonthDisplay(yearMonth) }}
            </option>
          </select>
        </label>
      </div>
    </div>
    <h3 class="u_txt--hdg3" *ngIf="!isUpcoming">Recently Played Matches</h3>
    <ng-container *ngIf="sortedMatches.length > 0; else noMatches">
      <ul class="c_leagueMatchBlock__matchList">
        <li *ngFor="let match of dateFilteredMatchList; let i = index">
          <app-league-match-block
            [leagueMatch]="match"
            [isDateActive]="i === 0"
            [isGrayedOut]="!isUpcoming"
          >
          </app-league-match-block>
        </li>
      </ul>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <app-loader>Loading Matches...</app-loader>
</ng-template>

<ng-template #noMatches>
  <div class="c_leagueMatchesBlock__noMatches">
    <i class="fas fa-search-minus fa-2x"></i>
    <p *ngIf="allMatches.length === 0; else upcomingAndPlayed">
      There Are No Matches For This League
    </p>
    <ng-template #upcomingAndPlayed>
      <p>
        <ng-container *ngIf="isUpcoming; else noPlayed">
          There Are No Upcoming Matches For This League
        </ng-container>
        <ng-template #noPlayed>
          There Are No Played Matches For This League
        </ng-template>
      </p>
    </ng-template>
  </div>
</ng-template>

<ng-template #error>
  <div class="c_leagueMatchesBlock__error">
    <i class="fas fa-exclamation-triangle fa-5x"></i>
    <h3 class="u_txt--hdg3">There Was an Error Loading the Matches</h3>
    <p>Please contact the GGLeagues staff for more information</p>
  </div>
</ng-template>
