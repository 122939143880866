import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
} from "@angular/router";
import { SessionStorageKeys } from "../enums/session-storage-keys.enum";

@Injectable({
  providedIn: "root",
})
export class RedirectGuard implements CanActivate {

  constructor(private router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    // Don't process exit analytics on a route redirect
    localStorage.setItem(SessionStorageKeys.IGNORE_EXIT_ANALYTIC, "true");
    window.location.href = route.data.externalUrl;
    return true;
  }
}
