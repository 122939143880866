import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-about-page",
  templateUrl: "./about-page.component.html",
  styleUrls: ["./about-page.component.scss"],
})
export class AboutPageComponent implements OnInit {
  constructor(private _titleService: Title) {}

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | About Us");
  }
}
