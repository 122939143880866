import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { Store } from "@ngrx/store";

import { ModalStep } from "@apptypes/view-types/modal.types";
import { MatchReportService } from "@services/match-report/match-report.service";
import { TeamWinsMap } from "@services/match-report/match-report.types";
import { Logger } from "@utils/logger";

import { RootState } from "src/app/reducers";
import { GetLeagueDashboard } from "src/app/reducers/dashboard/dashboard.actions";
import { GetSeriesMatchupById } from "src/app/reducers/matches/matches.actions";
import { MatchReport, MatchReportGame } from "src/app/reducers/matches/matches.types";
import { ModalStepperFooterButtonsComponent } from "../modal-stepper-footer-buttons/modal-stepper-footer-buttons.component";

@Component({
  selector: "app-report-confirmation-step",
  templateUrl: "./report-confirmation-step.component.html",
  styleUrls: ["./report-confirmation-step.component.scss"],
})
export class ReportConfirmationStepComponent implements OnChanges, ModalStep {
  @Input() public matchReport: MatchReport;
  @Input() public gameReports: MatchReportGame[];
  @Output() public submitReportSuccessEvent: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild("footerButtons") public footerButtons: TemplateRef<ModalStepperFooterButtonsComponent>;

  public notEnoughGamesToDeclareWinner = false;
  // eslint-disable-next-line object-curly-newline
  public teamWinsMap: TeamWinsMap = {};

  constructor(
    private _matchService: MatchReportService,
    private _toastr: ToastrService,
    private _store: Store<RootState>
  ) {}

  public ngOnChanges({ gameReports }: SimpleChanges): void {
    /**
     * Using this over getters to prevent these from being run on
     * every change detection, however, it'll be quicker to
     * run this function on every change detection for this input
     * than to compare previousValue and currentValue every time
     */
    if(gameReports && !!this.gameReports){
      this.notEnoughGamesToDeclareWinner = !MatchReportService.doGamesHaveEnoughToDeclareWinner(
        this.gameReports, this.matchReport.teams
      );
      this.teamWinsMap = MatchReportService.generateTeamWinsMap(this.gameReports, this.matchReport.teams);
    }
  }

  public get teamOneWins(): number {
    return this.teamWinsMap[this.matchReport.teams[0]?.id] ?? 0;
  }

  public get teamTwoWins(): number {
    return this.teamWinsMap[this.matchReport.teams[1]?.id] ?? 0;
  }

  public submitReport() {
    this._matchService
      .reportResults({
        ...this.matchReport,
        games: this.gameReports,
      })
      .pipe(
        catchError((err) => {
          Logger.error(err);
          this._toastr.error("There was an issue submitting your match results. Please try again or contact an admin");
          return of(null);
        })
      )
      .subscribe((res) => {
        if(res){
          this._store.dispatch(new GetLeagueDashboard());
          this._store.dispatch(new GetSeriesMatchupById(this.matchReport.seriesMatchupsId));
          this._toastr.success("Your match results have been successfully reported.");
          this.submitReportSuccessEvent.emit();
        }
      });
  }
}
