import {
  Component,
  Input,
  OnInit,
} from "@angular/core";

import { GenericLeagueMatch, GenericLeagueTeam } from "src/app/reducers/leagues/league.types";
import { processImage } from "src/app/util/image-utils";
import { isoStringToShortWithTime } from "src/app/util/date-utils";
import { generateMatchDetailRoute, generateSeriesDetailRoute } from "src/app/enums/routes/routePaths";

@Component({
  selector: "app-small-match-block",
  templateUrl: "./small-match-block.component.html",
  styleUrls: ["./small-match-block.component.scss"],
})
export class SmallMatchBlockComponent implements OnInit {
  @Input() public match: GenericLeagueMatch;

  public teamOneLogo: string | null;
  public teamTwoLogo: string | null;

  public ngOnInit() {
    const [teamOne, teamTwo] = this.match.teams;
    this.teamOneLogo = !!teamOne ? processImage(teamOne.image) : null;
    this.teamTwoLogo = !!teamTwo ? processImage(teamTwo.image) : null;
  }

  public get teamOne(): GenericLeagueTeam | null {
    return this.match.teams[0] ?? null;
  }

  public get teamTwo(): GenericLeagueTeam | null {
    return this.match.teams[1] ?? null;
  }

  public processDate(date: string): string {
    return isoStringToShortWithTime(date);
  }

  public getMatchDetailRoute(id: string): string {
    if (this.match.isSeries) {
      return generateSeriesDetailRoute(id);
    }

    return generateMatchDetailRoute(id);
  }
}
