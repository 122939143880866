<section class="c_loginPage">
  <div
    class="u_txt--white c_loginPage__redirectBanner"
    *ngIf="isRedirect$ | async"
  >
    <span *ngIf="isInstantInviteRedirect$ | async; else genericRedirect">
      Please login to accept your instant invite
    </span>
    <ng-template #genericRedirect>
      You must be logged in to view the page you requested
    </ng-template>
  </div>
  <div
    class="c_loginPage__redirectBanner"
    *ngIf="isSignupConfirmation$ | async"
  >
    Your GGLeagues account has been created! You can now log in!
  </div>
  <div class="c_loginPage__redirectBanner" *ngIf="isReauth$ | async">
    Your GGLeagues Session has Expired. Please log in again.
  </div>
  <div class="c_loginBoxContainer">
    <form
      class="c_loginBox"
      (submit)="loginUser()"
      [formGroup]="loginFormGroup"
    >
      <h2 class="u_txt--hdg2">Login to Your Account</h2>
      <br />
      <label class="c_inputLabel" for="email">Email Address</label>
      <input
        class="c_input"
        type="text"
        id="email"
        formControlName="email"
        placeholder="Email"
      />
      <br />
      <label class="c_inputLabel" for="password">Password</label>
      <input
        class="c_input"
        type="password"
        id="password"
        formControlName="password"
        placeholder="Password"
      />
      <br />
      <a
        routerLink="/{{ userRoutes.PASSWORD_RESET }}"
        class="c_login__forgotPwd"
        >Forgot my password</a
      >
      <br />
      <br />
      <span>
        Need to make an account?
        <br />
        <a [routerLink]="signupRoute" class="c_login__forgotPwd"
          >Click here to sign up</a
        >
      </span>
      <br />
      <br />
      <app-form-footer-error-message
        *ngIf="formInvalid"
      ></app-form-footer-error-message>
      <app-form-loading-button [isLoading]="isLoggingIn$ | async"
        >Login</app-form-loading-button
      >
    </form>
  </div>
</section>
