import { Action } from "@ngrx/store";

import { SeriesMatchupV2 } from "./matches.types";

export enum MatchesActionTypes {
  GET_SERIES_MATCHUP_BY_ID = "[Match] Fetching a Series Matchup",
  GET_SERIES_MATCHUP_BY_ID_ERROR = "[Match] Failed to fetch a Series Matchup",
  GET_SERIES_MATCHUP_BY_ID_SUCCESS = "[Match] Fetched a Series Matchup",
  UPDATE_CURRENT_SERIES_MATCHUP_DATA = "[Match] Current series data updated",
  FETCH_UNKNOWN_MATCH_COMMENTERS = "[Match] Fetch unknown match commenters",
  FETCH_UNKNOWN_MATCH_COMMENTERS_ERROR = "[Match] Fetch unknown match commenters error",
  UPDATE_MATCH_COMMENTERS = "[Match] Update Match Commenters",
  CLEAR_MATCH_COMMENTERS = "[Match] Clear Match Commenters",
}

export class GetSeriesMatchupById implements Action {
  public readonly type = MatchesActionTypes.GET_SERIES_MATCHUP_BY_ID;
  constructor(public payload: string) {}
}

export class GetSeriesMatchupByIdError implements Action {
  public readonly type = MatchesActionTypes.GET_SERIES_MATCHUP_BY_ID_ERROR;
  constructor(public payload: void) {}
}

export class GetSeriesMatchupByIdSuccess implements Action {
  public readonly type = MatchesActionTypes.GET_SERIES_MATCHUP_BY_ID_SUCCESS;
  constructor(public payload: SeriesMatchupV2) {}
}

export class UpdateCurrentSeries implements Action {
  public readonly type = MatchesActionTypes.UPDATE_CURRENT_SERIES_MATCHUP_DATA;
  constructor(public payload: Partial<SeriesMatchupV2>) {}
}

export class FetchUnknownMatchCommenters implements Action {
  public readonly type = MatchesActionTypes.FETCH_UNKNOWN_MATCH_COMMENTERS;
  constructor(public payload: string[]) {}
}

export class FetchUnknownMatchCommentersError implements Action {
  public readonly type = MatchesActionTypes.FETCH_UNKNOWN_MATCH_COMMENTERS_ERROR;
  constructor(public payload: void) {}
}

/**
 * @param payload [userid, username][]
 */
export class UpdateMatchCommenters implements Action {
  public readonly type = MatchesActionTypes.UPDATE_MATCH_COMMENTERS;
  constructor(public payload: [string, string][] = []) {}
}

export class ClearMatchCommenters implements Action {
  public readonly type = MatchesActionTypes.CLEAR_MATCH_COMMENTERS;
  constructor(public payload: void) {}
}

export type MatchesActions =
  GetSeriesMatchupById |
  GetSeriesMatchupByIdError |
  GetSeriesMatchupByIdSuccess |
  UpdateCurrentSeries |
  UpdateMatchCommenters |
  ClearMatchCommenters |
  FetchUnknownMatchCommenters |
  FetchUnknownMatchCommentersError;
