<a
  routerLink="/{{ generateRoute(rosterMemberData.id) }}"
  class="c_largeRosterIcon"
>
  <div class="c_largeRosterIcon__captain">
    {{ rosterMemberData.isCaptain ? "Captain" : "Member" }}
  </div>
  <div
    class="c_largeRosterIcon__icon"
    [ngClass]="{
      'c_largeRosterIcon__icon--captain': rosterMemberData.isCaptain
    }"
    [ngStyle]="{ 'background-image': userImage }"
  ></div>
  <div class="c_largeRosterIcon__name u_txt--bold">
    {{ rosterMemberData.inGameName }}
  </div>
</a>
