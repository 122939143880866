<form [formGroup]="controlForm">
  <div>Please select your referal</div>
  <input
    placeholder="Ex. 1 Main St, New York, NY"
    class="c_input c_referralChoice__filter"
    type="text"
    (keyup)="referralFilterInput$.next($event.target.value)"
  />
  <div class="c_referralChoice__referralList">
    <div *ngFor="let referral of referralList">
      <input
        id="referral_{{ referral.id }}"
        class="c_referralChoice__radioInput"
        type="radio"
        [value]="referral"
        formControlName="selectedReferral"
      />
      <label
        for="referral_{{ referral.id }}"
        class="c_referralChoice__label"
        [ngClass]="{
          c_referralChoice__selectedRadio: isReferralSelected(referral.id)
        }"
      >
        <span class="u_txt c_referralChoice__name">
          {{ referral.name }}
        </span>
      </label>
    </div>
  </div>
  <app-modal-stepper-footer-buttons
    [hasPrevious]="!isFirstStep"
    [hasNext]="true"
    [formToCheck]="controlForm"
    [errorMessage]="errorMessage"
  >
  </app-modal-stepper-footer-buttons>
</form>
