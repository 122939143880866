<app-page>
  <ng-container *ngIf="userProfile$ | async as profile; else loading">
    <header
      class="c_userPageHeader"
      *ngIf="isMyProfile$ | async; else otherProfile"
    >
      <h2 class="u_txt--hdg2 u_txt--white">My Profile</h2>
      <app-need-help-button></app-need-help-button>
    </header>
    <ng-template #otherProfile>
      <header class="c_userPageHeader">
        <h2 class="u_txt--hdg2 u_txt--white">User Profile</h2>
        <app-need-help-button></app-need-help-button>
      </header>
    </ng-template>
    <div class="c_userPageGrid">
      <div class="c_userPage__playerBlock">
        <div>
          <app-player-info-block
            [user]="userProfile$ | async"
          ></app-player-info-block>
        </div>
      </div>
      <div class="c_userPage__teamBlock">
        <app-player-team-block
          [teams]="sortTeams(profile.teams)"
        ></app-player-team-block>
      </div>
      <div class="c_userPage__statsBlock">
        <app-game-credentials-layout
          [isUser]="isMyProfile$ | async"
        ></app-game-credentials-layout>
      </div>
      <div
        class="c_userPage__passesBlock"
        *ngIf="isMyProfile$ | async"
        id="myPasses"
      >
        <app-profile-passes-panel
          [myPasses]="(userProfile$ | async)?.gamePasses"
        ></app-profile-passes-panel>
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <app-loader [useWhite]="true">Loading Player...</app-loader>
  </ng-template>
</app-page>
