import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import {
  filter,
  mergeMap,
  pluck,
  take,
} from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Router } from "@angular/router";

import { MatchesService } from "@services/matches/matches.service";

import { RootState } from "src/app/reducers";
import { ModalController } from "../modal-controller/modal-controller.abstract";

@Component({
  selector: "app-dispute-results-modal",
  templateUrl: "./dispute-results-modal.component.html",
  styleUrls: ["./dispute-results-modal.component.scss"],
})
export class DisputeResultsModalComponent extends ModalController<null> implements OnInit {
  public disputeForm: FormGroup;

  private _seriesMatchupId$: Observable<string>;
  private _hasBlockingErrors = false;
  constructor(
    private _store: Store<RootState>,
    private _toastr: ToastrService,
    private _matchService: MatchesService,
    private _formBuilder: FormBuilder,
    private _modalService: NgxSmartModalService,
    private _router: Router
  ) {
    super(_modalService, "disputeResults", _router);
    this.disputeForm = this._formBuilder.group({
      disputeDescription: [null, Validators.required],
    });
  }

  public ngOnInit() {
    this._seriesMatchupId$ = this._store.select("matches", "seriesMatchup").pipe(
      filter((seriesMatchup) => !!seriesMatchup),
      pluck("id"),
      take(1)
    );
  }

  /**
   * Reads the dispute form and sends a post request to the server, notify the user
   * for error and success via toasts
   *
   * @author Christian Tweed
   */
  public sendDispute() {
    this.disputeForm.markAllAsTouched();
    if (this.disputeForm.valid) {
      const disputeDescription = this.disputeForm.get("disputeDescription").value;
      this._seriesMatchupId$
        .pipe(mergeMap((seriesMatchupId) => this._matchService.disputeResults(seriesMatchupId, disputeDescription)))
        .subscribe((serverResponse) => {
          if (serverResponse) {
            this._toastr.success("Your dispute has been submitted. A GGLeagues member will reach out to both teams.", "Success");
          } else {
            this._toastr.error("Your dispute could not be processed", "Error");
          }
          this.close();
        });
    }
  }

  public get disputeDescriptionInvalid(): boolean {
    const disputeDescription = this.disputeForm.get("disputeDescription");
    return (disputeDescription.dirty || disputeDescription.touched) && disputeDescription.invalid;
  }
}
