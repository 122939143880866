import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";

import { gameCredentialAddFriendsLinks, gamePlatforms } from "src/app/enums/game-platforms.enum";
import { ModalController } from "../modal-controller/modal-controller.abstract";

@Component({
  selector: "app-add-friends-modal",
  templateUrl: "./add-friends-modal.component.html",
  styleUrls: ["./add-friends-modal.component.scss"],
})
export class AddFriendsModalComponent extends ModalController<gamePlatforms> {
  public static modalID = "addFriend";
  constructor(private _ngxService: NgxSmartModalService, private _router: Router) {
    super(_ngxService, AddFriendsModalComponent.modalID, _router);
  }

  public get esport(): gamePlatforms {
    return this.getData();
  }

  public get friendLinks() {
    return gameCredentialAddFriendsLinks.get(this.esport) ?? [];
  }
}
