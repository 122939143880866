import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ModalController } from "../modal-controller/modal-controller.abstract";

@Component({
  selector: "app-queue-no-match-modal",
  templateUrl: "./queue-no-match-modal.component.html",
  styleUrls: ["./queue-no-match-modal.component.scss"],
})
export class QueueNoMatchModalComponent extends ModalController<void> {

  public static MODAL_NAME = "queue_no_match_modal";

  constructor(private _modalService: NgxSmartModalService, private _router: Router) {
    super(_modalService, QueueNoMatchModalComponent.MODAL_NAME, _router);
  }
}
