import { Action } from "@ngrx/store";

import { QuickPlayEvent } from "@apptypes/quick-play-events.types";

import {
  GenericLeagueMatch,
  GenericLeagueDetails,
  LeagueBracketDetails,
  LeagueTeam,
  LeagueEnrollment,
} from "./league.types";

export enum LeagueActionTypes {
  GET_LEAGUE_BY_ID = "[Leagues] Get League By Id",
  GET_LEAGUE_BY_ID_SUCCESS = "[Leagues] Get League By Id Success",
  GET_LEAGUE_BY_ID_ERROR = "[Leagues] Get League By Id Error",
  UPDATE_LEAGUE_BRACKET_DATA = "[Leagues] Update League Bracket Data",
  UPDATE_LEAGUE_TEAM_DATA = "[League] Update League Team Data",
  UPDATE_LEAGUE_ENROLLMENT_DATA = "[League] Update League Enrollment Data",
  GET_QUICK_PLAY_EVENT_BY_LEAGUE_ID = "[Leagues] Get QuickPlayEvent by League ID",
  GET_QUICK_PLAY_EVENT_BY_ID_LEAGUE_ERROR = "[Leagues] Failed to Get QuickPlayEvent by League ID",
  GET_QUICK_PLAY_EVENT_BY_ID_LEAGUE_SUCCESS = "[Leagues] Get QuickPlayEvent by League ID Success",
  GET_LEAGUE_BRACKETS = "[Leagues] Get League Brackets",
  GET_LEAGUE_BRACKETS_ERROR = "[Leagues] Failed to Get League Brackets",
  CLEAR_QUICK_PLAY_EVENT = "[Leagues] Clear QuickPlayEvent",
}

export class GetLeagueById implements Action {
  public readonly type = LeagueActionTypes.GET_LEAGUE_BY_ID;
  constructor(public payload: number | string) { }
}

export class GetLeagueByIdError implements Action {
  public readonly type = LeagueActionTypes.GET_LEAGUE_BY_ID_ERROR;
  constructor(public payload: void) { }
}

export class GetLeagueByIdSuccess implements Action {
  public readonly type = LeagueActionTypes.GET_LEAGUE_BY_ID_SUCCESS;
  constructor(public payload: GenericLeagueDetails) { }
}

export class UpdateLeagueEnrollmentStatus implements Action {
  public readonly type = LeagueActionTypes.UPDATE_LEAGUE_ENROLLMENT_DATA;
  constructor(public payload: LeagueEnrollment | null) { }
}

export class UpdateLeagueBrackets implements Action {
  public readonly type = LeagueActionTypes.UPDATE_LEAGUE_BRACKET_DATA;
  constructor(public payload: LeagueBracketDetails[]) { }
}

export class UpdateLeagueTeams implements Action {
  public readonly type = LeagueActionTypes.UPDATE_LEAGUE_TEAM_DATA;
  constructor(public payload: LeagueTeam[]) { }
}

export class UpdateLeagueMatches implements Action {
  public readonly type = LeagueActionTypes.GET_LEAGUE_BY_ID;
  constructor(public payload: GenericLeagueMatch[]) { }
}

export class GetQuickPlayEventByLeagueID implements Action {
  public readonly type = LeagueActionTypes.GET_QUICK_PLAY_EVENT_BY_LEAGUE_ID;
  constructor(public payload: string) {}
}

export class GetQuickPlayEventByLeagueIDError implements Action {
  public readonly type = LeagueActionTypes.GET_QUICK_PLAY_EVENT_BY_ID_LEAGUE_ERROR;
  constructor(public payload: void) {}
}

export class GetQuickPlayEventByLeagueIDSuccess implements Action {
  public readonly type = LeagueActionTypes.GET_QUICK_PLAY_EVENT_BY_ID_LEAGUE_SUCCESS;
  constructor(public payload: QuickPlayEvent) {}
}

export class GetLeagueBrackets implements Action {
  public readonly type = LeagueActionTypes.GET_LEAGUE_BRACKETS;
  constructor(public payload: string) {}
}

export class GetLeagueBracketsError implements Action {
  public readonly type = LeagueActionTypes.GET_LEAGUE_BRACKETS_ERROR;
  constructor(public payload: void) {}
}

export class ClearQuickPlayEvent implements Action {
  public readonly type = LeagueActionTypes.CLEAR_QUICK_PLAY_EVENT;
  constructor(public payload: void) {}
}

export type LeagueActions =
  | GetLeagueById
  | GetLeagueByIdSuccess
  | GetLeagueByIdError
  | UpdateLeagueBrackets
  | UpdateLeagueTeams
  | UpdateLeagueEnrollmentStatus
  | GetQuickPlayEventByLeagueID
  | GetQuickPlayEventByLeagueIDError
  | GetQuickPlayEventByLeagueIDSuccess
  | GetLeagueBrackets
  | GetLeagueBracketsError
  | ClearQuickPlayEvent;
