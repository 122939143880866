import { ViewportScroller } from "@angular/common";
import { Component } from "@angular/core";

import { PlayerGuides } from "src/app/enums/resources/document-links/player-guides.enum";

@Component({
  selector: "app-parents",
  templateUrl: "./parents.component.html",
  styleUrls: ["./parents.component.scss"],
})
export class ParentsComponent {
  public playerGuides = PlayerGuides;

  constructor(private _viewportScroller: ViewportScroller) {}

  public scrollToForm(): void {
    this._viewportScroller.scrollToAnchor("form");
  }
}
