<div class="c_passDetailsPanel">
  <ng-container *ngIf="seasonPass.acceptingRegistration; else notAccepting">
    <p *ngIf="userOwnsPass">
      Congrats! You already activated this Esports Pass on your account!
      <strong class="c_passDetailsPanel__focusText">
        <app-season-pass-league-count-context
          [seasonPass]="seasonPass"
        ></app-season-pass-league-count-context>
      </strong>
      are available to join with your current GGLeagues account! Use the filters
      down below to search for something you'd like to join!
    </p>
    <p *ngIf="!userOwnsPass">
      This pass will make
      <strong class="c_passDetailsPanel__focusText">
        <app-season-pass-league-count-context
          [seasonPass]="seasonPass"
        ></app-season-pass-league-count-context>
      </strong>
      available to join with your current GGLeagues account!
    </p>
    <section>
      <h4 class="u_txt--hdg4">What does the Season Esports Pass Offer?</h4>
      <p>
        When you purchase an esports season pass, you gain access to a wide
        variety of exciting esport programs and events! Season passes provide
        entry into your community’s seasonal recreational leagues, which are
        safe, friendly, staff-moderated at all times. Outside of our
        recreational esport leagues, season pass holders also gain access to
        things such as:
      </p>
      <ul>
        <li>Special one-off esport tournaments</li>
        <li>Requested Scrimmages with other players</li>
        <li>Panels and discussions with esport professionals!</li>
        <li>
          GGLeague’s resource library on a variety of different topics within
          esports
        </li>
        <li>
          Access to other fun events such as Minecraft servers, one-off game
          nights, and more!
        </li>
      </ul>
    </section>
    <section *ngIf="showTallmadgeWaiver">
      <blockquote>
        By clicking through to the next landing page and in consideration of
        your participation, you hereby acknowledge and consent to all assumption
        of risk, waivers of liability and indemnity that are applied in the
        Terms and Conditions and Privacy Policy to GGLeagues also applying to
        the City of Tallmadge and do hereby expressly release and hold harmless
        the City of Tallmadge, it officers, employees, agents and assigns from
        any and all liability, claims, costs, expenses and/or damages associated
        with such participation.
      </blockquote>
    </section>
    <section *ngIf="showHopewellWaiver">
      <blockquote>
        By purchasing this pass I hereby acknowledge and agree that there is the
        potential for risk of injury by my/my child’s participation in this
        event, and I knowingly and voluntarily assume all risk associated
        therewith. I also agree to indemnify and hold harmless the City of
        Hopewell, its officers, employees, and agents from and against any and
        all claims of liability for injury to person or property, foreseeable
        and unforeseeable, which I/my minor child may sustain as a result of, or
        in any way connected with, my/my minor child’s use of any City of
        Hopewell facility, or participation in any activity, program conducted,
        hosted, or sponsored by the City of Hopewell. If any provision of this
        application is held void or of no effect by a court of competent
        jurisdiction, the remainder shall be effective to the extent permitted
        under Virginia law.
        <br />
        Photos and Video: The City of Hopewell reserves the right to use any
        photo or video taken of any person participating in a City-sponsored
        program or event for future publication.
      </blockquote>
    </section>
    <div class="c_passDetailsPanel__options" *ngIf="!userOwnsPass">
      <button
        class="c_button primary_dark"
        *ngIf="seasonPass.registrationType === passTypes.CODE"
        (click)="openSponsorPassModal()"
      >
        I have already purchased a {{ seasonPass.title }} Pass and need to
        activate it
      </button>
      <button
        class="c_button primary_dark"
        *ngIf="seasonPass.registrationType === passTypes.CODE"
        (click)="openSponsorPassModalPurchaseInfo()"
      >
        I need to purchase a {{ seasonPass.title }} Pass
      </button>
      <button
        class="c_button primary_dark"
        *ngIf="seasonPass.registrationType === passTypes.PAYMENT"
        (click)="openPurchasePassModal(showTallmadgeWaiver)"
      >
        I would like to purchase a {{ seasonPass.title }} Pass
      </button>
    </div>
  </ng-container>
  <ng-template #notAccepting>
    <p>This season pass is no longer available for purchase.</p>
    <p>
      If you believe you have reached this page in error,
      <a (click)="zendeskService.openCustomerSupport()"
        >please reach out to our support staff</a
      >
    </p>
  </ng-template>
</div>
