import { Component, Input } from "@angular/core";

/**
 * A card that has an image for the top third, and the remainder is filled with a title and extra content.
 * The title is specified with a "title" attribute
 *
 * @class PreviewCardComponent
 * @exports
 */
@Component({
  selector: "app-preview-card",
  templateUrl: "./preview-card.component.html",
  styleUrls: ["./preview-card.component.scss"],
})
export class PreviewCardComponent {
  @Input() public imageUrl = "";
}
