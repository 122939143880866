<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
></app-modal-container>

<ng-template #title>
  <app-modal-title>Results Already Submitted</app-modal-title>
</ng-template>

<ng-template #content>
  <p>
    Someone has already submitted results for this match, we've updated the page
    data for you to see the score!
  </p>
</ng-template>
