import { FormGroup, FormControl } from "@angular/forms";

import { ImageResizeService, ProcessedFile } from "@services/images/image-resize/image-resize.service";

import { Logger as logger } from "../../util/logger";
import { FormController } from "../form-controller/form-controller.abstract";

/**
 * An abstract class to handle many of the repetitive file operations we handle, and allows us
 * to keep the image resizing in one place. As with the FormController, we need to call _loadForm()
 * before any other operations
 *
 * @author Christian Tweed
 */
export abstract class FileFormController extends FormController {
  constructor(private _imageResize: ImageResizeService) {
    super();
  }

  /**
   * When a user uploads an image, we resize it and with the loaded
   * file we set the form value "loadedFile" with the new file
   *
   * @param event
   * @param formControlName is the formControl the user inputs the file into
   * @author Christian Tweed
   */
  public onImageSelect($event: Event, formControlName: string): void {
    this._formGroup.get(formControlName).markAsTouched();
    this._imageResize.readImage($event)
      .then((processedFile) => {
        //We don't set it to the same formControlName because if we do we cause an infinite loop of
        //file updates
        this._formGroup.patchValue({
          loadedFile: processedFile,
        });
        this._formGroup.get("loadedFile").markAsDirty();
      })
      .catch((err) => {
        logger.error(err);
        this._formGroup.get(formControlName).setErrors({
          invalidImage: true,
        });
      });
  }

  /**
   * Reset the loaded file form control. Remove errors
   * from the given form control and mark it as pristing
   *
   * @param formControlName
   * @author Christian Tweed
   */
  public clear(formControlName: string): void {
    this._formGroup.get("loadedFile").reset();
    const fileUploadFormControl = this._formGroup.get(formControlName);
    if(fileUploadFormControl){
      fileUploadFormControl.setErrors(null);
      fileUploadFormControl.markAsPristine();
    }
  }

  public get showClearButton(): boolean {
    return this._formGroup.get("loadedFile").dirty;
  }

  public get imageFile(): ProcessedFile {
    return this._formGroup.get("loadedFile").value;
  }

  /**
   * Links a given form to the controller and adds a
   * control for loading files into
   *
   * @override _loadForm (adds a control)
   * @author Christian Tweed
   */
  protected _loadForm(form: FormGroup) {
    this._formGroup = form;
    this._formGroup.addControl("loadedFile", new FormControl(null));
  }
}
