/* eslint-disable max-len */
//Angular Core
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

//Components
import { PageComponent } from "../components/page/page.component";
import { CheckboxComponent } from "./components/checkbox/checkbox.component";
import { LoaderComponent } from "../components/loader/loader.component";
import { ClipboardCopyDirective } from "./directives/clipboard-copy/clipboard-copy.directive";
import { IconColumnBlockComponent } from "../components/icon-column-block/icon-column-block.component";
import { IconColumnComponent } from "../components/icon-column/icon-column.component";
import { TabsBlockComponent } from "../components/tabs-block/tabs-block.component";
import { TabComponent } from "../components/tab/tab.component";
import { ReadMoreContainerComponent } from "../components/read-more-container/read-more-container.component";
import { SearchPaginationFooterComponent } from "../components/search-pagination-footer/search-pagination-footer.component";
import { NoSearchResultsComponent } from "../components/no-search-results/no-search-results.component";
import { LeagueSearchResultComponent } from "../components/league-search-result/league-search-result.component";
import { LogoSvgWhiteComponent } from "../components/logo-svg-white/logo-svg-white.component";
import { OrganizationSearchResultComponent } from "../components/organization-search-result/organization-search-result.component";
import { EsportCardComponent } from "../league-search/components/esport-card/esport-card.component";
import { PaginatorComponent } from "../league-search/components/paginator/paginator.component";

//Pipes
import { PassDetailsRouterPipe } from "@pipes/routing-pipes/pass-details-router/pass-details-router.pipe";
import { OrgContextQueryPipe } from "@pipes/routing-pipes/query-pipes/org-context-query/org-context-query.pipe";
import { ImgSrcLogoSvgPipe } from "@pipes/image-pipes/img-src-logo-svg/img-src-logo-svg.pipe";
import { LeagueDetailRouterPipe } from "@pipes/routing-pipes/league-detail-router/league-detail-router.pipe";
import { OrganizationDetailRouterPipe } from "@pipes/routing-pipes/organization-detail-router/organization-detail-router.pipe";
import { ModalTitleComponent } from "../components/modal-title/modal-title.component";
import { ModalContainerComponent } from "../components/modal-container/modal-container.component";
import { ModalFooterButtonsComponent } from "../components/modal-footer-buttons/modal-footer-buttons.component";
import { StepperComponent } from "../components/stepper/stepper.component";
import { StepComponent } from "../components/step/step.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [
    PageComponent,
    CheckboxComponent,
    ClipboardCopyDirective,
    LoaderComponent,
    IconColumnBlockComponent,
    IconColumnComponent,
    TabsBlockComponent,
    TabComponent,
    ReadMoreContainerComponent,
    PassDetailsRouterPipe,
    OrgContextQueryPipe,
    ImgSrcLogoSvgPipe,
    SearchPaginationFooterComponent,
    NoSearchResultsComponent,
    LeagueSearchResultComponent,
    LeagueDetailRouterPipe,
    LogoSvgWhiteComponent,
    OrganizationSearchResultComponent,
    OrganizationDetailRouterPipe,
    EsportCardComponent,
    PaginatorComponent,
    ModalTitleComponent,
    ModalContainerComponent,
    ModalFooterButtonsComponent,
    StepperComponent,
    StepComponent,
  ],
  exports: [
    PageComponent,
    CheckboxComponent,
    ClipboardCopyDirective,
    LoaderComponent,
    IconColumnBlockComponent,
    IconColumnComponent,
    TabsBlockComponent,
    TabComponent,
    ReadMoreContainerComponent,
    PassDetailsRouterPipe,
    OrgContextQueryPipe,
    ImgSrcLogoSvgPipe,
    SearchPaginationFooterComponent,
    NoSearchResultsComponent,
    LeagueSearchResultComponent,
    LeagueDetailRouterPipe,
    LogoSvgWhiteComponent,
    OrganizationSearchResultComponent,
    OrganizationDetailRouterPipe,
    EsportCardComponent,
    PaginatorComponent,
    ModalTitleComponent,
    ModalContainerComponent,
    ModalFooterButtonsComponent,
    StepperComponent,
    StepComponent,
  ],
})
export class SharedModule { }
