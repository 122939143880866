import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { routePaths } from "../enums/routes/routePaths";
import { SessionStorageKeys } from "../enums/session-storage-keys.enum";
import { isValidRouteToStash } from "@utils/auth-utils";



@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router
  ) { }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        const attemptedURL = this.router.routerState.snapshot.url;
        const safeURL = isValidRouteToStash(attemptedURL) ? attemptedURL : "/dashboard";
        localStorage.setItem(SessionStorageKeys.REAUTH_REDIRECT, safeURL);
        // Don't process exit analytics on an expired session redirect
        localStorage.setItem(SessionStorageKeys.IGNORE_EXIT_ANALYTIC, "true");
        window.location.href = `/${routePaths.LOGIN_EXPIRED}`;
      }
      const error = err.error || err.statusText;
      return throwError(error);
    }));
  }
}
