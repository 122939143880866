<app-page>
  <app-club-search-hero-image hero></app-club-search-hero-image>
  <header class="c_organizationSearch__header">
    <h1 class="u_txt--hdg1 u_txt--white">Search for your Local Club</h1>
  </header>
  <app-organization-search-menu
    [useRouting]="true"
    [hasWhiteText]="true"
    [searchTerms]="lastSearchTerms"
    (searchTermsChange)="searchOrganizations($event)"
    [paginationInfo]="paginationInfo"
  ></app-organization-search-menu>
  <ng-container>
    <ng-container *ngIf="searchResults$ | async as results">
      <div class="c_organizationSearch__results">
        <ng-container *ngIf="results.organizations.length > 0; else noResults">
          <app-organization-search-result
            [searchResult]="result"
            *ngFor="let result of results.organizations"
          >
          </app-organization-search-result>
          <app-search-pagination-footer
            [paginationInfo]="paginationInfo"
            [useDarkBackground]="true"
            (paginationInfoChanges)="handlePageChange($event)"
          >
          </app-search-pagination-footer>
          <p class="u_txt--white c_organizationSearch__persistentGGLCopy">
            Not seeing any clubs near you? <br />You can always get a pass
            directly through GGLeagues and join one of our events!
          </p>
          <app-organization-search-result [searchResult]="ggleaguesOrg">
          </app-organization-search-result>
          <div class="loader" *ngIf="loading">
            <app-loader [useWhite]="true">Loading Clubs...</app-loader>
          </div>
        </ng-container>
        <ng-template #noResults>
          <app-no-search-results
            searchItem="clubs"
            [clarificationString]="clarificationString"
            [isOrg]="true"
          >
          </app-no-search-results>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
  <app-club-search-preview-cards></app-club-search-preview-cards>
</app-page>
