import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AnnouncementsService } from "@services/v2/announcements/announcements.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { routePaths } from "src/app/enums/routes/routePaths";
import { ModalController } from "../modal-controller/modal-controller.abstract";

@Component({
  selector: "app-text-notification-login-modal",
  templateUrl: "./text-notification-login-modal.component.html",
  styleUrls: ["./text-notification-login-modal.component.scss"],
})
export class TextNotificationLoginModalComponent extends ModalController<string> {
  public static MODAL_ID = "textNotificationLoginModal";

  private _announcementId: string;

  constructor(
    private _ngxService: NgxSmartModalService,
    private _router: Router,
    private _announcementService: AnnouncementsService
  ) {
    super(_ngxService, TextNotificationLoginModalComponent.MODAL_ID, _router);
    this._announcementId = this.getData();
  }


  public openTextNotifManagement(): void {
    this._acknowledgeModalAsDismissed();
    this._router.navigate([`/${routePaths.PROFILE_EDIT}`]);
  }

  public fullDismiss(): void {
    this._acknowledgeModalAsDismissed();
  }

  private _acknowledgeModalAsDismissed(): void {
    this._ngxService.closeAll();
    this._announcementService.dismissAnnouncement(this._announcementId).subscribe();
  }
}
