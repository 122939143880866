<div class="c_brLobbyPanel">
  <div *ngIf="activeGame">
    <h3 class="u_txt--hdg3">
      Game {{ activeGameIndex }} of {{ brGamesSource.length }}
      <div>
        <app-esport-help-modal
          [esport]="esport"
          [isDark]="true"
          [isBattleRoyaleMode]="true"
        ></app-esport-help-modal>
        <button
          class="c_button secondary_dark c_brLobbyPanel__issuesBtn"
          (click)="openIssuesModal()"
        >
          Having Issues?
        </button>
      </div>
    </h3>
    <dl class="c_brLobbyPanel__infoList">
      <dt>Status:</dt>
      <dd>{{ getStatusDisplayLabel(activeGame.status) }}</dd>
      <dt>Total Players Checked-In:</dt>
      <dd>{{ activeGame.checked_in_teams.length }}</dd>
      <dt>Lobby Info:</dt>
      <dd>
        <ng-container *ngIf="!userIsCheckedIn; else checkinStatus">
          <button
            (click)="openDialogue(activeGame.battle_royale_matchup_game_id)"
            class="c_button"
            *ngIf="!isCheckinDisabled(activeGame.status)"
          >
            Check-in to Game<span class="c_brLobby_extraText">
              for Lobby Code</span
            >
          </button>
          <ng-container *ngIf="isCheckinDisabled(activeGame.status)">
            <button class="c_button" disabled>
              Check-in to Game<span class="c_brLobby_extraText">
                for Lobby Code</span
              >
            </button>
            <p>
              <small>
                {{ getCheckinDisabledMessage(activeGame.status) }}
              </small>
            </p>
          </ng-container>
        </ng-container>
        <ng-template #checkinStatus>
          <ng-container *ngIf="showExtendedLobbyInfo; else standardLobbyCode">
            <label>
              Lobby Name:
              <input
                type="text"
                value="{{ activeGame.lobby_code || 'Waiting for Lobby Name' }}"
                readonly
              />
            </label>
            <br />
            <label>
              Lobby Code:
              <input
                type="text"
                value="{{
                  activeGame.lobby_password || 'Waiting for Lobby Code'
                }}"
                readonly
              />
            </label>
          </ng-container>
          <ng-template #standardLobbyCode>
            <input
              type="text"
              value="{{ activeGame.lobby_code || 'Waiting for Lobby Code' }}"
              readonly
            />
          </ng-template>
        </ng-template>
      </dd>
    </dl>
  </div>
  <hr />
  <p>
    <strong>NOTICE: </strong> Players must check in for each game to recieve the
    code for their lobby. The code should <u>NEVER BE SHARED IN CHAT</u>.
    Players who join without checking-in first will
    <u>NOT RECEIVE A SCORE FOR THE GAME</u>
  </p>
  <hr />
  <div *ngIf="showGamesList">
    <h4>Individual Game Results:</h4>
    <small *ngIf="finishedGames.length === 0; else gameResultsList"
      >There are no finished games yet</small
    >
    <ng-template #gameResultsList>
      <div class="c_brLobbyPanel__results">
        <button
          class="c_button c_button--slim secondary_dark"
          *ngFor="let finishedGame of finishedGames; let i = index"
          (click)="openGameResults(finishedGame, i)"
        >
          Game {{ i + 1 }} Results
        </button>
      </div>
    </ng-template>
  </div>
</div>
