<app-icon-column-block>
  <app-icon-column>
    <i class="fas fa-info-circle" icon></i>
    <h6 class="u_txt--hdg6 c_leaguesPassesPage__infoBlockHeading" heading>
      What's an Esport Pass?
    </h6>
    <p class="u_txt" body>
      An esports pass is a seasonal membership for an organization that gets you
      access to play in all the leagues that they are running for a season.
      These are replacing individual league fees.
    </p>
  </app-icon-column>
  <app-icon-column>
    <i class="fas fa-door-open" icon></i>
    <h6 class="u_txt--hdg6 c_leaguesPassesPage__infoBlockHeading" heading>
      What Access Comes With a Pass?
    </h6>
    <p class="u_txt" body>
      Activating an esports pass will get you access to {{ league.title }} and
      <strong>all events and leagues</strong> that an organization runs for a
      given season.
    </p>
  </app-icon-column>
  <app-icon-column>
    <i class="fas fa-question-circle" icon></i>
    <h6 class="u_txt--hdg6 c_leaguesPassesPage__infoBlockHeading" heading>
      Questions
    </h6>
    <p class="u_txt" body>
      Still have questions? You can
      <a class="u_txt--link--dark" (click)="openZendeskModal()">click here</a>
      click here to reach out to our support team or send an email to
      <a
        class="u_txt--link--dark"
        [href]="'support@ggleagues.com' | mailto: 'Esports Pass Question'"
        >support@ggleagues.com</a
      >
    </p>
  </app-icon-column>
</app-icon-column-block>
