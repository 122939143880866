<div class="c_teamStatsBlock">
	<h4 class="u_txt--hdg4 c_teamStatsBlock__header">Team Stats</h4>
	<div class="c_teamStatsBlockContainer" *ngIf="team$ | async as team">
		<div class="c_teamRecordBlock">
			<table class="c_teamRecordBlock__table">
				<thead>
					<tr>
						<th>MATCHES</th>
						<th>WINS</th>
						<th>LOSSES</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{{ team.completedGames }}</td>
						<td>{{ team.wins }}</td>
						<td>{{ team.completedGames - team.wins }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="c_teamWinPct">
			<div>Win Percentage</div>
			<div class="u_txt--bold">
				{{ generateWinPercentage(team.wins, team.completedGames) }}
			</div>
		</div>
	</div>
</div>
