import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";

import { Logger } from "@utils/logger";

import { CommentValidatorService } from "./comment-validator/comment-validator.service";
import { CommentValidityErrors } from "./comment-validator/comment-validator.types";
import { Comment } from "./comment.api.types";
import { ChatData } from "@apptypes/chat/chat-data.interface";

/**
 * A class that posts messages to different chats across the site.
 * It is not provided in 'root', and must be injected/provided in components
 *
 * @see https://indepth.dev/posts/1414/angular-and-solid-principles
 * @author Christian Tweed
 */
@Injectable()
export abstract class CommentService {
  constructor(
    protected _http: HttpClient,
    protected _toastr: ToastrService,
    protected _commentValidator: CommentValidatorService
  ) { }

  /**
   * A helper function to handle toasts and logging with comment errors
   *
   * @param error
   */
  protected _logMessageError(error: CommentValidityErrors): void {
    switch (error) {
      case CommentValidityErrors.EMPTY:
        Logger.error(CommentValidityErrors.EMPTY);
        this._toastr.error(
          "You cannot post empty messages",
          "Invalid message"
        );
        break;
      case CommentValidityErrors.PROFANE:
        Logger.error(CommentValidityErrors.PROFANE);
        this._toastr.error(
          "All chat messages must follow the GGLeagues Code of Conduct",
          "Invalid message"
        );
        break;
      case CommentValidityErrors.TOO_LONG:
        Logger.error(CommentValidityErrors.TOO_LONG);
        this._toastr.error(
          "You cannot post spam messages",
          "Invalid message"
        );
        break;
    }
  }

  /**
   * Post the comment to the API service
   *
   * @param resourceId
   * @param authorId
   * @param message
   */
  public abstract postComment(chatData: ChatData, authorId: string, message: string): Observable<unknown>;
  // TODO: Come back to verify return types here

  protected abstract _formatChatMessage(resourceId: string, authorId: string, message: string): Comment;
}
