import {
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { Title } from "@angular/platform-browser";
import { NgxSmartModalService } from "ngx-smart-modal";

import { RootState } from "src/app/reducers";
import { GetTeamById } from "src/app/reducers/teams/teams.actions";
import { Unsubscriber } from "src/app/util/unsubscriber";
import { ComponentRouteParams } from "src/app/enums/routes/routeParams";
import { TeamTypes } from "src/app/reducers/teams/teams.types";
import { AccountTeamExplainerModalComponent } from "src/app/components/account-team-explainer-modal/account-team-explainer-modal.component";

@Component({
  selector: "app-team-detail-page",
  templateUrl: "./team-detail-page.component.html",
  styleUrls: ["./team-detail-page.component.scss"],
})
export class TeamDetailPageComponent implements OnInit, OnDestroy {
  public isPageLoading$: Observable<boolean> = of(true);
  public isAccountTeam$: Observable<boolean> = of(false);

  private _unsub = new Unsubscriber();
  constructor(
    private _route: ActivatedRoute,
    private _store: Store<RootState>,
    private _titleService: Title,
    private _modal: NgxSmartModalService
  ) {
    this.isPageLoading$ = this._store.select("teams", "fetchingId");
  }

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | Team Details");
    this._route.paramMap
      .pipe(takeUntil(this._unsub.unsubEvent))
      .subscribe((params) => {
        this._store.dispatch(
          new GetTeamById(+params.get(ComponentRouteParams.TEAM_ID))
        );
      });

    this.isAccountTeam$ = this._store
      .select("teams", "currentTeam", "teamType")
      .pipe(
        takeUntil(this._unsub.unsubEvent),
        map((teamType) => teamType === TeamTypes.ACCOUNT_SOLO_TEAM)
      );
  }

  public ngOnDestroy() {
    this._unsub.kill();
  }

  public openAcctModal(): void {
    this._modal.create("accountTeam", AccountTeamExplainerModalComponent, {
      closable: false,
      dismissable: false,
      customClass: "c_ngxSmartModal",
    }).open();
  }
}
