import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import {
  filter,
  takeUntil,
  tap,
} from "rxjs/operators";

import { EsportUserCredentialsAttributes } from "@services/user/user.api.types";

import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import { RootState } from "src/app/reducers";
import { UpdateEsportCredentials } from "src/app/reducers/user/user.actions";
import {
  AvailableEsport,
  EsportUserCredential,
  UserProfile,
} from "src/app/reducers/user/user.types";
import { Unsubscriber } from "src/app/util/unsubscriber";

@Component({
  selector: "app-game-credentials-layout",
  templateUrl: "./game-credentials-layout.component.html",
  styleUrls: ["./game-credentials-layout.component.scss"],
})
export class GameCredentialsLayoutComponent implements OnInit, OnDestroy {
  @Input() public isUser = false;
  @Input() public isInEditMode = false;

  public credentialsMap = new Map<gamePlatforms, string>();
  public userCredentialForm: FormGroup;
  public isEmpty = true;
  public cancelSubject: Subject<void> = new Subject<void>();

  private _hasBlockingErrors = false;
  private _availableEsports: AvailableEsport[];
  private _userEsportCredentials: EsportUserCredential[];
  private _unsub = new Unsubscriber();

  constructor(private _formBuilder: FormBuilder, private _store: Store<RootState>) {
    // eslint-disable-next-line object-curly-newline
    this.userCredentialForm = this._formBuilder.group({});
  }

  public ngOnInit() {
    const profileName = this.isUser ? "currentUser" : "browsingUser";
    this._store
      .select("user", profileName)
      .pipe(
        filter((user) => !!user),
        tap((user) => this._saveEsportDataAndLoadForm(user)),
        takeUntil(this._unsub.unsubEvent)
      )
      .subscribe();
  }

  public ngOnDestroy() {
    this._unsub.kill();
  }

  public showCredential(credential: string): boolean {
    return this.isUser || credential.length > 0;
  }

  public startEditing(): void {
    this.isInEditMode = true;
  }

  public submitChanges(): void {
    this._hasBlockingErrors = this.userCredentialForm.invalid && (this.userCredentialForm.dirty || this.userCredentialForm.touched);
    if (!this._hasBlockingErrors) {
      const credentialsToChange: EsportUserCredentialsAttributes[] = Object.entries(this.userCredentialForm.controls)
        .map((control) => ({
          esport: control[0],
          userEsportId: control[1].value.gameCredential,
          dirty: control[1].dirty,
        }))
        .filter((formCredential) => formCredential.dirty)
        .map((formCredential) => {
          const id = this._userEsportCredentials.find((userCredential) => userCredential.esport === formCredential.esport)?.id;
          const esportId = this._availableEsports.find((availEsport) => availEsport.name === formCredential.esport).id;
          const { esport, userEsportId } = formCredential;
          this.credentialsMap.set(esport as gamePlatforms, userEsportId);
          return {
            userEsportId,
            esportId,
            ...(id && {
              id,
            }),
          };
        })
        .filter((credentialToChange) => credentialToChange.esportId);

      this._store.dispatch(new UpdateEsportCredentials(credentialsToChange));
    }

    this._checkIfEmpty();
    this.isInEditMode = this._hasBlockingErrors;
  }

  public cancel(): void {
    this.isInEditMode = false;
    this.userCredentialForm.markAsPristine();
    this.cancelSubject.next();
    this._hasBlockingErrors = this.userCredentialForm.invalid && (this.userCredentialForm.dirty || this.userCredentialForm.touched);
  }

  public get hasError(): boolean {
    const invalid = this.userCredentialForm?.invalid ?? false;
    return invalid && this._hasBlockingErrors;
  }

  public get isShowingEditButton(): boolean {
    return this.isUser && !this.isInEditMode && !this.isEmpty;
  }

  public get isShowingFormActionButtons(): boolean {
    return this.isUser && this.isInEditMode;
  }

  public get isShowingCredentialLayout(): boolean {
    return !this.isEmpty || this.isInEditMode;
  }

  public isShowingCredential(credential: { key: gamePlatforms; value: string }): boolean {
    return this.isInEditMode || credential.value?.length > 0;
  }

  /**
   * A helper function to identify if the map is empty. Updates
   * this.IsEmpty accordingly
   *
   * @author Christian Tweed
   */
  private _checkIfEmpty(): void {
    //If we do not start as true, then when we use the
    //&& operator it will return an inaccurate value
    let credentialsAreEmpty = true;
    for (const key of this.credentialsMap.keys()) {
      const credential = this.credentialsMap.get(key);
      const isCredentialEmpty = credential ? credential.length === 0 : true;
      //We compare the value to itself so that way we always mask the
      //length boolean if we've found a nonempty credential
      credentialsAreEmpty = isCredentialEmpty && credentialsAreEmpty;
    }
    this.isEmpty = credentialsAreEmpty;
  }

  /**
   * Take a user and pull out the available esports, and user esport credentials.
   * Then save both of those to the class, and load the credentials into the form
   * and local map. Load the available esports into the map if this is the
   * user profile.
   *
   * @param user is our user profile
   * @author Christian Tweed
   */
  private _saveEsportDataAndLoadForm(user: UserProfile): void {
    this._userEsportCredentials = user.esportCredentials ?? [];
    this._availableEsports = user.availableEsports ?? [];
    this._userEsportCredentials.map((userCredential) => {
      this.credentialsMap.set(userCredential.esport, userCredential.userEsportId);
    });

    if (this.isUser) {
      //Iterate over available esports to add them to the map
      this._availableEsports.map((availableEsport) => {
        const { name } = availableEsport;
        if (!this.credentialsMap.has(name) && name !== gamePlatforms.OTHER) {
          this.credentialsMap.set(name, null);
        }
      });

      //Only add things to the form if we are the user
      for (const key of this.credentialsMap.keys()) {
        const gameCredential = this.credentialsMap.get(key);
        this.userCredentialForm.addControl(
          key,
          new FormControl({
            gameCredential,
          })
        );
      }
    }
    this._checkIfEmpty();
  }
}
