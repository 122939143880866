import { Nullish } from "@apptypes/generic-nullish.types";
import { gamePlatforms } from "../enums/game-platforms.enum";

export enum ImageTypes {
  USER_RECT = "/assets/images/defaults/ggl_website_generic_user_rect_480.png",
  USER_SQ = "/assets/images/defaults/ggl_website_generic_user_sq.png",
  TEAM_RECT = "/assets/images/defaults/ggl_website_generic_team_rect.png",
  TEAM_SQ = "/assets/images/defaults/ggl_website_generic_team_sq.png",
  LEAGUE_RECT = "/assets/images/new-thumbnail.png",
}

export enum PlatformImageTypes {
  DOTA = "/assets/images/games/esport-leaguetable/dota2.jpeg",
  FIFA = "/assets/images/games/esport-leaguetable/ea_fifa.png",
  MADDEN = "/assets/images/games/esport-leaguetable/ea_madden.png",
  NHL = "/assets/images/games/esport-leaguetable/ea_nhl.png",
  FORTNITE = "/assets/images/games/esport-leaguetable/fortnite.png",
  LEAGUE_OF_LEGENDS = "/assets/images/games/esport-leaguetable/leagueoflegends.png",
  OVERWATCH = "/assets/images/games/esport-leaguetable/overwatch.png",
  ROCKET_LEAGUE = "/assets/images/games/esport-leaguetable/rocketleague.png",
  SMASH_ULTIMATE = "/assets/images/games/esport-leaguetable/smashultimate.png",
  VALORANT = "/assets/images/games/esport-leaguetable/valorant.png",
  NBA_2k = "/assets/images/games/esport-leaguetable/nba_2k.png",
  CALL_OF_DUTY = "/assets/images/games/esport-leaguetable/call_of_duty.png",
  MARIO_KART = "/assets/images/games/esport-leaguetable/mario_kart.png",
  POKEMON = "/assets/images/games/esport-leaguetable/pokemon.png",
}

export const processImage = (logoUrl: string, type: ImageTypes | null = null): string | null => {
  const urlRegex = /^http/;
  const fileTypeRegex = /jpg|png|jpeg/;
  // /(?=.*\b^http\b)(?=.*\b.jpg\b|\bpng\b|\bjpeg\b)/
  if (logoUrl && logoUrl.length >= 0) {
    if (urlRegex.test(logoUrl) || fileTypeRegex.test(logoUrl)) {
      return `url('${logoUrl}')`;
    }
    return type ? `url('${type}')` : null;
  }
  return type ? `url('${type}')` : null;
};

export const isLogoUrlValid = (logoUrl: Nullish<string>): boolean => {
  const imageURLRegex = /^(http(s){0,1}):\/\/.+\.(png|jpeg|jpg)/;

  if (!logoUrl) {
    return false;
  }

  if (logoUrl.length >= 0) {
    if (imageURLRegex.test(logoUrl)) {
      return true;
    }
  }

  return false;
};

export const showPlatformImage = (type: gamePlatforms, css?: boolean): string => {
  switch (type) {
    case gamePlatforms.VALORANT:
      return css ?
        `url('${PlatformImageTypes.VALORANT}')` :
        PlatformImageTypes.VALORANT;
    case gamePlatforms.ROCKET_LEAGUE:
      return css ?
        `url('${PlatformImageTypes.ROCKET_LEAGUE}')` :
        PlatformImageTypes.ROCKET_LEAGUE;
    case gamePlatforms.LEAGUE_OF_LEGENDS:
      return css ?
        `url('${PlatformImageTypes.LEAGUE_OF_LEGENDS}')` :
        PlatformImageTypes.LEAGUE_OF_LEGENDS;
    case gamePlatforms.OVERWATCH:
      return css ?
        `url('${PlatformImageTypes.OVERWATCH}')` :
        PlatformImageTypes.OVERWATCH;
    case gamePlatforms.DOTA_2:
      return css ?
        `url('${PlatformImageTypes.DOTA}')` :
        PlatformImageTypes.DOTA;
    case gamePlatforms.FORTNITE:
      return css ?
        `url('${PlatformImageTypes.FORTNITE}')` :
        PlatformImageTypes.FORTNITE;
    case gamePlatforms.EA_NHL:
      return css ? `url('${PlatformImageTypes.NHL}')` : PlatformImageTypes.NHL;
    case gamePlatforms.EA_MADDEN:
      return css ?
        `url('${PlatformImageTypes.MADDEN}')` :
        PlatformImageTypes.MADDEN;
    case gamePlatforms.EA_FIFA:
      return css ?
        `url('${PlatformImageTypes.FIFA}')` :
        PlatformImageTypes.FIFA;
    case gamePlatforms.NBA_2K:
      return css ?
        `url('${PlatformImageTypes.NBA_2k}')` :
        PlatformImageTypes.NBA_2k;
    case gamePlatforms.SSBB:
      return css ?
        `url('${PlatformImageTypes.SMASH_ULTIMATE}')` :
        PlatformImageTypes.SMASH_ULTIMATE;
    case gamePlatforms.CALL_OF_DUTY:
      return css ?
        `url('${PlatformImageTypes.CALL_OF_DUTY}')` :
        PlatformImageTypes.CALL_OF_DUTY;
    case gamePlatforms.MARIO_KART:
      return css ?
        `url('${PlatformImageTypes.MARIO_KART}')` :
        PlatformImageTypes.MARIO_KART;
    case gamePlatforms.POKEMON:
      return css ?
        `url('${PlatformImageTypes.POKEMON}')` :
        PlatformImageTypes.POKEMON;
    default:
      return css ?
        "url('/assets/images/leagues/ggleagues_new_default.png')" :
        "/assets/images/leagues/ggleagues_new_default.png";
  }
};

export const showNextGameImage = (type: gamePlatforms, css?: boolean): string => {
  const imgVal = showPlatformImage(type, css);
  return imgVal.replace("esport-leaguetable", "nextgame-preview");
};
