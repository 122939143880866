<app-page>
  <header class="c_createTeamPageHeader">
    <h2 class="u_txt--hdg2 u_txt--white">Create New Team</h2>
    <app-need-help-button></app-need-help-button>
  </header>
  <div class="c_createTeamBlock">
    <form
      [formGroup]="createTeamForm"
      (submit)="createTeam()"
      *ngIf="!isLoading; else loading"
    >
      <fieldset [disabled]="isLoading">
        <h4 class="u_txt--hdg4 c_createTeamBlock__subhead">Team Information</h4>
        <label class="c_inputLabel" for="title"> Team Name </label>
        <input
          class="c_input"
          type="text"
          placeholder="Team Name"
          formControlName="title"
          id="title"
          name="title"
        />
        <p
          class="c_input__error"
          *ngIf="hasSpecificError('title', 'profanity')"
        >
          Your cannot use that title
        </p>
        <p
          class="c_input__error"
          *ngIf="hasSpecificError('title', 'minlength')"
        >
          Team Name must be at least 3 characters long
        </p>
        <p class="c_input__error" *ngIf="hasSpecificError('title', 'required')">
          You must provide a title for your team
        </p>
        <p
          class="c_input__error"
          *ngIf="hasSpecificError('title', 'maxlength')"
        >
          Your title is too long
        </p>
        <label class="c_inputLabel" for="description"> Description </label>
        <textarea
          class="c_input"
          type="text"
          placeholder=""
          formControlName="description"
          id="description"
          name="description"
        >
				<p
					class="c_input__error"
					*ngIf="hasSpecificError('description', 'profanity')"
				>
					Your cannot use that description
				</p>
				</textarea
        >
        <div class="c_createTeamBlock__field">
          <label class="c_inputLabel" for="logoUrl"> Team Image </label>
          <input
            #fileInput
            class="c_input c_input__file"
            type="file"
            formControlName="logoUrl"
            id="logoUrl"
            name="logoUrl"
            accept="image/*"
            (change)="onImageSelect($event, 'logoUrl')"
          />
          <div class="c_createTeamBlock__imageDisplay">
            <img
              [src]="image"
              class="c_createTeamBlock__imageDisplay--image"
              (click)="clear('logoUrl')"
            />
            <i
              class="fas fa-times c_createTeamBlock__closeButton"
              *ngIf="showClearButton"
              (click)="clear('logoUrl')"
            ></i>
          </div>
          <p
            class="c_input__error"
            *ngIf="hasSpecificError('logoUrl', 'invalidImage')"
          >
            The image your provided was invalid
          </p>
          <p
            class="c_createTeamBlock__defaultImage"
            *ngIf="isUsingDefaultImage"
          >
            No file selected, using default image. <br />
            If you are uploading an image, it will be resized to 512 x 512
            pixels.
          </p>
          <p class="c_createTeamBlock__defaultImage">
            {{ updatedFileName }}
          </p>
          <button
            type="button"
            class="c_button secondary_dark small"
            (click)="fileInput.click()"
          >
            Select File
          </button>
        </div>
      </fieldset>
      <div class="c_createTeamBlock__buttons">
        <p
          *ngIf="serverError"
          class="c_input__error c_createTeam__serverErrorMessage"
        >
          Your request could not be processed, please try again.
        </p>
        <app-form-footer-error-message
          *ngIf="formInvalid"
        ></app-form-footer-error-message>
        <a
          routerLink="/{{ routes.MY_TEAMS }}"
          class="c_button secondary_dark c_createTeam__backBtn"
        >
          Cancel
        </a>
        <app-form-loading-button [isLoading]="isLoading"
          >Create Team!</app-form-loading-button
        >
      </div>
    </form>
  </div>
  <ng-template #loading>
    <div class="c_createTeamBlock">
      <app-loader>Loading Form...</app-loader>
    </div>
  </ng-template>
</app-page>
