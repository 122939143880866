import { Component, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

import { GenericLeagueBracket } from "src/app/reducers/leagues/league.types";

@Component({
  selector: "app-playoff-bracket",
  templateUrl: "./playoff-bracket.component.html",
  styleUrls: ["./playoff-bracket.component.scss"],
})
export class PlayoffBracketComponent {
  @Input() public bracketArray: Array<GenericLeagueBracket[]> = [];
  @Input() public maxRounds: number;

  constructor(private _sanitizer: DomSanitizer) { }

  public getBracketGridWidth() {
    // Need to use sanitizer to allow direct style setting. Angular has known
    // CSS Grid issues with [style] tag in template when using conventional
    // structure
    return this._sanitizer.bypassSecurityTrustStyle(`grid-template-columns: repeat(${this.bracketArray.length}, 1fr);`);
  }

  public getBracketColumnHeight() {
    // Need to use sanitizer to allow direct style setting. Angular has known
    // CSS Grid issues with [style] tag in template when using conventional
    // structure
    return this._sanitizer.bypassSecurityTrustStyle(`grid-template-rows: auto repeat(${this.bracketArray[0].length}, 80px);`);
  }

  public getBracketGameLink(seriesId = null): string | null {
    if (seriesId) {
      return `/series_matchup/${seriesId}`;
    }

    return null;
  }

  public getScoreValue(winnerValue: string, currentTeam: string): string {
    if (winnerValue === currentTeam) {
      return "W";
    } else if (winnerValue === null) {
      return "-";
    } else {
      return "L";
    }
  }

  public generateRoundTitle(roundNumber: number): string {
    if (roundNumber === -1) {
      return "Play-in Round";
    } else if (roundNumber === this.maxRounds) {
      return "Finals";
    } else {
      return `Round ${roundNumber}`;
    }
  }
}
