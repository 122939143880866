<div
  class="c_chatMessageBlock"
  [ngClass]="{
    'c_chatMessageBlock--system': isSystemMessage,
    'c_chatMessageBlock--user': message.isUser,
    'c_chatMessageBlock--nonUser': !message.isUser,
    'c_chatMessageBlock--admin': message.isAdmin,
    'c_chatMessageBlock--teamOne': message.team === teams.TEAM_ONE,
    'c_chatMessageBlock--teamTwo': message.team === teams.TEAM_TWO
  }"
>
  <ng-container *ngIf="!isSystemMessage">
    <p class="c_chatMessageBlock__nameDate">
      {{ message.isAdmin ? "🛡️" : "" }} {{ message.username }}
      {{ message.isAdmin ? "(Admin)" : "" }} - {{ formatDate(message.date) }}
    </p>
    <p [ngClass]="{ c_chatMessageBlock__moderatedMessage: message.isHidden }">
      {{
        message.isHidden
          ? "This message has been removed by GGLeagues Staff."
          : message.message
      }}
    </p>
    <a
      *ngIf="message.isHidden"
      class="c_chatMessageBlock__codeOfConductLink"
      target="_blank"
      rel="noreferrer"
      [href]="playerGuides.CODE_OF_CONDUCT"
      >For more information, review the Code of Conduct (PDF) here.</a
    >
  </ng-container>
  <ng-container *ngIf="isSystemMessage">
    <p class="c_chatMessageBlock__nameDate">
      🤖 GGLeaguesBot - {{ formatDate(message.date) }}
    </p>
    <p [ngClass]="{ c_chatMessageBlock__moderatedMessage: message.isHidden }">
      {{ message.message }}
    </p>
  </ng-container>
</div>
