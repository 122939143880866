import {
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { QueueSessionStatuses } from "@services/v2/leagues/leagues.v2.api.types";
import { showNextGameImage } from "@utils/image-utils";
import { Logger } from "@utils/logger";
import { Unsubscriber } from "@utils/unsubscriber";
import {
  forkJoin,
  Observable,
  of,
} from "rxjs";
import {
  first,
  map,
  pluck,
  takeUntil,
} from "rxjs/operators";
import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import { generateLeagueDetailCheckinRoute, generateLeagueDetailRoute } from "src/app/enums/routes/routePaths";
import { RootState } from "src/app/reducers";
import { DashboardLeagueMap, DashboardQueue } from "src/app/reducers/dashboard";
import { GetLeagueById, UpdateLeagueEnrollmentStatus } from "src/app/reducers/leagues/league.actions";
import { GenericLeagueDetails } from "src/app/reducers/leagues/league.types";

@Component({
  selector: "app-dash-next-queue",
  templateUrl: "./dash-next-queue.component.html",
  styleUrls: ["./dash-next-queue.component.scss"],
})
export class DashNextQueueComponent implements OnInit, OnDestroy {
  public nextQueue$: Observable<DashboardQueue | null> = of(null);
  public leagueData$: Observable<GenericLeagueDetails>;
  public leagueInfoLoading$: Observable<boolean> = of(true);
  public userLeaguesMap$: Observable<DashboardLeagueMap>;

  private _unsub = new Unsubscriber();

  constructor(
    private _store: Store<RootState>
  ) {}

  public ngOnInit(): void {
    this.userLeaguesMap$ = this._store.select("dashboard", "leagues").pipe(
      map((userLeagueFlatmap) => new Map(userLeagueFlatmap)),
      first()
    );

    this.nextQueue$ = this._store.select("dashboard").pipe(
      pluck("nextQueue"),
      first()
    );

    this.leagueInfoLoading$ = this._store.select("leagues", "fetchingLeagueById").pipe(
      takeUntil(this._unsub.unsubEvent)
    );

    this.leagueData$ = this._store.select("leagues", "league").pipe(
      takeUntil(this._unsub.unsubEvent)
    );

    //Emit when both are completed
    forkJoin([this.nextQueue$, this.userLeaguesMap$]).subscribe(
      ([queueData, userLeagueMap]) => {
        if (queueData) {
          const queueLeagueId = queueData.leagueId.toString();
          try {
            const userTeamId = userLeagueMap.get(queueLeagueId)?.leagueTeam?.id;
            if (userTeamId) {
              this._store.dispatch(new UpdateLeagueEnrollmentStatus({
                id: queueLeagueId,
                userIsInLeague: true,
                teamId: userTeamId,
              }));
            } else {
              throw new Error("Cannot find user enrollment team");
            }
          } catch (e) {
            Logger.error(e);
            Logger.warn(
              `Couldn't match user team for given queue league, 
              they will be redirected to the league page 
              when they try to access the queue page`
            );
          }
          this._store.dispatch(new GetLeagueById(queueLeagueId));
        }
      }
    );
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public leagueQueueLink(queueId: string | number, leagueId: string | number): string {
    return `/${generateLeagueDetailCheckinRoute(leagueId, queueId)}`;
  }

  public leagueLink(leagueId: string | number): string {
    return `/${generateLeagueDetailRoute(leagueId)}`;
  }

  public getQueueStatusMessage(dashQueue: DashboardQueue): string {
    if (dashQueue.status === QueueSessionStatuses.COMPLETED) {
      return "Queue is Completed & Closed";
    }

    if (dashQueue.isQueueOpen) {
      return "Queue is Open to Join!";
    }

    return "Queue will open 10 minutes before start time";
  }

  public showEsportImage(esport: gamePlatforms): string {
    return `background-image: ${showNextGameImage(esport, true)}`;
  }
}
