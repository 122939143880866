import { Component } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";

@Component({
  selector: "app-modal-title",
  templateUrl: "./modal-title.component.html",
  styleUrls: ["./modal-title.component.scss"],
})
export class ModalTitleComponent {
  constructor(private _modalService: NgxSmartModalService) { }

  public close(): void {
    //Clears the data from the modal
    this._modalService.getModalStack().map((modal) => this._modalService.resetModalData(modal.id));
    this._modalService.closeLatestModal();
  }
}
