export const OK_CITIES: string[] = [
  "Arcadia",
  "Bethany",
  "Blanchard",
  "Chandler",
  "Chickasaw",
  "Chickasha",
  "Choctaw",
  "Cushing",
  "Del City",
  "Edmond",
  "El Reno",
  "Forest Park",
  "Guthrie",
  "Harrah",
  "Holdenville",
  "Jones",
  "Kingfisher",
  "Langston",
  "Luther",
  "McLoud",
  "Midwest City",
  "Moore",
  "Mustang",
  "Newcastle",
  "Nichols Hills",
  "Nicoma Park",
  "Noble",
  "Norman",
  "OKC",
  "Okemah",
  "Oklahoma City",
  "Piedmont",
  "Purcell",
  "Seminole",
  "Shawnee",
  "Spencer",
  "Stillwater",
  "Stroud",
  "Tecumseh",
  "The Village",
  "Tinker",
  "Tuttle",
  "Valley Brook",
  "Warr Acres",
  "Yukon",
];
