<div
  class="c_loaderContainer"
  [ngClass]="{ 'c_loaderContainer--white': useWhite }"
>
  <div class="c_loader">
    <p class="c_loader__words">
      <ng-content></ng-content>
    </p>
    <i class="c_loader__spinner" class="fa fa-circle-notch fa-5x fa-spin"></i>
  </div>
</div>
