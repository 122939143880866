<app-static-page pageTitle="Contact Us" class="c_contactPage">
  <section>
    <h2 class="u_txt--hdg2">General Inquiries</h2>
    <p>
      We love to hear from you! If you have feedback, questions, or comments, or
      general inquiries, please send an email to us here at:
    </p>
    <p>
      <a class="c_contactDetail" href="mailto:info@ggleagues.com">
        info@ggleagues.com
      </a>
    </p>
  </section>
  <hr />
  <section>
    <h2 class="u_txt--hdg2">Support</h2>
    <p>
      Need help with something on the platform? We have staff readily available
      to help you sign up, sort out an issue, or just answer some questions in
      our
      <a
        href="https://discord.gg/hN4aXBa"
        target="_blank"
        rel="noreferrer"
        class="c_contactLink"
      >
        Discord server
      </a>
      , OR via the email address below:
    </p>
    <p>
      <a class="c_contactDetail" href="mailto:support@ggleagues.com">
        support@ggleagues.com
      </a>
    </p>
  </section>
  <hr />
  <section>
    <h2 class="u_txt--hdg2">Legal</h2>
    <p>For any legal inquiries, please reach out to:</p>
    <p>
      <a class="c_contactDetail" href="mailto:legal@ggleagues.com">
        legal@ggleagues.com
      </a>
    </p>
  </section>
  <hr />
  <section>
    <h2 class="u_txt--hdg2">Address</h2>
    <p>GGLeagues offices are located at:</p>
    <p class="c_contactDetail">
      GGLeagues
      <br />
      154 Palm Blvd
      <br />
      Missouri City, TX 77459
    </p>
  </section>
</app-static-page>
