/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { ActionReducerMap, MetaReducer } from "@ngrx/store";
import { RouterReducerState, routerReducer } from "@ngrx/router-store";

import { environment } from "@environments/environment";

import {
  UserState,
  userReducer,
  UserEffects,
} from "./user";
import {
  leagueReducer,
  LeagueState,
  LeagueEffects,
} from "./leagues";
import {
  TeamsState,
  teamsReducer,
  TeamsEffects,
} from "./teams";
import {
  matchesReducer,
  MatchState,
  MatchesEffects,
} from "./matches";
import {
  dashboardReducer,
  DashboardState,
  DashboardEffects,
} from "./dashboard";

import {
  clubReducer,
  ClubState,
  ClubEffects,
} from "./clubs";


export interface RootState {
  leagues: LeagueState;
  router: RouterReducerState;
  user: UserState;
  teams: TeamsState;
  matches: MatchState;
  dashboard: DashboardState;
  clubs: ClubState;
}

// Any intead of making a blobby type to grab all effects just to make eslint happy here.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const effects: any[] = [
  UserEffects,
  TeamsEffects,
  LeagueEffects,
  MatchesEffects,
  DashboardEffects,
  ClubEffects,
];

export const reducers: ActionReducerMap<RootState> = {
  leagues: leagueReducer,
  router: routerReducer,
  user: userReducer,
  teams: teamsReducer,
  matches: matchesReducer,
  dashboard: dashboardReducer,
  clubs: clubReducer,
};

export const metaReducers: MetaReducer<RootState>[] = !environment.production ? [] : [];
