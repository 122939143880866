<div class="c_confirmGrid">
  <div class="c_confirmCopy">
    <p class="u_txt">
      <strong> Please confirm the information below </strong>
    </p>
    <div
      class="c_confirmationStep__confirmationItem"
      *ngIf="
        confirmationInfo.selectedTeam as confirmTeam;
        else registeringWithAccountTeam
      "
    >
      <span class="c_confirmationStep__confirmationType">
        You will be registering with this team</span
      >
      <div class="c_confirmationStep__team">
        <div
          class="c_confirmationStep__icon"
          [ngStyle]="{
            'background-image': displayTeamImage(confirmTeam.logoUrl)
          }"
        ></div>
        <div class="c_confirmationStep__teamDetails">
          <strong>
            {{ confirmTeam.title }}
          </strong>
          <span class="u_txt c_confirmationStep__teamPlayers"
            >({{ confirmTeam.playerCount }} Players)</span
          >
          <div
            *ngIf="errorState.team"
            class="c_confirmationStep__errorMessage c_confirmationStep__errorMessage--subMessage"
          >
            {{ errorState.team }}
          </div>
        </div>
      </div>
    </div>
    <ng-template #registeringWithAccountTeam>
      <p class="c_confirmationStep__confirmationItem">
        You will be registering with the <strong>solo team</strong> associated
        with your account.
      </p>
    </ng-template>
    <ng-container *ngIf="isReferral; else registeringSponsor">
      <div
        class="c_confirmationStep__confirmationItem"
        *ngIf="confirmationInfo.referrall as confirmReferral"
      >
        <span
          >You will be registering with a referral from
          <strong>
            {{ confirmReferral.name }}
          </strong>
          <div
            class="c_confirmationStep__errorMessage c_confirmationStep__errorMessage--subMessage"
            *ngIf="!confirmationInfo.selectedTeam"
          >
            {{ errorState.team }}
          </div>
        </span>
      </div>
    </ng-container>
    <div class="c_confirmationStep__confirmationItem" *ngIf="canCheckout()">
      The registration fee for this league will be
      <strong>${{ confirmationInfo.leagueFee }}</strong>
    </div>
    <ng-template #registeringSponsor>
      <ng-container *ngIf="isSponsor; else registeringSeasonPass">
        <div
          class="c_confirmationStep__confirmationItem"
          *ngIf="confirmationInfo.sponsorCode as code"
        >
          <span>
            You will be using the sponsor code <strong>{{ code }}</strong
            >.
          </span>
          <div
            class="c_confirmationStep__errorMessage c_confirmationStep__errorMessage--subMessage"
          >
            {{ errorState.sponsor }}
          </div>
          <div
            class="c_confirmationStep__errorMessage c_confirmationStep__errorMessage--subMessage"
            *ngIf="!confirmationInfo.selectedTeam"
          >
            {{ errorState.team }}
          </div>
        </div>
      </ng-container>
    </ng-template>
    <ng-template #registeringSeasonPass>
      <div
        class="c_confirmationStep__confirmationItem"
        *ngIf="confirmationInfo.isSeasonPassLeague; else freeLeague"
      >
        <span>
          You will be using the season pass
          <strong>{{ seasonPassTitle$ | async }}</strong
          >. You can join other leagues using this same pass without an extra
          cost.
        </span>
        <div
          class="c_confirmationStep__errorMessage c_confirmationStep__errorMessage--subMessage"
        >
          {{ errorState.seasonPass }}
        </div>
        <div
          class="c_confirmationStep__errorMessage c_confirmationStep__errorMessage--subMessage"
          *ngIf="!confirmationInfo.selectedTeam"
        >
          {{ errorState.team }}
        </div>
      </div>
    </ng-template>
    <ng-template #freeLeague>
      <div class="c_confirmationStep__confirmationItem" *ngIf="isFreeLeague">
        <span> There is no registration fee for this league. </span>
        <div
          class="c_confirmationStep__errorMessage c_confirmationStep__errorMessage--subMessage"
          *ngIf="!confirmationInfo.selectedTeam"
        >
          {{ errorState.team }}
        </div>
      </div>
    </ng-template>
    <div
      *ngIf="confirmationHasError"
      class="c_confirmationStep__errorMessage c_confirmationStep__errorMessage--alert"
    >
      You have errors that are preventing you from registering in this league,
      please see above
    </div>
  </div>
  <div class="c_confirmButtons">
    <app-modal-stepper-footer-buttons
      [hasNext]="false"
      [hasPrevious]="!isFirstStep"
      [formToCheck]="null"
      errorMessage=""
    >
      <button
        *ngIf="canCheckout(); else complete"
        class="c_button primary_dark c_continueSubmit__button"
        [disabled]="paymentIsBlocked() | async"
        mat-button
        matStepperNext
      >
        Confirm
      </button>
      <ng-template #complete>
        <button
          class="c_button primary_dark c_continueSubmit__button"
          mat-button
          [disabled]="paymentIsBlocked() | async"
          (click)="joinLeagueWithNoPayment()"
        >
          Join League!
        </button>
      </ng-template>
    </app-modal-stepper-footer-buttons>
  </div>
</div>
