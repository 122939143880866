<section class="c_signupPage">
  <div class="c_signupBoxContainer">
    <ng-container [ngSwitch]="signupState">
      <div
        *ngSwitchCase="signupStateEnum.GUARDIAN_PROMPT"
        class="c_signupGuardianPrompt"
      >
        <div class="c_signupGuardianPrompt__icon">
          <i class="fas fa-user-shield"></i>
        </div>
        <h1 class="u_txt--hdg1 u_txt--white">Guardian Verification Required</h1>
        <p class="u_txt--body u_txt--white c_signupGuardianPrompt__copy">
          Per COPPA rules and regulations, all users under 13 years old need
          their parent or guardian to approve their account. To complete
          registration please go get a parent or guardian and continue to the
          next step!
        </p>
        <button
          class="c_button primary_light c_signupGuardianPrompt__btn"
          (click)="proceedToVerification()"
        >
          Proceed to Verification
        </button>
      </div>
      <div *ngSwitchCase="signupStateEnum.GUARDIAN_CONFIRM">
        <app-guardian-verification-form
          [userSignupData]="guardianSignupData"
        ></app-guardian-verification-form>
      </div>
      <div *ngSwitchDefault class="c_signupBox">
        <h2 class="u_txt--hdg2">Create an Account</h2>
        <p class="u_txt--body">
          Sign up in 30 seconds or less– you can join a league, see current
          league standings and stats, and follow community news.
        </p>
        <br />
        <form
          class="c_signupForm"
          [formGroup]="signUpForm"
          (submit)="submitSignup(signUpForm)"
        >
          <fieldset [disabled]="isLoading">
            <h4 class="u_txt--hdg4 c_signupBox__subhead">
              Account Information
            </h4>
            <label class="c_inputLabel" for="firstName"> First Name * </label>
            <input
              class="c_input"
              type="text"
              placeholder="First Name"
              formControlName="firstName"
              id="firstName"
            />
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('firstName', 'required')"
            >
              You must provide a first name
            </p>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('firstName', 'profanity')"
            >
              Your name cannot contain profanity
            </p>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('firstName', 'minLength')"
            >
              Your first name is too short
            </p>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('firstName', 'invalidCharacters')"
            >
              Your first name contains invalid characters
            </p>
            <label class="c_inputLabel" for="firstName"> Last Name * </label>
            <input
              class="c_input"
              type="text"
              placeholder="Last Name"
              formControlName="lastName"
              id="lastName"
            />
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('lastName', 'required')"
            >
              You must provide a last name
            </p>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('lastName', 'profanity')"
            >
              Your name cannot contain profanity
            </p>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('lastName', 'minLength')"
            >
              Your last name is too short
            </p>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('lastName', 'invalidCharacters')"
            >
              Your last name contains invalid characters
            </p>
            <label class="c_inputLabel" for="zipCode">
              Zip Code (optional)
            </label>
            <input
              class="c_input"
              type="text"
              placeholder="Zip Code"
              formControlName="zipCode"
              id="zipCode"
            />
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('zipCode', 'pattern')"
            >
              You must provide a valid zip code
            </p>
            <label class="c_inputLabel" for="email"> Email * </label>
            <input
              class="c_input"
              type="text"
              placeholder="Email"
              formControlName="email"
              id="email"
            />
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('email', 'email')"
            >
              You must provide a valid email address
            </p>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('email', 'required')"
            >
              You must provide an email address
            </p>
            <label class="c_inputLabel" for="emailConfirm">
              Confirm Email *
            </label>
            <input
              class="c_input"
              type="text"
              placeholder="Confirm Email"
              formControlName="confirmEmail"
              id="emailConfirm"
            />
            <p
              class="c_input__error"
              *ngIf="signUpForm.errors?.nonMatchingEmail"
            >
              Your emails must match
            </p>
            <label class="c_inputLabel" for="inGameName"> Username * </label>
            <input
              class="c_input"
              type="text"
              placeholder="Username"
              formControlName="inGameName"
              id="inGameName"
              maxlength="16"
            />
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('inGameName', 'required')"
            >
              You must provide a username
            </p>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('inGameName', 'minLength')"
            >
              Your username is too short
            </p>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('inGameName', 'maxLength')"
            >
              Usernames cannot be greater than 16 characters
            </p>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('inGameName', 'profanity')"
            >
              Your username cannot contain profanity
            </p>
            <label class="c_inputLabel" for="password"> Password * </label>
            <input
              class="c_input"
              type="{{ showPassword() ? 'text' : 'password' }}"
              placeholder="Password"
              formControlName="password"
              id="password"
            />
            <div class="c_passwordToggle">
              <label for="showPW">
                <input type="checkbox" formControlName="showPW" id="showPW" />
                Show Password
              </label>
            </div>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('password', 'required')"
            >
              You must provide a password
            </p>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('password', 'minlength')"
            >
              Password must be at least 8 characters long
            </p>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('password', 'passwordUppercase')"
            >
              Password must contain at least 1 capital letter
            </p>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('password', 'passwordLowercase')"
            >
              Password must contain at least 1 lowercase letter
            </p>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('password', 'passwordNumber')"
            >
              Password must contain at least 1 number character (0-9)
            </p>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('password', 'passwordSpecial')"
            >
              Password must contain at least 1 special character:
              <br />
              @ ! * $ % # ? + = &
            </p>
            <label class="c_inputLabel" for="confirmPassword">
              Confirm Password *
            </label>
            <input
              class="c_input"
              type="{{ showPassword() ? 'text' : 'password' }}"
              placeholder="Confirm Password"
              formControlName="confirmPassword"
              id="confirmPassword"
            />
            <p
              class="c_input__error"
              *ngIf="signUpForm.errors?.nonMatchingPasswords"
            >
              Your passwords must match
            </p>
            <span class="c_inputLabel"> Date of Birth </span>
            <div class="c_signupBox__datepicker">
              <div>
                <label class="c_inputLabel" for="dobMonth"> Month </label>
                <select
                  class="c_input"
                  formControlName="dobMonth"
                  id="dobMonth"
                >
                  <option value="">Month</option>
                  <option *ngFor="let month of months" [value]="month">
                    {{ month }}
                  </option>
                </select>
              </div>
              <div>
                <label class="c_inputLabel" for="dobDay"> Day </label>
                <select class="c_input" formControlName="dobDay" id="dobDay">
                  <option value="">Day</option>
                  <option *ngFor="let day of days" [value]="day">
                    {{ day }}
                  </option>
                </select>
              </div>
              <div>
                <label class="c_inputLabel" for="dobYear"> Year </label>
                <select class="c_input" formControlName="dobYear" id="dobYear">
                  <option value="">Year</option>
                  <option *ngFor="let year of years" [value]="year">
                    {{ year }}
                  </option>
                </select>
              </div>
            </div>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('dobDay', 'required')"
            >
              You must provide a day in your birthday
            </p>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('dobMonth', 'required')"
            >
              You must provide a month in your birthday
            </p>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('dobYear', 'required')"
            >
              You must provide a year in your birthday
            </p>
            <p class="c_input__error" *ngIf="dateParseErr">
              Invalid date, please check to make sure your birthday is valid
            </p>
            <br />
            <label class="c_inputLabel" for="discordName">
              Discord Username (Optional)
            </label>
            <input
              class="c_input"
              type="text"
              placeholder="Discord Username"
              formControlName="discordName"
              id="discordName"
            />
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('discordName', 'pattern')"
            >
              Discord Name must be in format DiscordName#1234
            </p>
            <br />
            <ng-container *ngIf="hasClubContext">
              <hr />
              <h4 class="u_txt--hdg4 c_signupBox__subhead">
                Club Subscriptions
              </h4>
              <p>
                Following a club lets you stay up to date when a club adds new
                esports, has special events, or has updates to share with the
                community!
              </p>
              <label class="c_inputLabel" for="clubName"> Current Club: </label>
              <input
                class="c_input"
                type="text"
                id="clubName"
                [value]="(club$ | async)?.name"
                readonly
              />
              <label for="clubAgreement">
                <input
                  type="checkbox"
                  id="clubAgreement"
                  (change)="followClubOnSignup = !followClubOnSignup"
                  [checked]="followClubOnSignup"
                />
                I want to follow this club when I create my account!
              </label>
              <hr />
            </ng-container>
            <div class="c_referralBlock">
              <hr />
              <h4 class="u_txt--hdg4 c_signupBox__subhead">Referral</h4>
              <p>
                If you have a referral code from a friend, you can enter it
                here!
              </p>
              <label class="c_inputLabel" for="referralCode">
                Referral Code (Optional)
              </label>
              <input
                class="c_input"
                type="text"
                placeholder="Referral Code"
                formControlName="referralCode"
                id="referralCode"
              />
              <hr />
              <br />
            </div>
            <div class="c_privacyAgreement">
              <label for="privacyAgreement">
                <input
                  type="checkbox"
                  formControlName="privacyAgreement"
                  id="privacyAgreement"
                />
                I accept the
                <a [href]="externalRoutes.TERMS" target="_blank"
                  >Terms of Use</a
                >
                and the
                <a [href]="externalRoutes.PRIVACY" target="_blank"
                  >Privacy Policy</a
                >
                of GGLeagues.com
              </label>
            </div>
            <p
              class="c_input__error"
              *ngIf="hasSpecificError('privacyAgreement', 'required')"
            >
              You must agree to the terms and conditions to make an account
            </p>
          </fieldset>
          <br />
          <app-form-footer-error-message
            *ngIf="isFormInvalid || formSubmitError"
            [errorMessage]="formSubmitError"
          ></app-form-footer-error-message>
          <button type="submit" class="c_button primary_dark c_signupBox__btn">
            Create Account
          </button>
        </form>
      </div>
    </ng-container>
  </div>
</section>
