import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import jsonToFormData from "@ajoelp/json-to-formdata";

import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-utils";

import {
  MatchReport,
  MatchReportGame,
  MatchReportTeam,
  NOT_PLAYED,
} from "src/app/reducers/matches/matches.types";
import { MatchReportSubmission, TeamWinsMap } from "./match-report.types";

@Injectable({
  providedIn: "root",
})
export class MatchReportService {
  constructor(private _http: HttpClient) { }

  public static doGamesHaveEnoughToDeclareWinner(games: MatchReportGame[], teams: MatchReportTeam[]): boolean {
    const minGamesToWin = Math.ceil(games.length / 2);
    const teamWinMap = this.generateTeamWinsMap(games, teams);

    return !!Object.values(teamWinMap).find(
      (winCount) => winCount >= minGamesToWin
    );
  }

  public static generateTeamWinsMap(games: MatchReportGame[], teams: MatchReportTeam[]): TeamWinsMap {
    // eslint-disable-next-line object-curly-newline
    const teamWinMap: { [key: string]: number } = {};

    teams.forEach((team) => teamWinMap[team.id] = 0);

    games.forEach((game) => {
      if (game.winnerId !== NOT_PLAYED) {
        teamWinMap[game.winnerId]++;
      }
    });

    return teamWinMap;
  }

  public reportResults(matchReport: MatchReport): Observable<unknown> {
    const payload = this._mapMatchReportToMatchReportSubmission(matchReport.games);
    const url = `${environment.apiBase}/api/v1/series_matchups/${matchReport.seriesMatchupsId}/report_match_winners`;
    const headers = basicAuthHeader();
    const formData = jsonToFormData(payload);
    return this._http.post(url, formData, {
      headers,
    });
  }

  public reportForfeit(seriesMatchupsId: string, winnerId: string): Observable<unknown> {
    const report = {
      series_matchup: {
        winner_id: winnerId,
      },
    };
    const url = `${environment.apiBase}/api/v1/series_matchups/${seriesMatchupsId}/report_match_forfeit`;
    const headers = basicAuthHeader();
    const formData = jsonToFormData(report);
    return this._http.post(url, formData, {
      headers,
    });
  }

  private _mapMatchReportToMatchReportSubmission(matchReportGames: MatchReportGame[]): MatchReportSubmission {
    const result = {
      series_matchup: {
        games_attributes: matchReportGames.map(({ gameId, winnerId, screenshot }) => ({
          id: gameId,
          winner_id: winnerId === NOT_PLAYED ? null : winnerId,
          score_screenshot: screenshot,
        })),
      },
    };
    return result;
  }
}
