<app-modal-container [modalContent]="content"></app-modal-container>

<ng-template #content>
  <div class="c_passActivationModalContainer">
    <h3 class="u_txt--hdg3">You've added an esports pass!</h3>
    <div *ngIf="!userHasPass" class="c_passActivationModal__noPassCopy">
      <app-bounce-loader [loadError]="activatePassWarning"></app-bounce-loader>
      <p>
        We're activating the new pass on your account! This may take up to 60
        seconds, hang tight!
      </p>
      <p>
        <small *ngIf="activatePassWarning">
          This is taking longer than usual. If the problem persists please try
          to refresh the page or reach out to GGLeagues Support
        </small>
      </p>
      <button
        (click)="dismissModal()"
        *ngIf="activatePassWarning"
        class="c_button primary_dark c_button--slim"
      >
        Close
      </button>
    </div>

    <div *ngIf="userHasPass" class="c_passActivationModal__successBlock">
      <div class="c_passActivationModal__successAnimContainer">
        <app-svg-animated-check></app-svg-animated-check>
      </div>
      <p *ngIf="contextLeagueTitle; else noLeagueContext">
        Thank you for activating your
        <strong>{{ formattedPassName }}</strong
        >! You can now register for
        <strong>{{ contextLeagueTitle }}</strong> and
        {{ esportPass.leagues.length - 1 }} other leagues included in your
        esports pass! You can find details about this pass and other passes on
        your
        <a [routerLink]="profileRoute">profile page</a>
      </p>
      <ng-template #noLeagueContext>
        Thank you for activating your <strong>{{ formattedPassName }}</strong
        >! You can now register for any number of the
        {{ esportPass.leagues.length }} leagues included in your esports pass!
        You can find details about this pass and other passes on your
        <a [routerLink]="profileRoute">profile page</a>
      </ng-template>
      <div>
        <button (click)="dismissModal()" class="c_button primary_dark">
          Close
        </button>
      </div>
    </div>
  </div>
</ng-template>
