<div class="c_profilePasses">
  <header class="c_profilePasses__headerContainer">
    <h4 class="u_txt--hdg4 c_profilePasses__header">My Esports Passes</h4>
  </header>
  <hr />
  <div>
    <ng-container *ngIf="userHasPasses; else noPasses">
      <div *ngFor="let pass of myPasses" class="c_profilePasses__userPass">
        <div class="c_profilePasses__userPass__title">
          {{ pass.title }}
        </div>
        <div class="c_profilePasses__userPass__buttons">
          <a
            [routerLink]="[orgRouteFromId(pass.orgId)]"
            class="c_button secondary_dark"
          >
            View Pass Organization
          </a>
          <a
            [routerLink]="pass.seasonPassId | passDetailsRouter"
            class="c_button primary_dark"
            >View Pass Details</a
          >
        </div>
      </div>
    </ng-container>
    <ng-template #noPasses>
      <div class="c_profilePasses__noPassesCopy">
        <i class="fas fa-ticket-alt fa-9x"></i>
        <p>You have not activated any esport passes on your account yet</p>
      </div>
    </ng-template>
  </div>
</div>
