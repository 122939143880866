<section class="c_clubSearchPreviewCards">
  <h3 class="u_txt--hdg3 u_txt--white">Why Join a Club?</h3>
  <ul class="c_clubSearchPreviewCards__list">
    <li>
      <app-preview-card
        imageUrl="/assets/images/modal_images/about_modal/strawberries-01.svg"
      >
        <h4 class="u_txt--hdg4" title>Community</h4>
        <p>
          Connect with others in a secure space, stay up to date on events from
          the club, and be the first to get notified when leagues open up for
          registration!
        </p>
      </app-preview-card>
    </li>
    <li>
      <app-preview-card
        imageUrl="/assets/images/modal_images/about_modal/fist_bumps-01.svg"
      >
        <h4 class="u_txt--hdg4" title>Competition</h4>
        <p>
          We have a wide variety of games available that are approved by your
          local club. All leagues are split into age divisions and some leagues
          will have skill divisions split out.
        </p>
      </app-preview-card>
    </li>
    <li>
      <app-preview-card
        imageUrl="/assets/images/modal_images/about_modal/3hands-01.svg"
      >
        <h4 class="u_txt--hdg4" title>Education</h4>
        <p>
          GGLeagues provides access to education for parents, gamers, and anyone
          interested in learning more about esports & gaming.
        </p>
      </app-preview-card>
    </li>
  </ul>
</section>
