import { Component, Input } from "@angular/core";
import { generateTeamDetailRoute } from "src/app/enums/routes/routePaths";
import { TeamTypes } from "src/app/reducers/teams/teams.types";
import { UserTeam } from "src/app/reducers/user/user.types";

@Component({
  selector: "app-team-block",
  templateUrl: "./team-block.component.html",
  styleUrls: ["./team-block.component.scss"],
})
export class TeamBlockComponent {
  @Input() public team: UserTeam;
  @Input() public centered = false;

  public getTeamDetailRoute(id: number | string): string[] {
    return id ? [`/${generateTeamDetailRoute(id.toString())}`] : [];
  }

  public showTeamType(teamType: TeamTypes = TeamTypes.ACCOUNT_SOLO_TEAM): string {
    return teamType === TeamTypes.ACCOUNT_SOLO_TEAM ? "Solo Team" : "Multiplayer Team";
  }
}
