export const Fall2021NESList: number[] = [
  2974,
  2978,
  3000,
  3005,
  3029,
  3032,
  3063,
  3067,
  3081,
  3094,
  3125,
  3147,
  3155,
  3176,
  3184,
  3189,
  3202,
  3218,
  3225,
  3259,
  3268,
  3277,
  3286,
  3303,
  3304,
  3328,
  3344,
  3352,
  3377,
  3388,
  3395,
  3414,
  3431,
  3432,
  3456,
  3477,
  3478,
  3509,
  3511,
  3538,
  3539,
  3545,
  3552,
  3571,
  3584,
  3603,
  3611,
  3621,
  3633,
  3652,
  3656,
  3680,
  3695,
  3717,
  3720,
  3748,
  3752,
  3765,
  3768,
  3820,
  3839,
  3851,
  3870,
  2975,
  2987,
  3002,
  3022,
  3042,
  3046,
  3064,
  3068,
  3099,
  3105,
  3126,
  3131,
  3154,
  3157,
  3171,
  3186,
  3193,
  3206,
  3237,
  3238,
  3256,
  3265,
  3274,
  3279,
  3313,
  3319,
  3329,
  3348,
  3360,
  3375,
  3387,
  3391,
  3410,
  3427,
  3450,
  3455,
  3482,
  3485,
  3498,
  3503,
  3516,
  3521,
  3549,
  3563,
  3579,
  3580,
  3608,
  3616,
  3635,
  3636,
  3649,
  3653,
  3694,
  3701,
  3730,
  3736,
  3766,
  3769,
  3824,
  3840,
  3847,
  3853,
];
