import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { of } from "rxjs";
import { Store } from "@ngrx/store";
import { catchError } from "rxjs/operators";

import { ModalStep } from "@apptypes/view-types/modal.types";
import { MatchReportService } from "@services/match-report/match-report.service";
import { Logger } from "@utils/logger";
import { ToastrService } from "ngx-toastr";

import { RootState } from "src/app/reducers";
import { GetLeagueDashboard } from "src/app/reducers/dashboard/dashboard.actions";
import { GetSeriesMatchupById } from "src/app/reducers/matches/matches.actions";
import { MatchReport } from "src/app/reducers/matches/matches.types";
import { ModalStepperFooterButtonsComponent } from "../modal-stepper-footer-buttons/modal-stepper-footer-buttons.component";

@Component({
  selector: "app-forfeit-confirmation-step",
  templateUrl: "./forfeit-confirmation-step.component.html",
  styleUrls: ["./forfeit-confirmation-step.component.scss"],
})
export class ForfeitConfirmationStepComponent implements ModalStep {
  @Input() public matchReport: MatchReport;
  @Input() public forfeittingTeamId: string = null;
  @Output() public submitForfeitSuccessEvent: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild("footerButtons") public footerButtons: TemplateRef<ModalStepperFooterButtonsComponent>;

  constructor(private _matchService: MatchReportService, private _toastr: ToastrService, private _store: Store<RootState>) {}

  public submitForfeit() {
    Logger.log({
      type: "FORFEIT",
      id: this.matchReport.seriesMatchupsId,
      winner: this.winningTeam,
    });

    this._matchService
      .reportForfeit(this.matchReport.seriesMatchupsId, this.winningTeam)
      .pipe(
        catchError((err) => {
          Logger.error(err);
          if (err.error && err.error.errors && err.error.errors[0]) {
            this._toastr.error(err.error.errors[0]);
          } else {
            this._toastr.error("There was an issue submitting your forfeit.  Please try again or contact an admin");
          }
          return of(null);
        })
      )
      .subscribe((res) => {
        if (res) {
          this._store.dispatch(new GetLeagueDashboard());
          this._store.dispatch(new GetSeriesMatchupById(this.matchReport.seriesMatchupsId));
          this._toastr.success("Your forfeit has been successfully reported.");
          this.submitForfeitSuccessEvent.emit();
        }
      });
  }

  public get winningTeam(): string {
    const winningTeams = this.matchReport.teams.filter((team) => team.id !== this.forfeittingTeamId);
    if(winningTeams[0]){
      return winningTeams[0].id;
    }

    return null;
  }
}
