<aside
  class="c_iconColumn"
  [ngClass]="{ 'c_iconColumn--headerless': isHeaderless }"
>
  <header class="c_iconColumn__heading">
    <ng-content select="[icon]"></ng-content>
    <ng-content select="[heading]"></ng-content>
  </header>
  <ng-content select="[body]"> </ng-content>
</aside>
