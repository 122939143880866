import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { modalOptions } from "@utils/modal-helpers";
import { NgxSmartModalService } from "ngx-smart-modal";
// eslint-disable-next-line max-len
import { TextNotificationLoginModalComponent } from "src/app/components/text-notification-login-modal/text-notification-login-modal.component";
import { NOTIFICATION_MODALS } from "src/app/enums/announcements/announcement-modal-mapping.enum";
import { ProfileAnnouncement } from "src/app/reducers/user/user.types";
@Injectable({
  providedIn: "root",
})
export class NotificationModalService {

  constructor(
    private _modalService: NgxSmartModalService,
    private _http: HttpClient
  ) { }

  public openUserNotificationModal(userAnnouncments: ProfileAnnouncement[]): void {
    // TODO: Eventually break this out into larger logic stuff, probably move some phone things out of here
    const textAnnouncement = userAnnouncments.find(
      (announcement) => announcement.optionalComponentName === NOTIFICATION_MODALS.TEXT_NOTIFICATION_RELEASE
    );

    // effectively disabling text modal without need to remove all imports and stuff
    if (textAnnouncement && userAnnouncments.length === 9999999) {
      this._modalService.create(
        TextNotificationLoginModalComponent.MODAL_ID,
        TextNotificationLoginModalComponent,
        modalOptions
      )
        .setData(textAnnouncement.id)
        .open();
    }

  }
}
