import { HttpHeaders, HttpParams } from "@angular/common/http";
import { SearchLeagueQuery } from "../reducers/leagues/league.types";
import { basicAuthHeaderWPageInfo } from "./auth-utils";

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_LIMIT = 20;

export interface SearchTerms {
  page?: number;
  limit?: number;
}

export interface SearchQuery {
  page: number;
  limit: number;
  total: number;
  pageLimit: number;
}

export interface SearchHeaders {
  headers: HttpHeaders;
  params: HttpParams;
}

export const defaultSearchQuery: SearchQuery = {
  page: DEFAULT_PAGE,
  limit: DEFAULT_PAGE_LIMIT,
  pageLimit: DEFAULT_PAGE,
  total: 0,
};

/**
 * Takes a set of search terms and creates both the http params and the http headers
 * for the api call
 *
 * @param searchTerms is the base set of search terms we will use
 * @author Christian Tweed
 */
export const setupParams = (searchTerms: SearchTerms): SearchHeaders => {
  let params = new HttpParams();
  const { page, limit, ...searchEntries } = searchTerms ?? {
  };
  const queryParams = Object.entries(searchEntries);
  queryParams.map((entry) => {
    //Array values need to be declared as such
    if(entry[1] instanceof Array){
      entry[1].map((arrayItem) => {
        params = params.append(`${entry[0]}[]`, arrayItem);
      });
    }
    else {
      params = params.set(entry[0], entry[1]);
    }
  });
  const headers = basicAuthHeaderWPageInfo({
    pageNumber: page,
    limit,
  });
  return {
    headers,
    params,
  };
};

/**
 * Reads the headers off of the response in order to extrapolate pagination information
 *
 * @param headers is the response's headers
 * @author Christian Tweed
 */
export const readSearchHeaders = (headers: HttpHeaders): SearchQuery => {
  const page = +headers.get("Page");
  const total = +headers.get("Total");
  const limit = +headers.get("Limit");
  const pageLimit = total === 0 ? 1 : Math.ceil(total / limit);
  return {
    page,
    total,
    limit,
    pageLimit,
  };
};

/**
 * Sort the leagues on a search league query in order of start time
 *
 * @param query is from a league search
 * @author Christian Tweed
 */
export const sortLeaguesByStartTime = (query: SearchLeagueQuery): SearchLeagueQuery => {
  query.leagues.sort((a, b) => {
    if (a.startTime > b.startTime) {
      return -1;
    } else if (a.startTime < b.startTime) {
      return 1;
    } else {
      return 0;
    }
  });
  return query;
};
