import { Component, OnInit } from "@angular/core";
import { take, filter } from "rxjs/operators";
import { Store } from "@ngrx/store";
import {
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { Title } from "@angular/platform-browser";

import { UserUpdateForm } from "@services/user/user.api.types";

import {
  formHasError,
  formHasSpecificError,
  nameValidator,
  profanityValidator,
  userFormDuster,
} from "src/app/util/form-helpers";
import { RootState } from "src/app/reducers";
import { UpdateUser } from "src/app/reducers/user/user.actions";
import { userRoutes } from "src/app/enums/routes/routePaths";

@Component({
  selector: "app-user-edit-page",
  templateUrl: "./user-edit-page.component.html",
  styleUrls: ["./user-edit-page.component.scss"],
})
export class UserEditPageComponent implements OnInit {
  public userEditForm: FormGroup;
  public isLoading = true;
  public userRoutes = userRoutes;
  public hasBlockingErrors = false;

  private _userId: number;

  constructor(private _store: Store<RootState>, private _titleService: Title) { }

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | Editing User");
    this._store
      .select("user", "currentUser")
      .pipe(
        filter((userData) => userData !== null),
        take(1)
      )
      .subscribe((userData) => {
        this._userId = userData.id;
        this.userEditForm = new FormGroup({
          firstName: new FormControl(userData.firstName, [nameValidator, Validators.minLength(2)]),
          lastName: new FormControl(userData.lastName, [nameValidator, Validators.minLength(2)]),
          email: new FormControl(userData.email, [Validators.required, Validators.email]),
          username: new FormControl(userData.inGameName, [Validators.required, profanityValidator]),
          discordId: new FormControl(userData.discordId, [Validators.pattern(/^(.+)#(\d{4})$/), profanityValidator]),
          description: new FormControl(userData.description, [profanityValidator]),
          zipCode: new FormControl(userData.zipCode || "", [Validators.pattern(/^(\d{5}([\-]\d{4})?)$/)]),
        });
        this.isLoading = false;
      });
  }

  public saveProfileUpdates(userForm: FormGroup, $event: Event): void {
    $event.preventDefault(); //Prevent the form from reloading
    if (userForm.valid) {
      const formValues = userForm.value;
      const finalFormValues: UserUpdateForm = userFormDuster({
        user: {
          description: formValues.description,
          discord_id: formValues.discordId,
          email: formValues.email,
          first_name: formValues.firstName,
          in_game_name: formValues.username,
          last_name: formValues.lastName,
          zip_code: formValues.zipCode,
        },
      });
      this._store.dispatch(new UpdateUser([finalFormValues, this._userId]));
    } else {
      this.hasBlockingErrors = true;
    }
  }

  public hasError(controlName: string): boolean {
    return formHasError(controlName, this.userEditForm);
  }

  public hasSpecificError(controlName: string, errorName: string): boolean {
    return formHasSpecificError(controlName, errorName, this.userEditForm);
  }

  public get formInvalid(): boolean {
    return this.userEditForm.invalid && this.hasBlockingErrors;
  }
}
