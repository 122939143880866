<div
  class="c_searchPaginationFooter"
  [ngClass]="{ 'c_searchPaginationFooter--darkBackground': useDarkBackground }"
>
  <button
    class="c_button primary_light icon c_searchPaginationFooter__page c_searchPaginationFooter__pageStart"
    *ngIf="paginationInfo.page !== 1"
    (click)="goToStart()"
  >
    <i class="fas fa-angle-double-left c_searchPaginationFooter__icon"></i>
  </button>
  <button
    class="c_button primary_light icon c_searchPaginationFooter__page c_searchPaginationFooter__pageDecrement"
    *ngIf="paginationInfo.page !== 1"
    (click)="decrement()"
  >
    <i class="fas fa-angle-left c_searchPaginationFooter__icon"></i>
  </button>
  <span class="c_searchPaginationFooter__pageNumber">
    Page {{ paginationInfo.page }} of {{ paginationInfo.pageLimit }}
  </span>
  <button
    class="c_button primary_light icon c_searchPaginationFooter__page c_searchPaginationFooter__pageIncrement"
    *ngIf="paginationInfo.page !== paginationInfo.pageLimit"
    (click)="increment()"
  >
    <i class="fas fa-angle-right c_searchPaginationFooter__icon"></i>
  </button>
  <button
    class="c_button primary_light icon c_searchPaginationFooter__page c_searchPaginationFooter__pageEnd"
    *ngIf="paginationInfo.page !== paginationInfo.pageLimit"
    (click)="goToEnd()"
  >
    <i class="fas fa-angle-double-right c_searchPaginationFooter__icon"></i>
  </button>
</div>
