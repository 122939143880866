<app-page>
  <header>
    <h1 class="u_txt--hdg1 u_txt--white">Activate an Esport Pass</h1>
    <h3 *ngIf="league$ | async as league" class="u_txt--hdg3 u_txt--white">
      <a
        class="u_txt--link--white"
        [routerLink]="league.id | leagueDetailRouter"
      >
        {{ league.title }}
      </a>
    </h3>
  </header>
  <article
    class="c_leaguesPassesPage"
    *ngIf="data$ | async as data; else loading"
  >
    <app-league-passes-info-block
      [league]="data.league"
    ></app-league-passes-info-block>
    <app-league-pass-list
      [userClubs]="data.userOrganizations"
      [seasonPassList]="data.seasonPasses"
    >
    </app-league-pass-list>
  </article>
  <ng-template #loading>
    <app-loader [useWhite]="true">Loading League Passes...</app-loader>
  </ng-template>
</app-page>
