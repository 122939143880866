import { ISOString } from "./date.types";

export enum QuickPlayEventStatus {
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  COMPLETE = "complete",
}

//Maps to the status in tournaments.rb
//TODO: Consolidate this and LeagueBracketStatus
export enum QuickPlayEventTournamentStatus {
  PROCESSING = "processing",
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  COMPLETE = "complete",
  CANCELLED = "cancelled",
}

export enum QuickPlayEventTournamentTeamStatus {
  BYE_WEEK = "bye_week",
  ACTIVE = "active",
  ELIMINATED = "eliminated",
  CHAMPION = "champion",
  DISQUALIFIED = "disqualified",
  INACTIVE = "inactive",
}

export interface QuickPlayEvent {
  id: string;
  type: "quickPlayEvents";
  chatroomReferenceId: string;
  status: QuickPlayEventStatus;
  streamReferenceId: string | null;
}

export interface QuickPlayEventStream {
  id: string;
  leagueID: string;
  startTime: ISOString;
  status: QuickPlayEventStatus;
  createdAt: ISOString;
}

export interface QuickPlayEventStreamCheckin {
  esportCredentialSnapshot: string | null;
  quickPlayEventCheckinID: string;
  teamID: string;
  userID: string;
}

export interface QuickPlayEventStreamTournamentTeam {
  status: QuickPlayEventTournamentTeamStatus;
  teamID: string;
  tournamentID: string;
  userIDs: string[];
}

export interface QuickPlayEventStreamTournament {
  schedulingType: string;
  status: QuickPlayEventTournamentStatus;
  tournamentID: string;
}

export interface QuickPlayEventStreamData {
  quickPlayEvent: QuickPlayEventStream;
  checkins: QuickPlayEventStreamCheckin[];
  tournamentTeams: QuickPlayEventStreamTournamentTeam[];
  tournaments: QuickPlayEventStreamTournament[];
}
