import {
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Title } from "@angular/platform-browser";
import { Observable, of } from "rxjs";
import {
  pluck,
  filter,
  map,
  takeUntil,
} from "rxjs/operators";
import { NgxSmartModalService } from "ngx-smart-modal";

import { RootState } from "src/app/reducers";
import { UserTeam } from "src/app/reducers/user/user.types";
import { Unsubscriber } from "src/app/util/unsubscriber";
import {
  teamRoutes,
  generateTeamDetailRoute,
  staticRoutes,
} from "src/app/enums/routes/routePaths";
import { TeamTypes } from "src/app/reducers/teams/teams.types";
import { AccountTeamExplainerModalComponent } from "src/app/components/account-team-explainer-modal/account-team-explainer-modal.component";
@Component({
  selector: "app-my-teams-page",
  templateUrl: "./my-teams-page.component.html",
  styleUrls: ["./my-teams-page.component.scss"],
})
export class MyTeamsPageComponent implements OnDestroy, OnInit {
  public isLoading$: Observable<boolean> = of(true);
  public userTeams$: Observable<UserTeam[]>;
  public userAccountTeam$: Observable<UserTeam>;
  public teamRoutes = teamRoutes;
  public staticRoutes = staticRoutes;

  private _unsubscriber = new Unsubscriber();

  constructor(
    private _store: Store<RootState>,
    private _titleService: Title,
    private _modal: NgxSmartModalService
  ) {
    this.userTeams$ = this._store.select("user", "currentUser").pipe(
      filter((currentUser) => currentUser !== null),
      pluck("teams"),
      map((teams) =>
        teams.filter((team) => team.teamType === TeamTypes.STANDARD)
      ),
      takeUntil(this._unsubscriber.unsubEvent)
    );

    this.userAccountTeam$ = this._store.select("user", "currentUser").pipe(
      filter((currentUser) => currentUser !== null),
      pluck("teams"),
      map((teams) =>
        teams.find((team) => team.teamType === TeamTypes.ACCOUNT_SOLO_TEAM)
      ),
      takeUntil(this._unsubscriber.unsubEvent)
    );

    this.isLoading$ = this.userTeams$.pipe(
      map((userTeams) => {
        if (userTeams !== null) {
          return false;
        }

        return true;
      })
    );
  }

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | My Teams");
  }

  public ngOnDestroy() {
    this._unsubscriber.kill();
  }

  public getTeamDetailRoute(id: number): string {
    return generateTeamDetailRoute(id.toString());
  }

  public openAcctModal(): void {
    this._modal.create("accountTeam", AccountTeamExplainerModalComponent, {
      closable: false,
      dismissable: false,
      customClass: "c_ngxSmartModal",
    }).open();
  }
}
