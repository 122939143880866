import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { Store } from "@ngrx/store";
import {
  map,
  filter,
  mergeMap,
  take,
} from "rxjs/operators";

import { RootState } from "../reducers";
import { StashLoginRoute, RestoreUserLogin } from "../reducers/user/user.actions";
import { UserProfile } from "../reducers/user/user.types";
import { SessionStorageKeys } from "../enums/session-storage-keys.enum";
import { userRoutes } from "src/app/enums/routes/routePaths";
import { isValidRouteToStash } from "../util/auth-utils";
import { Logger } from "@utils/logger";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  public userRoutes = userRoutes;

  constructor(private _router: Router, private _store: Store<RootState>) { }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._waitForLoginToLoad().pipe(
      take(1),
      map((user) => {
        const userIsLoggedIn = !!user;

        if (userIsLoggedIn) {
          return true;
        }

        // Keep our tracker ID so we don't lose it
        try {
          const ridref = next.queryParamMap.get("rid");
          const fbclidref = next.queryParamMap.get("fbclid");
          const gclidref = next.queryParamMap.get("gclid");
          const twtrRef = next.queryParamMap.get("twtr");


          if (fbclidref) {
            localStorage.setItem(SessionStorageKeys.FB_CAMPAIGN_REF, fbclidref);
          }
          if (ridref) {
            localStorage.setItem(SessionStorageKeys.REDDIT_CAMPAIGN_REF, ridref);
          }
          if (gclidref) {
            localStorage.setItem(SessionStorageKeys.GOOGLE_CAMPAIGN_REF, gclidref);
          }
          if (twtrRef) {
            localStorage.setItem(SessionStorageKeys.TWITTER_CAMPAIGN_REF, twtrRef);
          }
        } catch (e) {
          Logger.error("Problem handling campaign query param");
          Logger.error(e);
        }

        if (isValidRouteToStash(state.url)) {
          // Stash the route initial attempted to navigate to it after login
          // Remove query params which get garbled on stashing as string
          const safeStashUrl = state.url.split("?")[0];
          this._store.dispatch(new StashLoginRoute(safeStashUrl));
        }
        this._router.navigate([`/${userRoutes.LOGIN_REDIRECT}`]);
        return false;
      })
    );
  }

  private _waitForLoginToLoad(): Observable<UserProfile> {
    return this._store.select("user", "currentUser").pipe(
      mergeMap((userProfile) => {
        if (userProfile) {
          return of(userProfile);
        }

        const sessionJWT = localStorage.getItem(SessionStorageKeys.USER_JWT);

        if (sessionJWT) {
          this._store.dispatch(
            new RestoreUserLogin({
              jwt: sessionJWT,
            })
          );
          return this._store.select("user", "fetchingProfile").pipe(
            filter((fetchingProfile) => !fetchingProfile),
            mergeMap(() => this._store.select("user", "currentUser"))
          );
        } else {
          return of(null);
        }
      })
    );
  }
}
