<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
></app-modal-container>

<ng-template #title>
  <app-modal-title>No Match Found...</app-modal-title>
</ng-template>

<ng-template #content>
  <p>
    There were an odd number of people in queue this week, and unable to match
    you with anyone to play with this week...
  </p>
  <p>You will be given a bye-week win for this week in the tournament</p>
</ng-template>
