<div class="c_battleRoyaleMatchesPreview">
  <h5 class="u_txt--hdg5">
    <ng-content></ng-content>
  </h5>
  <div
    class="c_battleRoyaleMatchesPreview__list"
    *ngIf="battleRoyaleMatches.length > 0; else noMatches"
  >
    <div
      class="c_battleRoyaleMatchesPreview__listItem"
      *ngFor="let match of battleRoyaleMatches.slice(0, 4)"
    >
      <app-small-battle-royale-match-block
        [battleRoyaleMatchup]="match"
      ></app-small-battle-royale-match-block>
    </div>
  </div>
  <ng-template #noMatches>
    <div class="c_battleRoyaleMatchesPreview__empty">
      <p class="u_txt">There are no matches for this category</p>
    </div>
  </ng-template>
</div>
