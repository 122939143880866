<div class="c_reportConfirmationStep__confirmationInfo">
  <p><strong>Confirm the results of the match below</strong></p>
  <small>
    Please check the following results and ensure they are correct. Once
    submitted they <strong class="u_txt--warn">can not</strong> be edited
  </small>
</div>
<div
  class="
    c_reportConfirmationStep__warning c_reportConfirmationStep__warning--mobile
  "
  *ngIf="notEnoughGamesToDeclareWinner"
>
  <i
    class="
      fas
      fa-2x fa-exclamation-circle
      c_reportConfirmationStep__warningIcon
    "
  ></i>
  <p class="u_txt c_reportConfirmationStep__tableText">
    There isn't a winner from the game results, are you sure you want to
    continue?
  </p>
</div>

<div class="c_reportConfirmationStep__teamMatchup">
  <div class="c_reportConfirmationStep__team">
    <img
      [src]="matchReport.teams[0]?.logoUrl | teamLogoDefault"
      alt="Team One Icon"
    />
    <strong>{{ matchReport.teams[0]?.title }}</strong>
  </div>

  <div class="c_reportConfirmationStep__vsBlock">
    <p class="c_reportConfirmationStep__vsTag">VS</p>
    <span>{{ teamOneWins }} - {{ teamTwoWins }}</span>
  </div>

  <div class="c_reportConfirmationStep__team">
    <img
      [src]="matchReport.teams[1]?.logoUrl | teamLogoDefault"
      alt="Team Two Icon"
    />
    <strong>{{ matchReport.teams[1]?.title }}</strong>
  </div>
</div>

<div
  class="
    c_reportConfirmationStep__warning c_reportConfirmationStep__warning--desktop
  "
  *ngIf="notEnoughGamesToDeclareWinner"
>
  <i
    class="
      fas
      fa-2x fa-exclamation-circle
      c_reportConfirmationStep__warningIcon
    "
  ></i>
  <p class="u_txt c_reportConfirmationStep__tableText">
    There isn't a winner from the game results, are you sure you want to
    continue?
  </p>
</div>

<ng-template #footerButtons>
  <app-modal-stepper-footer-buttons [hasPrevious]="true" [hasNext]="false">
    <button
      class="
        c_button
        primary_dark
        c_reportConfirmationStep__button
        c_modal__button
      "
      (click)="submitReport()"
    >
      Confirm
    </button>
  </app-modal-stepper-footer-buttons>
</ng-template>
