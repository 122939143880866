import { Action } from "@ngrx/store";
import { Club, ClubSubscription } from "./club.types";


export enum ClubActionTypes {
  GET_CLUB_BY_ID = "[Club] Get Club by Id or Slug",
  GET_CLUB_BY_ID_ERROR = "[Club] Failed to Get Club by Id or Slug",
  GET_CLUB_BY_ID_SUCCESS = "[Club] Get Club by Id or Slug Success",
  GET_CLUB_SUBSCRIPTIONS = "[Club] Get Club Subscriptions",
  GET_CLUB_SUBSCRIPTIONS_ERROR = "[Club] Failed to Get Club Subscriptions",
  GET_CLUB_SUBSCRIPTIONS_SUCCESS = "[Club] Get Club Sucscriptions Success",
  SUBSCRIBE_TO_CLUB = "[Club] Subscribe to Club",
  SUBSCRIBE_TO_CLUB_ERROR = "[Club] Subscribe to Club Error",
  SUBSCRIBE_TO_CLUB_SUCCESS = "[Club] Subscribe to Club Success",
  UNSUBSCRIBE_TO_CLUB = "[Club] Unsubscribe to Club",
  UNSUBSCRIBE_TO_CLUB_ERROR = "[Club] Unsubscribe to Club Error",
  UNSUBSCRIBE_TO_CLUB_SUCCESS = "[Club] Unsubscribe to Club Success",
}

export class GetClubById implements Action {
  public readonly type = ClubActionTypes.GET_CLUB_BY_ID;
  constructor(public payload: string) { }
}

export class GetClubByIdError implements Action {
  public readonly type = ClubActionTypes.GET_CLUB_BY_ID_ERROR;
  constructor(public payload: void) { }
}

export class GetClubByIdSuccess implements Action {
  public readonly type = ClubActionTypes.GET_CLUB_BY_ID_SUCCESS;
  constructor(public payload: Club) { }
}

export class GetClubSubscriptions implements Action {
  public readonly type = ClubActionTypes.GET_CLUB_SUBSCRIPTIONS;
  constructor(public payload: void) {}
}

export class GetClubSubscriptionsError implements Action {
  public readonly type = ClubActionTypes.GET_CLUB_SUBSCRIPTIONS_ERROR;
  constructor(public payload: void) {}
}

export class GetClubSubscriptionsSuccess implements Action {
  public readonly type = ClubActionTypes.GET_CLUB_SUBSCRIPTIONS_SUCCESS;
  constructor(public payload: ClubSubscription[]) {}
}

export class SubscribeToClub implements Action {
  public readonly type = ClubActionTypes.SUBSCRIBE_TO_CLUB;
  constructor(public payload: string | number) {}
}

export class SubscribeToClubError implements Action {
  public readonly type = ClubActionTypes.SUBSCRIBE_TO_CLUB_ERROR;
  constructor(public payload: void) {}
}

export class SubscribeToClubSuccess implements Action {
  public readonly type = ClubActionTypes.SUBSCRIBE_TO_CLUB_SUCCESS;
  constructor(public payload: void) {}
}

export class UnsubscribeToClub implements Action {
  public readonly type = ClubActionTypes.UNSUBSCRIBE_TO_CLUB;
  constructor(public payload: string | number) {}
}

export class UnsubscribeToClubError implements Action {
  public readonly type = ClubActionTypes.UNSUBSCRIBE_TO_CLUB_ERROR;
  constructor(public payload: void) {}
}

export class UnsubscribeToClubSuccess implements Action {
  public readonly type = ClubActionTypes.UNSUBSCRIBE_TO_CLUB_SUCCESS;
  constructor(public payload: void) {}
}

export type ClubActions =
  | GetClubById
  | GetClubByIdError
  | GetClubByIdSuccess
  | GetClubSubscriptions
  | GetClubSubscriptionsError
  | GetClubSubscriptionsSuccess
  | SubscribeToClub
  | SubscribeToClubError
  | SubscribeToClubSuccess
  | UnsubscribeToClub
  | UnsubscribeToClubError
  | UnsubscribeToClubSuccess;
