/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { ClubState } from "./club.types";
import { ClubActions, ClubActionTypes } from "./club.actions";

const initialState: ClubState = {
  club: null,
  fetchingClub: false,
  fetchingClubError: false,
  clubSubscriptions: [],
  fetchingClubSubscriptions: false,
  fetchingClubSubscriptionsError: false,
  subscribingToClub: false,
  subscribingToClubError: false,
  unsubscribingToClub: false,
  unsubscribingToClubError: false,
};

export function clubReducer(
  state = initialState,
  action: ClubActions
): ClubState {
  switch (action.type) {
    case ClubActionTypes.GET_CLUB_BY_ID:
      return {
        ...state,
        club: null,
        fetchingClub: false,
        fetchingClubError: false,
      };
    case ClubActionTypes.GET_CLUB_BY_ID_ERROR:
      return {
        ...state,
        club: null,
        fetchingClub: false,
        fetchingClubError: true,
      };
    case ClubActionTypes.GET_CLUB_BY_ID_SUCCESS:
      return {
        ...state,
        club: action.payload,
        fetchingClub: false,
        fetchingClubError: false,
      };
    case ClubActionTypes.GET_CLUB_SUBSCRIPTIONS:
      return {
        ...state,
        clubSubscriptions: [],
        fetchingClubSubscriptions: true,
        fetchingClubSubscriptionsError: false,
      };
    case ClubActionTypes.GET_CLUB_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        clubSubscriptions: [],
        fetchingClubSubscriptions: false,
        fetchingClubSubscriptionsError: true,
      };
    case ClubActionTypes.GET_CLUB_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        clubSubscriptions: action.payload,
        fetchingClubSubscriptions: false,
        fetchingClubSubscriptionsError: false,
      };
    case ClubActionTypes.SUBSCRIBE_TO_CLUB:
      return {
        ...state,
        subscribingToClub: true,
        subscribingToClubError: false,
      };
    case ClubActionTypes.SUBSCRIBE_TO_CLUB_ERROR:
      return {
        ...state,
        subscribingToClub: false,
        subscribingToClubError: true,
      };
    case ClubActionTypes.SUBSCRIBE_TO_CLUB_SUCCESS:
      return {
        ...state,
        subscribingToClub: false,
        subscribingToClubError: false,
      };
    case ClubActionTypes.UNSUBSCRIBE_TO_CLUB:
      return {
        ...state,
        unsubscribingToClub: true,
        unsubscribingToClubError: false,
      };
    case ClubActionTypes.UNSUBSCRIBE_TO_CLUB_ERROR:
      return {
        ...state,
        unsubscribingToClub: false,
        unsubscribingToClubError: true,
      };
    case ClubActionTypes.UNSUBSCRIBE_TO_CLUB_SUCCESS:
      return {
        ...state,
        unsubscribingToClub: false,
        unsubscribingToClubError: false,
      };
    default:
      return state;
  }
}
