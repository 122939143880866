<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
></app-modal-container>

<ng-template #title>
  <h3 class="u_txt--hdg3 c_beginQueueModalTitle">Joining League Queue</h3>
</ng-template>

<ng-template #content>
  <p>
    You are joining the queue to play your scheduled league match for the week.
    The queue may last up to 25 minutes and always ends 15 minutes after the
    queue start time. If you stay on the queue page you will be automatically
    redirected when your match is found, otherwise you will recieve an email and
    a text message (if they are enabled).
  </p>
  <p>
    Once any member of your team joins the queue, your entire team will be
    entered into the queue.
    <strong class="u_txt--warn">
      Leaving the page will not remove you from the queue!
    </strong>
  </p>
  <p>
    There is a chance that if an odd number of teams queue, one team may not get
    a match for the week.
    <strong>
      If this occurs you will be notified when the queue closes, get a bye-week
      win for the week
    </strong>
  </p>
  <div class="c_beginQueueButtonContainer">
    <button (click)="declineQueue()" class="c_button error">
      Decline Queue
    </button>
    <button (click)="joinQueue()" class="c_button success">Join Queue</button>
  </div>
</ng-template>
