import { Component, Input } from "@angular/core";

@Component({
  selector: "app-checkout-step",
  templateUrl: "./checkout-step.component.html",
  styleUrls: ["./checkout-step.component.scss"],
})
export class CheckoutStepComponent {
  @Input() public paymentSuccessFunction: (event: Event) => void;
  @Input() public paymentItem: string;
}
