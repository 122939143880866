import { Component, Input } from "@angular/core";

import { UserTeam } from "src/app/reducers/user/user.types";

@Component({
  selector: "app-player-team-block",
  templateUrl: "./player-team-block.component.html",
  styleUrls: ["./player-team-block.component.scss"],
})
export class PlayerTeamBlockComponent {
  @Input() public teams: UserTeam[];
  public teamImage: string[];
}
