/* eslint-disable operator-linebreak */

import { CanDeactivate } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class LeaveQueueGuard implements CanDeactivate<ComponentCanDeactivate> {
  public canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate() ?
      true :
      // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
      // when navigating away from your angular app, the browser will show a generic warning message
      // see http://stackoverflow.com/a/42207299/7307355
      confirm(
        "Attention!  You're leaving the queue page while still being queued for a match."
        + "  You will stay in queue while you're gone, and you may not be automatically redirected"
        + " to your match page when you find your match"
      );
  }
}
