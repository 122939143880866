import {
  Component,
  forwardRef,
  Input,
} from "@angular/core";
import {
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from "@angular/forms";

import { RegistrationTypes } from "src/app/enums/registration-types.enum";
import { BaseCVA } from "src/app/components/base-cva/base-cva.class";
import { JoinLeagueModalService } from "src/app/stateful-services/join-league-modal/join-league-modal.service";

@Component({
  selector: "app-registration-selection-step",
  templateUrl: "./registration-selection-step.component.html",
  styleUrls: ["./registration-selection-step.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RegistrationSelectionStepComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RegistrationSelectionStepComponent),
      multi: true,
    },
  ],
})
export class RegistrationSelectionStepComponent
  extends BaseCVA
  implements Validator {
  @Input() public sponsorOnlyMode = false;
  public registrationTypes = RegistrationTypes;

  constructor(private _formBuilder: FormBuilder, private _joinLeagueModalService: JoinLeagueModalService) {
    super();
    this.controlForm = this._formBuilder.group({
      registrationType: [null, Validators.required],
    });
  }

  public get registrationType(): RegistrationTypes {
    return this.controlForm.get("registrationType").value;
  }

  public get errorMessage(): string {
    return this.controlForm.invalid ? "No registration type selected" : "";
  }

  public get isFirstStep(): boolean {
    return this._joinLeagueModalService.isRegistrationStepFirst;
  }

  /* Validator Functions */

  public validate(): ValidationErrors | null {
    return this.controlForm.valid ?
      null :
      {
        invalidForm: {
          valid: false,
          message: "No registration type selected",
        },
      };
  }
}
