<section class="c_bracketController">
  <ng-container *ngIf="!isLoading; else loading">
    <ng-container
      *ngIf="bracketArray && bracketArray.length > 0; else nobrackets"
    >
      <div class="c_bracketController__switcher" *ngIf="bracketList.length > 1">
        <select (change)="updateBracket($event)">
          <option
            *ngFor="let bracketOption of bracketList; let bIndex = index"
            [value]="bIndex"
          >
            {{ bracketOption.title }}
          </option>
        </select>
      </div>
      <header class="c_bracketController__heading">
        <button
          class="c_button primary_dark--alt"
          (click)="decrementIndex()"
          [ngClass]="{
            c_bracketController__hideButton: currentRoundIndex <= 0
          }"
        >
          <span>
            <i class="fas fa-arrow-left"></i>
            <span class="c_bracketController__buttonCopy"> Prev. Round </span>
          </span>
        </button>
        <button
          class="c_button primary_dark--alt"
          (click)="incrementIndex()"
          [ngClass]="{
            c_bracketController__hideButton: currentRoundIndex >= indexMax
          }"
        >
          <span>
            <span class="c_bracketController__buttonCopy"> Next Round </span>
            <i class="fas fa-arrow-right"></i>
          </span>
        </button>
      </header>
      <app-playoff-bracket
        [bracketArray]="bracketArray"
        [maxRounds]="maxRounds"
      ></app-playoff-bracket>
    </ng-container>
    <ng-template #nobrackets>
      <div class="c_bracketController__noBracketCopy">
        <i class="far fa-calendar-alt"></i>
        <p>There is no bracket for this league yet</p>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loading> Bracket data is loading </ng-template>
</section>
