/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { TeamsState } from "./teams.types";
import { TeamsActions, TeamsActionTypes } from "./teams.actions";

const initialState: TeamsState = {
  currentTeam: null,
  allTeams: null,
  fetching: false,
  fetchingId: false,
  teamIdError: false,
  teamsError: false,
  isUpdatingRoster: false,
  addingTeamToLeague: false,
  addingTeamToLeagueError: false,
};

export function teamsReducer(state = initialState, action: TeamsActions): TeamsState {
  switch (action.type) {
    case TeamsActionTypes.GET_TEAMS:
      return {
        ...state,
        allTeams: null,
        fetching: true,
      };
    case TeamsActionTypes.GET_TEAM_BY_ID:
      return {
        ...state,
        currentTeam: null,
        fetchingId: true,
      };
    case TeamsActionTypes.GET_TEAM_BY_ID_SUCCESS:
      return {
        ...state,
        currentTeam: action.payload,
        fetchingId: false,
        teamIdError: false,
      };
    case TeamsActionTypes.GET_TEAMS_SUCCESS:
      return {
        ...state,
        allTeams: action.payload,
        fetching: false,
        teamsError: false,
      };
    case TeamsActionTypes.GET_TEAM_BY_ID_ERROR:
      return {
        ...state,
        currentTeam: null,
        fetchingId: false,
        teamIdError: true,
      };
    case TeamsActionTypes.GET_TEAMS_ERROR:
      return {
        ...state,
        allTeams: null,
        fetching: false,
        teamsError: true,
      };
    case TeamsActionTypes.UPDATE_ROSTER:
    case TeamsActionTypes.ROSTER_REQUEST:
      return {
        ...state,
        isUpdatingRoster: true,
      };
    case TeamsActionTypes.UPDATE_ROSTER_ERROR:
    case TeamsActionTypes.UPDATE_ROSTER_SUCCESS:
    case TeamsActionTypes.ROSTER_REQUEST_ERROR:
    case TeamsActionTypes.ROSTER_REQUEST_SUCCESS:
      return {
        ...state,
        isUpdatingRoster: false,
      };
    case TeamsActionTypes.ADD_TEAM_TO_LEAGUE:
      return {
        ...state,
        addingTeamToLeague: true,
        addingTeamToLeagueError: false,
      };
    case TeamsActionTypes.ADD_TEAM_TO_LEAGUE_ERROR:
      return {
        ...state,
        addingTeamToLeague: false,
        addingTeamToLeagueError: true,
      };
    case TeamsActionTypes.ADD_TEAM_TO_LEAGUE_SUCCESS:
      return {
        ...state,
        addingTeamToLeague: false,
        addingTeamToLeagueError: false,
      };
    case TeamsActionTypes.LOCAL_TEAM_UPDATE:
      const { currentTeam } = state;
      return {
        ...state,
        currentTeam: {
          ...currentTeam,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
