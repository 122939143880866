<section class="c_notificationManagementPanel">
  <header>
    <h4 class="u_txt--hdg4">Notification Management</h4>
    <hr />
  </header>
  <p>
    By enabling game notifications, you will receive a text message reminder the
    morning of your match, and 30 minutes before your matches. Make sure to stay
    up to date and never miss a match again!
  </p>
  <div>
    <p>
      <strong>
        Notifications are currently:
        <ng-container *ngIf="enableNotifications">
          <span class="u_txt--success">Enabled</span>
          on {{ userPhone$ | async }}
        </ng-container>
        <span class="u_txt--warn" *ngIf="!enableNotifications">Disabled</span>
      </strong>
    </p>
  </div>
  <ng-container *ngIf="!isLoading; else loading">
    <ng-container *ngIf="!enableNotifications; else userHasTextNotifs">
      <form [formGroup]="notificationForm">
        <label for="phone"> Phone Number </label>
        <input
          class="c_notificationManagementPanel__phoneInput"
          formControlName="phone"
          type="tel"
          appPhoneNumber
          placeholder="(123)-456-7890"
        />
        <p
          class="c_notificationManagementPanel__errorMessage"
          *ngIf="isPhoneNumberInvalid"
        >
          Your phone number is invalid
        </p>

        <div class="c_notificationManagementPanel__formButtons">
          <button
            type="submit"
            class="c_button primary_dark"
            (click)="enableNotificationTexts()"
          >
            Enable Notifications
          </button>
        </div>
      </form>
    </ng-container>
    <ng-template #userHasTextNotifs>
      <div class="c_notificationManagementPanel__formButtons">
        <button
          type="submit"
          class="c_button error"
          (click)="disableNotifications()"
        >
          Disable Notifications
        </button>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loading>
    <app-loader alternateLoadingText="Updating Phone Number"></app-loader>
  </ng-template>
</section>
