<ng-template #title>
  <app-modal-title>Game {{ gameIndex }} Results</app-modal-title>
</ng-template>

<ng-template #content>
  <app-tabs-block [darkBackground]="false">
    <app-tab tabTitle="Final Scores">
      <ng-container *ngIf="gameHasFinalScores; else noScore">
        <div class="c_resultsTable">
          <table>
            <thead>
              <tr>
                <th>
                  <span> Team Name</span>
                </th>
                <th><span>Final Score</span></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let gameScore of sortedScores">
                <td>{{ getTeamName(gameScore.team_id) }}</td>
                <td>{{ gameScore.fortnite_team_scores }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <small>
          Final score is calculated by adding the weighted score for your
          placement plus a bonus for each elimination you had during a match. It
          may not always be the same as the person who placed first in a battle
          royale
        </small>
      </ng-container>
      <ng-template #noScore>
        <p>
          The results from this game haven't been scored yet. You can check
          'Game Stats' to see the performance of players from this game
        </p>
      </ng-template>
    </app-tab>
    <app-tab tabTitle="Game Stats">
      <ng-container *ngIf="gameHasUploadedStats; else noStats">
        <div class="c_resultsTable">
          <table>
            <thead>
              <tr>
                <th>
                  <span> Fortnite Name </span>
                </th>
                <th><span>Placement</span></th>
                <th><span>Eliminations</span></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let gameStat of sortedGameStats">
                <td>{{ gameStat.fortnite_name }}</td>
                <td>{{ gameStat.match_placement }}</td>
                <td>{{ gameStat.elimination_count }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <small>
          Game Stats are the raw placement and elimination values reported to us
          by Fortnite at the end of your game before we calculate team points
        </small>
      </ng-container>
      <ng-template #noStats>
        <p>
          The results from this game haven't finished uploading from Fortnite
          yet.
        </p>
      </ng-template>
    </app-tab>
  </app-tabs-block>
</ng-template>

<app-modal-container [modalTitle]="title" [modalContent]="content">
</app-modal-container>
