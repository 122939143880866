import { Component } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";

import { modalOptions } from "src/app/util/modal-helpers";
import { ContactAdminModalComponent } from "../contact-admin-modal/contact-admin-modal.component";

@Component({
  selector: "app-contact-admin-button",
  templateUrl: "./contact-admin-button.component.html",
  styleUrls: ["./contact-admin-button.component.scss"],
})
export class ContactAdminButtonComponent {
  constructor(
    private _modalService: NgxSmartModalService
  ) {}

  public openDialogue() {
    this._modalService.create("contactAdmin", ContactAdminModalComponent, modalOptions).open();
  }
}
