import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { isUsingMobileSafari, MOBILE_SAFARI_CLASS_MODIFIER } from "src/app/util/browser-detection.utils";

import { ModalFooterButtonsComponent } from "../modal-footer-buttons/modal-footer-buttons.component";
import { ModalTitleComponent } from "../modal-title/modal-title.component";

@Component({
  selector: "app-modal-container",
  templateUrl: "./modal-container.component.html",
  styleUrls: ["./modal-container.component.scss"],
})
export class ModalContainerComponent implements OnDestroy, OnInit {
  @Input()
  public modalTitle: TemplateRef<ModalTitleComponent>;

  @Input()
  public modalContent: TemplateRef<unknown>;

  @Input()
  public modalFooter: TemplateRef<ModalFooterButtonsComponent>;

  @Input()
  public isStepper = false;

  constructor(
    private _ngxModalService: NgxSmartModalService
  ) { }

  @HostListener("window:popstate", ["$event"])
  public dismissModal() {
    this._ngxModalService.closeAll();
  }

  public ngOnDestroy() {
    // Check to make sure that modal is still in history state
    if (window?.history?.state?.modal) {
      // "Go Back" to before modal state was injected, restoring proper history
      history.back();
    }
  }

  public ngOnInit() {
    // Creates "false state" for history preventing back button from going to previous route
    const modalState = {
      modal: true,
    };
    history.pushState(modalState, null);

    if (isUsingMobileSafari()) {
      document.querySelector("body").classList.add(MOBILE_SAFARI_CLASS_MODIFIER);
    }
  }
}
