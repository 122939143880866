<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
  [modalFooter]="footer"
></app-modal-container>

<ng-template #title>
  <app-modal-title> Need assistance from a GGLeagues admin? </app-modal-title>
</ng-template>

<ng-template #content>
  <p class="u_txt c_contactAdminModal__description">
    Provide a brief description below and our team will follow up with you
    directly through email or in chat on this page. This form is NOT for
    reporting match scores.
  </p>
  <ng-container [formGroup]="contactForm">
    <textarea
      class="c_input c_contactAdminModal__input"
      formControlName="contactMessage"
    ></textarea>
  </ng-container>
</ng-template>

<ng-template #footer>
  <app-modal-footer-buttons>
    <button
      mat-button
      (click)="contactAdmin()"
      class="c_button primary_dark c_contactAdminModal__submitButton"
    >
      Contact Admin
    </button>
  </app-modal-footer-buttons>
</ng-template>
