import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { OrganizationAPISeasonPass } from "@services/organizations/organization.api.types";
import { basicAuthHeader } from "@utils/auth-utils";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { apiToPlatform } from "src/app/enums/game-platforms.enum";
import {
  Club,
  ClubSubscription,
  ClubSubscriptionOrganization,
} from "src/app/reducers/clubs/club.types";
import { OrganizationSeasonPass } from "src/app/reducers/organization/organization.types";
import {
  APIClubResponse,
  APIClubSubscription,
  APIClubSubscriptionOrganization,
} from "./club.api.types";

@Injectable({
  providedIn: "root",
})
export class ClubsService {

  constructor(
    private _http: HttpClient
  ) { }

  public getClub(slug: string): Observable<Club> {
    const url = `${environment.apiBase}/api/v2/clubs/${slug}`;
    const headers = basicAuthHeader();

    return this._http.get<{ data: APIClubResponse }>(url, {
      headers,
    }).pipe(
      map((apiClub) => this._clubMapper(apiClub.data))
    );
  }

  public subscribeToClub(clubId: string | number): Observable<{ success: true }> {
    const url = `${environment.apiBase}/api/v2/club_subscriptions/subscribe`;
    const payload = {
      club_subscription: {
        club_id: clubId,
      },
    };
    const options = {
      headers: basicAuthHeader(),
    };

    return this._http.post<{ success: true }>(url, payload, options);
  }

  public unsubscribeToClub(clubSubId: string | number): Observable<{ success: true }> {
    const url = `${environment.apiBase}/api/v2/club_subscriptions/${clubSubId}/unsubscribe`;

    const options = {
      headers: basicAuthHeader(),
    };

    return this._http.post<{ success: true }>(url, null, options);
  }

  public getUserClubSubscription(): Observable<ClubSubscription[]> {
    const url = `${environment.apiBase}/api/v2/club_subscriptions`;
    const options = {
      headers: basicAuthHeader(),
    };

    const mapClubSubscriptionOrg = (
      { data: { id, type, attributes } }: { data: APIClubSubscriptionOrganization }
    ): ClubSubscriptionOrganization => ({
      id,
      type,
      ...attributes,
    });

    const mapClubSubscription = ({ id, type, attributes: { clubId, organization } }: APIClubSubscription): ClubSubscription => ({
      id,
      type,
      clubId: clubId?.toString(),
      organization: mapClubSubscriptionOrg(organization),
    });

    const mapSubscriptions = ({ data }: { data: APIClubSubscription[] }): ClubSubscription[] => data.map(mapClubSubscription);

    return this._http.get<{ data: APIClubSubscription[] }>(url, options).pipe(map(mapSubscriptions));
  }

  private _clubMapper(apiData: APIClubResponse): Club {
    const orgData = apiData.attributes.organization.data;
    const orgAttributes = orgData.attributes;
    const apiSeasonPassesAcceptingRegistration = orgAttributes.seasonPassesAcceptingRegistration;
    const apiAvailableEsports = orgAttributes.availableEsports;
    const mapSeasonPasses = ({ id, type, attributes }: OrganizationAPISeasonPass): OrganizationSeasonPass => ({
      id,
      type,
      ...attributes,
    });
    const seasonPassesAcceptingRegistration = apiSeasonPassesAcceptingRegistration.data.map(mapSeasonPasses);
    const availableEsports = apiAvailableEsports.data.map(apiEsport => apiToPlatform(apiEsport.attributes.name));

    return {
      id: apiData.id,
      type: "club",
      name: orgAttributes.name,
      logoUrl: orgAttributes.logoUrl,
      websiteUrl: orgAttributes.websiteUrl,
      facebookUrl: orgAttributes.facebookUrl,
      instagramUrl: orgAttributes.instagramUrl,
      twitterUrl: orgAttributes.twitterUrl,
      leagues: [],
      seasonPassesAcceptingRegistration,
      availableEsports,
      orgId: orgData.id,
    };
  }
}
