<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
></app-modal-container>

<ng-template #title>
  <app-modal-title (closeEvent)="close()"
    >What's a Single-Player team?</app-modal-title
  >
</ng-template>

<ng-template #content>
  <p>
    Single-Player teams are a special team used for joining all GGLeagues
    single-player events.
  </p>
  <p>
    Each user has an Single-Player team created for them automatically when they
    create an account on GGLeagues. This team will always be used when you
    register for a 1v1 or Solo league.
  </p>

  <p>
    Single-Player teams are unique to each player and cannot be deleted,
    removed, or have the roster edited.
  </p>
</ng-template>
