import { Component, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import { generateLeagueDetailRoute, generateSeriesMatchupRoute } from "src/app/enums/routes/routePaths";
import { RootState } from "src/app/reducers";
import { DashboardNextMatch } from "src/app/reducers/dashboard";
import { differenceFromNow } from "src/app/util/date-utils";
import { showNextGameImage } from "src/app/util/image-utils";
import { modalOptions } from "src/app/util/modal-helpers";
import { Unsubscriber } from "src/app/util/unsubscriber";
import { DashOverdueInfoModalComponent } from "../dash-overdue-info-modal/dash-overdue-info-modal.component";

export enum NextGameStatus {
  NORMAL = "normal",
  IN_PROGRESS = "inprogress",
  OVERDUE = "overdue",
}

@Component({
  selector: "app-dash-next-game",
  templateUrl: "./dash-next-game.component.html",
  styleUrls: ["./dash-next-game.component.scss"],
})
export class DashNextGameComponent implements OnDestroy {
  public nextMatch$: Observable<DashboardNextMatch>;

  public gameStatuses = NextGameStatus;

  private _unsub = new Unsubscriber();

  constructor(private _store: Store<RootState>, private _modalService: NgxSmartModalService) {
    this.nextMatch$ = this._store.select("dashboard", "nextMatch").pipe(takeUntil(this._unsub.unsubEvent));
  }

  public getGameStatus(matchTime: string): NextGameStatus {
    const timeDiffMin = differenceFromNow(matchTime, "min");

    const IN_PROGRESS_LOWER = 0;
    const IN_PROGRESS_UPPER = -60;

    if (timeDiffMin < IN_PROGRESS_LOWER) {
      if (timeDiffMin >= IN_PROGRESS_UPPER) {
        return NextGameStatus.IN_PROGRESS;
      }

      return NextGameStatus.OVERDUE;
    }

    return NextGameStatus.NORMAL;
  }

  public generateMatchupRoute(matchID: string): string {
    return `/${generateSeriesMatchupRoute(matchID)}`;
  }

  public generateQueueRoute(leagueId: string): string {
    return `/${this.generateQueueRoute(leagueId)}`;
  }

  public generateLeagueRoute(leagueId: string): string {
    return `/${generateLeagueDetailRoute(leagueId)}`;
  }

  public openOverdueModal(): void {
    this._modalService.create(DashOverdueInfoModalComponent.modalId, DashOverdueInfoModalComponent, modalOptions).open();
  }

  public showEsportImage(esport: gamePlatforms): string {
    return `background-image: ${showNextGameImage(esport, true)}`;
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }
}
