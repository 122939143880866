<ng-container *ngIf="hasSponsorData$ | async">
  <div class="c_sponsorAssetsContainer">
    <div class="c_sponsorAssets__titleAsset">
      <ng-container *ngIf="titleSponsorAsset$ | async">
        <p>This league sponsored by</p>
        <img [src]="titleSponsorAsset$ | async" />
      </ng-container>
    </div>
    <div
      class="c_sponsorAssets__secondaryAsset c_sponsorAssets__secondaryAsset--one"
    >
      <ng-container *ngIf="secondaryAssetOne$ | async">
        <img [src]="secondaryAssetOne$ | async" />
      </ng-container>
    </div>
    <div
      class="c_sponsorAssets__secondaryAsset c_sponsorAssets__secondaryAsset--two"
    >
      <ng-container *ngIf="secondaryAssetTwo$ | async">
        <img [src]="secondaryAssetTwo$ | async" />
      </ng-container>
    </div>
    <div
      class="c_sponsorAssets__secondaryAsset c_sponsorAssets__secondaryAsset--three"
    >
      <ng-container *ngIf="secondaryAssetThree$ | async">
        <img [src]="secondaryAssetThree$ | async" />
      </ng-container>
    </div>
  </div>
</ng-container>
