import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

import { routePaths } from "src/app/enums/routes/routePaths";

@Component({
  selector: "app-new-user-page",
  templateUrl: "./new-user-page.component.html",
  styleUrls: ["./new-user-page.component.scss"],
})
export class NewUserPageComponent implements OnInit {
  public routes = routePaths;

  constructor(private _titleService: Title) {}

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | New User Guide");
  }
}
