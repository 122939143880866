import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CustomerSupportService {
  public openCustomerSupport(): void {
    //This method is for the zendesk widget exposed by vendor scripts
    //SEE: https://developer.zendesk.com/api-reference/widget/core/#open
    // eslint-disable-next-line @typescript-eslint/dot-notation
    window["zE"]("webWidget", "open");
  }
}
