<app-lobby-help-modal-frame
  modalTitle="Joining a Rocket League Private Tournament"
>
  <p class="u_txt">
    To join the GGLeagues Rocket League Private Tournament follow these steps:
  </p>
  <h3>Step 1: Get the Private Tournament Name & Code:</h3>
  <ol>
    <li>
      Wait for the current game to begin <strong>Accepting Check-Ins</strong>.
      Check-ins open usually 10 minutes before the series begins, and will stay
      open for 10 minutes for each game
    </li>
    <li>
      Follow the steps to check-in to the current game. You will need to provide
      your Rocket League ID if you haven't done so yet. This allows us to match
      the winner with their GGLeagues account at the end of the match
    </li>
    <li>
      After checking in, the tournament info will change and display the
      credentials for the game
    </li>
  </ol>
  <h3>
    <a
      href="https://support.rocketleague.com/hc/en-us/articles/360015578213-Custom-Tournaments"
      target="_blank"
      rel="noopener"
    >
      Step 2: Join the Private Tournament in Rocket League
      <i class="fas fa-external-link-alt"></i>
    </a>
  </h3>
  <ol>
    <li>Select Join Private</li>
    <li>
      Join a private Tournament by entering a Name and Password (as set by
      GGLeagues)
    </li>
  </ol>
</app-lobby-help-modal-frame>
