import { Component } from "@angular/core";

@Component({
  selector: "app-rocket-league-tournament-help-modal-component",
  templateUrl: "./rocket-league-tournament-help-modal-component.component.html",
  styleUrls: ["./rocket-league-tournament-help-modal-component.component.scss"],
})
export class RocketLeagueTournamentHelpModalComponentComponent {

}
