import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";

import { ComponentRouteParams } from "src/app/enums/routes/routeParams";
import { routePaths } from "src/app/enums/routes/routePaths";
import { RULEBOOK_TYPES } from "src/app/enums/rulebooks.enum";

enum RULEBOOK_URIS {
  MADDEN = "https://content.ggleagues.com/public/rulebooks/rulebook_rec_madden.pdf",
  FORTNITE = "https://content.ggleagues.com/public/rulebooks/rulebook_rec_fortnitebr.pdf",
  FORTNITE_ELIM = "https://content.ggleagues.com/public/rulebooks/rulebook_rec_fortnitebr_elimination.pdf",
  ROCKET_LEAGUE = "https://content.ggleagues.com/public/rulebooks/rulebook_rec_rocketleague.pdf",
  SMASH_BROS = "https://content.ggleagues.com/public/rulebooks/rulebook_rec_ssbu.pdf",
  LOL_ELITE = "https://content.ggleagues.com/public/rulebooks/rulebook_elite_lol_v1-1.pdf",
  OW_ELITE = "https://content.ggleagues.com/public/rulebooks/rulebook_elite_overwatch.pdf",
  RL_ELITE = "https://content.ggleagues.com/public/rulebooks/rulebook_elite_rocketleague.pdf",
  VALORANT_ELITE = "https://content.ggleagues.com/public/rulebooks/rulebook_elite_valorant.pdf",
  MARIOKART = "https://content.ggleagues.com/public/rulebooks/rulebook_rec_mariokart.pdf",
  SMASH_BROS_WEEKLY = "https://content.ggleagues.com/public/rulebooks/rulebook_weekly_ssbu.pdf",
  RL_WEEKLY = "https://content.ggleagues.com/public/rulebooks/rulebook_weekly_rocketleague.pdf",
  MARIOKART_WEEKLY = "https://content.ggleagues.com/public/rulebooks/rulebook_weekly_mariokart.pdf",
  FORTNITE_ELIM_WEEKLY = "https://content.ggleagues.com/public/rulebooks/rulebook_weekly_fortnitebr_elim.pdf",
  FORTNITE_BR_WEEKLY = "https://content.ggleagues.com/public/rulebooks/rulebook_weekly_fortnitebr.pdf",
  NBA_2K_WEEKLY = "https://content.ggleagues.com/public/rulebooks/rulebook_weekly_nba2k.pdf",
}

@Component({
  selector: "app-rules-pdf-page",
  templateUrl: "./rules-pdf-page.component.html",
  styleUrls: ["./rules-pdf-page.component.scss"],
})
export class RulesPdfPageComponent implements OnInit {
  public rulebookPath: SafeResourceUrl | null = null;
  public rulebookTitle = "";
  public rulesRoute = `/${routePaths.RULES}`;
  public hasRules = true;

  constructor(private _sanitizer: DomSanitizer, private _activeRoute: ActivatedRoute) { }

  public ngOnInit(): void {
    const rulebookType: RULEBOOK_TYPES = this._activeRoute.snapshot.paramMap.get(ComponentRouteParams.RULEBOOK_ID) as RULEBOOK_TYPES;
    switch (rulebookType) {
      case RULEBOOK_TYPES.MADDEN:
        this.rulebookTitle = "Madden Rec Rulebook";
        this.rulebookPath = this._sanitizer.bypassSecurityTrustResourceUrl(RULEBOOK_URIS.MADDEN);
        break;
      case RULEBOOK_TYPES.FORTNITE_BR:
        this.rulebookTitle = "Fortnite Battle Royale Rec Rulebook";
        this.rulebookPath = this._sanitizer.bypassSecurityTrustResourceUrl(RULEBOOK_URIS.FORTNITE);
        break;
      case RULEBOOK_TYPES.FORTNITE_BR_ELIM:
        this.rulebookTitle = "Fortnite Elimination Race Rec Rulebook";
        this.rulebookPath = this._sanitizer.bypassSecurityTrustResourceUrl(RULEBOOK_URIS.FORTNITE_ELIM);
        break;
      case RULEBOOK_TYPES.ROCKET_LEAGUE:
        this.rulebookTitle = "Rocket League Rec Rulebook";
        this.rulebookPath = this._sanitizer.bypassSecurityTrustResourceUrl(RULEBOOK_URIS.ROCKET_LEAGUE);
        break;
      case RULEBOOK_TYPES.SSBU:
        this.rulebookTitle = "Super Smash Bros. Ultimate Rec Rulebook";
        this.rulebookPath = this._sanitizer.bypassSecurityTrustResourceUrl(RULEBOOK_URIS.SMASH_BROS);
        break;
      case RULEBOOK_TYPES.LOL_ELITE:
        this.rulebookTitle = "League of Legends Elite Rulebook";
        this.rulebookPath = this._sanitizer.bypassSecurityTrustResourceUrl(RULEBOOK_URIS.LOL_ELITE);
        break;
      case RULEBOOK_TYPES.OVERWATCH_ELITE:
        this.rulebookTitle = "Overwatch Rulebook";
        this.rulebookPath = this._sanitizer.bypassSecurityTrustResourceUrl(RULEBOOK_URIS.OW_ELITE);
        break;
      case RULEBOOK_TYPES.ROCKET_LEAGUE_ELITE:
        this.rulebookTitle = "Rocket League Elite Rulebook";
        this.rulebookPath = this._sanitizer.bypassSecurityTrustResourceUrl(RULEBOOK_URIS.RL_ELITE);
        break;
      case RULEBOOK_TYPES.VALORANT_ELITE:
        this.rulebookTitle = "Valorant Elite Rulebook";
        this.rulebookPath = this._sanitizer.bypassSecurityTrustResourceUrl(RULEBOOK_URIS.VALORANT_ELITE);
        break;
      case RULEBOOK_TYPES.MARIOKART:
        this.rulebookTitle = "Mario Kart Rec Rulebook";
        this.rulebookPath = this._sanitizer.bypassSecurityTrustResourceUrl(RULEBOOK_URIS.MARIOKART);
        break;
      case RULEBOOK_TYPES.SSBU_WEEKLY:
        this.rulebookTitle = "Super Smash Bros. Ultimate Weekly Rulebook";
        this.rulebookPath = this._sanitizer.bypassSecurityTrustResourceUrl(RULEBOOK_URIS.SMASH_BROS_WEEKLY);
        break;
      case RULEBOOK_TYPES.ROCKET_LEAGUE_WEEKLY:
        this.rulebookTitle = "Rocket League Weekly Rulebook";
        this.rulebookPath = this._sanitizer.bypassSecurityTrustResourceUrl(RULEBOOK_URIS.RL_WEEKLY);
        break;
      case RULEBOOK_TYPES.MARIOKART_WEEKLY:
        this.rulebookTitle = "Mario Kart Weekly Rulebook";
        this.rulebookPath = this._sanitizer.bypassSecurityTrustResourceUrl(RULEBOOK_URIS.MARIOKART_WEEKLY);
        break;
      case RULEBOOK_TYPES.FORTNITE_BR_ELIM_WEEKLY:
        this.rulebookTitle = "Fortnite Elimination Race Weekly Rulebook";
        this.rulebookPath = this._sanitizer.bypassSecurityTrustResourceUrl(RULEBOOK_URIS.FORTNITE_ELIM_WEEKLY);
        break;
      case RULEBOOK_TYPES.FORTNITE_BR_WEEKLY:
        this.rulebookTitle = "Fortnite Battle Royale Weekly Rulebook";
        this.rulebookPath = this._sanitizer.bypassSecurityTrustResourceUrl(RULEBOOK_URIS.FORTNITE_BR_WEEKLY);
        break;
      case RULEBOOK_TYPES.NBA_2K_WEEKLY:
        this.rulebookTitle = "NBA 2K Weekly Rulebook";
        this.rulebookPath = this._sanitizer.bypassSecurityTrustResourceUrl(RULEBOOK_URIS.NBA_2K_WEEKLY);
        break;
      default:
        this.hasRules = false;
        break;
    }
  }
}
