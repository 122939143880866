import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { modalOptions } from "@utils/modal-helpers";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Observable } from "rxjs";
import {
  filter,
  map,
  take,
} from "rxjs/operators";

import { gameCredentialTitles, gamePlatforms } from "src/app/enums/game-platforms.enum";
import {
  generateTeamDetailRoute,
  generateUserDetailRoute,
  routePaths,
} from "src/app/enums/routes/routePaths";
import { RootState } from "src/app/reducers";
import {
  ScrimmageTeam,
  SeriesMatchupV2Player,
  SeriesMatchupV2Team,
} from "src/app/reducers/matches/matches.types";
import { SeriesMatchupNoCredInfoModalComponent } from "../series-matchup-no-cred-info-modal/series-matchup-no-cred-info-modal.component";

@Component({
  selector: "app-matchup-roster-tables",
  templateUrl: "./matchup-roster-tables.component.html",
  styleUrls: ["./matchup-roster-tables.component.scss"],
})
export class MatchupRosterTablesComponent {
  @Input() public teams: SeriesMatchupV2Team[] | ScrimmageTeam[];
  @Input() public esport: gamePlatforms;

  public userId$: Observable<number>;
  public profileEditRoute = `/${routePaths.PROFILE_EDIT}`;

  constructor(private _store: Store<RootState>, private _modal: NgxSmartModalService) {
    this.userId$ = this._store.select("user", "currentUser").pipe(
      filter((currentUser) => currentUser !== null),
      take(1),
      map((currentUser) => currentUser.id)
    );
  }

  public getTeamDetailRoute(id: string | number): string[] {
    return id ? [`/${generateTeamDetailRoute(id.toString())}`] : [];
  }

  public getUserDetailRoute(id: string | number): string[] {
    return id ? [`/${generateUserDetailRoute(id.toString())}`] : [];
  }

  public get esportTagTitle(): string {
    return gameCredentialTitles.get(this.esport);
  }

  public showUserCredentialPrompt(userId: number, playerId: string): boolean {
    return userId.toString() === playerId;
  }

  public sortedPlayers(teamMembers: SeriesMatchupV2Player[]): SeriesMatchupV2Player[] {
    if (teamMembers) {
      const sortedList = [...teamMembers];
      sortedList.sort((memberA, memberB) => {
        if (memberA.isCaptain) {
          return -1;
        } else if (memberB.isCaptain) {
          return 1;
        }

        if (memberA.username.toLowerCase() < memberB.username.toLowerCase()) {
          return -1;
        } else if (memberA.username.toLowerCase() > memberB.username.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      });
      return sortedList;
    }

    return [];
  }

  public openNoCredInfoModal(): void {
    this._modal.create(
      SeriesMatchupNoCredInfoModalComponent.modalId,
      SeriesMatchupNoCredInfoModalComponent,
      modalOptions
    ).open();
  }
}
