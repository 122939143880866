import {
  Component,
  Input,
  OnChanges,
  OnInit,
} from "@angular/core";
import { BattleRoyaleStreamGame } from "@apptypes/streaming-series.types";
import { Store } from "@ngrx/store";
import { modalOptions } from "@utils/modal-helpers";
import { NgxSmartModalService } from "ngx-smart-modal";
import {
  map,
  pluck,
  switchMap,
  take,
  tap,
} from "rxjs/operators";
import { BattleRoyaleGameStatus, BattleRoyaleGameStatusLabel } from "src/app/enums/battle-royale-game-status.enum";
import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import { RootState } from "src/app/reducers";
import { UserTeam } from "src/app/reducers/user/user.types";
import { BattleRoyaleCheckinModalComponent, BRCheckinInfo } from "../battle-royale-checkin-modal/battle-royale-checkin-modal.component";
import { BattleRoyaleNeedHelpModalComponent } from "../battle-royale-need-help-modal/battle-royale-need-help-modal.component";
// eslint-disable-next-line import-newlines/enforce
import {
  BattleRoyaleResultModalData,
  BattleRoyaleResultsModalComponent,
} from "../battle-royale-results-modal/battle-royale-results-modal.component";

@Component({
  selector: "app-battle-royale-lobby-info",
  templateUrl: "./battle-royale-lobby-info.component.html",
  styleUrls: ["./battle-royale-lobby-info.component.scss"],
})
export class BattleRoyaleLobbyInfoComponent implements OnInit, OnChanges {
  @Input() public brGamesSource: BattleRoyaleStreamGame[];
  @Input() public seriesId: string;
  @Input() public esport: gamePlatforms;

  public activeGame: BattleRoyaleStreamGame | null = null;
  public playerTeam: UserTeam;
  public userId: number;
  public seriesTeams: [id: string, title: string][] = [];

  constructor(
    private _modalService: NgxSmartModalService,
    private _store: Store<RootState>
  ) { }

  public openDialogue(checkingameId: number) {
    const gameInfo: BRCheckinInfo = {
      gameId: checkingameId,
      teamId: this.playerTeam.id.toString(),
      seriesId: this.seriesId,
      esport: this.esport,
      type: "seriesMatchup",
    };
    this._modalService.create(
      BattleRoyaleCheckinModalComponent.MODAL_NAME,
      BattleRoyaleCheckinModalComponent,
      modalOptions
    ).setData(gameInfo).open();
  }

  public ngOnInit(): void {
    this._setActiveGame();
    this._store.select("matches", "seriesMatchup").pipe(
      take(1),
      pluck("teams"),
      tap((teamsList) => {
        this.seriesTeams = teamsList.map(({ id, title }) =>
          [id, title]
        );
      }),
      switchMap((seriesTeams) => this._store.select("user", "currentUser").pipe(
        take(1),
        tap((currentUser) => {
          this.userId = currentUser.id;
        }),
        pluck("teams"),
        map((userTeams) => {
          const idSeriesTeams = seriesTeams.map((seriesTeam) => seriesTeam.id);
          const matchedTeam = userTeams.find((userTeam) => idSeriesTeams.indexOf(`${userTeam.id}`) !== -1);
          return matchedTeam;
        })
      ))
    ).subscribe(
      (matchedTeam) => {
        this.playerTeam = matchedTeam;
      }
    );
  }

  public ngOnChanges() {
    this._setActiveGame();
  }

  public getStatusDisplayLabel(status: BattleRoyaleGameStatus): string {
    return BattleRoyaleGameStatusLabel[status];
  }

  public isCheckinDisabled(status: BattleRoyaleGameStatus): boolean {
    if (status === BattleRoyaleGameStatus.ACCEPTING_CHECKINS) {
      return false;
    }
    return true;
  }

  public getCheckinDisabledMessage(status: BattleRoyaleGameStatus): string {
    if (status === BattleRoyaleGameStatus.IN_PROGRESS) {
      return "The game is started and users can no longer check-in";
    }

    if (status === BattleRoyaleGameStatus.COMPLETE) {
      return "The game is has been played to completion";
    }

    if (status === BattleRoyaleGameStatus.CANCELLED) {
      return "The game has been cancelled";
    }

    if (status === BattleRoyaleGameStatus.PENDING) {
      if (this.activeGameIndex === 1) {
        return "Check-ins do not open for a series until 10 minutes before series start";
      } else {
        return "The admin has not opened up check-in for this game yet.  It should be available soon";
      }
    }
  }

  public get showGamesList(): boolean {
    return this.esport === gamePlatforms.FORTNITE;
  }

  public get showExtendedLobbyInfo(): boolean {
    return this.esport === gamePlatforms.ROCKET_LEAGUE;
  }

  public get userIsCheckedIn(): boolean {
    const matchedGame = this.activeGame;

    if (matchedGame) {
      const userIdMatch = matchedGame.checked_in_teams.find((team) => team.user_id === this.userId);
      if (userIdMatch) {
        return true;
      }
    }

    return false;
  }

  public get activeGameIndex(): number {
    const completeGamesCount = this.brGamesSource.filter((game) => game.status === BattleRoyaleGameStatus.COMPLETE).length;
    return (completeGamesCount >= this.brGamesSource.length) ? this.brGamesSource.length : completeGamesCount + 1;
  }

  public get finishedGames(): BattleRoyaleStreamGame[] {
    if (this.brGamesSource) {
      const unsortedFinishedGames = this.brGamesSource.filter((brGame) => {
        if ((brGame.status === BattleRoyaleGameStatus.COMPLETE) || (brGame.status === BattleRoyaleGameStatus.CANCELLED)) {
          return true;
        }

        return false;
      });
      return [...unsortedFinishedGames].sort((a, b) => {
        const matchupTimeA = a.match_start_time || "";
        const matchupTimeB = b.match_start_time || "";

        if (matchupTimeA > matchupTimeB) {
          return 1;
        } else {
          return -1;
        }
      });
    }

    return [];
  }

  public openGameResults(game: BattleRoyaleStreamGame, index: number): void {
    const modalData: BattleRoyaleResultModalData = {
      gameIndex: index + 1,
      gameInfo: game,
      teamInfo: [...this.seriesTeams],
    };
    this._modalService
      .create(BattleRoyaleResultsModalComponent.MODAL_NAME, BattleRoyaleResultsModalComponent, modalOptions)
      .setData(modalData)
      .open();
  }

  public openIssuesModal(): void {
    this._modalService
      .create(BattleRoyaleNeedHelpModalComponent.MODAL_NAME, BattleRoyaleNeedHelpModalComponent, modalOptions)
      .setData(this.esport)
      .open();
  }

  private _setActiveGame(): void {
    if (this.brGamesSource) {
      const activeGameMatch = this.brGamesSource.find(
        (game) => {
          const { status } = game;
          if (status === BattleRoyaleGameStatus.IN_PROGRESS) {
            return true;
          } else if (status === BattleRoyaleGameStatus.ACCEPTING_CHECKINS) {
            return true;
          }

          return false;
        }

      );


      if (activeGameMatch) {
        this.activeGame = activeGameMatch;
      } else {
        this.activeGame = this.brGamesSource.filter((game) => game.status === BattleRoyaleGameStatus.PENDING)[0];
      }
    }
  }
}
