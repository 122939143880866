import {
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { take } from "rxjs/operators";

import {
  GenericLeagueDetails,
  GenericLeagueMatch,
  GenericLeagueTeam,
  LeagueBracketDetails,
  LeagueGame,
  LeagueSeriesMatchup,
  LeagueTeam,
} from "src/app/reducers/leagues/league.types";
import { RootState } from "src/app/reducers";

@Component({
  selector: "app-matches-panel",
  templateUrl: "./matches-panel.component.html",
  styleUrls: ["./matches-panel.component.scss"],
})
export class MatchesPanelComponent implements OnInit {
  @Input() public bracket: LeagueBracketDetails | null = null;
  public upcomingMatches: GenericLeagueMatch[] = [];
  public recentMatches: GenericLeagueMatch[] = [];
  public league$: Observable<GenericLeagueDetails>;

  constructor(private _store: Store<RootState>) {}

  public ngOnInit() {
    this._store
      .select("leagues", "league")
      .pipe(take(1))
      .subscribe((league) => {
        this._mapMatches(league);
      });
  }

  private _mapMatches(league: GenericLeagueDetails): void {
    if (league && this.bracket) {
      const isSeries = league.series.length > 0;
      const gamesToMap: LeagueSeriesMatchup[] | LeagueGame[] = isSeries ? league.series : league.games;
      gamesToMap.forEach((game: LeagueSeriesMatchup | LeagueGame) => {
        const teamsInMatch: LeagueTeam[] = [];
        game.teams.forEach(team => {
          const foundLeagueTeam: LeagueTeam | undefined = league.teams.find((({ id }) => team.id === `${id}`));
          if(!!foundLeagueTeam){
            teamsInMatch.push(foundLeagueTeam);
          }
        });
        if (teamsInMatch.length === 0) {
          return;
        }

        const isMatchInBracket = isSeries ?
          this._doesSeriesMatchupExistInBracket(game as LeagueSeriesMatchup, this.bracket) :
          this._doesMatchExistInBracket(game as LeagueGame, this.bracket);

        if(isMatchInBracket){
          const match = this._buildGenericLeagueMatch(game, teamsInMatch, isSeries);
          this._pushMatch(match);
        }
      });
      this._sortMatches();
    }
  }

  private _sortMatches(): void {
    this.recentMatches.sort((a, b) => {
      if (+a.date > +b.date) {
        return -1;
      } else if (+a.date < +b.date) {
        return 1;
      } else {
        return 0;
      }
    });
    this.upcomingMatches.sort((a, b) => {
      if (+a.date > +b.date) {
        return 1;
      } else if (+a.date < +b.date) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  private _pushMatch(match: GenericLeagueMatch): number {
    const matchHasWinner = match.teams.map(team => team.name).includes(match.winner) && !!match.winner;
    return matchHasWinner ?
      this.recentMatches.push(match) :
      this.upcomingMatches.push(match);
  }

  private _doesSeriesMatchupExistInBracket(series: LeagueSeriesMatchup, bracket: LeagueBracketDetails): boolean {
    return series.tournamentId.toString() === bracket.id;
  }

  private _doesMatchExistInBracket(game: LeagueGame, bracket: LeagueBracketDetails): boolean {
    return !!bracket.matchupMapping.find((bracketMatch) => bracketMatch.matchIds.find(matchId => matchId.toString() === game.id));
  }

  private _buildGenericLeagueMatch(
    game: LeagueGame | LeagueSeriesMatchup,
    teams: LeagueTeam[],
    isSeries: boolean
  ): GenericLeagueMatch {
    const mapLeagueTeamToGenericLeagueTeam = (leagueTeam: LeagueTeam): GenericLeagueTeam => ({
      id: leagueTeam.id.toString(),
      name: leagueTeam.title,
      wins: leagueTeam.wins,
      losses: leagueTeam.losses,
      image: leagueTeam.logoUrl,
    });

    const mappedTeams = teams.map(mapLeagueTeamToGenericLeagueTeam);

    return {
      id: game.id.toString(),
      date: game.gameStartTime,
      winner: game.winner,
      teams: mappedTeams,
      isSeries,
    };
  }
}
