import { Component, Input } from "@angular/core";

@Component({
  selector: "app-static-page",
  templateUrl: "./static-page.component.html",
  styleUrls: ["./static-page.component.scss"],
})
export class StaticPageComponent {
  @Input() public pageTitle: string;
}
