<footer class="o_layout__footer c_footer">
  <div class="c_footer__inner">
    <div class="c_footer__icon">
      <a [routerLink]="staticTemplateRoutes.HOME">
        <app-logo-svg-white
          [hasBackgroundColor]="false"
          [isCircle]="false"
          [useHoverColorShift]="true"
        >
        </app-logo-svg-white>
      </a>
    </div>
    <ul class="c_footer__linkContainer">
      <li>
        <a class="c_footer__link" [routerLink]="staticTemplateRoutes.ABOUT"
          >About</a
        >
      </li>
      <li>
        <a class="c_footer__link" [routerLink]="staticTemplateRoutes.RULES"
          >Rules</a
        >
      </li>
      <li>
        <a
          class="c_footer__link"
          target="_blank"
          [routerLink]="staticTemplateRoutes.FAQ"
          >FAQ</a
        >
      </li>
      <li>
        <a class="c_footer__link" [routerLink]="staticTemplateRoutes.CONTACT"
          >Contact Us</a
        >
      </li>
      <li>
        <a
          rel="noopener"
          class="c_footer__link"
          [routerLink]="staticTemplateRoutes.WELCOME"
        >
          Registration 101
        </a>
      </li>
      <li>
        <a class="c_footer__link" [routerLink]="staticTemplateRoutes.PARENTS"
          >Info for Parents</a
        >
      </li>
      <li>
        <a class="c_footer__link" href="https://ggleagues.com/partners"
          >Partners</a
        >
      </li>
      <li>
        <a
          target="_blank"
          rel="noopener"
          class="c_footer__link"
          href="https://sectorsixapparel.com/collections/gg-leagues"
        >
          Shop
        </a>
      </li>
    </ul>
    <div class="c_footer__socials">
      <!-- TODO: https://webaim.org/techniques/css/invisiblecontent/  -->
      <ul class="c_footer__socialsSplit">
        <li>
          <a
            href="https://www.twitch.tv/ggleaguesofficial"
            target="_blank"
            rel="noopener"
            class="c_footer__socialLink"
          >
            <i
              class="fab fa-twitch"
              title="GGLeagues Twitch"
              aria-labelledby="title"
            ></i>
          </a>
        </li>
        <li>
          <a
            href="https://discord.gg/faATXf8"
            target="_blank"
            rel="noopener"
            class="c_footer__socialLink"
          >
            <i
              class="fab fa-discord"
              title="GGLeagues Discord"
              aria-labelledby="title"
            ></i>
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/channel/UCle0udEN66t5kfa5Oi3mE9g"
            target="_blank"
            rel="noopener"
            class="c_footer__socialLink"
          >
            <i
              class="fab fa-youtube"
              title="GGLeagues Youtube"
              aria-labelledby="title"
            ></i>
          </a>
        </li>
      </ul>
      <ul class="c_footer__socialsSplit">
        <li>
          <a
            href="https://twitter.com/ggleaguesmain"
            target="_blank"
            rel="noopener"
            class="c_footer__socialLink"
          >
            <i
              class="fab fa-twitter"
              title="GGLeagues Twitter"
              aria-labelledby="title"
            ></i>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/ggleaguesofficial/?hl=en"
            target="_blank"
            rel="noopener"
            class="c_footer__socialLink"
          >
            <i
              title="GGLeagues Instagram"
              aria-labelledby="title"
              class="fab fa-instagram"
            ></i>
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/ggleaguesofficial"
            target="_blank"
            rel="noopener"
            class="c_footer__socialLink"
          >
            <i
              class="fab fa-facebook"
              title="GGLeagues Facebook"
              aria-labelledby="title"
            ></i>
          </a>
        </li>
      </ul>
    </div>
    <div class="c_footer__privacy">
      <p class="u_txt--white">
        &copy; 2018 - {{ currentYear }}, GGLeagues, Inc.
      </p>
      <div class="c_footer__legalLinks">
        <p>
          <a target="_blank" routerLink="/privacy">Privacy Policy</a>
        </p>
        <p>
          <a target="_blank" [href]="staticTemplateRoutes.TERMS"
            >Terms of Use</a
          >
        </p>
      </div>
    </div>
  </div>
</footer>
