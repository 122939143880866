import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { UserAddress, UserAddressService } from "@services/v2/user-address/user-address.service";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs/operators";
import { StatesAbbreviations } from "src/app/enums/states.enum";
import { RootState } from "src/app/reducers";

interface ShippingFormValues {
  id?: string;
  firstName: string;
  lastName: string;
  addressOne: string;
  addressTwo: string;
  city: string;
  state: string;
  zipCode: string;
}

@Component({
  selector: "app-user-edit-address-block",
  templateUrl: "./user-edit-address-block.component.html",
  styleUrls: ["./user-edit-address-block.component.scss"],
})
export class UserEditAddressBlockComponent implements OnInit {
  public formError: null | string = null;
  public stateList = Object.keys(StatesAbbreviations);
  public statesAbbreviations = StatesAbbreviations;
  public isSaving = false;

  public shippingForm = this._fb.group({
    firstName: new FormControl("", [Validators.required]),
    lastName: new FormControl("", [Validators.required]),
    addressOne: new FormControl("", [Validators.required]),
    addressTwo: new FormControl(""),
    city: new FormControl("", [Validators.required]),
    state: new FormControl("", [Validators.required]),
    zipCode: new FormControl("", [Validators.required]),
  });

  private _userId: string | number;
  private _currentAddress: UserAddress | null = null;

  constructor(
    private _toastr: ToastrService,
    private _store: Store<RootState>,
    private _userAddressService: UserAddressService,
    private _fb: FormBuilder
  ) { }

  public ngOnInit(): void {
    this._store.select("user", "currentUser").pipe(
      first()
    ).subscribe(
      async (currentUser) => {
        this._userId = currentUser.id;
        this._currentAddress = await this._userAddressService.getUserAddress(this._userId).toPromise();
        if (this._currentAddress) {
          this.shippingForm.setValue({
            firstName: this._currentAddress.first_name,
            lastName: this._currentAddress.last_name,
            addressOne: this._currentAddress.address_first,
            addressTwo: this._currentAddress.address_second,
            city: this._currentAddress.city,
            state: this._currentAddress.state,
            zipCode: this._currentAddress.zip_code,
          });
        }
      }
    );
  }

  public updateAddress(): void {
    this.formError = null;
    this.isSaving = true;

    if (!this.shippingForm.valid) {
      this.shippingForm.markAllAsTouched();
      this.formError = "Please correct the form errors to continue";
      this.isSaving = false;
      return;
    }

    const userFormAddress = this.shippingForm.value as ShippingFormValues;
    const formattedUserAddress: UserAddress = {
      address_first: userFormAddress.addressOne,
      address_second: userFormAddress.addressTwo || undefined,
      address_type: "shipping",
      city: userFormAddress.city,
      country: "USA",
      first_name: userFormAddress.firstName,
      last_name: userFormAddress.lastName,
      state: userFormAddress.state,
      email: "",
      zip_code: userFormAddress.zipCode,
    };

    if (this._currentAddress) {
      formattedUserAddress.id = this._currentAddress.id;
    }

    this._userAddressService.updateUserAddress(this._userId, formattedUserAddress).subscribe(
      // SUCCESS
      () => {
        this._toastr.success("Address Updated");
        this.isSaving = false;

      },
      // ERROR
      () => {
        this.formError = "Error saving your address, please try again.  If the issue persists, reach out to support";
        this.isSaving = false;
      }
    );
  }
}
