import { Component, Input } from "@angular/core";
import { generateOrganizationSlugRoute } from "src/app/enums/routes/routePaths";
import { UserEsportsPass } from "src/app/reducers/user/user.types";

@Component({
  selector: "app-profile-passes-panel",
  templateUrl: "./profile-passes-panel.component.html",
  styleUrls: ["./profile-passes-panel.component.scss"],
})
export class ProfilePassesPanelComponent {
  @Input() public myPasses: UserEsportsPass[] = [];

  public get userHasPasses(): boolean {
    if (this.myPasses && this.myPasses.length > 0) {
      return true;
    }

    return false;
  }

  public orgRouteFromId(orgId: string): string {
    return `/${generateOrganizationSlugRoute(orgId)}`;
  }
}
