import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";

import { environment } from "@environments/environment";

import { Notification } from "src/app/reducers/matches/matches.types";
import { basicAuthHeader } from "../util/auth-utils";

const BLACKLIST_USERIDS = [
  5182,
  1,
  5729,
];

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  constructor(private _firestore: AngularFirestore, private _http: HttpClient) {}

  public getUserNotifications(userId: number): Observable<Notification[]> {
    if (BLACKLIST_USERIDS.indexOf(userId) !== -1) {
      return of([]);
    }

    return this._firestore
      .collection<Notification>(environment.firebaseConfig.notificationCollection, (ref) =>
    // TODO: Update this to be any notification type in the future?
      ref.where("type", "==", "match_chatroom").where("userId", "==", userId)
    )
      .valueChanges({
        idField: "notificationId",
      });
  }

  public dismissNotifications(ids: string[]) {
    const url = `${environment.apiBase}/api/v1/firestore_integrations/notifications/dismiss`;
    const headers = basicAuthHeader();
    const payload = {
      notification: {
        ids,
      },
    };
    return this._http.post(url, payload, {
      headers,
    });
  }
}
