import {
  Component,
  Input,
  EventEmitter,
  Output,
} from "@angular/core";
import { pluck } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

import { PaymentService } from "@services/payment.service";

import { Logger } from "../../util/logger";
import { Payment } from "../../services/payment.service";
import { isoToShort } from "../../util/date-utils";

@Component({
  selector: "app-payment-block",
  templateUrl: "./payment-block.component.html",
  styleUrls: ["./payment-block.component.scss"],
  providers: [PaymentService],
})
export class PaymentBlockComponent {
  @Input() public payments: Payment[] = [];
  @Output() public updatePayments = new EventEmitter<void>();

  public startIndex = 0;
  public endIndex = 10;
  public range = 10;

  public rangeValues = [
    10,
    25,
    50,
    100,
  ];

  public sortTable = {
    date: {
      active: false,
      direction: "up",
    },
    league: {
      active: false,
      direction: "up",
    },
    amount: {
      active: false,
      direction: "up",
    },
  };

  constructor(
    private _paymentService: PaymentService,
    private _toastr: ToastrService
  ) {}

  public parseDate(date: string) {
    return isoToShort(date);
  }

  //TODO: Optimize this function, it's gross
  public sortPayments(header: string) {
    switch (header) {
      //NOTE: Incorrect sorting for now until the route is set up to get UNIX time
      //We sort based on UNIX time
      case "date":
        if (this.sortTable.date.active === false) {
          this.sortTable.date.active = true;

          //Set all others to false
          this.sortTable.amount.active = false;
          this.sortTable.league.active = false;

          //Do a sort on first click
          if (this.sortTable.date.direction === "up") {
            this.payments.sort((a, b) => {
              if (a.date > b.date) {
                return 1;
              } else if (a.date < b.date) {
                return -1;
              } else {
                return 0;
              }
            });
          } else {
            this.payments.sort((a, b) => {
              if (a.date > b.date) {
                return 1;
              } else if (a.date < b.date) {
                return -1;
              } else {
                return 0;
              }
            });
          }
        } else {
          if (this.sortTable.date.direction === "up") {
            this.sortTable.date.direction = "down";

            this.payments.sort((a, b) => {
              if (a.date > b.date) {
                return -1;
              } else if (a.date < b.date) {
                return 1;
              } else {
                return 0;
              }
            });
          } else {
            this.sortTable.date.direction = "up";

            this.payments.sort((a, b) => {
              if (a.date > b.date) {
                return -1;
              } else if (a.date < b.date) {
                return 1;
              } else {
                return 0;
              }
            });
          }
        }
        break;
      case "league":
        if (this.sortTable.league.active === false) {
          this.sortTable.league.active = true;

          //Set all others to false
          this.sortTable.amount.active = false;
          this.sortTable.date.active = false;

          //Do a sort on first click
          if (this.sortTable.league.direction === "up") {
            this.payments.sort((a, b) => {
              if (a.leagueName > b.leagueName) {
                return -1;
              } else if (a.leagueName < b.leagueName) {
                return 1;
              } else {
                return 0;
              }
            });
          } else {
            this.payments.sort((a, b) => {
              if (a.leagueName > b.leagueName) {
                return 1;
              } else if (a.leagueName < b.leagueName) {
                return -1;
              } else {
                return 0;
              }
            });
          }
        } else {
          if (this.sortTable.league.direction === "up") {
            this.sortTable.league.direction = "down";

            this.payments.sort((a, b) => {
              if (a.leagueName > b.leagueName) {
                return 1;
              } else if (a.leagueName < b.leagueName) {
                return -1;
              } else {
                return 0;
              }
            });
          } else {
            this.sortTable.league.direction = "up";

            this.payments.sort((a, b) => {
              if (a.leagueName > b.leagueName) {
                return -1;
              } else if (a.leagueName < b.leagueName) {
                return 1;
              } else {
                return 0;
              }
            });
          }
        }
        break;
      case "amount":
        if (this.sortTable.amount.active === false) {
          this.sortTable.amount.active = true;

          //Set all others to false
          this.sortTable.date.active = false;
          this.sortTable.league.active = false;

          //Do a sort on first click
          if (this.sortTable.amount.direction === "up") {
            this.payments.sort((a, b) => {
              if (a.fee > b.fee) {
                return -1;
              } else if (a.fee < b.fee) {
                return 1;
              } else {
                return 0;
              }
            });
          } else {
            this.payments.sort((a, b) => {
              if (a.fee > b.fee) {
                return 1;
              } else if (a.fee < b.fee) {
                return -1;
              } else {
                return 0;
              }
            });
          }
        } else {
          if (this.sortTable.amount.direction === "up") {
            this.sortTable.amount.direction = "down";

            this.payments.sort((a, b) => {
              if (a.fee > b.fee) {
                return 1;
              } else if (a.fee < b.fee) {
                return -1;
              } else {
                return 0;
              }
            });
          } else {
            this.sortTable.amount.direction = "up";

            this.payments.sort((a, b) => {
              if (a.fee > b.fee) {
                return -1;
              } else if (a.fee < b.fee) {
                return 1;
              } else {
                return 0;
              }
            });
          }
        }
        break;
    }
  }

  public openReceipt(id: string | number): void {
    this._paymentService
      .getIndividualPayment(id)
      .pipe(pluck("receiptUrl"))
      .subscribe(
        (receiptUrl) => {
          if (!receiptUrl) {
            this._toastr.warning(
              "There is no receipt data available for this payment yet. Check again later or reach out to admin@ggleagues.com",
              "No Receipt Available"
            );
          } else {
            window.open(receiptUrl, "_blank");
            this._toastr.success("Receipt Opened in New Tab");
          }
        },
        (err) => {
          Logger.error(err);
          this._toastr.error("Error getting receipt data");
        }
      );
  }

  public processSuccessfulPayment(): void {
    this.updatePayments.emit();
  }
}
