import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import {
  Observable,
  of,
  throwError,
} from "rxjs";
import {
  catchError,
  first,
  map,
  pluck,
  switchMap,
} from "rxjs/operators";

import { SeasonPass } from "@apptypes/season-pass/season-pass.types";
import { SeasonPassService } from "@services/v2/season-pass/season-pass.service";
import { Logger } from "@utils/logger";

import { ComponentRouteParams } from "src/app/enums/routes/routeParams";
import { RootState } from "src/app/reducers";
import { NgxSmartModalService } from "ngx-smart-modal";
import { LeaguePassActivationModalComponent } from "src/app/components/league-pass-activation-modal/league-pass-activation-modal.component";
import { generateOrganizationSlugRoute } from "src/app/enums/routes/routePaths";

@Component({
  selector: "app-pass-details-page",
  templateUrl: "./pass-details-page.component.html",
  styleUrls: ["./pass-details-page.component.scss"],
})
export class PassDetailsPageComponent implements OnInit {
  public userOwnsPass$: Observable<boolean>;
  public seasonPass$: Observable<SeasonPass>;
  public errorLoading = false;

  // Waiver Hardcodes
  public isTallmadge = false;
  public isHopewell = false;

  constructor(
    private _activeRoute: ActivatedRoute,
    private _store: Store<RootState>,
    private _seasonPassService: SeasonPassService,
    private _modalService: NgxSmartModalService
  ) {
    const seasonPassId$ = this._activeRoute.paramMap.pipe(
      map(paramMap => paramMap.get(ComponentRouteParams.ESPORT_PASS_ID)),
      // HOTFIX CH3810
      map((id) => {
        if (id === "216" || id === "253") {
          return "208";
        }
        return id;
      }),
      first()
    );

    this.seasonPass$ = this._getSeasonPass(seasonPassId$);

    this.seasonPass$.pipe(first()).subscribe(
      (passInfo) => {
        // CH4001 Tallmadge Waiver Hotfix
        if (passInfo.organizationId === "298") {
          this.isTallmadge = true;
        }
        // CH6157 Hopewell Waiver Hotfix
        if (passInfo.organizationId === "566") {
          this.isHopewell = true;
        }
      }
    );

    this.userOwnsPass$ = seasonPassId$.pipe(
      switchMap((currentPassId) => this._store.select("user", "currentUser").pipe(
        pluck("gamePasses"),
        map((userPasses = []) => {
          const passIds = userPasses.map((pass) => pass.seasonPassId);
          return passIds.includes(currentPassId);
        }),
        first()
      )),
      first()
    );
  }

  public ngOnInit(): void {
    LeaguePassActivationModalComponent.initPassActivationModal(
      this._modalService,
      this._activeRoute,
      this._seasonPassService,
      this._store
    );
  }

  public getOrganizationLink(orgId: string): string {
    return `/${generateOrganizationSlugRoute(orgId)}`;
  }

  private _getSeasonPass(seasonPassId$: Observable<string | null>): Observable<SeasonPass> {
    return seasonPassId$.pipe(
      switchMap(passId => {
        if (!passId) {
          throwError(new Error("Could not read the route"));
        }
        return this._seasonPassService.getSeasonPassById(passId);
      }),
      catchError(err => {
        this.errorLoading = true;
        Logger.error(err);
        return of(null);
      }),
      first()
    );
  }
}
