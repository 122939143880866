import { Component } from "@angular/core";

@Component({
  selector: "app-fortnite-battle-royale-help-modal",
  templateUrl: "./fortnite-battle-royale-help-modal.component.html",
  styleUrls: ["./fortnite-battle-royale-help-modal.component.scss"],
})
export class FortniteBattleRoyaleHelpModalComponent {


}
