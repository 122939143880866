import { Component, Input } from "@angular/core";

import { ISOString } from "@apptypes/date.types";

/**
 * Displays a shorthand of the date with the day of week and the number of the month. It accepts an ISOString
 * and an isActive property. The isActive property changes the background color from primary-light to primary-dark
 * when true.
 *
 * @exports
 * @class DateBoxComponent
 */
@Component({
  selector: "app-date-box",
  templateUrl: "./date-box.component.html",
  styleUrls: ["./date-box.component.scss"],
})
export class DateBoxComponent {
  @Input() public date: ISOString;
  @Input() public isActive = false;
  @Input() public isGrayedOut = false;
}
