import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";

import { LeaguesV2Service } from "@services/v2/leagues/leagues.v2.service";
import {
  compareISODatesAsc,
  compareISODatesDesc,
  isISODateInFuture,
  isISODateInPast,
} from "@utils/date-utils";
import { Logger } from "@utils/logger";
import { parseISO } from "date-fns";
import { Observable, of } from "rxjs";
import {
  catchError,
  first,
  switchMap,
} from "rxjs/operators";
import { months } from "src/app/enums/date.enum";
import { generateLeagueScheduleRoute } from "src/app/enums/routes/routePaths";

import { RootState } from "src/app/reducers";
import { LeagueSeriesMatchupV2 } from "src/app/reducers/leagues/league.types";

export interface YearMonthFilter {
  year: number;
  month: number; //0-11
}

@Component({
  selector: "app-league-matches-block",
  templateUrl: "./league-matches-block.component.html",
  styleUrls: ["./league-matches-block.component.scss"],
})
export class LeagueMatchesBlockComponent implements OnInit {
  public matches$: Observable<LeagueSeriesMatchupV2[] | null> = of(null);
  public sortedMatches: LeagueSeriesMatchupV2[] = [];
  public allMatches: LeagueSeriesMatchupV2[] = [];
  public today: Date = new Date();
  public yearMonthList: string[] = [];
  public selectedYearMonth: string;

  public scheduleLink: string;

  public isUpcoming = true;
  public isLoading = true;
  public hasError = false;

  private _leagueId: number;

  constructor(
    private _store: Store<RootState>,
    private _leagueV2Service: LeaguesV2Service
  ) { }

  public ngOnInit(): void {
    this._store.select("leagues", "league").pipe(
      switchMap(({ id }) => {
        this._leagueId = id;
        return this._leagueV2Service.getLeagueMatches(id);
      }),
      catchError(err => {
        this.hasError = true;
        Logger.error(err);
        return of([]);
      }),
      first()
    ).subscribe((matches) => {
      this.scheduleLink = `/${generateLeagueScheduleRoute(this._leagueId)}`;
      this.allMatches = matches;
      this.yearMonthList = this._generateMatchDateList(this.allMatches);
      this.selectedYearMonth = JSON.stringify(this._currentDateYearMonth);
      //Initially set matches to upcoming
      this.setUpcomingMatches();
      this.isLoading = false;
    });
  }

  public getYearMonthDisplay(yearMonthString: string): string {
    const { year, month }: YearMonthFilter = JSON.parse(yearMonthString);
    return `${months[month]} ${year}`;
  }

  public mapMonthToNominative(monthIndex: number): string {
    return months[monthIndex];
  }

  public setUpcomingMatches(): void {
    this.isUpcoming = true;
    this.sortedMatches = this.allMatches.filter(
      (match) => {
        const hasWinner = match.winner ? true : false;
        return isISODateInFuture(match.gameStartTime, -30) && !hasWinner;
      }
    ).sort(({ gameStartTime: dateA }, { gameStartTime: dateB }) => compareISODatesAsc(dateA, dateB));
  }

  public setPlayedMatches(): void {
    this.isUpcoming = false;
    this.sortedMatches = this.allMatches.filter(
      (match) => {
        const hasWinner = match.winner ? true : false;
        return isISODateInPast(match.gameStartTime, -30) || hasWinner;
      }
    ).sort(({ gameStartTime: dateA }, { gameStartTime: dateB }) => compareISODatesDesc(dateA, dateB));
  }

  public get dateFilteredMatchList(): LeagueSeriesMatchupV2[] {
    if (!this.isUpcoming) {
      return this.sortedMatches;
    }

    const { month: selectedMonth, year: selectedYear }: YearMonthFilter = JSON.parse(this.selectedYearMonth);

    return this.sortedMatches.filter((match) => {
      const { month, year } = this._getMatchYearMonth(match);
      return (month === selectedMonth) && (year === selectedYear);
    });
  }

  private _generateMatchDateList(matchList: LeagueSeriesMatchupV2[]): string[] {
    const rawMatchList = matchList.map((match) => {
      const yearMonth = this._getMatchYearMonth(match);
      return yearMonth;
    });

    // Get Current Date so there are no gaps in the list
    rawMatchList.push(this._currentDateYearMonth);

    const uniqueMonthList: YearMonthFilter[] = rawMatchList.filter((yearMonth) => !!yearMonth); //Try catch can return null

    const sortedUniqueMonthList = [...uniqueMonthList];
    return sortedUniqueMonthList.sort((a, b) => {
      if (a.year === b.year) {
        if (a.month < b.month) {
          return 1;
        }

        if (a.month > b.month) {
          return -1;
        }
      } else if (a.year > b.year) {
        return -1;
      } else if (a.year < b.year) {
        return 1;
      }

      return 0;
    }).map((yearMonth) => JSON.stringify(yearMonth)).filter((monthVal, i, self) => self.indexOf(monthVal) === i);
  }

  private get _currentDateYearMonth(): YearMonthFilter {
    const currentDate = parseISO(this.today.toISOString());
    return {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth(),
    };
  }

  private _getMatchYearMonth(match: LeagueSeriesMatchupV2): YearMonthFilter {
    try {
      const date = parseISO(match.gameStartTime);
      return {
        year: date.getFullYear(),
        month: date.getMonth(),
      };
    }
    catch (e) {
      Logger.error(e);
      return null;
    }
  }
}
