import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { zip, Observable } from "rxjs";
import {
  filter,
  map,
  mergeMap,
  pluck,
  take,
} from "rxjs/operators";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Router } from "@angular/router";

import { ChatroomService } from "@services/chatroom/chatroom.service";

import { RootState } from "src/app/reducers";
import { ModalController } from "../modal-controller/modal-controller.abstract";

@Component({
  selector: "app-contact-admin-modal",
  templateUrl: "./contact-admin-modal.component.html",
  styleUrls: ["./contact-admin-modal.component.scss"],
})
export class ContactAdminModalComponent extends ModalController<null> implements OnInit {
  public contactForm: FormGroup;

  private _userId$: Observable<string>;
  private _seriesMatchupId$: Observable<string>;

  constructor(
    private _chatService: ChatroomService,
    private _store: Store<RootState>,
    private _formBuilder: FormBuilder,
    private _modalService: NgxSmartModalService,
    private _router: Router
  ) {
    super(_modalService, "contactAdmin", _router);

    this.contactForm = this._formBuilder.group({
      contactMessage: [null, Validators.required],
    });
  }

  public ngOnInit(): void {
    this._userId$ = this._store.select("user", "currentUser").pipe(
      filter((user) => !!user),
      map((user) => user.id.toString()),
      take(1)
    );

    this._seriesMatchupId$ = this._store.select("matches", "seriesMatchup").pipe(
      filter((seriesMatchup) => !!seriesMatchup),
      pluck("id"),
      take(1)
    );
  }

  /**
   * Sends a post request to the server with the seriesMatchupId, userId, and the contact message
   * The chat service also handles the toast notifications
   *
   * @author Christian Tweed
   */
  public contactAdmin() {
    if (this.contactForm.valid) {
      const description = this.contactForm.get("contactMessage").value;
      zip(this._seriesMatchupId$, this._userId$)
        .pipe(
          mergeMap(([seriesMatchupId, userId]) => this._chatService.notifyAdmin(description, seriesMatchupId, userId)),
          take(1)
        )
        .subscribe(() => {
          this.close();
        });
    }
  }

  public get errorMessage(): string {
    return this.contactForm.invalid ? "Your description cannot be empty" : "";
  }
}
