import { GenericLeagueTeam, LeagueBracketStatus } from "../leagues/league.types";
import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import { Teams } from "src/app/enums/team.enum";
import { TeamTypes } from "../teams/teams.types";

import { ISOString } from "@apptypes/date.types";
import { APITournamentTypes } from "src/app/enums/tournamentTypes.enum";

export enum SeriesMatchupTypes {
  BASIC_SERIES_MATCHUP = "basicSeriesMatchup",
  SERIES_MATCHUP = "seriesMatchup",
}

export interface MatchState {
  seriesMatchup: SeriesMatchupV2;
  matchCommenters: MatchCommenterIDUsernameFlatMap;
  fetchingSeriesMatchupById: boolean;
  fetchingSeriesMatchupByIdError: boolean;
  fetchingMatchCommenters: boolean;
  fetchingMatchCommentersError: boolean;
}

export interface SeriesMatchup {
  id: string;
  type: string;
  winner: string;
  matches: {
    id: string;
    teamOneId: string;
    teamTwoId: string;
    winnerId: string;
  }[];
  tournament: {
    tournamentId: string;
    tournamentTitle: string;
  };
}

export interface Comment {
  id: number;
  comment: string;
  commenter: {
    inGameName: string;
    admin: boolean;
  };
  postedAt: number;
}

export interface MatchCommenter {
  id: number;
  inGameName: string;
}

export interface ChatMembers {
  chatMembers: MatchCommenter[];
  type: "chatMembers";
}

export enum NotificationResourceTypes {
  MATCH = "game",
  SERIES = "series_matchup",
}

export interface Notification {
  status: string;
  type: string;
  userId: number;
  matchId: string;
  matchTitle: string;
  messageFrom: number;
  notificationId: string;
  resourceType: NotificationResourceTypes;
  referenceId?: string;
  timestamp: {
    seconds: number;
    nanoseconds: number;
  };
}

export interface ChatMessage {
  isAdmin: boolean;
  isUser: boolean;
  message: string;
  date: number;
  userId: number;
  username: string;
  isHidden: boolean;
  team?: Teams;
}

export interface GenericSeriesMatchup {
  id: string;
  type: string;
  dateTimeStart: number;
  winnerId: string;
  seriesGameType: APITournamentTypes;
  league?: {
    id: string;
    type: string;
    esport: gamePlatforms;
    title: string;
  };
  tournament?: {
    id: string;
    type: string;
    title: string;
  };
  matches?: {
    id: string;
    type: string;
    teamOneId: string;
    teamTwoId: string;
    title: string;
    winnerId: string;
    lobby: string;
    lobbyPw: string;
  }[];
  teams?: GenericLeagueTeam[];
}

export enum MatchReportSubmissionType {
  FORFEIT = "forfeit",
  SUBMISSION = "submission",
}

export interface MatchReport {
  teams: MatchReportTeam[];
  games: MatchReportGame[];
  totalMatches: number;
  seriesMatchupsId: string;
  esport: gamePlatforms;
}

export interface MatchReportGame {
  gameId: string;
  winnerId: string;
  screenshot?: File;
}

export interface MatchReportTeam {
  id: string;
  title: string;
  logoUrl?: string;
}

export const NOT_PLAYED = "not_played";
export const WINNER_DECLARED = "winner_declared";
export type NOT_PLAYED = typeof NOT_PLAYED;
export type WINNER_DECLARED = typeof WINNER_DECLARED;

export interface SeriesMatchupLobbyInformation {
  lobby: string;
  lobbyPw: string;
  teamLeftLobby: string;
  teamRightLobby: string;
  winner?: string;
}

export interface SeriesMatchupV2 {
  id: string;
  type: SeriesMatchupTypes;
  esport: gamePlatforms;
  league: SeriesMatchupV2League;
  matches: SeriesMatchupV2Match[];
  matchupStartTime: ISOString;
  firebaseStreamId: string;
  teams: SeriesMatchupV2Team[];
  title: string;
  tournament: SeriesMatchupV2Tournament;
  winnerId: string;
  seriesMatchType?: APITournamentTypes;
  twitchStreamUrl: string | null;
  chatroomReferenceId: string;
}

export interface SeriesMatchupV2League {
  id: string;
  type: "league";
  title: string;
}

export interface SeriesMatchupV2Tournament {
  id: string;
  type: "tournament";
  title: string;
  schedulingType: APITournamentTypes;
  status: LeagueBracketStatus;
}

export interface SeriesMatchupV2Player {
  id: string;
  type: "user" | "users";
  esportCredential: string;
  username: string;
  isCaptain: boolean;
}

export interface SeriesMatchupV2Team {
  id: string;
  type: "team";
  logoUrl: string;
  players: SeriesMatchupV2Player[];
  teamType: TeamTypes;
  title: string;
  wins: number;
  losses: number;
}

export interface SeriesMatchupV2Match {
  id: string;
  type: "game";
  lobby: string;
  lobbyPw: string;
  teamOneId: string;
  teamTwoId: string;
  winnerId: string | null;
}

export interface Scrimmage {
  id: string;
  type: "scrimmage";
  esport: gamePlatforms;
  teams: ScrimmageTeam[];
  startTime: ISOString;
  winnerId: string;
  title: string;
}

export interface ScrimmageTeam {
  id: string;
  type: "scrimmageTeam";
  logoUrl: string;
  players: SeriesMatchupV2Player[];
  teamType: TeamTypes;
  title: string;
}

export type MatchCommenterIDUsernameMap = Map<string, string>;
export type MatchCommenterIDUsernameFlatMap = [string, string][];
