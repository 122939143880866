<div class="c_battleRoyaleMatchesPanel">
  <app-battle-royale-matches-preview [battleRoyaleMatches]="unplayedMatchups">
    Unplayed Matches
  </app-battle-royale-matches-preview>
  <app-battle-royale-matches-preview
    [battleRoyaleMatches]="recentlyPlayedMatchups"
  >
    Recently Played Matches
  </app-battle-royale-matches-preview>
</div>
