<button
  class="c_button c_esportHelpModalButton"
  [ngClass]="{
    secondary_light: isSecondary && !isDark,
    secondary_dark: isSecondary && isDark,
    primary_light: !isSecondary && !isDark,
    primary_dark: !isSecondary && isDark
  }"
  (click)="openModal()"
>
  <ng-container *ngIf="isBattleRoyaleMode">
    <div [ngSwitch]="esport">
      <span
        *ngSwitchCase="gamePlatforms.FORTNITE"
        class="c_esportHelpModalButton__fullText"
      >
        How to Join a Battle Royale?
      </span>
      <span
        *ngSwitchCase="gamePlatforms.ROCKET_LEAGUE"
        class="c_esportHelpModalButton__fullText"
      >
        How to Join the Tournament?
      </span>
      <span *ngSwitchDefault>How do I Join?</span>
    </div>
    <span class="c_esportHelpModalButton__shortText"> Need Help Joining? </span>
  </ng-container>
  <ng-container *ngIf="!isBattleRoyaleMode">
    <span class="c_esportHelpModalButton__fullText">
      Need Help Creating a Lobby?
    </span>
    <span class="c_esportHelpModalButton__shortText"> Lobby Help? </span>
  </ng-container>
</button>
