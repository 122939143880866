<div class="c_playerInfoBlock">
  <h3 class="c_playerInfoBlock__name u_txt--hdg3">
    {{ user.inGameName }}
  </h3>
  <div class="c_playerInfoBlock__info">
    <!-- TODO: Enable when we have data -->
    <!-- <strong class="c_playerInfoBlock__info__rank">
			Rank: Gold
		</strong>
		<strong class="c_playerInfoBlock__info__record">
			53 LP / 15W - 5L
		</strong>
		<span class="c_playerInfoBlock__info__winPercent">
			Win Ratio 54%
		</span> -->
  </div>
  <div class="c_playerInfoBlock__bio">
    <div
      class="c_playerInfoBlock__bio__photo"
      [ngStyle]="{ 'background-image': userImage }"
    ></div>
    <p class="c_playerInfoBlock__bio__txt u_txt--body">
      {{ user.description }}
    </p>
  </div>
  <div class="c_playerInfoBlock__social">
    <!-- TODO: Enable these when we have data -->
    <!-- <a class="c_playerInfoBlock__social__icon" href="#" target="_blank">
			<i class="fab fa-discord"></i>
		</a>
		<a class="c_playerInfoBlock__social__icon" href="#" target="_blank">
			<i class="fab fa-twitch"></i>
		</a> -->
  </div>
  <div class="c_playerInfoBlock__edit" *ngIf="userCanEdit$ | async">
    <a
      routerLink="/{{ userRoutes.PROFILE_EDIT }}"
      class="c_button primary_dark"
    >
      Edit Profile
    </a>
  </div>
</div>
