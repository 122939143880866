import {
  Component,
  forwardRef,
  Input,
  OnInit,
} from "@angular/core";
import {
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Observable } from "rxjs";

import {
  gameCredentialsHelpLinks,
  gameCredentialTitles,
  gamePlatforms,
} from "src/app/enums/game-platforms.enum";
import { showPlatformImage } from "src/app/util/image-utils";
import { modalOptions } from "src/app/util/modal-helpers";
import { BaseCVA } from "../base-cva/base-cva.class";
import { GameCredentialModalComponent } from "../game-credential-modal/game-credential-modal.component";

@Component({
  selector: "app-game-credentials",
  templateUrl: "./game-credentials.component.html",
  styleUrls: ["./game-credentials.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GameCredentialsComponent),
      //Multi is true since we are constructing these with an ngFor
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => GameCredentialsComponent),
      multi: true,
    },
  ],
})
export class GameCredentialsComponent extends BaseCVA implements OnInit {
  @Input() public gameCredential: string;
  @Input() public esport: gamePlatforms;
  @Input() public isUser = false;
  @Input() public isInEditMode = false;
  @Input() public cancel: Observable<void>;

  private _gameCredentialMap = gameCredentialTitles;
  private _noExcessWhitespaceRegex = /^\S(.+)\S$/i;

  constructor(private _formBuilder: FormBuilder, private _modalService: NgxSmartModalService) {
    super();
  }

  public ngOnInit() {
    this.controlForm = this._formBuilder.group({
      gameCredential: [this.gameCredential, [Validators.pattern(this._credentialRegex), Validators.pattern(this._noExcessWhitespaceRegex)]],
    });
    if (this.isUser && this.cancel) {
      this.cancel.subscribe(() => {
        this.controlForm.reset({
          gameCredential: this.gameCredential,
        });
        this.controlForm.markAsPristine();
      });
    }
  }

  public get gameCredentialName(): string {
    return this.gameCredential ?? "";
  }

  public get showEsportImage(): string {
    return showPlatformImage(this.esport);
  }

  public showEsportAccountTitle(): string {
    return this._gameCredentialMap.get(this.esport)?.toLocaleUpperCase();
  }

  public openModal(): void {
    this._modalService.create("gameCredential", GameCredentialModalComponent, modalOptions).setData(this.esport).open();
  }

  public get gameHasLinks(): boolean {
    const links = gameCredentialsHelpLinks.get(this.esport);
    return links?.length > 0 ?? false;
  }

  public get placeHolderForCredential(): string {
    switch (this.esport) {
      case gamePlatforms.SSBB:
      case gamePlatforms.POKEMON:
      case gamePlatforms.MARIO_KART:
        return "SW-XXXX-XXXX-XXXX";
      case gamePlatforms.OVERWATCH:
        return "Name#1234";
    }
    return "";
  }

  public get hasError(): boolean {
    return this.isInEditMode && this.controlForm.invalid;
  }

  /* Validator Functions */

  public validate(): ValidationErrors | null {
    return this.controlForm.valid ?
      null :
      {
        invalidForm: {
          valid: false,
          message: "Invalid format",
        },
      };
  }

  private get _credentialRegex(): RegExp {
    switch (this.esport) {
      case gamePlatforms.SSBB:
      case gamePlatforms.POKEMON:
      case gamePlatforms.MARIO_KART:
        return /SW(-[\d]{4}){3}$/;
      case gamePlatforms.OVERWATCH:
        return /^(.+)#(\d{4,})$/;
    }
    return null;
  }
}
