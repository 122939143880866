<div class="c_seriesMatchup__tbdBanner--container" *ngIf="displayTBDBanner">
  <div class="u_txt--white c_seriesMatchup__tbdBanner">
    This matchup is not finalized yet, and is waiting on the result of other
    matches before both teams are determined
  </div>
</div>
<ng-container *ngIf="seriesMatchup$ | async as seriesMatchup">
  <div
    class="c_seriesMatchup__winnerBanner--container"
    *ngIf="seriesMatchup.winnerId"
  >
    <div class="c_seriesMatchup__winnerBanner">
      A winner has been declared for this match,
      <a [routerLink]="seriesMatchup.league.id | leagueDetailRouter"
        >go back to your league hub</a
      >
      to find your next match!
    </div>
  </div>
</ng-container>
<app-page>
  <!-- TODO: ALl these switches may need to be re-evaluated so they only activate on a battle-royale lobby -->
  <ng-container *ngIf="(loading$ | async) === false; else loading">
    <div
      class="c_seriesMatchupPage"
      *ngIf="seriesMatchup$ | async as seriesMatchup"
    >
      <div class="c_seriesMatchupPage__header">
        <div class="c_seriesMatchupPage__header__title">
          <h2 class="u_txt--hdg2 u_txt--white">Series Matchup</h2>
          <p
            class="u_txt--white c_seriesMatchupPage__header__title__subheading"
          >
            {{ seriesMatchup.league.title }}
          </p>
          <a
            class="c_button secondary_light"
            [routerLink]="getLeagueDetailRoute(seriesMatchup?.league?.id)"
            >Back to league</a
          >
        </div>
        <h3 class="c_seriesMatchup__header__date u_txt--hdg2 u_txt--white">
          {{ seriesMatchup.matchupStartTime | date: "EEEE MMMM d, h:mma" }}
        </h3>
      </div>
      <!-- SWITCH FOR TEAM PREVIEW SECTION -->
      <ng-container [ngSwitch]="seriesType$ | async">
        <div
          class="c_seriesMatchupPage__brTeamPreview"
          *ngSwitchCase="seriesTypes.BATTLE_ROYALE"
        >
          <app-battle-royale-preview-block
            [seriesData]="seriesMatchup$ | async"
            [brSeriesData]="firebaseStreamBRGames$ | async"
          ></app-battle-royale-preview-block>
          <!-- TWITCH EMBED -->
          <ng-container *ngIf="safeStreamUrl">
            <div class="c_seriesMatchup__streamContainer">
              <h3 class="u_txt--hdg3">Stream:</h3>
              <iframe
                [src]="safeStreamUrl"
                height="300"
                width="500"
                allowfullscreen="true"
              ></iframe>
            </div>
          </ng-container>
          <!-- END TWITCH EMBED -->
        </div>
        <div class="c_seriesMatchupPage__teamPreview" *ngSwitchDefault>
          <app-team-preview-block
            [teamInfo]="seriesMatchup.teams[0]"
            [winnerId]="seriesMatchup.winnerId"
          >
          </app-team-preview-block>
          <div class="c_seriesMatchupPage__vs">
            <strong class="u_txt">VS</strong>
            <p
              class="u_txt c_seriesMatchupPage__bestOfText"
              *ngIf="
                (userHasMatchPermissions$ | async) &&
                seriesMatchup.esport === 'League of Legends'
              "
            >
              Best of {{ seriesMatchup.matches.length }}
            </p>
          </div>
          <app-team-preview-block
            [teamInfo]="(seriesMatchup$ | async).teams[1]"
            [winnerId]="seriesMatchup.winnerId"
          >
          </app-team-preview-block>
          <ng-container
            *ngIf="
              (userHasMatchPermissions$ | async) &&
              (seriesMatchup$ | async).teams[1] &&
              (seriesMatchup$ | async).teams[0]
            "
          >
            <div class="c_seriesMatchupPage__topBtnContainer">
              <ng-container
                *ngIf="!gameHasWinner(seriesMatchup.winnerId); else dispute"
              >
                <app-self-report-button
                  class="c_seriesMatchupPage__selfReport"
                  [seriesMatchup]="seriesMatchup"
                >
                </app-self-report-button>
                <app-esport-help-modal
                  *ngIf="!hasLobbies"
                  [esport]="(seriesMatchup$ | async)?.esport"
                  [isDark]="true"
                  [isSecondary]="true"
                ></app-esport-help-modal>
              </ng-container>
              <ng-template #dispute>
                <app-dispute-results-button
                  class="c_seriesMatchupPage__selfReport"
                ></app-dispute-results-button>
              </ng-template>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <!-- END TEAM PREVIEW SECTION -->
      <!-- SWITCH FOR ROSTER & LOBBY SECTION -->
      <ng-container [ngSwitch]="seriesType$ | async">
        <ng-container *ngSwitchCase="seriesTypes.BATTLE_ROYALE">
          <div
            class="c_seriesMatchup_brLobbyBlock"
            *ngIf="userHasMatchPermissions$ | async"
          >
            <app-battle-royale-lobby-info
              [brGamesSource]="firebaseStreamBRGames$ | async"
              [seriesId]="(seriesMatchup$ | async)?.id"
              [esport]="(seriesMatchup$ | async)?.esport"
            >
            </app-battle-royale-lobby-info>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container *ngIf="userHasMatchPermissions$ | async">
            <div
              class="c_seriesMatchup__rosterTables"
              [ngClass]="{
                'c_seriesMatchup__rosterTables--noLobbies': !hasLobbies
              }"
            >
              <app-matchup-roster-tables
                [teams]="seriesMatchup.teams"
                [esport]="seriesMatchup.esport"
              >
              </app-matchup-roster-tables>
            </div>
            <div class="c_seriesMatchup__lobbyInformation" *ngIf="hasLobbies">
              <app-matchup-lobby-block
                [lobbyCodes]="lobbyCodes"
                [esport]="seriesMatchup.esport"
              >
              </app-matchup-lobby-block>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- END ROSTER ROSTER & LOBBY SECTION -->
      <!-- SWITCH FOR CHAT SECTION -->
      <div
        class="c_seriesMatchupPage__comments"
        *ngIf="userHasMatchPermissions$ | async"
      >
        <ng-container *ngIf="seriesMatchup?.matches">
          <ng-container [ngSwitch]="seriesType$ | async">
            <ng-container *ngSwitchCase="seriesTypes.BATTLE_ROYALE">
              <app-split-admin-chat-block
                [chatData]="chatData$ | async"
                [teams]="seriesMatchup.teams"
                [lobbyCodes]="battleRoyaleLobbyCodes"
              ></app-split-admin-chat-block>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <app-chat-block
                [teams]="seriesMatchup.teams"
                [chatData]="chatData$ | async"
              ></app-chat-block>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <app-loader [useWhite]="true">Loading Matchup...</app-loader>
  </ng-template>
</app-page>
