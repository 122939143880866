<app-page>
  <h1 class="u_txt--hdg1 u_txt--white">Match Schedule</h1>
  <a
    [routerLink]="leagueId | leagueDetailRouter"
    class="c_button secondary_light c_schedule__backBtn"
  >
    Back to League Overview
  </a>
  <div class="c_schedulePage" *ngIf="!isDataLoading; else loading">
    <div class="c_scheduleBlock">
      <div class="c_scheduleBlock__header">
        <h3 class="u_txt--hdg3">Unplayed Matches</h3>
        <div class="c_scheduleBlock__gameFilter">
          <label class="c_scheduleBlock__filterLabel"> Current View </label>
          <select
            class="c_scheduleBlock__filterDropdown"
            [(ngModel)]="isShowingUserMatches"
          >
            <option [ngValue]="true" selected>My Games</option>
            <option [ngValue]="false">All Games in League</option>
          </select>
        </div>
      </div>
      <hr />
      <div
        class="c_scheduleBlock__matchList"
        *ngIf="upcomingMatches?.length; else noUpcoming"
      >
        <div class="c_scheduleBlock__match--header">
          <span>Date</span>
          <span>Winner</span>
          <span>Teams</span>
          <span></span>
        </div>
        <div
          class="c_scheduleBlock__matchWrapper"
          *ngFor="let match of upcomingMatches"
        >
          <a
            [routerLink]="match.id | seriesMatchupRouter"
            class="c_scheduleBlock__match"
          >
            <div>
              <strong class="c_scheduleBlock__match__label">Date: </strong
              >{{ processDate(match.gameStartTime) }}
            </div>
            <div class="c_scheduleBlock__match--winner">
              <strong class="c_scheduleBlock__match__label">Winner: </strong
              >{{ match.winner || "TBD" }}
            </div>
            <ng-container
              *ngIf="isMatchBattleRoyale(match); else standardMatchInfo"
            >
              <div>
                <strong>Battle Royale Series</strong>
              </div>
              <div>{{ match.teamCount }} Participants</div>
            </ng-container>
            <ng-template #standardMatchInfo>
              <div>
                <strong class="c_scheduleBlock__match__label">Team One: </strong
                >{{ compareTitleAgainstTBD(match.teams[0]) }}
              </div>
              <div>
                <strong class="c_scheduleBlock__match__label">Team Two: </strong
                >{{ compareTitleAgainstTBD(match.teams[1]) }}
              </div>
            </ng-template>
          </a>
        </div>
      </div>
      <ng-template #noUpcoming>
        <div class="c_scheduleBlock__matchList">
          <h4 class="u_txt--hdg4">There are no upcoming matches</h4>
        </div>
      </ng-template>
    </div>
    <div class="c_scheduleBlock">
      <h3 class="u_txt--hdg3">Recently Played Matches</h3>
      <hr />
      <div
        class="c_scheduleBlock__matchList"
        *ngIf="recentMatches?.length; else noRecent"
      >
        <div class="c_scheduleBlock__match--header">
          <span>Date</span>
          <span>Winner</span>
          <span>Teams</span>
          <span></span>
        </div>
        <div
          class="c_scheduleBlock__matchWrapper"
          *ngFor="let match of recentMatches"
        >
          <a
            [routerLink]="match.id | seriesMatchupRouter"
            class="c_scheduleBlock__match"
          >
            <div>
              <strong class="c_scheduleBlock__match__label">Date: </strong
              >{{ processDate(match.gameStartTime) }}
            </div>
            <div class="c_scheduleBlock__match--winner">
              <strong class="c_scheduleBlock__match__label">Winner: </strong
              >{{ match.winner || "TBD" }}
            </div>
            <ng-container
              *ngIf="isMatchBattleRoyale(match); else standardMatchInfo"
            >
              <div>
                <strong>Battle Royale Series</strong>
              </div>
              <div>{{ match.teamCount }} Participants</div>
            </ng-container>
            <ng-template #standardMatchInfo>
              <div>
                <strong class="c_scheduleBlock__match__label">Team One: </strong
                >{{ compareTitleAgainstTBD(match.teams[0]) }}
              </div>
              <div>
                <strong class="c_scheduleBlock__match__label">Team Two: </strong
                >{{ compareTitleAgainstTBD(match.teams[1]) }}
              </div>
            </ng-template>
          </a>
        </div>
      </div>
      <ng-template #noRecent>
        <div class="c_scheduleBlock__matchList">
          <h4 class="u_txt--hdg4">There are no recent matches</h4>
        </div>
      </ng-template>
    </div>
  </div>
  <ng-template #loading>
    <div class="c_schedulePage__loading">
      <app-loader [useWhite]="true">Loading Schedule...</app-loader>
    </div>
  </ng-template>
</app-page>
