import { Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  filter,
  first,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { Observable, of } from "rxjs";
import { Store } from "@ngrx/store";

import { SeasonPass } from "@apptypes/season-pass/season-pass.types";

import { routeQueries } from "src/app/enums/routes/routeParams";
import { RootState } from "src/app/reducers";
import { GenericLeagueDetails } from "src/app/reducers/leagues/league.types";
import { GetLeagueById } from "src/app/reducers/leagues/league.actions";

@Component({
  selector: "app-season-pass-league-count-context",
  templateUrl: "./season-pass-league-count-context.component.html",
  styleUrls: ["./season-pass-league-count-context.component.scss"],
})
export class SeasonPassLeagueCountContextComponent {
  @Input() public seasonPass!: SeasonPass;
  public hasLeagueContext$: Observable<boolean> = of(false);
  public leagueTitle$: Observable<string> = of("");

  constructor(private _activatedRoute: ActivatedRoute, private _store: Store<RootState>) {
    const leagueId$ = this._activatedRoute.queryParamMap.pipe(
      map(paramMap => paramMap.get(routeQueries.LEAGUE_ID)),
      first()
    );

    this.hasLeagueContext$ = leagueId$.pipe(
      map(leagueId => !!leagueId),
      first()
    );

    this.leagueTitle$ = this._getLeagueTitle(leagueId$, this.hasLeagueContext$);
  }

  private _getLeagueTitle(leagueId$: Observable<string>, hasLeagueContext$: Observable<boolean>): Observable<string> {
    return hasLeagueContext$.pipe(
      switchMap(hasContext => {
        if (hasContext)
        {
          return this._getLeague(leagueId$);
        }
        return of(null);
      }),
      map(league => league?.title ?? ""),
      first()
    );
  }

  private _getLeague(leagueId$: Observable<string>): Observable<GenericLeagueDetails> {
    return leagueId$.pipe(
      switchMap(leagueId => this._store.select("leagues", "league").pipe(
        tap(league => {
          if(league && league.id.toString() === leagueId) {
            return;
          }
          this._store.dispatch(new GetLeagueById(+leagueId));
        }),
        filter(league => !!league && league.id.toString() === leagueId),
        first()
      ))
    );
  }
}
