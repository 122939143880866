<ng-container *ngIf="league$ | async as league">
  <aside class="c_leagueFeature__aside">
    <span class="c_leagueFeature__status">
      {{ getLeagueStatus(league) }}
    </span>
    <strong class="c_leagueFeature__asideItem">
      <i class="far fa-calendar fa-2x"></i>
      <span>
        {{ showEveryText + getLeagueMatchDay(league) | uppercase }}
      </span>
    </strong>
    <strong class="c_leagueFeature__asideItem">
      <i class="far fa-clock fa-2x"></i>
      <span>{{ getWeeklyHour(league) | uppercase }}</span>
    </strong>
    <strong class="c_leagueFeature__asideItem">
      <i class="fas fa-users fa-2x"></i>
      <span>{{ league.maxEntrants + " ENTRANTS" | uppercase }}</span>
    </strong>
    <app-join-league-button
      *ngIf="this.isUserLoggedIn$ | async; else logInToJoinButton"
      class="c_leagueFeature__info__button"
    ></app-join-league-button>
    <ng-template #logInToJoinButton>
      <div class="c_leagueFeature__info__button">
        <a routerLink="/login/redirect" class="c_button warn">LOG IN TO JOIN</a>
      </div>
    </ng-template>
  </aside>
  <app-sponsor-campaign-bar-v1></app-sponsor-campaign-bar-v1>
  <section
    class="c_leagueFeature"
    [ngStyle]="{ 'background-image': getSplashUrl(league) }"
    [ngClass]="{ c_leagueFeature__hideImage: !useFullArt }"
  >
    <div class="c_leagueFeature__details">
      <header class="c_leagueFeature__titleDetails">
        <h3 class="u_txt--hdg3 u_txt--white">{{ league.title }}</h3>
        <h4 class="u_txt--hdg4">{{ getStartDate(league) }}</h4>
      </header>
      <p class="u_txt--hdg4 c_leagueFeature__dateFee">
        <ng-container *ngIf="isQuickplay; else defaultLeagueDate">
          {{ getQuickplayDateTime(league) }} at {{ getWeeklyHour(league) }} |
        </ng-container>
        <ng-template #defaultLeagueDate>
          Every {{ getLeagueMatchDay(league) }} at {{ getWeeklyHour(league) }} |
        </ng-template>
        <a
          class="u_txt--hdg4 c_leagueFeature__passInfoModal c_leagueFeature__noWrapInfo"
          [href]="leaguePassesUrl | async"
          *ngIf="!getDoesLeagueHaveFee(league); else useFee"
          >REQUIRES PASS <i class="fas fa-ticket-alt"></i>
        </a>
        <ng-template #useFee>
          <span class="u_txt--hdg4 c_leagueFeature__noWrapInfo">
            {{ (getLeagueFeeText(league) | currency) || "FREE ENTRY" }}
          </span>
        </ng-template>
      </p>
    </div>
    <div
      *ngIf="!!getSplashUrl(league)"
      class="c_leagueFeature__splashArt"
      [ngStyle]="{ 'background-image': getSplashUrl(league) }"
    ></div>
  </section>
  <!-- TWITCH EMBED -->
  <ng-container *ngIf="leagueHasStream">
    <div class="c_leagueFeature__streamPanel">
      <h3 class="u_txt--hdg3">Stream:</h3>
      <div class="c_leagueFeature__streamContainer">
        <div id="twitch-player"></div>
      </div>
    </div>
  </ng-container>
  <!-- END TWITCH EMBED -->
</ng-container>
