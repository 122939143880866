import {
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { Observable, of } from "rxjs";
import { Store } from "@ngrx/store";
import { takeUntil, filter } from "rxjs/operators";

import { GenericRosterMember } from "src/app/reducers/leagues/league.types";
import { RootState } from "src/app/reducers";
import { UpdateTeamRoster } from "src/app/reducers/teams/teams.actions";
import { TeamRosterUpdateActions } from "src/app/reducers/teams/teams.types";
import { generateUserDetailRoute } from "src/app/enums/routes/routePaths";
import { Unsubscriber } from "src/app/util/unsubscriber";

@Component({
  selector: "app-team-invites-block",
  templateUrl: "./team-invites-block.component.html",
  styleUrls: ["./team-invites-block.component.scss"],
})
export class TeamInvitesBlockComponent implements OnInit, OnDestroy {
  public pending$: Observable<GenericRosterMember[]> = of([]);
  public isRosterUpdating$: Observable<boolean> = of(false);

  private _unsub = new Unsubscriber();

  constructor(private _store: Store<RootState>) {}

  public approvePlayer(playerId: string): void {
    this._store.dispatch(
      new UpdateTeamRoster({
        teamUserId: +playerId,
        rosterAction: TeamRosterUpdateActions.ACCEPT_INVITE,
      })
    );
  }

  public rejectPlayer(playerId: string): void {
    this._store.dispatch(
      new UpdateTeamRoster({
        teamUserId: +playerId,
        rosterAction: TeamRosterUpdateActions.REJECT_PLAYER,
      })
    );
  }

  public ngOnInit() {
    this._store
      .select("teams", "currentTeam")
      .pipe(
        filter((team) => team !== null),
        takeUntil(this._unsub.unsubEvent)
      )
      .subscribe((team) => {
        const pendingMembers = team.members.pending;
        this.pending$ = of(pendingMembers);
      });

    this.isRosterUpdating$ = this._store
      .select("teams", "isUpdatingRoster")
      .pipe(takeUntil(this._unsub.unsubEvent));
  }

  public ngOnDestroy() {
    this._unsub.kill();
  }

  public getUserDetailRoute(id: string): string {
    return generateUserDetailRoute(id);
  }
}
