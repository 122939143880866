import {
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import {
  filter,
  map,
  takeUntil,
} from "rxjs/operators";

import { Unsubscriber } from "@utils/unsubscriber";
import { ChatData } from "@apptypes/chat/chat-data.interface";
import { QuickPlayEventChatroomProvider } from "@providers/chatroom-collection";
import { QuickPlayEventCommentProvider } from "@providers/comment-service/quick-play-event-comment.provider";

import { RootState } from "src/app/reducers";
import { GenericLeagueDetails } from "src/app/reducers/leagues/league.types";
import { ChatResourceTypes } from "src/app/enums/chat-resource-types";
import { ChatBlockTeam } from "../split-admin-chat-block/split-admin-chat-block.component";


//TODO: Rename this to QuickPlayEventChatBlock
@Component({
  selector: "app-quick-play-event-chat-block",
  templateUrl: "./quick-play-event-chat-block.component.html",
  styleUrls: ["./quick-play-event-chat-block.component.scss"],
  providers: [QuickPlayEventChatroomProvider, QuickPlayEventCommentProvider],
})
export class QuickPlayEventChatBlockComponent implements OnInit, OnDestroy {
  public league$: Observable<GenericLeagueDetails | null> = of(null);
  public chatData$: Observable<ChatData | null> = of(null);
  public chatBlockTeams$: Observable<ChatBlockTeam[]> = of([]);

  private _unsub = new Unsubscriber();

  constructor(private _store: Store<RootState>) {}

  public ngOnInit(): void {
    this.league$ = this._store.select("leagues", "league").pipe(
      filter((league) => league !== null),
      takeUntil(this._unsub.unsubEvent)
    );

    this.chatData$ = this._store.select("leagues", "quickPlayEvent").pipe(
      filter((nullishQuickPlayEvent) => nullishQuickPlayEvent !== null),
      map((quickPlayEvent) => ({
        railsID: quickPlayEvent.id,
        firestoreDocID: quickPlayEvent.chatroomReferenceId,
        referenceType: ChatResourceTypes.QUICK_PLAY_EVENT,
      })),
      takeUntil(this._unsub.unsubEvent)
    );

    this.chatBlockTeams$ = this.league$.pipe(
      map((league) => this.mapChatBlockTeams(league))
    );
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public mapChatBlockTeams({ teams }: GenericLeagueDetails): ChatBlockTeam[] {
    if(!teams){
      return [];
    }

    return teams.map(({ id, userIds }) => ({
      id,
      players: userIds.map(userId => ({
        id: userId,
      })),
    }));
  }
}
