import { HttpParams } from "@angular/common/http";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "mailto",
})
export class MailtoPipe implements PipeTransform {

  public transform(email: string | null | undefined, subject?: string | undefined | null): string {
    if(!email){
      return "";
    }

    if(!subject) {
      return `mailto:${email}`;
    }

    const params = new HttpParams().append("subject", subject).toString();
    return `mailto:${email}?${params}`;
  }

}
