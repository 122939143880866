/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef, Provider } from "@angular/core";
import {
  ControlValueAccessor,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";

export abstract class BaseCVA implements ControlValueAccessor {
  public controlForm: FormGroup;
  public onTouched: () => void;

  constructor() {
    this.onTouched = () => { };
  }

  public static createCVAProviders(component: any): Provider {
    return {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => component),
      multi: true,
    };
  }

  public static createCVAValidatorProvider(component: any): Provider {
    return {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => component),
      multi: true,
    };
  }

  /*
   * Source:
   * https://medium.com/angular-in-depth/angular-nested-reactive-forms-using-cvas-b394ba2e5d0d
   */

  /* Standard CVA Functions */

  public writeValue(obj: any): void {
    if (obj) {
      this.controlForm.setValue(obj, {
        emitEvent: false,
      });
    }
  }

  public registerOnChange(fn: any): void {
    this.controlForm.valueChanges.subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.controlForm.disable();
    } else {
      this.controlForm.enable();
    }
  }
}
