import { Component, Input } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";

import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import { modalOptions } from "src/app/util/modal-helpers";
import { AddFriendsModalComponent } from "../add-friends-modal/add-friends-modal.component";

@Component({
  selector: "app-add-friend-button",
  templateUrl: "./add-friend-button.component.html",
  styleUrls: ["./add-friend-button.component.scss"],
})
export class AddFriendButtonComponent {
  @Input() public isDark = true;
  @Input() public esport: gamePlatforms;

  constructor(private _ngxService: NgxSmartModalService) {}

  public openModal() {
    this._ngxService.create(AddFriendsModalComponent.modalID, AddFriendsModalComponent, modalOptions).setData(this.esport).open();
  }
}
