<section class="c_staticPage">
  <div class="c_staticPage__headerContainer" *ngIf="pageTitle !== ''">
    <h1 class="c_staticPage__header u_txt--hdg1">{{ pageTitle }}</h1>
  </div>
  <div class="c_staticPage__bodyContainer">
    <div class="c_staticPage__body">
      <ng-content></ng-content>
    </div>
  </div>
</section>
