<app-page>
  <div class="c_resetPasswordBlock">
    <div class="c_resetPasswordBlock__header">
      <h2 class="u_txt--white u_hdg2">Reset Your Password</h2>
    </div>
    <p class="u_txt--body c_resetPasswordBlock__copy">
      Forgot your password? Provide the email you used to register your account
      and we will send you instructions on how to reset your password:
    </p>
    <form
      class="c_resetPasswordBlock__body"
      *ngIf="!sentEmail"
      [formGroup]="resetEmailForm"
      (submit)="sendPasswordRequest()"
    >
      <input
        formControlName="email"
        class="c_input"
        type="email"
        placeholder="Email Address"
      />
      <app-form-footer-error-message
        *ngIf="formInvalid"
      ></app-form-footer-error-message>
      <app-form-loading-button>Email Me</app-form-loading-button>
    </form>
    <div class="c_resetPasswordBlock__body" *ngIf="sentEmail">
      <h3 class="u_hdg3">Email Sent!</h3>
      <p class="u_txt--body">
        Email sent to <strong>{{ emailInput() }}</strong> with instructions on
        how to reset your password.
      </p>
      <a routerLink="/{{ staticRoutes.HOME }}" class="c_button primary_dark"
        >Return Home</a
      >
    </div>
  </div>
</app-page>
