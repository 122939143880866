export type Comment = RailsSeriesMatchupComment | RailsTournamentComment | RailsScrimmageComment | CloudComment;

export interface RailsSeriesMatchupComment {
  message: {
    body: string;
  };
}

export interface RailsScrimmageComment {
  comment: {
    body: string;
    user_id: string;
    scrimmage_id: string;
  };
}

export interface RailsTournamentComment {
  message: {
    body: string;
    tournament_id: string;
    user_id: string;
  };
}

export interface CloudComment {
  chatroomId: string;
  userId: string;
  message: string;
}

export const BannedChatError = {
  error: "AccessGranted::AccessDenied",
};
