import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from "@angular/core";
import { CarouselGameCard } from "@apptypes/component-interfaces/carousel-cards.interface";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { first, map } from "rxjs/operators";
import { RootState } from "src/app/reducers";
import {
  FortniteCard,
  MaddenCard,
  MarioKartCard,
  RocketLeagueCard,
  SSBUCard,
} from "src/app/static-data/league-carousel-cards.data";

interface FAQItem {
  title: string;
  copy: string;
  link: string;
}

@Component({
  selector: "app-new-user-dashboard",
  templateUrl: "./new-user-dashboard.component.html",
  styleUrls: ["./new-user-dashboard.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewUserDashboardComponent implements OnInit {
  public weeklyCarouselCards: CarouselGameCard[] = [
    SSBUCard,
    FortniteCard,
    RocketLeagueCard,
    MarioKartCard,
  ];

  public recCarouselCards: CarouselGameCard[] = [
    RocketLeagueCard,
    SSBUCard,
    MarioKartCard,
    MaddenCard,
  ];

  public userAlreadyHasPass$: Observable<boolean> = of(false);

  public faqItems: FAQItem[] = [
    {
      title: "How do leagues work?",
      copy: `
        There are two types of leagues. 
        Recreational and Elite. You can find more information on the league types in this article. 
        Once you are registered for a league, you will be playing against your opponent online...
      `,
      link: "https://ggleagues.zendesk.com/hc/en-us/articles/4413490118039-How-do-leagues-work-",
    },
    {
      title: "How do I compete each week?",
      copy: `
        In order to compete each week, you will need to register for one of our leagues. 
        You will receive a notification 48 hours before your match and the day of your match. 
        On match day, you will need to check-in...
      `,
      link: "https://ggleagues.zendesk.com/hc/en-us/articles/4412834349207-How-do-I-compete-each-week-",
    },
    {
      title: "How does GGLeagues monitor the games?",
      copy: `
        Each match is monitored by GGLeagues through the match chats on the website. 
        All communication with your opponent should go through that chat so GGLeagues can best monitor each game.
      `,
      link: "https://ggleagues.zendesk.com/hc/en-us/articles/4413675837079-How-does-GGLeagues-monitor-the-games-",
    },
  ];

  constructor(
    private _store: Store<RootState>
  ) { }

  public ngOnInit(): void {
    this.userAlreadyHasPass$ = this._store.select("user", "currentUser").pipe(
      first(),
      map(({ gamePasses }) => gamePasses.length > 0)
    );
  }

}
