<div class="c_matchesPreview">
  <h5 class="u_txt--hdg5">{{ matchType }}</h5>
  <div class="c_matchesPreview__list" *ngIf="matches.length > 0; else empty">
    <div
      *ngFor="let match of matches.slice(0, 4)"
      class="c_matchesPreview__listItem"
    >
      <app-small-match-block [match]="match"></app-small-match-block>
    </div>
  </div>
  <ng-template #empty>
    <div class="c_matchesPreview__empty">
      <p class="u_txt">There are no matches for this category.</p>
    </div>
  </ng-template>
</div>
