<app-static-page pageTitle="GGLeagues for Parents">
  <section>
    <p>
      <strong
        >GGLeagues aims to provide players with a safe and memorable online
        gaming experience.</strong
      >
      We provide a platform for gamers to compete in a fun, safe setting. Our
      platform allows for children to play their favorite games in a structured
      environment, free to toxicity. Through our methods, we ensure that players
      are watched and cared for every step of the way in their competitive
      gaming journey.
    </p>
    <div>
      <a
        class="c_button primary_dark--alt"
        style="
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        "
        (click)="scrollToForm()"
      >
        Register for GGLeagues Parent Resources
      </a>
    </div>
  </section>
  <hr />
  <section>
    <h2 class="u_txt--hdg2">Keeping your child safe online:</h2>
    <h3 class="u_txt--hdg3">Monitor</h3>
    <p>
      Our experienced team of moderators, referees, and league managers will be
      on staff during every game, in order to ensure everyone is staying within
      our code of conduct and guidelines. Should any code of conduct breach or
      toxicity occur, our moderators will always be available to step in and
      de-escalate the situation.
    </p>
    <h3 class="u_txt--hdg3">Protect</h3>
    <ul>
      <li>
        With our moderated, private chats, your child can communicate safely
        with their opponent each week
      </li>
      <li>
        All personal information on your child’s profile is private, and unable
        to be seen by other players - you can feel safe knowing that your
        child’s information will never be shared.
      </li>
      <li>
        GGLeagues is fully in compliance with COPPA - the Children’s Online
        Privacy Protection Act
      </li>
    </ul>
    <h3 class="u_txt--hdg3">Manage</h3>
    <p>
      Parents have the ability to monitor and manage what leagues and
      tournaments their child takes part in. In addition, GGLeagues provides
      age-specific competitions, to allow your child to connect and engage with
      other players within their age group.
      <br />
      For more information, please feel free to check out our
      <a [href]="playerGuides.CODE_OF_CONDUCT" target="_blank"
        >GGLeagues Player Code of Conduct</a
      >
    </p>
  </section>
  <hr />
  <section>
    <h2 class="u_txt--hdg2">What is esports?</h2>
    <p>
      Esports is the competitive practice of video games. There are professional
      esports teams that compete in franchised leagues, akin to NBA or NFL.
    </p>
    <p>
      There are many benefits to youth participating in esport competitions, and
      team-based video games! Participating in esports can also lead to other
      opportunities for your child later in life. Curious to learn more about
      esports, collegiate esports scholarships, and how to get more involved
      with your child through gaming?
      <br />
      <a
        target="_blank"
        rel="noreferrer"
        href="https://content.ggleagues.com/public/guides/parents_handbook_v4.pdf"
      >
        Check out our parents handbook here!
      </a>
    </p>
  </section>
  <hr />
  <section>
    <h2 class="u_txt--hdg2">GGLeagues Platform Benefits</h2>
    <ul>
      <li>Fun & inclusive</li>
      <li>Compete against local players</li>
      <li>Segmented age divisions</li>
      <li>Open to all skill levels</li>
      <li>Personal support from our highly trained staff</li>
    </ul>
  </section>
  <hr />
  <section>
    <h2 class="u_txt--hdg2">GGLeagues Player Code of Conduct</h2>
    <p>
      <a target="_blank" rel="noreferrer" [href]="playerGuides.CODE_OF_CONDUCT">
        Complete GGLeagues Code of Conduct (PDF)
      </a>
    </p>
    <p>
      <strong
        >In order for every player to have the best experience possible, all
        players are expected to display Good Gamer Behavior:</strong
      >
    </p>
    <ol>
      <li>Respect your opponents, team, and GGLeagues staff</li>
      <li>
        Don’t be toxic. This means <strong>NO</strong>:
        <ul>
          <li>Cursing</li>
          <li>Sexism</li>
          <li>Racism</li>
          <li>Homophobic remarks</li>
          <li>Taunting</li>
          <li>Bullying</li>
          <li>Lewd/NSFW remarks or posts</li>
        </ul>
      </li>
      <li>Play with integrity in all GGLeagues matches</li>
      <li>Follow all the rules listed out in our GGLeagues Rulebooks</li>
    </ol>
  </section>
  <hr />
  <section id="form">
    <iframe
      style="width: 100%; min-height: 811px"
      src="https://cdn.forms-content.sg-form.com/c69b04b1-2ae1-11eb-b106-b29df8e95b4c"
    ></iframe>
  </section>
</app-static-page>
