import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import { ModalController } from "../modal-controller/modal-controller.abstract";

@Component({
  selector: "app-battle-royale-need-help-modal",
  templateUrl: "./battle-royale-need-help-modal.component.html",
  styleUrls: ["./battle-royale-need-help-modal.component.scss"],
})
export class BattleRoyaleNeedHelpModalComponent extends ModalController<gamePlatforms>  {
  public static MODAL_NAME = "br_needhelp_modal";
  public gamePlatforms = gamePlatforms;

  constructor(
    private _modalService: NgxSmartModalService,
    private _router: Router
  ) {
    super(_modalService, BattleRoyaleNeedHelpModalComponent.MODAL_NAME, _router);
  }

  public get getEsport(): gamePlatforms {
    return this.getData();
  }
}
