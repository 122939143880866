import {
  AfterViewInit,
  Component,
  HostListener,
} from "@angular/core";
import { Observable, of } from "rxjs";
import { Store } from "@ngrx/store";
import {
  filter,
  first,
  map,
} from "rxjs/operators";
import { TwitchPlayer } from "twitch-player";
import { NgxSmartModalService } from "ngx-smart-modal";

import { modalOptions } from "@utils/modal-helpers";
import {
  getDayOfWeekFromISOString,
  getHourFromISOString,
  getMonthDateFromISOString,
  isISODateInFuture,
  isoToShort,
} from "@utils/date-utils";

import { RootState } from "src/app/reducers";
import { GenericLeagueDetails } from "src/app/reducers/leagues/league.types";
import { LeagueTypes } from "src/app/enums/league-type.enum";
import { EsportSplashArtworkMap } from "src/app/enums/resources/images/esports/esport-artworks.enum";
import { EsportsPassInfoModalComponent } from "../esports-pass-info-modal/esports-pass-info-modal.component";
import { generateLeagueSeasonPassesRoute } from "src/app/enums/routes/routePaths";

@Component({
  selector: "app-league-feature",
  templateUrl: "./league-feature.component.html",
  styleUrls: ["./league-feature.component.scss"],
})
export class LeagueFeatureComponent implements AfterViewInit {
  public league$: Observable<GenericLeagueDetails>;
  public isUserLoggedIn$: Observable<boolean> = of(false);

  public dateString: string;
  public splashArtworks = EsportSplashArtworkMap;
  public useFullArt = false;
  public isQuickplay = false;

  private _twitchStream: string | null = null;

  constructor(
    private _store: Store<RootState>,
    private _modalService: NgxSmartModalService
  ) {
    this.league$ = this._store.select("leagues", "league").pipe(
      filter((leagueData) => leagueData !== null),
      first()
    );

    this.isUserLoggedIn$ = this._store.select("user", "currentUser").pipe(
      map(user => !!user),
      first()
    );

    this.league$.subscribe((league) => {
      this.isQuickplay = league.leagueType === LeagueTypes.QUICKPLAY;
      this._twitchStream = league.twitchStreamUrl;
    });
  }

  @HostListener("window:resize")
  public onResize() {
    this.useFullArt = window.innerWidth <= 1000;
  }

  public getDoesLeagueHaveFee({ organizationSeasonPassIds }: GenericLeagueDetails): boolean {
    return organizationSeasonPassIds.length === 0;
  }

  public getIsLeagueFree({ defaultTeamFee }: GenericLeagueDetails): boolean {
    return !defaultTeamFee || defaultTeamFee === "0.0";
  }

  public getLeagueFeeText(league: GenericLeagueDetails): string | null {
    const { defaultTeamFee } = league;

    return this.getIsLeagueFree(league) ? null : defaultTeamFee;
  }

  public openPassHelpModal(): void {
    this._modalService.create(EsportsPassInfoModalComponent.MODAL_NAME, EsportsPassInfoModalComponent, modalOptions).open();
  }

  public getLeagueStatus(league: GenericLeagueDetails): string {
    if (league.finished === true) {
      return "League Over";
    }

    if (isISODateInFuture(league.startTime)) {
      return "Upcoming";
    } else {
      return "In Progress";
    }
  }

  public getQuickplayDateTime({ startTime }: GenericLeagueDetails): string {
    return getMonthDateFromISOString(startTime);
  }

  public getLeagueMatchDay({ startTime }: GenericLeagueDetails): string {
    return getDayOfWeekFromISOString(startTime);
  }

  public getWeeklyHour({ startTime }: GenericLeagueDetails): string {
    return getHourFromISOString(startTime);
  }

  public getSplashUrl({ esport }: GenericLeagueDetails): string {
    return !!this.splashArtworks[esport] ? `url(${this.splashArtworks[esport]})` : "";
  }

  public getStartDate({ startTime }: GenericLeagueDetails): string {
    return isoToShort(startTime);
  }

  public get showEveryText(): string {
    return !this.isQuickplay ? "Every " : "";
  }

  public get leagueHasStream(): boolean {
    return !!this._twitchStream;
  }

  public get leaguePassesUrl(): Observable<string> {
    return this.league$.pipe(
      first(),
      map((league) => `/${generateLeagueSeasonPassesRoute(league.id)}`)
    );
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.onResize();
    });

    if (this.leagueHasStream) {
      setTimeout(() => {
        const CHANNEL_NAME_REGEX_MATCH_GROUP_NUMBER = 4;
        const twitchChannelValue = this._twitchStream.match(
          /^(https:\/\/){0,1}(www.)(twitch.tv\/)(\w+)$/
        )[CHANNEL_NAME_REGEX_MATCH_GROUP_NUMBER];
        TwitchPlayer.FromOptions("twitch-player", {
          width: "100%",
          height: 300,
          channel: twitchChannelValue,
        });
      }, 10);
    }
  }
}
