import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
} from "@angular/core";
import { doesMessageContainLobbyCode } from "@utils/chat.utils";

import { ChatResourceTypes } from "src/app/enums/chat-resource-types";
import { PlayerGuides } from "src/app/enums/resources/document-links/player-guides.enum";

@Component({
  selector: "app-add-new-comment-block",
  templateUrl: "./add-new-comment-block.component.html",
  styleUrls: ["./add-new-comment-block.component.scss"],
})
export class AddNewCommentBlockComponent implements OnInit {
  @Output() public sendChatEvent = new EventEmitter();
  @Input() public status: string;
  @Input() public chatType: ChatResourceTypes;
  @Input() public lobbyCodes: string[] = [];

  public playerGuides = PlayerGuides;
  public commentText = "";
  // eslint-disable-next-line max-len
  public chatActive = false;

  private _messageContainsCode = false;
  private _hasBlockingErrors = false;

  public ngOnInit() {
    if (this.status === "active" || this.status === "pending") {
      this.chatActive = true;
    }
  }

  public sendComment(): void {
    this.commentText = this.commentText.trim(); //Catch string that have a return character
    const messageContainsLobbyCode = this.lobbyCodes.length > 0 ? this._checkLobbyCodes(this.commentText, this.lobbyCodes) : false;
    if (this.commentText.length === 0 || this.chatInactive || messageContainsLobbyCode) {
      this._hasBlockingErrors = true;
      return;
    }
    this.sendChatEvent.emit(this.commentText);
    this.commentText = "";
    this._hasBlockingErrors = false;
  }

  public get shortCommentText(): boolean {
    return this.commentText.length === 0 && this._hasBlockingErrors;
  }

  public get chatInactive(): boolean {
    return !this.chatActive;
  }

  public get showContactAdmin(): boolean {
    return this.chatType === ChatResourceTypes.SERIES_MATCHUP;
  }

  public get chatMessageLobbyCodeError(): boolean {
    return this._messageContainsCode && this._hasBlockingErrors;
  }

  private _checkLobbyCodes(message: string, lobbyCodes: string[]): boolean {
    const messageContainsCode = !!lobbyCodes.find(code =>
      doesMessageContainLobbyCode(message, code));
    this._messageContainsCode = messageContainsCode;
    return messageContainsCode;
  }
}
