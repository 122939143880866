import { Component, Input } from "@angular/core";

import { APITournamentTypes } from "src/app/enums/tournamentTypes.enum";
import { LeagueSeriesMatchupV2 } from "src/app/reducers/leagues/league.types";

@Component({
  selector: "app-league-match-block",
  templateUrl: "./league-match-block.component.html",
  styleUrls: ["./league-match-block.component.scss"],
})
export class LeagueMatchBlockComponent {
  @Input() public leagueMatch: LeagueSeriesMatchupV2 | null = null;
  @Input() public isDateActive = false;
  @Input() public isGrayedOut = false;

  public schedulingTypes = APITournamentTypes;
}
