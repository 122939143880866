import { FormGroup } from "@angular/forms";

import { formHasSpecificError } from "src/app/util/form-helpers";

/**
 * A base class for handling a lot of form operations that are common throughout
 * our application. You must call _loadForm after the form is initialized to sync up
 * the memory reference.
 *
 * @author Christian Tweed
 */
export abstract class FormController {
  protected _formGroup: FormGroup;
  protected _hasBlockingErrors = false;

  /**
   * Checks to see if a form control has a specific error
   *
   * @param controlName
   * @param errorName
   * @author Alex Muench
   */
  public hasSpecificError(controlName: string, errorName: string): boolean {
    return formHasSpecificError(controlName, errorName, this._formGroup);
  }

  /**
   * Returns if the form is invalid for proceeding.
   * We are checking both the status of the form and blocking
   * errors to not show false alarms
   *
   * @author Christian Tweed
   */
  public get formInvalid(): boolean {
    return this._formGroup.invalid && this._hasBlockingErrors;
  }

  /**
   * This should be run before any submissions for a form.
   * Checks to see if our form can be submitted, and marks all
   * fields as touched.
   *
   * @author Christian Tweed
   */
  protected _canProceed(): boolean {
    if (this._formGroup.invalid) {
      this._formGroup.markAllAsTouched();
      this._hasBlockingErrors = true;
      return false;
    }
    return true;
  }

  /**
   * Loads the form into the abstract class to make it visible to the other methods
   * Since Javascript saves objects via memory references, the _formGroup property on the
   * abstract class and the specific form are synced
   *
   * @author Christian Tweed
   */
  protected _loadForm(form: FormGroup): void {
    this._formGroup = form;
  }
}
