import { environment } from "@environments/environment";
import { apiGamePlatforms } from "src/app/enums/game-platforms.enum";

import { OrganizationSeasonPassRegistrationType } from "src/app/enums/registration-types.enum";
import { StatesAbbreviations } from "src/app/enums/states.enum";
import { OrganizationSearchQuery } from "src/app/reducers/organization/organization.types";
import { defaultSearchQuery, SearchTerms } from "src/app/util/search-utils";


export interface OrganizationAPIResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      name: string;
      logoUrl: string | null;
      websiteUrl: string | null;
      facebookUrl: string | null;
      instagramUrl: string | null;
      twitterUrl: string | null;
      leagues: {
        data: OrganizationAPILeague[];
      };
      seasonPassesAcceptingRegistration: {
        data: OrganizationAPISeasonPass[];
      };
      availableEsports: {
        data: OrganizationAPIEsport[];
      };
    };
  };
}

export interface OrganizationAPILeague {
  id: string;
  type: string;
  attributes: {
    description: string;
    entrants: number;
    esport: apiGamePlatforms;
    finished: boolean;
    registrationEndDate: number;
    startTime: number;
    timeZone: string;
    title: string;
    defaultTeamFee: string;
  };
}

export interface OrganizationAPISeasonPass {
  id: string;
  type: "organizationSeasonPasses";
  attributes: {
    price: number;
    registrationType: OrganizationSeasonPassRegistrationType;
    title: string;
  };
}

export interface OrganizationAPIEsport {
  id: string;
  type: "esports";
  attributes: {
    name: apiGamePlatforms;
  };
}

export interface OrganizationSearchAPIResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      name: string;
      slug: string;
      state: StatesAbbreviations;
      logoUrl: string;
    };
  }[];
}

export interface OrganizationSearchTerms extends SearchTerms {
  name?: string;
  state_matches?: string;
  postal_code?: string;
}


export const defaultOrganizationSearchQuery: OrganizationSearchQuery = {
  ...defaultSearchQuery,
  organizations: [],
};

export const searchOrganizationURL = `${environment.apiBase}/api/v1/search/organizations`;
