<a
  [routerLink]="getTeamDetailRoute(team.id)"
  class="c_teamBlock__teamLinkWrapper"
>
  <div
    class="c_teamBlock__team"
    [ngClass]="{ 'c_teamBlock__team--centered': centered }"
  >
    <div
      class="c_teamBlock__icon"
      [ngStyle]="{ 'background-image': team.logoUrl }"
    ></div>
    <div class="c_teamBlock__teamTitle">
      <strong>
        {{ team.title }}
      </strong>
      <br />
      <em>{{ showTeamType(team.teamType) }}</em>
    </div>
  </div>
</a>
