<div class="c_addCommentBlock">
  <textarea
    *ngIf="chatActive; else inactiveChat"
    maxlength="300"
    placeholder="Chat with players"
    [(ngModel)]="commentText"
    (keyup.enter)="sendComment()"
    class="c_addCommentBlock__textarea u_txt--body"
  ></textarea>
  <ng-template #inactiveChat>
    <textarea
      maxlength="300"
      placeholder="This chat is not active"
      class="c_addCommentBlock__textarea u_txt--body"
      disabled
    ></textarea>
  </ng-template>
  <div class="c_addCommentBlock__underBlock">
    <small class="c_addCommentBlock__infoLink">
      All chat messages must follow the
      <a [href]="playerGuides.CODE_OF_CONDUCT" target="_blank" rel="noreferrer"
        >GGLeagues Code of Conduct</a
      >
    </small>
    <span
      class="c_addCommentBlock__count"
      [ngClass]="{
        'c_addCommentBlock__count--max': commentText.length === 300
      }"
    >
      {{ commentText.length }} / 300
    </span>
  </div>
  <p class="c_input__error" *ngIf="shortCommentText">
    Your message is too short
  </p>
  <p class="c_input__error" *ngIf="chatMessageLobbyCodeError">
    You cannot post the lobby code in chat
  </p>
  <p class="c_input__error" *ngIf="chatInactive">This chatroom is closed</p>
  <div class="c_addCommentBlock__buttons">
    <div class="c_addCommentBlock__button" *ngIf="showContactAdmin">
      <app-contact-admin-button></app-contact-admin-button>
    </div>
    <button
      class="c_button primary_dark c_addCommentBlock__button"
      (click)="sendComment()"
    >
      Submit
    </button>
  </div>
</div>
