import { Component, Input } from "@angular/core";
import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import { NgxSmartModalService } from "ngx-smart-modal";

import { RocketLeagueHelpModalComponent } from "./rocket-league-help-modal/rocket-league-help-modal.component";
import { OverwatchHelpModalComponent } from "./overwatch-help-modal/overwatch-help-modal.component";
import { LeagueOfLegendsHelpModalComponent } from "./league-of-legends-help-modal/league-of-legends-help-modal.component";
import { FifaHelpModalComponent } from "./fifa-help-modal/fifa-help-modal.component";
import { modalOptions } from "src/app/util/modal-helpers";
import { GenericHelpModalComponent } from "./generic-help-modal/generic-help-modal.component";
import { FortniteBattleRoyaleHelpModalComponent } from "../fortnite-battle-royale-help-modal/fortnite-battle-royale-help-modal.component";
import { FortniteHelpModalComponent } from "./fortnite-help-modal/fortnite-help-modal.component";
// eslint-disable-next-line max-len
import { RocketLeagueTournamentHelpModalComponentComponent } from "../rocket-league-tournament-help-modal-component/rocket-league-tournament-help-modal-component.component";

@Component({
  selector: "app-esport-help-modal",
  templateUrl: "./esport-help-modal.component.html",
  styleUrls: ["./esport-help-modal.component.scss"],
})
export class EsportHelpModalComponent {
  @Input() public esport: gamePlatforms;
  @Input() public isBattleRoyaleMode = false;
  @Input() public isDark = true;
  @Input() public isSecondary = false;

  public gamePlatforms = gamePlatforms;

  public validGames = [
    gamePlatforms.ROCKET_LEAGUE,
    gamePlatforms.LEAGUE_OF_LEGENDS,
    gamePlatforms.OVERWATCH,
  ];

  constructor(private _modalService: NgxSmartModalService) { }

  public openModal() {
    if (this.esport) {
      if (this.isBattleRoyaleMode) {
        switch (this.esport) {
          case gamePlatforms.ROCKET_LEAGUE:
            this._modalService.create("esportHelp", RocketLeagueTournamentHelpModalComponentComponent, modalOptions).open();
            break;
          case gamePlatforms.FORTNITE:
            this._modalService.create("esportHelp", FortniteBattleRoyaleHelpModalComponent, modalOptions).open();
            break;
          default:
            this._modalService.create("esportHelp", GenericHelpModalComponent, modalOptions).open();
            break;
        }
      } else {
        switch (this.esport) {
          case gamePlatforms.EA_FIFA:
            this._modalService.create("esportHelp", FifaHelpModalComponent, modalOptions).open();
            break;
          case gamePlatforms.LEAGUE_OF_LEGENDS:
            this._modalService.create("esportHelp", LeagueOfLegendsHelpModalComponent, modalOptions).open();
            break;
          case gamePlatforms.OVERWATCH:
            this._modalService.create("esportHelp", OverwatchHelpModalComponent, modalOptions).open();
            break;
          case gamePlatforms.ROCKET_LEAGUE:
            this._modalService.create("esportHelp", RocketLeagueHelpModalComponent, modalOptions).open();
            break;
          // case gamePlatforms.DOTA_2:
          // 	dialogRef = this._modal.open(DotaHelpModalComponent);
          // 	break;
          case gamePlatforms.FORTNITE:
            this._modalService.create("esportHelp", FortniteHelpModalComponent, modalOptions).open();
            break;
          // case gamePlatforms.EA_MADDEN:
          // 	dialogRef = this._modal.open(MaddenHelpModalComponent);
          // 	break;
          // case gamePlatforms.EA_NHL:
          // 	dialogRef = this._modal.open(NhlHelpModalComponent);
          // 	break;
          // case gamePlatforms.VALORANT:
          // 	dialogRef = this._modal.open(ValorantHelpModalComponent);
          // 	break;
          case gamePlatforms.OTHER:
          default:
            this._modalService.create("esportHelp", GenericHelpModalComponent, modalOptions).open();
            break;
        }
      }
    }
  }
}
