import { Action } from "@ngrx/store";

import { EsportUserCredentialsAttributes, UserUpdateForm } from "@services/user/user.api.types";

import {
  UserLoginToken,
  UserLogin,
  UserProfile,
  PersonalUserProfile,
} from "./user.types";

export enum UserActionTypes {
  LOGIN_USER = "[User] Login user",
  LOGIN_NEW_USER = "[User] Login first-time user",
  RESTORE_USER = "[User] Restoring Prior Logged-in user",
  LOGIN_USER_ERROR = "[User] Login user error",
  LOGIN_USER_SUCCESS = "[User] Login user success",
  LOGIN_NEW_USER_SUCCESS = "[User] Login new user success",
  RESTORE_USER_SUCCESS = "[User] Restore user success",
  LOGOUT_USER = "[User] Log out user",
  UPDATE_USER = "[User] Update user info",
  UPDATE_USER_SUCCESS = "[User] User info update success",
  UPDATE_USER_ERROR = "[User] User info update error",
  FETCH_PROFILE = "[User] Fetch user profile",
  FETCH_PROFILE_ERROR = "[User] Fetch user profile error",
  FETCH_PROFILE_SUCCESS = "[User] Fetch user profile success",
  CLEAR_FETCHED_PROFILE = "[User] Clear Fetched User Profile",
  STASH_LOGIN_REDIRECT = "[User] Stash Login Redirect Route",
  CLEAR_LOGIN_ROUTE = "[User] Clear Login Route",
  FETCH_PROFILE_BY_ID = "[User] Fetch user profile by id",
  FETCH_PROFILE_BY_ID_ERROR = "[User] Fetch user profile by id error",
  FETCH_PROFILE_BY_ID_SUCCESS = "[User] Fetch user profile by id success",
  REFRESH_USER_PROFILE = "[User] Refresh the user's profile",
  REFRESH_USER_PROFILE_SUCCESS = "[User] Refreshed the profile",
  REFRESH_USER_PROFILE_ERROR = "[User] Could not refresh the profile",
  UPDATE_ESPORT_CREDENTIALS = "[User] Update user esport credentials",
  UPDATE_ESPORT_CREDENTIALS_ERROR = "[User] Could not update user esport credentials",
  UPDATE_ESPORT_CREDENTIALS_SUCCESS = "[User] Update user esport credentials success",
  PATCH_CURRENT_USER_DATA = "[User] Update current user data",
}

export class LoginUser implements Action {
  public readonly type = UserActionTypes.LOGIN_USER;
  constructor(
    public payload: {
      credentials: UserLogin;
      isRedirect?: boolean;
      isSignup?: boolean;
    }
  ) { }
}

export class LoginNewUser implements Action {
  public readonly type = UserActionTypes.LOGIN_NEW_USER;
  constructor(public payload: { credentials: UserLogin; clubId?: string }) { }
}

export class RestoreUserLogin implements Action {
  public readonly type = UserActionTypes.RESTORE_USER;
  constructor(public payload: UserLoginToken) { }
}

export class LoginUserError implements Action {
  public readonly type = UserActionTypes.LOGIN_USER_ERROR;
  constructor(public payload: void) { }
}

export class LoginUserSuccess implements Action {
  public readonly type = UserActionTypes.LOGIN_USER_SUCCESS;
  constructor(public payload: PersonalUserProfile) { }
}

export class LoginNewUserSuccess implements Action {
  public readonly type = UserActionTypes.LOGIN_NEW_USER_SUCCESS;
  constructor(public payload: PersonalUserProfile) { }
}

export class RestoreUserSuccess implements Action {
  public readonly type = UserActionTypes.RESTORE_USER_SUCCESS;
  constructor(public payload: PersonalUserProfile) { }
}

export class LogOutUser implements Action {
  public readonly type = UserActionTypes.LOGOUT_USER;
  constructor(public payload: void) { }
}
export class UpdateUser implements Action {
  public readonly type = UserActionTypes.UPDATE_USER;
  constructor(public payload: [UserUpdateForm, number]) { }
}
export class UpdateUserSuccess implements Action {
  public readonly type = UserActionTypes.UPDATE_USER_SUCCESS;
  //TODO: Make ajax compatible, but for now dispatch another event to get the user
  constructor(public payload: void) { }
}
export class UpdateUserError implements Action {
  public readonly type = UserActionTypes.UPDATE_USER_ERROR;
  constructor(public payload: void) { }
}

export class FetchUserProfile implements Action {
  public readonly type = UserActionTypes.FETCH_PROFILE;
  constructor(public payload: void) { }
}

export class FetchUserProfileSuccess implements Action {
  public readonly type = UserActionTypes.FETCH_PROFILE_SUCCESS;
  constructor(public payload: PersonalUserProfile) { }
}

export class FetchUserProfileError implements Action {
  public readonly type = UserActionTypes.FETCH_PROFILE_ERROR;
  constructor(public payload: void) { }
}

export class ClearFetchedUser implements Action {
  public readonly type = UserActionTypes.CLEAR_FETCHED_PROFILE;
  constructor(public payload: void) { }
}

export class StashLoginRoute implements Action {
  public readonly type = UserActionTypes.STASH_LOGIN_REDIRECT;
  constructor(public payload: string) { }
}

export class ClearLoginRoute implements Action {
  public readonly type = UserActionTypes.CLEAR_LOGIN_ROUTE;
  constructor(public payload: void) { }
}

export class FetchProfileById implements Action {
  public readonly type = UserActionTypes.FETCH_PROFILE_BY_ID;
  constructor(public payload: number) { }
}

export class FetchProfileByIdError implements Action {
  public readonly type = UserActionTypes.FETCH_PROFILE_BY_ID_ERROR;
  constructor(public payload: void) { }
}

export class FetchProfileByIdSuccess implements Action {
  public readonly type = UserActionTypes.FETCH_PROFILE_BY_ID_SUCCESS;
  constructor(public payload: UserProfile) { }
}

export class RefreshUserProfile implements Action {
  public readonly type = UserActionTypes.REFRESH_USER_PROFILE;
  constructor(public payload: void) { }
}

export class RefreshUserProfileSuccess implements Action {
  public readonly type = UserActionTypes.REFRESH_USER_PROFILE_SUCCESS;
  constructor(public payload: PersonalUserProfile) { }
}

export class RefreshUserProfileError implements Action {
  public readonly type = UserActionTypes.REFRESH_USER_PROFILE_ERROR;
  constructor(public payload: void) { }
}

export class UpdateEsportCredentials implements Action {
  public readonly type = UserActionTypes.UPDATE_ESPORT_CREDENTIALS;
  constructor(public payload: EsportUserCredentialsAttributes[]) { }
}

export class UpdateEsportCredentialsError implements Action {
  public readonly type = UserActionTypes.UPDATE_ESPORT_CREDENTIALS_ERROR;
  constructor(public payload: void) { }
}

export class UpdateEsportCredentialsSuccess implements Action {
  public readonly type = UserActionTypes.UPDATE_ESPORT_CREDENTIALS_SUCCESS;
  constructor(public payload: void) { }
}

export class PatchCurrentUserData implements Action {
  public readonly type = UserActionTypes.PATCH_CURRENT_USER_DATA;
  constructor(public payload: Partial<UserProfile>) { }
}

export type UserActions =
  | LoginUser
  | LoginNewUser
  | LoginUserError
  | LoginUserSuccess
  | LoginNewUserSuccess
  | LogOutUser
  | RestoreUserLogin
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserError
  | FetchUserProfile
  | FetchUserProfileSuccess
  | FetchUserProfileError
  | ClearFetchedUser
  | StashLoginRoute
  | ClearLoginRoute
  | RestoreUserSuccess
  | FetchProfileById
  | FetchProfileByIdError
  | FetchProfileByIdSuccess
  | RefreshUserProfile
  | RefreshUserProfileError
  | RefreshUserProfileSuccess
  | UpdateEsportCredentials
  | UpdateEsportCredentialsError
  | UpdateEsportCredentialsSuccess
  | PatchCurrentUserData;
