import { MatchCommenterIDUsernameFlatMap } from "src/app/reducers/matches/matches.types";

/**
 * Takes a new set of match commenters and adds or replaces in the previous set of encountered usernames
 *
 * @param newMatchCommenters [userid, username][]
 * @param oldMatchCommenters [userid, username][]
 * @author Christian Tweed
 */
export const addMatchCommenters = (
  newMatchCommenters: MatchCommenterIDUsernameFlatMap,
  oldMatchCommenters: MatchCommenterIDUsernameFlatMap
): MatchCommenterIDUsernameFlatMap => {
  const matchCommenterMap = new Map<string, string>(oldMatchCommenters);
  for(const matchCommenter of newMatchCommenters){
    matchCommenterMap.set(matchCommenter[0], matchCommenter[1]);
  }
  return Array.from(matchCommenterMap);
};
