import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { TeamsService } from "@services/teams.service";

import { ComponentRouteParams } from "src/app/enums/routes/routeParams";
import { generateTeamDetailRoute, routePaths } from "src/app/enums/routes/routePaths";
import { Logger } from "src/app/util/logger";
@Component({
  selector: "app-team-instant-invite",
  templateUrl: "./team-instant-invite.component.html",
  styleUrls: ["./team-instant-invite.component.scss"],
})
export class TeamInstantInviteComponent implements OnInit {
  public inviteHasError = false;
  public isLoading = true;
  public homeRoute = routePaths.HOME;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _teamsService: TeamsService,
    private _toastr: ToastrService,
    private _title: Title
  ) {}

  public async ngOnInit(): Promise<void> {
    this._title.setTitle("GGLeagues | Instant Invite");
    const inviteCode = this._route.snapshot.paramMap.get(
      ComponentRouteParams.INSTANT_INVITE
    );

    if (inviteCode) {
      try {
        const inviteTeam = await this._teamsService
          .joinTeamWithCode(inviteCode)
          .toPromise();
        this._toastr.success(
          `You have successfully been added to ${inviteTeam.teamName}`,
          "Success!"
        );
        this._router.navigate([generateTeamDetailRoute(inviteTeam.teamId)]);
      } catch (e) {
        Logger.error(e);
        if (e.status === 403) {
          const errorMessage = e.error.errors[0][0].replace(
            "User is",
            "You are"
          );
          this._toastr.error(errorMessage, "Error");
        } else {
          this._toastr.error("Could not join the team", "Error");
        }
        this.isLoading = false;
        this.inviteHasError = true;
      }
    }
  }
}
