import {
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { Store } from "@ngrx/store";
import {
  filter,
  take,
  takeUntil,
} from "rxjs/operators";
import { Observable, of } from "rxjs";
import { NgxSmartModalService } from "ngx-smart-modal";

import { TeamRosterUpdateActions } from "src/app/reducers/teams/teams.types";
import { RootState } from "src/app/reducers";
import { GenericRosterMember } from "src/app/reducers/leagues/league.types";
import { UpdateTeamRoster } from "src/app/reducers/teams/teams.actions";
import { Unsubscriber } from "src/app/util/unsubscriber";
import { RemovePlayerModalComponent } from "../remove-player-modal/remove-player-modal.component";
import { modalOptions } from "src/app/util/modal-helpers";

@Component({
  selector: "app-team-roster-edit-block",
  templateUrl: "./team-roster-edit-block.component.html",
  styleUrls: ["./team-roster-edit-block.component.scss"],
})
export class TeamRosterEditBlockComponent implements OnInit, OnDestroy {
  public isRosterUpdating$: Observable<boolean> = of(false);
  public teamStarters$: Observable<GenericRosterMember[]> = of([]);
  public teamBench$: Observable<GenericRosterMember[]> = of([]);

  private _unsub = new Unsubscriber();

  constructor(
    private _store: Store<RootState>,
    private _modalService: NgxSmartModalService
  ) {}

  public ngOnInit() {
    this._store
      .select("teams", "currentTeam")
      .pipe(
        filter((team) => team !== null),
        takeUntil(this._unsub.unsubEvent)
      )
      .subscribe((team) => {
        this.teamStarters$ = of(team.members.starters);
        this.teamBench$ = of(team.members.bench);
      });

    this.isRosterUpdating$ = this._store
      .select("teams", "isUpdatingRoster")
      .pipe(takeUntil(this._unsub.unsubEvent));
  }

  public ngOnDestroy() {
    this._unsub.kill();
  }

  public openRemovePlayerModal(player: GenericRosterMember) {
    const removeModal = this._modalService.create("removePlayer", RemovePlayerModalComponent, modalOptions);
    removeModal.setData(player).open();
    // Sets listener to clear the passed-in modal data on close
    removeModal.onAnyCloseEvent.pipe(
      take(1)
    ).subscribe(
      () => {
        this._modalService.resetModalData("removePlayer");
      }
    );
  }

  public benchPlayer(playerId: string): void {
    this._store.dispatch(
      new UpdateTeamRoster({
        teamUserId: +playerId,
        rosterAction: TeamRosterUpdateActions.BENCH_PLAYER,
      })
    );
  }

  public activatePlayer(playerId: string): void {
    this._store.dispatch(
      new UpdateTeamRoster({
        teamUserId: +playerId,
        rosterAction: TeamRosterUpdateActions.ACTIVATE_PLAYER,
      })
    );
  }
}
