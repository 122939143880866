import {
  Component,
  Input,
  OnInit,
} from "@angular/core";
import {
  map,
  filter,
  take,
} from "rxjs/operators";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";

import { UserProfile } from "src/app/reducers/user/user.types";
import { RootState } from "src/app/reducers";
import { processImage, ImageTypes } from "../../util/image-utils";
import { userRoutes } from "src/app/enums/routes/routePaths";

@Component({
  selector: "app-player-info-block",
  templateUrl: "./player-info-block.component.html",
  styleUrls: ["./player-info-block.component.scss"],
})
export class PlayerInfoBlockComponent implements OnInit {
  @Input() public user: UserProfile;
  public userCanEdit$: Observable<boolean>;
  public userImage: string;
  public userRoutes = userRoutes;

  constructor(private _store: Store<RootState>) {}

  public ngOnInit() {
    this.userCanEdit$ = this._store.select("user", "currentUser").pipe(
      filter((user) => user !== null),
      map((currUser) => {
        if (this.user && currUser.id === this.user.id) {
          return true;
        }
        return false;
      }),
      take(1)
    );

    this.userImage = processImage(this.user.profilePicture, ImageTypes.USER_SQ);
  }
}
