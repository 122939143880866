import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

import { environment } from "../../../environments/environment";
import { basicAuthHeader } from "../../util/auth-utils";
import {
  GenericSeriesMatchup,
  SeriesMatchupV2,
  SeriesMatchupV2League,
  SeriesMatchupV2Match,
  SeriesMatchupV2Player,
  SeriesMatchupV2Team,
  SeriesMatchupV2Tournament,
} from "../../reducers/matches/matches.types";
import { Logger as logger } from "../../util/logger";
import { GenericLeagueTeam } from "../../reducers/leagues/league.types";
import { apiToPlatform } from "../../enums/game-platforms.enum";
import {
  SeriesMatchupAPIResponse,
  SeriesMatchupAPIV2Response,
  SeriesMatchupV2APILeague,
  SeriesMatchupV2APIMatch,
  SeriesMatchupV2APIPlayer,
  SeriesMatchupV2APITeam,
  SeriesMatchupV2APITeamRecords,
  SeriesMatchupV2APITournament,
} from "./matches.api.types";

@Injectable({
  providedIn: "root",
})
export class MatchesService {
  constructor(private _http: HttpClient) { }

  public getSeriesMatchupById(seriesMatchupId: string): Observable<GenericSeriesMatchup> {
    const url = `${environment.apiBase}/api/v1/series_matchups/${seriesMatchupId}`;
    const headers = basicAuthHeader();
    return this._http.get<SeriesMatchupAPIResponse>(url, {
      headers,
    }).pipe(map((apiResponse) => {
      const res = this._mapSeriesMatchup(apiResponse);
      return res;
    }));
  }

  public getSeriesMatchupV2ById(id: string | number): Observable<SeriesMatchupV2> {
    const url = `${environment.apiBase}/api/v2/series_matchups/${id}`;
    const headers = basicAuthHeader();
    return this._http.get<SeriesMatchupAPIV2Response>(url, {
      headers,
    }).pipe(map((apiResponse) => this._mapSeriesMatchupV2(apiResponse)));
  }

  public disputeResults(seriesMatchupsId: string, message: string): Observable<unknown> {
    const url = `${environment.apiBase}/api/v1/series_matchups/${seriesMatchupsId}/dispute_match`;
    const headers = basicAuthHeader();
    const payload = {
      series_matchup: {
        dispute_message: message,
      },
    };
    return this._http
      .post(url, payload, {
        headers,
      })
      .pipe(
        catchError((err) => {
          logger.error(err);
          return of(null);
        })
      );
  }

  public checkinToMatch(seriesMatchupId: string, gameNumber: number, teamId: string): Observable<{ success: true }> {
    // eslint-disable-next-line max-len
    const url = `${environment.apiBase}/api/v2/series_matchups/${seriesMatchupId}/battle_royale_matchup_games/${gameNumber}/battle_royale_matchup_checkins`;
    const headers = basicAuthHeader();
    const payload = {
      battle_royale_matchup_checkin: {
        team_id: teamId,
      },
    };
    return this._http.post<{ success: true }>(url, payload, {
      headers,
    });
  }

  private _getGenericLeagueTeamsFromSeriesMatchups(apiResponse: SeriesMatchupAPIResponse): GenericLeagueTeam[] {
    const teamRecords = Object.entries(apiResponse.data.attributes.teamRecords);
    return apiResponse.data.attributes.teams.data.map((apiTeam) => {
      const teamRecord = teamRecords.find((team) => team[0] === apiTeam.id);
      return {
        id: apiTeam.id,
        name: apiTeam.attributes.title,
        wins: teamRecord[1]?.wins,
        losses: teamRecord[1]?.losses,
        winner: apiResponse.data.attributes.winnerId === null ? null : apiTeam.id === apiResponse.data.attributes.winnerId.toString(),
        members: apiTeam.attributes.players.data
          .map((player) => ({
            id: player.id,
            type: player.type,
            inGameName: player.attributes.inGameName,
          }))
          .filter((player) => player.id !== apiTeam.attributes.captain.data.id),
        captain: {
          isCaptain: true,
          id: apiTeam.attributes.captain.data.id,
          inGameName: apiTeam.attributes.captain.data.attributes.inGameName,
        },
      };
    });
  }

  private _mapSeriesMatchup(apiResponse: SeriesMatchupAPIResponse): GenericSeriesMatchup {
    return {
      id: apiResponse.data.id,
      type: apiResponse.data.type,
      dateTimeStart: apiResponse.data.attributes.datetimeStart,
      winnerId: apiResponse.data.attributes.winnerId !== null ? apiResponse.data.attributes.winnerId.toString() : null,
      seriesGameType: apiResponse.data.attributes.tournament.data.attributes.schedulingType,
      league: {
        id: apiResponse.data.attributes.league.data.id,
        type: apiResponse.data.attributes.league.data.type,
        esport: apiToPlatform(apiResponse.data.attributes.league.data.attributes.esport),
        title: apiResponse.data.attributes.league.data.attributes.title,
      },
      tournament: {
        id: apiResponse.data.attributes.tournament.data.id,
        type: apiResponse.data.attributes.tournament.data.type,
        title: apiResponse.data.attributes.tournament.data.attributes.title,
      },
      matches: apiResponse.data.attributes.matches?.data.map((apiMatch) => ({
        id: apiMatch.id,
        type: apiMatch.type,
        teamOneId: apiMatch.attributes.teamOneId.toString(),
        teamTwoId: apiMatch.attributes.teamTwoId.toString(),
        title: apiMatch.attributes.title,
        lobby: apiMatch.attributes.lobby,
        lobbyPw: apiMatch.attributes.lobbyPw,
        winnerId: apiMatch.attributes.winnerId !== null ? apiMatch.attributes.winnerId.toString() : null,
      })),
      teams: this._getGenericLeagueTeamsFromSeriesMatchups(apiResponse),
    };
  }

  private _mapSeriesMatchupV2(apiResponse: SeriesMatchupAPIV2Response): SeriesMatchupV2 {
    const { id, type, attributes } = apiResponse.data;
    const { title, teamRecords, matchupStartTime, twitchStreamUrl } = attributes;
    const esport = apiToPlatform(attributes.esport);
    const winnerId = attributes.winnerId?.toString() ?? null;
    const league = this._mapSeriesMatchupLeague(attributes.league.data);
    const teams = attributes.teams.data.map((apiTeam) => this._mapSeriesMatchupTeam(apiTeam, teamRecords));
    const matches = attributes.matches ? attributes.matches.data.map((apiMatch) => this._mapSeriesMatchupMatch(apiMatch)) : [];
    const tournament = attributes.tournament ? this._mapSeriesMatchupTournament(attributes.tournament.data) : null;
    const firebaseStreamId = attributes.streamReferenceId;
    const seriesMatchType = attributes.tournament ? attributes.tournament.data.attributes.schedulingType : null;
    const chatroomReferenceId = attributes.chatroomReferenceId;
    return {
      id,
      type,
      esport,
      league,
      matches,
      firebaseStreamId,
      chatroomReferenceId,
      matchupStartTime,
      teams,
      seriesMatchType,
      tournament,
      title,
      winnerId,
      twitchStreamUrl,
    };
  }

  private _mapSeriesMatchupPlayer(apiPlayer: SeriesMatchupV2APIPlayer, captainId: string): SeriesMatchupV2Player {
    const { id, type, attributes } = apiPlayer;
    const { esportCredential, username } = attributes;
    const isCaptain = id === captainId;

    return {
      id,
      type,
      esportCredential,
      username,
      isCaptain,
    };
  }

  private _mapSeriesMatchupLeague(apiLeague: SeriesMatchupV2APILeague): SeriesMatchupV2League {
    const { id, type, attributes } = apiLeague;
    const { title } = attributes;
    return {
      id,
      type,
      title,
    };
  }

  private _mapSeriesMatchupTeam(apiTeam: SeriesMatchupV2APITeam, apiTeamRecords: SeriesMatchupV2APITeamRecords): SeriesMatchupV2Team {
    const { id, type, attributes } = apiTeam;
    const { wins, losses } = apiTeamRecords[+id];
    const { logoUrl, teamType, title, captain } = attributes;
    const captainId = captain ? captain.data.id : null;
    const players = attributes.players ?
      attributes.players.data.map((apiPlayer) => this._mapSeriesMatchupPlayer(apiPlayer, captainId)) :
      [];
    return {
      id,
      type,
      wins,
      losses,
      logoUrl,
      teamType,
      title,
      players,
    };
  }

  private _mapSeriesMatchupMatch(apiMatch: SeriesMatchupV2APIMatch): SeriesMatchupV2Match {
    const { id, type, attributes } = apiMatch;
    const { lobby, lobbyPw } = attributes;
    const teamOneId = apiMatch.attributes.teamOneId?.toString() ?? "";
    const teamTwoId = apiMatch.attributes.teamTwoId?.toString() ?? "";
    const winnerId = apiMatch.attributes.winnerId?.toString() ?? null;
    return {
      id,
      type,
      lobby,
      lobbyPw,
      teamOneId,
      teamTwoId,
      winnerId,
    };
  }

  private _mapSeriesMatchupTournament(apiTournament: SeriesMatchupV2APITournament): SeriesMatchupV2Tournament {
    const { id, type, attributes } = apiTournament;
    const { title, status, schedulingType } = attributes;
    return {
      id,
      type,
      title,
      status,
      schedulingType,
    };
  }
}
