import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";

import {
  gameCredentialsHelpLinks,
  gameCredentialTitles,
  gamePlatforms,
} from "src/app/enums/game-platforms.enum";
import { ModalController } from "../modal-controller/modal-controller.abstract";

@Component({
  selector: "app-game-credential-modal",
  templateUrl: "./game-credential-modal.component.html",
  styleUrls: ["./game-credential-modal.component.scss"],
})
export class GameCredentialModalComponent extends ModalController<gamePlatforms> {
  public static MODAL_ID = "gameCredential";

  constructor(private _modalService: NgxSmartModalService, private _router: Router) {
    super(_modalService, GameCredentialModalComponent.MODAL_ID, _router);
  }

  public get esportTitle(): string {
    return gameCredentialTitles.get(this.getData()) ?? "Tag";
  }

  public get helpLinks(): string[] {
    return gameCredentialsHelpLinks.get(this.getData()) ?? [];
  }
}
