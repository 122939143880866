<app-static-page pageTitle="Welcome to GGLeagues!">
  <article class="c_newUserPage">
    <section>
      <h2 class="c_txt--hdg2">Quick Start</h2>
      <p>
        Get ready to become a local legend in your community! Before you’re
        ready to start competing, you’ll need to follow several steps to get
        ready for your league:
      </p>
    </section>
    <hr />
    <section>
      <h2 class="c_text--hdg2">Step 1: Find your organization or league!</h2>
      <ol>
        <li>
          If you are registering via an organization, such as a Community park
          district, or school community, you can locate your organization via
          our <a routerLink="/organization/search">Organization Search Page</a>
        </li>
        <li>
          Simply search for the name of the organization you wish to compete
          with. Once your organization has been found, navigate to ‘Get a Pass!’
          button in the middle of the page to purchase your season esports pass!
          <strong>
            If you have already purchased a season pass via your community,
            proceed to Step 2 on redeeming your pass!
          </strong>
        </li>
      </ol>
      <h2 class="c_text--hdg2">Step 2: Purchasing your Esports Season Pass!</h2>
      <p>
        In order to participate in any event here at GGLeagues, you will need to
        have an esports pass.
        <br /><br />
        You can purchase an esports pass in one of two ways. The first way will
        be to find the organization you would like to register through and
        purchase the pass directly through the organization page, as noted
        above.
        <a routerLink="/organization/search">Organization Search Page</a>
        <br /><br />
        <strong
          >If you are registering directly through your community:
        </strong>
        Upon completion of registration within your community, your community
        will provide you with a code to redeem your pass. Navigate to your
        organization page, and hit the ‘Get a [community] pass!’ button on the
        middle of the screen. Once on the pass page, hit ‘I have already
        purchased a pass and need to activate it’. <br /><br />
        From there, input the code given by your community - then you’re all
        set!
      </p>
      <h2 class="c_text--hdg2">Step 3: Join your league!</h2>
      <p>
        Registration steps will vary depending on whether you’d like to
        participate in a <strong>single player league</strong>, or a
        <strong>multiplayer league</strong>.
      </p>
      <ul>
        <li>
          <strong>For Single Player Leagues</strong>
          <ul>
            <li>
              On your Organization’s page, click on the page of the league you
              would like to participate in, then “join league”. The website will
              confirm that you have a season pass - Hit ‘Join League’ again, and
              you will be all set to go!
            </li>
            <li>
              If you don’t have a season pass, please refer to
              <strong>Step 2</strong>.
            </li>
          </ul>
        </li>
        <li>
          <strong>For Multiplayer Leagues</strong>
          <ul>
            <li>
              On the GGLeagues website, navigate to ‘My Teams’ in the upper
              right corner
            </li>
            <li>
              If you are creating a team: Click ‘Create Your Own’ in the bottom
              right corner
            </li>
            <li>
              Name your team, and provide a description and photo if you want,
              then hit ‘Create Team’
            </li>
            <li>
              Players can then join your team by clicking your instant invite
              link, which is created by clicking the ‘Instant Invite Link’
              button in the top right corner
            </li>
            <li>
              Players can also join teams by clicking the 3 black bars in the
              top right corner of the screen, and going to ‘Find a team’ - from
              there, players will search for their team via the team name.
            </li>
            <li>
              Once your team has been found, you can ‘Request to Join Team’
            </li>
            <li>
              The team captain of the team will then need to accept requests to
              join the team via their team page
            </li>
            <li>
              Team captains will then register their newly created team to the
              league of their choice
            </li>
          </ul>
        </li>
      </ul>
    </section>
  </article>
</app-static-page>
