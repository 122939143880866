import { Component, Input } from "@angular/core";

@Component({
  selector: "app-form-loading-button",
  templateUrl: "./form-loading-button.component.html",
  styleUrls: ["./form-loading-button.component.scss"],
})
export class FormLoadingButtonComponent {
  @Input() public isLoading = false;
}
