import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-utils";
import { ChatData } from "@apptypes/chat/chat-data.interface";

import { CommentValidityErrors } from "../comment-validator/comment-validator.types";
import { CommentService } from "../comment.abstract.service";
import { RailsSeriesMatchupComment } from "../comment.api.types";

/**
 * A comment service to post series matchup comments to the rails application
 *
 * @author Christian Tweed
 */
@Injectable({
  providedIn: "any",
})
export class RailsSeriesMatchupCommentService extends CommentService {

  /**
   * Send a chat message to the rails series matchup comment route
   *
   * @param seriesMatchupId
   * @param _authorId is unused, but left in to match the position for the arguments
   * @param message
   */
  public postComment({ railsID: seriesMatchupId }: ChatData, _authorId: string, message: string): Observable<unknown> {
    const url = `${environment.apiBase}/api/v2/series_matchups/${seriesMatchupId}/match_chatrooms/message`;
    const options = {
      headers: basicAuthHeader(),
    };

    const messageError: false | CommentValidityErrors = this._commentValidator.isMessageInvalid(message);

    if (messageError) {
      this._logMessageError(messageError);
      return of(null);
    }

    const body = this._formatChatMessage(message);

    return this._http.post(url, body, options);
  }

  protected _formatChatMessage(body: string): RailsSeriesMatchupComment {
    return {
      message: {
        body,
      },
    };
  }
}
