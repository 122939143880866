<div class="c_leagueCheckinPanel">
  <h3 class="u_txt--hdg3">
    {{ queuePanelHeading }}
  </h3>
  <ng-container *ngIf="isQueueOpen">
    <div class="c_leagueCheckinPanel__queueInfo">
      <p>
        The queue <span class="u_txt--brand-dark">opened on</span>:<br /><strong
          >{{ queueStartTime }}</strong
        >
      </p>
      <p>
        The queue <span class="u_txt--warn">will close by</span>:<br /><strong
          >{{ queueCloseTime || "TBD" }}</strong
        >
      </p>
    </div>
    <p>
      {{ openQueueMessage }}
    </p>
    <div>
      <a [routerLink]="queueLink" class="c_button primary_dark">Join Queue</a>
    </div>
  </ng-container>
  <p *ngIf="!isQueueOpen">
    <ng-container *ngIf="!!activeSession">
      The queue is not open to join yet. It will open on
      <strong>{{ queueStartTime }}</strong>
    </ng-container>
    <ng-container *ngIf="!activeSession">
      <p>There are no remaining queues for this tournament</p>
    </ng-container>
  </p>
</div>
