<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
></app-modal-container>

<ng-template #title>
  <app-modal-title>You've Found Your Match!</app-modal-title>
</ng-template>

<ng-template #content>
  <p>Your match has been found, and we've redirected to your match page!</p>
  <p>Say hello to your opponent, and get ready to play your game!</p>
</ng-template>
