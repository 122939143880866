import { Pipe, PipeTransform } from "@angular/core";

import { Nullish } from "@apptypes/generic-nullish.types";

import { routeQueries } from "src/app/enums/routes/routeParams";

export interface LeagueContextQuery {
  [routeQueries.LEAGUE_ID]: string;
};

@Pipe({
  name: "leagueContextQuery",
})
export class LeagueContextQueryPipe implements PipeTransform {
  public transform(leagueId: Nullish<string | number>): LeagueContextQuery | null {
    return !!leagueId ? {
      league_id: leagueId.toString(),
    } : null;
  }
}
