<app-modal-container
  [modalTitle]="title"
  [modalContent]="content"
  [modalFooter]="footer"
></app-modal-container>

<ng-template #title>
  <app-modal-title>Instant Invite Link</app-modal-title>
</ng-template>

<ng-template #content>
  <div class="c_magicLinkComponent">
    <div class="c_magicLinkComponent__content">
      <p class="u_txt">
        This link can be sent to another player on GGLeagues to let them
        automatically join your team! If you would like to prevent the link from
        being used, you can disable it below.
      </p>
      <p class="u_txt">Only the captain can see this information</p>
      <form class="c_magicLinkComponent__form">
        <input
          id="copyCode"
          type="text"
          readonly
          value="{{ linkCode }}"
          [disabled]="!linkEnabled"
          (click)="copyToClipboard()"
        />
        <button
          class="c_button primary_dark c_magicLinkComponent__copyButton"
          (click)="copyToClipboard()"
        >
          <i class="far fa-copy"></i>
        </button>
      </form>
      <p class="u_txt c_magicLinkComponent__linkStatus">
        Instant Invite Link is currently
        <ng-container>
          <span
            *ngIf="linkEnabled"
            class="c_magicLinkComponent__linkStatus--enabled"
          >
            ENABLED
          </span>
          <span
            *ngIf="!linkEnabled"
            class="c_magicLinkComponent__linkStatus--disabled"
          >
            DISABLED
          </span>
        </ng-container>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #footer>
  <app-modal-footer-buttons>
    <button class="c_button primary_dark" (click)="changeStatus()">
      <span>
        <ng-container>
          <span *ngIf="linkEnabled"> Disable </span>
          <span *ngIf="!linkEnabled"> Enable </span>
        </ng-container>
        Invite Link
      </span>
    </button>
  </app-modal-footer-buttons>
</ng-template>
