import { Logger } from "@utils/logger";

// Refernce to Google Analytics
// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

export const registerNewUserDashWeekliesEvent = (): void => {
  try {
    gtag("event", "clicked_new_user_dash_weeklies", {
      event_category: "new_user_dash_interactions",
      event_label: "clicked_new_user_dash_weeklies",
      value: "clicked_new_user_dash_weeklies",
    });
    Logger.log(
      {
        event_category: "new_user_dash_interactions",
        event_label: "clicked_new_user_dash_weeklies",
        value: "clicked_new_user_dash_weeklies",
      }
    );
  } catch (e) {
    Logger.error("Analytics event failed to fire");
  }
};

export const registerNewUserDashPassEvent = (): void => {
  try {
    gtag("event", "clicked_new_user_dash_seasonPass", {
      event_category: "new_user_dash_interactions",
      event_label: "clicked_new_user_dash_seasonPass",
      value: "clicked_new_user_dash_seasonPass",
    });
    Logger.log(
      {
        event_category: "new_user_dash_interactions",
        event_label: "clicked_new_user_dash_seasonPass",
        value: "clicked_new_user_dash_seasonPass",
      }
    );
  } catch (e) {
    Logger.error("Analytics event failed to fire");
  }
};
