import { Injectable } from "@angular/core";

import { BadWordsService } from "../bad-words/bad-words.service";
import { CommentValidityErrors } from "./comment-validator.types";

/**
 * A service to validate chat messages.
 *
 * @author Christian Tweed
 */
@Injectable({
  providedIn: "root",
})
export class CommentValidatorService {

  constructor(private _badWordsService: BadWordsService) {}

  /**
   * Checks to see if the message is invalid.
   *
   * @returns false if the message is valid
   * @returns CommentValidityErrors if the message has an error
   * @param message
   */
  public isMessageInvalid(message: string | null | undefined): false | CommentValidityErrors {
    if(!message){
      return CommentValidityErrors.EMPTY;
    }

    if(this._isMessageTooLong(message)){
      return CommentValidityErrors.TOO_LONG;
    }

    if(this._isMessageProfane(message)){
      return CommentValidityErrors.PROFANE;
    }

    return false;
  }

  private _isMessageTooLong(message: string): boolean {
    return message.length > 35 && message.indexOf(" ") === -1;
  }

  private _isMessageProfane(message: string): boolean {
    return this._badWordsService.isProfane(message);
  }
}
