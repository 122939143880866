<div class="c_playoffBracketGroup" [style]="getBracketGridWidth()">
  <ng-container
    *ngFor="let bracketGroup of bracketArray; let indexGroup = index"
  >
    <div
      class="c_playoffColumn c_playoffColumn--r{{ indexGroup + 1 }}"
      [style]="getBracketColumnHeight()"
    >
      <p class="u_txt c_playoffColumn__roundTitle">
        {{ generateRoundTitle(bracketGroup[0].round) }}
      </p>
      <a
        *ngFor="let i of bracketGroup; let indexCell = index"
        class="c_playoffBracket__cellContainer"
        [ngClass]="{
          'c_playoffBracket__cellContainer--EMPTY': i.round === -1,
          even: indexCell % 2 === 0,
          odd: indexCell % 2 !== 0,
          last: indexGroup + 1 === bracketArray.length
        }"
        [routerLink]="getBracketGameLink(i.seriesMatchupId)"
      >
        <div
          class="c_playoffBracket__cell"
          [ngClass]="{
            'c_playoffBracket__cell--last': i.round === bracketArray.length
          }"
        >
          <div
            class="
              c_playoffBracket__cell__team c_playoffBracket__cell__team--one
            "
            [ngClass]="{
              'c_playoffBracket__cell__team--winner': i.winner === i.teamOne,
              'c_playoffBracket__cell__team--loser': i.winner === i.teamTwo
            }"
          >
            {{ i.teamOneTitle }}
            <span class="c_playoffBracket__cell__score">
              {{ getScoreValue(i.winner, i.teamOne) }}
            </span>
          </div>
          <div
            class="
              c_playoffBracket__cell__team c_playoffBracket__cell__team--two
            "
            [ngClass]="{
              'c_playoffBracket__cell__team--winner': i.winner === i.teamTwo,
              'c_playoffBracket__cell__team--loser': i.winner === i.teamOne
            }"
          >
            {{ i.teamTwoTitle }}
            <span class="c_playoffBracket__cell__score">
              {{ getScoreValue(i.winner, i.teamTwo) }}
            </span>
          </div>
        </div>
      </a>
    </div>
  </ng-container>
</div>
