<div
  class="c_expandableContent"
  [ngClass]="{
    'c_expandableContent--expanded': isShowingContent,
    'c_expandableContent--darkBackground': hasBackground && hasDarkbackground,
    'c_expandableContent--lightBackground': hasBackground && !hasDarkbackground
  }"
>
  <div class="c_expandableContent--buttonContainer">
    <div class="c_expandableContent--button" (click)="expandContent()">
      <i
        class="fas fa-angle-double-up c_expandableContent--button--icon"
        [ngClass]="{
          'c_expandableContent--button--icon--closed': !isShowingContent,
          'c_expandableContent--button--icon--open': isShowingContent
        }"
      ></i>
      <ng-content select="[button]"></ng-content>
    </div>
  </div>
  <div class="c_expandableContent--content">
    <ng-content></ng-content>
  </div>
</div>
