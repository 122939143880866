<ng-container *ngIf="match; else noMatch">
  <a
    class="c_dashboardMatchPreview__linkWrapper"
    [routerLink]="getSeriesMatchupRoute(match.matchId)"
  >
    <div class="c_dashboardMatchPreview">
      <h5 class="c_dashboardMatchPreview__heading u_txt--hdg5">
        <span>Match Time: </span>
        <strong class="c_dashboardMatchPreview__matchDetailsHighlight"
          >{{ match.matchTime | date: "MMM d @ h:mm a" }}
        </strong>
      </h5>
      <div class="c_dashboardMatchPreview__matchDetails">
        <img
          class="c_dashboardMatchPreview__matchDetails__esport"
          [src]="showEsportImage(match.esport)"
          alt="{{ match.esport }} logo"
        />
        <dl class="c_dashboardMatchPreview__matchDetails__information">
          <dt class="u_txt">Matchup:</dt>
          <dd class="u_txt c_dashboardMatchPreview__matchDetailsHighlight">
            <strong>
              {{ matchupDisplayText }}
            </strong>
          </dd>
        </dl>
      </div>
    </div>
  </a>
</ng-container>

<ng-template #noMatch>
  <div class="c_dashboardMatchPreview__noMatch">
    <i class="fas fa-3x fa-calendar-alt"></i>
    <p class="u_txt">
      You do not have any upcoming matches in this league at this time
    </p>
  </div>
</ng-template>
