import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ModalController } from "../modal-controller/modal-controller.abstract";

interface BeginQueueModalProps {
  setQueueStatus: (val: boolean) => void;
}

@Component({
  selector: "app-begin-queue-modal",
  templateUrl: "./begin-queue-modal.component.html",
  styleUrls: ["./begin-queue-modal.component.scss"],
})
export class BeginQueueModalComponent extends ModalController<BeginQueueModalProps> {

  public static MODAL_ID = "begin_queue_modal";

  constructor(private _modalService: NgxSmartModalService, private _router: Router) {
    super(_modalService, BeginQueueModalComponent.MODAL_ID, _router);
  }


  public declineQueue(): void {
    const { setQueueStatus } = this.getData();
    setQueueStatus(false);
    this._modalService.closeLatestModal();
  }

  public joinQueue(): void {
    const { setQueueStatus } = this.getData();
    setQueueStatus(true);
    this._modalService.closeLatestModal();
  }

}
