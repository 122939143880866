import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";

import { ToastrService } from "ngx-toastr";

import { PasswordService } from "@services/password.service";
import { Logger } from "../../util/logger";
import { pwValidatorGroup } from "src/app/util/password-validators";
import { userRoutes } from "src/app/enums/routes/routePaths";
import { ComponentRouteParams } from "src/app/enums/routes/routeParams";
import { FormController } from "src/app/components/form-controller/form-controller.abstract";

@Component({
  selector: "app-create-new-password-page",
  templateUrl: "./create-new-password-page.component.html",
  styleUrls: ["./create-new-password-page.component.scss"],
})
export class CreateNewPasswordPageComponent extends FormController implements OnInit {
  public resetSuccess = false;
  public resetPasswordForm: FormGroup;
  public routes = userRoutes;

  private _token: string = null;

  constructor(
    private _passwordService: PasswordService,
    private _route: ActivatedRoute,
    private _titleService: Title,
    private _toastr: ToastrService
  ) {
    super();
    this.resetPasswordForm = new FormGroup({
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(8),
        ...pwValidatorGroup,
      ]),
      showPW: new FormControl(false),
    });

    this._loadForm(this.resetPasswordForm);
  }

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | Reset Password");
    this._token = this._route.snapshot.paramMap.get(ComponentRouteParams.PASSWORD_TOKEN);
  }

  public emailInput(): string {
    try {
      return this.resetPasswordForm.value.email || "";
    } catch (e) {
      return "";
    }
  }

  public showPassword() {
    return this.resetPasswordForm.get("showPW").value;
  }

  public setNewPassword() {
    if (this._canProceed()) {
      const newPassword = this.resetPasswordForm.value.password;

      if (!newPassword) {
        this._toastr.error("You must provide a new password", "No password provided");
        return;
      }

      if (!this._token) {
        this._toastr.error("Your reset token is invalid or expired", "Invalid Token");
        return;
      }

      this._passwordService.setNewUserPassword(newPassword, this._token).subscribe(
        (res) => {
          if (res.success) {
            this._toastr.success("Your password has been reset", "Success!");
            this.resetSuccess = true;
          } else {
            this._toastr.error(
              "Your password reset token has expired.  Please request a new one from the forgot password page",
              "Invalid Token!"
            );
          }
        },
        (err) => {
          this._toastr.error("There was an error updating your password, please try again", "Error!");
          Logger.error(err);
        }
      );
    }
  }
}
