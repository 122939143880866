import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-utils";
import { ChatData } from "@apptypes/chat/chat-data.interface";

import { CommentService } from "../comment.abstract.service";
import { CloudComment } from "../comment.api.types";

/**
 * A Comment service to post to Cloud Tournament Chatrooms
 *
 * @author Christian Tweed
 */
@Injectable({
  providedIn: "any",
})
export class CloudTournamentCommentService extends CommentService {

  public postComment({ firestoreDocID: chatroomId }: ChatData, authorId: string, message: string): Observable<unknown> {
    const url = `${environment.cloudAPIBase}/manageTournamentChat`;
    const options = {
      headers: basicAuthHeader(),
    };

    const body = this._formatChatMessage(chatroomId, authorId, message);

    return this._http.post(url, body, options);
  }

  protected _formatChatMessage(chatroomId: string, userId: string, message: string): CloudComment {
    return {
      chatroomId,
      userId,
      message,
    };
  }
}
