<app-static-page pageTitle="About Us" class="c_aboutPage">
  <div class="c_aboutPage__container">
    <p>
      GGLeagues is the premier platform built for recreational esports players
      and communities. We are focused on bringing communities together through
      gaming, regardless of your skill level. Either join one of our existing
      leagues or create your own! We are here to empower you because we are all
      good gamers, but we are even <strong>better people</strong>.
    </p>
    <iframe
      src="https://www.youtube.com/embed/ONLv6Q5zbZs"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      class="c_aboutUs__video"
    ></iframe>
  </div>
</app-static-page>
