import {
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import {
  filter,
  map,
  pluck,
  take,
  takeUntil,
} from "rxjs/operators";
import { Title } from "@angular/platform-browser";

import { RootState } from "src/app/reducers";
import { GetLeagueDashboard } from "src/app/reducers/dashboard/dashboard.actions";
import { DashboardState } from "src/app/reducers/dashboard";
import { Unsubscriber } from "src/app/util/unsubscriber";
import {
  leagueRoutes,
  routePaths,
  routeQueries,
  staticRoutes,
  teamRoutes,
} from "src/app/enums/routes/routePaths";
import { differenceFromNow, isISODateInPast } from "src/app/util/date-utils";
import {
  addMinutes,
  isBefore,
  parseISO,
} from "date-fns";
import { Logger } from "@utils/logger";
import { LeaguePassActivationModalComponent } from "src/app/components/league-pass-activation-modal/league-pass-activation-modal.component";
import { ActivatedRoute } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import { SeasonPassService } from "@services/v2/season-pass/season-pass.service";

@Component({
  selector: "app-dashboard-page",
  templateUrl: "./dashboard-page.component.html",
  styleUrls: ["./dashboard-page.component.scss"],
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  public myTeamsRoute = `/${routePaths.MY_TEAMS}`;
  public userInLeague = false;

  public leagueRoutes = leagueRoutes;
  public routeQueries = routeQueries;
  public staticRoutes = staticRoutes;
  public teamRoutes = teamRoutes;

  public dashboard$: Observable<DashboardState>;
  public userHasTeam$: Observable<boolean> = of(true);
  public userHasMultiplayerTeams$: Observable<boolean> = of(false);
  public userInLeague$: Observable<boolean> = of(true);
  public userHasEsportsCredentials$: Observable<boolean> = of(true);
  public isLoading$: Observable<boolean> = of(true);

  public showQueuePanel$: Observable<boolean> = of(true);

  private _unsub = new Unsubscriber();

  constructor(
    private _store: Store<RootState>,
    private _titleService: Title,
    private _route: ActivatedRoute,
    private _modalService: NgxSmartModalService,
    private _seasonPassService: SeasonPassService
  ) { }

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | Dashboard");
    this.dashboard$ = this._store.select("dashboard").pipe(takeUntil(this._unsub.unsubEvent));

    LeaguePassActivationModalComponent.initPassActivationModal(
      this._modalService,
      this._route,
      this._seasonPassService,
      this._store
    );

    this.userHasEsportsCredentials$ = this._store.select("user", "currentUser")
      .pipe(
        filter((user) => user !== null),
        map((user) => user.esportCredentials ? user.esportCredentials.length > 0 : false),
        takeUntil(this._unsub.unsubEvent)
      );

    this.userInLeague$ = this._store.select("dashboard", "leagues").pipe(
      map((leagueFlatMap) => new Map(leagueFlatMap)),
      map((leagueMap) => (leagueMap.size > 0))
    );

    this.isLoading$ = this._store
      .select("dashboard")
      .pipe(pluck("isLoading"), takeUntil(this._unsub.unsubEvent));

    this.dashboard$
      .pipe(
        take(1)
      )
      .subscribe((dashboard) => {
        if (!dashboard.updatedOn) {
          this._store.dispatch(new GetLeagueDashboard());
        } else {
          const dashboardAgeInMinutes = differenceFromNow(dashboard.updatedOn, "min");
          const MAX_AGE_IN_MINUTES = 5;
          if (Math.abs(dashboardAgeInMinutes) > MAX_AGE_IN_MINUTES) {
            this._store.dispatch(new GetLeagueDashboard());
          } else if (dashboard.leagues.length === 0) {
            this._store.dispatch(new GetLeagueDashboard());
          }
        }
      });

    this.showQueuePanel$ = this.dashboard$.pipe(
      map((dashboardData) => this._isNextEventQueue(dashboardData)),
      takeUntil(this._unsub.unsubEvent)
    );
  }

  public refreshDashboard(): void {
    this._store.dispatch(new GetLeagueDashboard());
  }

  public ngOnDestroy() {
    this._unsub.kill();
  }

  private _isNextEventQueue(dashboardData: DashboardState): boolean {
    const { nextMatch, nextQueue } = dashboardData;

    // Setup some named variables for better comprehension here
    const dashHasQueue = !!nextQueue;
    const dashHasMatch = !!nextMatch;

    // Setup basic priority when options are missing
    if (dashHasMatch && !dashHasQueue) {
      return false;
    } else if (!dashHasMatch && dashHasQueue) {
      return true;
    } else if (!dashHasMatch && !dashHasQueue) {
      return false;
    }

    // Another name var, setup priority when a NextGame and NextQueue both exist
    const { isQueueOpen } = nextQueue;

    // Always give queue priority when queue is open or will open within 30 minutes
    if (isQueueOpen) {
      return true;
    }
    try {
      // Safety try because date parsing stuff can get weird
      const QUEUE_SOON_MINUTES_OFFSET = -30;
      const offsetQueueTime = addMinutes(parseISO(nextQueue.startTime), QUEUE_SOON_MINUTES_OFFSET).toISOString();
      const doesQueueOpenSoon = isISODateInPast(offsetQueueTime);

      if (doesQueueOpenSoon) {
        return true;
      }
    } catch (e) {
      // If parsing fails on queue, default to match
      Logger.error(e);
      return false;
    }

    // Lastly just compare the start times for both and use that to determine which one to display
    try {
      const queueStartTime = parseISO(nextQueue.startTime);
      const matchStartTime = parseISO(nextMatch.matchTime);

      const isQueueBeforeMatch = isBefore(queueStartTime, matchStartTime);
      if (isQueueBeforeMatch) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      Logger.error(e);
      // We know if there is an error the second set of parses, something broke with match time
      return true;
    }
  }
}
