<div class="c_teamRosterEditBlock">
  <h4 class="u_txt--hdg4 c_teamRosterEditBlock__header">Team Roster</h4>
  <div class="c_teamRosterEditBlock__starters">
    <h5 class="u_txt--hdg5 c_teamRosterEditBlock__header">Starters</h5>
    <div
      class="c_teamRosterEditBlock__rosterGrid"
      *ngIf="(teamStarters$ | async)?.length > 0; else startersUnavailable"
    >
      <div
        class="c_teamRosterEditBlock__rosterGrid__gridItem"
        *ngFor="let starter of teamStarters$ | async"
      >
        <app-large-roster-icon
          [rosterMemberData]="starter"
        ></app-large-roster-icon>
        <button
          [disabled]="isRosterUpdating$ | async"
          (click)="benchPlayer(starter.teamUserId)"
          class="
            c_button
            warn
            small
            c_rosterActionButton c_rosterActionButton--bench
          "
        >
          Bench
        </button>
        <div *ngIf="starter.isCaptain">
          <button
            disabled
            matTooltip="You cannot remove the captain from the roster"
            class="
              c_button
              error
              small
              c_rosterActionButton c_rosterActionButton--remove
            "
          >
            Remove
          </button>
        </div>
        <div *ngIf="!starter.isCaptain">
          <button
            (click)="openRemovePlayerModal(starter)"
            [disabled]="isRosterUpdating$ | async"
            class="
              c_button
              error
              small
              c_rosterActionButton c_rosterActionButton--remove
            "
          >
            Remove
          </button>
        </div>
      </div>
    </div>
    <ng-template #startersUnavailable>
      <div class="c_teamRosterEditBlock__rosterGrid">
        <p>There are no starting players</p>
      </div>
    </ng-template>
  </div>
  <div class="c_teamRosterEditBlock__bench">
    <h5 class="u_txt--hdg5 c_teamRosterEditBlock__header">Bench</h5>
    <div
      class="c_teamRosterEditBlock__rosterGrid"
      *ngIf="(teamBench$ | async)?.length > 0; else benchUnavailable"
    >
      <div
        class="c_teamRosterEditBlock__rosterGrid__gridItem"
        *ngFor="let bench of teamBench$ | async"
      >
        <app-large-roster-icon
          [rosterMemberData]="bench"
        ></app-large-roster-icon>
        <button
          [disabled]="isRosterUpdating$ | async"
          (click)="activatePlayer(bench.teamUserId)"
          class="
            c_button
            success
            small
            c_rosterActionButton c_rosterActionButton--activate
          "
        >
          Make Starter
        </button>
        <div *ngIf="bench.isCaptain">
          <button
            disabled
            matTooltip="You cannot remove the captain from the roster"
            class="
              c_button
              error
              small
              c_rosterActionButton c_rosterActionButton--remove
            "
          >
            Remove
          </button>
        </div>
        <div *ngIf="!bench.isCaptain">
          <button
            (click)="openRemovePlayerModal(bench)"
            [disabled]="isRosterUpdating$ | async"
            class="
              c_button
              error
              small
              c_rosterActionButton c_rosterActionButton--remove
            "
          >
            Remove
          </button>
          <ngx-smart-modal #removePlayer identifier="removePlayer">
            <app-remove-player-modal></app-remove-player-modal>
          </ngx-smart-modal>
        </div>
      </div>
    </div>
    <ng-template #benchUnavailable>
      <div class="c_teamRosterEditBlock__rosterGrid">
        <p>There are no benched players</p>
      </div>
    </ng-template>
  </div>
</div>
<ng-template #loading>
  <div class="c_teamRosterEditBlock">
    <app-loader>Loading Roster...</app-loader>
  </div>
</ng-template>
