import { Directive, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[appPhoneNumber]",
})
export class PhoneNumberDirective{
  constructor(private _control: NgControl) {}

  //TODO: Get backspace and delete working
  // @HostListener("input", ["$event"])
  // public onInputChange(event: InputEvent): void {
  //   //Specifically to support backspace and delete
  //   if(event.inputType === "deleteContentBackward"){
  //     const newVal = this._stripStringToNumbersOnly((event.target as any).value);
  //     console.log(newVal);
  //   }
  // }

  @HostListener("ngModelChange", ["$event"])
  public ngControlChange(event: string): void {
    if(event){
      this._control.control.patchValue(this._formatPhoneNumber(event), {
        emitViewToModelChange: false, //prevents the hostlistener from recursing
      });
    }

    this._control.control.updateValueAndValidity();
  }

  private _formatPhoneNumber(inputVal: string): string {
    inputVal = this._stripStringToNumbersOnly(inputVal);
    const area = inputVal.slice(0, 3);
    const exchange = inputVal.slice(3, 6);
    const subscriber = inputVal.slice(6,10);

    if(!area){
      return "";
    }

    else if(!!area && !exchange){
      return `(${area})`;
    }

    else if(!!area && !!exchange && !subscriber){
      return `(${area})-${exchange}`;
    }

    return `(${area})-${exchange}-${subscriber}`;
  }

  private _stripStringToNumbersOnly(str: string): string {
    return str.replace(/[^0-9]*/g, "");
  }

}
