import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import {
  LeagueSeasonPass,
  SeasonPass,
  SeasonPassLeague,
} from "@apptypes/season-pass/season-pass.types";
import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-utils";

import {
  APILeagueSeasonPass,
  APISeasonPass,
  APISeasonPassLeague,
} from "./season-pass.v2.api.types";
import { apiToPlatform } from "src/app/enums/game-platforms.enum";
import {
  generateDashboardPassSuccessRoute,
  generateEsportPassRoute,
  generateEsportPassSuccessRoute,
  generateLeagueDetailPassActivationRoute,
  generateOrganizationSlugPassSuccessRoute,
} from "src/app/enums/routes/routePaths";

@Injectable({
  providedIn: "root",
})
export class SeasonPassService {
  constructor(private _http: HttpClient) { }

  public getLeagueSeasonPasses(leagueId: string | number): Observable<LeagueSeasonPass[]> {
    const url = `${environment.apiBase}/api/v2/leagues/${leagueId}/organization_season_passes`;
    const headers = basicAuthHeader();

    const mapAPISeasonPass = ({ id, type, attributes: { title, organizationId: apiOrganizationId } }: APILeagueSeasonPass
    ): LeagueSeasonPass => ({
      id,
      type,
      title,
      organizationId: apiOrganizationId.toString(),
    });

    const mapAPIResponse = ({ data }: { data: APILeagueSeasonPass[] }): LeagueSeasonPass[] => data.map(mapAPISeasonPass);

    return this._http.get<{ data: APILeagueSeasonPass[] }>(url, {
      headers,
    }).pipe(
      map(mapAPIResponse)
    );
  }

  public getSeasonPassById(seasonPassId: string | number): Observable<SeasonPass> {
    const url = `${environment.apiBase}/api/v2/organization_season_passes/${seasonPassId}`;
    const headers = basicAuthHeader();

    const mapAPILeague = (
      { id, type, attributes: { esportName: apiEsportName, ...attributes } }: APISeasonPassLeague
    ): SeasonPassLeague => ({
      id,
      type,
      ...attributes,
      esportName: apiToPlatform(apiEsportName),
    });

    const mapAPIResponse = (
      { data: { id, type, attributes: { leagues: apiLeagues, organizationId: apiOrganizationId, ...attributes } } }: { data: APISeasonPass }
    ) => {
      const leagues = apiLeagues.data.map(mapAPILeague);
      const organizationId = apiOrganizationId.toString();
      const formattedPass = {
        id,
        type,
        leagues,
        organizationId,
        hasSwag: attributes.swagStatus === "has_swag",
        ...attributes,
      };
      delete formattedPass.swagStatus;
      return formattedPass;
    };

    return this._http.get<{ data: APISeasonPass }>(url, {
      headers,
    }).pipe(
      map(mapAPIResponse)
    );
  }

  public getPassCheckoutPage(seasonPassId: string,
    orgContext: string | null = null,
    leagueContext: string | null = null,
    navigateToDashboard: boolean = false
  ): Observable<string | null> {
    const url = `${environment.apiBase}/api/v2/organization_season_passes/${seasonPassId}/purchase_from_stripe`;

    let successUrl: string;
    if (navigateToDashboard) {
      successUrl = this._generateReturnURL("dashboard", seasonPassId);
    } else if (orgContext !== null) {
      successUrl = this._generateReturnURL("org", seasonPassId, orgContext);
    } else if (leagueContext !== null) {
      successUrl = this._generateReturnURL("league", seasonPassId, leagueContext);
    } else {
      successUrl = this._generateReturnURL("pass", seasonPassId);
    }

    const payload = {
      organization_season_pass: {
        success_url: successUrl,
        cancel_url: `${generateEsportPassRoute(seasonPassId)}`,
      },
    };
    const options = {
      headers: basicAuthHeader(),
    };

    return this._http.post<{ checkout_url: string }>(url, payload, options).pipe(
      map((res) => res.checkout_url || null)
    );
  }

  public registerPassWithCode(seasonPassId: string, passCode: string): Observable<void> {
    const url = `${environment.apiBase}/api/v2/organization_season_passes/${seasonPassId}/redeem_with_code`;
    const formattedPassCode = passCode.trim().toUpperCase();
    const payload = {
      organization_season_pass: {
        registration_code: formattedPassCode,
        success_url: "",
        cancel_url: "",
      },
    };
    const options = {
      headers: basicAuthHeader(),
    };

    return this._http.post<void>(url, payload, options);
  }

  private _generateReturnURL(type: "pass" | "league" | "org" | "dashboard", passId: string, contextId: string | null = null): string {
    if (type === "league") {
      return `${generateLeagueDetailPassActivationRoute(contextId, passId)}`;
    }
    if (type === "org") {
      return `${generateOrganizationSlugPassSuccessRoute(contextId, passId)}`;
    }
    if (type === "dashboard") {
      return `${generateDashboardPassSuccessRoute(passId)}`;
    }
    return `${generateEsportPassSuccessRoute(passId)}`;
  }

}
