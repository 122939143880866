import { generateRulebookRoute } from "./routes/routePaths";

export enum RULEBOOK_TYPES {
  //REC
  MADDEN = "madden",
  FORTNITE_BR = "fortnitebr",
  FORTNITE_BR_ELIM = "fortnitebr_elim",
  ROCKET_LEAGUE = "rocketleague",
  SSBU = "smashbros",
  MARIOKART = "mariokart",
  //ELITE
  LOL_ELITE = "lol_elite",
  OVERWATCH_ELITE = "ow_elite",
  ROCKET_LEAGUE_ELITE = "rl_elite",
  VALORANT_ELITE = "valorant_elite",
  //WEEKLY
  SSBU_WEEKLY = "smashbros_weekly",
  ROCKET_LEAGUE_WEEKLY = "rl_weekly",
  MARIOKART_WEEKLY = "mariokart_weekly",
  FORTNITE_BR_ELIM_WEEKLY = "fortnitebr_elim_weekly",
  FORTNITE_BR_WEEKLY = "fortnitebr_weekly",
  NBA_2K_WEEKLY = "nba_2k_weekly",
}

export const REC_RULE_LINKS = {
  MADDEN: `/${generateRulebookRoute(RULEBOOK_TYPES.MADDEN)}`,
  FORTNITE: `/${generateRulebookRoute(RULEBOOK_TYPES.FORTNITE_BR)}`,
  FORTNITE_ELIM: `/${generateRulebookRoute(RULEBOOK_TYPES.FORTNITE_BR_ELIM)}`,
  SMASHBROS: `/${generateRulebookRoute(RULEBOOK_TYPES.SSBU)}`,
  ROCKETLEAGUE: `/${generateRulebookRoute(RULEBOOK_TYPES.ROCKET_LEAGUE)}`,
  MARIOKART: `/${generateRulebookRoute(RULEBOOK_TYPES.MARIOKART)}`,
};

export const ELITE_RULE_LINKS = {
  LOL_ELITE: `/${generateRulebookRoute(RULEBOOK_TYPES.LOL_ELITE)}`,
  RL_ELITE: `/${generateRulebookRoute(RULEBOOK_TYPES.ROCKET_LEAGUE_ELITE)}`,
  VALORANT_ELITE: `/${generateRulebookRoute(RULEBOOK_TYPES.VALORANT_ELITE)}`,
  OW_ELITE: `/${generateRulebookRoute(RULEBOOK_TYPES.OVERWATCH_ELITE)}`,
};

export const WEEKLY_RULE_LINKS = {
  SSBU_WEEKLY: `/${generateRulebookRoute(RULEBOOK_TYPES.SSBU_WEEKLY)}`,
  RL_WEEKLY: `/${generateRulebookRoute(RULEBOOK_TYPES.ROCKET_LEAGUE_WEEKLY)}`,
  MARIOKART_WEEKLY: `/${generateRulebookRoute(RULEBOOK_TYPES.MARIOKART_WEEKLY)}`,
  FORTNITE_BR_ELIM_WEEKLY: `/${generateRulebookRoute(RULEBOOK_TYPES.FORTNITE_BR_ELIM_WEEKLY)}`,
  FORTNITE_BR_WEEKLY: `/${generateRulebookRoute(RULEBOOK_TYPES.FORTNITE_BR_WEEKLY)}`,
  NBA_2K: `/${generateRulebookRoute(RULEBOOK_TYPES.NBA_2K_WEEKLY)}`,
};
