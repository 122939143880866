/**
 * Checks to see if the chat message contains the lobby code
 *
 * @param message
 * @param lobbyCode
 * @author Christian Tweed
 */
export const doesMessageContainLobbyCode = (message: string = "", lobbyCode: string = ""): boolean => {
  if(lobbyCode && lobbyCode.length === 0) {
    return false;
  }
  const stripCharactersArray = [
    " ",
    ".",
    ",",
    "-",
    "/",
    "\\",
    "!",
    "|",
  ];

  let strippedString = message.toLowerCase();

  for(const char of stripCharactersArray){
    strippedString = strippedString.split(char).join("");
  }

  return strippedString.includes(lobbyCode.toLowerCase());
};
