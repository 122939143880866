/* eslint-disable max-len */
import { gamePlatforms } from "src/app/enums/game-platforms.enum";

export const EsportArtworksMap: Map<gamePlatforms, string> = new Map([
  [gamePlatforms.SSBB, "https://content.ggleagues.com/public/esport_artwork/supersmashbros.png"],
  [gamePlatforms.EA_MADDEN, "https://content.ggleagues.com/public/esport_artwork/madden.png"],
  [gamePlatforms.ROCKET_LEAGUE, "https://content.ggleagues.com/public/esport_artwork/rocket_league.png"],
  [gamePlatforms.FORTNITE, "https://content.ggleagues.com/public/esport_artwork/fortnite.jpeg"],
  [gamePlatforms.MARIO_KART, "https://content.ggleagues.com/public/esport_artwork/splash_artwork/mario_kart_splash.png"],
]);

export const EsportSplashArtworkMap: { [key in gamePlatforms]: string } = {
  [gamePlatforms.LEAGUE_OF_LEGENDS]: "https://content.ggleagues.com/public/esport_artwork/splash_artwork/league_of_legends_splash.png",
  [gamePlatforms.ROCKET_LEAGUE]: "https://content.ggleagues.com/public/esport_artwork/rocket_league.png",
  [gamePlatforms.OVERWATCH]: "https://content.ggleagues.com/public/esport_artwork/splash_artwork/overwatch_splash.png",
  [gamePlatforms.DOTA_2]: "https://content.ggleagues.com/public/esport_artwork/splash_artwork/dota_2_splash.png",
  [gamePlatforms.VALORANT]: "https://content.ggleagues.com/public/esport_artwork/splash_artwork/valorant_splash.png",
  [gamePlatforms.FORTNITE]: "https://content.ggleagues.com/public/esport_artwork/fortnite.jpeg",
  [gamePlatforms.EA_MADDEN]: "https://content.ggleagues.com/public/esport_artwork/madden.png",
  [gamePlatforms.EA_NHL]: "https://content.ggleagues.com/public/esport_artwork/splash_artwork/ea_nhl_splash.png",
  [gamePlatforms.EA_FIFA]: "https://content.ggleagues.com/public/esport_artwork/splash_artwork/ea_fifa_splash.png",
  [gamePlatforms.NBA_2K]: "https://content.ggleagues.com/public/esport_artwork/splash_artwork/nba_2k_splash.png",
  [gamePlatforms.SSBB]: "https://content.ggleagues.com/public/esport_artwork/supersmashbros.png",
  [gamePlatforms.CALL_OF_DUTY]: "https://content.ggleagues.com/public/esport_artwork/splash_artwork/call_of_duty_splash.png",
  [gamePlatforms.MARIO_KART]: "https://content.ggleagues.com/public/esport_artwork/splash_artwork/mario_kart_splash.png",
  [gamePlatforms.POKEMON]: "https://content.ggleagues.com/public/esport_artwork/splash_artwork/pokemon_splash.jpg",
  [gamePlatforms.OTHER]: "", //Not sure what for here
};
