import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
import { zip } from "rxjs";
import {
  filter,
  map,
  tap,
  take,
} from "rxjs/operators";
import { Title } from "@angular/platform-browser";
import { parseISO, format } from "date-fns";

import { LeaguesV2Service } from "@services/v2/leagues/leagues.v2.service";
import { Logger } from "@utils/logger";

import { ComponentRouteParams } from "src/app/enums/routes/routeParams";
import { RootState } from "src/app/reducers";
import { doArraysShareStringValues } from "src/app/util/array-utils";
import { LeagueSeriesMatchupTeam, LeagueSeriesMatchupV2 } from "src/app/reducers/leagues/league.types";
import { APITournamentTypes } from "src/app/enums/tournamentTypes.enum";

export interface SortedMatches {
  recent: LeagueSeriesMatchupV2[];
  upcoming: LeagueSeriesMatchupV2[];
}

@Component({
  selector: "app-schedule-page",
  templateUrl: "./schedule-page.component.html",
  styleUrls: ["./schedule-page.component.scss"],
})
export class SchedulePageComponent implements OnInit {
  public leagueId: number;
  public title = "";
  public isShowingUserMatches = true;
  public isDataLoading = true;

  private _unfilteredRecentMatches: LeagueSeriesMatchupV2[] = [];
  private _unfilteredUpcomingMatches: LeagueSeriesMatchupV2[] = [];
  private _userTeamIDs: string[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _store: Store<RootState>,
    private _titleService: Title,
    private _leagueV2Service: LeaguesV2Service
  ) { }

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | Schedule");

    this._route.paramMap.pipe(take(1)).subscribe((params) => {
      this.leagueId = +params.get(ComponentRouteParams.LEAGUE_ID);
      const user$ = this._store.select("user", "currentUser").pipe(filter((user) => !!user));
      const matches$ = this._leagueV2Service.getLeagueMatches(this.leagueId);

      zip(user$, matches$)
        .pipe(
          map(([user, matches]) => ({
            user,
            matches,
          })),
          tap((info) => {
            this._userTeamIDs = info.user.teams.map((team) => team.id.toString());
          }),
          map((info) => info.matches),
          map((series) => this._sortMatchesForTime(series)),
          map((sortedSeries) => this._saveSortedMatches(sortedSeries)),
          tap(() => (this.isDataLoading = false)),
          take(1)
        )
        .subscribe();
    });
  }

  /**
   * Returns the recent matches, and if we are only showing user matches it
   * then filters out matches the user is not in
   *
   * @author Christian Tweed
   */
  public get recentMatches(): LeagueSeriesMatchupV2[] {
    return this._applyFilterForMatches(this._unfilteredRecentMatches);
  }

  /**
   * Returns the upcoming matches, and if we are only showing user matches it
   * then filters out matches the user is not in
   *
   * @author Christian Tweed
   */
  public get upcomingMatches(): LeagueSeriesMatchupV2[] {
    return this._applyFilterForMatches(this._unfilteredUpcomingMatches);
  }

  public processDate(isoDate: string): string {
    if (!isoDate) {
      return "TBD";
    }

    try {
      const parsedDate = parseISO(isoDate);
      return format(parsedDate, "MMM do y @ h:mma");
    }
    catch (e) {
      Logger.error(e);
      return "TBD";
    }
  }

  public compareTitleAgainstTBD(team: LeagueSeriesMatchupTeam): string {
    if (!team) {
      return "TBD";
    }

    return team.title ?? "TBD";
  }

  public isMatchBattleRoyale(match: LeagueSeriesMatchupV2): boolean {
    return match.tournament.schedulingType === APITournamentTypes.BATTLE_ROYALE;
  }

  /**
   * Splits the matches into recent and upcoming matches stored in an object
   *
   * @param matches
   * @author Christian Tweed
   */
  private _sortMatchesForTime(matches: LeagueSeriesMatchupV2[]): SortedMatches {
    const recent = [];
    const upcoming = [];
    matches.forEach((match) => {
      if (!match.winner) {
        upcoming.push(match);
      } else {
        recent.push(match);
      }
    });

    recent.sort((a, b) => {
      if (a.gameStartTime > b.gameStartTime) {
        return -1;
      } else if (a.gameStartTime < b.gameStartTime) {
        return 1;
      }
      return 0;
    });

    upcoming.sort((a, b) => {
      if (a.gameStartTime > b.gameStartTime) {
        return 1;
      } else if (a.gameStartTime < b.gameStartTime) {
        return -1;
      }
      return 0;
    });

    return {
      recent,
      upcoming,
    };
  }

  /**
   * Saves the sorted matches to our unfiltered arrays
   *
   * @author Christian Tweed
   */
  private _saveSortedMatches(sortedMatches: SortedMatches): void {
    this._unfilteredRecentMatches = sortedMatches.recent;
    this._unfilteredUpcomingMatches = sortedMatches.upcoming;
  }

  /**
   * If we are only showing user matches, we filter the given array for
   * matches that the user has a team in.
   *
   * @param matches
   * @author Christian Tweed
   */
  private _applyFilterForMatches(matches: LeagueSeriesMatchupV2[]): LeagueSeriesMatchupV2[] {
    return this.isShowingUserMatches ? matches.filter((match) => this._isUserMatch(match)) : matches;
  }

  /**
   * Checks to see if the user has a team in the given match
   *
   * @param match
   * @author Christian Tweed
   */
  private _isUserMatch(match: LeagueSeriesMatchupV2): boolean {
    if (match.tournament.schedulingType === APITournamentTypes.BATTLE_ROYALE) {
      return true;
    }
    const teamIds = match.teams.map(team => team.id);
    return doArraysShareStringValues(this._userTeamIDs, teamIds);
  }
}
