<app-lobby-help-modal-frame modalTitle="Learning About Your Overwatch Match">
	<p class="u_txt">How to Join an Overwatch Lobby</p>
	<iframe
		width="560"
		height="315"
		src="https://www.youtube.com/embed/kjnmflG6Yq8"
		frameborder="0"
		allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
		allowfullscreen
	></iframe>
</app-lobby-help-modal-frame>
