export enum FirestoreCollections {
  ANNOUNCEMENTS = "announcements",
  CHATROOMS = "chatrooms",
  NOTIFICATIONS = "notifications",
  SCRIMMAGE_CHATROOMS = "scrimmage-chatrooms",
  SERIES_MATCHUP_STREAMDATA = "series-matchup-streams",
  USER_CHATS = "user-chats",
  TOURNAMENT_CHATROOMS = "tournament-chatrooms",
  TOURNAMENT_STREAMS = "tournament-streams",
  QUEUE_SESSION_STREAMS = "tournament-queue-session-streams",
  QUEUE_SESSION_STREAM_CHECKINS = "tournament-queue-session-checkins",
  QUICK_PLAY_EVENT_STREAMS = "quick-play-event-streams",
}

export enum SeriesMatchupStreamCollections {
  BATTLE_ROYALE_GAMES = "battle-royale-matchup-games",
}

export enum TournamentStreamCollections {
  TOURNAMENT_CHECKINS = "tournament-checkins",
}

export enum QuickPlayEventStreamCollections {
  QUICK_PLAY_EVENT_CHECKINS = "quick-play-event-checkins",
  TOURNAMENT_TEAMS = "tournament-teams",
  TOURNAMENTS = "tournaments",
}

export enum FirestoreChatroomCollections {
  CHATROOMS = "chatrooms",
  TOURNAMENT_CHATROOMS = "tournament-chatrooms",
  SCRIMMAGE_CHATROOMS = "scrimmage-chatrooms",
  QUICK_PLAY_CHATROOMS = "quick-play-event-chatrooms",
}
