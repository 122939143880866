import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Unsubscriber } from "@utils/unsubscriber";
import { Observable, of } from "rxjs";
import {
  map,
  pluck,
  takeUntil,
} from "rxjs/operators";
import { SPONSOR_MANIFEST_LOCATIONS } from "src/app/enums/sponsor-manifests/sponsor-manifest.enum";
import { RootState } from "src/app/reducers";
import { LeagueLayoutManifest } from "src/app/reducers/leagues/league.types";

@Component({
  selector: "app-sponsor-campaign-bar-v1",
  templateUrl: "./sponsor-campaign-bar-v1.component.html",
  styleUrls: ["./sponsor-campaign-bar-v1.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SponsorCampaignBarV1Component implements OnDestroy, OnInit {
  public hasSponsorData$: Observable<boolean> = of(false);
  public manifestData$: Observable<LeagueLayoutManifest>;
  public titleSponsorAsset$: Observable<string>;
  public secondaryAssetOne$: Observable<string>;
  public secondaryAssetTwo$: Observable<string>;
  public secondaryAssetThree$: Observable<string>;


  private _unsub = new Unsubscriber();

  constructor(
    private _store: Store<RootState>
  ) { }

  public ngOnInit(): void {
    this.hasSponsorData$ = this._store.select("leagues", "league").pipe(
      pluck("leagueLayoutManifest"),
      map((llman) => {
        if (!llman) {
          return false;
        }

        return true;
      }),
      takeUntil(this._unsub.unsubEvent)

    );

    this.manifestData$ = this._store.select("leagues", "league").pipe(
      pluck("leagueLayoutManifest"),
      takeUntil(this._unsub.unsubEvent)
    );

    /*
      NOTE: Get updated data from these assets so we can provide alt tags
     */

    this.titleSponsorAsset$ = this.manifestData$.pipe(
      map((manifestData) => {
        if (manifestData) {
          const images = manifestData.sponsorshipCampaignContentWells;
          return images[SPONSOR_MANIFEST_LOCATIONS.TITLE_SPONSOR]?.assetUrl || "";
        }

        return "";
      })
    );


    this.secondaryAssetOne$ = this.manifestData$.pipe(
      map((manifestData) => {
        if (manifestData) {
          const images = manifestData.sponsorshipCampaignContentWells;
          return images[SPONSOR_MANIFEST_LOCATIONS.SECONDARY_SPONSOR_LOGO_ONE]?.assetUrl || "";
        }

        return "";
      })
    );


    this.secondaryAssetTwo$ = this.manifestData$.pipe(
      map((manifestData) => {
        if (manifestData) {
          const images = manifestData.sponsorshipCampaignContentWells;
          return images[SPONSOR_MANIFEST_LOCATIONS.SECONDARY_SPONSOR_LOGO_TWO]?.assetUrl || "";
        }
        return "";
      })
    );


    this.secondaryAssetThree$ = this.manifestData$.pipe(
      map((manifestData) => {
        if (manifestData) {
          const images = manifestData.sponsorshipCampaignContentWells;
          return images[SPONSOR_MANIFEST_LOCATIONS.SECONDARY_SPONSOR_LOGO_THREE]?.assetUrl || "";
        }

        return "";
      })
    );

  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }



}
