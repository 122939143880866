<app-page>
  <div class="c_createNewPasswordBlock">
    <div class="c_createNewPasswordBlock__header">
      <h2 class="u_txt--white u_txt--hdg2">Create New Password</h2>
    </div>
    <form
      class="c_createNewPasswordBlock__body"
      *ngIf="!resetSuccess"
      [formGroup]="resetPasswordForm"
      (submit)="setNewPassword()"
    >
      <input
        class="c_input"
        type="{{ showPassword() ? 'text' : 'password' }}"
        placeholder="Password"
        formControlName="password"
        id="password"
      />
      <div class="c_passwordToggle">
        <label for="showPW">
          <input type="checkbox" formControlName="showPW" id="showPW" />
          Show Password
        </label>
      </div>
      <p
        class="c_input__error"
        *ngIf="hasSpecificError('password', 'required')"
      >
        You must provide a password
      </p>
      <p
        class="c_input__error"
        *ngIf="hasSpecificError('password', 'minlength')"
      >
        Password must be at least 8 characters long
      </p>
      <p
        class="c_input__error"
        *ngIf="hasSpecificError('password', 'passwordUppercase')"
      >
        Password must contain at least 1 capital letter
      </p>
      <p
        class="c_input__error"
        *ngIf="hasSpecificError('password', 'passwordLowercase')"
      >
        Password must contain at least 1 lowercase letter
      </p>
      <p
        class="c_input__error"
        *ngIf="hasSpecificError('password', 'passwordNumber')"
      >
        Password must contain at least 1 number character (0-9)
      </p>
      <p
        class="c_input__error"
        *ngIf="hasSpecificError('password', 'passwordSpecial')"
      >
        Password must contain at least 1 special character:
        <br />
        @ ! * $ % # ? + = &
      </p>
      <app-form-loading-button>Create New Password</app-form-loading-button>
    </form>
    <div class="c_createNewPasswordBlock__body" *ngIf="resetSuccess">
      <h3 class="u_hdg3">Password Reset!</h3>
      <p class="u_txt--body">
        Your password has been reset successfully, you can now login with your
        new credentials
      </p>
      <a routerLink="/{{ routes.LOGIN }}" class="c_button primary_dark"
        >Login</a
      >
    </div>
  </div>
</app-page>
