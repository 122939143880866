<app-page>
  <div class="c_notificationsPageBlock__header">
    <h2 class="u_txt--white u_txt--hdg2">Notifications</h2>
    <div class="c_notificationPageBlock__subHeader">
      <button class="c_button primary_light" (click)="dismissAll()">
        Dismiss All
      </button>
    </div>
  </div>
  <div
    class="c_notificationPageBlock"
    *ngIf="!loading; else loadingNotifications"
  >
    <div
      class="c_notificationPageBlock__notifications"
      *ngIf="notificationsMap.length > 0; else noNotifications"
    >
      <div
        class="c_notificationPageBlock__notification"
        *ngFor="let notification of notificationsMap"
      >
        <div class="c_notificationPageBlock__notificationText">
          <div
            class="c_notificationPageBlock__notificationContent"
            *ngIf="notification.messageCount > 1; else singleMessage"
          >
            <div class="c_notificationPageBlock__notificationIcon">
              <i class="far fa-comments fa-5x"></i>
            </div>
            <a
              class="c_notificationPageBlock__notificationMessage"
              (click)="clearNotification(notification)"
              routerLink="/{{ getMatchDetailRoute(notification) }}"
            >
              <span class="u_txt"
                >There are {{ notification.messageCount }} unread messages in
                the {{ notification.chatTitle }} chat</span
              >
              <span class="u_txt c_notificationPageBlock__timestamp">
                {{ processDate(notification.latestTimestamp) }}
              </span>
            </a>
          </div>
          <ng-template #singleMessage>
            <div class="c_notificationPageBlock__notificationContent">
              <div class="c_notificationPageBlock__notificationIcon">
                <i class="far fa-comment fa-5x"></i>
              </div>
              <a
                (click)="clearNotification(notification)"
                class="c_notificationPageBlock__notificationMessage"
                routerLink="/{{ getMatchDetailRoute(notification) }}"
              >
                <span
                  class="u_txt c_notificationPageBlock__notificationChatMessage"
                  >There is 1 unread message in the
                  {{ notification.chatTitle }} chat</span
                >
                <span class="u_txt c_notificationPageBlock__timestamp">
                  {{ processDate(notification.latestTimestamp) }}
                </span>
              </a>
            </div>
          </ng-template>
          <i
            (click)="clearNotification(notification)"
            class="fas fa-times c_notificationPageBlock__clearButton"
          ></i>
        </div>
      </div>
    </div>

    <ng-template #noNotifications>
      <div class="c_notificationPageBlock__noNotifications">
        <i class="far fa-envelope-open fa-7x"></i>
        <h3 class="u_txt--hdg3">You Currently Have No Notifications</h3>
      </div>
    </ng-template>
  </div>

  <ng-template #loadingNotifications>
    <div class="c_notificationPageBlock">
      <app-loader [useWhite]="true">Loading Notifications...</app-loader>
    </div>
  </ng-template>
</app-page>
