// Refernce to Google Analytics

import { Logger } from "@utils/logger";

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

export const registerAnonymousExit = (referralUrl: string = "N/A"): void => {
  try {
    gtag("event", "anonymous_user_exit", {
      event_category: "user_exits",
      event_label: `exitpage:${referralUrl}`,
    });

  } catch (e) {
    Logger.error("Analytics success event failed to fire");
  }
};

export const registerUserExit = (referralUrl: string = "N/A"): void => {
  try {
    gtag("event", "registered_user_exit", {
      event_category: "user_exits",
      event_label: `exitpage:${referralUrl}`,
    });

  } catch (e) {
    Logger.error("Analytics success event failed to fire");
  }
};
