<app-lobby-help-modal-frame
	modalTitle="Learning About Your League of Legends Match"
>
	<p class="u_txt">
		How to Join a League of Legends Lobby with a Tournament Code
	</p>
	<iframe
		width="560"
		height="315"
		src="https://www.youtube.com/embed/Y6xqaBGY3HU"
		frameborder="0"
		allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
		allowfullscreen
	></iframe>
</app-lobby-help-modal-frame>
