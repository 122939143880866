import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Logger } from "@utils/logger";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ModalController } from "../modal-controller/modal-controller.abstract";

@Component({
  selector: "app-queue-success-modal",
  templateUrl: "./queue-success-modal.component.html",
  styleUrls: ["./queue-success-modal.component.scss"],
})
export class QueueSuccessModalComponent extends ModalController<void> implements OnInit {

  public static MODAL_NAME = "queue_success_modal";

  constructor(private _modalService: NgxSmartModalService, private _router: Router) {
    super(_modalService, QueueSuccessModalComponent.MODAL_NAME, _router);
  }

  public ngOnInit(): void {
    this._playSuccessChime();
  }

  private _playSuccessChime() {
    //TODO: If we make a new audio reference, we might want to make a service that creates a new audio and
    //add it into component providers so that way each component gets its own audio element and we
    //can mock and test them
    const audio = new Audio();
    audio.src = " https://content.ggleagues.com/public/webassets/queue_success.mp3";
    audio.load();
    const audioPlay = audio.play();
    // This helps catch and suppress errors if autoplay can't be triggered
    audioPlay.then(
      () => {
        Logger.log("Audio started");
      }
    ).catch(
      (err) => {
        Logger.error(err);
      }
    );
  }

}
